declare function initModals(): any;
declare function initShareModal(): any;

declare var $: any;
export function initShareModelCustome() {
  $(document).ready(function () {
    initShareModal();
    initModals();

    if ($('#share-modal').length) {
      //Share modal main dropdown
      $('.share-dropdown').on('click', function () {
        $(this).toggleClass('is-active');
      });

      //Share modal main dropdown
      $('.share-dropdown .dropdown-item').on('click', function () {
        var targetSharingChannel = $(this).attr('data-target-channel');
        var channelIcon = $(this).find('i').attr('class');
        var channelName = $(this).find('h3').text();

        if (targetSharingChannel !== undefined) {
          $('.share-dropdown .button')
            .find('i')
            .removeClass()
            .addClass(channelIcon);
          $('.share-dropdown .button').find('span').text(channelName);
          $('.share-channel-control').addClass('is-hidden');
          $('.footer-action.is-active').removeClass('is-active');
          $('#share-to-' + targetSharingChannel)
            .removeClass('is-hidden')
            .find('input')
            .focus();
        }
      });

      //Share modal page selector subdropdown
      $('.page-dropdown').on('click', function () {
        $(this).toggleClass('is-active');
      });

      //Share modal footer actions
      $('.action-wrap .footer-action').on('click', function () {
        var targetAction = $(this).attr('data-target-action');

        $('.footer-action.is-active').removeClass('is-active');
        $(this).addClass('is-active');

        if (targetAction !== undefined) {
          //$('.share-channel-control').addClass('is-hidden');
          $('.bottom-share-inputs .field').addClass('is-hidden');
          $('#action-' + targetAction)
            .removeClass('is-hidden')
            .find('input')
            .focus();
        }
      });
    }
  });
}
