<!-- <div class="pageloader"></div>
<div class="infraloader is-active"></div>
<div class="app-overlay"></div> -->
<app-nav-bar></app-nav-bar>
<div class="container is-custom">
  <div id="profile-main" class="view-wrap is-headless">
    <div class="columns is-multiline no-margin">
      <div class="column is-paddingless">
        <app-timeline-header
          [infouser]="infouser"
          (page)="getProfilePage($event)"
        ></app-timeline-header>
      </div>
    </div>
    <div class="columns" *ngIf="profilePage == 1">
      <app-profile
        [infouser]="infouser"
        [friends]="friends"
        [photos]="photos"
        [videos]="videos"
        [arrEducations]="arrEducations"
        id="profile-timeline-widgets"
        class="column is-4"
      ></app-profile>
      <div class="column is-8">
        <div id="activity-feed">
          <app-compose-card *ngIf="infouser"></app-compose-card>
        </div>
        <div id="profile-timeline-posts" class="box-heading">
          <h4>Posts</h4>
          <div class="button-wrap">
            <button type="button" class="button is-active">Recent</button>
            <button type="button" class="button">Popular</button>
          </div>
        </div>
        <div class="profile-timeline">
          <app-post-timeline [infouser]="infouser"></app-post-timeline>
        </div>
      </div>
    </div>
    <div class="column" *ngIf="profilePage == 2">
      <app-profile-about
        class="profile-about side-menu"
        [infouser]="infouser"
        [friends]="friends"
        [photos]="photos"
        [videos]="videos"
        [arrEducations]="arrEducations"
        [arrJobs]="arrJobs"
      ></app-profile-about>
    </div>
    <div class="columns" *ngIf="profilePage == 3">
      <app-profile-friends
        class="column"
        [infouser]="infouser"
        [friends]="friends"
        [loggedUserId]="loggedUser._id"
        (friendListChange)="onFriendListChange($event)"
      ></app-profile-friends>
    </div>
    <div class="columns" *ngIf="profilePage == 4">
      <app-profile-photos
        class="column"
        [infouser]="infouser"
        [photos]="photos"
      ></app-profile-photos>
    </div>
  </div>
</div>
<app-change-cover-modal></app-change-cover-modal>
<app-change-profile-pic-modal></app-change-profile-pic-modal>
<app-custom-profile-lightbox></app-custom-profile-lightbox>
<app-upload-crop-cover-modal></app-upload-crop-cover-modal>
<app-upload-crop-profile-modal></app-upload-crop-profile-modal>
<app-user-photos-modal></app-user-photos-modal>

<!-- modal album -->
<app-albums-help-modal></app-albums-help-modal>
<app-albums-modal></app-albums-modal>
<app-video-help-modal></app-video-help-modal>
<app-video-modal></app-video-modal>
<app-no-stream-modal></app-no-stream-modal>
