import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comment-dropdown',
  templateUrl: './comment-dropdown.component.html',
  styleUrls: ['./comment-dropdown.component.scss']
})
export class CommentDropdownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
