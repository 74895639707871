<div class="box-heading">
	<h4>Friends</h4>
	<div class="dropdown is-neutral is-spaced is-right dropdown-trigger">
		<div>
			<div class="button">
				<i-feather name="more-vertical"></i-feather>
			</div>
		</div>
		<div class="dropdown-menu" role="menu">
			<div class="dropdown-content">
				<a class="dropdown-item">
					<div class="media">
						<i-feather name="users"></i-feather>
						<div class="media-content">
							<h3>All Friends</h3>
							<small>View all friends.</small>
						</div>
					</div>
				</a>
				<a href="#" class="dropdown-item">
					<div class="media">
						<i-feather name="heart"></i-feather>
						<div class="media-content">
							<h3>Family</h3>
							<small>View family members.</small>
						</div>
					</div>
				</a>
				<a href="#" class="dropdown-item">
					<div class="media">
						<i-feather name="briefcase"></i-feather>
						<div class="media-content">
							<h3>Work Relations</h3>
							<small>View work related friends.</small>
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</div>
<div class="friend-cards-list">
	<div class="card is-friend-card">
		<div class="friend-item" *ngFor="let friend of friends">
			<ng-container *ngIf="infouser._id == friend.senderId._id">
				<img
					[src]="friend.receiverId.avatar"
					data-demo-src="assets/img/avatars/dan.jpg"
					alt
				/>
				<div class="text-content">
					<a>
						{{ friend.receiverId.firstname + " " + friend.receiverId.lastname }}
					</a>
					<span>10 mutual friend(s)</span>
				</div>
				<div class="star-friend">
					<i-feather name="star"></i-feather>
				</div>
			</ng-container>
			<ng-container *ngIf="infouser._id == friend.receiverId._id">
				<img
					[src]="friend.senderId.avatar"
					data-demo-src="assets/img/avatars/dan.jpg"
					alt
				/>
				<div class="text-content">
					<a>
						{{ friend.senderId.firstname + " " + friend.senderId.lastname }}
					</a>
					<span>10 mutual friend(s)</span>
				</div>
				<div class="star-friend">
					<i-feather name="star"></i-feather>
				</div>
			</ng-container>
		</div>
	</div>
</div>
