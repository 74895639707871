<ng-container *ngIf="friends && friends.length > 0">
	<div
		class="full-details"
		*ngFor="let friend of friends"
		(click)="getMessages(friend)"
		[ngStyle]="{background: friend.active ? '#ededed' : ''}"
	>
		<div class="avatar-container">
			<img class="user-avatar" [src]="friend.friendavatar" alt />
			<div
				class="friend-name"
				*ngIf="friend.senderId._id == tokenInfo._id && listExpanded"
				[ngStyle]="{'font-weight': friend.unread ? 'bold' : ''}"
			>
				{{ friend?.receiverId.firstname }} {{ friend?.receiverId.lastname }}
				<div
					class="last-message"
					*ngIf="friend?.messages && friend?.messages.length > 0"
				>
					{{ friend?.messages[friend.messages.length - 1].message }}
					{{ friend.countUnread > 0 ? "(" + friend.countUnread + ")" : "" }}
				</div>
			</div>
			<div
				class="friend-name"
				*ngIf="friend.receiverId._id == tokenInfo._id && listExpanded"
				[ngStyle]="{'font-weight': friend.unread ? 'bold' : ''}"
			>
				{{ friend?.senderId.firstname }} {{ friend?.senderId.lastname }}
				<div
					class="last-message"
					*ngIf="friend.messages && friend.messages.length > 0"
				>
					{{ friend.messages[friend.messages.length - 1].message }}
					{{ friend.countUnread > 0 ? "(" + friend.countUnread + ")" : "" }}
				</div>
			</div>
			<div class="user-status is-busy"></div>
			<div class="new-message-notification" *ngIf="friend.unread"></div>
		</div>
	</div>
</ng-container>
