import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import iziToast from 'izitoast';
import { fromEvent } from 'rxjs';
import { GroupService } from 'src/app/services/group/group.service';
import { PagesService } from 'src/app/services/pages.service';
import * as pageLoadJs from '../../../../page-main/js/pagejs';
declare var jQuery: any;

declare function initModals(): any;
@Component({
  selector: 'app-group-create',
  templateUrl: './group-create.component.html',
  styleUrls: ['./group-create.component.scss'],
})
export class GroupCreateComponent implements OnInit {
  @ViewChild('closeModal', { static: true }) divCloseModal: ElementRef;
  @Input() isModalGroup;
  @Output() isModalctrPage = new EventEmitter<any>();

  arrDisplay = [
    {
      value: 0,
      nameValue: 'Public',
      content: 'Anyone can see their team members and what they post.',
      icon: 'globe',
    },
    {
      value: 1,
      nameValue: 'Private',
      content: 'Only members can see group members and what they post.',
      icon: 'lock',
    },
  ];

  arrHidden = [
    {
      value: 0,
      nameValue: 'Visible',
      content: 'Anyone can find this group.',
      icon: 'eye',
    },
    {
      value: 1,
      nameValue: 'Hidden',
      content: 'Only members can find this group',
      icon: 'eye-off',
    },
  ];

  isActiveBtn;
  constructor(
    private groupService: GroupService,
    private pagesService: PagesService,
    private elm: ElementRef
  ) { }
  // form display custome
  previewDisplay;
  previewIcon;
  isDisplay;

  // form hidden group
  previewHidden;
  previewIconHidden;
  // model groups
  selectDisplay;
  groupHidden;
  groupName;
  previewName;
  groupDescription;
  groupDisplay;
  groupCategory;
  ngOnInit() {
    this.previewName = 'Group Name';
    this.previewDisplay = 'Choose privacy';
    this.previewHidden = 'Hidden group';
    this.isDisplay = false;
    this.isChangePrivateGroup = false;
    this.previewIcon = '';
    this.previewIconHidden = '';
    initModals();
  }

  //#region OPEN/SELECT hidden group
  isHidden;
  openHidden() {
    this.isHidden = !this.isHidden;
  }
  isChangePrivateGroup;
  selectHidden(value) {
    this.previewHidden = value.nameValue;
    this.previewIconHidden = value.icon;
    this.groupHidden = value.value;
    this.isHidden = !this.isHidden;
  }
  //#endregion

  //#region OPEN/SELECT display
  openDisplay() {
    this.isDisplay = true;
  }

  selectDiplay(value) {
    this.selectDisplay = value.value;
    this.previewDisplay = value.nameValue;
    this.previewIcon = value.icon;
    this.isDisplay = false;
    if (value.value === 1) {
      this.isChangePrivateGroup = true;
    }
  }
  //#endregion

  loadScriptIcon() {

  }

  isUpload;
  closeModalUpload() {
    pageLoadJs.pageLoadJs();
    this.isUpload = !this.isUpload;
  }

  //#region Create group
  createGroup() {
    if (this.groupName == null) {
      this.showMessageError('Group name is required.');
      return;
    }
    if (this.selectDiplay == null) {
      this.showMessageError('Please select display group.');
      return;
    }
    if (this.selectDisplay === 1) {
      if (this.groupHidden == null) {
        this.showMessageError('Please select hidden group.');
        return;
      }
    }

    if (this.groupName != null && this.selectDisplay != null) {
      this.groupService.checkGroupName(this.groupName).subscribe((result) => {
        if (result.status === 'Group name has already exist') {
          this.showMessageError('Group name has already exit.');
          return;
        } else {
          let reqGroup = {
            groupName: this.groupName,
            groupDisplay: this.selectDisplay,
            groupDescription: this.groupDescription,
            groupHidden: this.groupHidden,
          };
          this.groupService.createGroup(reqGroup).subscribe((result) => {
            if (result.status == 'success') {
              this.createAdminGroup(result.data);
              this.showMessageSuccess('Create group success.');
              this.pagesService.changeSuccessCreatePage({
                isLoading: true,
                id: result._id,
              });
              this.clearInput();
              let close = document.getElementsByClassName('close-modal')[0];
              close.addEventListener('click', function () { }, false);
            }
          });
        }
      });
    }
  }
  createMember(dataGroup) {
    let reqMember = {
      groupId: dataGroup._id,
      memberId: dataGroup.groupAdmin,
      isJoin: true,
    };

    this.groupService.createMember(reqMember).subscribe((result) => { });
  }
  createAdminGroup(dataGroup) {
    let data = {
      groupId: dataGroup._id,
      groupAdmin: dataGroup.groupAdmin,
      isAdmin: true,
      isCensor: true,
    };
    this.groupService.createAdmin(data).subscribe((result) => {
      if (result.status == 'success') {
        this.createMember(dataGroup);
      }
    });
  }
  clearInput() {
    this.groupName = undefined;
    this.selectDisplay = undefined;
    this.groupDescription = undefined;
    this.groupHidden = undefined;
  }
  //#endregion

  closeModalCreatePage() {
    this.isModalctrPage.emit(false);
  }
  //#region Show notification
  showMessageSuccess(message) {
    iziToast.show({
      class: 'toast',
      message: message,
      messageColor: '#ffffff',
      iconColor: '#ffffff',
      backgroundColor: '#00cc00',
      progressBarColor: '#fafafa',
      position: 'topRight',
      transitionIn: 'fadeInDown',
      close: false,
      zindex: 99999,
    });
  }

  showMessageError(message) {
    iziToast.warning({
      class: 'toast',
      message: message,
      messageColor: '#ffffff',
      iconColor: '#000000',
      backgroundColor: '#ff0000',
      progressBarColor: '#fafafa',
      position: 'topRight',
      transitionIn: 'fadeInDown',
      close: false,
      zindex: 99999,
    });
  }
  //#endregion
}
