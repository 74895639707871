import { Component, OnInit } from '@angular/core';
import iziToast from 'izitoast';
import { ProfileService } from 'src/app/services/profile.service';
declare var jQuery: any;

@Component({
  selector: 'app-upload-crop-profile-modal',
  templateUrl: './upload-crop-profile-modal.component.html',
  styleUrls: ['./upload-crop-profile-modal.component.scss'],
})
export class UploadCropProfileModalComponent implements OnInit {
  constructor(private profileService: ProfileService) { }

  ngOnInit(): void {
    this.loadJsUploadImage();
  }

  //#region Upload avatar
  uploadAvatarUser() {
    setTimeout(() => {
      const src = document.getElementById('avt-user').getAttribute('src');
      if (src != '' && src != null && src != undefined) {
        let data = {
          editImage: src,
        };
        this.profileService.uploadAvatar(data).subscribe((result) => {
          if (result.status == 'success') {
            iziToast.success({
              title: 'Cập nhật avatar thành công.',
              titleColor: '#000',
              timeout: 1000,
            });
            this.createPost(result);
            this.closeModal();
          }
        });
        (function ($) {
          $('#profile-upload-reset').addClass('is-hidden');
          $('.profile-uploader-box, .upload-demo-wrap').toggleClass(
            'is-hidden'
          );
          $('#submit-profile-picture').addClass('is-disabled');
          $('#upload-avatar-picture').val('');
        })(jQuery);
      }
    }, 1000);
  }
  //#endregion

  closeModal() {
    (function ($) {
      $('.modal.is-active').removeClass('is-active');
    })(jQuery);
  }

  loadJsUploadImage() {
    (function ($) {
      $(document).ready(function () {
        if ($('#upload-avatar').length) {
          let imgSrc;
          function readFile(input) {
            if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.onload = function (e) {
                $uploadCrop
                  .croppie('bind', {
                    url: e.target.result,
                  })
                  .then(function () {
                    imgSrc = e.target.result;
                    console.log('jQuery bind complete');
                  });
              };

              reader.readAsDataURL(input.files[0]);
            }
          }
          if (!$('#upload-avatar').data('croppie')) {
            var $uploadCrop = $('#upload-avatar').croppie({
              enableExif: true,
              url: 'assets/img/profile.png',
              viewport: {
                width: 350,
                height: 350,
                type: 'circle',
              },
              boundary: {
                width: 350,
                height: 350,
              },
            });
          }

          function popupResult(result) {
            var html;
            if (result.html) {
              html = result.html;
            }

            if (result.src) {
              $('.avatar-user').attr('src', result.src);
              html = '<img  src="' + result.src + '" />';
              $('.cover-bg .avatar .avatar-image, #user-avatar-minimal').attr(
                'src',
                result.src
              );

              $('#submit-profile-picture').removeClass('is-loading');
              $('#upload-crop-profile-modal').removeClass('is-active');
            }
          }

          $('#upload-avatar-picture').on('change', function () {
            readFile(this);
            $(this)
              .closest('.modal')
              .find('.profile-uploader-box, .upload-demo-wrap, .profile-reset')
              .toggleClass('is-hidden');
            $('#submit-profile-picture').removeClass('is-disabled');
          });

          $('#submit-profile-picture').on('click', function (ev) {
            var $this = $(this);
            $this.addClass('is-loading');
            $uploadCrop
              .croppie('result', {
                type: 'canvas',
                size: 'original',
                circle: false,
              })
              .then(function (resp) {
                popupResult({
                  src: resp,
                });
              });
          });

          $('#profile-upload-reset').on('click', function () {
            $(this).addClass('is-hidden');
            $('.profile-uploader-box, .upload-demo-wrap').toggleClass(
              'is-hidden'
            );
            $('#submit-profile-picture').addClass('is-disabled');
            $('#upload-avatar-picture').val('');
          });
        }
      });
    })(jQuery);
  }

  createPost(src) {
    let time = new Date().getTime();
    let postImg = [{ url: src.url.url, nameUrl: `avatar${time}`, _id: src._id, caption: "", type: "image/jpeg" }];
    let data = {
      postContent: 'đã cập nhật ảnh đại diện.',
      postImg: postImg,
      postDisplay: 0,
      postType: 0,
    };

    this.profileService.createPost(data).subscribe((result) => {
      if (result.status == 'success') {
        let postNew = result.data;
        if (postNew.postImg) {
          for (let i of postNew.postImg) {
            let photo = {
              photoName: i.url,
              photoDescription: 'Avatar',
            };
            this.profileService.createPhoto(photo).subscribe((resultPhoto) => {
              if (resultPhoto.status == 'success') {
                this.profileService.changeImg({ status: 'success' });
                const src = document
                  .getElementById('avt-user')
                  .setAttribute('src', '');
                document
                  .getElementsByClassName('cr-image')[0]
                  .setAttribute('src', '');
                console.log(
                  'cre- image',
                  document.getElementsByClassName('cr-image')[0]
                );
              }
            });
          }
        }
      }
    });
  }
}
