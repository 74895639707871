<div class="columns">
	<div class="column is-8">
		<div class="card page-about-card group-ab">
			<div class="card-title txt-title">
				<span>About This Group</span>
			</div>
			<div class="about-body">
				<div class="about-block">
					<div class="block-hr"></div>
					<div class="block-content">
						<span>{{ dataGroups?.groupDescription }}</span>
						<div class="ab">
							<div class="ab-wrap">
								<div class="ab-icon">
									<i-feather [name]="groupDisplayIcons"></i-feather>
								</div>
								<div class="ab-content">
									<div class="ab-cont-wrap txt-title">
										<span>{{ groupDisplays }}</span>
									</div>
									<div class="ab-cont-wrap txt-cont">
										{{ groupDisplayContents }}
										<span></span>
									</div>
								</div>
							</div>
							<div class="ab-wrap" *ngIf="dataGroups?.groupHidden">
								<div class="ab-icon">
									<i-feather [name]="groupHiddenIcons"></i-feather>
								</div>
								<div class="ab-content">
									<div class="ab-cont-wrap txt-title">
										<span>{{ groupHiddens }}</span>
									</div>
									<div class="ab-cont-wrap txt-cont">
										{{ groupHiddenContents }}
										<span></span>
									</div>
								</div>
							</div>
							<div class="ab-wrap">
								<div class="ab-icon">
									<i-feather name="users"></i-feather>
								</div>
								<div class="ab-content">
									<div class="ab-cont-wrap txt-title">
										<span> General Group</span>
									</div>
									<div class="ab-cont-wrap txt-cont">
										<span></span>
									</div>
								</div>
							</div>
							<div class="ab-wrap" *ngIf="dataGroups?.groupLocation">
								<div class="ab-icon">
									<i-feather name="map-pin"></i-feather>
								</div>
								<div class="ab-content">
									<div class="ab-cont-wrap txt-title">
										<span>{{ dataGroups?.groupLocation }}</span>
									</div>
									<div class="ab-cont-wrap txt-cont">
										<span></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card page-about-card group-ab">
			<div class="card-title txt-title is-end">
				<span>Members</span> <span>&nbsp;</span
				><span aria-hidden="true"> · </span>
				<span>&nbsp;</span>
				<span>{{ countMember }}</span>
			</div>
			<div class="about-body">
				<div class="about-block">
					<div class="block-hr"></div>
					<div class="block-content">
						<div class="gr-user">
							<div
								class="gr-user-wrap"
								*ngFor="let user of dataMember | slice: 0:14"
							>
								<img [src]="user?.memberId?.avatar" alt="user" />
							</div>
						</div>
						<div class="gr-name">
							<span
								><span *ngIf="arrAdmin"
									>{{ arrAdmin[0]?.groupAdmin?.lastname }}
								</span>
								and {{ countAdmin }} other members are admins.
								<span *ngIf="dataMember">{{
									dataMember[0]?.memberId?.lastname
								}}</span>
								and <span>{{ countMember }}</span> other members are
								moderators.</span
							>
							<div class="btn-see" (click)="clickSeeAll()">
								<span>See All</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
