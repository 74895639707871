import { Component, ElementRef, OnInit } from '@angular/core';

import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import iziToast from 'izitoast';
import { AuthService } from 'src/app/services/auth.service';
declare var jQuery: any;

@Component({
  selector: 'app-register-layouts',
  templateUrl: './register-layouts.component.html',
  styleUrls: ['./register-layouts.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class RegisterLayoutsComponent implements OnInit {
  uploadImage: FormGroup;
  signUpForm: FormGroup;
  user;
  arrMonth = [
    { number: 1, month: 'Jan' },
    {
      number: 2,
      month: 'Feb',
    },
    {
      number: 3,
      month: 'Mar',
    },
    {
      number: 4,
      month: 'Apr',
    },
    {
      number: 5,
      month: 'May',
    },
    {
      number: 6,
      month: 'June',
    },
    {
      number: 7,
      month: 'July',
    },
    {
      number: 8,
      month: 'Aug',
    },
    {
      number: 9,
      month: 'Sept',
    },
    {
      number: 10,
      month: 'Oct',
    },
    {
      number: 11,
      month: 'Nov',
    },
    { number: 12, month: 'Dec' },
  ];

  arrYear = [];
  arrDate = [];
  //model birthDay
  birthdayMonth;
  birthdayYear;
  birthdayDay;
  constructor(private authserver: AuthService, private fb: FormBuilder) {
    loadJsRegister();
    for (let i = 1; i <= 31; i++) {
      this.arrDate.push(i);
    }
    let yearCurrent = new Date().getFullYear();
    let maxYear = Number(yearCurrent - 1970);
    for (let j = 0; j <= maxYear; j++) {
      this.arrYear.push(Number(1970 + j));
    }
    this.arrYear.sort((a, b) => parseFloat(b) - parseFloat(a));
    this.user = new User(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  passwordRepeat;
  imgURL;
  ngOnInit(): void {
    const dateDefault = new Date();
    dateDefault.setFullYear(1970, 1, 1);
    this.birthdayYear = dateDefault.getFullYear();
    this.birthdayDay = dateDefault.getDate();
    this.birthdayMonth = 1;
    this.uploadImage = new FormGroup({
      image: new FormControl('', Validators.required),
    });
    this.createForm();
  }

  private createForm() {
    this.signUpForm = this.fb.group({
      info: this.fb.group({
        Firstname: new FormControl('', [Validators.required]),
        Lastname: new FormControl('', [Validators.required]),
        Email: new FormControl('', [
          Validators.required,
          this.checkWhiteSpace,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ]),
        Birthday: new FormControl(''),
        Monthbirthday: new FormControl('', [Validators.required]),
        Daybirthday: new FormControl('', [
          Validators.required,
          this.checkDay(),
        ]),
        Yearbirthday: new FormControl('', [
          Validators.required,
          this.checkYear(),
        ]),
        Gender: new FormControl(''),
        GenderMale: new FormControl(''),
        GenderFemale: new FormControl(''),
      }),

      Password: new FormControl('', [Validators.required]),
      Confirmpassword: new FormControl('', [Validators.required]),

      Dialnumber: new FormControl(''),
      Phone: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\(?([0-9]{10})$/),
      ]),
      Countryname: new FormControl(''),

      Occupation: new FormControl(''),

      Color: new FormControl(''),
    });
  }

  private checkWhiteSpace(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    if (control.value === '') {
      return isValid ? null : { whitespace: false };
    }
    return isValid ? null : { whitespace: true };
  }

  get primEmail() {
    return this.signUpForm.get('info.Email');
  }
  get primPassword() {
    return this.signUpForm.get('Password');
  }
  get primFirstname() {
    return this.signUpForm.get('info.Firstname');
  }

  get primLastname() {
    return this.signUpForm.get('info.Lastname');
  }

  get primConfirmPassword() {
    return this.signUpForm.get('Confirmpassword');
  }

  get primPhoneNumber() {
    return this.signUpForm.get('Phone');
  }
  get primDay() {
    return this.signUpForm.get('info.Daybirthday');
  }

  get primYear() {
    return this.signUpForm.get('info.Yearbirthday');
  }

  get primOccupatioon() {
    return this.signUpForm.get('Occupation');
  }

  get primMonth() {
    return this.signUpForm.get('info.Monthbirthday');
  }

  get primInfo() {
    return this.signUpForm.get('info');
  }

  private checkDay(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let maxDay;
      if (
        this.birthdayMonth == 1 ||
        this.birthdayMonth == 3 ||
        this.birthdayMonth == 5 ||
        this.birthdayMonth == 7 ||
        this.birthdayMonth == 8 ||
        this.birthdayMonth == 10 ||
        this.birthdayMonth == 12
      ) {
        maxDay = 31;
      } else if (
        this.birthdayMonth == 4 ||
        this.birthdayMonth == 6 ||
        this.birthdayMonth == 9 ||
        this.birthdayMonth == 11
      ) {
        maxDay = 30;
      } else if (this.birthdayMonth == 2) {
        if (
          (this.birthdayYear % 4 == 0 && this.birthdayYear % 100 != 0) ||
          this.birthdayYear % 400 == 0
        ) {
          maxDay = 29;
        } else {
          maxDay = 28;
        }
      }
      if ((control.value && control.value > maxDay) || control.value < 0) {
        return { dayInvalid: true };
      } else {
        return null;
      }
    };
  }

  private checkYear(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let year = new Date().getFullYear();
      if ((control.value && control.value > year) || control.value < 1900) {
        return { yearInvalid: true };
      } else {
        return null;
      }
    };
  }

  isFemale: boolean = false;
  isMale: boolean = false;
  female;
  male;
  gender;
  clickGender(id) {
    if (id == 0) {
      this.female = true;
      this.isFemale = true;
      this.isMale = false;
      this.gender = 'Nam';
      return;
    }
    if (id == 1) {
      this.male = true;
      this.isFemale = false;
      this.isMale = true;
      this.gender = 'Nữ';
      return;
    }
  }
  changeYear() {
    let maxDay;
    if (
      this.birthdayMonth == 1 ||
      this.birthdayMonth == 3 ||
      this.birthdayMonth == 5 ||
      this.birthdayMonth == 7 ||
      this.birthdayMonth == 8 ||
      this.birthdayMonth == 10 ||
      this.birthdayMonth == 12
    ) {
      maxDay = 31;
    } else if (
      this.birthdayMonth == 4 ||
      this.birthdayMonth == 6 ||
      this.birthdayMonth == 9 ||
      this.birthdayMonth == 11
    ) {
      maxDay = 30;
    } else if (this.birthdayMonth == 2) {
      if (
        (this.birthdayYear % 4 == 0 && this.birthdayYear % 100 != 0) ||
        this.birthdayYear % 400 == 0
      ) {
        maxDay = 29;
      } else {
        maxDay = 28;
      }
    }
    if (
      this.birthdayDay &&
      this.birthdayDay <= maxDay &&
      this.birthdayDay > 0
    ) {
      this.birthdayDay = this.birthdayDay;
    } else {
      this.birthdayDay = '';
    }
  }
  changeMonth(month) {
    this.birthdayMonth = month.target.value;
    this.birthdayDay = '';
  }
  //#region  FUNCTION check step 3
  isNextStep3: boolean = false;
  checkNextStep3() {
    if (this.isEmpty(this.primPassword.errors) === false) {
      if (this.primPassword.errors.required) {
        this.showMessageError('Password is required');
        return true;
      }
    }
    if (this.user.Password.length < 6) {
      this.showMessageError('6 digits(+)');
      return true;
    }

    if (this.isEmpty(this.primConfirmPassword.errors) === false) {
      this.showMessageError('Confirm password is required');
      return true;
    }
    if (this.user.Password && this.passwordRepeat) {
      if (this.isEqual(this.user.Password, this.passwordRepeat) === false) {
        this.showMessageError('Confirm password do not match.');
        return;
      }
    }

    if (this.passwordRepeat.length < 6) {
      this.showMessageError('6 digits(+)');
      return true;
    }
    this.doRegister();
  }
  // check equal password
  isEqual(str1, str2) {
    return str1.toUpperCase() === str2.toUpperCase();
  }
  // check obj null or empty
  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  }
  //#endregion

  //#region FUNCTION check step 2
  isNextStep2: boolean = false;
  checkNextStep2() {
    let img = document.getElementById('upload-preview').getAttribute('src');
    this.imgURL = img;
    this.user.avatar = img;
    this.isNextStep2 = true;
  }
  //#endregion

  //#region FUNCTION check step 1
  isDoNext: boolean = false;
  checkNextStep1() {
    this.user.Birthday = new Date(
      this.birthdayYear,
      this.birthdayMonth,
      this.birthdayDay
    );
    this.user.Gender = this.gender;
    if (!this.user.Firstname) {
      this.showMessageError('First name is required.');
      return (this.isDoNext = false);
    }
    if (!this.user.Lastname) {
      this.showMessageError('Last name is required.');
      return (this.isDoNext = false);
    }
    if (!this.user.Email) {
      this.showMessageError('Email is required.');
      return (this.isDoNext = false);
    }

    if (this.user.Email != null) {
      if (
        this.primEmail.errors != null &&
        this.primEmail.errors.pattern != undefined
      ) {
        this.showMessageError('Email is valid.');
        return (this.isDoNext = false);
      }
    }
    this.checkEmail(this.user.Email);
  }
  //#endregion

  onClick(event) {}
  //#region FUNCTION check Email
  isCheckEmail: boolean = false;
  checkEmail(email) {
    if (email) {
      let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.search(regex) > -1) {
        this.authserver.checkEmail({ email: email }).subscribe((result) => {
          if (result.status === 'User has already exist') {
            this.showMessageError('The email address is already registered.');
            this.isCheckEmail = false;

            return (this.isDoNext = false);
          }
          if (result.status === 'success') {
            this.showMessageSuccess('Bạn có thể sử dụng email này.');
            this.isCheckEmail = true;

            this.isDoNext = true;
          }
        });
      }
    }
  }
  //#endregion

  //#region FUNCTION clear Email
  clearEmail() {
    this.user.Email = undefined;
    this.primEmail.reset();
  }
  //#endregion

  //#region FUNCTION register
  doRegister() {
    let color = {
      userColor: '#7986cb',
      birthday: '#33b679',
      reminder: '#a79b8e',
      task: '#c0ca33',
      holiday: '#33b679',
    };
    this.user.Dialnumber = '84';
    this.user.Country = 'vn';
    this.user.Color = color;
    this.authserver.register(this.user).subscribe((result) => {
      if (result.status == 'success') {
        this.isNextStep3 = true;
      }
    });
  }
  //#endregion

  //#region FUNCTION show message notification
  showMessageSuccess(message) {
    iziToast.show({
      class: 'toast',
      message: message,
      messageColor: '#ffffff',
      iconColor: '#ffffff',
      backgroundColor: '#00cc00',
      progressBarColor: '#fafafa',
      position: 'topRight',
      transitionIn: 'fadeInDown',
      timeout: 1000,
      close: false,
      zindex: 99999,
    });
  }

  showMessageError(message) {
    iziToast.warning({
      class: 'toast',
      message: message,
      messageColor: '#ffffff',
      iconColor: '#000000',
      backgroundColor: '#ff0000',
      progressBarColor: '#fafafa',
      position: 'topRight',
      transitionIn: 'fadeInDown',
      timeout: 1000,
      close: false,
      zindex: 99999,
    });
  }

  //#endregion
}

export class User {
  constructor(
    public FirstName,
    public LastName,
    public Email,
    public Password,
    public Birthday,
    public Phone,
    public Country,
    public Dialnumber,
    public Occupation,
    public Gender,
    public Color,
    public avatar,
    public cover
  ) {}
}

export let loadJsRegister = () => {
  let js = ['../../../assets/js/signup.js'];
  removeScript();
  for (let i of js) {
    loadScript(i);
  }
};

function removeScript() {
  let removeScript = document.querySelectorAll(
    '[src="../../assets/js/signup.js"]'
  );
  if (removeScript.length > 0) {
    for (let i = 0; i < removeScript.length; i++) {
      if (removeScript[i]) {
        document.getElementsByTagName('body')[0].removeChild(removeScript[i]);
      }
    }
  }
}

function loadScript(url) {
  let script;
  script = document.createElement('script');
  let body = <HTMLDivElement>document.body;
  script.setAttribute('data-name', 'myscript');
  script.innerHTML = '';
  script.src = url;
  script.async = true;
  script.defer = true;
  body.appendChild(script);
}
