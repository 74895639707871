import { Component, Input, OnInit } from '@angular/core';
import { GroupService } from 'src/app/services/group/group.service';
import * as environment from '../../../../environments/environment';

declare var jQuery: any;

declare function initModals(): any;
@Component({
  selector: 'app-group-sidebar',
  templateUrl: './group-sidebar.component.html',
  styleUrls: ['./group-sidebar.component.scss'],
})
export class GroupSidebarComponent implements OnInit {
  dataGroups;
  isAdminGroups: boolean;
  @Input() set dataGroup(dataGroup: any) {
    this.dataGroups = dataGroup;
    if (this.dataGroups != null) {
      this.groupDisplay = this.arrDisplay[
        Number(this.dataGroups.groupDisplay)
      ].nameValue;
      this.iconDisplay = this.arrDisplay[
        Number(this.dataGroups.groupDisplay)
      ].icon;
    }
  }
  @Input() set isAdminGroup(isAdmin: boolean) {
    this.isAdminGroups = isAdmin;
  }

  environment;
  arrDisplay = [
    {
      value: 0,
      nameValue: 'Public',
      content: 'Anyone can see their team members and what they post.',
      icon: 'globe',
    },
    {
      value: 1,
      nameValue: 'Private',
      content: 'Only members can see group members and what they post.',
      icon: 'lock',
    },
  ];
  constructor(private groupService: GroupService) {
    this.environment = environment.environment.apiUrl;
  }
  groupDisplay;
  iconDisplay: string;
  ngOnInit(): void {
    this.getGroups();
    this.getGroupsByUser();
    initModals();
  }

  //#region Open side bar
  openSideBar() {
    let divId = document.getElementById('group-sidebar');
    divId.classList.toggle('is-active');
    this.groupService.clickBtnAdmin({ event: 'click' });
  }
  //#endregion

  //#region Get post new feed
  arrGroupAdmin = [];
  getGroups() {
    this.groupService.getGroupsAdminByUserId().subscribe((result) => {
      if (result.status == 'success') {
        this.arrGroupAdmin = result.data;
      }
    });
  }

  arrMyGroup = [];
  getGroupsByUser() {
    this.groupService.getGroupsByUserLogin().subscribe((result) => {
      if (result.status == 'success') {
        this.arrMyGroup = result.data;
      }
    });
  }
  //#endregion

  //#region open Modal create group
  openModalCreateGroup() {
    initModals();
  }
  //#endregion

  //#region Click attached group
  isAttached: boolean = false;
  openAttachedGroup() {
    this.isAttached = !this.isAttached;
    this.groupService.openAttached({
      status: this.isAttached,
      data: this.arrMyGroup,
    });
  }
  //#endregion

  //#region click feed post group
  openNewFeed() {
    this.isAttached = !this.isAttached;
    this.groupService.openAttached({
      status: this.isAttached,
      data: this.arrMyGroup,
    });
  }
  //#endregion
  //#region search group by groupName
  arrGroup = [];
  keySearch;
  searchGroup(groupName) {
    this.groupService
      .searchGroup({ groupName: groupName })
      .subscribe((result) => {
        if (result.status == 'success') {
          this.arrGroup = result.data;
        }
      });
  }
  gotoGroup(group) {
    window.location.replace(`groups/${group._id}`);
  }
  //#endregion
}
