import { Component, Input, OnInit } from '@angular/core';
import * as environment from '../../../../../environments/environment';
import { PagesService } from 'src/app/services/pages.service';
import { StoriesService } from 'src/app/services/stories.service';
declare var jQuery: any;
@Component({
  selector: 'app-video-story-modal',
  templateUrl: './video-story-modal.component.html',
  styleUrls: ['./video-story-modal.component.scss'],
})
export class VideoStoryModalComponent implements OnInit {
  infoUsers;
  @Input() set infoUser(infoUser: any) {
    this.infoUsers = infoUser;
  }
  environment;
  textComment;
  commentTag = [];
  postTags = [];
  model; arrName = []; arrUser = [];
  postContent;
  postImg;
  constructor(private pagesService: PagesService, private storiesService: StoriesService) {
    this.environment = environment.environment.apiUrl;
  }

  ngOnInit(): void { }

  searchUser(username) {
    if (username != undefined || username != null) {
      (function ($) {
        $(document).ready(function () {
          $('.tag-fr').focusout(function () {
            var element = $(this);
            if (!element.text().replace(' ', '').length) {
              element.empty();
            }
          });
        });
      })(jQuery);
      var start = /@/gi; // @ Match
      var word = /@(\w+)/gi; //@abc Match
      if (username.indexOf('@') > -1) {
        var go = username.match(start); //Content Matching @
        this.arrName = username.match(word); //Content Matching @abc
        if (go.length > 0) {
          if (this.arrName) {
            let count = 0;
            if (this.arrName.length > 1) {
              count = this.arrName.length - 1;
            }
            let usernameFillter = this.arrName[count].toString().slice(1);
            this.pagesService
              .searchUser({ search: usernameFillter })
              .subscribe((arrUser) => {
                if (arrUser.status == 'success') {
                  this.arrUser = arrUser.data;
                }
              });
          } else {
            this.arrUser = [];
          }
        }
      }
    }
  }

  async createStories() {
    let dataVideo = await this.storiesService.uploadVideo(this.formData).toPromise();
    let valueVideo = {
      url: "https://" + dataVideo.location,
      type: "video/mp4",
      name: dataVideo.originalname
    }
    let reqStories = {
      postImg: valueVideo,
      postContent: this.postContent,
      postTags: this.postTags,
      postType: 1,
    };
    let result = await this.storiesService.createStoires(reqStories).toPromise();
    if (result.status == 'success') {
      (function ($) {
        $(document).ready(function () {
          $('.btn-cus.close-modal').trigger('click');
        });
      })(jQuery);
      this.storiesService.changeStories({ isStories: true });
      this.clearInput();
    }
  }

  clearInput() {
    this.postImg = [];
    this.postContent = undefined;
    this.postTags = [];
    this.previewImg = [];
    this.formData.delete('post-img');
    document.getElementById(`tag-friends`).innerHTML = '';
  }

  imgDefault =
    'https://cdn.dribbble.com/users/17001/screenshots/5899975/dribbble_11_1x.png';
  previewImg;
  defaultImg = "https://cdn.dribbble.com/users/17914/screenshots/4902225/video-placeholder_1x.png"
  formData: FormData = new FormData();
  detectFiles(event) {
    this.formData = new FormData();
    this.previewImg = null;
    const file = event.target.files[0];
    this.formData.append("video", file);
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        // this.postImg = e.target.result;
        this.previewImg = e.target.result;
      };
    }
  }
}
