<app-nav-bar></app-nav-bar>
<div id="main-feed" class="container">
	<!-- <div class="pageloader"></div>
	<div class="infraloader is-active"></div>
	<div class="app-overlay"></div> -->
	<div id="activity-feed" class="view-wrap true-dom">
		<div class="columns">
			<div class="column is-3 is-hidden-mobile">
				<app-feed-menu></app-feed-menu>
			</div>
			<div class="column is-9">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</div>
