import { Component, OnInit, Input } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Input() infouser;
  @Input() friends;
  @Input() photos;
  @Input() arrEducations;
  @Input() videos;
  constructor(private profile: ProfileService) { }

  ngOnInit(): void { }
}
