import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import * as loadjs from '../../pages/feed-layout/pagelayout';
import { ActivatedRoute } from '@angular/router';
import * as environment from 'src/environments/environment';
@Component({
  selector: 'app-profile-main',
  templateUrl: './profile-main.component.html',
  styleUrls: ['./profile-main.component.scss'],
})
export class ProfileMainComponent implements OnInit {
  infouser;
  userId;
  friends;
  token;
  photos;
  videos;
  environment = environment.environment.apiUrl;
  profilePage;
  arrEduUser;
  arrJobUser;
  constructor(private profile: ProfileService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.arrEducations = [];
    this.arrJobs = [];
    this.arrEduUser = [];
    this.userId = this.route.snapshot.paramMap.get('id');
    this.token = localStorage.getItem('token');
    this.profilePage = 1;
    this.profile.changeSuccess.subscribe((image) => {
      if (image.status == 'success') {
        this.getProfile();
      }
    });
    this.getProfile();
    this.getInfoUserByToken();
    loadjs.loadjs();
  }
  async getProfile() {
    let data = {
      _id: this.userId,
    };
    let profile: any = await this.profile.getProfileById(data).toPromise();
    if (profile.status == 'success') {
      this.infouser = profile.data;
    }
    if (this.infouser) {
      let friends: any = await this.profile.getFriendsById(data).toPromise();
      this.friends = friends.data;
      let photos: any = await this.profile.getPhotos(data).toPromise();
      this.photos = photos.data;
      let videos: any = await this.profile.getVideos(data).toPromise();
      this.videos = videos.data;
      await this.getEducationByUserId(this.userId);
      await this.getJobByUserId(this.userId);
    } else {
      document.location.href = '/';
    }
  }

  getProfilePage($event) {
    this.profilePage = $event;
  }

  arrEducations = [];
  getEducationByUserId(data) {
    this.profile.getEduByUserId(data).subscribe((result) => {
      if (result.status == 'success') {
        this.arrEducations = result.data;
      }
    });
  }

  arrJobs = [];
  getJobByUserId(data) {
    this.profile.getJobByUserId(data).subscribe((result) => {
      if (result.status == 'success') {
        this.arrJobs = result.data;
      }
    });
  }

  loggedUser;
  getInfoUserByToken() {
    this.profile.getProfile().subscribe((result) => {
      if (result.status == 'success') {
        this.loggedUser = result['data'];
        console.log(this.loggedUser);
      }
    });
  }

  onFriendListChange(friend) {
    console.log('cc');
    this.friends.splice(
      this.friends.findIndex((i) => {
        return i._id == friend._id;
      }),
      1
    );
  }
}
