<ng-container *ngIf="infoUserId == friend.senderId._id">
  <div class="friend-item has-text-centered">
    <div class="item-header">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <!-- <button mat-menu-item disabled>
                        <span>Check voice mail</span>
                      </button> -->
        <button
          mat-menu-item
          (click)="unFriend(friend)"
          *ngIf="loggedUserId === infoUserId"
        >
          <mat-icon>person_remove</mat-icon>
          <span> Unfriend</span>
        </button>
      </mat-menu>
    </div>
    <a class="avatar-wrap">
      <div class="circle"></div>
      <div class="chat-button">
        <i-feather name="message-circle"></i-feather>
      </div>
      <img
        [src]="friend.receiverId.avatar"
        data-demo-src="assets/img/avatars/milly.jpg"
        data-user-popover="7"
        alt
      />
    </a>
    <a href="">
      <h3>
        {{ friend.receiverId.firstname + " " + friend.receiverId.lastname }}
      </h3>
    </a>
    <p>From Rome</p>
  </div>
</ng-container>
<ng-container *ngIf="infoUserId == friend.receiverId._id">
  <div class="friend-item has-text-centered">
    <div class="item-header">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <!-- <button mat-menu-item disabled>
                        <span>Check voice mail</span>
                      </button> -->
        <button
          mat-menu-item
          (click)="unFriend(friend)"
          *ngIf="loggedUserId === infoUserId"
        >
          <mat-icon>person_remove</mat-icon>
          <span> Unfriend</span>
        </button>
      </mat-menu>
    </div>
    <a class="avatar-wrap">
      <div class="circle"></div>
      <div class="chat-button">
        <i-feather name="message-circle"></i-feather>
      </div>
      <img
        [src]="friend.senderId.avatar"
        data-demo-src="assets/img/avatars/milly.jpg"
        data-user-popover="7"
        alt
      />
    </a>
    <a href="">
      <h3>
        {{ friend.senderId.firstname + " " + friend.senderId.lastname }}
      </h3>
    </a>
    <p>From Rome</p>
  </div>
</ng-container>
