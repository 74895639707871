import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questions-settings',
  templateUrl: './questions-settings.component.html',
  styleUrls: ['./questions-settings.component.scss']
})
export class QuestionsSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
