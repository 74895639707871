import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MessageService {

  public selectChatSubject: Subject<any> = new Subject();

  constructor(private http: HttpClient) {

  }

  selectChatSubjectNext() {
    this.selectChatSubject.next();
  }

  getMessages(data): Observable<any> {
    return this.http.post("link/message", data)
  }
  getAllMessages(): Observable<any> {
    return this.http.get("link/message/getAll")
  }
  createMessage(data): Observable<any> {
    return this.http.post("link/message/create", data)
  }
  updateMessage(data): Observable<any> {
    return this.http.post(`link/message/update`, data)
  }
  deleteMessage(id): Observable<any> {
    return this.http.delete(`link/message/delete/${id}`)
  }
  upload(formdata): Observable<any> {
    return this.http.post(`link/message/upload`, formdata)
  }
}
