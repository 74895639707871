import { NotificationGroupDialogComponent } from './../../notification-group-dialog/notification-group-dialog.component';
import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FeedService } from 'src/app/services/feed.service';
import { GroupService } from 'src/app/services/group/group.service';
import { PagesService } from 'src/app/services/pages.service';
import * as environment from 'src/environments/environment';
declare var jQuery: any;
declare var $: any;
declare function initDropdowns(): any;
import * as loadjs from 'src/app/pages/feed-layout/pagelayout';
@Component({
  selector: 'app-group-post',
  templateUrl: './group-post.component.html',
  styleUrls: ['./group-post.component.scss'],
})
export class GroupPostComponent implements OnInit, AfterViewChecked {
  dataGroups;
  dataUsers;
  @Input() set dataGroup(dataGroup) {
    this.dataGroups = dataGroup;
  }
  @Input() set dataUser(dataUser) {
    this.dataUsers = dataUser;
  }

  isHiddens: boolean;
  @Input() set isHidden(isHidden: boolean) {
    this.isHiddens = isHidden;
  }

  isMembers: boolean;
  @Input() set isMember(isMember: boolean) {
    this.isMembers = isMember;
  }

  //
  @Input() dataPage;
  @Input() dataAdmin;
  @Input() isAdmin;

  @Output() closeSide = new EventEmitter<any>();
  dataPageReceive;
  dataAdminReceive;
  environment;
  constructor(
    private pageService: PagesService,
    private groupService: GroupService,
    private feedService: FeedService,
    public dialog: MatDialog
  ) {
    this.environment = environment.environment.apiUrl;
  }

  limit;
  skip;
  skipReply;
  limitReply;

  ngAfterViewChecked(): void {
    if (this.isLoading) {
      $('.dropdown-trigger').click(function () {
        $('.dropdown-trigger').removeClass('is-active');
        $(this).addClass('is-active');
      });

      $(document).click(function (e) {
        var target = e.target;
        if (
          !$(target).is('.dropdown-trigger img') &&
          !$(target).parents().is('.dropdown-trigger')
        ) {
          $('.dropdown-trigger').removeClass('is-active');
        }
      });
    }
  }

  isLoading: boolean = false;
  ngOnInit() {
    //filter get more comment
    this.limit = 3;
    this.skip = 0;

    //filter get more replies
    this.skipReply = 0;
    this.limitReply = 1;

    // filter get more post
    this.skipPost = 0;
    this.limitPost = 5;

    this.getPosts(this.dataGroups._id);

    // when submit create post from component PageComposeCardComponent
    this.groupService.isSuccessPost.subscribe((result) => {
      if (result.status == 'success') {
        this.listPost = [];
        this.getPosts(this.dataGroups._id);
        setTimeout(() => {
          this.isLoading = true;
        }, 500);
      }
    });
    setTimeout(() => {
      this.isLoading = true;
    }, 500);
    loadjs.loadjs();
    console.log(this.dataUsers);
  }

  //#region FUCNTION share modal permission of user
  clickSharePost(post) {
    this.feedService.clickSharePost({ status: 'success', data: post });
  }
  //#endregion

  // ngOnChanges() {
  //   this.isAdmin = this.isAdmin;
  //   this.dataPageReceive = this.dataPage;
  //   this.dataAdminReceive = this.dataAdmin;
  // }

  clickGotoWeb(domain) {
    window.location.href = domain;
  }
  //#region  Get List Post in page

  listPost = [];
  getPosts(groupId) {
    this.groupService.getPostGroup(groupId).subscribe((result) => {
      if (result.status == 'success') {
        let arrPostNew = result.data;
        for (let post of arrPostNew) {
          post.isOpenComment = post.isOpenComment || {
            isActive: false,
          };
          let filter = {
            limit: this.limit,
            skip: this.skip,
          };
          this.getComments(post, filter);
          this.getLikeByPostId(post);
        }
        this.listPost = this.listPost.concat(arrPostNew);

        for (let post of this.listPost) {
          this.initPostContent(post);
        }

        console.log(this.listPost);
      }
    });
  }

  initPostContent(post) {
    if (post.postContent) {
      post.postContent = post.postContent.replace(/\n/g, '<br />');
      const lengthTxt = post.postContent.length;
      if (lengthTxt > 150) {
        post.postContentCut = post.postContent.substr(0, 150) + '...';
        post.isLength = true;
        return post;
      }
      post.isLength = false;
      return post;
    }
  }

  getComments(post, filters) {
    let requestComment = {
      postId: post._id,
      groupId: this.dataGroups._id,
      limit: filters.limit,
      skip: filters.skip,
    };
    this.groupService.getComments(requestComment).subscribe((resultComment) => {
      let filterReplies = {
        limit: 3,
        skip: 0,
      };
      let filterView = {
        isActive: false,
        isViewMore: false,
      };
      post.arrComment = post.arrComment || [];
      post.countComment = resultComment.count || 0;
      if (resultComment.status == 'success') {
        this.initComment(resultComment, post, filterReplies, filterView);
      }
    });
  }
  initComment(result, post, filterReplies, filterView) {
    post.countComment = result.count;
    post.arrComment = result.data.concat(post.arrComment);
    if (post.arrComment.length > 0) {
      if (post.filterComment && post.countComment > post.filterComment.skip) {
        post.filterView.isActive = true;
        post.filterView.isViewMore = true;
      }
      for (let comment of post.arrComment) {
        //filter get replies from comment Id
        comment.filterReplies = {
          skip: filterReplies.skip,
          limit: filterReplies.limit,
        };

        comment.filterView = {
          isActivce: filterView.isActive,
          isViewMore: filterView.isViewMore,
        };
        comment.filterView.isActive = filterView.isActive;
        comment.filterView.isViewMore = filterView.isViewMore;

        comment.arrReplies = comment.arrReplies || [];
        comment.countReplies = comment.countReplies || 0;
        comment.isLiked = false;
        comment.arrLike = comment.arrLike || [];
        this.getReplyByCmtIds(comment, comment.filterReplies);
        this.getLikeByCommentId(comment);
        this.checkCommentForUser(comment);
      }
    }
  }
  checkCommentForUser(comment) {
    comment.isUser = comment.userId._id == this.dataUsers._id ? true : false;
  }
  getLikeByCommentId(comment) {
    this.groupService.getLikeByCommentId(comment._id).subscribe((result) => {
      comment.arrLike = [];
      if (result.status == 'success') {
        let arrLike = result.data;
        comment.arrLike = arrLike;
        this.checkLikeUser(comment);
      }
    });
  }
  checkLikeUser(comment) {
    let arrLike = comment.arrLike;
    let checkLikeUser = arrLike.find(
      (like) => like.userId._id === this.dataUsers._id
    );
    comment.isLikeComment = checkLikeUser ? true : false;
  }
  //#endregion

  //#region Activities OPEN/CLOSE COMMENT in post
  openComment(post) {
    post.isOpenComment.isActive = true;
  }
  closeComment(post) {
    post.isOpenComment.isActive = false;
  }
  //#endregion

  //#region LIKE/UNLIKE post in group
  isActiveBtn;
  createLikePost(id, index) {
    this.isActiveBtn = true;
    let dataLike = {
      postId: id,
      groupId: this.dataGroups._id,
      type: 0,
    };
    this.groupService.createLike(dataLike).subscribe((like) => {
      if (like.status == 'success') {
        this.getLikeByPostId(this.listPost[index]);
      }
    });
  }
  unLikePost(id, index) {
    this.isActiveBtn = false;
    let unlike = {
      postId: id,
    };
    this.groupService.unlikePost(unlike).subscribe((result) => {
      if (result.status == 'success') {
        this.listPost[index].isLiked = false;
        this.getLikeByPostId(this.listPost[index]);
      }
    });
  }
  //#endregion

  //#region Get Array Like Post in group

  getLikeByPostId(post) {
    post.arrLike = [];
    post.isLiked = false;
    this.groupService.getLikesByPostId(post._id).subscribe((result) => {
      if (result.status == 'success') {
        let arrLikeNew = result.data;
        let checkeUseLike = arrLikeNew.find(
          (like) => like.userId._id === this.dataUsers._id
        );
        post.isLiked = checkeUseLike ? true : false;
        post.arrLike = arrLikeNew;
      }
    });
  }
  //#endregion

  //#region Create comment for post in group
  clearInput(post, index) {
    post.commentContent = undefined;
    post.commentImg = undefined;
    post.commentTag = undefined;
    post.commentVideo = undefined;
    document.getElementById(`${index}tag-friends`).innerHTML = '';
    document.getElementById(`${index}cmt-image`).style.display = 'none';
  }

  createComment(id, index) {
    let postCurrent = this.listPost[index];
    postCurrent.commentContent = document.getElementById(
      `${index}tag-friends`
    ).innerHTML;

    let dataComment = {
      groupId: this.dataGroups._id,
      postId: id,
      commentContent: postCurrent.commentContent,
      commentImg: postCurrent.commentImg,
      commentTag: postCurrent.commentTag,
      commentVideo: postCurrent.commentVideo,
    };
    this.groupService.createComment(dataComment).subscribe((result) => {
      if (result.status == 'success') {
        this.closeImg(index);
        this.clearInput(postCurrent, index);
        let commentNew = result.data;
        this.getCommentById(commentNew._id, index);
      }
    });
  }

  getCommentById(id, index) {
    this.groupService.getCommentById(id).subscribe((result) => {
      if (result.status == 'success') {
        let commnetNews = result.data;
        if (commnetNews) {
          this.insertOneComment(commnetNews, index);
        }
      }
    });
  }
  insertOneComment(commentNew, index) {
    commentNew.isLikeComment = false;
    commentNew.arrLike = commentNew.arrLike || [];
    let postCurrent = this.listPost[index];
    postCurrent.arrComment = postCurrent.arrComment || [];
    postCurrent.arrComment.push(commentNew);
    postCurrent.countComment = Number(postCurrent.countComment + 1);
  }
  deleteComment(idComment, index) {
    this.groupService.deleteComment(idComment).subscribe((deleteComment) => {
      if (deleteComment.status == 'success') {
        for (let post of Object.keys(this.listPost)) {
          let arrPost = this.listPost[post];
          let arrComment = arrPost.arrComment;
          if (arrComment.length > 0) {
            arrComment.splice(index, 1);
            arrPost.countComment = arrPost.countComment - 1;
          }
        }
      }
    });
  }
  //#endregion

  //#region Insert Reply of post when load first group

  getReplyByCmtIds(commentCurrent, filter) {
    commentCurrent.arrReplies = commentCurrent.arrReplies || [];
    commentCurrent.countReply = commentCurrent.countReply || 0;
    let requestReply = {
      id: commentCurrent._id,
      skip: filter.skipReply,
      limit: filter.limitReply,
    };
    this.groupService.getReplysByCmtId(requestReply).subscribe((dataReply) => {
      if (dataReply.status == 'success') {
        let arrRepliesNew = dataReply.data;
        if (arrRepliesNew.length > 0) {
          commentCurrent.countReply = dataReply.count || 0;
          commentCurrent.arrReplies = arrRepliesNew;

          commentCurrent.filterView.isActive =
            commentCurrent.countReply > 1 ? true : false;
          commentCurrent.filterView.isMore =
            commentCurrent.countReply > 1 ? true : false;

          commentCurrent.arrReplies = this.getUnique(
            commentCurrent.arrReplies,
            '_id'
          );

          let arrReplyNews = commentCurrent.arrReplies;
          for (let replies of arrReplyNews) {
            replies.isUser =
              replies.userId._id == this.dataUsers._id ? true : false;
            replies.arrLikeReply = replies.arrLikeReply || [];
            replies.isLikeReply = false;
            this.getLikeByReplyId(replies);
          }
        }
      }
    });
  }
  // check array duplicate
  getUnique(arr, comp) {
    const unique = arr
      .map((e) => e[comp])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);
    return unique;
  }

  //#endregion

  //#region Search user for tags
  repeatTag(arr) {
    if (arr.length > 1) {
      let count = 0;
      for (let i = 0; i < arr.length; i++) {
        for (let j = 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            return (count = count + 1);
          }
        }
      }
    }
  }
  textComment;
  tagsUser(user, index) {
    let postCurrent = this.listPost[index];
    let fullname = user.firstname + user.lastname;
    postCurrent.commentTag = postCurrent.commentTag || [];
    postCurrent.commentTag.push(user._id);
    let txtContent = document.getElementById(`${index}tag-friends`).innerHTML;
    let count = 0;
    if (this.repeatTag(postCurrent.arrName) > 0) {
      let lengthLast =
        postCurrent.arrName[postCurrent.arrName.length - 1].length;
      txtContent = txtContent.slice(0, txtContent.length - lengthLast);
    } else {
      if (postCurrent.arrName.length > 1) {
        count = postCurrent.arrName.length - 1;
      }
      let indexCount = txtContent.indexOf(postCurrent.arrName[count], 0);
      txtContent = txtContent.slice(0, indexCount);
    }
    document.getElementById(`${index}tag-friends`).innerHTML = txtContent;
    this.changeCommentContent(fullname, index);
    postCurrent.arrUser = [];
  }
  model;
  arrSpan = [];
  word;
  changeCommentContent(commentContent, index) {
    $(document).ready(function () {
      $(`#${index}tag-friends`).append(
        `<mark contenteditable="false">${commentContent}</mark>`
      );
    });
  }

  searchUser(username, index) {
    let postCurrent = this.listPost[index];
    if (username != undefined || username != null) {
      $('.tag-fr').focusout(function () {
        var element = $(this);
        if (!element.text().replace(' ', '').length) {
          element.empty();
        }
      });
      var start = /@/gi; // @ Match
      var word = /@(\w+)/gi; //@abc Match
      if (username.indexOf('@') > -1) {
        var go = username.match(start); //Content Matching @
        postCurrent.arrName = username.match(word); //Content Matching @abc
        if (go.length > 0) {
          if (postCurrent.arrName) {
            let count = 0;
            if (postCurrent.arrName.length > 1) {
              count = postCurrent.arrName.length - 1;
            }
            let usernameFillter = postCurrent.arrName[count]
              .toString()
              .slice(1);
            this.pageService
              .searchUser({ search: usernameFillter })
              .subscribe((arrUser) => {
                if (arrUser.status == 'success') {
                  postCurrent.arrUser = arrUser.data;
                }
              });
          } else {
            postCurrent.arrUser = [];
          }
        }
      }
    }
  }
  //#endregion

  //#region Preview image
  detectFiles(event, index) {
    let postCurrent = this.listPost[index];
    postCurrent.commentImg = undefined;
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        if (file.type.indexOf('image') > -1) {
          postCurrent.format = 'image';
        }
        if (file.type.indexOf('video') > -1) {
          postCurrent.format = 'video';
        }
        reader.onload = (e: any) => {
          document.getElementById(`${index}cmt-image`).style.display = 'block';
          if (file.type.indexOf('image') > -1) {
            postCurrent.commentImg = e.target.result;
          }
          if (file.type.indexOf('video') > -1) {
            postCurrent.commentVideo = e.target.result;
          }
        };
      }
    }
  }
  //#endregion

  //#region Delete image in comment in post
  closeImg(index) {
    let postCurrent = this.listPost[index];
    postCurrent.commentImg = undefined;
    document.getElementById(`${index}cmt-image`).style.display = 'none';
    if (postCurrent.format === 'image') {
      postCurrent.commentImg = undefined;
      postCurrent.format = undefined;
      return;
    }
    if (postCurrent.format === 'video') {
      postCurrent.commentVideo = undefined;
      postCurrent.format = undefined;
      return;
    }
  }
  //#endregion

  //#region View all comment, reply in post
  viewMoreComment(post) {
    post.filterComment.skip =
      post.filterComment.skip + post.filterComment.limit;

    this.getComments(post, post.filterComment);
  }

  viewMoreReplies(indexPostCurrent, comment) {
    comment.filterReplies.skipReply =
      comment.filterReplies.skipReply + comment.filterReplies.limitReply;
    this.insertManyReply(comment._id, comment.filterReplies, comment);
  }
  insertManyReply(commentId, filter, comment) {
    let requestReply = {
      id: commentId,
      limit: filter.limitReply,
      skip: filter.skipReply,
    };
    this.groupService
      .getReplysByCmtId(requestReply)
      .subscribe((resultReplies) => {
        if (resultReplies.status == 'success') {
          let arrRepliesNew = resultReplies.data;
          comment.arrReplies = comment.arrReplies || [];
          comment.countReply = resultReplies.count || 0;
          for (let replies of arrRepliesNew) {
            this.getLikeByReplyId(replies);
          }
          comment.filterView.isActive =
            comment.countReply > filter.limiReply ? true : false;
          comment.filterView.isMore =
            comment.countReply > filter.limiReply ? true : false;

          comment.arrReplies = arrRepliesNew.concat(comment.arrReplies);
        }
      });
  }
  //#endregion

  //#region Enter link preview
  private regex = new RegExp(
    '^(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})'
  );
  isEnter;
  enterLink(postShareLink) {
    this.isEnter = true;
    this.getDataFromUrl(postShareLink);
  }
  linkPreview;
  getDataFromUrl(url) {
    if (url.length > 0) {
      if (url.indexOf('https://') > -1) {
        url = url.slice(8, url.length);
      }
      this.pageService.getDataFromUrl({ url: url }).subscribe((result) => {
        if (result.status == 'success') {
          this.isEnter = false;
          this.linkPreview = result.data;
          $('#link-summary').css('display', 'block').fadeIn(2000, 'swing');
        }
      });
    }
  }
  //#endregion

  //#region CREATE, GET, DELETE, EDIT Reply comment in post group
  createReplyCmt(postId, index) {
    let postCurrent = this.listPost[index];
    postCurrent.commentContent = document.getElementById(
      `${index}tag-friends`
    ).innerHTML;
    if (postCurrent.repliedCommentId) {
      let reply = {
        groupId: this.dataGroups._id,
        postId: postId,
        commentId: postCurrent.repliedCommentId,
        replyContent: postCurrent.commentContent,
        replyImg: postCurrent.commentImg,
      };
      this.groupService.createReply(reply).subscribe((result) => {
        if (result.status == 'success') {
          if (result.data) {
            let replies = result.data;
            this.getReplyById(
              replies._id,
              index,
              postCurrent.indexCommentReplyByUser
            );
            this.clearInput(postCurrent, index);
            postCurrent.arrReplyTime = undefined;
          }
        }
      });
    }
  }
  getReplyById(replyId, index, indexComment) {
    this.groupService.getReplyById(replyId).subscribe((dataReply) => {
      if (dataReply.status == 'success') {
        let repliesNew = dataReply.data;
        this.insertOneReply(repliesNew, index, indexComment);
      }
    });
  }
  insertOneReply(replies, index, indexComment) {
    replies.isLikeReply = false;
    replies.arrLikeReply = replies.arrLikeReply || [];

    let postCurrent = this.listPost[index];
    postCurrent.arrComment = postCurrent.arrComment || [];
    let commentCurrent = postCurrent.arrComment[indexComment];
    commentCurrent.arrReplies.push(replies);
    postCurrent.countComment = Number(postCurrent.countComment + 1);
  }
  clickReply(username, commentId, index, indexComment) {
    document.getElementById(`${index}tag-friends`).focus();
    let postCurrent = this.listPost[index];
    postCurrent.repliedCommentId = commentId;
    postCurrent.indexCommentReplyByUser = indexComment;
    postCurrent.arrReplyTime = postCurrent.arrReplyTime || [];
    postCurrent.arrReplyTime.push(username);
    let fullname = username.firstname + username.lastname;
    if (this.dataUsers._id != username._id) {
      this.changeCommentContent(fullname, index);
    }
  }
  deleteReply(replyId, comment, indexReply) {
    this.groupService.deleteReplies(replyId).subscribe((result) => {
      if (result.status == 'success') {
        let arrReplies = comment.arrReplies;
        arrReplies.splice(indexReply, 1);
        arrReplies.countReply = Number(arrReplies.countReply - 1);
      }
    });
  }
  //#endregion

  //#region Jquery load function
  openMenu() {
    initDropdowns();
  }
  //#endregion

  //#region CREATE LIKE, UNLIKE COMMENT
  createLikeComment(comment, index, indexComment) {
    let reqLikeCmt = {
      commentId: comment._id,
      type: 1,
    };
    this.groupService.createLike(reqLikeCmt).subscribe((resultLike) => {
      if (resultLike.status == 'success') {
        this.insertLikeComment(index, indexComment);
      }
    });
  }

  insertLikeComment(index, indexComment) {
    let postCurrent = this.listPost[index];
    let commentCurrent = postCurrent.arrComment[indexComment];
    this.getLikeByCommentId(commentCurrent);
  }

  unlikeComment(comment, index, indexComment) {
    let unLike = {
      commentId: comment._id,
    };
    this.groupService.unlikeComment(unLike).subscribe((result) => {
      if (result.status == 'success') {
        this.RemoveLikeComment(index, indexComment);
      }
    });
  }
  RemoveLikeComment(index, indexComment) {
    let posts = this.listPost[index];
    let commentcurrent = posts.arrComment[indexComment];
    this.getLikeByCommentId(commentcurrent);
  }
  //#endregion

  //#region CREATE LIKE, UNLIKE REPLY COMMENT
  createLikeReply(commentId, replyId, index, indexComment, indexReply) {
    let likeReply = {
      commentId: commentId,
      replyId: replyId,
      type: 2,
    };
    this.groupService.createLike(likeReply).subscribe((result) => {
      if (result.status == 'success') {
        this.insertLikeReply(index, indexComment, indexReply);
      }
    });
  }
  insertLikeReply(index, indexComment, indexReply) {
    let postCurrent = this.listPost[index];
    let commentCurrent = postCurrent.arrComment[indexComment];
    let replies = commentCurrent.arrReplies[indexReply];
    replies.isLikeReply = false;
    replies.arrLikeReply = replies.arrLikeReply || [];
    this.getLikeByReplyId(replies);
  }
  getLikeByReplyId(replies) {
    this.groupService.getLikeByReplyId(replies._id).subscribe((result) => {
      if (result.status == 'success') {
        if (result.data) {
          replies.arrLikeReply = result.data;
          this.checkLikeReply(replies);
        }
      }
    });
  }
  checkLikeReply(replies) {
    let arrLikeReply = replies.arrLikeReply;
    let checkLikeReplies = arrLikeReply.find(
      (item) => item.userId._id === this.dataUsers._id
    );
    replies.isLikeReply = checkLikeReplies ? true : false;
  }

  unLikeReply(replyId, index, indexComment, indexReply) {
    let data = {
      replyId: replyId,
    };
    this.groupService.unlikeReply(data).subscribe((result) => {
      if (result.status == 'success') {
        this.insertLikeReply(index, indexComment, indexReply);
      }
    });
  }
  //#endregion

  //#region GET list post with filter(skip, limit)
  skipPost;
  limitPost;
  skipPosts = 0;
  getMoresPost() {
    (function ($) {
      $(document).ready(function () {
        var t;
        $('.load-more-button').on('click', function (e) {
          e.preventDefault();
          clearTimeout(t);
          $(this).toggleClass('loading');
          t = setTimeout(function () {
            $('.load-more-button').removeClass('loading');
          }, 2500);
        });
      });
    })(jQuery);

    this.skipPost = this.skipPosts + 5;
    this.skipPosts = this.skipPost;
    let reqMore = {
      postGroupId: this.dataGroups._id,
      skip: this.skipPosts,
      limit: this.limitPost,
    };
    this.groupService.getMorePosts(reqMore).subscribe((result) => {
      if (result.status == 'success') {
        let arrPostNew = result.data;
        for (let post of arrPostNew) {
          post.isOpenComment = post.isOpenComment || {
            isActive: false,
          };
          let filter = {
            limit: this.limit,
            skip: this.skip,
          };
          // this.getCommentsByPageId(post, filter);
          // this.getLikeByPostId(post);
        }
        this.listPost = this.listPost.concat(arrPostNew);
      }
    });
  }
  //#endregion

  //#region JQUERY ZOOM IMAGE USING FANCYBOX
  dataPost;
  isBtnAdmin: boolean = false;
  openPostFancyBox(post) {
    post.type = 2;
    this.dataPost = post;
    this.isActivePhoto = true;
    this.closeSide.emit({ click: true });
  }

  //#endregion
  //#region @Output from pagesPhotoLightBoxComponents
  isActivePhoto: boolean = false;
  closeModal(event) {
    this.isActivePhoto = event;
  }
  //#endregion

  //#region properties with post
  deletePost(post) {
    console.log(this.dataGroups);
    const dialog = this.dialog.open(NotificationGroupDialogComponent, {
      width: '500px',
      data: {
        groupName: this.dataGroups.groupName,
        description: 'Xóa bài đăng',
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result.status == 'yes') {
        this.groupService.deletePostGroup(post._id).subscribe((result) => {
          if (result.status === 'success') {
            this.groupService
              .deletePhotoByPostId(post._id)
              .subscribe((result) => {
                this.listPost.splice(
                  this.listPost.findIndex((i) => {
                    return i._id == post._id;
                  }),
                  1
                );
              });
          }
        });
      }
    });
  }
  //#endregion

  indexImg;
  getImage(index) {
    this.indexImg = index;
    this.isActivePhoto = true;
  }

  classifyPost = 0;
  changeMode(number: number) {
    this.classifyPost = number;
  }

  //Output from post-request-group, do when a new was added
  updatePostList(post) {
    console.log(post);
    this.listPost.splice(0, 0, post);
  }
}
