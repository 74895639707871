import { Component, OnInit, Input } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import $ from 'jquery';
import { Education, Job } from '../../settings.component';
import * as loadjs from '../../../../pages/feed-layout/pagelayout';
import { DatePipe } from '@angular/common';
import iziToast from 'izitoast';
declare var jQuery: any;

@Component({
  selector: 'app-general-setting',
  templateUrl: './general-setting.component.html',
  styleUrls: ['./general-setting.component.scss'],
})
export class GeneralSettingComponent implements OnInit {
  @Input() user;
  @Input() job;

  @Input() education;
  @Input() arrEduUser;
  @Input() arrSchools;

  @Input() arrCompany;
  @Input() arrJobUser;

  arrDisplay = [
    { value: 0, name: ' Công khai' },
    { value: 1, name: ' Bạn bè' },
    { value: 2, name: ' Riêng tư' },
    { value: 3, name: ' Chỉ mình tôi' },
  ];
  userId;
  isEditEdu;
  isEditJob;
  isAddJob;
  isAddEdu;
  isShow;
  constructor(
    private datePipe: DatePipe,
    private profileService: ProfileService,
    private toastr: ToastrManager
  ) { }

  ngOnInit(): void {
    this.isEditEdu = false;
    this.isEditJob = false;
    this.isAddJob = false;
    this.isAddEdu = false;
  }

  clickDatePicker() {
    $(document).ready(function () {
      $('[data-toggle="datepicker"]').datepicker();
    });
  }

  updateUser() {
    let data = {
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      city: this.user.city,
      country: this.user.country,
      address: this.user.address,
    };

    this.profileService.updateProfile(data).subscribe((result) => {
      if (result.status == 'success') {
        iziToast.success({
          title: 'Đã lưu thông tin',
          titleColor: '#000',
          timeout: 1000,
        });
      } else {
        iziToast.warning({
          title: 'Thông tin chưa được lưu',
          titleColor: '#000',
          timeout: 1000,
        });
      }
    });
  }

  addEducation() {
    this.isAddEdu = !this.isAddEdu;
    this.education = new Education('', '', '', '', '', 0);
  }

  addJob() {
    this.isAddJob = !this.isAddJob;
    this.job = new Job('', '', '', '', '', 0);
  }

  idEduEdit;
  clickEditEdu(dataEduUser) {
    this.isEditEdu = !this.isEditEdu;
    this.idEduEdit = dataEduUser._id;
    this.education = new Education(
      dataEduUser.userId,
      dataEduUser.schoolId,
      dataEduUser.role,
      this.datePipe.transform(dataEduUser.startDate, 'yyyy-MM-dd'),
      this.datePipe.transform(dataEduUser.endDate, 'yyyy-MM-dd'),
      dataEduUser.isShow
    );

  }
  idJobEdit;
  clickEditJob(dataJobUser) {
    this.isEditJob = !this.isEditJob;
    this.idJobEdit = dataJobUser._id;
    this.job = new Job(
      dataJobUser.userId,
      dataJobUser.companyId,
      dataJobUser.role,
      this.datePipe.transform(dataJobUser.startDate, 'yyyy-MM-dd'),
      this.datePipe.transform(dataJobUser.endDate, 'yyyy-MM-dd'),
      dataJobUser.isShow
    );

  }

  clickCloseEditEdu() {
    if (this.isAddEdu == true) {
      this.isAddEdu = !this.isAddEdu;
    } else {
      this.isEditEdu = !this.isEditEdu;
    }
    this.profileService.changeListByUser({ isCallEdu: true, isCallJob: false });

    this.education = new Education('', '', '', '', '', 0);
  }

  clickCloseEditJob() {
    if (this.isAddJob == true) {
      this.isAddJob = !this.isAddJob;
    } else {
      this.isEditJob = !this.isEditJob;
    }
    this.profileService.changeListByUser({ isCallEdu: false, isCallJob: true });

    this.job = new Job('', '', '', '', '', 0);
  }

  clickSaveEduUser() {
    let startDate = this.education.startDate;
    let endDate = this.education.endDate;
    let dataEdu;
    dataEdu = {
      schoolId: this.education.schoolId,
      role: this.education.role,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      isShow: this.education.isShow,
    };
    if (this.isEditEdu == true) {
      dataEdu.id = this.idEduEdit;
      this.updateEducation(dataEdu);
    } else {
      this.createEducation(dataEdu);
    }
  }

  clickSaveJobUser() {
    let startDate = this.job.startDate;
    let endDate = this.job.endDate;
    let dataJobUser;
    dataJobUser = {
      companyId: this.job.companyId,
      role: this.job.role,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      isShow: this.job.isShow,
    };
    if (this.isEditJob == true && this.isAddJob == false) {
      dataJobUser.id = this.idJobEdit;
      this.updateJob(dataJobUser);
    } else {
      this.createJob(dataJobUser);
    }
  }
  initSuccessNotification(mess) {
    iziToast.success({
      title: mess,
      titleColor: '#000',
      timeout: 1000,
    });
  }

  initErrorNotification(mess) {
    iziToast.warning({
      title: mess,
      titleColor: '#000',
      timeout: 1000,
    });
  }
  createEducation(dataEdu) {
    this.profileService.createEducation(dataEdu).subscribe((result) => {
      if (result.status == 'success') {
        this.initSuccessNotification('Thông tin đã được lưu.');
      } else {
        this.initErrorNotification('Thông tin chưa được lưu.');
      }
    });
  }

  createJob(dataJobUser) {
    this.profileService.createJobByUserId(dataJobUser).subscribe((result) => {
      if (result.status == 'success') {
        this.initSuccessNotification('Thông tin đã được lưu.');
      } else {
        this.initErrorNotification('Thông tin chưa được lưu.');
      }
    });
  }

  updateEducation(dataEdu) {
    this.profileService.updateEducation(dataEdu).subscribe((result) => {
      if (result.status == 'success') {
        this.initSuccessNotification('Cập nhật thông tin thành công.');
      } else {
        this.initErrorNotification('Thông tin chưa được cập nhật.');
      }
    });
  }

  updateJob(dataJobUser) {
    this.profileService.updateJob(dataJobUser).subscribe((result) => {
      if (result.status == 'success') {
        this.initSuccessNotification('Cập nhật thông tin thành công.');
      } else {
        this.initErrorNotification('Thông tin chưa được cập nhật.');
      }
    });
  }


}
