<div class="container is-custom">
	<div id="profile-photos" class="view-wrap is-headless">
		<div class="columns is-multiline no-margin">
			<div class="column is-paddingless"></div>
		</div>
		<div class="columns">
			<div class="column">
				<div class="box-heading">
					<div
						class="
							dropdown
							photos-dropdown
							is-spaced is-neutral
							dropdown-trigger
						"
					>
						<div>
							<div class="button" (click)="openDropDown()">
								<span>All Pictures</span>
								<i-feather name="chevron-down"></i-feather>
							</div>
						</div>
						<div class="dropdown-menu" role="menu">
							<div class="dropdown-content">
								<a href="#" class="dropdown-item">
									<div class="media">
										<i-feather name="tag"></i-feather>
										<div class="media-content">
											<h3>Tagged Photos</h3>
											<small>Photos whith this user tagged.</small>
										</div>
									</div>
								</a>
								<a class="dropdown-item">
									<div class="media">
										<i-feather name="clock"></i-feather>
										<div class="media-content">
											<h3>Recent</h3>
											<small>View most recent photos.</small>
										</div>
									</div>
								</a>
								<a class="dropdown-item">
									<div class="media">
										<i-feather name="heart"></i-feather>
										<div class="media-content">
											<h3>Popular</h3>
											<small>View popular photos.</small>
										</div>
									</div>
								</a>
								<a class="dropdown-item">
									<div class="media">
										<i-feather name="book-open"></i-feather>
										<div class="media-content">
											<h3>Albums</h3>
											<small>See all albums.</small>
										</div>
									</div>
								</a>
								<hr class="dropdown-divider" />
								<a
									class="dropdown-item modal-trigger"
									data-modal="albums-modal"
								>
									<div class="media">
										<i-feather name="plus"></i-feather>
										<div class="media-content">
											<h3>Add Photos</h3>
											<small>Upload pictures.</small>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
					<div class="button-wrap">
						<button
							(click)="clickLayoutActiveId(0)"
							type="button"
							class="button"
							[ngClass]="layoutActiveId == 0 ? 'is-active' : ''"
						>
							Recent
						</button>
						<button
							(click)="clickLayoutActiveId(1)"
							type="button"
							class="button"
							[ngClass]="layoutActiveId == 1 ? 'is-active' : ''"
						>
							Albums
						</button>
					</div>
				</div>
				<div class="image-grid-wrap" *ngIf="layoutActiveId == 0">
					<div class="image-grid">
						<div class="image-row img-row-wrap">
							<ng-container>
								<ng-container *ngFor="let photo of photos | slice: 0:9">
									<div
										class="flex-1 has-background-image"
										[ngStyle]="{
											'background-image': 'url(' + photo?.photoName + ')'
										}"
									>
										<div class="overlay"></div>
										<div class="image-owner">
											<img
												class="avatar"
												[src]="photo?.userId?.avatar"
												[attr.data-demo-src]="photo?.userId?.avatar"
												alt
											/>
											<div class="name">
												{{ photo?.userId?.firstname }}
												{{ photo?.userId?.lastname }}
											</div>
										</div>
										<div class="photo-time">
											{{ photo?.createdAt | date: "MMM d, y" }}
										</div>
										<a
											class="photo-like"
											[ngClass]="photo.like ? 'is-liked' : ''"
										>
											<div class="inner" (click)="photo.like = !photo.like">
												<div class="like-overlay"></div>
												<i-feather name="heart"></i-feather>
											</div>
										</a>
									</div>
								</ng-container>
							</ng-container>
						</div>
						<div class="load-more-wrap has-text-centered">
							<a class="load-more-button"> Load More </a>
						</div>
					</div>
				</div>
				<ng-container *ngIf="layoutActiveId == 1">
					<app-profile-album [infouser]="infouser"></app-profile-album>
				</ng-container>
			</div>
		</div>
	</div>
</div>
