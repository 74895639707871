<!-- /html/partials/pages/stories/modals/video-story-modal.html -->
<div
	id="video-story-modal"
	class="modal video-story-modal is-medium has-light-bg"
>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-heading">
				<div class="small-avatar">
					<img
						class="avatar"
						[src]="infoUsers?.avatar"
						data-demo-src="assets/img/avatars/jenna.png"
						data-user-popover="0"
						alt=""
					/>
				</div>
				<h3>Video Story</h3>
				<!-- Close X button -->
				<div class="close-wrap">
					<span class="close-modal">
						<i-feather name="x"></i-feather>
					</span>
				</div>
			</div>
			<div class="card-body">
				<div class="columns">
					<div class="column is-6">
						<div class="story-meta">
							<div class="field">
								<div class="control">
									<textarea
										class="textarea is-fade"
										rows="3"
										placeholder="Describe your story"
									></textarea>
								</div>
							</div>
							<div class="field">
								<div class="control">
									<div
										placeholder="Write a comment..."
										id="tag-friends"
										class="tag-fr textarea"
										contenteditable="true"
										(input)="model = $event.target.textContent"
										(keyup)="searchUser(model)"
									></div>
								</div>
							</div>
							<div class="field">
								<div class="control buttons">
									<button class="button is-light close-modal">Cancel</button>
									<button
										class="button is-solid accent-button raised"
										(click)="createStories()"
									>
										Publish Story
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="column is-6">
						<div class="story-upload">
							<div id="preview" class="preview-block">
								<img
									id="upload-placeholder"
									class="upload-placeholder"
									[src]="defaultImg"
									alt=""
									*ngIf="!previewImg"
								/>
								<video *ngIf="previewImg" [src]="previewImg" controls></video>
							</div>
							<div class="input-block">
								<input
									id="story-upload"
									type="file"
									accept="video/mp4"
									id="file-input"
									(change)="detectFiles($event)"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
