<div id="create-member-modal" class="modal create-member-modal is-light-bg">
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-heading">
				<h3>Invite member</h3>
				<!-- Close X button -->
				<div class="close-wrap">
					<span class="close-modal">
						<i-feather name="x"></i-feather>
					</span>
				</div>
			</div>
			<!-- Modal subheading -->
			<div class="subheading">
				<!-- Group avatar -->
				<div class="group-avatar">
					<input id="group-avatar-upload" type="file" />
					<div class="add-photo">
						<i-feather name="plus"></i-feather>
					</div>
				</div>
				<!-- Group name -->
				<div class="control">
					<input
						type="text"
						class="input"
						placeholder="Give the group a name"
					/>
				</div>
			</div>
			<div class="card-body">
				<div class="inner">
					<div class="left-section">
						<div class="search-subheader">
							<div class="control">
								<input
									type="text"
									class="input"
									placeholder="Search for friends to add"
								/>
								<span class="icon">
									<i-feather name="search"></i-feather>
								</span>
							</div>
						</div>
						<div id="new-group-list" class="user-list has-slimscroll">
							<!-- Friend -->
							<ng-container
								*ngFor="let user of arrUserSuggested; let index = index"
							>
								<div class="friend-block">
									<img
										class="friend-avatar"
										[src]="user?.avatar"
										data-demo-src="assets/img/avatars/dan.jpg"
										alt
									/>
									<div class="friend-name">
										{{ user?.firstname }} {{ user?.lastname }}
									</div>
									<div class="round-checkbox is-small">
										<div>
											<input
												type="checkbox"
												[attr.id]="'checkbox-group-' + index"
												(click)="selectUser(user)"
											/>
											<label [attr.for]="'checkbox-group-' + index"></label>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="right-section has-slimscroll">
						<div class="selected-count">
							<span>Selected</span>
							<span id="selected-friends-count">{{
								arrSelectedUser?.length
							}}</span>
						</div>
						<div id="selected-list" class="selected-list">
							<ng-container
								*ngFor="let user of arrSelectedUser; let index = index"
							>
								<div id="ref-4" class="selected-friend-block">
									<div class="image-wrapper">
										<img class="friend-avatar" [src]="user?.avatar" alt="" />
										<div class="checked-badge">
											<i-feather name="check"></i-feather>
										</div>
									</div>
									<div class="friend-name">
										{{ user?.firstname }} {{ user?.lastname }}
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<button type="button" class="button is-solid grey-button close-modal">
					Cancel
				</button>
				<button
					type="button"
					class="button is-solid accent-button close-modal"
					[disabled]="arrSelectedUser.length <= 0 ? 'disabled' : null"
					(click)="sendInvite()"
				>
					Send Invites
				</button>
			</div>
		</div>
	</div>
</div>
