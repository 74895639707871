<ng-container *ngIf="infouser">
	<div class="cover-bg">
		<img
			class="cover-image"
			[src]="infouser.cover"
			data-demo-src="assets/img/demo/bg/4.png"
			alt
		/>

		<div class="avatar">
			<img
				class="avatar-image"
				[src]="infouser.avatar"
				data-demo-src="assets/img/avatars/jenna.png"
				alt
			/>
			<div
				class="avatar-button"
				(click)="isActiveAvtBtn = !isActiveAvtBtn"
				[ngClass]="isActiveAvtBtn ? 'is-active' : ''"
			>
				<i-feather name="plus"></i-feather>
			</div>
			<div
				*ngIf="isOwner"
				class="pop-button pop-shift is-center has-tooltip modal-trigger"
				[ngClass]="isActiveAvtBtn ? 'is-active' : ''"
				data-modal="change-profile-pic-modal"
				data-placement="top"
				data-title="Change avatar"
			>
				<a class="inner">
					<i-feather name="camera"></i-feather>
				</a>
			</div>
			<div
				class="pop-button is-far-left has-tooltip"
				[ngClass]="isActiveAvtBtn ? 'is-active' : ''"
				data-modal="change-profile-pic-modal"
				data-placement="left"
				data-title="Copy link"
			>
				<a class="inner">
					<i-feather name="copy"></i-feather>
				</a>
			</div>
			<div
				*ngIf="!isOwner"
				class="pop-button pop-shift is-left has-tooltip"
				[ngClass]="isActiveAvtBtn ? 'is-active' : ''"
				data-placement="left"
				data-title="Subscription"
				(click)="follow ? unfollow() : createFollow()"
			>
				<a class="inner">
					<i-feather
						class="inactive-icon"
						name="{{ follow ? 'bell-off' : 'bell' }}"
					></i-feather>
				</a>
			</div>
			<div
				*ngIf="isOwner"
				class="pop-button pop-shift is-left has-tooltip"
				[ngClass]="isActiveAvtBtn ? 'is-active' : ''"
				data-modal="change-profile-pic-modal"
				data-placement="left"
				data-title="Edit profile"
			>
				<a class="inner">
					<i-feather name="edit"></i-feather>
				</a>
			</div>
			<div
				*ngIf="!isOwner && !isFriend && !isFriendRequest"
				class="pop-button pop-shift is-center has-tooltip"
				[ngClass]="isActiveAvtBtn ? 'is-active' : ''"
				data-placement="top"
				data-title="Add friend"
				(click)="createFriendRequest()"
			>
				<a class="inner">
					<i-feather class="inactive-icon" name="plus"></i-feather>
					<i-feather class="active-icon" name="minus"></i-feather>
				</a>
			</div>
			<div
				*ngIf="!isOwner && isFriend && !isFriendRequest"
				class="pop-button pop-shift is-center has-tooltip"
				[ngClass]="isActiveAvtBtn ? 'is-active' : ''"
				data-placement="top"
				data-title="Delete friend"
				(click)="deleteFriend()"
			>
				<a class="inner">
					<i-feather class="inactive-icon" name="minus"></i-feather>
					<i-feather class="active-icon" name="minus"></i-feather>
				</a>
			</div>
			<div
				*ngIf="!isOwner && isFriendRequest && !isFriend"
				class="pop-button pop-shift is-center has-tooltip"
				[ngClass]="isActiveAvtBtn ? 'is-active' : ''"
				data-placement="top"
				data-title="Delete friend request"
				(click)="deleteFriendRequest()"
			>
				<a class="inner">
					<i-feather class="inactive-icon" name="minus"></i-feather>
					<i-feather class="active-icon" name="minus"></i-feather>
				</a>
			</div>
			<div
				*ngIf="!isOwner"
				class="pop-button is-right has-tooltip"
				[ngClass]="isActiveAvtBtn ? 'is-active' : ''"
				data-placement="right"
				data-title="Chat"
			>
				<a class="inner">
					<i-feather name="message-circle"></i-feather>
				</a>
			</div>
			<div
				*ngIf="isOwner"
				class="pop-button is-right has-tooltip"
				[ngClass]="isActiveAvtBtn ? 'is-active' : ''"
				data-placement="right"
				data-title="View"
			>
				<a class="inner">
					<i-feather name="eye"></i-feather>
				</a>
			</div>
			<div
				*ngIf="isOwner"
				class="pop-button is-far-right has-tooltip"
				[ngClass]="isActiveAvtBtn ? 'is-active' : ''"
				data-placement="right"
				data-title="Privacy"
			>
				<a class="inner">
					<i-feather name="lock"></i-feather>
				</a>
			</div>
			<div
				*ngIf="!isOwner"
				class="pop-button is-far-right has-tooltip"
				[ngClass]="isActiveAvtBtn ? 'is-active' : ''"
				data-placement="right"
				data-title="Send message"
			>
				<a href="messages-inbox.html" class="inner">
					<i-feather name="mail"></i-feather>
				</a>
			</div>
		</div>
		<div class="cover-overlay"></div>
		<div
			class="cover-edit modal-trigger"
			data-modal="change-cover-modal"
			*ngIf="isOwner"
		>
			<i class="mdi mdi-camera"></i>
			<span>Edit cover image</span>
		</div>
		<app-timeline-mobile-dropdown></app-timeline-mobile-dropdown>
	</div>
	<div class="profile-menu is-hidden-mobile">
		<div class="menu-start">
			<a
				(click)="goProfile(1)"
				class="button has-min-width"
				[ngClass]="{'is-active': profilePage == 1}"
			>
				Timeline
			</a>
			<a
				(click)="goProfile(2)"
				class="button has-min-width"
				[ngClass]="{'is-active': profilePage == 2}"
			>
				About
			</a>
		</div>
		<div class="menu-end">
			<!-- <a
				class="button has-min-width"
				[ngClass]="{'is-active': profilePage == 4}"
				*ngIf="!isOwner || isFriend == false"
				(click)="createFriendRequest()"
			>
				Add Friend
			</a> -->
			<a
				(click)="goProfile(3)"
				id="profile-friends-link"
				class="button has-min-width"
				[ngClass]="{'is-active': profilePage == 3}"
			>
				Friends
			</a>
			<a
				class="button has-min-width"
				(click)="goProfile(4)"
				[ngClass]="{'is-active': profilePage == 4}"
			>
				Photos
			</a>
		</div>
	</div>
	<div class="profile-subheader">
		<div class="subheader-start is-hidden-mobile">
			<span>3.4K</span>
			<span>Friends</span>
		</div>
		<div class="subheader-middle">
			<h2>{{ infouser.firstname }} {{ infouser.lastname }}</h2>
			<span>{{ infouser.occupation }}</span>
		</div>
		<div class="subheader-end is-hidden-mobile">
			<a class="button has-icon is-bold">
				<i-feather name="clock"></i-feather>
				History
			</a>
		</div>
	</div>
</ng-container>
