<div
	*ngIf="dataPost"
	class="custom-profile-lightbox"
	[ngClass]="isActivePhoto == true ? 'is-active' : ''"
>
	<div class="lightbox-inner">
		<div class="image-side">
			<div class="image-side-inner">
				<div class="image-wrap">
					<div class="image-loader" [ngClass]="isLoading ? '' : 'is-active'">
						<div class="loader is-loading"></div>
					</div>
					<div class="action-block">
						<a class="action-button">
							<i-feather name="tag"></i-feather>
						</a>
						<a class="action-button">
							<i-feather name="map-pin"></i-feather>
						</a>
						<a class="action-button">
							<i-feather name="arrow-down"></i-feather>
						</a>
						<a class="action-button">
							<i-feather name="x" (click)="closePopup()"></i-feather>
						</a>
					</div>

					<div
						class="one-time"
						[ngClass]="dataPost?.postImg?.length == 1 ? 'one-time-wrap' : ''"
					>
						<!-- <app-page-photo-lightbox
							*ngFor="let dataImg of dataPost?.postImg; let i = index"
							[dataImg]="dataImg"
						></app-page-photo-lightbox> -->
						<ng-container
							*ngFor="let dataImg of dataPost?.postImg; let i = index"
						>
							<img [src]="dataImg.url" alt=""
						/></ng-container>
					</div>
					<div class="prev" *ngIf="dataPost?.postImg?.length != 1">
						<button
							(click)="prev()"
							[disabled]="indexImg == 0"
							class="mdi mdi-chevron-left"
						></button>
					</div>
					<div class="next" *ngIf="dataPost?.postImg?.length != 1">
						<button
							(click)="next()"
							[disabled]="indexImg == dataPost.postImg.length - 1"
							class="mdi mdi-chevron-right"
						></button>
					</div>
				</div>
			</div>
		</div>

		<div class="sidebar-side" [ngStyle]="{top: showChatMobile ? '0' : ''}">
			<div class="comments-loader" [ngClass]="dataPost ? '' : ' is-active'">
				<div class="loader is-loading"></div>
			</div>
			<div class="header">
				<img
					*ngIf="dataPost?.postPageId"
					id="lightbox-avatar"
					[src]="
						dataPost?.postPageId?.pageAvatar
							? dataPost?.postPageId?.pageAvatar
							: imgDefault
					"
					[attr.data-demo-src]="
						dataPost?.postPageId?.pageAvatar
							? dataPost?.postPageId?.pageAvatar
							: imgDefault
					"
					alt=""
				/>
				<img
					*ngIf="dataPost?.postUserId"
					id="lightbox-avatar"
					[src]="
						dataPost?.postUserId?.avatar
							? dataPost?.postUserId?.avatar
							: imgDefault
					"
					[attr.data-demo-src]="
						dataPost?.postUserId?.avatar
							? dataPost?.postUserId?.avatar
							: imgDefault
					"
					alt=""
				/>
				<div class="user-meta">
					<a
						*ngIf="dataPost?.postPageId"
						id="lightbox-username"
						(click)="gotoPage(dataPost?.postPageId?._id)"
					>
						{{ dataPost?.postPageId?.pageName }}
					</a>
					<a
						*ngIf="dataPost?.postUserId"
						id="lightbox-username"
						(click)="gotoProfile(dataPost?.postUserId?._id)"
					>
						{{ dataPost?.postUserId?.firstname }}
						{{ dataPost?.postUserId?.lastname }}
					</a>
					<span
						><small>{{ dataPost?.createdAt | dateAgo }}</small></span
					>
				</div>
				<button
					type="button"
					class="button close-lightbox"
					(click)="closePopup()"
				>
					Close
				</button>
				<button
					type="button"
					class="button close-lightbox-mobile"
					(click)="showChatMobile = !showChatMobile"
				>
					Close
				</button>
				<div class="dropdown is-spaced is-right is-neutral dropdown-trigger">
					<div>
						<div class="button">
							<i-feather name="more-vertical"></i-feather>
						</div>
					</div>
					<div class="dropdown-menu" role="menu">
						<div class="dropdown-content">
							<a href="#" class="dropdown-item">
								<div class="media">
									<i-feather name="tag"></i-feather>
									<div class="media-content">
										<h3>Tag Friends</h3>
										<small>Tag friends in this picture.</small>
									</div>
								</div>
							</a>
							<a class="dropdown-item">
								<div class="media">
									<i-feather name="arrow-down"></i-feather>
									<div class="media-content">
										<h3>Download</h3>
										<small>Download this picture.</small>
									</div>
								</div>
							</a>
							<a class="dropdown-item">
								<div class="media">
									<i-feather name="share-2"></i-feather>
									<div class="media-content">
										<h3>Share Picture</h3>
										<small>Share this picture.</small>
									</div>
								</div>
							</a>
							<a class="dropdown-item">
								<div class="media">
									<i-feather name="message-circle"></i-feather>
									<div class="media-content">
										<h3>Send In Chat</h3>
										<small>Send in a chat message.</small>
									</div>
								</div>
							</a>
							<hr class="dropdown-divider" />
							<a class="dropdown-item">
								<div class="media">
									<i-feather name="flag"></i-feather>
									<div class="media-content">
										<h3>Report</h3>
										<small>Report for inappropriate content.</small>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>

			<div class="inner-content">
				<div class="post-caption">
					{{ dataPost?.postImg[indexImg]?.caption }}
				</div>
				<div class="live-stats">
					<div class="social-count">
						<div
							class="likes-count"
							[ngClass]="dataPost?.isLiked == true ? 'is-like-comment' : ''"
						>
							<i-feather name="heart"></i-feather>
							<span>{{ dataPost?.arrLike?.length }}</span>
						</div>
						<div class="comments-count">
							<i-feather name="message-square"></i-feather>
							<span>{{ dataPost?.dataCommentsFile?.length }}</span>
						</div>
					</div>
					<div class="social-count ml-auto">
						<div class="views-count">
							<span>{{ dataPost?.dataCommentsFile?.length }}</span>
							<span class="views"><small>comments</small></span>
						</div>
					</div>
				</div>
				<div class="actions">
					<div
						class="action"
						(click)="
							dataPost?.isLiked == true
								? unLikePost(dataPost)
								: createLikePost(dataPost)
						"
						[ngClass]="dataPost?.isLiked == true ? 'is-like' : ''"
					>
						<i-feather name="thumbs-up"></i-feather>
						<span>Like</span>
					</div>
					<div class="action">
						<i-feather name="message-square"></i-feather>
						<span>Comment</span>
					</div>
				</div>
			</div>

			<div class="comments-list has-slimscroll comment-list-wrapper">
				<div
					class="comments-heading count-cmt"
					*ngIf="
						dataPost?.dataCommentsFile?.length > 0 &&
						dataPost?.filterView?.isActive == true &&
						dataPost?.countComment >
							dataPost?.filterComment.limit + dataPost?.filterComment.skip
					"
					(click)="viewMoreComment(dataPost)"
				>
					<span
						*ngIf="dataPost?.filterView?.isViewMore == true"
						(click)="viewMoreComment(dataPost)"
						>View more Comments</span
					>
				</div>
				<ng-container
					*ngFor="
						let comment of dataPost.dataCommentsFile;
						let indexComment = index
					"
				>
					<div class="media is-comment media-wrapper">
						<div class="group-media">
							<figure class="media-left">
								<p class="image is-32x32">
									<img
										[src]="
											comment?.userId?.avatar
												? comment?.userId?.avatar
												: imgDefault
										"
										[attr.data-demo-src]="
											comment?.userId?.avatar
												? comment?.userId?.avatar
												: imgDefault
										"
										alt=""
										[attr.data-user-popover]="comment?.userId?._id"
									/>
								</p>
							</figure>
							<div class="media-content">
								<div class="username">
									{{ comment?.userId?.firstname }}
									{{ comment?.userId?.lastname }}
								</div>
								<span
									class="cmt-tags"
									[innerHTML]="comment?.commentContent | sanitizeHtmlPipe"
								></span>
								<div class="media-img">
									<img
										*ngIf="comment?.commentImg"
										[src]="comment?.commentImg ? comment?.commentImg : ''"
										alt=""
									/>
								</div>
								<div class="media-video">
									<video
										*ngIf="comment?.commentVideo"
										height="200"
										controls
										controlsList="nodownload"
									>
										<source [src]="comment?.commentVideo" type="video/mp4" />
									</video>
								</div>

								<div class="comment-actions">
									<a
										href="javascript:void(0);"
										class="is-inverted"
										(click)="
											comment?.isLiked == true
												? unlikeComment(comment, dataPost)
												: createLikeComment(comment, dataPost)
										"
										>Like</a
									>
									<span>{{ comment?.createdAt | dateAgo }}</span>
									<div class="reply">
										<a
											(click)="
												clickReplyUser(comment?.userId, comment, indexComment)
											"
											>Reply</a
										>
									</div>
									<div
										class="likes-count"
										[ngClass]="
											comment?.isLiked == true ? 'is-like-comment' : ''
										"
									>
										<i-feather name="heart"></i-feather>
										<span>{{ comment?.arrLikeComments?.length }}</span>
									</div>
								</div>
							</div>
						</div>
						<div
							class="comments-heading count-cmt"
							*ngIf="
								comment?.filterView?.isActive == true &&
								comment?.filterView?.isViewMore == true &&
								comment?.arrReplies?.length > 0
							"
							(click)="viewMoreRepliesBy(dataPost, comment)"
						>
							<span
								><i-feather name="corner-down-right"></i-feather> View
								{{ comment?.countReply - comment?.arrReplies?.length }}
								Reply</span
							>
						</div>
						<ng-container *ngIf="comment.replies" class="true-ok">
							<ng-container
								*ngFor="let reply of comment.replies; let indexReply = index"
							>
								<div class="media is-comment is-nested">
									<figure class="media-left">
										<p class="image is-32x32">
											<img
												[src]="
													reply?.userId?.avatar
														? reply?.userId?.avatar
														: imgDefault
												"
												[attr.data-demo-src]="
													reply?.userId?.avatar
														? reply?.userId?.avatar
														: imgDefault
												"
												alt=""
												[attr.data-user-popover]="reply?.userId?._id"
											/>
										</p>
									</figure>
									<div class="media-content">
										<div class="username">
											{{ reply?.userId?.firstname }}
											{{ reply?.userId?.lastname }}
										</div>
										<span
											class="cmt-tags"
											[innerHTML]="reply?.replyContent"
										></span>
										<div class="comment-actions">
											<a
												href="javascript:void(0);"
												class="is-inverted"
												(click)="
													reply?.isLikeReplies == true
														? unLikeReplies(dataPost, reply)
														: createLikeReplies(dataPost, comment, reply)
												"
												>Like</a
											>
											<span>{{ reply?.createdAt | dateAgo }}</span>
											<div class="reply">
												<a
													(click)="
														clickReplyUser(
															comment?.userId,
															comment,
															indexComment
														)
													"
													>Reply</a
												>
											</div>
											<div
												class="likes-count"
												[ngClass]="
													reply?.isLikeReplies == true ? 'is-like-comment' : ''
												"
											>
												<i-feather name="heart"></i-feather>
												<span>{{ reply?.arrLikeReplies?.length }}</span>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
						</ng-container>
					</div>
				</ng-container>
			</div>

			<div class="comment-controls">
				<div
					class="replies-wrap"
					*ngIf="dataPost?.arrRepliesTemporary?.length > 0"
				>
					<div class="content-replies-wrap">
						<span>replies</span
						><span *ngFor="let replies of dataPost?.arrRepliesTemporary"
							>{{ replies?.firstname }}{{ replies?.lastname }}</span
						>
					</div>

					<div class="clear-replies-wrap" (click)="clearReply(dataPost)">
						<i-feather name="x"></i-feather>
					</div>
				</div>

				<div class="controls-inner">
					<img
						[src]="InfoUser?.avatar ? InfoUser?.avatar : imgDefault"
						[attr.data-demo-src]="
							InfoUser?.avatar ? InfoUser?.avatar : imgDefault
						"
						alt=""
					/>
					<div class="control">
						<div
							placeholder="Write a comment..."
							id="tag-friends"
							class="tag-fr textarea"
							contenteditable="true"
							(input)="model = $event.target.textContent"
							(keyup)="searchUser(model)"
						></div>
						<div
							class="select-custome"
							*ngIf="arrUser?.length > 0 && model?.length > 0"
						>
							<div class="s-user" id="s-user-autocpl">
								<ul>
									<li
										class="is-selected"
										*ngFor="
											let user of arrUser | slice: 0:5;
											let indexs = index
										"
										(click)="tagUser(user, dataPost)"
									>
										<div class="eac-item">
											<div class="template-wrapper">
												<div class="avatar-wrapper">
													<img
														class="autocpl-avatar"
														*ngIf="user?.avatar"
														[src]="user?.avatar"
													/>
												</div>
												<div class="entry-text">
													{{ user?.firstname }} {{ user?.lastname }}
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<ul class="toolbar">
							<div class="action is-emoji">
								<i-feather name="smile"></i-feather>
							</div>
							<div class="is-active emoji"></div>
							<div class="action is-upload">
								<i-feather name="camera"></i-feather>
								<input
									type="file"
									accept="video/mp4,image/png,image/jpeg"
									(change)="detectFile($event, dataPost)"
								/>
							</div>
							<div
								*ngIf="dataPost?.arrRepliesTemporary?.length <= 0"
								class="action send-comment"
								(click)="createComment(dataPost)"
							>
								<i-feather name="send"></i-feather>
							</div>

							<div
								*ngIf="dataPost?.arrRepliesTemporary?.length > 0"
								class="action send-comment"
								(click)="createReplyUser(dataPost)"
							>
								<i-feather name="send"></i-feather>
							</div>
						</ul>
					</div>
				</div>
				<div id="cmt-image" class="cmt-img">
					<div class="cmt-img-wrapper">
						<img
							[src]="dataPost?.contentImg"
							*ngIf="dataPost?.format === 'image' && dataPost?.contentImg"
							id="cmt-img"
							alt=""
						/>
					</div>
					<div class="cmt-video">
						<video
							[src]="dataPost?.contentVideo"
							*ngIf="dataPost?.format === 'video' && dataPost?.contentVideo"
							height="200"
							controls
							controlsList="nodownload"
						></video>
					</div>
					<div class="close-img" (click)="closeImg(dataPost)">
						<i-feather name="x"></i-feather>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-mobile">
			<div class="footer-name" *ngIf="dataPost?.postPageId">
				{{ dataPost?.postPageId?.pageName }}
			</div>
			<div class="footer-name" *ngIf="dataPost?.postUserId">
				{{ dataPost?.postUserId?.firstname }}
				{{ dataPost?.postUserId?.lastname }}
			</div>
			<div class="footer-content">
				{{ dataPost?.postImg[indexImg]?.caption }}
			</div>
			<div class="footer-time">{{ dataPost?.createdAt | dateAgo }}</div>
			<div class="footer-reaction">
				<div class="heart">
					<span class="mdi mdi-thumb-up"></span>
					<span>{{ dataPost?.arrLike.length }}</span>
				</div>
				<div class="count-comment" (click)="showChatMobile = !showChatMobile">
					{{ dataPost?.dataCommentsFile?.length }} comments
				</div>
			</div>
			<div class="footer-reactbar">
				<div
					class="react-icon"
					(click)="
						dataPost?.isLiked == true
							? unLikePost(dataPost)
							: createLikePost(dataPost)
					"
					[ngClass]="dataPost?.isLiked == true ? 'is-like' : ''"
				>
					<span class="mdi mdi-heart"></span> Like
				</div>
				<div class="react-icon" (click)="showChatMobile = !showChatMobile">
					<span class="mdi mdi-comment"></span>
					Comment
				</div>
				<div class="react-icon">
					<span class="mdi mdi-share-variant"></span> Share
				</div>
			</div>
		</div>
	</div>
</div>

<app-nav-bar></app-nav-bar>
