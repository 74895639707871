import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss'],
})
export class VideosComponent implements OnInit {
  urlCurrent: string;
  constructor() {
    this.urlCurrent = document.URL;
  }
  ngOnInit(): void {
    this.checkRoute(this.urlCurrent);
  }

  idActive: number = 0;
  checkRoute(urlCurrent) {
    if (urlCurrent.search('home') != -1) {
      this.idActive = 0;
      return;
    }
    if (urlCurrent.search('player') != -1) {
      this.idActive = 1;
      return;
    }
  }
}
