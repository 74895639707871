import { Component, OnInit, Input } from '@angular/core';
import { FeedService } from 'src/app/services/feed.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProfileService } from 'src/app/services/profile.service';
import * as environment from '../../../../../environments/environment';
declare var jQuery: any;

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
})
export class ShareModalComponent implements OnInit {
  dataUsers;
  @Input() post;
  @Input() set dataUser(dataUser: any) {
    this.dataUsers = dataUser;
  }
  arrDisplayPost = [
    {
      id: 0,
      icon: 'globe',
      mediaTitle: 'Public',
      mediaContent: 'Anyone can see this publication.',
    },
    {
      id: 1,
      icon: 'users',
      mediaTitle: 'Friends',
      mediaContent: 'only friends can see this publication.',
    },
    {
      id: 2,
      icon: 'user',
      mediaTitle: 'Specific friends',
      mediaContent: "Don't show it to some friends.",
    },
    {
      id: 3,
      icon: 'lock',
      mediaTitle: 'Only me',
      mediaContent: 'Only me can see this publication.',
    },
  ];

  arrShare = [
    {
      id: 0,
      mediaTitle: 'Share in your feed',
      mediaContent: 'Share this publication on your feed.',
      channel: 'feed',
      class: 'mdi mdi-format-float-left',
    },
    {
      id: 1,
      mediaTitle: `Share in a friend's feed`,
      mediaContent: `Share this publication on a friend's feed.`,
      channel: 'friend',
      class: 'mdi mdi-account-heart',
    },
    {
      id: 2,
      mediaTitle: 'Share in a group',
      mediaContent: 'Share this publication in a group.',
      channel: 'group',
      class: 'mdi mdi-account-group',
    },
    {
      id: 3,
      mediaTitle: 'Share in a group',
      mediaContent: 'Share this publication in a page.',
      channel: 'page',
      class: 'mdi mdi-file-document-box',
    },
    {
      id: 4,
      mediaTitle: 'Share in message',
      mediaContent: 'Share this publication in a private message.',
      channel: 'private-message',
      class: 'mdi mdi-email-plus',
    },
  ];

  environment;
  constructor(
    private profileService: ProfileService,
    private feedService: FeedService,
    private notifiService: NotificationService
  ) {
    this.environment = environment.environment.apiUrl;
  }

  recevieDataPost: any;
  ngOnInit(): void {
    this.feedService.recevieSharePost.subscribe((result) => {
      if (result.status == 'success') {
        this.recevieDataPost = result.data;
        console.log(this.recevieDataPost);

        if (this.isEmpty(this.recevieDataPost.postShareContent) === false) {
          this.recevieDataPost.postShareContent.type = this.recevieDataPost.type;
          this.recevieDataPost = this.recevieDataPost.postShareContent;
        }
      }
    });
  }
  //check object null or empty
  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }

  // model request create post share
  dataPost = {
    postContent: '',
    postFeeling: '',
    postTags: [],
    postLocation: '',
    postDisplay: 0,
    postType: 2,
  };

  //#region CREATE Post share by user login
  createSharePost() {
    let reqShare = {
      postId: this.recevieDataPost._id,
      postContent: this.dataPost.postContent,
      postFeeling: this.dataPost.postFeeling,
      postTags: this.dataPost.postTags,
      postLocation: this.dataPost.postLocation,
      postDisplay: this.dataPost.postDisplay,
      postType: this.dataPost.postType,
      postShareContent: this.recevieDataPost,
    };
    this.profileService.createSharePost(reqShare).subscribe((result) => {
      if (result.status == 'success') {
        this.clearInputs();
        this.feedService.isSubmit.next({ isSubmit: true });
        if (this.dataUsers) {
          this.createNotification(
            this.dataUsers._id,
            undefined,
            0,
            'share on',
            this.recevieDataPost._id,
            false
          );
        }

        let reqShare = {
          sharePostId: this.recevieDataPost._id,
          sharePostType: this.recevieDataPost.type,
        };
        this.feedService.createShare(reqShare).subscribe((result) => {
          if (result.status == 'success') {
          }
        });
      }
    });
  }
  //#endregion

  //#region FUNCTION clear input after submit success
  clearInputs() {
    this.dataPost = {
      postContent: '',
      postFeeling: '',
      postTags: [],
      postLocation: '',
      postDisplay: 0,
      postType: 2,
    };
  }
  //#endregion

  //#region FUNCTION tag user in share post
  tagUser(user) {
    var fullname = user.firstname + user.lastname;
    (function ($) {
      $(document).ready(function () {
        //Get the user name from the autocomplete
        //empty the input for next use
        $('#share-friend-tags-autocpl').val('');
        var html = `
                        <div class="control is-spaced tag-control">
                            <div class="tags has-addons">
                                <a class="tag is-link">${fullname}</a>
                                <a class="tag is-delete is-inverted"></a>
                            </div>
                        </div>
                    `;
        $.when($('#share-modal-tag-list').append(html)).done(function () {
          //Make added tag removable
          $('.tag.is-delete').on('click', function () {
            $(this).closest('.tag-control').remove();
          });
        });
      });
    })(jQuery);
    this.dataPost.postTags.push(user._id);
    this.arrUser = undefined;
  }
  model;
  arrUser = [];
  searchUser(txtSearch) {
    this.feedService
      .searchUserShare({ search: txtSearch })
      .subscribe((result) => {
        if (result.status == 'success') {
          this.arrUser = result.data;
          let postTags = this.dataPost.postTags;
          if (postTags.length > 0) {
            for (let user of this.arrUser) {
              let indexUsered = this.dataPost.postTags.findIndex(
                (item) => item === user._id
              );
              this.arrUser.splice(indexUsered, 1);
            }
          }
          (function ($) {
            $(document).ready(function () {
              $('#eac-container-share-friend-tags-autocpl > ul')
                .css('display', 'block')
                .fadeIn(300);
            });
          })(jQuery);
        }
      });
    if (!txtSearch) {
      this.arrUser = [];
      (function ($) {
        $(document).ready(function () {
          $('#eac-container-share-friend-tags-autocpl > ul')
            .css('display', 'none')
            .fadeIn(300);
        });
      })(jQuery);
    }
  }

  //#endregion

  //#region FUNCTION select display post share
  selectDisplaySharePost(valueDisplay) {
    this.dataPost.postDisplay = valueDisplay.id;
  }
  //#endregion

  createNotification(
    notifiedUser,
    notifiedPage,
    notificationType,
    notificationContent,
    notificationPostId,
    notificationReaded
  ) {
    if (notifiedUser != this.dataUsers._id) {
      let reqNotification = {
        notifiedUser: notifiedUser,
        notifiedPage: notifiedPage,
        notificationType: notificationType,
        notificationContent: notificationContent,
        notificationPostId: notificationPostId,
        notificationReaded: notificationReaded,
      };

      this.notifiService
        .createNotification(reqNotification)
        .subscribe((resultNotification) => { });
    }
  }
}
