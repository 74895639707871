import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeedService } from 'src/app/services/feed.service';
import * as environment from '../../../../environments/environment';
@Component({
  selector: 'app-stories-widget',
  templateUrl: './stories-widget.component.html',
  styleUrls: ['./stories-widget.component.scss'],
})
export class StoriesWidgetComponent implements OnInit {
  arrStories = [];
  constructor(private feedServer: FeedService, private router: Router) {
    this.environment = environment.environment.apiUrl;
  }
  environment;
  ngOnInit(): void {
    let filter = {
      skip: 0,
      limit: 3,
    };
    this.getStories(filter);
    this.feedServer.changeSubmit.subscribe((result) => {
      if (result.isStories == true) {
        this.getStories(filter);
      }
    });
  }

  getStories(filter) {
    let reqStories = {
      skip: filter.skip,
      limit: filter.limit,
    };
    this.feedServer.getStory(reqStories).subscribe((result) => {
      if (result.status == 'success') {
        this.arrStories = result.data;
      }
    });
  }

  //#region go to stories
  goToStories() {
    this.router.navigateByUrl('stories');
  }
  //#endregion
}
