import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import * as environment from 'src/environments/environment';
import iziToast from 'iziToast';
@Component({
  selector: 'app-friend-requests-dropdown',
  templateUrl: './friend-requests-dropdown.component.html',
  styleUrls: ['./friend-requests-dropdown.component.scss'],
})
export class FriendRequestsDropdownComponent implements OnInit {
  constructor(private profileService: ProfileService) {}
  friendRequests;
  environment = environment.environment.apiUrl;
  ngOnInit(): void {
    this.getFriendRequests();
  }

  getFriendRequests() {
    this.profileService.getFriendsRequest().subscribe((result) => {
      this.friendRequests = result['data'];
    });
  }

  createFriend(request) {
    let data = {
      senderId: request.senderId._id,
    };
    this.profileService.friendAccept(data).subscribe((result) => {
      iziToast.show({
        class: 'success-toast',
        icon: 'mdi mdi-send',
        title: '',
        message: `You are now friend with <b>${request.senderId.firstname} ${request.senderId.lastname}</b>`,
        titleColor: '#fff',
        messageColor: '#fff',
        iconColor: '#fff',
        backgroundColor: '#7F00FF',
        progressBarColor: '#b975ff',
        position: 'bottomRight',
        transitionIn: 'fadeInUp',
        close: false,
        timeout: 2500,
        zindex: 99999,
      });
      request.friend = true;
    });
  }
  deleteFriend(request) {
    this.profileService.deleteRequestFriend(request._id).subscribe((result) => {
      this.friendRequests.splice(
        this.friendRequests.findIndex((i) => {
          return i._id == request._id;
        }),
        1
      );
    });
  }
}
