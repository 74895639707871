<ng-container *ngIf="dataPage">
	<div class="cover-bg">
		<img
			class="cover-image"
			[src]="
				dataPage?.pageCover ?  dataPage?.pageCover : coverDefault
			"
			data-demo-src="assets/img/demo/bg/4.jpg"
			alt
		/>
		<div class="avatar">
			<img
				id="user-avatar"
				class="avatar-image"
				[src]="
					dataPage?.pageAvatar
						?  dataPage?.pageAvatar
						: avatarDefault
				"
				data-demo-src="assets/img/icons/logos/fastpizza.svg"
				alt
			/>
			<div
				class="avatar-button"
				[ngClass]="{'is-active': isActiveBtn}"
				(click)="openMenuPlus()"
			>
				<i-feather name="plus"></i-feather>
			</div>
			<div
				class="pop-button is-far-left has-tooltip modal-trigger"
				data-modal="change-profile-pic-modal"
				data-placement="right"
				data-title="Change profile picture"
				[ngClass]="isActiveBtn ? 'is-active' : ''"
				*ngIf="isAdminActive"
				(click)="initModals()"
			>
				<a class="inner">
					<i-feather name="camera"></i-feather>
				</a>
			</div>
			<div
				*ngIf="!isAdminActive"
				id="follow-pop"
				class="pop-button pop-shift is-left has-tooltip"
				data-placement="top"
				data-title="Subscription"
				[ngClass]="{'is-active': isActiveBtn}"
			>
				<a class="inner">
					<i-feather class="inactive-icon" name="bell"></i-feather>
					<i-feather class="active-icon" name="bell-off"></i-feather>
				</a>
			</div>
			<div
				id="invite-pop"
				class="pop-button pop-shift is-center has-tooltip"
				data-placement="top"
				data-title="like"
				[ngClass]="{
					'is-active': isActiveBtn,
					'is-shifted': isLikeActive
				}"
				(click)="isLikeActive == true ? unLikePage() : createLikePage()"
			>
				<a class="inner">
					<i-feather class="inactive-icon" name="plus"></i-feather>
					<i-feather class="active-icon" name="minus"></i-feather>
				</a>
			</div>
			<div
				*ngIf="!isAdminActive"
				id="chat-pop"
				class="pop-button is-right has-tooltip"
				data-placement="top"
				data-title="Chat"
				[ngClass]="isActiveBtn ? 'is-active' : ''"
			>
				<a class="inner">
					<i-feather name="message-square"></i-feather>
				</a>
			</div>
			<div
				*ngIf="!isAdminActive"
				class="pop-button is-far-right has-tooltip"
				data-placement="right"
				data-title="Send message"
				[ngClass]="isActiveBtn ? 'is-active' : ''"
			>
				<a class="inner">
					<i-feather name="mail"></i-feather>
				</a>
			</div>
		</div>
		<div class="cover-overlay"></div>
		<div
			(click)="loadModal()"
			class="cover-edit modal-trigger"
			data-modal="change-cover-modal"
			*ngIf="isAdminActive"
		>
			<i class="mdi mdi-camera"></i>
			<span>Edit cover image</span>
		</div>
		<!-- /html/partials/pages/pages/dropdowns/page-timeline-mobile-dropdown.html -->
		<div
			class="dropdown is-spaced is-right is-accent dropdown-trigger timeline-mobile-dropdown is-hidden-desktop"
		>
			<div>
				<div class="button">
					<i-feather name="more-vertical"></i-feather>
				</div>
			</div>
			<div class="dropdown-menu" role="menu">
				<div class="dropdown-content">
					<a href="/pages-main.html" class="dropdown-item">
						<div class="media">
							<i-feather name="activity"></i-feather>
							<div class="media-content">
								<h3>Timeline</h3>
								<small>Open Timeline.</small>
							</div>
						</div>
					</a>
					<a href="/pages-about.html" class="dropdown-item">
						<div class="media">
							<i-feather name="align-right"></i-feather>
							<div class="media-content">
								<h3>About</h3>
								<small>See about info.</small>
							</div>
						</div>
					</a>
					<a href="/pages-community.html" class="dropdown-item">
						<div class="media">
							<i-feather name="globe"></i-feather>
							<div class="media-content">
								<h3>Community</h3>
								<small>See community.</small>
							</div>
						</div>
					</a>
					<a href="/pages-photos.html" class="dropdown-item">
						<div class="media">
							<i-feather name="image"></i-feather>
							<div class="media-content">
								<h3>Photos</h3>
								<small>See all photos.</small>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>

	<div class="profile-menu is-hidden-mobile">
		<div class="menu-start">
			<a
				class="button has-min-width"
				[ngClass]="{'is-active': isPageActive == 1}"
				(click)="clickActivePage(1)"
				>Timeline</a
			>
			<a
				class="button has-min-width"
				[ngClass]="{'is-active': isPageActive == 3}"
				(click)="clickActivePage(3)"
				>About</a
			>
		</div>
		<div class="menu-end">
			<a
				class="button has-min-width"
				[ngClass]="{'is-active': isPageActive == 2}"
				(click)="clickActivePage(2)"
				>Community</a
			>
			<a
				class="button has-min-width"
				[ngClass]="{'is-active': isPageActive == 4}"
				(click)="clickActivePage(4)"
				>Photos</a
			>
		</div>
	</div>

	<div class="profile-subheader">
		<div class="subheader-start is-hidden-mobile">
			<span>{{ countFL }}</span>
			<span>Followers</span>
		</div>
		<div class="subheader-middle">
			<h2>{{ dataPage?.pageName }}</h2>
			<span>{{ dataPage?.pageTitle }}</span>
		</div>
		<div class="subheader-end is-hidden-mobile">
			<a class="button has-icon is-bold">
				<i-feather name="clock"></i-feather>
				History
			</a>
		</div>
	</div>
</ng-container>
