import {
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import { WebSocketService } from "src/app/services/web-socket.service";
import * as environment from "src/environments/environment";
import { ProfileService } from "src/app/services/profile.service";
import { MessageService } from "src/app/services/message.service";
import jwt_decode from "jwt-decode";
@Component({
	selector: "app-chat",
	templateUrl: "./chat.component.html",
	styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit {
	friends;
	tokenInfo;
	filterMessages = [];
	infoFriend;
	selectedMessages: any;
	showDetails = false;
	@Output() onClickCloseChat = new EventEmitter<void>();
	constructor(
		private webSocket: WebSocketService,
		private profileService: ProfileService,
		private messageService: MessageService
	) { }

	ngOnInit(): void {
		this.webSocket.listen("new_message").subscribe((result: any) => {
			for (var i in this.friends) {
				var friend = this.friends[i];
				if (result.senderId === friend.senderId._id || result.senderId === friend.receiverId._id) {
					friend.unread = true;
					friend.countUnread++
					friend.messages.push(result);
				}
			}
			console.log(this.friends);

		});

		this.getAllFriends();
		let token = localStorage.getItem("token");
		if (token) {
			this.getInfoUser(token);
		}
		if (window.innerWidth <= 1023) {
			this.listExpanded = false;
		}
	}


	environment = environment.environment.apiUrl;
	friend;
	listExpanded = true;
	changeToViewFullListDetail() {
		this.listExpanded = !this.listExpanded;
	}

	getDecodedAccessToken(token: string): any {
		try {
			return jwt_decode(token);
		} catch (Error) {
			return null;
		}
	}

	selectedFriendChanged(infoFriend) {
		if (this.tokenInfo._id == infoFriend.senderId._id) {
			this.infoFriend = infoFriend.receiverId
			this.messageService.updateMessage({ senderId: infoFriend.receiverId._id }).subscribe()
		} else {
			this.infoFriend = infoFriend.senderId
			this.messageService.updateMessage({ senderId: infoFriend.senderId._id }).subscribe()
		}
		console.log(infoFriend);

		infoFriend.countUnread = 0;
		infoFriend.unread = false;
		this.selectedMessages = infoFriend.messages;
	}

	getInfoUser(token) {
		this.tokenInfo = this.getDecodedAccessToken(token);
	}
	async getAllFriends() {
		let friends = await this.profileService.getFriends().toPromise();
		let messages = await this.messageService.getAllMessages().toPromise();

		this.filterMessages = messages.filter((v, i) =>
			messages.findIndex(
				(item) =>
					((item._id.senderId === v._id.receiverId &&
						item._id.receiverId === v._id.senderId) ||
						(item._id.receiverId === v._id.senderId &&
							item._id.senderId === v._id.receiverId)) == i
			)
		);
		this.friends = friends.data;
		for (let friend of this.friends) {
			if (friend.senderId._id == this.tokenInfo._id) {
				friend.friendavatar = friend.receiverId.avatar;
			} else {
				friend.friendavatar = friend.senderId.avatar;
			}
			for (let message of messages) {
				if (
					(message._id.senderId == friend.senderId._id &&
						message._id.receiverId == friend.receiverId._id) ||
					(message._id.receiverId == friend.senderId._id &&
						message._id.senderId == friend.receiverId._id)
				) {
					friend.messages = message.messages.reverse();
				}
			}
		}
		for (let friend of this.friends) {
			friend.countUnread = 0;
			friend.unread = false;
			for (let message of friend.messages) {
				if (message.receiverId == this.tokenInfo._id) {
					if (message.read == false) {
						friend.countUnread++;
					}
				}
			}
			if (friend.countUnread > 0) {
				friend.unread = true;
			}
		}
	}
}
