<div class="custom-profile-lightbox">
    <div class="lightbox-inner">
        <div class="image-side">
            <div class="image-side-inner">
                <div class="image-wrap">
                    <div class="image-loader is-active">
                        <div class="loader is-loading"></div>
                    </div>
                    <div class="meta-overlay"></div>
                    <div class="meta-block">
                        <h3>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Frater et
              T. Duo Reges: constructio interrete. Summae mihi videtur
              inscitiae. Esse enim, nisi eris, non potes.
                        </h3>
                        <p>
                            Uploaded on
                            <var id="lightbox-time"></var>
                        </p>
                    </div>
                    <div class="action-block">
                        <a class="action-button">
                            <i-feather name="tag"></i-feather>
                        </a>
                        <a class="action-button">
                            <i-feather name="map-pin"></i-feather>
                        </a>
                        <a class="action-button">
                            <i-feather name="arrow-down"></i-feather>
                        </a>
                        <a class="action-button">
                            <i-feather name="more-vertical"></i-feather>
                        </a>
                    </div>
                    <img
                        id="lightbox-image"
                        src="https://via.placeholder.com/1600x900"
                        data-demo-src="assets/img/demo/unsplash/3.jpg"
                        alt
                    >
                </div>
            </div>
        </div>






        <div class="sidebar-side">
            <div class="comments-loader is-active">
                <div class="loader is-loading"></div>
            </div>
            <div class="header">
                <img
                    id="lightbox-avatar"
                    src="https://via.placeholder.com/300x300"
                    data-demo-src="assets/img/avatars/dan.jpg"
                    alt
                >
                <div class="user-meta">
                    <span id="lightbox-username">Dan Walker</span>
                    <span>
                        <small>2 hours ago</small>
                    </span>
                </div>
                <button
                    type="button"
                    class="button close-lightbox"
                >
                    Close
                </button>
                <div class="dropdown is-spaced is-right is-neutral dropdown-trigger">
                    <div>
                        <div class="button">
                            <i-feather name="more-vertical"></i-feather>
                        </div>
                    </div>
                    <div
                        class="dropdown-menu"
                        role="menu"
                    >
                        <div class="dropdown-content">
                            <a
                                href="#"
                                class="dropdown-item"
                            >
                                <div class="media">
                                    <i-feather name="tag"></i-feather>
                                    <div class="media-content">
                                        <h3>Tag Friends</h3>
                                        <small>Tag friends in this picture.</small>
                                    </div>
                                </div>
                            </a>
                            <a class="dropdown-item">
                                <div class="media">
                                    <i-feather name="arrow-down"></i-feather>
                                    <div class="media-content">
                                        <h3>Download</h3>
                                        <small>Download this picture.</small>
                                    </div>
                                </div>
                            </a>
                            <a class="dropdown-item">
                                <div class="media">
                                    <i-feather name="share-2"></i-feather>
                                    <div class="media-content">
                                        <h3>Share Picture</h3>
                                        <small>Share this picture.</small>
                                    </div>
                                </div>
                            </a>
                            <a class="dropdown-item">
                                <div class="media">
                                    <i-feather name="message-circle"></i-feather>
                                    <div class="media-content">
                                        <h3>Send In Chat</h3>
                                        <small>Send in a chat message.</small>
                                    </div>
                                </div>
                            </a>
                            <hr class="dropdown-divider">
                            <a class="dropdown-item">
                                <div class="media">
                                    <i-feather name="flag"></i-feather>
                                    <div class="media-content">
                                        <h3>Report</h3>
                                        <small>Report for inappropriate content.</small>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>






            <div class="inner-content">
                <div class="live-stats">
                    <div class="social-count">
                        <div class="likes-count">
                            <i-feather name="heart"></i-feather>
                            <span>12</span>
                        </div>
                        <div class="comments-count">
                            <i-feather name="message-square"></i-feather>
                            <span>8</span>
                        </div>
                    </div>
                    <div class="social-count ml-auto">
                        <div class="views-count">
                            <span>8</span>
                            <span class="views">
                                <small>comments</small>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="actions">
                    <div class="action">
                        <i-feather name="thumbs-up"></i-feather>
                        <span>Like</span>
                    </div>
                    <div class="action">
                        <i-feather name="message-square"></i-feather>
                        <span>Comment</span>
                    </div>
                </div>
            </div>






            <div class="comments-list has-slimscroll">
                <div class="media is-comment">
                    <figure class="media-left">
                        <p class="image is-32x32">
                            <img
                                src="https://via.placeholder.com/300x300"
                                data-demo-src="assets/img/avatars/dan.jpg"
                                alt
                                
                            >
                        </p>
                    </figure>
                    <div class="media-content">
                        <div class="username">Dan Walker</div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              ornare magna eros.
                        </p>
                        <div class="comment-actions">
                            <a
                                href="javascript:void(0);"
                                class="is-inverted"
                            >
                                Like
                            </a>
                            <span>28m</span>
                            <div class="likes-count">
                                <i-feather name="heart"></i-feather>
                                <span>2</span>
                            </div>
                        </div>
                    </div>
                </div>






                <div class="media is-comment is-nested">
                    <figure class="media-left">
                        <p class="image is-32x32">
                            <img
                                src="https://via.placeholder.com/300x300"
                                data-demo-src="assets/img/avatars/david.jpg"
                                alt
                                data-user-popover="4"
                            >
                        </p>
                    </figure>
                    <div class="media-content">
                        <div class="username">David Kim</div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing.
                        </p>
                        <div class="comment-actions">
                            <a
                                href="javascript:void(0);"
                                class="is-inverted"
                            >
                                Like
                            </a>
                            <span>15m</span>
                            <div class="likes-count">
                                <i-feather name="heart"></i-feather>
                                <span>0</span>
                            </div>
                        </div>
                    </div>
                </div>






                <div class="media is-comment">
                    <figure class="media-left">
                        <p class="image is-32x32">
                            <img
                                src="https://via.placeholder.com/300x300"
                                data-demo-src="assets/img/avatars/rolf.jpg"
                                alt
                                data-user-popover="17"
                            >
                        </p>
                    </figure>
                    <div class="media-content">
                        <div class="username">Rolf Krupp</div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              ornare magna eros. Consectetur adipiscing elit. Proin ornare magna
              eros.
                        </p>
                        <div class="comment-actions">
                            <a
                                href="javascript:void(0);"
                                class="is-inverted"
                            >
                                Like
                            </a>
                            <span>9h</span>
                            <div class="likes-count">
                                <i-feather name="heart"></i-feather>
                                <span>1</span>
                            </div>
                        </div>
                    </div>
                </div>






                <div class="media is-comment is-nested">
                    <figure class="media-left">
                        <p class="image is-32x32">
                            <img
                                src="https://via.placeholder.com/300x300"
                                data-demo-src="assets/img/avatars/elise.jpg"
                                alt
                                data-user-popover="6"
                            >
                        </p>
                    </figure>
                    <div class="media-content">
                        <div class="username">Elise Walker</div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              ornare magna eros.
                        </p>
                        <div class="comment-actions">
                            <a
                                href="javascript:void(0);"
                                class="is-inverted"
                            >
                                Like
                            </a>
                            <span>8h</span>
                            <div class="likes-count">
                                <i-feather name="heart"></i-feather>
                                <span>4</span>
                            </div>
                        </div>
                    </div>
                </div>






                <div class="media is-comment is-nested">
                    <figure class="media-left">
                        <p class="image is-32x32">
                            <img
                                src="https://via.placeholder.com/300x300"
                                data-demo-src="assets/img/avatars/rolf.jpg"
                                alt
                                data-user-popover="17"
                            >
                        </p>
                    </figure>
                    <div class="media-content">
                        <div class="username">Rolf Krupp</div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              ornare magna eros.
                        </p>
                        <div class="comment-actions">
                            <a
                                href="javascript:void(0);"
                                class="is-inverted"
                            >
                                Like
                            </a>
                            <span>7h</span>
                            <div class="likes-count">
                                <i-feather name="heart"></i-feather>
                                <span>2</span>
                            </div>
                        </div>
                    </div>
                </div>






                <div class="media is-comment is-nested">
                    <figure class="media-left">
                        <p class="image is-32x32">
                            <img
                                src="https://via.placeholder.com/300x300"
                                data-demo-src="assets/img/avatars/elise.jpg"
                                alt
                                data-user-popover="6"
                            >
                        </p>
                    </figure>
                    <div class="media-content">
                        <div class="username">Elise Walker</div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              ornare magna eros.
                        </p>
                        <div class="comment-actions">
                            <a
                                href="javascript:void(0);"
                                class="is-inverted"
                            >
                                Like
                            </a>
                            <span>6h</span>
                            <div class="likes-count">
                                <i-feather name="heart"></i-feather>
                                <span>4</span>
                            </div>
                        </div>
                    </div>
                </div>






                <div class="media is-comment">
                    <figure class="media-left">
                        <p class="image is-32x32">
                            <img
                                src="https://via.placeholder.com/300x300"
                                data-demo-src="assets/img/avatars/lana.jpeg"
                                alt
                                data-user-popover="14"
                            >
                        </p>
                    </figure>
                    <div class="media-content">
                        <div class="username">Lana Henrikssen</div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              ornare magna eros. Consectetur adipiscing elit.
                        </p>
                        <div class="comment-actions">
                            <a
                                href="javascript:void(0);"
                                class="is-inverted"
                            >
                                Like
                            </a>
                            <span>10h</span>
                            <div class="likes-count">
                                <i-feather name="heart"></i-feather>
                                <span>7</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






            <div class="comment-controls">
                <div class="controls-inner">
                    <img
                        src="https://via.placeholder.com/300x300"
                        data-demo-src="assets/img/avatars/jenna.png"
                        alt
                    >
                    <div class="control">
                        <textarea
                            class="textarea comment-textarea is-rounded"
                            rows="1"
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
