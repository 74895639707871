<div
    id="albums-help-modal"
    class="modal albums-help-modal is-xsmall has-light-bg"
>
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="card">
            <div class="card-heading">
                <h3>Add Photos</h3>
                <!-- Close X button -->
                <div class="close-wrap">
                    <span class="close-modal">
                        <i-feather name="x"></i-feather>
                    </span>
                </div>
            </div>
            <div class="card-body">
                <div class="content-block is-active">
                    <img
                        src="assets/img/illustrations/cards/albums.svg"
                        alt
                    >
                    <div class="help-text">
                        <h3>Manage your photos</h3>
                        <p>
                            Lorem ipsum sit dolor amet is a dummy text used by the typography industry and the web industry.
                        </p>
                    </div>
                </div>
                <div class="content-block">
                    <img
                        src="assets/img/illustrations/cards/upload.svg"
                        alt
                    >
                    <div class="help-text">
                        <h3>Upload your photos</h3>
                        <p>
                            Lorem ipsum sit dolor amet is a dummy text used by the typography industry and the web industry.
                        </p>
                    </div>
                </div>
                <div class="slide-dots">
                    <div class="dot is-active"></div>
                    <div class="dot"></div>
                </div>
                <div class="action">
                    <button
                        type="button"
                        class="button is-solid accent-button next-modal raised"
                        data-modal="albums-modal"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
