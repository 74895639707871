<div class="columns">
  <div class="column is-8">
    <div class="card page-about-card gr-ab">
      <div class="card-gr-title txt-title">
        <span>Members</span>
        <span class="title-members">{{ countMember }}</span>
      </div>
      <div class="gr-ab-wrap txt-cont">
        <span>New people and Pages who join this group will appear here.</span>
      </div>
      <div class="gr-search">
        <div class="search-member">
          <input
            type="text"
            [(ngModel)]="keySearch"
            placeholder="Find a member"
            (ngModelChange)="searchMember(keySearch)"
          />
          <div class="search-icon">
            <i-feather name="search"></i-feather>
          </div>
        </div>
      </div>
      <div class="about-hr"></div>
      <div class="about-body">
        <div class="about-block">
          <div class="block-hr"></div>
          <div class="block-content">
            <div
              class="gr-title txt-title"
              *ngIf="arrAdmin?.length > 0 && arrMemberSearch?.length <= 0"
            >
              <span
                >Admins<span>&nbsp;</span>&<span>&nbsp;</span>moderators
                <span>&nbsp;</span><span aria-hidden="true"> · </span>
                <span>&nbsp;</span>{{ countAdmin }}</span
              >
            </div>

            <div
              class="gr-member-wrap"
              *ngIf="arrAdmin?.length > 0 && arrMemberSearch?.length <= 0"
            >
              <ng-container *ngFor="let admin of arrAdmin; let index = index">
                <div class="wrap-member">
                  <div class="member-avatar">
                    <img [src]="admin?.groupAdmin?.avatar" alt="" />
                  </div>
                  <div class="member-cont">
                    <div class="cont-wrap">
                      <div class="cont-wrap-name">
                        <a [href]="'profile/' + admin?.groupAdmin?._id">
                          {{ admin?.groupAdmin?.firstname }}
                          {{ admin?.groupAdmin?.lastname }}</a
                        >
                      </div>
                      <div class="cont-wrap-position">
                        <i-feather
                          name="shield"
                          *ngIf="admin?.isAdmin == true"
                        ></i-feather>
                        <span class="wrap-position">{{
                          admin?.memberPosition
                        }}</span>
                      </div>
                    </div>
                    <div class="cont-btn">
                      <div
                        class="btn-managing"
                        (click)="openModalManaging(admin)"
                        *ngIf="userId != admin?.groupAdmin?._id && isHighAdmin"
                      >
                        <i-feather name="more-horizontal"></i-feather>
                      </div>
                      <div class="modal-managing" *ngIf="admin?.isModalActive">
                        <div class="header-modal"></div>
                        <div class="body-modal">
                          <div class="modal-permission">
                            <div
                              class="permission-item modal-trigger"
                              data-modal="modal-confirm"
                              (click)="conversionHighAdmin(admin)"
                            >
                              Conversion high admin
                            </div>
                            <div
                              class="permission-item modal-trigger"
                              data-modal="modal-confirm"
                              (click)="cancelAdmin(admin)"
                            >
                              Cancel admin rights
                            </div>
                            <div
                              class="permission-item modal-trigger"
                              data-modal="modal-confirm"
                              (click)="openConfirm(1, admin)"
                            >
                              Block member
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="about-footer">
              <div
                class="btn-see"
                (click)="getMore(0, 20)"
                *ngIf="arrAdmin?.length > 0 && arrMemberSearch?.length <= 0"
              >
                <span>See All</span>
              </div>
            </div>
            <div
              class="about-hr"
              *ngIf="arrAdmin?.length > 0 && arrMemberSearch?.length <= 0"
            ></div>
            <div
              class="gr-title txt-title is-pd10"
              *ngIf="arrMember?.length > 0 && arrMemberSearch?.length <= 0"
            >
              <span
                >Members<span>&nbsp;</span> <span>&nbsp;</span
                ><span aria-hidden="true"> · </span> <span>&nbsp;</span
                >{{ countMember }}</span
              >
            </div>
            <div
              class="gr-member-wrap"
              *ngIf="arrMember?.length > 0 && arrMemberSearch?.length <= 0"
            >
              <ng-container *ngFor="let member of arrMember; let index = index">
                <div class="wrap-member">
                  <div class="member-avatar">
                    <img [src]="member?.memberId?.avatar" alt="" />
                  </div>
                  <div class="member-cont">
                    <div class="cont-wrap">
                      <div class="cont-wrap-name">
                        <a [href]="'profile/' + member?.memberId?._id">
                          {{ member?.memberId?.firstname }}
                          {{ member?.memberId?.lastname }}</a
                        >
                      </div>
                      <div class="cont-wrap-position">
                        <i-feather
                          name="shield"
                          *ngIf="member?.isAdmin == true"
                        ></i-feather>
                        <span class="wrap-position">{{
                          member?.memberPosition
                        }}</span>
                      </div>
                    </div>
                    <div class="cont-btn">
                      <div class="add-friend">
                        <div
                          class="btn-add-friend"
                          *ngIf="
                            member.memberId._id !== userId &&
                            !member.isFriend &&
                            !member.sendedFriendRequest
                          "
                          (click)="addFriendMember(member)"
                        >
                          <i-feather name="plus"></i-feather>
                          Add friend
                        </div>
                        <div
                          class="btn-add-friend"
                          *ngIf="
                            member.memberId._id !== userId &&
                            !member.isFriend &&
                            member.sendedFriendRequest
                          "
                          (click)="cancelFriendRequest(member)"
                        >
                          Cancel
                        </div>
                      </div>

                      <div
                        class="btn-managing"
                        (click)="openModalManaging(member)"
                        *ngIf="
                          isHighAdmin ||
                          isAdmin ||
                          member.memberId._id == userId
                        "
                      >
                        <i-feather name="more-horizontal"></i-feather>
                      </div>
                      <div class="modal-managing" *ngIf="member?.isModalActive">
                        <div class="header-modal"></div>
                        <div class="body-modal">
                          <div class="modal-permission">
                            <div
                              class="permission-item modal-trigger"
                              data-modal="modal-confirm"
                              (click)="openConfirm(1, member)"
                              *ngIf="isHighAdmin || isAdmin"
                            >
                              Block member
                            </div>
                            <div
                              class="permission-item modal-trigger"
                              data-modal="modal-confirm"
                              (click)="openConfirm(2, member)"
                              *ngIf="member?.isAdmin == false && isHighAdmin"
                            >
                              Make admin
                            </div>
                          </div>
                        </div>
                        <div class="footer-modal">
                          <div
                            class="permission-item modal-trigger"
                            data-modal="modal-confirm"
                            (click)="openConfirm(3, member)"
                            *ngIf="isHighAdmin || isAdmin"
                          >
                            Remove member
                          </div>
                        </div>
                        <div class="footer-modal">
                          <div
                            class="permission-item modal-trigger"
                            data-modal="modal-confirm"
                            *ngIf="
                              member.memberId._id == userId && !isHighAdmin
                            "
                            (click)="confirmLeaveGroup(member)"
                          >
                            Leave group
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div
              class="about-footer"
              *ngIf="arrMember?.length > 0 && arrMemberSearch?.length <= 0"
            >
              <div class="btn-see" (click)="getMore(0, 20)">
                <span>See All</span>
              </div>
            </div>
            <div
              class="about-hr"
              *ngIf="arrMember?.length > 0 && arrMemberSearch?.length <= 0"
            ></div>
            <div
              class="gr-title txt-title is-pd10"
              *ngIf="arrMemberSearch?.length > 0"
            >
              <span>Search results</span>
            </div>
            <div class="gr-member-wrap" *ngIf="arrMemberSearch?.length > 0">
              <ng-container
                *ngFor="let member of arrMemberSearch; let index = index"
              >
                <div class="wrap-member">
                  <div class="member-avatar">
                    <img
                      [src]="member?.member?.avatar"
                      [alt]="member?.member?.avatar"
                      [srcset]="member?.member?.avatar"
                    />
                  </div>
                  <div class="member-cont">
                    <div class="cont-wrap">
                      <div class="cont-wrap-name">
                        <a [href]="'profile/' + member?.memberId">
                          {{ member?.member?.firstname }}
                          {{ member?.member?.lastname }}</a
                        >
                      </div>
                      <div class="cont-wrap-position">
                        <i-feather
                          name="shield"
                          *ngIf="member?.isAdmin == true"
                        ></i-feather>
                        <span class="wrap-position">{{
                          member?.memberPosition
                        }}</span>
                      </div>
                    </div>
                    <div class="cont-btn">
                      <div
                        class="btn-managing"
                        (click)="openModalManaging(member)"
                      >
                        <i-feather name="more-horizontal"></i-feather>
                      </div>
                      <div class="modal-managing" *ngIf="member?.isModalActive">
                        <div class="header-modal"></div>
                        <div class="body-modal">
                          <div class="modal-permission">
                            <div
                              class="permission-item modal-trigger"
                              data-modal="modal-confirm"
                              (click)="openConfirm(1, member)"
                            >
                              Block member
                            </div>
                            <div
                              class="permission-item modal-trigger"
                              data-modal="modal-confirm"
                              (click)="openConfirm(2, member)"
                              *ngIf="member?.isAdmin == false"
                            >
                              Make admin
                            </div>
                          </div>
                        </div>
                        <div class="footer-modal">
                          <div
                            class="permission-item modal-trigger"
                            data-modal="modal-confirm"
                            (click)="openConfirm(3, member)"
                          >
                            Remove member
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="about-hr" *ngIf="arrMemberSearch?.length > 0"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-confirm
  [isModalConfirm]="isModalConfirm"
  [isBlock]="isBlock"
  [isMakeAdmin]="isMakeAdmin"
  [isRemoveMember]="isRemoveMember"
  [dataMember]="dataMember"
  [dataGroup]="dataGroups"
  (isSubmit)="callFunction($event)"
  (isClose)="closeModal($event)"
></app-modal-confirm>
