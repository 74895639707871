<div class="dropdown is-spaced is-accent is-right dropdown-trigger">
    <div>
        <div class="button">
            <i-feather name="more-vertical"></i-feather>
        </div>
    </div>
    <div
        class="dropdown-menu"
        role="menu"
    >
        <div class="dropdown-content">
            <a
                href="#"
                class="dropdown-item"
            >
                <div class="media">
                    <i-feather name="life-buoy"></i-feather>
                    <div class="media-content">
                        <h3>Help Center</h3>
                        <small>Access user guides and FAQs.</small>
                    </div>
                </div>
            </a>
            <a class="dropdown-item">
                <div class="media">
                    <i-feather name="headphones"></i-feather>
                    <div class="media-content">
                        <h3>Talk</h3>
                        <small>Call the support team.</small>
                    </div>
                </div>
            </a>
            <hr class="dropdown-divider">
            <a
                href="#"
                class="dropdown-item"
            >
                <div class="media">
                    <i-feather name="x"></i-feather>
                    <div class="media-content">
                        <h3>Hide</h3>
                        <small>I don't want to see this stuff anymore.</small>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
