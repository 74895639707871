import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

declare var jQuery: any;
@Component({
  selector: 'app-questions-nav',
  templateUrl: './questions-nav.component.html',
  styleUrls: ['./questions-nav.component.scss'],
})
export class QuestionsNavComponent implements OnInit {
  @Output() pageActive = new EventEmitter<any>();

  @Input() set isActive(isActive: any) {
    this.isActives = isActive;
  }
  constructor() { }
  isQuestion: boolean = false;
  pageQuestionId: number = 0;

  ngOnInit(): void {

  }

  clickPageQuestion(id) {
    this.pageQuestionId = id;
    this.pageActive.emit({ pageClick: this.pageQuestionId });
  }
  isActives: number = 0;


}
