import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(obj): Observable<any> {
    return this.http.post(`user/login`, obj);
  }
  register(obj): Observable<any> {
    return this.http.post(`user/create`, obj);
  }
  logout(): void {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
  }

  checkEmail(obj): Observable<any> {
    return this.http.post(`user/search-email`, obj);
  }
  checkEmailForgot(obj): Observable<any> {
    return this.http.post(`user/forgotPassword`, obj);
  }

  checkCodeVerify(obj): Observable<any> {
    return this.http.post(`user/getCodePassword`, obj);
  }

  updateAvatarUser(obj): Observable<any> {
    return this.http.post(`user/uploadAvatar`, obj);
  }

  uploadImage(image): Observable<any> {
    return this.http.post(`user/uploadImage`, image);
  }
}
