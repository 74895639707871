<div class="image-grid-wrap" *ngIf="!isActiveAlbum">
	<div class="image-grid">
		<div class="image-row img-row-wrap">
			<ng-container *ngFor="let albums of listShowAblum">
				<div
					class="has-background-image"
					[ngStyle]="{
						background: 'url(' + albums?.albumPhoto + ')'
					}"
					[attr.data-demo-background]="albums?.albumPhoto"
					(click)="openAlbum(albums)"
				>
					<div class="overlay"></div>
					<div class="image-owner">
						<img
							class="avatar"
							[src]="albums?.userId?.avatar"
							[attr.data-demo-background]="albums?.userId?.avatar"
							alt
						/>
						<div class="name">
							{{ albums?.userId?.firstname }}
							{{ albums?.userId?.lastname }}
						</div>
						<span>{{ albums?.albumDescription }}</span>
					</div>
					<div class="photo-time">
						{{ albums?.createdAt | date: "MMM dd,yyyy" }}
						<span> - {{ albums?.albumName }}</span>
					</div>
					<a class="photo-like">
						<div class="inner">
							<div class="like-overlay"></div>
							<i-feather name="heart"></i-feather>
						</div>
					</a>
				</div>
			</ng-container>
		</div>
	</div>

	<!-- Load more photos -->
	<div class="load-more-wrap has-text-centered">
		<a class="load-more-button"> Load More </a>
	</div>
</div>
<div class="list-photo" *ngIf="isActiveAlbum">
	<div class="back-album">
		<span (click)="closeAlbum()"
			><i-feather name="corner-down-left"></i-feather
		></span>
		<span>{{ titleAlbum }}</span>
	</div>
	<div class="image-grid-wrap">
		<div class="image-grid">
			<div class="image-row img-row-wrap">
				<ng-container *ngFor="let photos of arrPhoto">
					<div
						class="has-background-image"
						[ngStyle]="{
							background: 'url(' + photos?.photo + ')'
						}"
						[attr.data-demo-background]="photos?.photo"
					>
						<div class="overlay"></div>
						<div class="image-owner image-owner-slick">
							<img
								class="avatar"
								[src]="photos?.userId?.avatar"
								[attr.data-demo-background]="photos?.userId?.avatar"
								alt
							/>
						</div>
						<div class="photo-time">
							{{ photos?.createdAt | date: "MMM dd,yyyy" }}
						</div>
						<a class="photo-like" (click)="openModalDelete(photos?._id)">
							<div class="inner">
								<div class="like-overlay"></div>
								<i-feather name="x"></i-feather>
							</div>
						</a>
					</div>
				</ng-container>
			</div>
		</div>
	</div>

	<!-- Load more photos -->
	<div class="load-more-wrap has-text-centered" (click)="getMorePhoto()">
		<a class="load-more-button"> Load More </a>
	</div>
</div>
<div class="customize-modal" *ngIf="isDelete">
	<div class="modal-wrap">
		<div class="header-modal">
			<span><i-feather name="trash-2"></i-feather> </span>
		</div>
		<div class="body-modal">
			<div class="content-modal">Are you want to delete this photo?</div>
		</div>
		<div class="footer-modal">
			<div class="btn-modal">
				<span>
					<a
						class="button is-solid white-button raised"
						(click)="closeModalDelete()"
						>Close</a
					></span
				>
				<span>
					<a class="button is-solid red-button raised" (click)="deleteImg()"
						>Delete</a
					></span
				>
			</div>
		</div>
	</div>
</div>
