import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-albums-help-modal',
  templateUrl: './albums-help-modal.component.html',
  styleUrls: ['./albums-help-modal.component.scss']
})
export class AlbumsHelpModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
