import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import * as environment from 'src/environments/environment';

@Component({
  selector: 'app-profile-friends',
  templateUrl: './profile-friends.component.html',
  styleUrls: ['./profile-friends.component.scss'],
})
export class ProfileFriendsComponent implements OnInit {
  @Input() infouser;
  @Input() friends;
  @Input() loggedUserId;
  @Output() friendListChange = new EventEmitter<any>();
  userId;
  // environment = environment.environment.apiUrl;
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  onfriendListChange(friend) {
    this.friendListChange.emit(friend);
  }
}
