<div class="box-heading">
    <h4>Trips</h4>
    <div class="dropdown is-neutral is-spaced is-right dropdown-trigger">
        <div>
            <div class="button">
                <i-feather name="more-vertical"></i-feather>
            </div>
        </div>
        <div
            class="dropdown-menu"
            role="menu"
        >
            <div class="dropdown-content">
                <a class="dropdown-item">
                    <div class="media">
                        <i-feather name="globe"></i-feather>
                        <div class="media-content">
                            <h3>View my Trips</h3>
                            <small>View all your trips</small>
                        </div>
                    </div>
                </a>
                <a
                    href="#"
                    class="dropdown-item"
                >
                    <div class="media">
                        <i-feather name="compass"></i-feather>
                        <div class="media-content">
                            <h3>Suggestions</h3>
                            <small>View trendy suggestions.</small>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>



<div class="trip-cards-list">
    <div class="card is-trip-card">
        <div class="trip-item">
            <img
                src="https://via.placeholder.com/200x200"
                data-demo-src="assets/img/demo/widgets/trips/1.jpg"
                alt
            >
            <div class="text-content">
                <a>New York, NY, USA</a>
                <span>4 months ago</span>
            </div>
        </div>
        <div class="trip-item">
            <img
                src="https://via.placeholder.com/200x200"
                data-demo-src="assets/img/demo/widgets/trips/2.jpg"
                alt
            >
            <div class="text-content">
                <a>Paris, France</a>
                <span>8 months ago</span>
            </div>
        </div>
        <div class="trip-item">
            <img
                src="https://via.placeholder.com/200x200"
                data-demo-src="assets/img/demo/widgets/trips/3.jpg"
                alt
            >
            <div class="text-content">
                <a>Madrid, Spain</a>
                <span>a year ago</span>
            </div>
        </div>
        <div class="trip-item">
            <img
                src="https://via.placeholder.com/200x200"
                data-demo-src="assets/img/demo/widgets/trips/4.jpg"
                alt
            >
            <div class="text-content">
                <a>Marrakech, Morocco</a>
                <span>a year ago</span>
            </div>
        </div>
    </div>
</div>
