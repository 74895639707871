<div class="schedule">
	<div class="schedule-day-container hidden">
		<div class="day-header day-header--large">
			<div class="day-header-bg"></div>
			<div class="day-header-close">
				<i-feather name="x"></i-feather>
			</div>
			<div class="day-header-content">
				<div class="day-header-title">
					<div class="day-header-title-day">24</div>
					<div class="day-header-title-month">October</div>
				</div>
				<div class="day-header-event">Workout Session</div>
			</div>
		</div>
		<div class="day-content has-slimscroll">
			<app-event></app-event>
		</div>
	</div>
	<div class="schedule-header">
		<div class="nav-icon">
			<i-feather name="chevron-left"></i-feather>
		</div>
		<div class="month">{{ monthCurrent }}</div>
		<div class="nav-icon">
			<i-feather name="chevron-right"></i-feather>
		</div>
	</div>
	<div class="schedule-calendar">
		<div class="calendar-row day-row">
			<div class="day day-name" *ngFor="let day of days">
				{{ day }}
			</div>
		</div>
		<div class="calendar-row">
			<div
				class="day"
				*ngFor="let day of arrCurrentDays | slice: 0:7"
				[ngClass]="{'event green': day?.active == true}"
				[ngStyle]="{background: day?.colorEvent}"
				[attr.data-content]="indexEvent"
				[attr.data-day]="day?.active == true ? day?.dayNumber : null"
				[attr.data-event]="day?.active == true ? day?.title : null"
				(click)="clickShowEvent(day)"
			>
				{{ day?.dayNumber }}
			</div>
		</div>
		<div class="calendar-row">
			<div
				class="day"
				*ngFor="let day of arrCurrentDays | slice: 7:14"
				[ngClass]="{'event green': day?.active == true}"
				[ngStyle]="{background: day?.colorEvent}"
				[attr.data-content]="indexEvent"
				[attr.data-day]="day?.active == true ? day?.dayNumber : null"
				[attr.data-event]="day?.active == true ? day?.title : null"
				(click)="clickShowEvent(day)"
			>
				{{ day?.dayNumber }}
			</div>
		</div>
		<div class="calendar-row">
			<div
				class="day"
				*ngFor="let day of arrCurrentDays | slice: 14:21"
				[ngClass]="{'event green': day?.active == true}"
				[ngStyle]="{background: day?.colorEvent}"
				[attr.data-content]="indexEvent"
				[attr.data-day]="day?.active == true ? day?.dayNumber : null"
				[attr.data-event]="day?.active == true ? day?.title : null"
				(click)="clickShowEvent(day)"
			>
				{{ day?.dayNumber }}
			</div>
		</div>
		<div class="calendar-row">
			<div
				class="day"
				*ngFor="let day of arrCurrentDays | slice: 21:28"
				[ngClass]="{'event green': day?.active == true}"
				[ngStyle]="{background: day?.colorEvent}"
				[attr.data-content]="indexEvent"
				[attr.data-day]="day?.active == true ? day?.dayNumber : null"
				[attr.data-event]="day?.active == true ? day?.title : null"
				(click)="clickShowEvent(day)"
			>
				{{ day?.dayNumber }}
			</div>
		</div>
		<div class="calendar-row calendar-row-last">
			<div
				class="day"
				*ngFor="let day of arrCurrentDays | slice: 28:31"
				[ngClass]="{'event green': day?.active == true}"
				[ngStyle]="{background: day?.colorEvent}"
				[attr.data-content]="indexEvent"
				[attr.data-day]="day?.active == true ? day?.dayNumber : null"
				[attr.data-event]="day?.active == true ? day?.title : null"
				(click)="clickShowEvent(day)"
			>
				{{ day?.dayNumber }}
			</div>
		</div>
		<div class="next-fab">
			<i-feather name="chevron-down"></i-feather>
		</div>
	</div>
	<div class="schedule-divider"></div>
	<div class="schedule-events">
		<div class="schedule-events-title">Upcoming events</div>
		<div class="schedule-event" *ngFor="let event of arrEvent">
			<div class="event-date" [ngStyle]="{background: event?.colorEvent}">
				{{ event?.startDate | date: "dd" }}
			</div>
			<div class="event-title">
				<span>{{ event?.title }}</span>
				<span> {{ event?.startTime }} pm | {{ event?.address }} </span>
			</div>
		</div>
		<!-- <div class="schedule-event">
            <div class="event-date purple">7</div>
            <div class="event-title">
                <span>Meet customer in LA</span>
                <span>11:00am | St Luc Café</span>
            </div>
        </div>
        <div class="schedule-event">
            <div class="event-date green">22</div>
            <div class="event-title">
                <span>Frank's birthday</span>
                <span>03:00pm | Frank's home</span>
            </div>
        </div>
        <div class="schedule-event">
            <div class="event-date primary">24</div>
            <div class="event-title">
                <span>Workout session</span>
                <span>07:00am | Gold Gym</span>
            </div>
        </div>
        <div class="schedule-event">
            <div class="event-date purple">26</div>
            <div class="event-title">
                <span>Project review</span>
                <span>08:00am | Office</span>
            </div>
        </div> -->
		<div class="button-wrap">
			<a class="button is-fullwidth has-icon" (click)="addEvent()">
				<i-feather name="plus"></i-feather>
				New Event
			</a>
		</div>
	</div>
	<div class="schedule-day-container hidden">
		<div class="day-header day-header--large">
			<div
				class="day-header-bg"
				[ngStyle]="{background: dataClickEvent?.colorEvent}"
			></div>
			<div class="day-header-close">
				<i-feather name="x"></i-feather>
			</div>
			<div class="day-header-content">
				<div class="day-header-title">
					<div class="day-header-title-day">
						{{ dataClickEvent?.dayNumber }}
					</div>
					<div class="day-header-title-month">{{ monthClickEvent }}</div>
				</div>
				<div class="day-header-event">{{ dataClickEvent?.title }}</div>
			</div>
		</div>
		<div class="day-content has-slimscroll">
			<app-event [arrEvent]="arrEvent"></app-event>
		</div>
	</div>
</div>

<div class="schedule" *ngIf="isEdit">
	<div class="header-event">
		<div class="box-header">create event</div>
		<div class="day-header-close" (click)="addEvent()">
			<i-feather name="x"></i-feather>
		</div>
	</div>
	<div class="body-event">
		<div class="columns is-multiline">
			<div class="column is-12">
				<div class="field field-group">
					<label>Title</label>
					<div class="control has-icon">
						<input type="text" class="input is-fade" [(ngModel)]="title" />
						<div class="form-icon">
							<i-feather name="user"></i-feather>
						</div>
					</div>
				</div>
			</div>
			<div class="column is-12">
				<div class="field field-group">
					<label>From</label>
					<div class="control has-icon">
						<input
							type="date"
							class="input is-fade pointer"
							[(ngModel)]="startDate"
						/>
						<div class="form-icon">
							<i-feather name="calendar"></i-feather>
						</div>
					</div>
				</div>
			</div>
			<div class="column is-12">
				<div class="field field-group">
					<label>To</label>
					<div class="control has-icon">
						<input
							type="date"
							class="input is-fade pointer"
							[(ngModel)]="endDate"
						/>
						<div class="form-icon">
							<i-feather name="calendar"></i-feather>
						</div>
					</div>
				</div>
			</div>

			<div class="column is-6">
				<div class="field field-group">
					<label>Start</label>
					<div class="control has-icon">
						<input type="text" class="input is-fade" disabled />
						<div
							id="tipue_drop_content"
							(click)="clickShowHours(1)"
							class="drop-result input is-fade pointer drop-content"
						>
							<div class="content-hours">{{ startTime }}</div>
							<div
								class="tipue_drop_box drop-result-box custome-select drop-hours"
								*ngIf="isHourStart"
							>
								<div id="tipue_drop_wrapper">
									<div
										class="tipue_drop_item"
										*ngFor="let hour of arrHoursOfDay"
									>
										<div
											class="tipue_drop_right"
											(click)="changeStartTime(hour.time)"
										>
											<div>{{ hour.time }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="form-icon">
								<i-feather name="calendar"></i-feather>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="column is-6">
				<div class="field field-group">
					<label>End</label>
					<div class="control has-icon">
						<input type="text" class="input is-fade" disabled />
						<div
							id="tipue_drop_content"
							(click)="clickShowHours(2)"
							class="drop-result input is-fade pointer drop-content"
						>
							<div class="content-hours">{{ endTime }}</div>
							<div
								class="tipue_drop_box drop-result-box custome-select drop-hours"
								*ngIf="isHourEnd"
							>
								<div id="tipue_drop_wrapper">
									<div
										class="tipue_drop_item"
										*ngFor="let houre of arrHoursOfDayFinish"
									>
										<div
											class="tipue_drop_right"
											(click)="changFinishTime(houre)"
										>
											<div>{{ houre }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="form-icon">
								<i-feather name="calendar"></i-feather>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="column is-12">
				<div class="field field-group">
					<label>Address</label>
					<div class="control has-icon">
						<input type="text" class="input is-fade" [(ngModel)]="address" />
						<div class="form-icon">
							<i-feather name="briefcase"></i-feather>
						</div>
					</div>
				</div>
			</div>

			<div class="column is-12">
				<label>Participants</label>
				<div class="navbar-item navbar-drop">
					<div id="global-search" class="control">
						<input
							[(ngModel)]="searchUser"
							id="tipue_drop_input"
							class="input is-rounded"
							type="search"
							placeholder="Search"
							(ngModelChange)="changeSearch(searchUser)"
							autocomplete="off"
						/>

						<div class="search-options"></div>
						<div
							id="tipue_drop_content"
							class="drop-result"
							*ngIf="searchUser != '' && arrResultUser.length > 0"
						>
							<div class="tipue_drop_box drop-result-box custome-select">
								<div id="tipue_drop_wrapper">
									<div
										class="tipue_drop_item"
										*ngFor="let user of arrResultUser"
									>
										<div class="tipue_drop_left">
											<img [src]="user.avatar" class="tipue_drop_image" />
										</div>
										<div class="tipue_drop_right" (click)="addRole(user)">
											{{ user.firstname }} {{ user.lastname }}
											<div>{{ user.occupation }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="column is-12" *ngIf="arrRole?.length > 0">
				<div class="group-roles">
					<div class="group-role-wrapper">
						<div class="item-role" *ngFor="let role of arrRole">
							<div class="avatar-user">
								<img [src]="role?.avatar" alt />
							</div>
							<div class="email-user">
								<div class>{{ role?.email }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="column is-6">
				<div class="field field-group">
					<div class="control">
						<input type="text" class="input is-fade" disabled />
						<div
							id="tipue_drop_content"
							(click)="clickColorEvent()"
							class="drop-result input pointer drop-color"
						>
							<div
								class="content-color"
								[ngStyle]="{background: colorEvent}"
							></div>
							<div
								class="tipue_drop_box custome-select drop-rs-color"
								*ngIf="isColor"
							>
								<div id="tipue_drop_wrapper">
									<div class="tipue_drop_item">
										<div class="tipue_drop_color">
											<div
												*ngFor="let color of arrColor"
												class="item-color"
												[ngStyle]="{background: color.color}"
												(click)="changeColorEvent(color)"
											>
												<div *ngIf="color.isActive == true" class="item-active">
													<img src="assets/img/icons/check.png" alt />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="column is-12">
				<!-- Field -->
				<div class="field field-group">
					<label>Description</label>
					<div class="control has-icon">
						<input
							type="text"
							class="input is-fade"
							[(ngModel)]="description"
						/>
						<div class="form-icon">
							<i-feather name="briefcase"></i-feather>
						</div>
					</div>
				</div>
			</div>
			<div class="column is-6">
				<div class="buttons">
					<button
						class="button is-solid accent-button form-button"
						(click)="createEvent()"
					>
						Save
					</button>
				</div>
			</div>
			<div class="column is-6">
				<div class="field field-group">
					<div class="control has-icon">
						<div
							id="tipue_drop_content"
							(click)="clickDisplay()"
							class="drop-result input is-fade pointer drop-content"
						>
							<div class="content-hours">{{ arrDisplay[display].name }}</div>
							<div
								class="
									tipue_drop_box
									drop-result-box
									custome-select
									drop-display
								"
								*ngIf="isDisplay"
							>
								<div id="tipue_drop_wrapper">
									<div class="tipue_drop_item" *ngFor="let item of arrDisplay">
										<div
											class="tipue_drop_right"
											(click)="changeDisplay(item.value)"
										>
											<div>{{ item.name }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="form-icon">
								<i-feather name="calendar"></i-feather>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
