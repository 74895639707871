<div class="basic-infos-wrapper sticky-wrap">
  <div class="card">
    <div class="card-wrap">
      <h4><span class="title-is-6">About</span></h4>
      <div class="ab-gr sub-title-is-6">
        <span>{{ dataGroups?.groupDescription }}</span>
      </div>
      <div class="ab">
        <div class="ab-wrap">
          <div class="ab-icon">
            <i-feather [name]="groupDisplayIcons"></i-feather>
          </div>
          <div class="ab-content">
            <div class="ab-cont-wrap title-is-6">
              <span>{{ groupDisplays }}</span>
            </div>
            <div class="ab-cont-wrap sub-title-is-6">
              {{ groupDisplayContents }}
              <span></span>
            </div>
          </div>
        </div>
        <div class="ab-wrap" *ngIf="dataGroups?.groupHidden">
          <div class="ab-icon">
            <i-feather [name]="groupHiddenIcons"></i-feather>
          </div>
          <div class="ab-content">
            <div class="ab-cont-wrap title-is-6">
              <span>{{ groupHiddens }}</span>
            </div>
            <div class="ab-cont-wrap sub-title-is-6">
              {{ groupHiddenContents }}
              <span></span>
            </div>
          </div>
        </div>
        <div class="ab-wrap">
          <div class="ab-icon">
            <i-feather name="users"></i-feather>
          </div>
          <div class="ab-content">
            <div class="ab-cont-wrap txt-title title-is-6">
              <span> General Group</span>
            </div>
            <div class="ab-cont-wrap txt-cont">
              <span></span>
            </div>
          </div>
        </div>
        <div class="ab-wrap" *ngIf="dataGroups?.groupLocation">
          <div class="ab-icon">
            <i-feather name="map-pin"></i-feather>
          </div>
          <div class="ab-content">
            <div class="ab-cont-wrap txt-title">
              <span>{{ dataGroups?.groupLocation }}</span>
            </div>
            <div class="ab-cont-wrap txt-cont">
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-wrap">
      <div class="title-is-6">
        <h4>Recent Media</h4>
      </div>
      <div class="ab">
        <div class="ab-media" *ngIf="arrPhotos?.length > 0">
          <div class="media-item" *ngFor="let img of arrPhotos | slice: 0:9">
            <img [src]="img?.photo" [alt]="img?.photo" />
          </div>
        </div>
        <button
          *ngIf="arrPhotos?.length > 9"
          class="button is-fullwidth btn-see-cus"
          (click)="seeAllPhoto()"
        >
          See All
        </button>
      </div>
    </div>
  </div>
</div>
