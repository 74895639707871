<!-- <div class="pageloader"></div>
<div class="infraloader is-active"></div>
<div class="app-overlay"></div> -->
<app-nav-bar></app-nav-bar>
<div class="container is-custom">
	<div id="pages-main" class="view-wrap is-headless">
		<div class="columns is-multiline no-margin">
			<div class="column is-paddingless">
				<app-timeline-page
					[idPage]="pageId"
					[dataPage]="dataPage"
					(pageActive)="goPageActive($event)"
					[isAdmin]="isAdmin"
					[dataUser]="dataUser"
					[countFollowPage]="countFollow"
					[isLike]="isLike"
					(isLikePageTimeLine)="getLikePageFromChild($event)"
					(isActiveAvtar)="openModalAvatar($event)"
				></app-timeline-page>
			</div>
		</div>

		<div class="columns has-portrait-padding">
			<div class="column is-4 pos-sticky-wrap">
				<ng-container *ngIf="dataPage && isPageActive != 4">
					<app-info
						[dataPage]="dataPage"
						[countLikePage]="countLikePage"
						[countFLPage]="countFollow"
					></app-info
				></ng-container>
			</div>
			<ng-container *ngIf="dataPage">
				<div class="column is-8" *ngIf="isPageActive == 1">
					<app-pages-profile
						*ngIf="dataAdmin && dataPage"
						[isAdmin]="isAdmin"
						[dataAdmin]="dataAdmin"
						[dataPage]="dataPage"
					></app-pages-profile>
				</div>
			</ng-container>

			<div class="column is-8" *ngIf="isPageActive == 2">
				<app-pages-community
					[dataPage]="dataPage"
					[countLikePage]="countLikePage"
					[countFLPage]="countFollow"
					[arrLike]="arrLike"
					[dataFLPage]="dataFLPage"
				></app-pages-community>
			</div>
			<ng-container *ngIf="dataPage">
				<div class="column is-8" *ngIf="isPageActive == 3">
					<app-pages-about
						[dataPage]="dataPage"
						(isUpdate)="checkEditPageChild($event)"
						[isAdmin]="isAdmin"
					></app-pages-about>
				</div>
			</ng-container>
		</div>
		<div class="columns has-portrait-padding" *ngIf="isPageActive == 4">
			<div class="column">
				<app-pages-photos
					[dataPage]="dataPage"
					[arrPost]="arrPost"
					[dataInfoUser]="dataUser"
				></app-pages-photos>
			</div>
		</div>
	</div>
</div>

<app-page-change-cover-modal></app-page-change-cover-modal>
<app-page-change-profile-pic-modal></app-page-change-profile-pic-modal>
<app-page-upload-crop-cover-modal
	[idPage]="pageId"
	[dataPage]="dataPage"
></app-page-upload-crop-cover-modal>
<app-page-upload-crop-profile-modal
	[dataUser]="dataUser"
	[dataPage]="dataPage"
></app-page-upload-crop-profile-modal>
<app-page-user-photos-modal></app-page-user-photos-modal>
<app-page-share-modal></app-page-share-modal>
<app-page-add-conversation-modal></app-page-add-conversation-modal>
