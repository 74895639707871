import { Component, OnInit } from "@angular/core";
import { ProfileService } from "src/app/services/profile.service";
import izToast from "izitoast";
@Component({
	selector: "app-suggested-friends-widget",
	templateUrl: "./suggested-friends-widget.component.html",
	styleUrls: ["./suggested-friends-widget.component.scss"],
})
export class SuggestedFriendsWidgetComponent implements OnInit {
	constructor(private profileService: ProfileService) {}

	checkAddFriend;

	ngOnInit(): void {}

	getSuggestedFriends() {}
	testToast() {
		this.checkAddFriend = !this.checkAddFriend;
		izToast.show({
			class: "success-toast",
			icon: "mdi mdi-bookmark-plus",
			title: "",
			message: " has been added to your bookmarks",
			titleColor: "#fff",
			messageColor: "#fff",
			iconColor: "#fff",
			backgroundColor: "#344258",
			progressBarColor: "#0062ff",
			position: "bottomRight",
			transitionIn: "fadeInUp",
			close: false,
			timeout: 1800,
		});
	}
}
