<div
    id="security-settings"
    class="settings-section"
>
    <div class="settings-panel">
        <div class="title-wrap">
            <a class="mobile-sidebar-trigger">
                <i-feather name="menu"></i-feather>
            </a>
            <h2>Security</h2>
        </div>
        <div class="settings-form-wrapper">
            <div class="settings-form">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <div class="field field-group">
                            <label>Current Password</label>
                            <div class="control has-icon">
                                <input
                                    type="password"
                                    class="input is-fade"
                                    [(ngModel)]="currentPassword"
                                    placeholder="********"
                                >
                                <div class="form-icon">
                                    <i-feather name="lock"></i-feather>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-6">
                        <div class="field field-group">
                            <label>New Password</label>
                            <div class="control has-icon">
                                <input
                                    type="password"
                                    class="input is-fade"
                                    [(ngModel)]="newPassword"
                                    placeholder="********"
                                >
                                <div class="form-icon">
                                    <i-feather name="lock"></i-feather>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-6">
                        <div class="field field-group">
                            <label>Repeat Password</label>
                            <div class="control has-icon">
                                <input
                                    type="password"
                                    class="input is-fade"
                                    [(ngModel)]="repeatPassword"
                                    placeholder="********"
                                >
                                <div class="form-icon">
                                    <i-feather name="lock"></i-feather>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-12">
                        <div class="form-text">
                            <p>
                                You can enable 2 factor authentication anytime to improve your account privacy and security.
                            </p>
                        </div>
                    </div>
                    <div class="column is-6">
                        <div class="field">
                            <div class="switch-block">
                                <label class="f-switch is-accent">
                                    <input
                                        type="checkbox"
                                        class="is-switch is-success"
                                    >
                                </label>
                                <div class="meta">
                                    <h4>Enable 2 factor auth</h4>
                                    <p>
                                        This will send an additional code to your phone number.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-6">
                        <div class="field field-group">
                            <label>Phone Number</label>
                            <div class="control has-icon">
                                <input
                                    type="text"
                                    class="input is-fade"
                                    [(ngModel)]="phoneNumber"
                                >
                                <div class="form-icon">
                                    <i-feather name="smartphone"></i-feather>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-12">
                        <div class="buttons">
                            <button
                                class="button is-solid accent-button form-button"
                                (click)="changePassword()"
                            >
                                Save Changes
                            </button>
                            <button class="button is-light form-button">Advanced</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="illustration">
                <img
                    src="assets/img/illustrations/settings/2.svg"
                    alt
                >
                <p>
                    If you'd like to learn more about security settings, you can read about it in the
                    <a>user guide</a>
                    .
                </p>
            </div>
        </div>
    </div>
</div>
