<div *ngIf="user" id="general-settings" class="settings-section is-active">
	<div class="settings-panel">
		<div class="title-wrap">
			<a class="mobile-sidebar-trigger">
				<i-feather name="menu"></i-feather>
			</a>
			<h2>General Settings</h2>
		</div>
		<div class="settings-form-wrapper">
			<div class="settings-form">
				<div class="columns is-multiline">
					<div class="column is-6">
						<!-- Field -->
						<div class="field field-group">
							<label>First Name</label>
							<div class="control has-icon">
								<input
									type="text"
									class="input is-fade"
									[(ngModel)]="user.firstname"
								/>
								<div class="form-icon">
									<i-feather name="user"></i-feather>
								</div>
							</div>
						</div>
						<!-- Field -->
						<div class="field field-group">
							<label>Email</label>
							<div class="control has-icon">
								<input
									type="text"
									class="input is-fade"
									[(ngModel)]="user.email"
								/>
								<div class="form-icon">
									<i-feather name="mail"></i-feather>
								</div>
							</div>
						</div>
					</div>
					<div class="column is-6">
						<!-- Field -->
						<div class="field field-group">
							<label>Last Name</label>
							<div class="control has-icon">
								<input
									type="text"
									class="input is-fade"
									[(ngModel)]="user.lastname"
								/>
								<div class="form-icon">
									<i-feather name="user"></i-feather>
								</div>
							</div>
						</div>
						<!-- Field -->
						<div class="field field-group">
							<label>Backup Email</label>
							<div class="control has-icon">
								<input
									type="text"
									class="input is-fade"
									[(ngModel)]="user.backupEmail"
								/>
								<div class="form-icon">
									<i-feather name="mail"></i-feather>
								</div>
							</div>
						</div>
					</div>
					<div class="column is-12">
						<!-- Field -->
						<div class="field field-group">
							<label>Address</label>
							<div class="control">
								<textarea
									type="text"
									class="textarea is-fade"
									rows="1"
									[(ngModel)]="user.address"
								></textarea>
							</div>
						</div>
					</div>
					<div class="column is-12">
						<!-- Field -->
						<div class="form-text">
							<p>
								Be sure to fill out your location settings. This will help us
								suggest you relevant friends and places you could like.
							</p>
						</div>
					</div>
					<div class="column is-6">
						<!-- Field -->
						<div class="field field-group">
							<label>City</label>
							<div class="control has-icon">
								<input
									type="text"
									class="input is-fade"
									[(ngModel)]="user.city"
								/>
								<div class="form-icon">
									<i-feather name="map-pin"></i-feather>
								</div>
							</div>
						</div>
					</div>
					<div class="column is-6">
						<!-- Field -->
						<div class="field field-group is-autocomplete">
							<label>Country</label>
							<div class="control has-icon">
								<input
									id="country-autocpl"
									type="text"
									class="input is-fade"
									[(ngModel)]="user.country"
								/>
								<div class="form-icon">
									<i-feather name="globe"></i-feather>
								</div>
							</div>
						</div>
					</div>
					<div class="column is-12">
						<div class="buttons">
							<button
								class="button is-solid accent-button form-button"
								(click)="updateUser()"
							>
								Save Changes
							</button>
							<button class="button is-light form-button">Advanced</button>
						</div>
					</div>
				</div>
			</div>
			<div class="illustration">
				<img src="assets/img/illustrations/settings/1.svg" alt />
				<p>
					If you'd like to learn more about general settings, you can read about
					it in the
					<a>user guide</a>
					.
				</p>
			</div>
		</div>
	</div>

	<div class="group-infomation">
		<div class="settings-panel settings-info" *ngIf="!isEditEdu && !isAddEdu">
			<div class="title-wrap">
				<a class="mobile-sidebar-trigger">
					<i-feather name="menu"></i-feather>
				</a>
				<h2>General Educations</h2>
			</div>
			<div class="settings-form-wrapper">
				<div class="settings-form">
					<div class="columns is-multiline">
						<div class="column is-12">
							<div class="groups-education" *ngFor="let edu of arrEduUser">
								<div class="avt-edu">
									<img src="/assets/img/profile.png" />
								</div>
								<div class="educations">
									<div class="education-title">
										Position {{ edu?.role }} at {{ edu?.schoolName }}
									</div>
									<div class="education-content">
										{{ edu?.startDate | date: "MMM, yyyy" }} -
										{{ edu?.endDate | date: "MMM, yyyy" }}
									</div>
								</div>
								<div class="btn-edit-edu">
									<button class="button btn-edit" (click)="clickEditEdu(edu)">
										<i-feather name="edit-2"></i-feather>
									</button>
								</div>
							</div>
							<!-- Field -->
						</div>
					</div>
				</div>
			</div>
			<div class="settings-form-wrapper settings-btn-edit">
				<button class="button btn-edit" (click)="addEducation()">
					<i-feather name="plus-circle"></i-feather>
				</button>
				<div class="add-job">Add Job...</div>
			</div>
		</div>
		<div class="settings-panel settings-info" *ngIf="isEditEdu || isAddEdu">
			<div class="title-wrap">
				<a class="mobile-sidebar-trigger">
					<i-feather name="menu"></i-feather>
				</a>
				<h2>General Educations</h2>
			</div>
			<div class="settings-form-wrapper">
				<div class="settings-form">
					<div class="columns is-multiline">
						<div class="column is-12">
							<div class="field field-group">
								<label>School at</label>
								<div class="control has-icon">
									<select
										class="select-school input is-fade"
										[(ngModel)]="education.schoolId"
									>
										<option>Vui lòng chọn trường!</option>
										<option
											*ngFor="let school of arrSchools"
											[value]="school._id"
										>
											{{ school.schoolName }}
										</option>
									</select>
									<div class="icon-dropdown">
										<i-feather name="chevron-down"></i-feather>
									</div>
									<div class="form-icon">
										<i-feather name="briefcase"></i-feather>
									</div>
								</div>
							</div>
							<!-- Field -->
						</div>
						<div class="column is-12">
							<!-- Field -->
							<div class="field field-group">
								<label>Position</label>
								<div class="control has-icon">
									<input
										type="text"
										class="input is-fade"
										[(ngModel)]="education.role"
									/>
									<div class="form-icon">
										<i-feather name="briefcase"></i-feather>
									</div>
								</div>
							</div>
						</div>
						<div class="column is-6">
							<!-- Field -->
							<div class="field field-group">
								<label>From</label>
								<div class="control has-icon">
									<input
										type="date"
										class="input is-fade"
										[(ngModel)]="education.startDate"
									/>
									<div class="form-icon">
										<i-feather name="calendar"></i-feather>
									</div>
								</div>
							</div>
							<!-- Field -->
						</div>
						<div class="column is-6">
							<div class="field field-group">
								<label>To</label>
								<div class="control has-icon">
									<input
										type="date"
										class="input is-fade"
										[(ngModel)]="education.endDate"
									/>
									<div class="form-icon">
										<i-feather name="calendar"></i-feather>
									</div>
								</div>
							</div>
						</div>
						<div class="column is-6">
							<div class="buttons">
								<button
									class="button is-solid accent-button form-button"
									(click)="clickSaveEduUser()"
								>
									Save Changes
								</button>
								<button
									class="button is-light form-button"
									(click)="clickCloseEditEdu()"
								>
									Close
								</button>
							</div>
						</div>
						<div class="column is-6">
							<div class="field field-group field-select">
								<div class="control has-icon">
									<select
										class="select-school input is-fade"
										[(ngModel)]="education.isShow"
									>
										<option
											*ngFor="let display of arrDisplay"
											[value]="display.value"
										>
											{{ display?.name }}
										</option>
									</select>
									<div class="form-icon">
										<i-feather name="globe"></i-feather>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="settings-panel settings-info" *ngIf="!isEditJob && !isAddJob">
			<div class="title-wrap">
				<a class="mobile-sidebar-trigger">
					<i-feather name="menu"></i-feather>
				</a>
				<h2>General Jobs</h2>
			</div>
			<div class="settings-form-wrapper">
				<div class="settings-form">
					<div class="columns is-multiline">
						<div class="column is-12">
							<div class="groups-education" *ngFor="let job of arrJobUser">
								<div class="avt-edu">
									<img src="/assets/img/profile.png" />
								</div>
								<div class="educations">
									<div class="education-title">
										Position {{ job?.role }} at {{ job?.comName }}
									</div>
									<div class="education-content">
										{{ job?.startDate | date: "MMM, yyyy" }} -
										{{ job?.endDate | date: "MMM, yyyy" }}
									</div>
								</div>
								<div class="btn-edit-edu">
									<button class="button btn-edit" (click)="clickEditJob(job)">
										<i-feather name="edit-2"></i-feather>
									</button>
								</div>
							</div>
							<!-- Field -->
						</div>
					</div>
				</div>
			</div>
			<div class="settings-form-wrapper settings-btn-edit">
				<button class="button btn-edit" (click)="addJob()">
					<i-feather name="plus-circle"></i-feather>
				</button>
				<div class="add-job">Add Job...</div>
			</div>
		</div>
		<div class="settings-panel settings-info" *ngIf="isEditJob || isAddJob">
			<div class="title-wrap">
				<a class="mobile-sidebar-trigger">
					<i-feather name="menu"></i-feather>
				</a>
				<h2>General Jobs</h2>
			</div>
			<div class="settings-form-wrapper">
				<div class="settings-form">
					<div class="columns is-multiline">
						<div class="column is-12">
							<div class="field field-group">
								<label>School at</label>
								<div class="control has-icon">
									<select
										class="select-school input is-fade"
										[(ngModel)]="job.companyId"
									>
										<option>Vui lòng chọn nghề nghiệp!</option>
										<option
											*ngFor="let comapny of arrCompany"
											[value]="comapny._id"
										>
											{{ comapny?.comName }}
										</option>
									</select>
									<div class="icon-dropdown">
										<i-feather name="chevron-down"></i-feather>
									</div>
									<div class="form-icon">
										<i-feather name="briefcase"></i-feather>
									</div>
								</div>
							</div>

							<!-- Field -->
						</div>
						<div class="column is-12">
							<!-- Field -->
							<div class="field field-group">
								<label>Position</label>
								<div class="control has-icon">
									<input
										type="text"
										class="input is-fade"
										[(ngModel)]="job.role"
										autocomplete="off"
									/>
									<div class="form-icon">
										<i-feather name="briefcase"></i-feather>
									</div>
								</div>
							</div>
						</div>
						<div class="column is-6">
							<!-- Field -->
							<div class="field field-group">
								<label>From</label>
								<div class="control has-icon">
									<input
										type="date"
										class="input is-fade"
										[(ngModel)]="job.startDate"
									/>
									<div class="form-icon">
										<i-feather name="calendar"></i-feather>
									</div>
								</div>
							</div>
							<!-- Field -->
						</div>
						<div class="column is-6">
							<div class="field field-group">
								<label>To</label>
								<div class="control has-icon">
									<input
										type="date"
										class="input is-fade"
										[(ngModel)]="job.endDate"
									/>
									<div class="form-icon">
										<i-feather name="calendar"></i-feather>
									</div>
								</div>
							</div>
						</div>
						<div class="column is-6">
							<div class="buttons">
								<button
									class="button is-solid accent-button form-button"
									(click)="clickSaveJobUser()"
								>
									Save Changes
								</button>
								<button
									class="button is-light form-button"
									(click)="clickCloseEditJob()"
								>
									Close!
								</button>
							</div>
						</div>
						<div class="column is-6">
							<div class="field field-group field-select">
								<div class="control has-icon">
									<select
										class="select-school input is-fade"
										[(ngModel)]="job.isShow"
									>
										<option
											*ngFor="let display of arrDisplay"
											[value]="display.value"
										>
											{{ display?.name }}
										</option>
									</select>
									<div class="form-icon">
										<i-feather name="globe"></i-feather>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
