<!-- /html/partials/pages/stories/modals/image-story-modal.html -->
<div
	id="image-story-modal"
	class="modal image-story-modal is-medium has-light-bg"
>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-heading">
				<div class="small-avatar">
					<img
						class="avatar"
						[src]="infoUsers?.avatar"
						data-demo-src="assets/img/avatars/jenna.png"
						data-user-popover="0"
						alt=""
					/>
				</div>
				<h3>Image Story</h3>
				<!-- Close X button -->
				<div class="close-wrap">
					<span class="close-modal">
						<i-feather name="x"></i-feather>
					</span>
				</div>
			</div>
			<div class="card-body">
				<div class="columns">
					<div class="column is-6">
						<div class="story-meta">
							<div class="field">
								<div class="control">
									<textarea
										class="textarea is-fade"
										rows="3"
										placeholder="Describe your story"
										[(ngModel)]="postContent"
									></textarea>
								</div>
								<div
									class="select-custome"
									*ngIf="arrUser?.length > 0 && model?.length > 0"
								>
									<div class="s-user" id="s-user-autocpl">
										<ul>
											<li
												class="is-selected"
												*ngFor="
													let user of arrUser | slice: 0:5;
													let indexs = index
												"
												(click)="tagsUser(user)"
											>
												<div class="eac-item">
													<div class="template-wrapper">
														<div class="avatar-wrapper">
															<img
																class="autocpl-avatar"
																*ngIf="user.avatar"
																[src]="user.avatar"
															/>
														</div>
														<div class="entry-text">
															{{ user?.firstname }} {{ user?.lastname }}
														</div>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div class="field">
								<div class="control">
									<div
										placeholder="Write a comment..."
										id="tag-friends"
										class="tag-fr textarea"
										contenteditable="true"
										(input)="model = $event.target.textContent"
										(keyup)="searchUser(model)"
									></div>
								</div>
							</div>
							<div class="field">
								<div class="control buttons">
									<button class="button is-light btn-cus close-modal">
										Cancel
									</button>
									<button
										class="button is-solid accent-button raised"
										(click)="createStories()"
									>
										Publish Story
									</button>
								</div>
							</div>
						</div>
					</div>

					<div class="column is-6">
						<div class="story-upload">
							<div id="image-preview" class="preview-block">
								<img
									id="image-upload-placeholder"
									class="image-upload-placeholder"
									[src]="previewImg ? previewImg : imgDefault"
									alt=""
								/>
							</div>
							<div class="input-block">
								<input
									id="image-story-upload"
									type="file"
									accept="video/mp4,image/png,image/jpeg"
									(change)="detectFiles($event)"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
