import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StoriesService {
  constructor(private http: HttpClient) { }

  //#region function create stories
  createStoires(obj): Observable<any> {
    return this.http.post('post/createStories', obj);
  }
  //#endregion

  //#region GET follow of user login
  getFollowers(): Observable<any> {
    return this.http.get('follow/follower');
  }
  getFollowing(): Observable<any> {
    return this.http.get('follow');
  }
  //#endregion

  //#region GET stories using skip,limit
  getListStories(obj): Observable<any> {
    return this.http.post(`post/getStory`, obj);
  }
  //#endregion

  //#region Upload image
  uploadImage(img): Observable<any> {
    return this.http.post('post/upload', img);
  }
  uploadVideo(video): Observable<any> {
    return this.http.post('post/upload-video', video);
  }
  //#endregion

  //#region send data using service
  isStories = new BehaviorSubject<any>([]);
  submitStories = this.isStories.asObservable;
  changeStories(stories) {
    this.isStories.next(stories);
  }

  isClickSide = new BehaviorSubject<any>([]);
  changeIndex = this.isClickSide.asObservable;
  openStory(stories) {
    this.isClickSide.next(stories);
  }
  //#endregion
}
