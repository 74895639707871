<ng-container *ngFor="let video of dataVideos.reverse(); let i = index">
	<div class="card is-post" *ngIf="video && video.postPageId">
		<div class="content-wrap">
			<div class="card-heading">
				<div class="user-block">
					<div class="image">
						<img
							src="{{ video.postPageId.pageAvatar }}"
							data-demo-src="assets/img/avatars/edward.jpeg"
							data-user-popover="5"
							alt=""
						/>
					</div>
					<div class="user-info">
						<a href="#">
							{{ video.postPageId.pageName }}
						</a>
						<span class="time">
							{{ video.createdAt | date: "MMM dd, y, h:mm a" }}</span
						>
					</div>
				</div>
				<app-feed-post-dropdown
					class="dropdown is-spaced is-right is-neutral dropdown-trigger"
				></app-feed-post-dropdown>
			</div>
			<div class="card-body">
				<div class="post-text">
					<p>
						{{ video.postContent }}
						<a *ngIf="video.postTags && video.postTags.length > 0">
							<ng-container *ngFor="let tag of video.postTags">
								#{{ tag }}
							</ng-container>
						</a>
					</p>
					<p></p>
				</div>
				<div class="post-link is-video">
					<div class="wrap-video">
						<video
							[src]="video.postImg[0]?.url"
							controls
							controlsList="nodownload"
						></video>
					</div>
					<div
						class="like-wrapper"
						(click)="video.like ? deleteLike(video) : createLike(video)"
					>
						<a
							href="javascript:void(0);"
							class="like-button"
							[ngClass]="video.like ? 'is-active' : ''"
						>
							<i class="mdi mdi-heart not-liked bouncy"></i>
							<i class="mdi mdi-heart is-liked bouncy"></i>
							<span class="like-overlay"></span>
						</a>
					</div>
					<div class="fab-wrapper is-share">
						<a
							href="javascript:void(0);"
							class="small-fab share-fab modal-trigger"
							data-modal="share-modal"
						>
							<i-feather name="link-2"></i-feather>
						</a>
					</div>

					<div class="fab-wrapper is-comment">
						<a class="small-fab">
							<i-feather name="message-circle"></i-feather>
						</a>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<div class="likers-group">
					<img
						src="http://localhost/document/getFile/profile.png"
						data-demo-src="http://localhost/document/getFile/profile.png"
						data-user-popover="3"
						alt=""
					/>
					<img
						src="http://localhost/document/getFile/profile.png"
						data-demo-src="http://localhost/document/getFile/profile.png"
						data-user-popover="6"
						alt=""
					/>
				</div>
				<div class="likers-text">
					<p>
						<a href="#">Daniel</a>
					</p>
					<p>liked this</p>
				</div>
				<div class="social-count">
					<div class="likes-count">
						<i-feather name="heart"></i-feather>
						<span>{{ video.countLike ? video.countLike : 0 }}</span>
					</div>
					<div class="shares-count">
						<i-feather name="link-2"></i-feather>
						<span>0</span>
					</div>
					<div class="comments-count">
						<i-feather name="message-circle"></i-feather>
						<span>{{ video.comments ? video.comments.length : 0 }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="comments-wrap is-hidden">
			<div class="comments-heading">
				<h4>
					Comments
					<small>({{ video.comments ? video.comments.length : 0 }})</small>
				</h4>
				<div class="close-comments" (click)="closeComment()">
					<i-feather name="x"></i-feather>
				</div>
			</div>
			<ng-container *ngIf="video.comments && video.comments.length > 0">
				<div
					class="comments-body has-slimscroll"
					*ngFor="let comment of video.comments"
				>
					<div class="media is-comment">
						<div class="media-left">
							<div class="image">
								<img
									src="http://localhost/document/getFile/profile.png"
									data-demo-src="http://localhost/document/getFile/profile.png"
									data-user-popover="6"
									alt=""
								/>
							</div>
						</div>
						<div class="media-content">
							<a href="#"
								>{{ comment.userId.firstname }} {{ comment.userId.lastname }}
							</a>
							<span class="time">{{ comment.createdAt | dateAgo }}</span>
							<p [innerHTML]="comment.commentContent"></p>
							<div class="controls">
								<div class="like-count">
									<i-feather
										name="thumbs-up"
										(click)="
											comment.like
												? deleteLikeComment(comment)
												: createLikeComment(comment)
										"
									></i-feather>
									<span>{{ comment.countLike }}</span>
								</div>
								<div class="reply">
									<a (click)="focusReply(comment, i)">Reply</a>
								</div>
							</div>
							<ng-container
								*ngIf="comment.replies && comment.replies.length > 0"
							>
								<div
									class="media is-comment"
									*ngFor="let reply of comment.replies"
								>
									<div class="media-left">
										<div class="image">
											<img
												src="http://localhost/document/getFile/profile.png"
												data-demo-src="http://localhost/document/getFile/profile.png"
												data-user-popover="3"
												alt=""
											/>
										</div>
									</div>
									<div class="media-content">
										<a
											>{{ reply.userId.firstname }}
											{{ reply.userId.lastname }}</a
										>
										<span class="time">{{ reply.createdAt | dateAgo }}</span>
										<p [innerHTML]="reply.replyContent"></p>
										<div class="controls">
											<div class="like-count">
												<i-feather
													name="thumbs-up"
													(click)="
														reply.like
															? deleteLikeReply(reply)
															: createLikeReply(reply)
													"
												></i-feather>
												<span>{{ reply.countLike }}</span>
											</div>
											<!-- <div class="reply">
											<a>Reply</a>
										</div> -->
										</div>
									</div>
									<div class="media-right">
										<div
											class="
												dropdown
												is-spaced is-right is-neutral
												dropdown-trigger
											"
										>
											<div>
												<div class="button">
													<i-feather name="more-vertical"></i-feather>
												</div>
											</div>
											<div class="dropdown-menu" role="menu">
												<div class="dropdown-content">
													<a class="dropdown-item">
														<div class="media">
															<i-feather name="x"></i-feather>
															<div class="media-content">
																<h3>Hide</h3>
																<small>Hide this comment.</small>
															</div>
														</div>
													</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item">
														<div class="media">
															<i-feather name="flag"></i-feather>
															<div class="media-content">
																<h3>Report</h3>
																<small>Report this comment.</small>
															</div>
														</div>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
						<div class="media-right">
							<div
								class="dropdown is-spaced is-right is-neutral dropdown-trigger"
							>
								<div>
									<div class="button">
										<i-feather name="more-vertical"></i-feather>
									</div>
								</div>
								<div class="dropdown-menu" role="menu">
									<div class="dropdown-content">
										<a class="dropdown-item">
											<div class="media">
												<i-feather name="x"></i-feather>
												<div class="media-content">
													<h3>Hide</h3>
													<small>Hide this comment.</small>
												</div>
											</div>
										</a>
										<div class="dropdown-divider"></div>
										<a href="#" class="dropdown-item">
											<div class="media">
												<i-feather name="flag"></i-feather>
												<div class="media-content">
													<h3>Report</h3>
													<small>Report this comment.</small>
												</div>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
			<div class="card-footer">
				<div class="media post-comment has-emojis">
					<div class="media-content">
						<div class="field">
							<div
								[attr.id]="i + '-video-content'"
								placeholder="Write a comment..."
								class="tag-fr textarea"
								contenteditable="true"
								(keyup)="addLabelVideo($event, i); mentionUser(i)"
								(paste)="onPaste($event)"
							></div>
							<div class="list-user" *ngIf="arrTags && arrTags.length > 0">
								<div
									class="user"
									*ngFor="let user of arrTags"
									(click)="addMentionUser(user, i)"
								>
									<img [src]="user.avatar ? user.avatar : ''" alt="" />
									<p>
										{{ user.firstname }} {{ user.lastname }} ({{ user.email }})
									</p>
								</div>
							</div>
						</div>
						<div class="actions">
							<div class="image is-32x32">
								<img
									class="is-rounded"
									src="http://localhost/document/getFile/profile.png"
									data-demo-src="http://localhost/document/getFile/profile.png"
									data-user-popover="0"
									alt=""
								/>
							</div>
							<div class="toolbar">
								<div class="action is-auto">
									<i-feather name="at-sign"></i-feather>
								</div>
								<div class="action is-emoji" id="post-comment-button-2">
									<i-feather name="smile"></i-feather>
								</div>
								<div class="action is-upload">
									<i-feather name="camera"></i-feather>
									<input type="file" (change)="preview($event, i)" multiple />
								</div>
								<a
									class="button is-solid primary-button raised"
									(click)="createCmt(video, i)"
									>Post Comment</a
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card is-post" *ngIf="video && video.postUserId">
		<div class="content-wrap">
			<div class="card-heading">
				<div class="user-block">
					<div class="image">
						<img
							src="{{ video.postUserId.avatar }}"
							data-demo-src="assets/img/avatars/edward.jpeg"
							data-user-popover="5"
							alt=""
						/>
					</div>
					<div class="user-info">
						<a href="#">
							{{ video.postUserId.firstname }} {{ video.postUserId.lastname }}
						</a>
						<span class="time">
							{{ video.createdAt | date: "MMM dd, y, h:mm a" }}</span
						>
					</div>
				</div>
				<app-feed-post-dropdown
					class="dropdown is-spaced is-right is-neutral dropdown-trigger"
				></app-feed-post-dropdown>
			</div>
			<div class="card-body">
				<div class="post-text">
					<p>
						{{ video.postContent }}
						<a *ngIf="video.postTags && video.postTags.length > 0">
							<ng-container *ngFor="let tag of video.postTags">
								#{{ tag }}
							</ng-container>
						</a>
					</p>
					<p></p>
				</div>
				<div class="post-link is-video">
					<div class="wrap-video">
						<video
							[src]="video.postImg[0]?.url"
							controls
							controlsList="nodownload"
						></video>
					</div>
					<div
						class="like-wrapper"
						(click)="video.like ? deleteLike(video) : createLike(video)"
					>
						<a
							href="javascript:void(0);"
							class="like-button"
							[ngClass]="video.like ? 'is-active' : ''"
						>
							<i class="mdi mdi-heart not-liked bouncy"></i>
							<i class="mdi mdi-heart is-liked bouncy"></i>
							<span class="like-overlay"></span>
						</a>
					</div>
					<div class="fab-wrapper is-share">
						<a
							href="javascript:void(0);"
							class="small-fab share-fab modal-trigger"
							data-modal="share-modal"
						>
							<i-feather name="link-2"></i-feather>
						</a>
					</div>

					<div class="fab-wrapper is-comment">
						<a class="small-fab">
							<i-feather name="message-circle"></i-feather>
						</a>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<div class="likers-group">
					<img
						src="http://localhost/document/getFile/profile.png"
						data-demo-src="http://localhost/document/getFile/profile.png"
						data-user-popover="3"
						alt=""
					/>
					<img
						src="http://localhost/document/getFile/profile.png"
						data-demo-src="http://localhost/document/getFile/profile.png"
						data-user-popover="6"
						alt=""
					/>
				</div>
				<div class="likers-text">
					<p>
						<a href="#">Daniel</a>
					</p>
					<p>liked this</p>
				</div>
				<div class="social-count">
					<div class="likes-count">
						<i-feather name="heart"></i-feather>
						<span>{{ video.countLike ? video.countLike : 0 }}</span>
					</div>
					<div class="shares-count">
						<i-feather name="link-2"></i-feather>
						<span>0</span>
					</div>
					<div class="comments-count">
						<i-feather name="message-circle"></i-feather>
						<span>{{ video.comments ? video.comments.length : 0 }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="comments-wrap is-hidden">
			<div class="comments-heading">
				<h4>
					Comments
					<small>({{ video.comments ? video.comments.length : 0 }})</small>
				</h4>
				<div class="close-comments" (click)="closeComment()">
					<i-feather name="x"></i-feather>
				</div>
			</div>
			<ng-container *ngIf="video.comments && video.comments.length > 0">
				<div
					class="comments-body has-slimscroll"
					*ngFor="let comment of video.comments"
				>
					<div class="media is-comment">
						<div class="media-left">
							<div class="image">
								<img
									src="http://localhost/document/getFile/profile.png"
									data-demo-src="http://localhost/document/getFile/profile.png"
									data-user-popover="6"
									alt=""
								/>
							</div>
						</div>
						<div class="media-content">
							<a href="#"
								>{{ comment.userId.firstname }} {{ comment.userId.lastname }}
							</a>
							<span class="time">{{ comment.createdAt | dateAgo }}</span>
							<p [innerHTML]="comment.commentContent"></p>
							<div class="controls">
								<div class="like-count">
									<i-feather
										name="thumbs-up"
										(click)="
											comment.like
												? deleteLikeComment(comment)
												: createLikeComment(comment)
										"
									></i-feather>
									<span>{{ comment.countLike }}</span>
								</div>
								<div class="reply">
									<a (click)="focusReply(comment, i)">Reply</a>
								</div>
							</div>
							<ng-container
								*ngIf="comment.replies && comment.replies.length > 0"
							>
								<div
									class="media is-comment"
									*ngFor="let reply of comment.replies"
								>
									<div class="media-left">
										<div class="image">
											<img
												src="http://localhost/document/getFile/profile.png"
												data-demo-src="http://localhost/document/getFile/profile.png"
												data-user-popover="3"
												alt=""
											/>
										</div>
									</div>
									<div class="media-content">
										<a
											>{{ reply.userId.firstname }}
											{{ reply.userId.lastname }}</a
										>
										<span class="time">{{ reply.createdAt | dateAgo }}</span>
										<p [innerHTML]="reply.replyContent"></p>
										<div class="controls">
											<div class="like-count">
												<i-feather
													name="thumbs-up"
													(click)="
														reply.like
															? deleteLikeReply(reply)
															: createLikeReply(reply)
													"
												></i-feather>
												<span>{{ reply.countLike }}</span>
											</div>
											<!-- <div class="reply">
											<a>Reply</a>
										</div> -->
										</div>
									</div>
									<div class="media-right">
										<div
											class="
												dropdown
												is-spaced is-right is-neutral
												dropdown-trigger
											"
										>
											<div>
												<div class="button">
													<i-feather name="more-vertical"></i-feather>
												</div>
											</div>
											<div class="dropdown-menu" role="menu">
												<div class="dropdown-content">
													<a class="dropdown-item">
														<div class="media">
															<i-feather name="x"></i-feather>
															<div class="media-content">
																<h3>Hide</h3>
																<small>Hide this comment.</small>
															</div>
														</div>
													</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item">
														<div class="media">
															<i-feather name="flag"></i-feather>
															<div class="media-content">
																<h3>Report</h3>
																<small>Report this comment.</small>
															</div>
														</div>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
						<div class="media-right">
							<div
								class="dropdown is-spaced is-right is-neutral dropdown-trigger"
							>
								<div>
									<div class="button">
										<i-feather name="more-vertical"></i-feather>
									</div>
								</div>
								<div class="dropdown-menu" role="menu">
									<div class="dropdown-content">
										<a class="dropdown-item">
											<div class="media">
												<i-feather name="x"></i-feather>
												<div class="media-content">
													<h3>Hide</h3>
													<small>Hide this comment.</small>
												</div>
											</div>
										</a>
										<div class="dropdown-divider"></div>
										<a href="#" class="dropdown-item">
											<div class="media">
												<i-feather name="flag"></i-feather>
												<div class="media-content">
													<h3>Report</h3>
													<small>Report this comment.</small>
												</div>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
			<div class="card-footer">
				<div class="media post-comment has-emojis">
					<div class="media-content">
						<div class="field">
							<div
								[attr.id]="i + '-video-content'"
								placeholder="Write a comment..."
								class="tag-fr textarea"
								contenteditable="true"
								(keyup)="addLabelVideo($event, i); mentionUser(i)"
								(paste)="onPaste($event)"
							></div>
							<div class="list-user" *ngIf="arrTags && arrTags.length > 0">
								<div
									class="user"
									*ngFor="let user of arrTags"
									(click)="addMentionUser(user, i)"
								>
									<img [src]="user.avatar ? user.avatar : ''" alt="" />
									<p>
										{{ user.firstname }} {{ user.lastname }} ({{ user.email }})
									</p>
								</div>
							</div>
						</div>
						<div class="actions">
							<div class="image is-32x32">
								<img
									class="is-rounded"
									src="http://localhost/document/getFile/profile.png"
									data-demo-src="http://localhost/document/getFile/profile.png"
									data-user-popover="0"
									alt=""
								/>
							</div>
							<div class="toolbar">
								<div class="action is-auto">
									<i-feather name="at-sign"></i-feather>
								</div>
								<div class="action is-emoji" id="post-comment-button-2">
									<i-feather name="smile"></i-feather>
								</div>
								<div class="action is-upload">
									<i-feather name="camera"></i-feather>
									<input type="file" (change)="preview($event, i)" multiple />
								</div>
								<a
									class="button is-solid primary-button raised"
									(click)="createCmt(video, i)"
									>Post Comment</a
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<div class="load-more-wrap has-text-centered">
	<a class="load-more-button" (click)="addMoreVideo()"> Load More </a>
</div>
