import { Component, OnInit } from "@angular/core";
import { VideoService } from "src/app/services/video.service";
import { UploadService } from "src/app/services/upload.service";
import { ProfileService } from "src/app/services/profile.service";
import { PagesService } from "src/app/services/pages.service";
import { GroupService } from "src/app/services/group/group.service";
import { FeedService } from "src/app/services/feed.service";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import * as environment from "src/environments/environment";
import * as loadjs from "../../../pages/feed-layout/pagelayout";
declare var jQuery: any;
declare function initModals(): any;
import jwt_decode from "jwt-decode";
@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"],
})
export class VideoComponent implements OnInit {
  environment = environment.environment.apiUrl;
  infoUser;
  constructor(
    private videoService: VideoService,
    private sanitizer: DomSanitizer,
    private profileService: ProfileService,
    private upload: UploadService,
    private pageService: PagesService,
    private groupService: GroupService,
    private feedService: FeedService
  ) {
    if (this.arrDraft.length > 0) {
      window.addEventListener("beforeunload", (event) => {
        event.preventDefault();
        event.returnValue = "Unsaved modifications";
        return event;
      });
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      loadjs.loadjs();
      this.getVideos();
      loadjs.loadjs();
    }, 100);
    if (localStorage.getItem("token")) {
      this.infoUser = jwt_decode(localStorage.getItem("token"));
    }
  }

  dataVideos = [];
  closeComment() {
    (function ($) {
      $(".close-comments").on("click", function (e) {
        $(this)
          .addClass("is-active")
          .closest(".card")
          .find(".comments-wrap")
          .addClass("is-hidden");
        var jump = $(this).closest(".is-post");
        var new_position = $(jump).offset();
        $("html, body")
          .stop()
          .animate({ scrollTop: new_position.top - 70 }, 500);
        e.preventDefault();
        setTimeout(function () {
          $(".emojionearea-editor").val("");
        }, 400);
      });
    })(jQuery);
  }
  async getVideos() {
    let dataVideos = await this.pageService.getVideoByPage(this.parameters).toPromise();
    this.dataVideos = dataVideos.data

    if (dataVideos["data"] && dataVideos["data"].length > 0) {
      let arrVideoId = [];
      let arrVideoGroupId = [];
      let arrVideoUserId = [];
      let dataVideosPage = [];
      let dataVideosGroup = [];
      let dataVideosUser = [];
      for (let i of dataVideos["data"]) {
        if (i.postPageId) {
          dataVideosPage.push(i);
          arrVideoId.push(i._id);
        } else if (i.postGroupId) {
          dataVideosGroup.push(i)
          arrVideoGroupId.push(i._id);
        } else {
          dataVideosUser.push(i)
          arrVideoUserId.push(i._id);
        }
      }
      // this.getLikes(dataVideosPage, arrVideoId);
      // this.getLikesGroup(dataVideosGroup, arrVideoGroupId)
      // this.getComments(dataVideosUser, arrVideoUserId);
    }
  }

  async getLikes(dataVideos, arrVideoId) {
    let dataLike = await this.pageService
      .getLikeByArrVideo({ arrVideo: arrVideoId })
      .toPromise();
    if (dataLike && dataLike["data"].length > 0) {
      for (let i of dataVideos["data"]) {
        i.countLike = 0;
        for (let j of dataLike["data"]) {
          if (i._id == j.postId) {
            i.like = true;
            i.countLike++;
          }
        }
      }
    }
  }

  async getLikesGroup(dataVideos, arrVideoId) { }
  async getLikesUser(dataVideos, arrVideoId) { }

  async getComments(dataVideos, arrVideoId) {
    let dataComment = await this.pageService
      .getCommentsByArrayVideo({ arrVideo: arrVideoId })
      .toPromise();
    if (dataComment && dataComment["data"].length > 0) {
      this.getReplies(dataComment, arrVideoId);
      for (let i of dataVideos["data"]) {
        i.comments = [];
        for (let j of dataComment["data"]) {
          if (i._id == j.postId) {
            i.comments.push(j);
          }
        }
      }
      //this.getLikeComments(dataComment, arrVideoId);
    }
  }

  async getCommentsGroup(dataVideos, arrVideoId) { }
  async getCommentsUser(dataVideos, arrVideoId) { }

  async getReplies(dataComment, arrVideoId) {
    let dataReply = await this.pageService
      .getRepliesByArrVideo({ arrVideo: arrVideoId })
      .toPromise();
    if (dataReply && dataReply["data"].length > 0) {
      for (let i of dataComment["data"]) {
        i.replies = [];
        for (let j of dataReply["data"]) {
          if (i._id == j.commentId) {
            i.replies.push(j);
          }
        }
      }
      //this.getLikeReplies(dataReply, arrVideoId);
    }
  }

  async getRepliesGroup(dataComment, arrVideoId) {

  }

  async getRepliesUser(dataComment, arrVideoId) { }

  parameters = {
    skip: 0,
    limit: 5,
  };

  addMoreVideo() {
    this.parameters.skip = this.parameters.skip + 5;
    setTimeout(() => {
      this.getVideos();
    }, 1000);
  }

  createLike(video) {
    let data = {
      postId: video._id,
      type: 0
    };
    this.pageService.createLikePagePost(data).subscribe((result) => {
      if (result.status == "success") {
        video.like = true;
      }
    });
  }

  deleteLike(video) {
    this.pageService.unLikePagePost(video._id).subscribe((result) => {
      if (result.status == "success") {
        video.like = false;
      }
    });
  }
  arrTags;
  model;
  usernameFilter;
  firstPos = 0;
  async mentionUser(index) {
    this.model = document.getElementById(index + "-video-content").innerHTML;
    var start = /@/gi;
    var word = /@(\w+)/gi;
    let str;
    if (this.firstPos <= this.model.length) {
      str = this.model.slice(this.firstPos, this.model.length);
    } else {
      str = this.model;
    }
    if (str && str.indexOf("@") > -1) {
      var go = str.match(start);
      this.arrTags = str.match(word);
      if (go.length > 0) {
        if (this.arrTags) {
          let count = 0;
          if (this.arrTags.length > 0) {
            count = this.arrTags.length - 1;
          }
          let usernameFilter = this.arrTags[count].toString().slice(0);
          this.usernameFilter = usernameFilter;
          let friends = await this.profileService
            .searchUsers({ search: usernameFilter.slice(1) })
            .toPromise();
          if (friends.status == "success") {
            this.arrTags = friends.data;
          }
        } else {
          this.arrTags = [];
        }
      }
    }
  }
  addArrTags = [];
  addMentionUser(user, index) {
    if (isExistInArray(user._id, this.addArrTags)) {
      this.addArrTags.push(user._id);
      this.model = document.getElementById(index + "-video-content").innerHTML;
      let content = `<a class="content">@${user.firstname} ${user.lastname}</a>&nbsp;`;
      this.model = this.model.replace(this.usernameFilter, content);
      document.getElementById(index + "-video-content").innerHTML = this.model;
      placeCaretAtEnd(document.getElementById(index + "-video-content"));
      this.model = document.getElementById(index + "-video-content").innerHTML;
      this.firstPos = this.model.length;
      this.arrTags = [];
    }
  }
  arrLabels;
  strLenModel = 0;
  async addLabelVideo(event, index) {
    this.model = document.getElementById(index + "-video-content").innerHTML;
    var start = /#/gi;
    var word = /#(\w+)/gi;
    let str;
    if (this.firstPos <= this.model.length) {
      str = this.model.slice(this.firstPos, this.model.length);
    } else {
      str = this.model;
    }
    if (str && str.indexOf("#") > -1) {
      var go = str.match(start);
      this.arrLabels = str.match(word);
      if (go.length > 0) {
        if (this.arrLabels) {
          let count = 0;
          if (this.arrLabels.length > 0) {
            count = this.arrLabels.length - 1;
          }
          let usernameFilter = this.arrLabels[count].toString().slice(0);
          if (event.code == "Space") {
            if (isExistInArray(usernameFilter, this.arrLabels)) {
              this.arrLabels.push(usernameFilter);
            }
            for (let i of this.arrLabels) {
              let content = `<a class="content">${i}</a>`;
              this.model = this.model.replace(i, content);
            }
            document.getElementById(
              index + "-video-content"
            ).innerHTML = this.model;
            this.model = document.getElementById(
              index + "-video-content"
            ).innerHTML;
            this.firstPos = this.model.length;
            placeCaretAtEnd(document.getElementById(index + "-video-content"));
          } else if (event.code == "Enter") {
            if (isExistInArray(usernameFilter, this.arrLabels)) {
              this.arrLabels.push(usernameFilter);
            }
            for (let i of this.arrLabels) {
              let content = `<a class="content">${i}</a></br>`;
              this.model = this.model.replace(i, content);
            }
            document.getElementById(
              index + "-video-content"
            ).innerHTML = this.model;
            this.model = document.getElementById(
              index + "-video-content"
            ).innerHTML;
            this.firstPos = this.model.length;
            placeCaretAtEnd(document.getElementById(index + "-video-content"));
          }
        }
      }
    }
  }
  arrImgComment;
  preview(event, index) {
    Array.from(event.target.files).forEach((file: File) => {
      let reader = new FileReader();
      reader.onload = async () => {
        var image = new Image();
        image.src = String(reader.result);
      };
      reader.readAsDataURL(file);
    });
  }
  arrDraft = [];
  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData.items;
    for (let i in clipboardData) {
      let item = clipboardData[i];
      if (item.kind == "file") {
        let blob = item.getAsFile();
        let reader = new FileReader();
        reader.onload = async () => {
          // let resImg = await this.upload
          //   .uploadDraft({ img: reader.result })
          //   .toPromise();
          this.arrDraft.push(reader.result);

        };
        reader.readAsDataURL(blob);
      }
    }
  }
  dataImgs;
  createCmt(video, index) {
    if (this.commentId) {
      this.createReply(video, index);
    } else {
      this.createComment(video, index);
    }
  }
  createComment(video, index) {
    let content = document.getElementById(index + "-video-content").innerHTML;
    let data = {
      postPageId: video._id,
      commentContent: content,
      commentTags: this.addArrTags,
      commentLabels: this.arrLabels,
      commentImg: this.dataImgs,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    this.pageService.createCommentPagePost(data).subscribe((result) => {
      document.getElementById(index + "-video-content").innerHTML = "";
      video.comments.push(result["data"]);
    });
  }
  createReply(video, index) {
    let content = document.getElementById(index + "-video-content").innerHTML;
    let data = {
      commentId: this.commentId,
      videoId: video._id,
      replyContent: content,
      replyTags: this.addArrTags,
      replyLabels: this.arrLabels,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    this.videoService.createReply(data).subscribe((result) => {
      document.getElementById(index + "-video-content").innerHTML = "";
      for (let i of video.comments) {
        if (i._id === this.commentId) {
          i.replies.push(result["data"]);
          this.commentId = undefined;
        }
      }
    });
  }
  updateCmt(comment) {
    let data = {};
    this.videoService.updateComment(comment._id, data).subscribe((result) => {
      for (let i of this.dataVideos) {
      }
    });
  }
  deleteCmt(comment) {
    this.videoService.deleteComment(comment._id).subscribe((result) => { });
  }
  commentId;
  focusReply(comment, index) {
    this.commentId = comment._id;
    if (isExistInArray(comment.userId._id, this.addArrTags)) {
      this.addArrTags.push(comment.userId._id);
      this.model = document.getElementById(index + "-video-content").innerHTML;
      let content = `<a class="content">@${comment.userId.firstname} ${comment.userId.lastname}</a>&nbsp;`;
      this.model = content;
      document.getElementById(index + "-video-content").innerHTML = this.model;
      placeCaretAtEnd(document.getElementById(index + "-video-content"));
      this.model = document.getElementById(index + "-video-content").innerHTML;
      this.firstPos = this.model.length;
      this.arrTags = [];
    }
  }
  async getLikeComments(dataComments, arrVideos) {
    let commentLike = await this.videoService
      .getCommentLikeByArrVideos({ arrVideo: arrVideos })
      .toPromise();
    if (dataComments && dataComments["data"].length > 0) {
      for (let i of dataComments["data"]) {
        i.countLike = 0;
        i.like = false;
        for (let j of commentLike["data"]) {
          if (i._id == j.commentId) {
            i.countLike++;
          }
          if (j.userId == this.infoUser._id && j.commentId == i._id) {
            i.like = true;
          }
        }
      }
    }
  }
  async getLikeReplies(dataReply, arrVideos) {
    let replyLike = await this.videoService
      .getReplyLikeByArrVideos({ arrVideo: arrVideos })
      .toPromise();
    if (dataReply && dataReply["data"].length > 0) {
      for (let i of dataReply["data"]) {
        i.countLike = 0;
        i.like = false;
        for (let j of replyLike["data"]) {
          if (i._id == j.replyId) {
            i.countLike++;
          }
          if (j.userId == this.infoUser._id && j.replyId == i._id) {
            i.like = true;
          }
        }
      }
    }
  }
  createLikeComment(comment) {
    this.videoService
      .createCommentLike({ commentId: comment._id, videoId: comment.videoId })
      .subscribe((result) => {
        comment.like = true;
        comment.countLike++;
      });
  }
  createLikeReply(reply) {
    this.videoService
      .createReplyLike({ replyId: reply._id, videoId: reply.videoId })
      .subscribe((result) => {
        reply.like = true;
        reply.countLike++;
      });
  }
  deleteLikeComment(comment) {
    this.videoService.deleteCommentLike(comment._id).subscribe((result) => {
      comment.like = false;
      comment.countLike--;
    });
  }
  deleteLikeReply(reply) {
    this.videoService.deleteReplyLike(reply._id).subscribe((result) => {
      reply.like = false;
      reply.countLike--;
    });
  }
}
function placeCaretAtEnd(el) {
  el.focus();
  if (
    typeof window.getSelection != "undefined" &&
    typeof document.createRange != "undefined"
  ) {
    var range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }
}

function isExistInArray(value, arr) {
  let check = true;
  for (let i of arr) {
    if (i == value) {
      check = false;
      break;
    }
  }
  return check;
}

function isExistIdInArray(value, arr) {
  let check = true;
  for (let i of arr) {
    if (i._id == value) {
      check = false;
      break;
    }
  }
  return check;
}
