import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import iziToast from 'izitoast';
import { GroupService } from 'src/app/services/group/group.service';
import { PagesService } from 'src/app/services/pages.service';
import { ProfileService } from 'src/app/services/profile.service';

//import json
import * as arrCountry from '../../../../../assets/data/country.json';
import * as arrArchitects from '../../../../../assets/data/architect.json';
import * as arrCategories from '../../../../../assets/data/categories.json';
import * as arrColor from '../../../../../assets/data/color.json';
import { FeedService } from 'src/app/services/feed.service';
import { async } from 'rxjs/internal/scheduler/async';
declare var Dropzone: any;
declare var $: any;
@Component({
  selector: 'app-albums-modal',
  templateUrl: './albums-modal.component.html',
  styleUrls: ['./albums-modal.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class AlbumsModalComponent implements OnInit {
  hoveredIndex;
  dataGroups;
  isGroup: boolean = false;
  @Input() set dataGroup(dataGroup) {
    this.dataGroups = dataGroup;
    this.isGroup = this.dataGroups != null ? true : false;
  }

  @Input() dataUser;
  ngOnChanges() { }
  arrCategories = [];
  arrCountryPopular = [
    { name: 'United States', code: 'US' },
    { name: 'China', code: 'CN' },
    { name: 'Spain', code: 'ES' },
    { name: 'Japan', code: 'JP' },
    { name: 'Australia', code: 'AU' },
    { name: 'Brazil', code: 'BR' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Germany', code: 'DE' },
    { name: 'Viet Nam', code: 'VN' },
    { name: 'Italy', code: 'IT' },
    { name: 'France', code: 'FR' },
  ];

  arrArchitects = [];
  arrArchitectPopular = [
    // { value: 0, title: 'M-Arquitectos', isActive: false },
    // { value: 1, title: 'Olson Kundig', isActive: false },
    // { value: 2, title: 'Foster + Partners', isActive: false },
    // { value: 3, title: 'Zaha Hadid Architects', isActive: false },
    // { value: 4, title: 'Schemata Architects', isActive: false },
    // { value: 5, title: 'IDOM', isActive: false },
    // { value: 6, title: 'Gmp Architects', isActive: false },
    // { value: 7, title: 'VTN Architects', isActive: false },
    // { value: 8, title: 'NatureHumaine', isActive: false },
    // { value: 9, title: 'SAOTA', isActive: false },
    // { value: 10, title: 'Pitsou Kedem Architects', isActive: false },
    // { value: 11, title: 'MVRDV', isActive: false },
    // { value: 12, title: 'OMA', isActive: false },
  ];

  dataAlbum = {
    albumName: '',
    albumPhoto: '',
    albumDescription: '',
    albumPlace: '',
    albumDatePrview: '',
    albumDate: '',
    albumTags: [],
    allowFriends: false,
    albumPrivacy: '',
    albumCategories: { categorieFather: undefined, categoriesChild: undefined },
    albumCountries: undefined,
    albumArchited: undefined,
    albumYear: undefined,
    albumMaterial: undefined,
    albumColor: undefined,
    albumArea: undefined,
  };

  arrMaterials = [
    { value: 0, title: 'Brick', isActive: false },
    { value: 1, title: 'Concrete', isActive: false },
    { value: 2, title: 'Fabric', isActive: false },
    { value: 3, title: 'Glass', isActive: false },
    { value: 4, title: 'Other', isActive: false },
    { value: 5, title: 'Plastic', isActive: false },
    { value: 6, title: 'Steel', isActive: false },
    { value: 7, title: 'Stone', isActive: false },
    { value: 8, title: 'Wood', isActive: false },
  ];

  arrColor = [];
  constructor(
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private pageService: PagesService,
    private groupService: GroupService,
    private feedService: FeedService
  ) { }

  pageId;
  isPage: boolean = false;
  urlCurrent: string;
  arrCountry: [];
  arrYears = [];
  arrAllArchitect = [];
  arrCountries = [];
  //#region TrackBy ngFor
  trackByFn(index, item) {
    return index;
  }

  //#endregion
  arrMainCategorie = [];
  ngOnInit(): void {
    this.arrColor = arrColor['default'];
    this.arrCategories = arrCategories['default'];
    this.arrCountry = arrCountry['default'];
    this.arrArchitects = arrArchitects['default'];
    this.urlCurrent = document.URL;
    this.isPage = this.urlCurrent.search('pages') != -1 ? true : false;
    this.pageId = this.route.snapshot.paramMap.get('id');
    let yearCurrent = new Date().getFullYear();
    for (let i = 0; i < 120; i++) {
      this.arrYears.push(Number(yearCurrent - i));
    }

    this.arrCountries = [
      { arrPopular: this.arrCountryPopular, isMostPopular: true },
      { arrCountries: this.arrCountry, isMostPopular: false },
    ];
    this.arrAllArchitect = [
      {
        arrPopular: this.arrArchitectPopular,
        isMostPopular: true,
      },
      { arrArchitects: this.arrArchitects, isMostPopular: false },
    ];
  }

  onClick(event) { }
  placeHolderYear = 'Year';
  //#region FUNCTION change color
  titleColor;
  color;
  placeHolderColor = 'Color';
  changeColor(color) {
    this.arrColor.forEach((element) => {
      element.isActive = element.color === color.color ? true : false;
    });
    this.color = color.color;
    this.titleColor = color.name;
    this.dataAlbum.albumColor = color.color;
    this.closeDropDown();
  }
  clearColor() {
    this.arrColor.forEach((element) => {
      element.isActive = false;
    });
    this.color = undefined;
    this.titleColor = undefined;
    this.dataAlbum.albumColor = undefined;
    this.closeDropDown();
  }

  dropDownId;
  //#endregion
  dropDown(id) {
    if (this.dropDownId === id) {
      this.dropDownId = undefined;
      return;
    }
    this.dropDownId = id;
  }

  closeDropDown() {
    this.dropDownId = undefined;
  }

  titleArea = 'Area';
  areaMin = 11;
  areaMax = 3767365;
  s;
  meanArea = 10.7639;

  btnAreaId = 0;
  changeArea(areaBtn) {
    this.btnAreaId = areaBtn;

    if (this.btnAreaId == 0) {
      let min = this.areaMin * this.meanArea;
      let max = this.areaMax * this.meanArea;
      this.areaMin = Number(this.initPrecise(min));
      this.areaMax = Number(this.initPrecise(max));
      return;
    }
    if (this.btnAreaId == 1) {
      let min = this.areaMin / this.meanArea;
      let max = this.areaMax / this.meanArea;
      this.areaMin = Number(this.initPrecise(min));
      this.areaMax = Number(this.initPrecise(max));
      return;
    }
  }

  initPrecise(numberFloat) {
    return Number.parseFloat(numberFloat).toPrecision(2);
  }
  isApply: boolean = false;
  applyArea() {
    this.isApply = true;
    this.dataAlbum.albumArea = {
      value: this.btnAreaId,
      areaMin: this.areaMin,
      areaMax: this.areaMax,
    };
    this.closeDropDown();
  }

  clearArea() {
    this.areaMin = 11;
    this.areaMax = 3767355;
    this.isApply = false;
    this.dataAlbum.albumArea = undefined;
    this.closeDropDown();
  }

  //#region FUNCTION select year
  year;
  searchYear;
  selectYear(year) {
    this.year = year;
    this.dataAlbum.albumYear = year;
    this.searchYear = undefined;
    this.closeDropDown();
  }
  clearYear() {
    this.year = undefined;
    this.searchYear = undefined;
    this.dataAlbum.albumYear = undefined;
    this.closeDropDown();
  }
  //#endregion
  //#region FUNCTION select categories
  itemCategories;
  placeHolderSelect: string = 'Categories';
  hoveredIndexItem;
  searchCate;
  selectCategories(categories) {
    this.itemCategories = categories.title;
    categories.isActive = true;
    this.arrCategories.find((element) => {
      element.isActive = element.value === categories.value ? true : false;
    });
    this.subCategories = undefined;
    this.dataAlbum.albumCategories.categoriesChild = undefined;
    this.dataAlbum.albumCategories.categorieFather = categories.title;
    this.searchCate = undefined;
    this.closeDropDown();
  }

  subCategories;
  selectItemCategories(categories, subCategories) {
    this.subCategories = subCategories.title;
    this.dataAlbum.albumCategories.categorieFather = categories.title;
    this.dataAlbum.albumCategories.categoriesChild = subCategories.title;
    this.searchCate = undefined;
    this.closeDropDown();
  }

  clearCategories() {
    this.arrCategories.find((element) => {
      if (element.isActive === true) {
        element.isActive = false;
      }
    });
    this.subCategories = undefined;
    this.itemCategories = undefined;
    this.dataAlbum.albumCategories.categoriesChild = undefined;
    this.dataAlbum.albumCategories.categorieFather = undefined;
    this.searchCate = undefined;
    this.closeDropDown();
    this.clearItemCate();
  }

  clearItemCate() {
    this.subCategories = undefined;
    this.dataAlbum.albumCategories.categoriesChild = undefined;
    this.closeDropDown();
  }
  //#endregion

  //#region FUNCTION select countries
  countries;
  searchCountries;
  selectCountries(countries) {
    this.countries = countries.name;
    this.dataAlbum.albumCountries = countries.name;
    this.searchCountries = undefined;
    this.closeDropDown();
  }
  clearCountries() {
    this.countries = undefined;
    this.dataAlbum.albumCountries = undefined;
    this.searchCountries = undefined;

    this.closeDropDown();
  }
  //#endregion

  //#region FUNCTION select architect
  architect;
  searchArchitect;
  selectArchitect(architect) {
    console.log(architect);

    this.architect = architect.title;
    this.dataAlbum.albumArchited = architect.title;
    this.searchArchitect = undefined;
    this.closeDropDown();
  }
  clearArchitect() {
    this.architect = undefined;
    this.searchArchitect = undefined;
    this.dataAlbum.albumArchited = undefined;
    this.closeDropDown();
  }
  //#endregion

  //#region FUNCTION select materials
  material;
  searchMaterial;
  selectMaterial(material) {
    this.material = material.title;
    this.searchMaterial = undefined;
    this.dataAlbum.albumMaterial = material.title;
    this.closeDropDown();
  }

  clearMaterial() {
    this.material = undefined;
    this.searchMaterial = undefined;
    this.dataAlbum.albumMaterial = undefined;
    this.closeDropDown();
  }
  //#endregion
  ReceiveDate(date) {
    this.dataAlbum.albumDatePrview = date;
    this.dataAlbum.albumDate = date;
  }

  //#region FUNCTION create Album user
  create() {
    this.feedService.uploadFile(this.imgFile).subscribe((result) => {
      let arrImg = result;
      if (this.arrImgPage.length > 0) {
        for (let i = 0; i < this.arrImgPage.length; i++) {
          const imgC = this.arrImgPage[i];
          const imgN = arrImg[i];
          imgC.url = imgN.location;
          imgC.filename = imgN.originalname;
          imgC._id = imgN._id
        }
      }
      let data = {
        albumName: this.dataAlbum.albumName,
        albumPhoto: this.dataAlbum.albumPhoto,
        albumDescription: this.dataAlbum.albumDescription,
        albumPlace: this.dataAlbum.albumPlace,
        albumPrivacy: this.dataAlbum.albumPrivacy,
        albumCategories: this.dataAlbum.albumCategories,
        albumCountries: this.dataAlbum.albumCountries,
        albumArchited: this.dataAlbum.albumArchited,
        albumYear: this.dataAlbum.albumYear,
        albumMaterial: this.dataAlbum.albumMaterial,
        albumColor: this.dataAlbum.albumColor,
        albumArea: this.dataAlbum.albumArea,
      };
      this.profileService.createAlbum(data).subscribe((album) => {
        let dataAlbum;
        if (album.status == 'success') {
          dataAlbum = album.data;
          iziToast.success({ message: 'created album success.' });
          this.createPost(this.dataAlbum);
          if (this.arrImgPage.length > 0) {
            for (let img of this.arrImgPage) {
              let requestPhoto = {
                albumId: dataAlbum._id,
                photoName: img.url,
                photoDescription: img.filename,
              };
              this.profileService
                .createPhoto(requestPhoto)
                .subscribe((result) => { });
            }
            this.clearInput();
          }
        } else {
          this.showMessage();
          this.clearInput();
        }
      });
    });
  }
  //#endregion

  //#region Create Album of Fanpages
  arrPhoto = [];
  photoDescription;
  albumPhoto;
  createAlbumPage() {
    this.feedService.uploadFile(this.imgFile).subscribe((result) => {
      let arrImg = result.filename;
      if (this.arrImgPage.length > 0) {
        for (let i = 0; i < this.arrImgPage.length; i++) {
          const imgC = this.arrImgPage[i];
          const imgN = arrImg[i];
          imgC.url = imgN.url;
          imgC.filename = imgN.filename;
        }
      }
      let requestData = {
        pageId: this.pageId,
        albumName: this.dataAlbum.albumName,
        albumPhoto: this.dataAlbum.albumPhoto,
        albumDescription: this.dataAlbum.albumDescription,
        albumPlace: this.dataAlbum.albumPlace,
        albumDate: this.dataAlbum.albumDate,
        albumPrivacy: this.dataAlbum.albumPrivacy,
        albumCategories: this.dataAlbum.albumCategories,
        albumCountries: this.dataAlbum.albumCountries,
        albumArchited: this.dataAlbum.albumArchited,
        albumYear: this.dataAlbum.albumYear,
        albumMaterial: this.dataAlbum.albumMaterial,
        albumColor: this.dataAlbum.albumColor,
        albumArea: this.dataAlbum.albumArea,
      };
      this.pageService.createAlbum(requestData).subscribe((result) => {
        if (result.status == 'success') {
          iziToast.success({ message: 'created album success.' });
          let dataAlbum = result.data;
          const albumId = dataAlbum._id;
          let lengthPhoto = this.arrImg.length;
          this.createPostPage(this.dataAlbum);
          if (lengthPhoto > 0) {
            for (let img of this.arrImgPage) {
              let requestPhoto = {
                pageId: this.pageId,
                albumId: albumId,
                photo: img.url,
                photoDescription: img.filename,
              };
              this.createPhoto(requestPhoto);
            }
            this.clearInput();
          }
        } else {
          this.showMessage();
          this.clearInput();
        }
      });
    });
  }
  //#endregion

  //#region Create photo in album of Fanpages
  createPhoto(requestPhoto) {
    this.pageService.createPhoto(requestPhoto).subscribe((result) => {
      if (result.status == 'success') {
      }
    });
  }
  //#endregion

  //#region Create album in group
  createAlbumGroup() {
    this.feedService.uploadFile(this.imgFile).subscribe((result) => {
      let arrImg = result.filename;
      if (this.arrImgPage.length > 0) {
        for (let i = 0; i < this.arrImgPage.length; i++) {
          const imgC = this.arrImgPage[i];
          const imgN = arrImg[i];
          imgC.url = imgN.url;
          imgC.filename = imgN.filename;
        }
      }
      let reqGroup = {
        groupId: this.dataGroups._id,
        albumName: this.dataAlbum.albumName,
        albumPhoto: this.dataAlbum.albumPhoto,
        albumDescription: this.dataAlbum.albumDescription,
        albumPlace: this.dataAlbum.albumPlace,
        albumDate: this.dataAlbum.albumDate,
        albumPrivacy: this.dataAlbum.albumPrivacy,
        albumCategories: this.dataAlbum.albumCategories,
        albumCountries: this.dataAlbum.albumCountries,
        albumArchited: this.dataAlbum.albumArchited,
        albumYear: this.dataAlbum.albumYear,
        albumMaterial: this.dataAlbum.albumMaterial,
        albumColor: this.dataAlbum.albumColor,
        albumArea: this.dataAlbum.albumArea,
      };
      this.groupService.createAlbumPhoto(reqGroup).subscribe((result) => {
        if (result.status == 'success') {
          if (this.arrImg.length > 0) {
            for (let img of this.arrImgPage) {
              this.createPhotoGroup(result.data, img);
            }
          }
          this.createPostGroup(this.dataAlbum);
          iziToast.success({ message: 'created album success.' });
          this.groupService.submitPhoto({ status: 'sucess' });
        } else {
          this.showMessage();
        }
        this.clearInput();
      });
    })

  }

  createPhotoGroup(dataAlbum, image) {
    let reqPhoto = {
      groupId: this.dataGroups._id,
      photo: image.url,
      photoName: image.filename,
      albumId: dataAlbum._id,
    };
    this.groupService.createPhoto(reqPhoto).subscribe((result) => { });
  }
  //#endregion

  //#region Clear input
  clearInput() {
    this.arrImg = [];
    this.dataAlbum = {
      albumName: '',
      albumPhoto: '',
      albumDescription: '',
      albumPlace: '',
      albumDatePrview: '',
      albumDate: '',
      albumTags: [],
      allowFriends: false,
      albumPrivacy: '',
      albumCategories: {
        categorieFather: undefined,
        categoriesChild: undefined,
      },
      albumCountries: undefined,
      albumArchited: undefined,
      albumYear: undefined,
      albumMaterial: undefined,
      albumColor: undefined,
      albumArea: undefined,
    };
    this.clearColor();
    this.clearArea();
    this.clearYear();
    this.clearCategories();
    this.clearItemCate();
    this.clearCountries();
    this.clearArchitect();
    this.clearMaterial();
    this.arrImgPage = [];
  }
  //#endregion

  //#region Preview image
  arrImg = [];
  imgDefault = 'https://via.placeholder.com/120x120';
  arrImgPage = [];
  imgFile = new FormData();

  detectFiles(event) {
    let files = event.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        this.imgFile.append('file', files[i], files[i]['name']);
        let reader = new FileReader();
        reader.onload = (e: any) => {
          const image = e.target.result;
          const img = new Image();
          img.src = image;
          img.onload = () => {
            var height = img.height;
            var width = img.width;
            this.arrImg.push({
              url: image,
              filename: files[i].name,
              isDefault: false,
            });
            this.arrImgPage.push({
              url: image,
              filename: files[i].name,
              height: height,
              width: width,
            });
          };
        };
        reader.readAsDataURL(files[i]);
      }
    }
  }
  //#endregion

  //#region Delete image
  deleteImg(index) {
    this.arrImg.splice(index, 1);
  }
  deleteAll(event) {
    this.arrImg = [];
    this.arrImgPage = [];
    this.imgFile.delete('post-img');
    event.target.files = [];
  }
  //#endregion

  //#region Set default image of album
  formPhoto = new FormData();
  clickSetDefaultPhoto(urlImg, indexImg) {
    this.dataAlbum.albumPhoto = urlImg;
    let lengthArrImg = this.arrImg.length;
    for (let i = 0; i < lengthArrImg; i++) {
      this.arrImg[i].isDefault = indexImg == i ? true : false;
    }
  }
  //#endregion

  //#region FUNCTION create post after create successful album
  createPost(dataAlbum) {
    dataAlbum.albumDescription = 'Album - ' + dataAlbum.albumName;
    let data = {
      postContent: dataAlbum.albumDescription,
      postImg: this.arrImgPage,
      postDisplay: '0',
      postType: '0',
    };

    this.profileService.createPost(data).subscribe((result) => {
      if (result.status == 'success') {
        this.feedService.changeSuccessCreatePage({
          isSubmit: true,
        });
        for (let i of this.arrImgPage) {
          let photo = {
            albumId: '',
            photoName: i.url,
            photoDescription: this.dataAlbum.albumDescription,
          };
          this.profileService.createPhoto(photo).subscribe((resultPhoto) => {
            if (resultPhoto.status == 'success') {
            }
          });
        }
      }
    });
  }
  //#endregion

  //#region FUNCTION create post page
  createPostPage(dataAlbum) {
    dataAlbum.albumDescription = 'Album - ' + dataAlbum.albumName;
    let data = {
      postUserId: this.dataUser.userId,
      postPageId: this.pageId,
      postContent: dataAlbum.albumDescription,
      postImg: this.arrImgPage,
      postType: 0,
      postDisplay: 0,
      isActive: true,
    };
    this.pageService.createPost(data).subscribe((result) => {
      if (result.status == 'success') {
        this.pageService.changeListPost({ status: 'success' });
        this.clearInput();
      }
    });
  }

  //#endregion

  //#region FUNCTION create post group
  createPostGroup(dataAlbum) {
    dataAlbum.albumDescription = 'Album - ' + dataAlbum.albumName;
    let data = {
      postUserId: this.dataUser._id,
      postGroupId: this.dataGroups._id,
      postContent: dataAlbum.albumDescription,
      postImg: this.arrImgPage,
      postType: 0,
      postDisplay: 0,
    };
    this.groupService.createPostGroup(data).subscribe((result) => {
      if (result.status == 'success') {
        let dataPost = result.data;
        if (this.arrImgPage) {
          for (let img of this.arrImgPage) {
            this.createPhotoByPost(dataPost, img);
          }
        }
        this.groupService.isSuccessPost.next({ status: 'success' });
        this.clearInput();
      }
    });
  }
  createPhotoByPost(post, image) {
    let reqPhoto = {
      groupId: this.dataGroups._id,
      postId: post._id,
      photo: image,
      groupName: post._id,
    };
    this.groupService.createPhoto(reqPhoto).subscribe((result) => { });
  }
  //#endregion

  //#region FUNCTION message errors
  showMessage() {
    iziToast.warning({
      message: 'create album failed',
    });
  }
  //#endregion
}
