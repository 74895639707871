<div
  class="card is-new-job-card has-background-image"
  data-background="assets/img/illustrations/cards/job-bg.svg"
>
  <div class="card-heading">
    <i-feather name="briefcase"></i-feather>
    <div class="dropdown is-spaced is-right dropdown-trigger is-light">
      <div>
        <div class="button">
          <i-feather name="more-vertical"></i-feather>
        </div>
      </div>
      <div class="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <a href="#" class="dropdown-item">
            <div class="media">
              <i-feather name="clock"></i-feather>
              <div class="media-content">
                <h3>Remind me</h3>
                <small>Remind me of this later today.</small>
              </div>
            </div>
          </a>
          <a class="dropdown-item">
            <div class="media">
              <i-feather name="message-circle"></i-feather>
              <div class="media-content">
                <h3>Message</h3>
                <small>Send an automatic congratz message.</small>
              </div>
            </div>
          </a>
          <hr class="dropdown-divider" />
          <a href="#" class="dropdown-item">
            <div class="media">
              <i-feather name="trash-2"></i-feather>
              <div class="media-content">
                <h3>Remove</h3>
                <small>Removes this widget from your feed.</small>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div>
      <div class="job-avatar">
        <img
          src="https://via.placeholder.com/300x300"
          data-demo-src="assets/img/avatars/nelly.png"
          alt
        />
      </div>
      <div class="job-content">
        <h4>Nelly has a new job!</h4>
        <p>Send her message congratulating her for getting this job.</p>
        <button type="button" class="button light-button">Write Message</button>
      </div>
    </div>
  </div>
</div>
