<div class="dropdown compose-dropdown is-spaced is-accent is-up dropdown-trigger">
    <div>
        <div class="add-button">
            <div class="button-inner">
                <i-feather name="plus"></i-feather>
            </div>
        </div>
    </div>
    <div
        class="dropdown-menu"
        role="menu"
    >
        <div class="dropdown-content">
            <a
                href="#"
                class="dropdown-item"
            >
                <div class="media">
                    <i-feather name="code"></i-feather>
                    <div class="media-content">
                        <h3>Code snippet</h3>
                        <small>Add and paste a code snippet.</small>
                    </div>
                </div>
            </a>
            <a class="dropdown-item">
                <div class="media">
                    <i-feather name="file-text"></i-feather>
                    <div class="media-content">
                        <h3>Note</h3>
                        <small>Add and paste a note.</small>
                    </div>
                </div>
            </a>
            <hr class="dropdown-divider">
            <a class="dropdown-item">
                <div class="media">
                    <i-feather name="server"></i-feather>
                    <div class="media-content">
                        <h3>Remote file</h3>
                        <small>Add a file from a remote drive.</small>
                    </div>
                </div>
            </a>
            <a class="dropdown-item">
                <div class="media">
                    <i-feather name="monitor"></i-feather>
                    <div class="media-content">
                        <h3>Local file</h3>
                        <small>Add a file from your computer.</small>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
