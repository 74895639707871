<!-- /html/partials/pages/stories/modals/new-story-modal.html -->
<div id="new-story-modal" class="modal new-story-modal is-medium has-light-bg">
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-heading">
				<div class="small-avatar">
					<img
						class="avatar"
						[src]=" infoUsers?.avatar"
						data-demo-src="assets/img/avatars/jenna.png"
						data-user-popover="0"
						alt=""
					/>
				</div>
				<h3>New Story</h3>
				<!-- Close X button -->
				<div class="close-wrap">
					<span class="close-modal">
						<i-feather name="x"></i-feather>
					</span>
				</div>
			</div>
			<div class="card-body">
				<!-- Placeholder -->
				<div class="selection-placeholder">
					<div class="columns">
						<div class="column is-6">
							<!-- Selection box -->
							<div
								class="selection-box modal-trigger"
								data-modal="video-story-modal"
							>
								<div class="box-content">
									<img
										src="assets/img/illustrations/stories/video-story.svg"
										alt=""
									/>
									<div class="box-text">
										<span>Video Story</span>
										<span>Upload a video file</span>
									</div>
								</div>
							</div>
						</div>
						<div class="column is-6">
							<!-- Selection box -->
							<div
								class="selection-box modal-trigger"
								data-modal="image-story-modal"
							>
								<div class="box-content">
									<img
										src="assets/img/illustrations/stories/post-story.svg"
										alt=""
									/>
									<div class="box-text">
										<span>Post Story</span>
										<span>Upload some photos</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
