import { Component, Input, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import * as environment from 'src/environments/environment';

declare function initDropdowns(): any;

@Component({
  selector: 'app-profile-photos',
  templateUrl: './profile-photos.component.html',
  styleUrls: ['./profile-photos.component.scss'],
})
export class ProfilePhotosComponent implements OnInit {
  @Input() infouser;
  @Input() photos;
  token;
  environment = environment.environment.apiUrl;
  constructor(private ProfileService: ProfileService) { }

  ngOnInit(): void {
    //initDropdowns();
    this.clickLayoutActiveId(0);
  }

  openDropDown() {
    //initDropdowns();
  }

  layoutActiveId;
  clickLayoutActiveId(id) {
    this.layoutActiveId = id;
  }
}
