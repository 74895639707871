<div class="box-heading">
	<div class="dropdown photos-dropdown is-spaced is-neutral dropdown-trigger">
		<div>
			<div class="button" (click)="openDropDown()">
				<span>All Pictures</span>
				<i-feather name="chevron-down"></i-feather>
			</div>
		</div>
		<div class="dropdown-menu" role="menu">
			<div class="dropdown-content">
				<a href="#" class="dropdown-item">
					<div class="media">
						<i-feather name="tag"></i-feather>
						<div class="media-content">
							<h3>Tagged Photos</h3>
							<small>Photos whith this user tagged.</small>
						</div>
					</div>
				</a>
				<a class="dropdown-item">
					<div class="media">
						<i-feather name="clock"></i-feather>
						<div class="media-content">
							<h3>Recent</h3>
							<small>View most recent photos.</small>
						</div>
					</div>
				</a>
				<a class="dropdown-item">
					<div class="media">
						<i-feather name="heart"></i-feather>
						<div class="media-content">
							<h3>Popular</h3>
							<small>View popular photos.</small>
						</div>
					</div>
				</a>
				<a class="dropdown-item">
					<div class="media">
						<i-feather name="book-open"></i-feather>
						<div class="media-content">
							<h3>Albums</h3>
							<small>See all albums.</small>
						</div>
					</div>
				</a>
				<hr class="dropdown-divider" />
				<a
					class="dropdown-item modal-trigger"
					data-modal="albums-modal"
					(click)="openModal()"
				>
					<div class="media">
						<i-feather name="plus"></i-feather>
						<div class="media-content">
							<h3>Add Photos</h3>
							<small>Upload pictures.</small>
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>
	<div class="button-wrap">
		<button
			type="button"
			class="button"
			[ngClass]="layoutActiveId == 0 ? 'is-active' : ''"
			(click)="clickActiveLayout(0)"
		>
			Recent
		</button>
		<button
			type="button"
			class="button"
			[ngClass]="layoutActiveId == 1 ? 'is-active' : ''"
			(click)="clickActiveLayout(1)"
		>
			Albums
		</button>
	</div>
</div>
<div class="image-grid-wrap" *ngIf="layoutActiveId == 0">
	<div class="image-grid">
		<div class="image-row img-row-wrap">
			<ng-container>
				<ng-container *ngFor="let photo of arrPhotos; trackBy: trackHero">
					<div
						class="has-background-image"
						[ngStyle]="{
							'background-image': 'url(' + photo?.photo + ')'
						}"
					>
						<div class="overlay"></div>
						<div class="image-owner">
							<img
								class="avatar"
								[src]="photo?.userId?.avatar"
								[attr.data-demo-src]="photo?.userId?.avatar"
								alt
							/>
							<div class="name">
								{{ photo?.userId?.firstname }} {{ photo?.userId?.lastname }}
							</div>
						</div>
						<div class="photo-time">
							{{ photo?.createdAt | date: "MMM d, y" }}
						</div>
						<a class="photo-like" [ngClass]="photo.like ? 'is-liked' : ''">
							<div class="inner" (click)="photo.like = !photo.like">
								<div class="like-overlay"></div>
								<i-feather name="heart"></i-feather>
							</div>
						</a>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</div>

	<div class="load-more-wrap has-text-centered" *ngIf="layoutActiveId == 0">
		<a class="load-more-button" (click)="getMore()"> Load More </a>
	</div>
</div>

<ng-container *ngIf="layoutActiveId == 1">
	<app-group-album [dataGroup]="dataGroups"></app-group-album>
</ng-container>
<app-albums-modal [dataGroup]="dataGroups"></app-albums-modal>
<app-albums-help-modal></app-albums-help-modal>
