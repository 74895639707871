<div class="sticky-wrap-feed">
  <div class="box-heading" *ngIf="dataPage">
    <h4>Info</h4>
    <div class="dropdown is-neutral is-spaced is-right dropdown-trigger">
      <div>
        <div class="button">
          <i-feather name="more-vertical"></i-feather>
        </div>
      </div>
      <div class="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <a href="profile-about.html" class="dropdown-item">
            <div class="media">
              <i-feather name="eye"></i-feather>
              <div class="media-content">
                <h3>View</h3>
                <small>View user details.</small>
              </div>
            </div>
          </a>
          <a href="#" class="dropdown-item">
            <div class="media">
              <i-feather name="search"></i-feather>
              <div class="media-content">
                <h3>Related</h3>
                <small>Search for related users.</small>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="basic-infos-wrapper">
    <div class="card is-community">
      <h4>Community</h4>
      <div class="flex-block">
        <i-feather name="users"></i-feather>
        <p>
          <a>Invite your friends</a>
          to follow this page
        </p>
      </div>
      <div class="flex-block">
        <i-feather name="thumbs-up"></i-feather>
        <p>{{ countLike }} people like this page</p>
      </div>
      <div class="flex-block">
        <i-feather name="cast"></i-feather>
        <p>{{ countFollow }} <span></span> people are following this</p>
      </div>
    </div>

    <div class="card is-about">
      <h4>About</h4>
      <div class="flex-block">
        <i-feather name="message-circle"></i-feather>
        <p>
          <a>Send a Message</a>
        </p>
      </div>
      <div class="flex-block">
        <i-feather name="shopping-bag"></i-feather>
        <p>
          <a>Commercial Company</a>
        </p>
      </div>
      <div class="flex-block">
        <i-feather name="edit-3"></i-feather>
        <p>
          <a>Suggest Changes</a>
        </p>
      </div>
    </div>

    <div class="card is-friendkit">
      <div class="title-wrap">
        <img src="assets/img/logo/friendkit-bold.svg" alt />
        <h4>Security</h4>
      </div>
      <p>
        {{ dataPages?.pageDescription }}
      </p>
      <div class="created">
        <i-feather name="flag"></i-feather>
        <span
          >Page created on
          {{ dataPages?.createdAt | date: "MMM dd, yyyy" }}</span
        >
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-heading is-bordered">
      <h4>Related Pages</h4>
      <div class="dropdown is-spaced is-neutral is-right dropdown-trigger">
        <div>
          <div class="button">
            <i-feather name="more-vertical"></i-feather>
          </div>
        </div>
        <div class="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <a href="#" class="dropdown-item">
              <div class="media">
                <i-feather name="file-text"></i-feather>
                <div class="media-content">
                  <h3>All Recommandations</h3>
                  <small>View all recommandations.</small>
                </div>
              </div>
            </a>
            <a class="dropdown-item">
              <div class="media">
                <i-feather name="settings"></i-feather>
                <div class="media-content">
                  <h3>Settings</h3>
                  <small>Access widget settings.</small>
                </div>
              </div>
            </a>
            <hr class="dropdown-divider" />
            <a href="#" class="dropdown-item">
              <div class="media">
                <i-feather name="trash-2"></i-feather>
                <div class="media-content">
                  <h3>Remove</h3>
                  <small>Removes this widget from your feed.</small>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body no-padding">
      <ng-container *ngFor="let page of arrRelatedPage; let index = index">
        <!-- Recommended Page -->
        <div class="page-block transition-block">
          <img
            [src]="
              page?.pageAvatar ?  page?.pageAvatar : imgDefault
            "
            [attr.data-demo-src]="
              page?.pageAvatar ?  page?.pageAvatar : imgDefault
            "
            [attr.data-page-popover]="page?._id"
            alt
          />
          <div class="page-meta" (click)="gotoPage(page?._id)">
            <span>{{ page?.pageName }}</span>
            <span>{{ page?.pageTitle }}</span>
          </div>
          <div
            class="add-page add-transition"
            [ngClass]="page?.isLiked == true ? 'is-like' : ''"
            (click)="
              page?.isLiked == false
                ? createLikePage(page, index)
                : unLikePage(page, index)
            "
          >
            <i-feather name="bookmark"></i-feather>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
