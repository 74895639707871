import { Component, OnInit } from '@angular/core';
import iziToast from 'izitoast';
import { GroupService } from 'src/app/services/group/group.service';

@Component({
  selector: 'app-create-group-modal',
  templateUrl: './create-group-modal.component.html',
  styleUrls: ['./create-group-modal.component.scss'],
})
export class CreateGroupModalComponent implements OnInit {
  reqGroup = {
    groupName: '',
    groupHidden: 0,
    groupDescription: '',
    groupDisplay: 0,
    groupCategory: '',
  };
  constructor(private groupService: GroupService) {}

  ngOnInit(): void {}

  //#region Create group
  createGroup() {
    this.groupService.createGroup(this.reqGroup).subscribe((result) => {
      if (result.status == 'success') {
        iziToast.success({
          message: 'created group success.',
          messageColor: '#ffffff',
        });
      }
    });
  }
  //#endregion
}
