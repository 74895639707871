import { Component, Input, OnInit } from '@angular/core';
import { GroupService } from 'src/app/services/group/group.service';
import * as environment from 'src/environments/environment';
import iziToast from 'iziToast';

@Component({
  selector: 'app-group-member-requests-dropdown',
  templateUrl: './group-member-requests-dropdown.component.html',
  styleUrls: ['./group-member-requests-dropdown.component.scss'],
})
export class GroupMemberRequestsDropdownComponent implements OnInit {
  @Input() memberRequests;
  environment = environment.environment.apiUrl;
  @Input() groupId;
  @Input() isAdmin;

  constructor(private groupService: GroupService) {}

  ngOnInit(): void {}

  createMember(request) {
    let reqGroup = {
      groupId: this.groupId,
      memberId: request.senderId._id,
      isJoin: true,
    };
    if (this.isAdmin) {
      this.groupService.createMember(reqGroup).subscribe((result) => {
        if (result.status == 'success') {
          this.groupService.joinGroup({ status: 'success' });
          iziToast.success({
            class: 'toast',
            message: 'Join group success!',
            messageColor: '#ffffff',
            iconColor: '#ffffff',
            backgroundColor: '#00cc00',
            progressBarColor: '#fafafa',
            position: 'topRight',
            transitionIn: 'fadeInDown',
            close: false,
            zindex: 99999,
          });
          this.deleteMemberRequest(request);
        }
      });
    }
  }

  deleteMemberRequest(request) {
    this.groupService.deleteMemberRequest(request._id).subscribe((result) => {
      this.memberRequests.splice(
        this.memberRequests.findIndex((i) => {
          return i._id == request._id;
        }),
        1
      );
    });
  }
}
