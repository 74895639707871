<div id="create-group-modals" class="modal create-group-modals is-light-bg">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="card">
      <div class="card-heading">
        <h3>Create group</h3>
        <!-- Close X button -->
        <div class="close-wrap">
          <span class="close-modal">
            <i-feather name="x"></i-feather>
          </span>
        </div>
      </div>
      <!-- Modal subheading -->
      <div class="subheading">
        <!-- Group avatar -->
        <div class="group-avatar">
          <input id="group-avatar-upload" type="file" />
          <div class="add-photo">
            <i-feather name="plus"></i-feather>
          </div>
        </div>
        <!-- Group name -->
        <div class="control">
          <input
            type="text"
            class="input"
            placeholder="Give the group a name"
          />
        </div>
      </div>
      <div class="card-body">
        <div class="inner">
          <div class="left-section">
            <div class="search-subheader">
              <div class="control">
                <input
                  type="text"
                  class="input"
                  placeholder="Search for friends to add"
                />
                <span class="icon">
                  <i-feather name="search"></i-feather>
                </span>
              </div>
            </div>
            <div id="new-group-list" class="user-list has-slimscroll">
              <!-- Friend -->
              <div class="friend-block" data-ref="ref-1">
                <img
                  class="friend-avatar"
                  src="https://via.placeholder.com/300x300"
                  data-demo-src="assets/img/avatars/dan.jpg"
                  alt
                />
                <div class="friend-name">Dan Walker</div>
                <div class="round-checkbox is-small">
                  <div>
                    <input type="checkbox" id="checkbox-group-1" />
                    <label for="checkbox-group-1"></label>
                  </div>
                </div>
              </div>
              <!-- Friend -->
              <div class="friend-block" data-ref="ref-2">
                <img
                  class="friend-avatar"
                  src="https://via.placeholder.com/300x300"
                  data-demo-src="assets/img/avatars/daniel.jpg"
                  alt
                />
                <div class="friend-name">Daniel Wellington</div>
                <div class="round-checkbox is-small">
                  <div>
                    <input type="checkbox" id="checkbox-group-2" />
                    <label for="checkbox-group-2"></label>
                  </div>
                </div>
              </div>
              <!-- Friend -->
              <div class="friend-block" data-ref="ref-3">
                <img
                  class="friend-avatar"
                  src="https://via.placeholder.com/300x300"
                  data-demo-src="assets/img/avatars/stella.jpg"
                  alt
                />
                <div class="friend-name">Stella Bergmann</div>
                <div class="round-checkbox is-small">
                  <div>
                    <input type="checkbox" id="checkbox-group-3" />
                    <label for="checkbox-group-3"></label>
                  </div>
                </div>
              </div>
              <!-- Friend -->
              <div class="friend-block" data-ref="ref-4">
                <img
                  class="friend-avatar"
                  src="https://via.placeholder.com/300x300"
                  data-demo-src="assets/img/avatars/david.jpg"
                  alt
                />
                <div class="friend-name">David Kim</div>
                <div class="round-checkbox is-small">
                  <div>
                    <input type="checkbox" id="checkbox-group-4" />
                    <label for="checkbox-group-4"></label>
                  </div>
                </div>
              </div>
              <!-- Friend -->
              <div class="friend-block" data-ref="ref-5">
                <img
                  class="friend-avatar"
                  src="https://via.placeholder.com/300x300"
                  data-demo-src="assets/img/avatars/nelly.png"
                  alt
                />
                <div class="friend-name">Nelly Schwartz</div>
                <div class="round-checkbox is-small">
                  <div>
                    <input type="checkbox" id="checkbox-group-5" />
                    <label for="checkbox-group-5"></label>
                  </div>
                </div>
              </div>
              <!-- Friend -->
              <div class="friend-block" data-ref="ref-6">
                <img
                  class="friend-avatar"
                  src="https://via.placeholder.com/300x300"
                  data-demo-src="assets/img/avatars/elise.jpg"
                  alt
                />
                <div class="friend-name">Elise Walker</div>
                <div class="round-checkbox is-small">
                  <div>
                    <input type="checkbox" id="checkbox-group-6" />
                    <label for="checkbox-group-6"></label>
                  </div>
                </div>
              </div>
              <!-- Friend -->
              <div class="friend-block" data-ref="ref-7">
                <img
                  class="friend-avatar"
                  src="https://via.placeholder.com/300x300"
                  data-demo-src="assets/img/avatars/bobby.jpg"
                  alt
                />
                <div class="friend-name">Bobby Brown</div>
                <div class="round-checkbox is-small">
                  <div>
                    <input type="checkbox" id="checkbox-group-7" />
                    <label for="checkbox-group-7"></label>
                  </div>
                </div>
              </div>
              <!-- Friend -->
              <div class="friend-block" data-ref="ref-8">
                <img
                  class="friend-avatar"
                  src="https://via.placeholder.com/300x300"
                  data-demo-src="assets/img/avatars/lana.jpeg"
                  alt
                />
                <div class="friend-name">Lana Henrikssen</div>
                <div class="round-checkbox is-small">
                  <div>
                    <input type="checkbox" id="checkbox-group-8" />
                    <label for="checkbox-group-8"></label>
                  </div>
                </div>
              </div>
              <!-- Friend -->
              <div class="friend-block" data-ref="ref-9">
                <img
                  class="friend-avatar"
                  src="https://via.placeholder.com/300x300"
                  data-demo-src="assets/img/avatars/gaelle.jpeg"
                  alt
                />
                <div class="friend-name">Gaelle Morris</div>
                <div class="round-checkbox is-small">
                  <div>
                    <input type="checkbox" id="checkbox-group-9" />
                    <label for="checkbox-group-9"></label>
                  </div>
                </div>
              </div>
              <!-- Friend -->
              <div class="friend-block" data-ref="ref-10">
                <img
                  class="friend-avatar"
                  src="https://via.placeholder.com/300x300"
                  data-demo-src="assets/img/avatars/mike.jpg"
                  alt
                />
                <div class="friend-name">Mike Lasalle</div>
                <div class="round-checkbox is-small">
                  <div>
                    <input type="checkbox" id="checkbox-group-10" />
                    <label for="checkbox-group-10"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-section has-slimscroll">
            <div class="selected-count">
              <span>Selected</span>
              <span id="selected-friends-count">0</span>
            </div>
            <div id="selected-list" class="selected-list"></div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button type="button" class="button is-solid grey-button close-modal">
          Cancel
        </button>
        <button type="button" class="button is-solid accent-button close-modal">
          Create a Group
        </button>
      </div>
    </div>
  </div>
</div>
