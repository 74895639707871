<div id="event-1" class="event-details-wrap" *ngIf="arrEvents">
    <div class="meta-block">
        <i class="mdi mdi-lock"></i>
        <div class="meta">
            <span>{{arrDisplays[arrEvents?.display]?.viewValue}}</span>
            <span>{{arrDisplays[arrEvents?.display]?.name}}</span>
        </div>
    </div>
    <div class="meta-block">
        <i class="mdi mdi-map-marker"></i>
        <div class="meta">
            <span>Where</span>
            <span>@ {{arrEvents?.address}}</span>
        </div>
    </div>
    <div class="meta-block">
        <i class="mdi mdi-progress-clock"></i>
        <div class="meta">
            <span>When</span>
            <span>{{arrEvents?.startTime}} - {{arrEvents?.endTime}}</span>
        </div>
    </div>
    <div class="participants-wrap">
        <label>4 Participants</label>
        <div class="participants">

            <img *ngFor="let role of arrDataInvite" [src]=" role?.calRoleId?.avatar"
                [attr.data-demo-src]=" role?.calRoleId?.avatar" alt data-user-popover="0">

            <!--
            <img src="https://via.placeholder.com/300x300" data-demo-src="assets/img/avatars/stella.jpg" alt
                data-user-popover="2">
            <img src="https://via.placeholder.com/300x300" data-demo-src="assets/img/avatars/milly.jpg" alt
                data-user-popover="7"> -->
        </div>
    </div>
    <div class="event-description">
        <label>Description</label>
        <p>
            {{arrEvents?.description}}
        </p>
    </div>
    <hr>
    <div class="button-wrap">
        <a class="button is-bold">Participating</a>
        <a class="button is-bold">Details</a>
    </div>
</div>