import { Component, OnInit } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-question-home',
  templateUrl: './question-home.component.html',
  styleUrls: ['./question-home.component.scss'],
})
export class QuestionHomeComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {

  }

  openCategoiresQuestion() {
    window.location.href = 'questions-categories';
  }
}
