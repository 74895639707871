import { Component, OnInit,AfterViewChecked } from '@angular/core';
declare var jQuery: any;

declare var window: any;

declare var $: any;
@Component({
  selector: 'app-group-compose-card',
  templateUrl: './group-compose-card.component.html',
  styleUrls: ['./group-compose-card.component.scss']
})
export class GroupComposeCardComponent implements OnInit, AfterViewChecked {
  isLoadedJs = false;
  constructor() { }
  ngAfterViewChecked(): void {
    if (!this.isLoadedJs) {
      this.isLoadedJs = true;
    
      $('#publish').on('click', function () {
        $('.app-overlay').addClass('is-active');
        $('.is-new-content').addClass('is-highlighted');
      });
      $("#publish").on("input", function () {
        var valueLength = $(this).val().length;

        if (valueLength >= 1) {
          $("#publish-button").removeClass("is-disabled");
        } else {
          $("#publish-button").addClass("is-disabled");
        }
      });
    }
  }

  ngOnInit(): void {
  }

}
