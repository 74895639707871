import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import jwt_decode from "jwt-decode";
@Component({
  selector: 'app-feed-post-dropdown',
  templateUrl: './feed-post-dropdown.component.html',
  styleUrls: ['./feed-post-dropdown.component.scss']
})
export class FeedPostDropdownComponent implements OnInit {
  @Output() edit: EventEmitter<any> = new EventEmitter();
  user: any;
  showAction = false;
  constructor() { }

  ngOnInit(): void {
    let token = localStorage.getItem("token")
    this.user = this.getDecodedAccessToken(token);
  }
  editPost() {
    this.edit.emit()
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
}
