<div
	id="create-page"
	class="modal albums-modal is-xxl has-light-bg"
	[ngClass]="isModalCreatePage ? 'is-active' : ''"
>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-heading">
				<h3>Create Fanpage</h3>
				<!-- Close X button -->
				<div
					class="close-wrap close-cus-wrapper"
					(click)="closeModalCreatePage()"
				>
					<span class="close-modal">
						<i-feather name="x"></i-feather>
					</span>
				</div>
			</div>
			<div class="card-body">
				<div class="left-section">
					<div class="page-form albums-form">
						<div class="control">
							<input
								type="text"
								class="cus-input input is-sm no-radius is-fade"
								placeholder="Tên trang"
								[(ngModel)]="pageName"
							/>
							<div class="icon cus-icon">
								<i-feather name="home"></i-feather>
							</div>
						</div>
						<div class="control">
							<input
								type="text"
								class="cus-input input is-sm no-radius is-fade"
								placeholder="Tên tóm tắt"
								[(ngModel)]="pageTitle"
							/>
							<div class="icon cus-icon">
								<i-feather name="home"></i-feather>
							</div>
						</div>
						<div class="control">
							<select
								[(ngModel)]="pageCategory"
								class="cus-select is-sm no-radius is-fade"
							>
								<option disabled selected>Chọn danh mục</option>
								<option
									*ngFor="let item of arrCategory"
									[value]="item.nameValue"
								>
									{{ item.nameValue }}
								</option>
							</select>
							<!-- <div class="icon">
                <i-feather name="home"></i-feather>
              </div> -->
						</div>

						<div class="control">
							<textarea
								class="textarea is-fade no-radius is-sm"
								rows="3"
								placeholder="Giới thiệu về trang..."
								[(ngModel)]="pageDescription"
							></textarea>
						</div>
						<div class="control">
							<input
								type="text"
								class="cus-input input is-sm no-radius is-fade"
								placeholder="Place"
								[(ngModel)]="pageAddres"
							/>
							<div class="icon cus-icon">
								<i-feather name="map-pin"></i-feather>
							</div>
						</div>
					</div>
					<div id="album-date" class="album-date">
						<div class="head">
							<h4>Change the date</h4>
							<button
								type="button"
								class="button is-solid dark-grey-button icon-button"
							>
								<i-feather name="plus"></i-feather>
							</button>
						</div>
						<p>Set a date for your album. You can always change it later.</p>
						<div class="control is-hidden">
							<input
								id="album-datepicker"
								type="text"
								class="input is-sm is-fade"
								placeholder="Select a date"
							/>
							<div class="icon">
								<i-feather name="calendar"></i-feather>
							</div>
						</div>
					</div>
				</div>
				<div class="right-section has-slimscroll">
					<div class="container is-custom preview-page">
						<div id="pages-main" class="view-wrap is-headless">
							<div class="columns is-multiline no-margin">
								<div class="column is-paddingless">
									<div class="cover-bg">
										<img
											#valueImgCover
											class="cover-image"
											src="https://sgp1.digitaloceanspaces.com/archzon-live/defaultcover.jpg"
											data-demo-src="assets/img/demo/bg/4.jpg"
											alt
										/>
										<div class="avatar">
											<img
												#valueImgAvatar
												id="user-avatar"
												class="avatar-image"
												src="https://sgp1.digitaloceanspaces.com/archzon-live/avatar-default.png"
												data-demo-src="assets/img/icons/logos/fastpizza.svg"
												alt
											/>
											<div
												class="avatar-button"
												(click)="clickActiveBtnPlus()"
												[ngClass]="isActiveBtn ? 'is-active' : ''"
											>
												<i-feather name="plus"></i-feather>
											</div>
											<div
												[ngClass]="isActiveBtn ? 'is-active' : ''"
												class="pop-button is-far-left has-tooltip"
												data-title="Change profile picture"
												(click)="openModalAvatar()"
											>
												<a class="inner">
													<i-feather name="camera"></i-feather>
												</a>
											</div>
											<div
												[ngClass]="isActiveBtn ? 'is-active' : ''"
												id="follow-pop"
												class="pop-button pop-shift is-left has-tooltip"
												data-placement="top"
												data-title="Subscription"
											>
												<a class="inner">
													<i-feather
														class="inactive-icon"
														name="bell"
													></i-feather>
													<i-feather
														class="active-icon"
														name="bell-off"
													></i-feather>
												</a>
											</div>
											<div
												[ngClass]="isActiveBtn ? 'is-active' : ''"
												id="invite-pop"
												class="pop-button pop-shift is-center has-tooltip"
												data-placement="top"
												data-title="Relationship"
											>
												<a href="#" class="inner">
													<i-feather
														class="inactive-icon"
														name="plus"
													></i-feather>
													<i-feather
														class="active-icon"
														name="minus"
													></i-feather>
												</a>
											</div>
											<div
												[ngClass]="isActiveBtn ? 'is-active' : ''"
												id="chat-pop"
												class="pop-button is-right has-tooltip"
												data-placement="top"
												data-title="Chat"
											>
												<a class="inner">
													<i-feather name="message-square"></i-feather>
												</a>
											</div>
											<div
												[ngClass]="isActiveBtn ? 'is-active' : ''"
												class="pop-button is-far-right has-tooltip"
												data-placement="right"
												data-title="Send message"
											>
												<a href="messages-inbox.html" class="inner">
													<i-feather name="mail"></i-feather>
												</a>
											</div>
										</div>

										<div class="cover-overlay"></div>
										<div
											class="cover-edit modal-trigger"
											(click)="openModalCover()"
										>
											<i class="mdi mdi-camera"></i>
											<span>Edit cover image</span>
										</div>
										<!-- /html/partials/pages/pages/dropdowns/page-timeline-mobile-dropdown.html -->
										<div
											class="dropdown is-spaced is-right is-accent dropdown-trigger timeline-mobile-dropdown is-hidden-desktop"
										>
											<div>
												<div class="button">
													<i-feather name="more-vertical"></i-feather>
												</div>
											</div>
											<div class="dropdown-menu" role="menu">
												<div class="dropdown-content">
													<a class="dropdown-item">
														<div class="media">
															<i-feather name="activity"></i-feather>
															<div class="media-content">
																<h3>Timeline</h3>
																<small>Open Timeline.</small>
															</div>
														</div>
													</a>
													<a class="dropdown-item">
														<div class="media">
															<i-feather name="align-right"></i-feather>
															<div class="media-content">
																<h3>About</h3>
																<small>See about info.</small>
															</div>
														</div>
													</a>
													<a class="dropdown-item">
														<div class="media">
															<i-feather name="globe"></i-feather>
															<div class="media-content">
																<h3>Community</h3>
																<small>See community.</small>
															</div>
														</div>
													</a>
													<a class="dropdown-item">
														<div class="media">
															<i-feather name="image"></i-feather>
															<div class="media-content">
																<h3>Photos</h3>
																<small>See all photos.</small>
															</div>
														</div>
													</a>
												</div>
											</div>
										</div>
									</div>

									<div class="profile-menu is-hidden-mobile">
										<div class="menu-start">
											<a class="button has-min-width"> Timeline </a>
											<a class="button has-min-width"> About </a>
										</div>
										<div class="menu-end">
											<a class="button has-min-width"> Community </a>
											<a class="button has-min-width"> Photos </a>
										</div>
									</div>

									<div class="profile-subheader">
										<div class="subheader-start is-hidden-mobile">
											<span>148K</span>
											<span>Followers</span>
										</div>
										<div class="subheader-middle">
											<h2>{{ pageName ? pageName : previewName }}</h2>
											<span>{{ pageTitle ? pageTitle : previewTitle }}</span>
										</div>
										<div class="subheader-end is-hidden-mobile">
											<a class="button has-icon is-bold">
												<i-feather name="clock"></i-feather>
												History
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<button
					type="button"
					class="button is-solid accent-button close-modal"
					(click)="createPage(valueImgCover, valueImgAvatar)"
				>
					Create page
				</button>
			</div>
		</div>
	</div>
</div>

<div
	id="change-cover-modal"
	class="modal change-cover-modal is-medium has-light-bg"
	[ngClass]="{'is-active': isImageCover}"
	*ngIf="isImageCover"
>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-heading">
				<h3>Update Cover</h3>
				<!-- Close X button -->
				<div class="close-wrap" (click)="closeModalCover()">
					<span class="close-cus-model">
						<i-feather name="x"></i-feather>
					</span>
				</div>
			</div>
			<div class="card-body">
				<div class="selection-placeholder">
					<div class="columns">
						<div class="column is-6">
							<div
								class="selection-box modal-trigger"
								(click)="openModelDropCover()"
							>
								<div class="box-content">
									<img
										src="assets/img/illustrations/profile/upload-cover.svg"
										alt
									/>
									<div class="box-text">
										<span>Upload</span>
										<span>From your computer</span>
									</div>
								</div>
							</div>
						</div>
						<div class="column is-6">
							<!-- Selection box -->
							<div
								class="selection-box modal-trigger"
								data-modal="user-photos-modal"
							>
								<div class="box-content">
									<img
										src="assets/img/illustrations/profile/change-cover.svg"
										alt
									/>
									<div class="box-text">
										<span>Choose</span>
										<span>From your photos</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div
	id="change-profile-pic-modal"
	class="modal change-profile-pic-modal is-medium has-light-bg"
	[ngClass]="isImageAvatar ? 'is-active' : ''"
	*ngIf="isImageAvatar"
>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-heading">
				<h3>Update Avatar Picture</h3>
				<!-- Close X button -->
				<div class="close-wrap" (click)="closeModalAvatar()">
					<span class="close-cus-model">
						<i-feather name="x"></i-feather>
					</span>
				</div>
			</div>
			<div class="card-body">
				<!-- Placeholder -->
				<div class="selection-placeholder">
					<div class="columns">
						<div class="column is-6" (click)="openModelDropAvatar()">
							<!-- Selection box -->
							<div
								class="selection-box modal-trigger"
								data-modal="upload-crop-profile-modal"
							>
								<div class="box-content">
									<img
										src="assets/img/illustrations/profile/change-profile.svg"
										alt=""
									/>
									<div class="box-text">
										<span>Upload</span>
										<span>From your computer</span>
									</div>
								</div>
							</div>
						</div>
						<div class="column is-6">
							<!-- Selection box -->
							<div
								class="selection-box modal-trigger"
								data-modal="user-photos-modal"
							>
								<div class="box-content">
									<img
										src="assets/img/illustrations/profile/upload-profile.svg"
										alt=""
									/>
									<div class="box-text">
										<span>Choose</span>
										<span>From your photos</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div
	id="upload-crop-cover-modal"
	*ngIf="isDropCover == true"
	class="modal upload-crop-cover-modal is-large has-light-bg"
	[ngClass]="{'is-active': isDropCover == true}"
>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-heading">
				<h3>Upload Cover</h3>
				<!-- Close X button -->
				<div class="close-wrap" (click)="closeModalDropCover()">
					<span class="close-modal">
						<i-feather name="x"></i-feather>
					</span>
				</div>
			</div>
			<div class="card-body">
				<label class="cover-uploader-box" for="upload-cover-picture">
					<span class="inner-content">
						<img src="assets/img/illustrations/profile/add-cover.svg" alt="" />
						<span>Click here to <br />upload a cover image</span>
					</span>
					<input
						class="input-cover"
						type="file"
						id="upload-cover-picture"
						accept="image/*"
					/>
				</label>
				<div class="upload-demo-wrap is-hidden">
					<div id="upload-cover"></div>
					<div class="upload-help">
						<a id="cover-upload-reset" class="cover-reset is-hidden"
							>Reset Picture</a
						>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<button
					id="submit-cover-picture"
					class="button is-solid accent-button is-fullwidth raised is-disabled"
				>
					Use Picture
				</button>
			</div>
		</div>
	</div>
</div>
<div
	id="upload-crop-profile-modal"
	*ngIf="isDropAvatar"
	[ngClass]="{'is-active': isDropAvatar == true}"
	class="modal upload-crop-profile-modal is-xsmall has-light-bg"
>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-heading">
				<h3>Upload Picture</h3>
				<!-- Close X button -->
				<div class="close-wrap" (click)="closeModalDropAvatar()">
					<span class="close-modal">
						<i-feather name="x"></i-feather>
					</span>
				</div>
			</div>
			<div class="card-body">
				<label class="profile-uploader-box" for="upload-profile-picture">
					<span class="inner-content">
						<img
							src="assets/img/illustrations/profile/add-profile.svg"
							alt=""
						/>
						<span>Click here to <br />upload a page picture</span>
					</span>
					<input type="file" id="upload-profile-picture" accept="image/*" />
				</label>
				<div class="upload-demo-wrap is-hidden">
					<div id="upload-profile"></div>
					<div class="upload-help">
						<a id="profile-upload-reset" class="profile-reset is-hidden"
							>Reset Picture</a
						>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<button
					id="submit-profile-picture"
					class="button is-solid accent-button is-fullwidth raised is-disabled"
					(click)="submitAvatar()"
				>
					Use Picture
				</button>
			</div>
		</div>
	</div>
</div>
