<!-- /html/partials/pages/stories/stories-sidebar.html -->
<div
  id="group-sidebar"
  class="stories-sidebar is-active"
  *ngIf="dataGroups && isAdminGroups"
>
  <div class="stories-sidebar-inner">
    <div class="user-block">
      <a class="close-stories-sidebar is-hidden">
        <i-feather name="x"></i-feather>
      </a>
      <div class="avatar-wrap">
        <img
          [src]=" dataGroups?.groupCover"
          [attr.data-demo-src]=" dataGroups?.groupCover"
          [attr.data-group-popover]="dataGroups?._id"
          alt="thumb-group"
        />
        <div class="badge">
          <i-feather name="check"></i-feather>
        </div>
      </div>
      <h4>{{ dataGroups?.groupName }}</h4>
      <p>{{ dataGroups?.groupDisplay }}</p>
      <div class="user-stats">
        <div class="stat-block">
          <span>Members</span>
          <span>2.3K</span>
        </div>
        <div class="stat-block">
          <i-feather [name]="iconDisplay"></i-feather>
          <span> {{ groupDisplay }}</span>
        </div>
      </div>
    </div>
    <div class="user-menu">
      <div class="user-menu-inner has-slimscroll">
        <div class="menu-block">
          <ul>
            <li>
              <a>
                <i-feather name="home"></i-feather>
                <span>Home</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="separator"></div>
        <div class="menu-block">
          <ul>
            <li>
              <a>
                <i-feather name="bell"></i-feather>
                <span>Notifications</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="separator"></div>
        <div class="menu-block">
          <ul>
            <li>
              <a>
                <i-feather name="settings"></i-feather>
                <span>Settings</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="separator"></div>
        <div class="menu-block">
          <ul>
            <li>
              <a>
                <i-feather name="lock"></i-feather>
                <span>Privacy</span>
              </a>
            </li>
            <li>
              <a>
                <i-feather name="life-buoy"></i-feather>
                <span>Help</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-menu-admin" (click)="openSideBar()">
    <i-feather name="menu"></i-feather>
  </div>
</div>
<!-- /html/partials/pages/stories/stories-sidebar.html -->
<div id="group-sidebar" class="stories-sidebar is-active" *ngIf="!dataGroups">
  <div class="stories-sidebar-inner">
    <div class="user-block">
      <div class="field field-wrap">
        <label>Groups</label>
        <div class="control has-icon">
          <input
            type="text"
            class="input is-rounded"
            [(ngModel)]="keySearch"
            (ngModelChange)="searchGroup(keySearch)"
          />
          <div class="form-icon">
            <i-feather name="search"></i-feather>
          </div>
          <div
            class="easy-autocomplete-container search-user"
            [ngClass]="arrGroup?.length > 0 ? '' : 'is-hidden'"
            id="eac-container-location-autocpl"
          >
            <ul [ngStyle]="{ display: 'block' }">
              <li
                class="is-selected focus-selected"
                *ngFor="let group of arrGroup"
                (click)="gotoGroup(group)"
              >
                <div class="eac-item">
                  <div class="template-wrapper">
                    <div class="avatar-wrapper">
                      <img
                        class="autocpl-avatar"
                        [src]=" group?.groupCover"
                      />
                    </div>
                    <div class="entry-text">
                      {{ group?.groupName }}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="btn-crt-gr">
        <div
          class="btn-group btn-crt modal-trigger"
          data-modal="create-group"
          (click)="openModalCreateGroup()"
        >
          <span>
            <i-feather name="plus"></i-feather>
            Create New Group</span
          >
        </div>
      </div>

      <div class="btn-crt-gr">
        <div class="btn-group btn-attached" (click)="openAttachedGroup()">
          <span>
            <i-feather name="flag"></i-feather>
            Attached Groups</span
          >
        </div>
      </div>
    </div>
    <div class="user-menu">
      <div class="user-menu-inner has-slimscroll">
        <div class="menu-block">
          <ul>
            <li class="is-active" (click)="openNewFeed()">
              <a>
                <i-feather name="grid"></i-feather>
                <span>Your Feed</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="separator"></div>
        <div class="menu-block">
          <ul class="lst-gr">
            <span class="gr-managing">Group you managa</span>
            <ng-container *ngIf="arrGroupAdmin?.length > 0">
              <ng-container *ngFor="let group of arrGroupAdmin">
                <li>
                  <a [href]="'groups/' + group._id">
                    <img
                      class="img-group"
                      [src]=" group?.groupCover"
                      alt=""
                    />
                    <span>{{ group?.groupName }}</span>
                  </a>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
        <div class="separator"></div>
        <div class="menu-block">
          <ul class="lst-gr">
            <span class="gr-managing">Your Groups</span>
            <ng-container *ngIf="arrMyGroup?.length > 0">
              <ng-container *ngFor="let group of arrMyGroup">
                <li>
                  <a [href]="'groups/' + group?.groupId?._id">
                    <img
                      class="img-group"
                      [src]=" group?.groupId?.groupCover"
                      alt=""
                    />
                    <span>{{ group?.groupId?.groupName }}</span>
                  </a>
                  <i-feather
                    *ngIf="group?.isAttached == true"
                    name="flag"
                  ></i-feather>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
        <div class="separator"></div>
      </div>
    </div>
  </div>
  <div *ngIf="dataGroups" class="btn-menu-admin" (click)="openSideBar()">
    <i-feather name="menu"></i-feather>
  </div>
</div>
<!-- Stories -->

<!-- Stories -->
<app-group-create></app-group-create>
