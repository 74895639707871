<div class="community-content">
	<div class="community-info">
		<div class="page-followers">
			<div class="header">
				<h3>Followers</h3>
			</div>
			<div class="followers">
				<ng-container *ngFor="let like of arrLikes">
					<a class="follower follower-wrapper">
						<img
							[src]="
								like?.userId?.avatar ?  like?.userId?.avatar : ''
							"
							[attr.data-demo-src]="
								like?.userId?.avatar ?  like?.userId?.avatar : ''
							"
							[attr.data-user-popover]="like?.userId?._id"
							alt
						/>
					</a>
				</ng-container>
			</div>
			<!-- Show More -->
			<div class="more-followers">
				<a>Show More</a>
			</div>
		</div>
	</div>
	<div class="community-widgets">
		<div class="page-counts">
			<div class="like-count">
				<span>{{ countLike }}</span>
				<span>Likes</span>
			</div>
			<div class="follow-count">
				<span>{{ countFollow }}</span>
				<span>Followers</span>
			</div>
		</div>

		<div class="page-invites">
			<div class="header">
				<h3>Invite your friends</h3>
				<div class="control">
					<input
						type="text"
						class="input"
						placeholder="Search your friends..."
					/>
					<div class="search-icon">
						<i-feather name="search"></i-feather>
					</div>
				</div>
			</div>
			<div class="suggestions">
				<div class="suggestion">
					<img
						src="https://via.placeholder.com/300x300"
						data-demo-src="assets/img/avatars/aline.jpg"
						
					/>
					<div class="meta">
						<span>Aline</span>
					</div>
					<a class="button">Invite</a>
				</div>
				<div class="suggestion">
					<img
						src="https://via.placeholder.com/300x300"
						data-demo-src="assets/img/avatars/bobby.jpg"
						
					/>
					<div class="meta">
						<span>Bobby</span>
					</div>
					<a class="button">Invite</a>
				</div>
				<div class="suggestion">
					<img
						src="https://via.placeholder.com/300x300"
						data-demo-src="assets/img/avatars/rolf.jpg"
						
					/>
					<div class="meta">
						<span>Rolf</span>
					</div>
					<a class="button">Invite</a>
				</div>
				<div class="suggestion">
					<img
						src="https://via.placeholder.com/300x300"
						data-demo-src="assets/img/avatars/stella.jpg"
						
					/>
					<div class="meta">
						<span>Stella</span>
					</div>
					<a class="button">Invite</a>
				</div>
			</div>
		</div>
	</div>
</div>
