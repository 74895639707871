<!-- <div class="pageloader"></div>
<div class="infraloader is-active"></div>
<div class="app-overlay"></div> -->
<app-nav-bar (dataUser)="getInfoUser($event)"></app-nav-bar>
<div
	class="questions-nav"
	[ngClass]="isScrollable ? 'is-active' : ''"
	*ngIf="!isFeed"
>
	<div class="inner">
		<div class="container">
			<div class="group-nav questions-nav-menu">
				<div class="menu-left">
					<div class="gr-avt">
						<img
							[src]="dataGroup?.groupCover"
							[attr.data-demo-src]="dataGroup?.groupCover"
							[attr.data-group-popover]="dataGroup?.groupCover"
							alt=""
						/>
					</div>

					<div class="meta">
						<h4>
							<span>{{ dataGroup?.groupName }}</span>
						</h4>
					</div>
				</div>
				<div class="menu-right">
					<div class="subheader-end is-hidden-mobile" *ngIf="!isMember">
						<a class="button has-min-width btn-join is-info"> Join Group </a>
					</div>
					<div class="subheader-end is-hidden-mobile" *ngIf="isMember">
						<a
							class="button has-icon is-bold modal-trigger is-info"
							data-modal="create-member-modal"
						>
							<i-feather name="plus"></i-feather>
							Invite
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div id="main-feed" class="stories-wrapper">
	<app-group-sidebar
		[dataGroup]="dataGroup"
		[isAdminGroup]="isAdminGroup"
	></app-group-sidebar>
	<app-group-content
		*ngIf="dataGroup"
		[dataGroup]="dataGroup"
		[isAdminGroup]="isAdminGroup"
		[dataUser]="dataUser"
		[isMember]="isMember"
		[isHidden]="isHidden"
		[arrPhoto]="arrPhoto"
	></app-group-content>
	<app-group-new-feed
		*ngIf="isFeed == true"
		[dataUser]="dataUser"
	></app-group-new-feed>
</div>
<app-share-modal></app-share-modal>
