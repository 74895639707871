<div
    id="privacy-settings"
    class="settings-section"
>
    <div class="settings-panel">

        <div class="title-wrap">
            <a class="mobile-sidebar-trigger">
                <i-feather name="menu"></i-feather>
            </a>
            <h2>Privacy Settings</h2>
        </div>

        <div class="settings-form-wrapper">
            <div class="settings-form">
                <div class="columns is-multiline">
                    <div class="column is-8">
                        <!-- Field -->
                        <div class="field spaced-field">
                            <div class="switch-block">
                                <label class="f-switch">
                                    <input
                                        type="checkbox"
                                        class="is-switch"
                                        checked
                                    >
                                </label>
                                <div class="meta">
                                    <h4>Public Profile</h4>
                                    <p>
                                        Enable to make your profile viewable by anyone.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Field -->
                        <div class="field spaced-field">
                            <div class="switch-block">
                                <label class="f-switch is-accent">
                                    <input
                                        type="checkbox"
                                        class="is-switch"
                                    >
                                </label>
                                <div class="meta">
                                    <h4>Public Posts</h4>
                                    <p>
                                        Enable to make your posts viewable by anyone.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Field -->
                        <div class="field spaced-field">
                            <div class="switch-block">
                                <label class="f-switch is-accent">
                                    <input
                                        type="checkbox"
                                        class="is-switch"
                                    >
                                </label>
                                <div class="meta">
                                    <h4>Free Tagging</h4>
                                    <p>
                                        Enable to disable tags verification before publish.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Field -->
                        <div class="field spaced-field">
                            <div class="switch-block">
                                <label class="f-switch is-accent">
                                    <input
                                        type="checkbox"
                                        class="is-switch"
                                        checked
                                    >
                                </label>
                                <div class="meta">
                                    <h4>Public List</h4>
                                    <p>
                                        Enable to make your friend list viewable by anyone.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Field -->
                        <div class="field">
                            <div class="switch-block">
                                <label class="f-switch is-accent">
                                    <input
                                        type="checkbox"
                                        class="is-switch"
                                        checked
                                    >
                                </label>
                                <div class="meta">
                                    <h4>SEO</h4>
                                    <p>
                                        Enable to make your profile indexable by search engines.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="illustration">
                <img
                    src="assets/img/illustrations/settings/4.svg"
                    alt
                >
                <p>
                    If you'd like to learn more about privacy settings, you can read about it in the
                    <a>user guide</a>
                    .
                </p>
            </div>
        </div>

    </div>
</div>
