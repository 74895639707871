import { Component, Input, OnInit } from '@angular/core';
import iziToast from 'izitoast';
import { PagesService } from 'src/app/services/pages.service';
declare var jQuery: any;
@Component({
  selector: 'app-page-upload-crop-profile-modal',
  templateUrl: './page-upload-crop-profile-modal.component.html',
  styleUrls: ['./page-upload-crop-profile-modal.component.scss'],
})
export class PageUploadCropProfileModalComponent implements OnInit {
  constructor(private pagesServe: PagesService) {}
  pageCover;
  id;
  dataUsers;
  @Input() set dataUser(dataUser: any) {
    this.dataUsers = dataUser;
  }
  @Input() set dataPage(page: any) {
    this.dataPages = page;
  }
  dataPages;
  ngOnInit(): void {
    this.loadJsUploadImage();
  }

  //#region
  createPost(srcImg) {
    let postImg = [{ srcImg }];
    let data = {
      postUserId: this.dataUsers._id,
      postPageId: this.dataPages._id,
      postContent: 'cập nhật ảnh đại diện',
      postImg: postImg,
      postType: 0,
      postDisplay: 0,
      isActive: true,
    };

    this.pagesServe.createPost(data).subscribe((result) => {
      if (result.status == 'success') {
        this.pagesServe.changeAvatar({ status: 'success' });
      }
    });
  }
  //#endregion
  avatar;
  updateAvatar() {
    setTimeout(() => {
      const src = document.getElementById('avt-page').getAttribute('src');

      if (src != '' && src != null && src != undefined) {
        let data = {
          id: this.id,
          avatar: src,
          name: this.dataPages.pageName,
        };
        this.pagesServe.updatePageAvatar(data).subscribe((result) => {
          if (result.status == 'success') {
            iziToast.success({
              title: 'Cập nhật avatar thành công.',
              titleColor: '#000',
              timeout: 1000,
            });
            this.closeModal();
            this.createPost(src);
          }
        });
      }
    }, 1000);
  }

  closeModal() {
    (function ($) {
      $('.modal.is-active').removeClass('is-active');
    })(jQuery);
  }

  loadJsUploadImage() {
    (function ($) {
      $(document).ready(function () {
        if ($('#upload-avatar').length) {
          let imgSrc;
          function readFile(input) {
            if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.onload = function (e) {
                $uploadCrop
                  .croppie('bind', {
                    url: e.target.result,
                  })
                  .then(function () {
                    imgSrc = e.target.result;
                    console.log('jQuery bind complete');
                  });
              };

              reader.readAsDataURL(input.files[0]);
            }
          }
          if (!$('#upload-avatar').data('croppie')) {
            var $uploadCrop = $('#upload-avatar').croppie({
              enableExif: true,
              url: 'assets/img/profile.png',
              viewport: {
                width: 130,
                height: 130,
                type: 'circle',
              },
              boundary: {
                width: '100%',
                height: 300,
              },
            });
          }

          function popupResult(result) {
            var html;
            if (result.html) {
              html = result.html;
            }

            if (result.src) {
              $('.avatar-pages').attr('src', result.src);
              html = '<img  src="' + result.src + '" />';
              $('.cover-bg .avatar .avatar-image, #user-avatar-minimal').attr(
                'src',
                result.src
              );

              $('#submit-avatar-picture').removeClass('is-loading');
              $('#upload-crop-profile-modal').removeClass('is-active');
            }
          }

          $('#upload-avatar-picture').on('change', function () {
            readFile(this);
            $(this)
              .closest('.modal')
              .find('.profile-uploader-box, .upload-demo-wrap, .profile-reset')
              .toggleClass('is-hidden');
            $('#submit-avatar-picture').removeClass('is-disabled');
          });

          $('#submit-avatar-picture').on('click', function (ev) {
            var $this = $(this);
            $this.addClass('is-loading');
            $uploadCrop
              .croppie('result', {
                type: 'canvas',
                size: 'viewport',
              })
              .then(function (resp) {
                popupResult({
                  src: resp,
                });
              });
          });

          $('#profile-upload-reset').on('click', function () {
            $(this).addClass('is-hidden');
            $('.profile-uploader-box, .upload-demo-wrap').toggleClass(
              'is-hidden'
            );
            $('#submit-avatar-picture').addClass('is-disabled');
            $('#upload-avatar-picture').val('');
          });
        }
      });
    })(jQuery);
  }
}
