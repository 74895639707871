import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import iziToast from 'izitoast';

import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-profile-friend-item',
  templateUrl: './profile-friend-item.component.html',
  styleUrls: ['./profile-friend-item.component.scss'],
})
export class ProfileFriendItemComponent implements OnInit {
  @Input() friend;
  @Input() loggedUserId;
  @Input() infoUserId;
  @Output() friendListChange = new EventEmitter<any>();

  constructor(private profileService: ProfileService) {}

  ngOnInit(): void {}

  unFriend(friend) {
    console.log(friend);
    this.profileService.deleteFriendById(friend._id).subscribe((result) => {
      if (result.status == 'success') {
        iziToast.show({
          class: 'success-toast',
          icon: 'mdi mdi-send',
          title: '',
          message: `You deleted friend </b>`,
          titleColor: '#fff',
          messageColor: '#fff',
          iconColor: '#fff',
          backgroundColor: '#ff5f59',
          progressBarColor: '#b975ff',
          position: 'bottomRight',
          transitionIn: 'fadeInUp',
          close: false,
          timeout: 2500,
          zindex: 99999,
        });
        this.friendListChange.emit(friend);
      }
    });
  }
}
