import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProfileService } from '../../../../services/profile.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as environment from '../../../../../environments/environment';
import jwt_decode from 'jwt-decode';
import iziToast from 'iziToast';

@Component({
  selector: 'app-timeline-header',
  templateUrl: './timeline-header.component.html',
  styleUrls: ['./timeline-header.component.scss'],
})
export class TimelineHeaderComponent implements OnInit {
  @Input() infouser;
  @Output() page = new EventEmitter<number>();
  previewAvatar;
  totalFriends;
  profilePage;
  profileAboutPage;
  profileAvatar;
  environment = environment.environment.apiUrl;
  token;
  tokenInfo;
  userId;
  isOwner;
  defautCover = 'assets/img/defaultcover.jpg';
  profileFriends;
  profilePhotos;
  isActiveAvtBtn;
  follow: boolean;
  constructor(
    private profile: ProfileService,
    private router: Router,

    private route: ActivatedRoute
  ) { }
  fdCover = new FormData();
  ngOnInit(): void {

    this.token = localStorage.getItem('token');
    this.tokenInfo = this.getDecodedAccessToken(this.token);
    this.userId = this.route.snapshot.paramMap.get('id');
    // if (this.token && this.userId == this.tokenInfo._id) {
    //   this.isOwner = true;
    // } else {
    //   this.isOwner = false;
    // }

    this.isOwner =
      this.token && this.userId == this.tokenInfo._id ? true : false;
    this.profilePage = 1;
    this.checkFollow();
    this.checkFriend();
    this.checkFriendRequest();
    this.profile.seeAll.subscribe((result) => {
      if (result.status == 'success') {
        this.goProfile(4);
      }
    });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  checkFollow() {
    if (this.token) {
      this.profile
        .checkFollow({ followId: this.userId })
        .subscribe((result) => {
          if (result.data && result.data.length > 0) {
            this.follow = true;
          } else {
            this.follow = false;
          }
        });
    }
  }

  isFriend = false;
  checkFriend() {
    if (this.token) {
      this.profile
        .checkFriend({ friendId: this.userId })
        .subscribe((result) => {
          if (result.data && result.data.length > 0) {
            this.isFriend = true;
          }
        });
    }
  }

  goProfile(value) {
    this.profilePage = value;
    this.page.emit(value);
  }

  createFollow() {
    this.isActiveAvtBtn = !this.isActiveAvtBtn;
    let data = {
      following: this.userId,
    };
    this.profile.follow(data).subscribe((following) => {
      this.follow = true;
    });
    iziToast.show({
      class: 'success-toast',
      icon: 'mdi mdi-bell-ring',
      title: '',
      message: `You are now following <b>${this.infouser.firstname} ${this.infouser.lastname}</b>`,
      titleColor: '#fff',
      messageColor: '#fff',
      iconColor: '#fff',
      backgroundColor: '#7F00FF',
      progressBarColor: '#b975ff',
      position: 'bottomRight',
      transitionIn: 'fadeInUp',
      close: false,
      timeout: 2500,
    });
  }

  createFriendRequest() {
    this.isActiveAvtBtn = !this.isActiveAvtBtn;
    let data = {
      receiverId: this.userId,
    };
    this.profile.friendRequest(data).subscribe((result) => {
      this.isFriendRequest = true
      iziToast.show({
        class: 'success-toast',
        icon: 'mdi mdi-send',
        title: '',
        message: `Your invitation has been sent to <b>${this.infouser.firstname} ${this.infouser.lastname}</b>`,
        titleColor: '#fff',
        messageColor: '#fff',
        iconColor: '#fff',
        backgroundColor: '#7F00FF',
        progressBarColor: '#b975ff',
        position: 'bottomRight',
        transitionIn: 'fadeInUp',
        close: false,
        timeout: 2500,
        zindex: 99999,
      });
    });
  }

  isFriendRequest = false;
  checkFriendRequest() {
    if (this.token) {
      this.profile
        .checkFriendRequest({ receiverId: this.userId })
        .subscribe((result) => {
          if (result.data && result.data.length > 0) {
            this.isFriendRequest = true
          }
        });
    }
  }

  deleteFriendRequest() {
    if (this.token) {
      this.profile
        .deleteFriendRequest({ receiverId: this.userId })
        .subscribe((result) => {
          this.isFriendRequest = false;
          iziToast.show({
            class: 'success-toast',
            icon: 'mdi mdi-bell-ring',
            title: '',
            message: `You deleted friend request <b>${this.infouser.firstname} ${this.infouser.lastname}</b>`,
            titleColor: '#fff',
            messageColor: '#fff',
            iconColor: '#fff',
            backgroundColor: '#7F00FF',
            progressBarColor: '#b975ff',
            position: 'bottomRight',
            transitionIn: 'fadeInUp',
            close: false,
            timeout: 2500,
            zindex: 99999,
          })
        });
    }
  }

  deleteFriend() {
    let data = {
      friendId: this.userId
    }
    this.profile.deleteFriend(data).subscribe((result) => {
      this.isFriend = false;
      iziToast.show({
        class: 'success-toast',
        icon: 'mdi mdi-bell-ring',
        title: '',
        message: `You deleted <b>${this.infouser.firstname} ${this.infouser.lastname}</b>`,
        titleColor: '#fff',
        messageColor: '#fff',
        iconColor: '#fff',
        backgroundColor: '#7F00FF',
        progressBarColor: '#b975ff',
        position: 'bottomRight',
        transitionIn: 'fadeInUp',
        close: false,
        timeout: 2500,
        zindex: 99999,
      })
    })
  }

  countFollowers;
  countFollowing;
  getFollowings() {
    this.profile.getFollowing().subscribe((followings) => {
      this.countFollowing = followings;
    });
  }

  getFollowers() {
    this.profile.getFollowers().subscribe((followers) => {
      this.countFollowers = followers;
    });
  }

  unfollow() {
    this.isActiveAvtBtn = !this.isActiveAvtBtn;
    this.profile.unfollow({ following: this.userId }).subscribe((result) => {
      this.follow = false;
    });
    iziToast.show({
      class: 'success-toast',
      icon: 'mdi mdi-bell-ring',
      title: '',
      message: `You are not following <b>${this.infouser.firstname} ${this.infouser.lastname}</b>`,
      titleColor: '#fff',
      messageColor: '#fff',
      iconColor: '#fff',
      backgroundColor: '#7F00FF',
      progressBarColor: '#b975ff',
      position: 'bottomRight',
      transitionIn: 'fadeInUp',
      close: false,
      timeout: 2500,
      zindex: 99999,
    });
  }
}
