import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagesService } from 'src/app/services/pages.service';
import { ProfileService } from '../../services/profile.service';

declare var $: any;
@Component({
  selector: 'app-page-main',
  templateUrl: './page-main.component.html',
  styleUrls: ['./page-main.component.scss'],
})
export class PageMainComponent implements OnInit {
  pageId;
  isPageActive;
  dataPage;
  constructor(
    private pagesServe: PagesService,
    private profileService: ProfileService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.isAdmin = false;
    this.pageId = this.route.snapshot.paramMap.get('id');
    if (this.pageId != '') {
      this.getPageById(this.pageId);
      this.getInfoUser();
      this.getListPost(this.pageId);
    }

    // change avatar pages
    this.pagesServe.loadParentPage.subscribe((result) => {
      if (result.status == 'success') {
        this.getPageById(this.pageId);
      }
    });

    // change cover pages
    this.pagesServe.loadTimeLinePage.subscribe((result) => {
      if (result.status == 'success') {
        this.getPageById(this.pageId);
      }
    });
  }

  //#region  Get info user
  //and Check admin for Fanpages
  //and check liker, follower
  dataUser;
  getInfoUser() {
    this.profileService.getProfile().subscribe((result) => {
      if (result.status == 'success') {
        this.dataUser = result.data;
        this.checkAdminPage(this.dataUser._id);
        this.checkFollower(this.dataUser._id, this.pageId);
        this.checkLikers(this.pageId);
      }
    });
  }

  isAdmin: boolean = false;
  dataAdmin: any;
  checkAdminPage(userId) {
    this.dataAdmin = [];
    this.pagesServe.getAdminByUserId().subscribe((result) => {
      if (result.status == 'success') {
        this.dataAdmin = result.data;
        for (let admin of this.dataAdmin) {
          this.isAdmin =
            userId == admin.userId && this.pageId == admin.pageId
              ? true
              : false;
          if (this.isAdmin == true) {
            return true;
          }
        }
      }
    });
  }
  checkLikers(pageId) {
    let data = {
      pageId: pageId,
    };
    this.pagesServe.checkLikePageForUser(data).subscribe((result) => {
      this.isLike = result.status == 'success' ? true : false;
    });
  }
  isFollow: boolean = false;
  checkFollower(userId, pageId) {
    const data = {
      follower: userId,
      pageId: pageId,
    };
    this.pagesServe.checkFollower(data).subscribe((result) => {
      this.isFollow = result == null ? false : true;
    });
  }

  //#endregion

  //#region Get data page by PageId
  getPageById(pageId) {
    this.pagesServe.getPageById(pageId).subscribe((result) => {
      if (result.status == 'success') {
        this.dataPage = result.data;
        this.getLikeByPageId(this.pageId);
        this.getFollowerByPageId(this.pageId);
      }
    });
  }
  //#endregion

  //#region Option pages in FanPages (example: timeline, about, community, photos)
  goPageActive(value) {
    this.isPageActive = value;
    this.getLikeByPageId(this.pageId);
  }
  //#endregion

  //#region Get data Liker, Followers by PageId
  countLikePage;
  arrLike = [];
  getLikeByPageId(pageId) {
    this.pagesServe.getLikeByPageId(pageId).subscribe((result) => {
      if (result.status == 'success') {
        this.countLikePage = result.count;
        this.arrLike = result.data;
      }
    });
  }
  countFollow;
  dataFLPage;
  getFollowerByPageId(pageId) {
    this.pagesServe.getFollowerByPageId(pageId).subscribe((result) => {
      if (result.status == 'success') {
        this.dataFLPage = result.data;
        this.countFollow = result.count;
      }
    });
  }
  //#endregion

  //#region request from components Timeline when user like FanPages
  isLike: boolean = false;
  getLikePageFromChild(value) {
    this.isLike = value;
    this.getLikeByPageId(this.pageId);
  }
  //#endregion

  //#region Update page from TimeLineComponents
  checkEditPageChild(event) {
    if (event) {
      if (event.isUpdate == true) {
        this.getPageById(this.pageId);
      }
    }
  }
  //#endregion

  //#region Get arr Post in fanpage by pageId
  arrPost = [];
  getListPost(pageId) {
    this.arrPost = [];
    this.pagesServe.getAllPostByPage(pageId).subscribe((result) => {
      if (result.status == 'success') {
        this.arrPost = result.data;
      }
    });
  }
  //#endregion

  openModalAvatar(event) {}
}
