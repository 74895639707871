<div class="image-grid-wrap" *ngIf="!isActiveAlbum">
	<div class="image-grid">
		<!-- Grid Row -->
		<div
			class="image-row"
			[ngClass]="arrAlbumm?.length > 1 ? 'image-three' : 'image-one'"
		>
			<ng-container *ngFor="let album of arrAlbumm">
				<div
					class="flex-1 has-background-image"
					[ngStyle]="{
						background: 'url(' + album?.albumPhoto + ')'
					}"
					[attr.data-demo-background]="album?.albumPhoto"
					(click)="openAlbum(album)"
				>
					<div class="overlay"></div>
					<div class="image-owner">
						<img
							class="avatar"
							[src]="infousers?.avatar"
							[attr.data-demo-background]="infousers?.avatar"
							alt
						/>
						<div class="name">
							{{ infousers?.firstname }}
							{{ infousers?.lastname }}
						</div>
						<span>{{ album?.albumDescription }}</span>
					</div>
					<div class="photo-time">
						{{ album?.createdAt | date: "MMM dd,yyyy" }}
						<span> - {{ album?.albumName }}</span>
					</div>
					<a class="photo-like">
						<div class="inner">
							<div class="like-overlay"></div>
							<i-feather name="heart"></i-feather>
						</div>
					</a>
				</div>
			</ng-container>
			<!-- Photo -->
			<!-- Photo -->
		</div>
	</div>

	<!-- Load more photos -->
	<div class="load-more-wrap has-text-centered">
		<a class="load-more-button"> Load More </a>
	</div>
</div>
<div class="image-grid-wrap list-photo" *ngIf="isActiveAlbum">
	<div class="back-album">
		<span (click)="closeAlbum()"
			><i-feather name="corner-down-left"></i-feather
		></span>
		<span>{{ titleAlbum }}</span>
	</div>
	<div class="image-grid">
		<!-- Grid Row -->
		<div
			class="image-row"
			[ngClass]="arrPhoto?.length > 1 ? 'image-three' : 'image-one'"
		>
			<ng-container *ngFor="let photo of arrPhoto">
				<div
					class="flex-1 has-background-image"
					[ngStyle]="{
						'background-image': 'url(' + photo?.photoName + ')'
					}"
					[attr.data-demo-background]="photo?.photo"
				>
					<div class="overlay"></div>
					<div class="image-owner image-owner-slick">
						<img
							class="avatar"
							[src]="infousers?.avatar"
							[attr.data-demo-background]="infousers?.avatar"
							alt
						/>
					</div>
					<div class="photo-time">
						{{ photo?.createdAt | date: "MMM dd,yyyy" }}
					</div>
					<a class="photo-like" (click)="openModalDelete(photo?._id)">
						<div class="inner">
							<div class="like-overlay"></div>
							<i-feather name="x"></i-feather>
						</div>
					</a>
				</div>
			</ng-container>
			<!-- Photo -->
			<!-- Photo -->
		</div>
	</div>

	<!-- Load more photos -->
	<div class="load-more-wrap has-text-centered" (click)="getMorePhoto()">
		<a class="load-more-button"> Load More </a>
	</div>
	<div class="customize-modal" *ngIf="isDelete">
		<div class="header-modal">
			<span><i-feather name="trash-2"></i-feather> </span>
		</div>
		<div class="body-modal">
			<div class="content-modal">Are you want to delete this photo?</div>
		</div>
		<div class="footer-modal">
			<div class="btn-modal">
				<span>
					<a
						class="button is-solid white-button raised"
						(click)="closeModalDelete()"
						>Close</a
					></span
				>
				<span>
					<a class="button is-solid red-button raised" (click)="deleteImg()"
						>Delete</a
					></span
				>
			</div>
		</div>
	</div>
</div>
