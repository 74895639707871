<div id="share-modal" class="modal share-modal is-xsmall has-light-bg">
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-heading">
				<div class="dropdown is-primary share-dropdown modal-cus">
					<div>
						<div class="button">
							<i class="mdi mdi-format-float-left"></i>
							<span>Share in your feed</span>
							<i-feather name="chevron-down"></i-feather>
						</div>
					</div>
					<div class="dropdown-menu" role="menu">
						<div class="dropdown-content">
							<ng-container *ngFor="let share of arrShare; let index = index">
								<hr class="dropdown-divider" *ngIf="index == 4" />
								<div
									class="dropdown-item"
									[attr.data-target-channel]="share?.channel"
								>
									<div class="media">
										<i [attr.class]="share?.class"></i>
										<div class="media-content">
											<h3>{{ share?.mediaTitle }}</h3>
											<small>{{ share?.mediaContent }}</small>
										</div>
									</div>
								</div>
							</ng-container>
							<!-- <div class="dropdown-item" data-target-channel="feed">
                <div class="media">
                  <i class="mdi mdi-format-float-left"></i>
                  <div class="media-content">
                    <h3>Share in your feed</h3>
                    <small>Share this publication on your feed.</small>
                  </div>
                </div>
              </div> -->
							<!-- <div class="dropdown-item" data-target-channel="friend">
                <div class="media">
                  <i class="mdi mdi-account-heart"></i>
                  <div class="media-content">
                    <h3>Share in a friend's feed</h3>
                    <small> Share this publication on a friend's feed. </small>
                  </div>
                </div>
              </div>
              <div class="dropdown-item" data-target-channel="group">
                <div class="media">
                  <i class="mdi mdi-account-group"></i>
                  <div class="media-content">
                    <h3>Share in a group</h3>
                    <small>Share this publication in a group.</small>
                  </div>
                </div>
              </div>
              <div class="dropdown-item" data-target-channel="page">
                <div class="media">
                  <i class="mdi mdi-file-document-box"></i>
                  <div class="media-content">
                    <h3>Share in a page</h3>
                    <small>Share this publication in a page.</small>
                  </div>
                </div>
              </div>
              <hr class="dropdown-divider" />
              <div class="dropdown-item" data-target-channel="private-message">
                <div class="media">
                  <i class="mdi mdi-email-plus"></i>
                  <div class="media-content">
                    <h3>Share in message</h3>
                    <small>
                      Share this publication in a private message.
                    </small>
                  </div>
                </div>
              </div> -->
						</div>
					</div>
				</div>
				<!-- Close X button -->
				<div class="close-wrap">
					<span class="close-modal">
						<i-feather name="x"></i-feather>
					</span>
				</div>
			</div>
			<div class="share-inputs">
				<div class="field is-autocomplete">
					<div
						id="share-to-friend"
						class="control share-channel-control is-hidden"
					>
						<input
							id="share-with-friend"
							type="text"
							class="input is-sm no-radius share-input simple-users-autocpl"
							placeholder="Your friend's name"
						/>
						<div class="input-heading">Friend :</div>
					</div>
				</div>
				<div class="field is-autocomplete">
					<div
						id="share-to-group"
						class="control share-channel-control is-hidden"
					>
						<input
							id="share-with-group"
							type="text"
							class="input is-sm no-radius share-input simple-groups-autocpl"
							placeholder="Your group's name"
						/>
						<div class="input-heading">Group :</div>
					</div>
				</div>
				<div
					id="share-to-page"
					class="control share-channel-control no-border is-hidden"
				>
					<div class="page-controls">
						<div class="page-selection">
							<div class="dropdown is-accent page-dropdown">
								<div>
									<div class="button page-selector">
										<img
											src="https://via.placeholder.com/150x150"
											data-demo-src="assets/img/avatars/hanzo.svg"
											alt
										/>
										<span>Css Ninja</span>
										<i-feather name="chevron-down"></i-feather>
									</div>
								</div>
								<div class="dropdown-menu" role="menu">
									<div class="dropdown-content">
										<div class="dropdown-item">
											<div class="media">
												<img
													src="https://via.placeholder.com/150x150"
													data-demo-src="assets/img/avatars/hanzo.svg"
													alt
												/>
												<div class="media-content">
													<h3>Css Ninja</h3>
													<small>Share on Css Ninja.</small>
												</div>
											</div>
										</div>
										<div class="dropdown-item">
											<div class="media">
												<img
													src="https://via.placeholder.com/150x150"
													data-demo-src="assets/img/icons/logos/nuclearjs.svg"
													alt
												/>
												<div class="media-content">
													<h3>NuclearJs</h3>
													<small>Share on NuclearJs.</small>
												</div>
											</div>
										</div>
										<div class="dropdown-item">
											<div class="media">
												<img
													src="https://via.placeholder.com/150x150"
													data-demo-src="assets/img/icons/logos/slicer.svg"
													alt
												/>
												<div class="media-content">
													<h3>Slicer</h3>
													<small>Share on Slicer.</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="alias">
							<img
								src="https://via.placeholder.com/150x150"
								data-demo-src="assets/img/avatars/jenna.png"
								alt
							/>
						</div>
					</div>
				</div>
				<div class="field is-autocomplete">
					<div
						id="share-to-private-message"
						class="control share-channel-control is-hidden"
					>
						<input
							id="share-with-private-message"
							type="text"
							class="input is-sm no-radius share-input simple-users-autocpl"
							placeholder="Message a friend"
						/>
						<div class="input-heading">To :</div>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="control">
					<textarea
						class="textarea comment-textarea"
						rows="1"
						placeholder="Say something about this ..."
						[(ngModel)]="dataPost.postContent"
					></textarea>
					<button class="emoji-button">
						<i-feather name="smile"></i-feather>
					</button>
				</div>
				<div class="shared-publication">
					<div class="featured-image">
						<img
							id="share-modal-image"
							src="https://via.placeholder.com/1600x900"
							data-demo-src="assets/img/demo/unsplash/1.jpg"
							alt
						/>
					</div>
					<div class="publication-meta">
						<div class="inner-flex">
							<img
								id="share-modal-avatar"
								src="https://via.placeholder.com/300x300"
								data-demo-src="assets/img/avatars/dan.jpg"
							/>
							<p id="share-modal-text"></p>
						</div>
						<div class="publication-footer">
							<div class="stats">
								<div class="stat-block">
									<i class="mdi mdi-earth"></i>
									<small>Public</small>
								</div>
								<div class="stat-block">
									<i class="mdi mdi-eye"></i>
									<small>163 views</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom-share-inputs">
				<div
					id="action-place"
					class="field is-autocomplete is-dropup is-hidden"
				>
					<div id="share-place" class="control share-bottom-channel-control">
						<input
							type="text"
							class="input is-sm no-radius share-input simple-locations-autocpl"
							placeholder="Where are you?"
							[(ngModel)]="dataPost.postLocation"
						/>
						<div class="input-heading">Location :</div>
					</div>
				</div>
				<div id="action-tag" class="field is-autocomplete is-dropup is-hidden">
					<div id="share-tags" class="control share-bottom-channel-control">
						<input
							id="share-friend-tags-autocpl"
							type="text"
							[(ngModel)]="model"
							class="input is-sm no-radius share-input"
							placeholder="Who are you with"
							(ngModelChange)="searchUser(model)"
						/>
						<div
							class="easy-autocomplete-container"
							id="eac-container-share-friend-tags-autocpl"
						>
							<ul>
								<ng-container *ngFor="let user of arrUser | slice: 0:5">
									<li (click)="tagUser(user)">
										<div class="eac-item">
											<div class="template-wrapper">
												<div class="avatar-wrapper">
													<img
														class="autocpl-avatar"
														[src]="user?.avatar"
													/><img
														class="avatar-badge"
														src="assets/img/icons/flags/australia.svg"
													/>
												</div>
												<div class="entry-text">
													{{ user?.firstname }}{{ user?.lastname }}<br /><span
														>{{ user?.country }}</span
													>
												</div>
											</div>
										</div>
									</li>
								</ng-container>
							</ul>
						</div>
						<div class="input-heading">Friends :</div>
					</div>
					<div id="share-modal-tag-list" class="tag-list no-margin"></div>
				</div>
			</div>
			<div class="card-footer">
				<div class="action-wrap">
					<div class="footer-action" data-target-action="tag">
						<i class="mdi mdi-account-plus"></i>
					</div>
					<div class="footer-action" data-target-action="place">
						<i class="mdi mdi-map-marker"></i>
					</div>
					<div
						class="
							footer-action
							dropdown
							is-spaced is-neutral
							dropdown-trigger
							is-up
						"
						data-target-action="permissions"
					>
						<div>
							<i class="mdi mdi-lock-clock"></i>
						</div>
						<div class="dropdown-menu" role="menu">
							<div class="dropdown-content">
								<ng-container *ngFor="let display of arrDisplayPost">
									<a
										class="dropdown-item"
										(click)="selectDisplaySharePost(display)"
									>
										<div class="media">
											<i-feather [name]="display?.icon"></i-feather>
											<div class="media-content">
												<h3>{{ display?.mediaTitle }}</h3>
												<small>{{ display?.mediaContent }}</small>
											</div>
										</div>
									</a>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
				<div class="button-wrap">
					<button
						type="button"
						class="button is-solid dark-grey-button close-modal"
					>
						Cancel
					</button>
					<button
						type="button"
						class="button is-solid primary-button close-modal"
						(click)="createSharePost()"
					>
						Publish
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
