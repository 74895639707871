import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import iziToast from 'izitoast';
import { PagesService } from 'src/app/services/pages.service';
import { environment } from '../../../../../environments/environment';
declare var jQuery: any;

declare function initModals(): any;
@Component({
  selector: 'app-timeline-page',
  templateUrl: './timeline-page.component.html',
  styleUrls: ['./timeline-page.component.scss'],
})
export class TimelinePageComponent implements OnInit {
  @Output() pageActive = new EventEmitter<number>();
  @Input() isPageActive;
  @Input() idPage;
  @Input() dataPage;
  @Input() set isAdmin(isAdmin: boolean) {

    this.isAdminActive = isAdmin;
  }
  @Input() set countFollowPage(countFollowPage: number) {
    this.countFL =
      countFollowPage >= 1000 ? countFollowPage + 'K' : countFollowPage;
  }
  @Input() dataUser;
  @Input() set isLike(isLike: boolean) {
    this.isLikeActive = isLike;
  }
  @Output() isLikePageTimeLine = new EventEmitter<any>();
  @Output() isActiveAvtar = new EventEmitter<any>();
  countFL;
  isLikeActive;
  isAdminActive;
  pageAvatar;
  pageCover;
  idPageReceive;
  coverDefault;
  avatarDefault;
  enviroments;
  constructor(private pagesServe: PagesService) {
    this.enviroments = environment.apiUrl;
  }
  ngOnInit() {
    this.coverDefault = 'https://via.placeholder.com/1600x460';
    this.avatarDefault = 'https://via.placeholder.com/300x300';
    this.isActiveBtn = false;
    this.isPageActive = 1;
    this.pageActive.emit(this.isPageActive);
    this.idPageReceive = this.idPage;
    this.loadModal();
  }

  //#region OPEN modal function
  isActiveBtn;
  openMenuPlus() {
    this.loadModal();
    this.isActiveBtn = !this.isActiveBtn;
  }
  loadModal() {
    // (function ($) {
    //   $(document).ready(function () {
    //     $('.modal-trigger').on('click', function () {
    //       var modalID = $(this).attr('data-modal');

    //       $('#' + modalID).addClass('is-active');
    //     });
    //   });
    // })(jQuery);
    initModals();
  }

  initModals() {
    this.isActiveBtn = !this.isActiveBtn;
    this.isActiveAvtar.emit(true);
    this.loadModal();
  }
  //#endregion

  //#region Load Icon

  //#endregion

  clickActivePage(id) {
    this.isPageActive = id;
    this.pageActive.emit(id);
  }

  createLikePage() {
    if (this.dataPage && this.dataUser) {
      let data = {
        pageId: this.dataPage._id,
      };

      this.pagesServe.createLikePage(data).subscribe((resultlike) => {
        if (resultlike.status == 'success') {
          iziToast.show({
            class: 'toast',
            message: `Thích thành công`,
            titleColor: '#fff',
            messageColor: '#fff',
            iconColor: '#fff',
            backgroundColor: '#0062ff',
            progressBarColor: '#fafafa',
            position: 'bottomRight',
            transitionIn: 'fadeInDown',
            close: false,
            timeout: 500,
            zindex: 99999,
          });
          this.isLikeActive = true;
          this.isLike = true;
          this.isLikePageTimeLine.emit(true);
        }
      });
    }
  }

  unLikePage() {
    if (this.dataPage) {
      let data = {
        pageId: this.dataPage._id,
      };
      this.pagesServe.deleteLikeForPage(data).subscribe((result) => {
        if (result.status == 'success') {
          iziToast.show({
            class: 'toast',
            message: `Bỏ thích`,
            titleColor: '#fff',
            messageColor: '#fff',
            iconColor: '#fff',
            backgroundColor: '#0062ff',
            progressBarColor: '#fafafa',
            position: 'bottomRight',
            transitionIn: 'fadeInDown',
            close: false,
            timeout: 500,
            zindex: 99999,
          });
          this.isLike = false;
          this.isLikeActive = false;
          this.isLikePageTimeLine.emit(false);
        } else {
          this.isLikePageTimeLine.emit(true);
          this.isLike = true;
          this.isLikeActive = true;
        }
      });
    }
  }
}
