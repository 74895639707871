import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private http: HttpClient) { }

  uploadDraft(data): Observable<any> {
    return this.http.post('post/draft', data)
  }

  deleteDraft(data): Observable<any> {
    return this.http.post('post/delete-draft', data)
  }
}
