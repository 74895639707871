import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import * as environment from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { FeedService } from 'src/app/services/feed.service';
import { PagesService } from 'src/app/services/pages.service';
declare var jQuery: any;
declare var feather: any;
declare function initModals(): any;
declare function initShareModal(): any;
declare var window: any;
import iziToast from 'izitoast';
@Component({
  selector: 'app-post-timeline',
  templateUrl: './post-timeline.component.html',
  styleUrls: ['./post-timeline.component.scss'],
})
export class PostTimelineComponent implements OnInit {
  infousers;
  @Input() set infouser(dataUser) {
    this.user = dataUser;
  }
  post: any;
  constructor(
    private profileService: ProfileService,
    private feedService: FeedService,
    private pageService: PagesService,
    private route: ActivatedRoute
  ) { }
  @HostListener("window:scroll", [])
  onScroll(): void {
    if (this.bottomReached()) {
      console.log(true);

      this.getMorePosts()
    }
  }
  bottomReached(): boolean {
    return ((window.scrollY + document.body.offsetHeight) >= document.body.scrollHeight);
  }
  dataPosts = [];
  dataComments;
  dataReplies;
  dataLikes;
  user;
  environment = environment.environment.apiUrl;
  userId;

  isLoading: boolean = false;
  @Input() reload: boolean;
  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('id');

    this.getPosts();
    this.profileService.changeSuccess.subscribe((result) => {
      if (result.status == 'success') {
        this.getPosts();
        setTimeout(() => {
          this.isLoading = true;
        }, 500);
      }
    });

    this.feedService.changeSuccess.subscribe((result) => {
      if (result.isSubmit == true) {
        this.getPosts();
        setTimeout(() => {
          this.isLoading = true;
        }, 500);
      }
    });
    this.initChangeTexts();
    setTimeout(() => {
      this.isLoading = true;
    }, 500);
  }

  closeComment(indexPostCurrent) {
    let comment = document.getElementById(`${indexPostCurrent}comments-wrap`);
    comment.classList.add('is-hidden');
  }

  openComment(indexPostCurrent) {
    let comment = document.getElementById(`${indexPostCurrent}comments-wrap`);
    comment.classList.remove('is-hidden');
  }

  //#region ---------------------------- Post of user--------------------------
  //#region FUNCTION GET arr posts of User/Page
  initGetPost(dataPosts) {
    if (dataPosts.length > 0) {
      let filter = {
        limit: this.limitCmt,
        skip: this.skipCmt,
      };

      let filterView = {
        isActive: false,
        isViewMore: false,
      };

      for (let post of dataPosts) {
        // get like post
        post.contenteditable = false;
        post.type = 0;
        this.getLikeByPostIdUser(post);
        post.isLiked = false;
        post.arrLike = [];
        post.filterView = {
          isActive: false,
          isViewMore: false,
        };
        // get comment
        post.filterView.isActive = filterView.isActive;
        post.filterView.isViewMore = filterView.isViewMore;
        post.filterComment = {
          limit: this.limitCmt,
          skip: this.skipCmt,
        };
        post.filterComment.skip = filter.skip;
        post.filterComment.limit = filter.limit;

        this.getComments(post, post.filterComment);
        post.postShare = post.postShare || [];
        post.countShare = post.countShare || 0;
        this.getShareUser(post);
      }
      return dataPosts;
    }
    return dataPosts;
  }
  getPosts() {
    let data = {
      userId: this.userId,
    };
    let reqShare = {
      skip: this.skip,
      limit: this.limit,
      userId: this.userId,
    };
    this.profileService
      .getPostByUserId(data)
      .subscribe(async (resultpostUser) => {
        if (resultpostUser.status == 'success') {
          // this.profileService
          //   .getPostShareByUserId(reqShare)
          //   .subscribe(async (resultpostpost) => {
          //     if (resultpostpost.status == 'success') {
          //       let arrPostUser =
          //         (await this.initGetPost(resultpostUser.data)) || [];
          //       let arrPostShare =
          //         (await this.initGetPost(resultpostpost.data)) || [];
          //       this.dataPosts = arrPostUser.concat(arrPostShare);
          //     }
          //   });
          this.dataPosts = (await this.initGetPost(resultpostUser.data)) || [];
        }
      });
  }
  //#endregion
  //#region GET list posts of user
  limitCmt;
  skipCmt;
  skip;
  limit;
  skipReplyUser;
  limitReplyUSer;
  getComments(post, filter) {
    let requestComment = {
      postId: post._id,
      skip: filter.skip,
      limit: filter.limit,
    };
    post.arrComment = post.arrComment || [];
    post.countComment = post.countComment || 0;
    let filters = {
      limit: this.limitReplyUSer,
      skip: this.skipReplyUser,
    };
    let filterView = {
      isActive: false,
      isViewMore: false,
    };
    this.feedService
      .getCommentByPostId(requestComment)
      .subscribe((dataComment) => {
        if (dataComment.status == 'success') {
          this.initComment(dataComment, post, filters, filterView);
        }
      });
  }
  initComment(result, post, filterReplies, filterView) {
    post.countComment = result.count;
    post.arrComment = result.data.concat(post.arrComment);
    if (post.arrComment.length > 0) {
      if (post.countComment > post.filterComment.skip) {
        post.filterView.isActive = true;
        post.filterView.isViewMore = true;
      }
      for (let comment of post.arrComment) {
        //filter get replies from comment Id
        comment.showEditComment = false;
        comment.contenteditable = false;
        comment.filterReplies = {
          skip: filterReplies.skip,
          limit: filterReplies.limit,
        };

        comment.filterView = {
          isActivce: filterView.isActive,
          isViewMore: filterView.isViewMore,
        };
        comment.arrReplies = comment.arrReplies || [];
        comment.countReplies = comment.countReplies || 0;
        comment.isLiked = false;
        comment.arrLike = comment.arrLike || [];
        this.getReplyByCmtIdUser(comment, comment.filterReplies);
        this.getLikeByCommentIdUser(comment);
        this.checkCommentForUser(comment);
      }
    }
  }
  checkCommentForUser(comment) {
    comment.isUser = comment.userId._id == this.user._id ? true : false;
  }

  isGetMore = false;
  getMorePosts() {
    this.isGetMore = !this.isGetMore;
    this.skip += +5;
    let data = {
      skip: this.skip,
    };
    this.profileService.getMorePosts(data).subscribe((result) => {
      if (result.status == 'success') {
        let arrMorePost = this.initGetPost(result.data);
        this.dataPosts = this.dataPosts.concat(arrMorePost);
      }
    });
  }
  //#endregion

  //#region CRUD like post of user
  createLikePost(post, indexPostUser) {
    let data = {
      postId: post._id,
      type: 0,
    };
    this.profileService.createLike(data).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByPostIdUser(this.dataPosts[indexPostUser]);
      }
    });
  }
  // params : post
  getLikeByPostIdUser(post) {
    this.feedService.getLikeByPostId(post._id).subscribe((result) => {
      if (result.status == 'success') {
        post.arrLike = result.data;
        if (post.arrLike.length > 0) {
          let ischeckUser = post.arrLike.find(
            (like) => like.userId._id === this.user._id
          );
          if (ischeckUser) {
            post.isLiked = true;
          }
        }
      }
    });
  }
  deleteLikePostUser(post, indexPost) {
    this.feedService.deleteLikePost(post._id).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByCommentIdUser(this.dataPosts[indexPost]);
      }
    });
  }
  //#endregion

  //#region Detect files imgage in comment
  formatUser;
  detectFileUser(event, indexPostUser) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        if (file.type.indexOf('image') > -1) {
          this.formatUser = 'image';
        } else if (file.type.indexOf('video') > -1) {
          this.formatUser = 'video';
        }
        reader.onload = (e: any) => {
          document.getElementById(
            `${indexPostUser}cmt-image-user`
          ).style.display = 'block';
          if (file.type.indexOf('image') > -1) {
            this.dataPosts[indexPostUser].contentImg = e.target.result;
          } else if (file.type.indexOf('video') > -1) {
            this.dataPosts[indexPostUser].contentVideo = e.target.result;
          }
        };
      }
    }
  }
  //#endregion

  //#region CRUD comment, like comment of user
  repeatTag(arr) {
    if (arr.length > 1) {
      let count = 0;
      for (let i = 0; i < arr.length; i++) {
        for (let j = 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            return (count = count + 1);
          }
        }
      }
    }
  }
  arrUser = [];
  checkKeySearch;
  arrName = [];
  searchUser(username) {
    if (username != undefined || username != null) {
      (function ($) {
        $(document).ready(function () {
          $('.tag-fr').focusout(function () {
            var element = $(this);
            if (!element.text().replace(' ', '').length) {
              element.empty();
            }
          });
        });
      })(jQuery);
      var start = /@/gi; // @ Match
      var word = /@(\w+)/gi; //@abc Match
      if (username.indexOf('@') > -1) {
        var go = username.match(start); //Content Matching @
        this.arrName = username.match(word); //Content Matching @abc
        if (go.length > 0) {
          if (this.arrName) {
            let count = 0;
            if (this.arrName.length > 1) {
              count = this.arrName.length - 1;
            }
            let usernameFillter = this.arrName[count].toString().slice(1);
            this.pageService
              .searchUser({ search: usernameFillter })
              .subscribe((arrUser) => {
                if (arrUser.status == 'success') {
                  this.arrUser = arrUser.data;
                }
              });
          } else {
            this.arrUser = [];
          }
        }
      }
    }
  }

  tagUser(user, indexPostUser) {
    let fullname = user.firstname + user.lastname;
    this.dataPosts[indexPostUser].commentTagUser = this.dataPosts[indexPostUser].commentTagUser ? this.dataPosts[indexPostUser].commentTagUser : []
    this.dataPosts[indexPostUser].commentTagUser.push({
      tag: '@' + fullname,
      userId: user._id,
    });
    let txtContent = document.getElementById(
      `${indexPostUser}tag-friends`
    ).innerHTML;
    let count = 0;
    if (this.repeatTag(this.arrName) > 0) {
      let lengthLast = this.arrName[this.arrName.length - 1].length;
      txtContent = txtContent.slice(0, txtContent.length - lengthLast);
    } else {
      if (this.arrName.length > 1) {
        count = this.arrName.length - 1;
      }
      let indexCount = txtContent.indexOf(this.arrName[count], 0);
      txtContent = txtContent.slice(0, indexCount);
    }
    document.getElementById(`${indexPostUser}tag-friends`).innerHTML =
      txtContent;
    this.changeCommentContent(fullname, indexPostUser);
    this.arrUser = [];
  }
  changeCommentContent(commentContent, index) {
    console.log();

    (function ($) {
      $(document).ready(function () {
        $(`#${index}tag-friends`).append(
          `<a contenteditable="false"  style="margin-left:3px;color:#000;padding:3px;background:#5DADE2;width:fit-content;border-radius:1px;font-size: 0.95rem;"  > ${commentContent}</a>`
        );
      });
    })(jQuery);
  }
  commentContentUser;
  commentTagUser;
  commentImgUser;
  commentVideoUser;
  createCommentUser(post, indexPostUser) {
    let commentContentUser = document.getElementById(
      `${indexPostUser}tag-friends`
    ).innerHTML;
    if (commentContentUser) {
      let requestComment = {
        postId: post._id,
        commentContent: commentContentUser,
        commentImg: this.dataPosts[indexPostUser].contentImg,
        commentTag: this.dataPosts[indexPostUser].commentTagUser,
        commentVideo: this.dataPosts[indexPostUser].contentVideo,
      };
      this.profileService.createComment(requestComment).subscribe((comment) => {
        if (comment.status == 'success') {
          let commentNew = comment.data;
          this.getCommentByIdUser(indexPostUser, commentNew._id);
          this.dataPosts[indexPostUser].contentImg = undefined;
          this.dataPosts[indexPostUser].contentVideo = undefined;
          this.dataPosts[indexPostUser].countComment++;
          document.getElementById(`${indexPostUser}tag-friends`).innerHTML = '';
        }
      });
    }

  }

  getCommentByIdUser(indexPostUser, commentId) {
    let filters = {
      limit: this.limitReplyUSer,
      skip: this.skipReplyUser,
    };
    let filterView = {
      isActive: false,
      isViewMore: false,
    };
    this.feedService.getCommentById(commentId).subscribe((result) => {
      if (result.status == 'success') {
        let commentNew = result.data;
        commentNew.arrLike = commentNew.arrLike || [];
        commentNew.isLiked = false;
        commentNew.filterReply = filters;
        commentNew.arrReply = commentNew.arrReply || [];
        commentNew.filterView = filterView;
        commentNew.countReply = commentNew.countReply || 0;
        this.insertComment(indexPostUser, commentNew);
      }
    });
  }
  insertComment(indexPost, commentNew) {
    this.dataPosts[indexPost].arrComment.push(commentNew);
  }

  updateCommentByUser(commentId, indexPostUSer) {
    let requestUpdateComment = {
      id: commentId,
    };
    this.feedService
      .updateCommentByUserId(requestUpdateComment)
      .subscribe((result) => {
        if (result.status == 'success') {
        }
      });
  }
  //#endregion

  //#region CRUD like comment of user
  createLikeCommentUser(post, comment, indexPostUser, indexComment) {
    let data = {
      postId: post._id,
      commentId: comment._id,
      type: 1,
    };
    this.profileService.createLike(data).subscribe((like) => {
      if (like.status == 'success') {
        let post = this.dataPosts[indexPostUser];
        let comment = post.arrComment[indexComment];
        this.getLikeByCommentIdUser(comment);
      }
    });
  }

  getLikeByCommentIdUser(comment) {
    this.feedService.getLikeByCommentId(comment._id).subscribe((result) => {
      if (result.status == 'success') {
        comment.arrLike = result.data;
        let arrLikeNew = result.data;
        let isLikeUser = arrLikeNew.find(
          (like) => like.userId._id === this.user._id
        );

        comment.isLiked = isLikeUser ? true : false;
      }
    });
  }
  deleteLikeCommentUser(comment, indexPostUser, indexComment) {
    this.feedService.deleteLikeComment(comment._id).subscribe((result) => {
      if (result.status == 'success') {
        let post = this.dataPosts[indexPostUser];
        let comment = post.arrComment[indexComment];
        this.getLikeByCommentIdUser(comment);
      }
    });
  }

  viewMoreCommentUser(indexPostUser) {
    let post = this.dataPosts[indexPostUser];
    post.filterComment.skip =
      post.filterComment.skip + post.filterComment.limit;
    this.getComments(post, post.filterComment);
  }
  //#endregion

  //#region CRUD reply of comment for users
  arrReplyTimeUser = [];
  idCommentRepyUser;
  clickReplyUser(username, comment, indexPostUser, indexCommentUser) {
    document.getElementById(`${indexPostUser}tag-friends`).focus();
    let post = this.dataPosts[indexPostUser];
    post.commentReplyByUser = comment;
    post.indexCommentReplyByUser = indexCommentUser;
    post.arrReplyTemporary = post.arrReplyTemporary || [];
    post.arrReplyTemporary.push(username);

    let fullname = username.firstname + username.lastname;
    if (this.user._id != username._id) {
      this.changeCommentContent(fullname, indexPostUser);
    }
  }
  clearReply(post) {
    post.commentReplyByUser = undefined;
    post.indexCommentReplyByUser = undefined;
    post.arrReplyTemporary = [];
  }
  replyContentUser;
  replyImgUser;
  createReplyUser(postId, indexPostUser) {
    let commentIdReplyByUser =
      this.dataPosts[indexPostUser].commentReplyByUser._id;
    let replyImg = this.dataPosts[indexPostUser].contentImg;
    let replyContentUser = document.getElementById(
      `${indexPostUser}tag-friends`
    ).innerHTML;

    let requestReply = {
      postId: postId,
      commentId: commentIdReplyByUser,
      replyContent: replyContentUser,
      replyImg: replyImg,
    };

    this.profileService.createReply(requestReply).subscribe((reply) => {
      if (reply.status == 'success') {
        let replyNew = reply.data;
        let postCurrent = this.dataPosts[indexPostUser];
        let indexCommentCurrent = postCurrent.indexCommentReplyByUser;
        this.getReplyById(replyNew, indexPostUser, indexCommentCurrent);

        // format div contentiditTable == ""
        document.getElementById(`${indexPostUser}tag-friends`).innerHTML = '';
        postCurrent.indexCommentReplyByUser = {};
        postCurrent.contentImg = '';
      }
    });
  }

  getReplyById(reply, indexPostCurrent, indexCommentCurrent) {
    this.feedService.getReplyById(reply._id).subscribe((result) => {
      if (result.status == 'success') {
        let replyNew = result.data;
        this.insertReplies(indexPostCurrent, indexCommentCurrent, replyNew);
      }
    });
  }
  insertReplies(indexPostCurrent, indexCommentCurrent, replyNew) {
    let post = this.dataPosts[indexPostCurrent];
    let commentCurrent = post.arrComment[indexCommentCurrent];
    commentCurrent.arrReplies = commentCurrent.arrReplies || [];
    commentCurrent.arrReplies.push(replyNew);
    post.countComment = Number(post.countComment + 1);
  }

  getReplyByCmtIdUser(comment, filter) {
    let request = {
      commentId: comment._id,
      skip: filter.skip,
      limit: filter.limit,
    };

    this.feedService.getReplyUserByCmtId(request).subscribe((reply) => {
      if (reply.status == 'success') {
        let arrReplyNew = reply.data;
        comment.arrReplies = arrReplyNew.concat(comment.arrReplies);
        comment.countReply = reply.count;
        if (comment.filterReply &&
          comment.arrReplies.length > 0 &&
          comment.countReply > comment.filterReply.skip
        ) {
          comment.filterView.isActive = true;
          comment.filterView.isViewMore = true;
          for (let reply of comment.arrReplies) {
            reply.showEditReply = false;
            reply.contenteditable = false;
          }
        }
      }
    });
  }

  viewMoreReplies(comment) {
    comment.filterReply.skip += +comment.filterReply.limit;
    this.getReplyByCmtIdUser(comment, comment.filterReply);
  }
  //#endregion

  //#region CRUD like reply of user
  likeReply(post, comment, reply) {
    let data = {
      postId: post._id,
      commentId: comment._id,
      reply: reply._id,
      type: 2,
    };
    this.profileService.createLike(data).subscribe((likeReply) => { });
  }
  //#endregion

  //#region GET list post share by user login
  getShareByPostId(post) {
    let arrPostShare = [];
    this.profileService.getshareByPostId(post._id).subscribe((result) => {
      if (result.status == 'success') {
        arrPostShare = result.data;
        post.arrShare = arrPostShare;
      }
    });
  }
  //#endregion

  //#region GET all list post

  sortFunction(a, b) {
    var dateA = new Date(a.createdAt).getTime();
    var dateB = new Date(b.createdAt).getTime();
    return dateB > dateA ? 1 : -1;
  }
  //#endregion

  //#endregion
  //#region FUNCTION share post
  sharePost(dataPost) {
    initModals();
    initShareModal();
    this.feedService.clickSharePost({ status: 'success', data: dataPost });
  }
  //#endregion
  model;
  arrSpan = [];
  word;
  //#region Delete image of comment in post
  closeImg(indexPostUser) {
    this.commentImgUser = undefined;
    document.getElementById(`${indexPostUser}cmt-image`).style.display = 'none';
    if (this.formatUser === 'image') {
      this.commentImgUser = undefined;
      this.formatUser = undefined;
    } else if (this.formatUser === 'video') {
      this.commentVideoUser = undefined;
      this.formatUser = undefined;
    }
  }
  //#endregion
  //#region LIKE/ UNLIKE reply of comment
  createLikeReply(post, reply, comment) {
    let likeReply = {
      commentId: comment._id,
      replyId: reply._id,
      type: 2,
      postId: post._id,
    };
    this.pageService.createLikeReply(likeReply).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByReplyIdUser(reply);
      }
    });
  }
  getLikeByReplyIdUser(replies) {
    this.feedService.getLikeByReplyId(replies._id).subscribe((result) => {
      if (result.status == 'success') {
        replies.arrLikeReplies = result.data;
        this.checkLikeReplyUser(replies);
      }
    });
  }
  checkLikeReplyUser(replies) {
    let checkLikeReplies = replies.arrLikeReplies.find(
      (item) => item.userId._id === this.user._id
    );
    replies.isLikeReplies = checkLikeReplies ? true : false;
    this.checkRepliesUser(replies);
  }
  checkRepliesUser(replies) {
    return (replies.isUser =
      replies.userId._id == this.user._id ? true : false);
  }

  getLikeByReplyId(replies) {
    this.pageService.getLikeByReplyId(replies._id).subscribe((result) => {
      if (result.status == 'success') {
        if (result.data) {
          let arrReplyNew = result.data;
          if (arrReplyNew) {
            replies.arrLikeReply = arrReplyNew;
            this.checkLikeReply(replies);
          }
        }
      }
    });
  }
  checkLikeReply(replies) {
    let arrLikeReply = replies.arrLikeReply;
    const lengthArrLikeReply = arrLikeReply.length;
    if (lengthArrLikeReply > 0) {
      for (let i = 0; i < lengthArrLikeReply; i++) {
        if (this.user._id == arrLikeReply[i].userId._id) {
          replies.isLikeReply = true;
        }
      }
    } else {
      replies.isLikeReply = false;
    }
  }

  unLikeReply(reply) {
    let data = {
      replyId: reply._id,
    };
    this.pageService.unLikeReply(data).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByReplyIdUser(reply);
      }
    });
  }
  //#endregion

  //#region change text coppy
  initChangeTexts() {
    (function ($) {
      $(document).on('paste', '[contenteditable]', function (e) {
        var content;
        e.preventDefault();

        if (window.clipboardData) {
          content = window.clipboardData.getData('Text');
          if (window.getSelection) {
            var selObj = window.getSelection();
            var selRange = selObj.getRangeAt(0);
            selRange.deleteContents();
            selRange.insertNode(document.createTextNode(content));
          }
        } else if (e.originalEvent.clipboardData) {
          content = (e.originalEvent || e).clipboardData.getData('text/plain');
          document.execCommand('insertText', false, content);
        }
      });
    })(jQuery);
  }
  //#endregion
  //#region FUNCTION get share post
  getShareUser(post) {
    this.feedService.getShareUser(post._id).subscribe((result) => {
      if (result.status == 'success') {
        post.countShare = result.count;
        post.postShare = result.data;
      }
    });
  }
  //#endregion

  //#region Open fancybox
  dataPost;
  isActivePhoto: boolean = false;
  openPostFancyBox(post) {
    console.log(post);
    post.type = 0;
    this.dataPost = post;
    this.isActivePhoto = true;
  }
  closeModal(event) {
    this.isActivePhoto = event;
  }
  //#endregion
  indexImg;
  getImage(index) {
    this.indexImg = index
    this.isActivePhoto = true;
  }
  editPost(post) {
    post.contenteditable = !post.contenteditable;
    if (post.contenteditable == true) {
      let div = document.getElementById(post._id);
      setTimeout(function () {
        div.focus();
      }, 0);
    }

  }
  updatePost(post) {
    let postContent: string = document.getElementById(post._id).innerHTML;
    post.contenteditable = false;
    this.profileService.updatePost(post._id, { postContent }).subscribe(result => {
      iziToast.success({
        title: 'Cập nhật bài viết thành công.',
        titleColor: '#000',
        timeout: 1000,
      })
    })
  }
  editReply(reply) {
    reply.contenteditable = !reply.contenteditable;
    if (reply.contenteditable == true) {
      let div = document.getElementById(reply._id);
      setTimeout(function () {
        div.focus();
      }, 0);
    }
  }
  updateReply(reply) {
    let postContent: string = document.getElementById(reply._id).innerHTML;
    reply.contenteditable = false;
    this.profileService.updateReply(reply._id, { content: postContent }).subscribe(result => {
      console.log(result);

    })
  }
  editComment(comment) {
    comment.contenteditable = !comment.contenteditable;
    if (comment.contenteditable == true) {
      let div = document.getElementById(comment._id);
      setTimeout(function () {
        div.focus();
      }, 0);
    }
  }
  updateComment(comment) {
    let postContent: string = document.getElementById(comment._id).innerHTML;
    comment.contenteditable = false;
    this.profileService.updateComment(comment._id, { content: postContent }).subscribe(result => {

    })
  }
}
