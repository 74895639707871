<div id="create-group" class="modal create-group is-xxl has-light-bg">
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-heading">
				<h3>Create Group</h3>
				<!-- Close X button -->
				<div
					class="close-wrap close-cus-wrapper"
					(click)="closeModalCreatePage()"
					#closeModal
				>
					<span class="close-modal">
						<i-feather name="x"></i-feather>
					</span>
				</div>
			</div>
			<div class="card-body">
				<div class="left-section">
					<div class="page-form albums-form">
						<div class="control">
							<input
								type="text"
								class="cus-input input is-sm no-radius is-fade"
								placeholder="Group Name"
								[(ngModel)]="groupName"
							/>
							<div class="icon cus-icon">
								<i-feather name="home"></i-feather>
							</div>
						</div>
						<div class="control">
							<div
								class="cus-input input is-sm no-radius is-fade"
								(click)="openDisplay()"
							>
								<div class="form-display">
									<span>{{ previewDisplay }}</span>
								</div>
							</div>
							<div class="icon cus-icon" *ngIf="previewIcon">
								<i-feather name="{{ previewIcon }}"></i-feather>
							</div>
							<div class="form-gr-display" *ngIf="isDisplay">
								<ng-container>
									<div
										class="gr-display"
										*ngFor="let display of arrDisplay"
										(click)="selectDiplay(display)"
									>
										<div class="gr-icon">
											<i-feather name="globe"></i-feather>
										</div>
										<div class="gr-wrapper">
											<div class="gr-wrapper-title">
												{{ display.nameValue }}
											</div>
											<div class="gr-wrapper-content">
												{{ display.content }}
											</div>
										</div>
									</div>
								</ng-container>
							</div>
						</div>
						<div class="control" *ngIf="isChangePrivateGroup">
							<div
								class="cus-input input is-sm no-radius is-fade"
								(click)="openHidden()"
							>
								<div class="form-display">
									<span>{{ previewHidden }}</span>
								</div>
							</div>
							<div class="icon cus-icon" *ngIf="previewIconHidden">
								<i-feather name="{{ previewIconHidden }}"></i-feather>
							</div>
							<div class="form-gr-display" *ngIf="isHidden == true">
								<ng-container>
									<div
										class="gr-display"
										*ngFor="let hd of arrHidden"
										(click)="selectHidden(hd)"
									>
										<div class="gr-icon">
											<i-feather [name]="hd.icon"></i-feather>
										</div>
										<div class="gr-wrapper">
											<div class="gr-wrapper-title">
												{{ hd.nameValue }}
											</div>
											<div class="gr-wrapper-content">
												{{ hd.content }}
											</div>
										</div>
									</div>
								</ng-container>
							</div>
						</div>

						<div class="control">
							<textarea
								class="textarea is-fade no-radius is-sm"
								rows="3"
								placeholder="Description for group..."
								[(ngModel)]="groupDescription"
							></textarea>
						</div>
					</div>
				</div>
				<div class="right-section has-slimscroll">
					<div class="container is-custom preview-page">
						<div id="pages-main" class="view-wrap is-headless">
							<div class="columns is-multiline no-margin">
								<div class="column is-paddingless">
									<div class="cover-bg">
										<img
											#valueImgCover
											class="cover-image"
											src="/assets/img/defaultcover.jpg"
											data-demo-src="assets/img/demo/bg/4.jpg"
											alt
										/>

										<div class="cover-overlay"></div>
										<div class="cover-edit modal-trigger">
											<i class="mdi mdi-camera"></i>
											<span>Edit cover image</span>
										</div>
										<!-- /html/partials/pages/pages/dropdowns/page-timeline-mobile-dropdown.html -->
										<div
											class="dropdown is-spaced is-right is-accent dropdown-trigger timeline-mobile-dropdown is-hidden-desktop"
										>
											<div>
												<div class="button">
													<i-feather name="more-vertical"></i-feather>
												</div>
											</div>
											<div class="dropdown-menu" role="menu">
												<div class="dropdown-content">
													<a class="dropdown-item">
														<div class="media">
															<i-feather name="activity"></i-feather>
															<div class="media-content">
																<h3>Timeline</h3>
																<small>Open Timeline.</small>
															</div>
														</div>
													</a>
													<a class="dropdown-item">
														<div class="media">
															<i-feather name="align-right"></i-feather>
															<div class="media-content">
																<h3>About</h3>
																<small>See about info.</small>
															</div>
														</div>
													</a>
													<a class="dropdown-item">
														<div class="media">
															<i-feather name="globe"></i-feather>
															<div class="media-content">
																<h3>Community</h3>
																<small>See community.</small>
															</div>
														</div>
													</a>
													<a class="dropdown-item">
														<div class="media">
															<i-feather name="image"></i-feather>
															<div class="media-content">
																<h3>Photos</h3>
																<small>See all photos.</small>
															</div>
														</div>
													</a>
												</div>
											</div>
										</div>
									</div>

									<div class="profile-menu is-hidden-mobile">
										<div class="menu-start">
											<a class="button has-min-width"> Timeline </a>
											<a class="button has-min-width"> About </a>
										</div>
										<div class="menu-end">
											<a class="button has-min-width"> Community </a>
											<a class="button has-min-width"> Photos </a>
										</div>
									</div>

									<div class="profile-subheader">
										<div class="subheader-start is-hidden-mobile">
											<span>148K</span>
											<span>Followers</span>
										</div>
										<div class="subheader-middle">
											<h2>{{ groupName ? groupName : previewName }}</h2>
										</div>
										<div class="subheader-end is-hidden-mobile">
											<a class="button has-icon is-bold">
												<i-feather name="clock"></i-feather>
												History
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<button
					type="button"
					class="button is-solid accent-button close-modal"
					(click)="createGroup()"
				>
					Create group
				</button>
			</div>
		</div>
	</div>
</div>
