<div class="box-heading">
  <h4>Photos</h4>
  <div class="dropdown is-neutral is-spaced is-right dropdown-trigger">
    <div>
      <div class="button">
        <i-feather name="more-vertical"></i-feather>
      </div>
    </div>
    <div class="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a class="dropdown-item">
          <div class="media">
            <i-feather name="image"></i-feather>
            <div class="media-content">
              <h3>View Photos</h3>
              <small>View all your photos</small>
            </div>
          </div>
        </a>
        <a href="#" class="dropdown-item">
          <div class="media">
            <i-feather name="tag"></i-feather>
            <div class="media-content">
              <h3>Tagged</h3>
              <small>View photos you are tagged in.</small>
            </div>
          </div>
        </a>
        <a href="#" class="dropdown-item">
          <div class="media">
            <i-feather name="folder"></i-feather>
            <div class="media-content">
              <h3>Albums</h3>
              <small>Open my albums.</small>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="photos">
  <div class="card">
    <div class="card-wrap">
      <div class="is-photos-widget phots-widget-wrap">
        <img
          *ngFor="let photo of photos | slice: 0:9"
          [src]=" photo?.photoName"
          data-demo-src="assets/img/demo/widgets/photos/1.jpg"
          alt
        />
      </div>
      <div class="btn-see">
        <button class="button is-fullwidth btn-see-cus" (click)="clickSeeAll()">
          See All
        </button>
      </div>
    </div>
  </div>
</ng-container>
