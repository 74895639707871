import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-group-setting',
  templateUrl: './admin-group-setting.component.html',
  styleUrls: ['./admin-group-setting.component.scss']
})
export class AdminGroupSettingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
