<!-- 175 705  layout-navbar.scss-->
<div class="navbar-group">
  <div class="navbar-item is-icon drop-trigger">
    <div class="count">
      {{ memberRequests ? memberRequests.length : "0" }}
    </div>
    <a class="icon-link is-secondary is-friends">
      <i-feather name="heart"></i-feather>
      <span class="indicator"></span>
    </a>
    <div class="nav-drop">
      <div class="inner">
        <div
          class="nav-drop-body is-friend-requests"
          *ngIf="memberRequests && memberRequests.length > 0"
        >
          <ng-container *ngFor="let request of memberRequests">
            <div class="media">
              <div class="media-left">
                <div class="image">
                  <img
                    [src]="request.senderId.avatar"
                    data-demo-src="assets/img/avatars/dan.jpg"
                    alt
                  />
                </div>
              </div>
              <div class="media-content">
                <a href="#">
                  {{ request.senderId.firstname }}
                  {{ request.senderId.lastname }}
                </a>
                <span>Request to join the group</span>
              </div>
              <div class="media-right">
                <button
                  class="button icon-button is-solid grey-button raised"
                  (click)="createMember(request)"
                >
                  <i-feather name="user-plus"></i-feather>
                </button>
                <button
                  class="button icon-button is-solid grey-button raised"
                  (click)="deleteMemberRequest(request)"
                >
                  <i-feather name="user-minus"></i-feather>
                </button>
              </div>
              <hr />
            </div>
          </ng-container>
        </div>
        <div class="nav-drop-footer">
          <a href="#">View All</a>
        </div>
      </div>
    </div>
  </div>
</div>
