import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-group-confirm-leave-dialog',
  templateUrl: './group-confirm-leave-dialog.component.html',
  styleUrls: ['./group-confirm-leave-dialog.component.scss'],
})
export class GroupConfirmLeaveDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GroupConfirmLeaveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onYesClick() {
    let data = {
      status: 'yes',
    };
    this.dialogRef.close(data);
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
