import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trips-widget',
  templateUrl: './trips-widget.component.html',
  styleUrls: ['./trips-widget.component.scss']
})
export class TripsWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
