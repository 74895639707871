import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StoriesService } from 'src/app/services/stories.service';
import * as environment from '../../../../environments/environment';

@Component({
  selector: 'app-stories-sidebar',
  templateUrl: './stories-sidebar.component.html',
  styleUrls: ['./stories-sidebar.component.scss'],
})
export class StoriesSidebarComponent implements OnInit {
  infoUsers;
  @Input() set infoUser(infoUser: any) {
    this.infoUsers = infoUser;
  }
  arrStories = [];
  @Input() set arrStory(data: any) {
    this.arrStories = data;
    if (this.arrStories.length > 0) {
      this.arrStories[0].isActive = true;
    }
  }

  @Output() openStorySide = new EventEmitter<any>();

  environment;
  constructor(private storiesService: StoriesService) {
    this.environment = environment.environment.apiUrl;
  }

  ngOnInit(): void {}
  openStory(id, index) {
    this.openStorySide.emit({ index: index });
    this.storiesService.openStory({ isClick: true, index: index });
  }
}
