<h1 mat-dialog-title>THÔNG BÁO {{ data?.groupName }}</h1>
<hr />
<div mat-dialog-content class="description-dialog">
  <p>{{ data?.description }}</p>
  <hr />
  <p>Thao tác này không thể hoàn tác, chắc chắn thực hiện ?</p>
</div>
<div mat-dialog-actions>
  <div class="button-wrap">
    <div class="btn-no" mat-button (click)="onNoClick()">Không</div>
    <div class="btn-yes" mat-button (click)="onYesClick()">Có</div>
  </div>
</div>
