<h1 mat-dialog-title>THÔNG BÁO</h1>
<hr />
<div mat-dialog-content class="description-dialog">
  <p>
    Bạn muốn loại bỏ quyền quản lý của thành viên
    <span>{{ data.adminName }}</span> trong nhóm
    <span>{{ data.groupName }}</span>
  </p>
  <p>
    Sau khi loại bỏ quyển quản lý, thành viên
    <span>{{ data.adminName }}</span> sẽ trở thành thành viên bình thường
  </p>
  <hr />
</div>
<div mat-dialog-actions>
  <div class="button-wrap">
    <div class="btn-no" mat-button (click)="onNoClick()">Không</div>
    <div class="btn-yes" mat-button (click)="onYesClick()">Có</div>
  </div>
</div>
