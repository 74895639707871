<div class="dropdown is-spaced is-right is-neutral dropdown-trigger">
	<div>
		<div class="button">
			<i-feather name="more-vertical"></i-feather>
		</div>
	</div>
	<div class="dropdown-menu" role="menu">
		<div class="dropdown-content">
			<a class="dropdown-item">
				<div class="media">
					<i-feather name="x"></i-feather>
					<div class="media-content">
						<h3>Hide</h3>
						<small>Hide this comment.</small>
					</div>
				</div>
			</a>
			<div class="dropdown-divider"></div>
			<a href="#" class="dropdown-item">
				<div class="media">
					<i-feather name="flag"></i-feather>
					<div class="media-content">
						<h3>Report</h3>
						<small>Report this comment.</small>
					</div>
				</div>
			</a>
		</div>
	</div>
</div>
