<div class="profile-timeline">
  <ng-container *ngFor="let post of listPost; let index = index">
    <app-post-request-group
      [post]="post"
      [groupId]="groupId"
      (spliceListPostRequest)="spliceListPostRequest($event)"
      (updateListPost)="updateListPost($event)"
    ></app-post-request-group>
  </ng-container>
</div>
