<div
    id="support-settings"
    class="settings-section"
>
    <div class="settings-panel">

        <div class="title-wrap">
            <a class="mobile-sidebar-trigger">
                <i-feather name="menu"></i-feather>
            </a>
            <h2>Assistance</h2>
        </div>

        <div class="settings-form-wrapper">
            <div class="settings-form">
                <div class="columns is-multiline flex-portrait">
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="book-open"></i-feather>
                                </div>
                                <h4>User Guide</h4>
                                <p>Learn more about the app</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="mail"></i-feather>
                                </div>
                                <h4>Message</h4>
                                <p>Contact the support team</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="message-circle"></i-feather>
                                </div>
                                <h4>Chat</h4>
                                <p>Chat with support</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="slash"></i-feather>
                                </div>
                                <h4>Blocked Users</h4>
                                <p>Manage blocked users</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="archive"></i-feather>
                                </div>
                                <h4>Archives</h4>
                                <p>Manage archives</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="flag"></i-feather>
                                </div>
                                <h4>Report</h4>
                                <p>Report offenses</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="award"></i-feather>
                                </div>
                                <h4>Rewards</h4>
                                <p>See your rewards</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="check-circle"></i-feather>
                                </div>
                                <h4>Partners</h4>
                                <p>Partner Programs</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="briefcase"></i-feather>
                                </div>
                                <h4>Sponsors</h4>
                                <p>Sponsor programs</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="illustration">
                <img
                    src="assets/img/illustrations/settings/7.svg"
                    alt
                >
                <p>
                    If you'd like to learn more about support, you can read about it in the
                    <a>user guide</a>
                    .
                </p>
            </div>
        </div>

    </div>
</div>
