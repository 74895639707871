<div class="home-wrapper">
	<a class="mobile-sidebar-trigger">
		<i-feather name="menu"></i-feather>
	</a>
	<!-- Wrapper -->
	<div class="video-header-wrap">
		<!-- Video Slide -->
		<div class="video-header-outer">
			<div class="video-header">
				<!-- Image -->
				<img
					class="cover"
					src="../assets/img/video/bogia.jpg"
					data-demo-src="assets/img/demo/video/home/h3.jpg"
					alt=""
				/>
				<!-- Overlay -->
				<div class="cover-overlay"></div>
				<!-- Caption -->
				<div class="cover-caption">
					<div class="caption-inner">
						<div class="video-caption">
							<div class="caption-block">
								<h2>Forgotten</h2>
								<div class="video-meta">
									<span>CCO</span>
									<span>Horror, Sci-Fi</span>
									<span class="rating">
										<i-feather name="star"></i-feather>
										<i-feather name="star"></i-feather>
										<i-feather name="star"></i-feather>
										<i-feather name="star"></i-feather>
									</span>
								</div>
								<p class="description">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									Zenonis est, inquam, hoc Stoici. Omnis enim est natura
									diligens sui. Utinam quidem dicerent alium alio beatiorem! Iam
									ruinas videres. Duo Reges: constructio interrete. Sed ad bona
									praeterita redeamus.
								</p>
								<div class="caption-actions">
									<a
										href="videos/player"
										class="button is-solid accent-button is-rounded"
									>
										Start Watching
									</a>
									<a class="trailer-button">
										<i-feather name="play"></i-feather>
										<span>Watch Trailer</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Video Slide -->
		<div class="video-header-outer">
			<div class="video-header">
				<!-- Image -->
				<img
					class="cover"
					src="../assets/img/video/starwar.jpg"
					data-demo-src="assets/img/demo/video/home/h1.jpg"
					alt=""
				/>
				<!-- Overlay -->
				<div class="cover-overlay"></div>
				<!-- Caption -->
				<div class="cover-caption">
					<div class="caption-inner">
						<div class="video-caption">
							<div class="caption-block">
								<h2>The Old Caban</h2>
								<div class="video-meta">
									<span>CCO</span>
									<span>Fantasy, Horror</span>
									<span class="rating">
										<i-feather name="star"></i-feather>
										<i-feather name="star"></i-feather>
										<i-feather name="star"></i-feather>
										<i-feather name="star"></i-feather>
										<i-feather name="star"></i-feather>
									</span>
								</div>
								<p class="description">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									Zenonis est, inquam, hoc Stoici. Omnis enim est natura
									diligens sui. Utinam quidem dicerent alium alio beatiorem! Iam
									ruinas videres. Duo Reges: constructio interrete. Sed ad bona
									praeterita redeamus.
								</p>
								<div class="caption-actions">
									<a class="button is-solid accent-button is-rounded">
										Start Watching
									</a>
									<a class="trailer-button">
										<i-feather name="play"></i-feather>
										<span>Watch Trailer</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Video Slide -->
		<div class="video-header-outer">
			<div class="video-header">
				<!-- Image -->
				<img
					class="cover"
					src="../assets/img/video/partymoon.jpg"
					data-demo-src="assets/img/demo/video/home/h2.jpg"
					alt=""
				/>
				<!-- Overlay -->
				<div class="cover-overlay"></div>
				<!-- Caption -->
				<div class="cover-caption">
					<div class="caption-inner">
						<div class="video-caption">
							<div class="caption-block">
								<h2>Eaten Alive</h2>
								<div class="video-meta">
									<span>CCO</span>
									<span>Horror, Thriller</span>
									<span class="rating">
										<i-feather name="star"></i-feather>
										<i-feather name="star"></i-feather>
										<i-feather name="star"></i-feather>
										<i-feather name="star"></i-feather>
										<i-feather name="star"></i-feather>
									</span>
								</div>
								<p class="description">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									Zenonis est, inquam, hoc Stoici. Omnis enim est natura
									diligens sui. Utinam quidem dicerent alium alio beatiorem! Iam
									ruinas videres. Duo Reges: constructio interrete. Sed ad bona
									praeterita redeamus.
								</p>
								<div class="caption-actions">
									<a class="button is-solid accent-button is-rounded">
										Start Watching
									</a>
									<a class="trailer-button">
										<i-feather name="play"></i-feather>
										<span>Watch Trailer</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Home content -->
	<div class="home-content">
		<div class="collections-header">
			<a class="is-active">Latest</a>
			<a>Subscribed</a>
			<a>Related</a>
			<div class="search-button">
				<i-feather name="search"></i-feather>
			</div>
		</div>

		<div class="collections-wrap is-active">
			<div class="collection">
				<div class="header">
					<h4>Shows & Movies</h4>
					<a>Show More</a>
				</div>
				<div class="video-collection">
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">01:39:43</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/partymoon.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c17.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/partymoon.jpg"
								data-demo-src="assets/img/icons/logos/metamovies.svg"
								data-page-popover="2"
								alt=""
							/>
							<div class="info">
								<span>The Old Caban</span>
								<span>Meta Movies</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">01:26:17</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/dogyellow.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c18.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/dogyellow.jpg"
								data-demo-src="assets/img/icons/logos/metamovies.svg"
								data-page-popover="2"
								alt=""
							/>
							<div class="info">
								<span>Eaten Alive</span>
								<span>Meta Movies</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">02:31:54</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/forme.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c19.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/forme.jpg"
								data-demo-src="assets/img/icons/logos/metamovies.svg"
								data-page-popover="2"
								alt=""
							/>
							<div class="info">
								<span>Forgotten</span>
								<span>Meta Movies</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">02:03:17</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/konh.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c20.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/konh.jpg"
								data-demo-src="assets/img/icons/logos/metamovies.svg"
								data-page-popover="2"
								alt=""
							/>
							<div class="info">
								<span>The Wastelands</span>
								<span>Meta Movies</span>
							</div>
						</div>
					</a>
				</div>
			</div>
			<!-- Collection -->
			<div class="collection">
				<div class="header">
					<h4>Gaming</h4>
					<a>Show More</a>
				</div>
				<div class="video-collection">
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">02:13:49</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/vernom.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c1.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/vernom.jpg"
								data-demo-src="assets/img/avatars/brian.jpg"
								data-user-popover="19"
								alt=""
							/>
							<div class="info">
								<span>The best keyboards for 2019!</span>
								<span>Brian Stevenson</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">00:26:17</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/mulan.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c2.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/mulan.jpg"
								data-demo-src="assets/img/avatars/dan.jpg"
								
								alt=""
							/>
							<div class="info">
								<span>Exclusive limited unboxing</span>
								<span>Dan Walker</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">01:12:31</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/fast.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c3.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/fast.jpg"
								data-demo-src="assets/img/avatars/dan.jpg"
								
								alt=""
							/>
							<div class="info">
								<span>I was at the VidCon 2019</span>
								<span>Dan Walker</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">00:12:06</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/tomb.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c4.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/tomb.jpg"
								data-demo-src="assets/img/avatars/david.jpg"
								data-user-popover="5"
								alt=""
							/>
							<div class="info">
								<span>Our plans for Alien Invasion</span>
								<span>David Kim</span>
							</div>
						</div>
					</a>
				</div>
			</div>

			<div class="collection">
				<div class="header">
					<h4>Fashion</h4>
					<a>Show More</a>
				</div>
				<div class="video-collection">
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">00:19:41</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/matbiec.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c5.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/matbiec.jpg"
								data-demo-src="assets/img/avatars/stella.jpg"
								data-user-popover="2"
								alt=""
							/>
							<div class="info">
								<span>5 new trendy outfits</span>
								<span>Stella Bergmann</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">00:10:41</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/malefi.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c6.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/malefi.jpg"
								data-demo-src="assets/img/avatars/milly.jpg"
								data-user-popover="7"
								alt=""
							/>
							<div class="info">
								<span>Make your makeups glossy</span>
								<span>Milly Augustine</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">00:06:12</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/sonic.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c7.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/sonic.jpg"
								data-demo-src="assets/img/avatars/lana.jpeg"
								data-user-popover="10"
								alt=""
							/>
							<div class="info">
								<span>Our latest lipsticks</span>
								<span>Lana Henrikssen</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">00:03:27</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/jumani.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c8.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/jumani.jpg"
								data-demo-src="assets/img/avatars/nelly.png"
								data-user-popover="10"
								alt=""
							/>
							<div class="info">
								<span>Colombia festival is on</span>
								<span>Nelly Schwartz</span>
							</div>
						</div>
					</a>
				</div>
			</div>

			<div class="collection">
				<div class="header">
					<h4>Food</h4>
					<a>Show More</a>
				</div>
				<div class="video-collection">
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">00:01:36</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/30tet.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c9.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/30tet.jpg"
								data-demo-src="assets/img/avatars/mike.jpg"
								data-user-popover="12"
								alt=""
							/>
							<div class="info">
								<span>Tasty home made gummies</span>
								<span>Mike Lasalle</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">00:07:28</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/aquamna.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c10.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/aquamna.jpg"
								data-demo-src="assets/img/avatars/mike.jpg"
								data-user-popover="12"
								alt=""
							/>
							<div class="info">
								<span>Raspberry cupcakes in 5 minutes</span>
								<span>Mike Lasalle</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">00:05:02</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/bumble.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c11.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/bumble.jpg"
								data-demo-src="assets/img/avatars/elise.jpg"
								data-user-popover="6"
								alt=""
							/>
							<div class="info">
								<span>Ways to citrus and dragon</span>
								<span>Elise Walker</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">00:06:12</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/tomb.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c12.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/tomb.jpg"
								data-demo-src="assets/img/avatars/lana.jpeg"
								data-user-popover="10"
								alt=""
							/>
							<div class="info">
								<span>The purple banana</span>
								<span>Lana Henrikssen</span>
							</div>
						</div>
					</a>
				</div>
			</div>

			<div class="collection">
				<div class="header">
					<h4>Automotive</h4>
					<a>Show More</a>
				</div>
				<div class="video-collection">
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">00:18:49</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/thor.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c13.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/thor.jpg"
								data-demo-src="assets/img/avatars/edward.jpeg"
								data-user-popover="5"
								alt=""
							/>
							<div class="info">
								<span>I tried the new Class E</span>
								<span>Edward Mayers</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">00:21:38</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/partymoon.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c14.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/partymoon.jpg"
								data-demo-src="assets/img/avatars/edward.jpeg"
								data-user-popover="5"
								alt=""
							/>
							<div class="info">
								<span>I tried the new Bolt 403</span>
								<span>Edward Mayers</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">00:17:11</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/care.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c15.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/care.jpg"
								data-demo-src="assets/img/avatars/edward.jpeg"
								data-user-popover="5"
								alt=""
							/>
							<div class="info">
								<span>I tried the new Supra RX</span>
								<span>Edward Mayers</span>
							</div>
						</div>
					</a>
					<!-- Episode -->
					<a class="episode">
						<div class="episode-thumbnail">
							<div class="episode-overlay"></div>
							<div class="episode-duration">00:14:38</div>
							<div class="play-button">
								<i-feather name="play-circle"></i-feather>
							</div>
							<img
								src="../assets/img/video/forme.jpg"
								data-demo-src="assets/img/demo/video/home/collections/c16.jpg"
								alt=""
							/>
						</div>
						<div class="episode-meta">
							<img
								src="../assets/img/video/forme.jpg"
								data-demo-src="assets/img/avatars/edward.jpeg"
								data-user-popover="5"
								alt=""
							/>
							<div class="info">
								<span>I tried the new Baratini DX</span>
								<span>Edward Mayers</span>
							</div>
						</div>
					</a>
				</div>
			</div>

			<!-- Load more posts -->
			<div class="load-more-wrap has-text-centered">
				<a href="#" class="load-more-button">Load More</a>
			</div>
		</div>
	</div>
</div>
