<!-- <div class="pageloader"></div>
<div class="infraloader is-active"></div> -->
<div class="signup-wrapper signup-custome">
	<!--Fake navigation-->
	<div class="fake-nav">
		<a href="/" class="logo">
			<img src="assets/img/logo/AZ-blue.png" alt="logo-archzon" />
		</a>
	</div>

	<div class="container">
		<!--Container-->
		<div class="login-container">
			<div class="columns is-vcentered">
				<div class="column is-6 image-column">
					<!--Illustration-->
					<img
						class="login-image"
						src="assets/img/illustrations/login/login.svg"
						alt=""
					/>
				</div>
				<div class="column is-6">
					<h2 class="form-title">Welcome Back</h2>
					<h3 class="form-subtitle">Enter your credentials to sign in.</h3>

					<!--Form-->
					<div class="login-form">
						<form [formGroup]="loginForm">
							<div class="form-panel">
								<div class="field">
									<label>Email</label>
									<div class="control">
										<input
											type="text"
											[(ngModel)]="Email"
											class="input"
											placeholder="Enter your email address"
											formControlName="email"
											[class.is-invalid]="
												primEmail.invalid && primEmail.touched
											"
											autocomplete="off"
										/>
									</div>
								</div>
								<div class="field">
									<label>Password</label>
									<div class="control">
										<input
											type="password"
											[(ngModel)]="Password"
											class="input"
											placeholder="Enter your password"
											formControlName="password"
											[class.is-invalid]="
												primPassword.invalid && primPassword.touched
											"
										/>
									</div>
								</div>
								<div class="field is-flex">
									<div class="switch-block">
										<label class="f-switch">
											<input type="checkbox" class="is-switch" />
											<i></i>
										</label>
										<div class="meta">
											<p>Remember me?</p>
										</div>
									</div>
									<a>Forgot Password?</a>
								</div>
							</div>
							<div class="buttons">
								<a
									class="button is-solid primary-button is-fullwidth raised"
									(click)="doLogin()"
									>Login</a
								>
							</div>

							<div class="account-link has-text-centered">
								<a (click)="goRegister()">Don't have an account? Sign Up</a>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
