import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GroupService } from 'src/app/services/group/group.service';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent implements OnInit {
  isModalConfirms;
  isBlocks;
  isMakeAdmins;
  isRemoveMembers;
  dataMembers;
  dataGroups;
  @Input() set isModalConfirm(isModal: boolean) {
    this.isModalConfirms = isModal;
  }
  @Input() set isBlock(isBlock: boolean) {
    this.isBlocks = isBlock;
  }
  @Input() set isMakeAdmin(isMAkeAdmin: boolean) {
    this.isMakeAdmins = isMAkeAdmin;
  }
  @Input() set isRemoveMember(isRemoveMember: boolean) {
    this.isRemoveMembers = isRemoveMember;
  }

  @Input() set dataMember(dataMember: object) {
    this.dataMembers = dataMember;
  }

  @Input() set dataGroup(dataGroup: object) {
    this.dataGroups = dataGroup;
  }

  @Output() isSubmit = new EventEmitter<any>();
  @Output() isClose = new EventEmitter<any>();

  constructor(private groupService: GroupService) {}

  ngOnInit(): void {}

  //#region FUNCTION managing of admin
  isSubmits;
  blockMember() {
    let reqBlock = {
      id: this.dataMembers._id,
    };
    this.groupService.blockMember(reqBlock).subscribe((result) => {
      if (result.status == 'success') {
        this.isSubmit.emit({ status: 'success' });
        this.isSubmits = true;
        this.isModalConfirms = !this.isModalConfirms;
      }
    });
  }
  makeAdmin() {
    let data = {
      groupId: this.dataGroups._id,
      groupAdmin: this.dataMembers.memberId._id,
      isAdmin: true,
      isCensor: true,
    };
    this.groupService.createAdmin(data).subscribe((result) => {
      if (result.status == 'success') {
        this.isSubmit.emit({ status: 'success' });
        this.isSubmits = true;
        this.isModalConfirms = !this.isModalConfirms;
      }
    });
  }
  removeMember() {
    this.groupService.deleteMember(this.dataMembers._id).subscribe((result) => {
      if (result.status == 'success') {
        if (this.dataMembers.isAdmin) {
          let data = {
            groupAdmin: this.dataMembers.memberId._id,
            groupId: this.dataGroups._id,
          };
          this.groupService.deleteAdmin(data).subscribe((result) => {
            console.log('success');
          });
        }
        this.isSubmit.emit({ status: 'success' });
        this.isSubmits = true;
        this.isModalConfirms = !this.isModalConfirms;
      }
    });
  }
  //#endregion

  //#region CLOSE modal
  closeModal() {
    this.isClose.emit({
      isClose: true,
    });
    this.isBlock = false;
    this.isMakeAdmin = false;
    this.isRemoveMember = false;
    this.isModalConfirm = false;
  }
  //#endregion
}
