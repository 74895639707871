<div class="inner-wrapper inner-str-wrap">
	<div class="stories-feed">
		<div class="stories-feed-wrap">
			<ng-container *ngFor="let itemStr of arrStories">
				<div
					class="feed-wrap feed-wrap-left feed-wrap-btn"
					*ngIf="itemStr?.isActive"
				>
					<span
						*ngIf="index > 0"
						class="btn-arrow btn-arrow-left"
						(click)="openLeft(itemStr?._id)"
						><i-feather name="chevron-left"></i-feather
					></span>
				</div>
				<div class="feed-wrap feed-wrap-img" *ngIf="itemStr?.isActive">
					<div class="feed-feed-item">
						<ng-container *ngIf="itemStr?.postImg">
							<ng-container *ngFor="let story of itemStr?.postImg">
								<div class="upload-item-progress">
									<div
										class="progress active"
										role="progressbar"
										aria-valuemin="0"
										aria-valuemax="100"
										aria-valuenow="0"
									>
										<div
											id="progress-bar-id"
											class="progress-bar progress-bar-success"
											data-dz-uploadprogress=""
										></div>
									</div>
								</div>
								<div class="feed-item-image">
									<img
										*ngIf="story && !story.type"
										class="featured-image"
										[src]="story?.url"
										[attr.data-demo-src]="story?.url"
										[alt]="story?.url"
									/>
									<video
										*ngIf="story && story.type"
										class="featured-image"
										[src]="story?.url"
										autoplay
									></video>
								</div>
							</ng-container>
						</ng-container>
					</div>
				</div>
				<div
					class="feed-wrap feed-wrap-right feed-wrap-btn"
					*ngIf="itemStr?.isActive"
				>
					<span
						*ngIf="index < arrStories?.length - 1"
						class="btn-arrow btn-arrow-right"
						(click)="openRight(itemStr?._id)"
						><i-feather name="chevron-right"></i-feather
					></span>
				</div>
			</ng-container>
		</div>
	</div>
</div>
