<div class="app-overlay close-publish"></div>
<div
	id="main-navbar"
	class="navbar is-inline-flex is-transparent no-shadow is-hidden-mobile"
>
	<div class="container is-fluid">
		<div class="navbar-brand">
			<a class="navbar-item" href="/">
				<img src="assets/img/logo/AZ-blue.png" alt="logo" />
			</a>
		</div>
		<div class="navbar-menu">
			<div class="navbar-start">
				<!-- Navbar Search -->
				<app-friend-requests-dropdown
					*ngIf="checkExistUser"
					class="navbar-item is-icon drop-trigger"
				></app-friend-requests-dropdown>
				<app-notification-dropdown
					*ngIf="checkExistUser"
					class="navbar-item is-icon drop-trigger"
				></app-notification-dropdown>
				<app-messages-dropdown
					*ngIf="checkExistUser"
					class="navbar-item is-icon drop-trigger"
				></app-messages-dropdown>
				<div class="navbar-item is-icon open-chat" (click)="openChat()">
					<a class="icon-link is-primary">
						<i-feather name="message-square"></i-feather>
						<span class="indicator"></span>
					</a>
				</div>
				<div id="explorer-trigger" class="navbar-item is-icon">
					<a class="icon-link is-primary">
						<i class="mdi mdi-apps"></i>
					</a>
				</div>
			</div>
			<div class="navbar-end">
				<app-search-widget></app-search-widget>
				<app-account-dropdown *ngIf="checkExistUser"></app-account-dropdown>
				<div class="navbar-item is-plus-menu is-hidden">
					<a
						id="plus-menu"
						class="button action-button is-solid primary-button raised"
					>
						<i-feather name="plus"></i-feather>
					</a>
					<div class="plus-drop">
						<div class="drop-content">
							<a>
								<i-feather name="book"></i-feather>
								<div class="meta">
									<span>Guides</span>
									<span>Learn everything fast</span>
								</div>
							</a>
							<a>
								<i-feather name="help-circle"></i-feather>
								<div class="meta">
									<span>FAQ</span>
									<span>Most asked questions</span>
								</div>
							</a>
							<a>
								<i-feather name="life-buoy"></i-feather>
								<div class="meta">
									<span>Assistance</span>
									<span>Get in touch with support</span>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<nav
	class="navbar mobile-navbar is-hidden-desktop no-shadow"
	aria-label="main navigation"
>
	<div class="navbar-brand">
		<a class="navbar-item" [routerLink]="['/']">
			<img src="assets/img/logo/AZ-char.png" alt="logo" />
		</a>
		<app-friend-requests-dropdown
			*ngIf="checkExistUser"
			class="navbar-item is-icon drop-trigger"
		></app-friend-requests-dropdown>
		<app-notification-dropdown
			*ngIf="checkExistUser"
			class="navbar-item is-icon drop-trigger"
		></app-notification-dropdown>
		<app-messages-dropdown
			*ngIf="checkExistUser"
			class="navbar-item is-icon drop-trigger"
		></app-messages-dropdown>
		<div class="navbar-item is-icon open-chat" (click)="openChat()">
			<a class="icon-link is-primary">
				<i-feather name="message-square"></i-feather>
				<span class="indicator"></span>
			</a>
		</div>
		<div id="mobile-explorer-trigger" class="navbar-item is-icon">
			<a class="icon-link is-primary">
				<i class="mdi mdi-apps"></i>
			</a>
		</div>
		<!-- Mobile menu toggler icon -->
		<div class="navbar-burger">
			<span></span>
			<span></span>
			<span></span>
		</div>
	</div>
	<!-- Navbar mobile menu -->
	<div class="navbar-menu" *ngIf="infoUser">
		<!-- Account -->
		<div class="navbar-item has-dropdown is-active">
			<div class="navbar-link">
				<img
					[src]="infoUser?.avatar"
					data-demo-src="assets/img/avatars/jenna.png"
					alt=""
				/>
				<span class="is-heading">
					{{ infoUser.firstname }} {{ infoUser.lastname }}
				</span>
			</div>
			<div class="navbar-dropdown">
				<a class="navbar-item is-flex is-mobile-icon" (click)="goProfile()">
					<span>
						<i-feather name="user"></i-feather>
						<p>Profile</p>
					</span>
				</a>
				<a class="navbar-item is-flex is-mobile-icon">
					<span>
						<i-feather name="file-plus"></i-feather>
						<p>Create Page</p>
					</span>
				</a>
				<a class="navbar-item is-flex is-mobile-icon">
					<span>
						<i-feather name="users"></i-feather>
						<p>Create Group</p>
					</span>
				</a>
			</div>
		</div>
		<div class="navbar-item has-dropdown">
			<hr _ngcontent-sau-c53="" class="account-divider" />
			<div class="navbar-dropdown">
				<a (click)="goSettings()" class="navbar-item">Settings</a>
				<a class="navbar-item">Help</a>
				<a (click)="logout()" class="navbar-item">Log out</a>
			</div>
		</div>
	</div>
</nav>
<nav
	class="navbar mobile-navbar-bottom is-hidden-desktop no-shadow"
	aria-label="main navigation"
>
	<div class="navbar-brand">
		<div
			class="is-icon"
			routerLink="/"
			[ngClass]="{'icon-active': urlRoute == '/'}"
		>
			<a class="icon-link is-primary">
				<i class="mdi mdi-home"></i>
			</a>
		</div>
		<div
			class="is-icon"
			routerLink="/friend-requests"
			[ngClass]="{'icon-active': urlRoute == '/friend-requests'}"
		>
			<a class="icon-link is-primary">
				<i class="mdi mdi-account-multiple"></i>
			</a>
		</div>
		<div
			class="is-icon"
			routerLink="/watch"
			[ngClass]="{'icon-active': urlRoute == '/watch'}"
		>
			<a class="icon-link is-primary">
				<i class="mdi mdi-play-box-outline"></i>
			</a>
		</div>
		<div
			class="is-icon"
			(click)="goMyPage(dataMyPage)"
			[ngClass]="{'icon-active': urlRoute == '/pages/' + dataMyPage?._id}"
		>
			<a class="icon-link is-primary">
				<i class="mdi mdi-note"></i>
			</a>
		</div>
	</div>
</nav>
