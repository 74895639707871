import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { checkToken, ProfileService } from '../../services/profile.service';
import { Router } from '@angular/router';
import { PagesService } from '../../services/pages.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  constructor(private router: Router, private profile: ProfileService, private pagesService: PagesService) { }
  @Output() openBtnChat = new EventEmitter<any>();
  @Output() dataUser = new EventEmitter<any>();
  checkExistUser;
  infoUser;
  urlRoute
  ngOnInit(): void {
    this.urlRoute = this.router.url;
    this.checkExistUser = checkToken();
    if (this.checkExistUser == true) {
      this.getInfoUser();
    }
  }

  goToMainPage() {
    this.router.navigateByUrl('/');
  }

  goMyPage(dataPage) {
    localStorage.setItem('id', dataPage._id);
    // window.location.replace(`/pages/${dataPage._id}`);
    this.router.navigate(['pages', dataPage._id]);
  }

  goProfile() {
    this.router.navigate(['/profile', this.infoUser._id]);
  }

  dataMyPage
  getPageByUserId(userId) {
    this.pagesService.getPageByUserId(userId).subscribe((result) => {
      if (result.status == 'success') {
        for (let page of result.data) {
          if (page.isAttached) {
            this.dataMyPage = page;
            break;
          }
        }
      }
    });
  }

  openChat() {
    this.router.navigateByUrl("/chat")
  }

  getInfoUser() {
    this.profile.getProfile().subscribe((result) => {
      this.infoUser = result.data;
      if (result.status == 'success') {
        this.getPageByUserId(result.data._id);
        this.dataUser.emit({ data: result.data });
      }
    });
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  goSettings() {
    this.router.navigate(['/settings']);
  }
}
