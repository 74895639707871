import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class VideoService {
  constructor(private http: HttpClient) { }

  get(parameters?): Observable<any> {
    let params = new HttpParams();
    if (!isUndefined(parameters)) {
      params = isUndefined(parameters.skip)
        ? params
        : params.append("skip", parameters.skip);
      params = isUndefined(parameters.limit)
        ? params
        : params.append("limit", parameters.limit);
    }
    return this.http.get("link/video", {
      params,
    });
  }

  // video
  create(data): Observable<any> {
    return this.http.post("link/video/create", data);
  }
  update(id, data) {
    return this.http.post(`link/video/${id}`, data);
  }
  delete(id): Observable<any> {
    return this.http.delete(`link/video/create/${id}`);
  }

  // video-like
  getLikeByArrVideo(arrVideo): Observable<any> {
    return this.http.post("link/video-like/getByArrVideo", arrVideo);
  }
  createLike(data): Observable<any> {
    return this.http.post("link/video-like/create", data);
  }
  deleteLike(videoId): Observable<any> {
    return this.http.delete(`link/video-like/delete/${videoId}`);
  }

  //video-comment
  getCommentsByArrayVideo(arrVideo): Observable<any> {
    return this.http.post("link/video-comment/getByArrVideo", arrVideo);
  }
  createComment(data): Observable<any> {
    return this.http.post("link/video-comment/create", data);
  }
  updateComment(id, data): Observable<any> {
    return this.http.post(`link/video-comment/${id}`, data);
  }
  deleteComment(id): Observable<any> {
    return this.http.delete(`link/video-comment/delete/${id}`);
  }

  //video-reply
  getRepliesByArrVideo(arrVideo): Observable<any> {
    return this.http.post(`link/video-reply/getByArrVideo`, arrVideo);
  }
  createReply(data): Observable<any> {
    return this.http.post(`link/video-reply/create`, data);
  }
  deleteReply(id): Observable<any> {
    return this.http.delete(`link/video-reply/delete/${id}`)
  }

  //share
  createShareVideo(data): Observable<any> {
    return this.http.post(`link/`, data)
  }

  //comment-like
  createCommentLike(data): Observable<any> {
    return this.http.post(`link/video-comment-like/create`, data)
  }
  deleteCommentLike(commentId): Observable<any> {
    return this.http.delete(`link/video-comment-like/delete/${commentId}`)
  }
  getCommentLikeByArrVideos(arr): Observable<any> {
    return this.http.post(`link/video-comment-like/getbyArrVideo`, arr)
  }

  //reply-like
  createReplyLike(data): Observable<any> {
    return this.http.post(`link/video-reply-like/create`, data)
  }
  deleteReplyLike(replyId): Observable<any> {
    return this.http.delete(`link/video-reply-like/delete/${replyId}`)
  }
  getReplyLikeByArrVideos(arr): Observable<any> {
    return this.http.post(`link/video-reply-like/getbyArrVideo`, arr)
  }

  getVideoByPage(): Observable<any> {
    return this.http.get('page-post/getPostVideo')
  }
}

function isUndefined(value) {
  if (value) {
    return false;
  } else {
    return true;
  }
}
