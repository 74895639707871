<!-- Question wrap -->
<div class="questions-wrap is-smaller">
	<!-- Container -->
	<div class="container">
		<div class="question-content">
			<div class="columns true-dom">
				<div class="column">
					<!-- Question content -->
					<div class="question-content">
						<!--Top Bar-->
						<div class="top-single-header has-text-right">
							<a class="button is-solid grey-button is-rounded"
								>Back To Questions</a
							>
						</div>
						<!-- Main question -->
						<div class="question-block">
							<!-- Title -->
							<h2 class="question-title">
								How can I handle photos with javascript?
							</h2>
							<!-- Author -->
							<div class="question-author">
								<img
									src="https://via.placeholder.com/150x150"
									data-demo-src="assets/img/avatars/dan.jpg"
									
									alt=""
								/>
								<div class="meta">
									<span>Dan Walker</span>
									<span>2 days ago</span>
								</div>
							</div>
							<!-- Content -->
							<div class="question-text">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. At
									eum nihili facit; Quacumque enim ingredimur, in aliqua
									historia vestigium ponimus. Duo Reges: constructio interrete.
									Proclivi currit oratio. Quae contraria sunt his, malane? Quod
									quidem nobis non saepe contingit. Hoc loco tenere se Triarius
									non potuit.
								</p>
							</div>
							<!-- Footer -->
							<div class="question-footer">
								<div class="likes">
									<div class="like-icon">
										<i-feather name="heart"></i-feather>
									</div>
									<span>8 Likes</span>
								</div>
								<div class="report">
									<div class="report-icon">
										<i-feather name="alert-octagon"></i-feather>
									</div>
									<span>Report Topic</span>
								</div>
							</div>
							<div class="answers-count">
								<span>5 Answers</span>
							</div>
						</div>
						<!-- Main answer -->
						<div class="answer-block is-best">
							<!-- Author -->
							<div class="answer-author">
								<div class="avatar-wrap">
									<img
										class="avatar"
										src="https://via.placeholder.com/150x150"
										data-demo-src="assets/img/avatars/elise.jpg"
										data-user-popover="6"
										alt=""
									/>
									<div class="badge">
										<i-feather name="check"></i-feather>
									</div>
								</div>
								<div class="meta">
									<span
										>Elise Walker
										<small class="best-tag">Best Answer</small></span
									>
									<span>1 day ago</span>
								</div>
							</div>
							<!-- Content -->
							<div class="answer-text">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. At
									eum nihili facit; Quacumque enim ingredimur, in aliqua
									historia vestigium ponimus. Duo Reges: constructio interrete.
									Proclivi currit oratio.
								</p>
								<p>First install this:</p>
								<p class="code">npm install photo_module_js - - save</p>
								<p>Then configure your photos:</p>
								<p class="code">
									$var product = this.getProduct.productDetails&#59; <br />$var
									photoUrl = product.mainInmage.photoUrl&#59; <br />for &#40;
									var i = 0&#59; i &lt; photoUrl.length&#59; i++&#41; &#123;
									//do your stuff here &#123;
								</p>
								<p>You can also check the documentation:</p>
								<p>
									<a class="standard-link"
										>https://friendkit/guides/ecommerce/products/docs</a
									>
								</p>
							</div>
							<!-- Footer -->
							<div class="answer-footer">
								<div class="upvote">
									<div class="upvote-icon">
										<i-feather name="check"></i-feather>
									</div>
									<span>Upvoted 56 times</span>
								</div>
								<div class="report">
									<div class="report-icon">
										<i-feather name="alert-octagon"></i-feather>
									</div>
									<span>Report Answer</span>
								</div>
							</div>
						</div>
						<!-- Other answer -->
						<div class="answer-block">
							<!-- Author -->
							<div class="answer-author">
								<div class="avatar-wrap">
									<img
										class="avatar"
										src="https://via.placeholder.com/150x150"
										data-demo-src="assets/img/avatars/david.jpg"
										data-user-popover="4"
										alt=""
									/>
									<div class="badge">
										<i-feather name="check"></i-feather>
									</div>
								</div>
								<div class="meta">
									<span>David Kim</span>
									<span>1 day ago</span>
								</div>
							</div>
							<!-- Content -->
							<div class="answer-text">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. At
									eum nihili facit; Quacumque enim ingredimur, in aliqua
									historia vestigium ponimus. Duo Reges: constructio interrete.
									Proclivi currit oratio.
								</p>
								<p>First install this:</p>
								<p class="code">npm install photo_module_js - - save</p>
								<p>Check this link:</p>
								<p>
									<a class="standard-link"
										>https://friendkit/questions/1856456</a
									>
								</p>
							</div>
							<!-- Footer -->
							<div class="answer-footer">
								<div class="upvote">
									<div class="upvote-icon">
										<i-feather name="check"></i-feather>
									</div>
									<span>Upvoted 5 times</span>
								</div>
								<div class="report">
									<div class="report-icon">
										<i-feather name="alert-octagon"></i-feather>
									</div>
									<span>Report Answer</span>
								</div>
							</div>
						</div>
						<!-- Other answer -->
						<div class="answer-block">
							<!-- Author -->
							<div class="answer-author">
								<div class="avatar-wrap">
									<img
										class="avatar"
										src="https://via.placeholder.com/150x150"
										data-demo-src="assets/img/avatars/elise.jpg"
										data-user-popover="6"
										alt=""
									/>
									<div class="badge">
										<i-feather name="check"></i-feather>
									</div>
								</div>
								<div class="meta">
									<span>Elise Walker</span>
									<span>1 day ago</span>
								</div>
							</div>
							<!-- Content -->
							<div class="answer-text">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. At
									eum nihili facit; Quacumque enim ingredimur, in aliqua
									historia vestigium ponimus. Duo Reges: constructio interrete.
									Proclivi currit oratio.
								</p>
								<p>It would ne nice to be more specific about it.</p>
								<p>Any suggestions?</p>
							</div>
							<!-- Footer -->
							<div class="answer-footer">
								<div class="upvote">
									<div class="upvote-icon">
										<i-feather name="check"></i-feather>
									</div>
									<span>Upvoted once</span>
								</div>
								<div class="report">
									<div class="report-icon">
										<i-feather name="alert-octagon"></i-feather>
									</div>
									<span>Report Answer</span>
								</div>
							</div>
						</div>
						<!-- Load more answers -->
						<div class="load-more-wrap has-text-centered">
							<a href="#" class="load-more-button">Load More</a>
						</div>
					</div>

					<!-- Other random questions -->
					<div class="other-questions">
						<!-- Title -->
						<h2 class="other-title">Other people asked</h2>
						<!-- Other question -->
						<div class="other-question">
							<div class="avatar-wrap">
								<img
									class="avatar"
									src="https://via.placeholder.com/150x150"
									data-demo-src="assets/img/avatars/david.jpg"
									data-user-popover="4"
									alt=""
								/>
								<div class="badge">
									<i-feather name="check"></i-feather>
								</div>
							</div>
							<div class="meta">
								<a>I'd like to learn a js framework, any ideas?</a>
								<span>David Kim | 1 day ago</span>
							</div>
						</div>
						<!-- Other question -->
						<div class="other-question">
							<div class="avatar-wrap">
								<img
									class="avatar"
									src="https://via.placeholder.com/150x150"
									data-demo-src="assets/img/avatars/elise.jpg"
									data-user-popover="6"
									alt=""
								/>
								<div class="badge">
									<i-feather name="check"></i-feather>
								</div>
							</div>
							<div class="meta">
								<a>Trouble running with Kubernetes + Docker</a>
								<span>Elise Walker | 3 days ago</span>
							</div>
						</div>
						<!-- Other question -->
						<div class="other-question">
							<div class="avatar-wrap">
								<img
									class="avatar"
									src="https://via.placeholder.com/150x150"
									data-demo-src="assets/img/avatars/lana.jpeg"
									data-user-popover="10"
									alt=""
								/>
								<div class="badge">
									<i-feather name="check"></i-feather>
								</div>
							</div>
							<div class="meta">
								<a>Is it worth buying the new Surface tablet?</a>
								<span>Lana Henrikssen | 7 days ago</span>
							</div>
						</div>
						<!-- Other question -->
						<div class="other-question">
							<div class="avatar-wrap">
								<img
									class="avatar"
									src="https://via.placeholder.com/150x150"
									data-demo-src="assets/img/avatars/edward.jpeg"
									data-user-popover="5"
									alt=""
								/>
								<div class="badge">
									<i-feather name="check"></i-feather>
								</div>
							</div>
							<div class="meta">
								<a>How much should I charge for freelance design?</a>
								<span>Edward Mayers | a month ago</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
