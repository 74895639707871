<div id="adminpage-settings" class="settings-section">
  <div class="group-infomation">
    <div class="settings-panel settings-info">
      <div class="title-wrap">
        <a class="mobile-sidebar-trigger">
          <i-feather name="menu"></i-feather>
        </a>
        <h2>Setting page for Admin</h2>
      </div>
      <div class="settings-form-wrapper">
        <div class="settings-form">
          <div class="columns is-multiline">
            <div class="column is-8">
              <div class="page-title">
                Attach Page <span>(*only one page)</span> :
              </div>
              <div class="separator"></div>
              <ng-container *ngFor="let page of dataPages; let index = index">
                <div class="gr-page">
                  <div class="page-avatar">
                    <img [src]=" page?.pageAvatar" />
                  </div>
                  <div class="page-cont">
                    <div class="page-cont-wrap">
                      <div class="cont-wrap-title">
                        <span>
                          {{ page?.pageName }}
                        </span>
                      </div>
                      <div class="cont-wrap-des">
                        <span>
                          created at {{ page?.createdAt | date: "MMM, yyyy" }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="round-checkbox is-green"
                      [ngClass]="
                        page?.isDisabled == true ? 'is-disabled-wrap' : ''
                      "
                    >
                      <div>
                        <input
                          type="checkbox"
                          [attr.id]="'checkbox-group-' + index"
                          [checked]="page?.isAttached == true"
                          (change)="attachedPage(page, index)"
                          [disabled]="page?.isDisabled"
                        />
                        <label [attr.for]="'checkbox-group-' + index"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- Field -->
            </div>
          </div>
        </div>
        <div class="illustration">
          <img src="assets/img/illustrations/settings/1.svg" alt />
          <p>
            If you'd like to learn more about general settings, you can read
            about it in the
            <a>user guide</a>
            .
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
