<app-nav-bar></app-nav-bar>
<div class="stories-wrapper">
  <app-stories-sidebar
    *ngIf="infoUser && arrStories"
    [infoUser]="infoUser"
    [arrStory]="arrStories"
    (openStorySide)="receviceOpen($event)"
  ></app-stories-sidebar>
  <app-stories-content
    *ngIf="infoUser && arrStories"
    [infoUser]="infoUser"
    [arrStory]="arrStories"
    (btnLeft)="receviceOpen($event)"
    (btnRight)="receviceOpen($event)"
  ></app-stories-content>
</div>
<app-new-story-modal [infoUser]="infoUser"></app-new-story-modal>
<app-video-story-modal [infoUser]="infoUser"></app-video-story-modal>
<app-image-story-modal [infoUser]="infoUser"></app-image-story-modal>
