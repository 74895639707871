import { Component, OnInit } from '@angular/core';
import { GroupService } from 'src/app/services/group/group.service';
import { checkToken } from '../../services/profile.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-feed',
  templateUrl: './new-feed.component.html',
  styleUrls: ['./new-feed.component.scss'],
})
export class NewFeedComponent implements OnInit {
  reloadValue;
  checkExistUser;
  post: any;
  checkCloseChat;
  dataUser: any;
  constructor(
    private groupService: GroupService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.reloadValue = true;
    this.checkExistUser = checkToken();
    if (this.checkExistUser) {
      this.getGroupAttached();
    }
  }
  reloadPosts() {
    this.reloadValue = !this.reloadValue;
  }
  getPost(event) {
    this.post = event;
  }

  gotoGroup() {
    //  window.location.href = 'groups/feed/';
    this.router.navigateByUrl('groups/feed/');
  }

  gotoWatch() {
    this.router.navigateByUrl('/watch');
  }

  getUser(event) {
    this.dataUser = event.data;
  }

  //#region FUNCTION Get group attached
  arrGroupAttached = [];
  getGroupAttached() {
    this.groupService.getGroupAttached().subscribe((result) => {
      if (result.status == 'success') {
        this.arrGroupAttached = result.data;
      }
    });
  }
  //#endregion
}
