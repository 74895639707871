<div class="card">
  <div class="card-heading is-bordered">
    <h4>Suggested Friends</h4>
    <div class="dropdown is-spaced is-right dropdown-trigger">
      <div>
        <div class="button">
          <i-feather name="more-vertical"></i-feather>
        </div>
      </div>
      <div class="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <a href="#" class="dropdown-item">
            <div class="media">
              <i-feather name="users"></i-feather>
              <div class="media-content">
                <h3>All Suggestions</h3>
                <small>View all friend suggestions.</small>
              </div>
            </div>
          </a>
          <a class="dropdown-item">
            <div class="media">
              <i-feather name="settings"></i-feather>
              <div class="media-content">
                <h3>Settings</h3>
                <small>Access widget settings.</small>
              </div>
            </div>
          </a>
          <hr class="dropdown-divider" />
          <a href="#" class="dropdown-item">
            <div class="media">
              <i-feather name="trash-2"></i-feather>
              <div class="media-content">
                <h3>Remove</h3>
                <small>Removes this widget from your feed.</small>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body no-padding">
    <!-- Suggested friend -->
    <div class="add-friend-block transition-block">
      <img
        src="https://via.placeholder.com/300x300"
        data-demo-src="assets/img/avatars/nelly.png"
      
        alt
      />
      <div class="page-meta">
        <span>Nelly Schwartz</span>
        <span>Melbourne</span>
      </div>
      <div
        class="add-friend add-transition"
        *ngIf="!checkAddFriend"
        (click)="testToast()"
      >
        <i-feather name="user-plus"></i-feather>
      </div>
      <div class="checkmark-wrapper" *ngIf="checkAddFriend">
        <svg
          class="checkmark is-xs"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            class="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          ></circle>
          <path
            class="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</div>
