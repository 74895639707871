import { Component, Input, OnInit } from '@angular/core';
import * as environment from '../../../../../environments/environment';

@Component({
  selector: 'app-new-story-modal',
  templateUrl: './new-story-modal.component.html',
  styleUrls: ['./new-story-modal.component.scss'],
})
export class NewStoryModalComponent implements OnInit {
  infoUsers;
  @Input() set infoUser(infoUser: any) {
    this.infoUsers = infoUser;
  }
  environment;
  constructor() {
    this.environment = environment.environment.apiUrl;
  }

  ngOnInit(): void {}
}
