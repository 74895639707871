<div
  id="change-profile-pic-modal"
  class="modal change-profile-pic-modal is-medium has-light-bg"
>
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="card">
      <div class="card-heading">
        <h3>Update Profile Picture</h3>
        <!-- Close X button -->
        <div class="close-wrap">
          <span class="close-modal">
            <i-feather name="x"></i-feather>
          </span>
        </div>
      </div>
      <div class="card-body">
        <!-- Placeholder -->
        <div class="selection-placeholder">
          <div class="columns">
            <div class="column is-6">
              <!-- Selection box -->
              <div
                class="selection-box modal-trigger"
                data-modal="upload-crop-profile-modal"
                (click)="loadModal()"
              >
                <div class="box-content">
                  <img
                    src="assets/img/illustrations/profile/change-profile.svg"
                    alt
                  />
                  <div class="box-text">
                    <span>Upload</span>
                    <span>From your computer</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <!-- Selection box -->
              <div
                class="selection-box modal-trigger"
                data-modal="user-photos-modal"
                (click)="loadModal()"
              >
                <div class="box-content">
                  <img
                    src="assets/img/illustrations/profile/upload-profile.svg"
                    alt
                  />
                  <div class="box-text">
                    <span>Choose</span>
                    <span>From your photos</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
