import { Component, Input, OnInit } from '@angular/core';
declare var jQuery: any;
declare function initModals(): any;

@Component({
  selector: 'app-page-change-profile-pic-modal',
  templateUrl: './page-change-profile-pic-modal.component.html',
  styleUrls: ['./page-change-profile-pic-modal.component.scss'],
})
export class PageChangeProfilePicModalComponent implements OnInit {
  @Input() set isActiveAvatar(isActiveAvatar: boolean) {}
  constructor() {}

  ngOnInit(): void {
    this.loadModal();
  }
  loadModal() {
    initModals();
    //   (function ($) {
    //   $(document).ready(function () {
    //     initModals();
    //     function initModals() {
    //       if ($('.modal-trigger').length) {
    //         $('.modal-trigger').on('click', function () {
    //           var modalID = $(this).attr('data-modal');
    //           $('#' + modalID).toggleClass('is-active');
    //         });

    //         $('.modal-close, .close-modal').on('click', function () {
    //           $('.modal.is-active').removeClass('is-active');
    //         });
    //       }
    //     }
    //   });
    // })(jQuery);
  }
}
