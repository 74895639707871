import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomPreloadingStrategy } from './CustomPreloadingStrategy';
const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/feed-layout/feed-layout.module').then(
        (m) => m.FeedLayoutModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingStrategy })],
  exports: [RouterModule],
  providers: [CustomPreloadingStrategy],
})
export class AppRoutingModule {}
