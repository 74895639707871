<div class="questions-wrap is-smaller">
	<div class="container">
		<div class="columns true-dom">
			<div class="column is-3">
				<ul class="questions-menu questions-menu-fixed">
					<li>
						<a href="questions/home">
							<i-feather name="home"></i-feather>
							<span>Home</span>
						</a>
					</li>
					<li>
						<a href="questions/single">
							<i-feather name="message-circle"></i-feather>
							<span>My Questions</span>
						</a>
					</li>
					<li class="is-active">
						<a href="questions/stats">
							<i-feather name="hexagon"></i-feather>
							<span>My Stats</span>
						</a>
					</li>
					<li>
						<a href="questions/categories">
							<i-feather name="layers"></i-feather>
							<span>Categories</span>
						</a>
					</li>
					<li>
						<a href="questions/settings">
							<i-feather name="settings"></i-feather>
							<span>Settings</span>
						</a>
					</li>
				</ul>

				<ul class="questions-menu">
					<li>
						<a href="questions/home">
							<i-feather name="home"></i-feather>
							<span>Home</span>
						</a>
					</li>
					<li>
						<a href="questions/single">
							<i-feather name="message-circle"></i-feather>
							<span>My Questions</span>
						</a>
					</li>
					<li class="is-active">
						<a href="questions/stats">
							<i-feather name="hexagon"></i-feather>
							<span>My Stats</span>
						</a>
					</li>
					<li>
						<a href="questions/categories">
							<i-feather name="layers"></i-feather>
							<span>Categories</span>
						</a>
					</li>
					<li>
						<a href="questions/settings">
							<i-feather name="settings"></i-feather>
							<span>Settings</span>
						</a>
					</li>
				</ul>
			</div>
			<div class="column is-6">
				<!-- user stats -->
				<div class="stats-wrapper">
					<div class="stats-header">
						<div class="avatar-wrapper">
							<img
								class="avatar"
								src="https://via.placeholder.com/150x150"
								data-demo-src="assets/img/avatars/jenna.png"
								data-user-popover="0"
								alt=""
							/>
							<div class="badge">
								<i-feather name="check"></i-feather>
							</div>
						</div>
						<div class="user-info">
							<h4>Jenna Davis</h4>
							<p>From Melbourne</p>
							<a class="button is-follow">Follow</a>
						</div>
						<div class="main-stats">
							<div class="stat-block">
								<h4>Questions</h4>
								<p>1337</p>
							</div>
							<div class="stat-block is-centered">
								<h4>Followers</h4>
								<p>618</p>
							</div>
							<div class="stat-block">
								<h4>Following</h4>
								<p>72</p>
							</div>
						</div>
					</div>

					<div class="achievements">
						<div class="header">
							<h3>Achievements</h3>
						</div>

						<div class="achievements-carousel-wrap">
							<div class="achievements-loader is-active">
								<div class="loader is-loading"></div>
							</div>

							<div class="achievements-carousel">
								<div class="achievement has-text-centered">
									<img src="assets/img/icons/questions/upvoted.svg" alt="" />
									<h3>Upvoted</h3>
									<p>A reward for community leaders.</p>
								</div>
								<div class="achievement has-text-centered">
									<img src="assets/img/icons/questions/accurate.svg" alt="" />
									<h3>Accurate</h3>
									<p>A very high rate of best answers.</p>
								</div>
								<div class="achievement has-text-centered">
									<img src="assets/img/icons/questions/gunslinger.svg" alt="" />
									<h3>Gun Slinger</h3>
									<p>Answers people fast as hell!</p>
								</div>
								<div class="achievement has-text-centered">
									<img src="assets/img/icons/questions/rookie.svg" alt="" />
									<h3>Rookie</h3>
									<p>Posted at least 50 questions</p>
								</div>
								<div class="achievement has-text-centered">
									<img
										src="assets/img/icons/questions/contributor.svg"
										alt=""
									/>
									<h3>Contributor</h3>
									<p>Posted at least 250 questions</p>
								</div>
							</div>
						</div>
					</div>

					<div class="quick-activity">
						<div class="header">
							<h3>Activity</h3>
						</div>

						<div class="activity-list">
							<div class="activity-item is-best">
								<div class="avatar-wrap">
									<img
										class="avatar"
										src="https://via.placeholder.com/150x150"
										data-demo-src="assets/img/avatars/elise.jpg"
										data-user-popover="6"
										alt=""
									/>
									<div class="badge">
										<i-feather name="check"></i-feather>
									</div>
								</div>
								<div class="meta">
									<span>Elise Walker</span>
									<span
										>Just asked
										<a
											>Is there a way to quickly convert a project from Angular
											to React?</a
										></span
									>
									<small>42 minutes ago</small>
								</div>
							</div>

							<div class="activity-item">
								<div class="avatar-wrap">
									<img
										class="avatar"
										src="https://via.placeholder.com/150x150"
										data-demo-src="assets/img/avatars/dan.jpg"
										
										alt=""
									/>
									<div class="badge">
										<i-feather name="check"></i-feather>
									</div>
								</div>
								<div class="meta">
									<span>Dan Walker</span>
									<span
										>Marked <a>your answer</a> as the best answer to his
										question.</span
									>
									<small>1 hour ago</small>
								</div>
							</div>

							<div class="activity-item">
								<div class="avatar-wrap">
									<img
										class="avatar"
										src="https://via.placeholder.com/150x150"
										data-demo-src="assets/img/avatars/daniel.jpg"
										data-user-popover="3"
										alt=""
									/>
									<div class="badge">
										<i-feather name="check"></i-feather>
									</div>
								</div>
								<div class="meta">
									<span>Daniel Wellington</span>
									<span
										>Replied to
										<a
											>Is there a cheatsheet listing all available browser
											polyfills?</a
										></span
									>
									<small>3 hours ago</small>
								</div>
							</div>

							<div class="activity-item is-best">
								<div class="avatar-wrap">
									<img
										class="avatar"
										src="https://via.placeholder.com/150x150"
										data-demo-src="assets/img/avatars/lana.jpeg"
										data-user-popover="10"
										alt=""
									/>
									<div class="badge">
										<i-feather name="check"></i-feather>
									</div>
								</div>
								<div class="meta">
									<span>Lana Henrikssen</span>
									<span
										>Marked <a>your answer</a> as the best answer to her
										question.</span
									>
									<small>5 hours ago</small>
								</div>
							</div>

							<div class="activity-item">
								<div class="avatar-wrap">
									<img
										class="avatar"
										src="https://via.placeholder.com/150x150"
										data-demo-src="assets/img/avatars/placeholder-m.jpg"
										alt=""
									/>
									<div class="badge">
										<i-feather name="check"></i-feather>
									</div>
								</div>
								<div class="meta">
									<span>Hank Robson</span>
									<span
										>Replied to
										<a
											>Is there a cheatsheet listing all available browser
											polyfills?</a
										></span
									>
									<small>5 hours ago</small>
								</div>
							</div>
						</div>

						<!-- Load more -->
						<div class="load-more-wrap has-text-centered">
							<a href="#" class="load-more-button">Load More</a>
						</div>
					</div>
				</div>
			</div>
			<div class="column is-3">
				<div class="questions-side-card">
					<img src="assets/img/icons/questions/help.svg" alt="" />
					<h4>Help center</h4>
					<p>
						Having trouble? Please search our
						<a class="standard-link">Help Center</a> for a quick answer to your
						problem.
					</p>
				</div>

				<div class="questions-side-card">
					<img src="assets/img/icons/questions/assistance.svg" alt="" />
					<h4>24/7 Support</h4>
					<p>
						Our Help Center didn't help? Please contact our 24/7
						<a class="standard-link">Customer Assistance</a> hotline.
					</p>
				</div>
			</div>
		</div>
	</div>
</div>
