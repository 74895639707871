import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import * as environment from 'src/environments/environment';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as loadjs from '../../../../pages/feed-layout/pagelayout';
declare var jQuery: any;
@Component({
  selector: 'app-schedule-widget',
  templateUrl: './schedule-widget.component.html',
  styleUrls: ['./schedule-widget.component.scss'],
})
export class ScheduleWidgetComponent implements OnInit {
  @Input() infouser;
  date = new Date();
  days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  arrColor = [
    { name: 'Màu đỏ tươi', color: '#AD1457', isActive: false },
    { name: 'Màu cam', color: '#F4511E', isActive: false },
    { name: 'Màu vỏ chanh', color: '#E4C441', isActive: false },
    { name: 'Màu húng quế', color: '#0B8043', isActive: false },
    { name: 'Màu việt quất', color: '#3F51B5', isActive: false },
    { name: 'Màu nho', color: '#8E24AA', isActive: false },
    { name: 'Màu hoa anh đào', color: '#D81B60', isActive: false },
    { name: 'Màu bí ngô', color: '#EF6C00', isActive: false },
    { name: 'Màu xanh lục', color: '#C0CA33', isActive: false },
    { name: 'Màu bạch đàn', color: '#009688', isActive: false },
    { name: 'Màu oải hương', color: '#7986CB', isActive: false },
    { name: 'Màu cacao', color: '#795548', isActive: false },
    { name: 'Màu đỏ cà chua', color: '#D50000', isActive: false },
    { name: 'Màu đỏ xoài', color: '#F09300', isActive: false },
    { name: 'Màu hồ trăn', color: '#7CB342', isActive: false },
    { name: 'Màu xanh lam', color: '#039BE5', isActive: false },
    { name: 'Màu tía', color: '#B39DDB', isActive: false },
    { name: 'Màu khói', color: '#616161', isActive: false },
    { name: 'Màu hồng hạc', color: '#E67C73', isActive: false },
    { name: 'Màu chuối', color: '#F6BF26', isActive: false },
    { name: 'Màu xanh lá nhạt', color: '#33B679', isActive: false },
    { name: 'Màu xanh thẫm', color: '#4285F4', isActive: false },
    { name: 'Màu thạch anh tím', color: '#9E69AF', isActive: false },
    { name: 'Màu bạch dương', color: '#A79B8E', isActive: false },
  ];

  arrDisplay = [
    { value: 0, name: 'Pulic' },
    { value: 1, name: 'Private' },
  ];
  lastday;
  firstDateIndex;
  lastDateIndex;
  prevLastDay;
  nextDay;
  arrCurrentDays;
  arrPrevDay;
  arrNextDay;
  monthNumber;
  month;
  currentMonth;
  today;
  year;
  monthCurrent;
  arrHoursOfDay;
  arrHours = ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
  arrMinus = ['00', '15', '30', '45'];
  arrFormatTime = ['AM', 'PM'];
  environment;
  isEdit;
  constructor(
    private profileServe: ProfileService,
    private datePipe: DatePipe,
    private toastr: ToastrManager
  ) {
    this.environment = environment.environment.apiUrl;
    this.monthCurrent = this.months[this.date.getMonth() + 1];
    this.minus = new Date().getMinutes();
    this.arrHoursOfDay = [];
    for (let k = 0; k < this.arrFormatTime.length; k++) {
      for (let i = 0; i < this.arrHours.length; i++) {
        for (let j = 0; j < this.arrMinus.length; j++) {
          this.arrHoursOfDay.push({
            time:
              this.arrHours[i] +
              ':' +
              this.arrMinus[j] +
              '' +
              this.arrFormatTime[k],
          });
        }
      }
    }
  }
  isDisplay;
  ngOnInit(): void {
    this.date = new Date();
    this.colorEvent = '#A79B8E';
    for (let color in this.arrColor) {
      if (this.colorEvent == this.arrColor[color].color) {
        this.arrColor[color].isActive = true;
      }
    }
    this.isHourStart = false;
    this.isHourEnd = false;
    this.isDisplay = false;
    this.isColor = false;
    this.isEdit = false;
    this.arrCurrentDays = [];
    this.arrPrevDay = [];
    this.arrNextDay = [];
    this.arrEvent = [];
    this.title = '';
    this.description = '';
    this.roleId = [];
    this.address = '';
    this.arrResultUser = [];
    this.startDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.display = 0;
    this.getEvent();
    this.getTimeStart();
  }

  isColor;
  clickColorEvent() {
    this.isColor = !this.isColor;
  }
  changeColorEvent(dataColor) {
    this.colorEvent = dataColor.color;
    for (let color in this.arrColor) {
      if (dataColor.color == this.arrColor[color].color) {
        this.arrColor[color].isActive = true;
      } else {
        this.arrColor[color].isActive = false;
      }
    }
  }

  clickDisplay() {
    this.isDisplay = !this.isDisplay;
  }

  changeDisplay(display) {
    this.display = display;
  }

  isHourStart;
  isHourEnd;
  clickShowHours(id) {
    if (id == 1) {
      this.isHourStart = !this.isHourStart;
    } else if (id == 2) {
      this.isHourEnd = !this.isHourEnd;
    }
  }
  indexEvent;
  dataClickEvent;
  monthClickEvent;
  clickShowEvent(date) {
    this.dataClickEvent = date;
    let monthEvent = new Date(date.date).getMonth() + 1;
    this.monthClickEvent = this.months[monthEvent];
    for (let i = 0; i < this.arrEvent.length; i++) {
      let dateEvent = new Date(this.arrEvent[i].startDate).getDate();
      if (date.dayNumber == dateEvent) {
        this.indexEvent = 1;
      } else {
        this.indexEvent = 0;
      }
    }
    this.profileServe.changedataEvent({
      isShow: true,
      dayClick: date.dayNumber,
    });
  }

  addEvent() {
    this.isEdit = !this.isEdit;
    if (this.isEdit == true) {
      loadjs.loadjs();
    }
    this.clearInput();
  }

  clearInput() {
    this.getTimeStart();
    this.startDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.address = '';
    this.title = '';
    this.role = 0;
    this.roleId = [];
    this.description = '';
    this.display = 0;
    this.arrRole = [];
  }

  arrResultUser;
  searchUser;
  changeSearch(searchUser) {
    this.searchUser = searchUser;
    this.arrResultUser = [];
    this.profileServe
      .searchUsers({ search: this.searchUser })
      .subscribe((res) => {
        this.arrResultUser = res.data;
        for (let j in this.arrResultUser) {
          if (this.infouser._id == this.arrResultUser[j]._id) {
            this.arrResultUser.splice(j, 1);
          }
        }
        if (this.arrRole.length > 0) {
          for (let i in this.arrRole) {
            for (let j in this.arrResultUser) {
              if (this.arrRole[i]._id == this.arrResultUser[j]._id) {
                this.arrResultUser.splice(j, 1);
              }
              if (this.infouser._id == this.arrResultUser[j]._id) {
                this.arrResultUser.splice(j, 1);
              }
            }
          }
        }
      });
  }

  arrEvent;
  getEvent() {
    this.profileServe.getEventByUserId().subscribe((result) => {
      this.calendar();
      this.arrEvent = result.data;
      for (let event in this.arrEvent) {
        let dateEvent = new Date(this.arrEvent[event].startDate).setHours(
          0,
          0,
          0,
          0
        );
        for (let day in this.arrCurrentDays) {
          let dateCurrent = new Date(this.arrCurrentDays[day].date).setHours(
            0,
            0,
            0,
            0
          );
          if (dateEvent == dateCurrent) {
            this.arrCurrentDays[day] = Object.assign(
              {},
              this.arrCurrentDays[day],
              this.arrEvent[event]
            );
            this.arrCurrentDays[day].active = true;
          }
        }
      }
    });
  }

  minus;
  getTimeStart() {
    let currentDate = new Date().toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    let hours = new Date();
    let formatHH = hours.getHours();
    let formatH = formatHH;
    if (formatH >= 12) {
      formatH = formatHH - 12;
    }

    let formatDate = currentDate.substring(currentDate.length - 2);
    for (let i in this.arrHoursOfDay) {
      if (this.arrHoursOfDay[i].time.indexOf(formatDate) >= 0) {
        if (this.arrHoursOfDay[i].time.indexOf(formatH) >= 0) {
          if (this.minus < 15) {
            this.startTime = formatH + ':' + this.arrMinus[1] + '' + formatDate;
          } else if (this.minus < 30) {
            this.startTime = formatH + ':' + this.arrMinus[2] + '' + formatDate;
          } else if (this.minus < 45) {
            this.startTime = formatH + ':' + this.arrMinus[3] + '' + formatDate;
          } else {
            formatH += 1;
            let newDate = false;
            if (formatH == 12) {
              if (formatDate == 'AM') {
                formatDate = 'PM';
              } else {
                formatDate = 'AM';
                newDate = true;
              }
            }
            if (formatH == 13) {
              formatH = 1;
            }

            this.startTime = formatH + ':' + this.arrMinus[0] + '' + formatDate;
          }

          this.changeStartTime(this.startTime);
        }
      }
    }
  }

  arrHoursOfDayFinish;
  endTime;
  changeStartTime(startTime) {
    this.startTime = startTime;
    let formatH = parseInt(startTime.split(':')[0]);
    let formatDate = startTime.substring(startTime.length - 2);
    let formatMinute = parseInt(startTime.split(':')[1].substring(0, 2));
    this.arrHoursOfDayFinish = [];
    let newHour = formatH + 1;
    let newFormatDate = formatDate;
    let isNewDate = false;
    let newMinute = formatMinute;
    if (newHour == 12) {
      if (formatDate == 'AM') {
        newFormatDate = 'PM';
      } else {
        newFormatDate = 'AM';
        isNewDate = true;
      }
    }
    if (newHour == 13) {
      newHour = 1;
    }
    if (newMinute == 0) {
      this.endTime = newHour + ':' + '00' + newFormatDate;
    } else {
      this.endTime = newHour + ':' + newMinute + newFormatDate;
    }

    for (let i = 0; i < 12 * 4; i++) {
      this.arrHoursOfDayFinish.push(
        formatH +
          ':' +
          (formatMinute < 10 ? '0' + formatMinute : formatMinute) +
          formatDate
      );
      formatMinute = formatMinute + 15;
      if (formatMinute == 60) {
        formatMinute = 0;
        formatH += 1;
        if (formatH == 12) {
          if (formatDate == 'AM') {
            formatDate = 'PM';
          } else {
            formatDate = 'AM';
          }
        }
        if (formatH == 13) {
          formatH = 1;
        }
      }
    }
  }

  changFinishTime(finishTime) {
    this.endTime = finishTime;
  }

  arrRole = [];
  addRole(dataRole) {
    this.arrRole.push(dataRole);
    this.searchUser = '';
  }

  startDate;
  endDate;
  address;
  title;
  startTime;
  role;
  roleId;
  description;
  display;
  colorEvent;
  createEvent() {
    for (let i in this.arrRole) {
      this.roleId.push(this.arrRole[i]._id);
    }
    let data = {
      title: this.title,
      display: this.display,
      address: this.address,
      startDate: new Date(this.startDate),
      endDate: new Date(this.endDate),
      startTime: this.startTime,
      endTime: this.endTime,
      colorEvent: this.colorEvent,
      role: 0,
      roleId: this.roleId,
      description: this.description,
    };
    this.profileServe.createEvent(data).subscribe((result) => {
      if (result.status == 'success') {
        this.toastr.successToastr('Đã lưu sự kiện', '', {
          toastTimeout: 1000,
        });
        if (data.roleId.length > 0) {
          this.createInviteUser(result.data);
        }
        this.clearInput();
      }
    });
  }

  createInviteUser(obj) {
    let data = {
      calEventId: obj._id,
      calUserId: this.infouser._id,
      calRoleId: this.infouser._id,
      calIsJoin: 0,
      calRole: 0,
    };
    this.profileServe.createInviteEvent(data).subscribe((result) => {});
    for (let i in obj.roleId) {
      let arrRoles = obj.roleId;
      let data = {
        calEventId: obj._id,
        calUserId: this.infouser._id,
        calRoleId: arrRoles[i],
        calIsJoin: 0,
        calRole: 1,
      };
      this.profileServe.createInviteEvent(data).subscribe((result) => {});
    }
  }

  calendar() {
    this.arrCurrentDays = [];
    this.arrPrevDay = [];
    this.arrNextDay = [];
    this.lastday = new Date(
      this.date.getFullYear(),
      this.date.getMonth() + 1,
      0
    ).getDate();
    this.today = new Date().setHours(0, 0, 0, 0);
    this.currentMonth = this.date.getMonth();
    this.date.setMonth;

    this.firstDateIndex = new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      1
    ).getDay();

    this.prevLastDay = new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      0
    ).getDate();

    this.lastDateIndex = new Date(
      this.date.getFullYear(),
      this.date.getMonth() + 1,
      0
    ).getDay();

    this.nextDay = 7 - this.lastDateIndex - 1;

    for (let i = 1; i <= this.lastday; i++) {
      let month = this.date.getMonth() + 1;
      let year = this.date.getFullYear();
      let date = new Date(year + '-' + month + '-' + i);
      let active = false;

      this.arrCurrentDays.push({
        dayNumber: i,
        date: date,
        active: active,
      });
    }

    for (let x = this.firstDateIndex; x > 0; x--) {
      let prevdays = this.prevLastDay - x + 1;
      let month = this.date.getMonth();
      let year = this.date.getFullYear();
      let date = new Date(year + '-' + month + '-' + prevdays);
      let active = false;
      this.arrPrevDay.push({
        dayNumber: prevdays,
        date: date,
        active: active,
      });
    }

    for (let j = 1; j <= this.nextDay; j++) {
      let month = this.date.getMonth() + 2;
      let year = this.date.getFullYear();
      let date = new Date(year + '-' + month + '-' + j);
      let active = false;
      this.arrNextDay.push({ dayNumber: j, date: date, active: active });
    }
    this.arrCurrentDays = this.arrPrevDay.concat(this.arrCurrentDays);
    this.arrCurrentDays = this.arrCurrentDays.concat(this.arrNextDay);
    this.monthNumber = this.date.getMonth();
    this.year = this.date.getFullYear();
  }
}
