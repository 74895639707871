import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as loadjs from '../../../pages/feed-layout/pagelayout';

@Component({
  selector: 'app-feed-menu',
  templateUrl: './feed-menu.component.html',
  styleUrls: ['./feed-menu.component.scss']
})
export class FeedMenuComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.checkRoute()
  }

  gotoPage(value) {
    this.router.navigateByUrl(value)
  }

  routeValue;
  checkRoute() {
    this.routeValue = this.router.url
  }
}
