<div
	id="modal-confirm"
	class="modal modal-confirm is-light-bg"
	[ngClass]="isModalConfirms == true ? 'is-active' : ''"
>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-heading">
				<h3 *ngIf="isBlocks">Block author from group</h3>
				<h3 *ngIf="isMakeAdmins">
					Invite {{ dataMembers.memberId?.firstname }}
					{{ dataMembers.memberId?.lastname }} to Be An Admin
				</h3>
				<h3 *ngIf="isRemoveMembers">Take Additional Actions?</h3>
				<!-- Close X button -->
				<div class="close-wrap">
					<span class="close-modal">
						<i-feather name="x"></i-feather>
					</span>
				</div>
			</div>
			<div class="card-hr"></div>
			<!-- Modal subheading -->
			<div class="card-body">
				<div class="inner">
					<span *ngIf="isBlocks"
						>{{ dataMembers.memberId?.firstname }}
						{{ dataMembers.memberId?.lastname }} won't be able to find, see, or
						rejoin this group. Would you like to take additional actions?</span
					>
					<span *ngIf="isMakeAdmins"
						>If {{ dataMembers.memberId?.firstname }}
						{{ dataMembers.memberId?.lastname }} accepts your invite, he will be
						able to add or remove admins and moderators, edit group settings,
						and more.</span
					>
					<span *ngIf="isRemoveMembers"
						>You're removing {{ dataMembers.memberId?.firstname }}'s' from your
						group. Would you like to take additional actions?</span
					>
				</div>
			</div>
			<div class="card-footer card-footer-wrap">
				<button
					type="button"
					class="button is-solid grey-button close-modal"
					(click)="closeModal()"
				>
					Cancel
				</button>
				<button
					*ngIf="isBlocks"
					type="button"
					class="button is-solid accent-button close-modal"
					(click)="blockMember()"
				>
					Confirm
				</button>
				<button
					*ngIf="isMakeAdmins"
					type="button"
					class="button is-solid accent-button close-modal"
					(click)="makeAdmin()"
				>
					Send invite
				</button>
				<button
					*ngIf="isRemoveMembers"
					type="button"
					class="button is-solid accent-button close-modal"
					(click)="removeMember()"
				>
					Confirm
				</button>
			</div>
		</div>
	</div>
</div>
