import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { StoriesService } from 'src/app/services/stories.service';

@Component({
  selector: 'app-stories-main',
  templateUrl: './stories-main.component.html',
  styleUrls: ['./stories-main.component.scss'],
})
export class StoriesMainComponent implements OnInit {
  infoUser;
  constructor(
    private profileService: ProfileService,
    private storiesService: StoriesService
  ) {}

  ngOnInit(): void {
    this.getInfoUser();
    this.getListStories();
    this.storiesService.isStories.subscribe((result) => {
      if (result.isStories == true) {
        this.getListStories();
      }
    });
  }

  //#region GET information user login
  getInfoUser() {
    this.profileService.getProfile().subscribe((result) => {
      if (result.status == 'success') {
        this.infoUser = result.data;
      }
    });
  }
  //#endregion

  //#region GET list stoires
  arrStories = [];
  getListStories() {
    let reqFilter = {
      skip: 0,
    };
    this.storiesService.getListStories(reqFilter).subscribe((result) => {
      if (result.status == 'success') {
        this.arrStories = result.data;
        for (let stories of this.arrStories) {
          stories.isActive = stories.isActive || false;
        }
      }
    });
  }
  //#endregion

  async receviceOpen(data) {
    if (data.index < this.arrStories.length && data.index >= 0) {
      await this.arrStories.forEach((element) => (element.isActive = false));
      await this.initActive(data);
    }
  }
  initActive(data) {
    return (this.arrStories[data.index].isActive = true);
  }
}
