import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import iziToast from 'izitoast';
import { PagesService } from 'src/app/services/pages.service';
declare var jQuery: any;


@Component({
  selector: 'app-pages-about',
  templateUrl: './pages-about.component.html',
  styleUrls: ['./pages-about.component.scss'],
})
export class PagesAboutComponent implements OnInit {
  isAdminReceive;
  @Input() set isAdmin(isAdmin: any) {
    this.isAdminReceive = isAdmin;
  }
  @Input() dataPage;
  dataPageReceive;
  @Output() isUpdate = new EventEmitter<any>();

  avatar;
  cover;
  pageTitle;
  pageName;
  previewName;
  previewTitle;
  pageDescription;
  pageCategory;
  pageAddres;
  pagePhone;
  pageCompany;
  pageHistory;
  isActiveEdit;
  url;
  constructor(
    private pageService: PagesService,
    private changeDetect: ChangeDetectorRef
  ) {
    this.url = 'https://';
  }
  ngOnChanges(change: SimpleChange) {
    this.dataPageReceive = this.dataPage;
    if (this.dataPageReceive) {
      this.pagePhone = this.dataPageReceive.pagePhone;
      this.pageUrl = this.dataPageReceive.pageUrl;
      this.pageCompany = this.dataPageReceive.pageCompany;
      this.pageHistory = this.dataPageReceive.pageHistory;
    }
    this.changeDetect.detectChanges();
  }
  ngOnInit(): void { }

  initNotification(title, messeage, colorTitle, colorMessage, color) {
    iziToast.show({
      title: title,
      titleColor: colorTitle,
      message: messeage,
      messageColor: colorMessage,
      color: color,
      theme: 'dark',
      position: 'topRight',
      close: true,
      transitionIn: 'fadeInUp',
      transitionOut: 'fadeOut',
      transitionInMobile: 'fadeInUp',
      transitionOutMobile: 'fadeOutDown',
      progressBar: true,
      progressBarColor: '',
      progressBarEasing: 'linear',
      timeout: 2000,
    });
  }

  checkEdit() {
    if (this.isEdit) {
      // if (this.isEditPhone && !this.pagePhone) {
      //   this.initNotification(
      //     'warning',
      //     'please enter number phone',
      //     'red',
      //     'black',
      //     'red'
      //   );
      // } else if (this.isEditUrl && !this.pageUrl) {
      //   this.initNotification(
      //     'warning',
      //     'please enter page url',
      //     'green',
      //     'black',
      //     'green'
      //   );
      // } else if (this.isEditCompany && !this.pageCompany) {
      //   this.initNotification(
      //     'warning',
      //     'please enter company',
      //     'red',
      //     'black',
      //     'red'
      //   );
      // } else {
      //   this.editPage();
      // }
      if (this.isEditPhone && !this.pagePhone) {
        this.initNotification(
          'warning',
          'please enter number phone',
          'red',
          'black',
          'red'
        );
        return;
      }
      if (this.isEditUrl && !this.pageUrl) {
        this.initNotification(
          'warning',
          'please enter page url',
          'green',
          'black',
          'green'
        );
        return;
      }
      if (this.isEditCompany && !this.pageCompany) {
        this.initNotification(
          'warning',
          'please enter company',
          'red',
          'black',
          'red'
        );
        return;
      } else {
        this.editPage();
      }
    }
  }

  pageUrl;
  editPage() {
    const data = {
      id: this.dataPage._id,
      pageTitle: this.dataPageReceive.pageTitle,
      pageName: this.dataPageReceive.pageName,
      pageDescription: this.dataPageReceive.pageDescription,
      pageCategory: this.dataPageReceive.pageCategory,
      pageHistory: [],
      pageAddress: this.pageAddres,
      pagePhone: this.pagePhone,
      pageUrl: this.pageUrl,
      pageCompany: this.pageCompany,
    };

    this.pageService.editPage(data).subscribe((result) => {
      if (result.status == 'success') {
        this.isUpdate.emit({ isUpdate: true });
        this.initNotification(
          'Success',
          'Update success',
          'green',
          'black',
          'green'
        );
        this.isEditPhone = false;
        this.isEditUrl = false;
        this.isEditCompany = false;
        this.isEdit = false;
      }
    });

  }

  isActive;
  isEditPhone;
  isEditCompany;
  isEditUrl;
  isEdit;
  InitEdit(id) {
    this.isEdit = true;

    if (id == 0) {
      this.isEditPhone = !this.isEditPhone;
    } else if (id == 1) {
      this.isEditUrl = !this.isEditUrl;
    } else if (id == 2) {
      this.isEditCompany = !this.isEditCompany;
    }
  }

  initClose(id) {

    if (id == 0) {
      this.isEditPhone = false;
    } else if (id == 1) {
      this.isEditUrl = false;
    } else if (id == 2) {
      this.isEditCompany = false;
    }
    if (!this.isEditPhone && !this.isEditUrl && !this.isEditCompany) {
      this.isEdit = false;
    }
  }

  init
}
