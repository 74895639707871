import { Component, OnInit } from '@angular/core';
import iziToast from 'izitoast';
import { ProfileService } from 'src/app/services/profile.service';
declare var jQuery: any;

@Component({
  selector: 'app-upload-crop-cover-modal',
  templateUrl: './upload-crop-cover-modal.component.html',
  styleUrls: ['./upload-crop-cover-modal.component.scss'],
})
export class UploadCropCoverModalComponent implements OnInit {
  constructor(private profileService: ProfileService) {}

  ngOnInit(): void {
    this.loadJsUploadCover();
  }

  updateCover() {
    setTimeout(() => {
      let src = document.getElementById('cover-user').getAttribute('src');
      if (src) {
        let data = {
          editImage: src,
        };
        this.profileService.uploadCover(data).subscribe((result) => {
          if (result.status == 'success') {
            iziToast.success({
              title: 'Cập nhật ảnh bìa thành công.',
              titleColor: '#000',
              timeout: 1000,
            });
            this.closeModal();
            this.profileService.changeImg({ status: 'success' });
          }
        });
      }
    }, 1000);
  }

  closeModal() {
    (function ($) {
      $('.modal.is-active').removeClass('is-active');
    })(jQuery);
  }

  loadJsUploadCover() {
    (function ($) {
      $(document).ready(function () {
        //Cover image cropper
        if ($('#upload-cover').length) {
          var coverSrc;

          function readCoverFile(input) {
            if (input.files && input.files[0]) {
              var reader = new FileReader();

              reader.onload = function (e) {
                $coverCrop
                  .croppie('bind', {
                    url: e.target.result,
                  })
                  .then(function () {
                    coverSrc = e.target.result;
                    console.log('jQuery bind complete');
                  });
              };

              reader.readAsDataURL(input.files[0]);
            }
          }
          if (!$('#upload-cover').data('croppie')) {
            var $coverCrop = $('#upload-cover').croppie({
              enableExif: true,
              url: 'assets/img/profile.png',
              viewport: {
                width: 640,
                height: 184,
                type: 'square',
              },
              boundary: {
                width: '100%',
                height: 300,
              },
            });
          }

          function popupCoverResult(result) {
            var html;
            if (result.html) {
              html = result.html;
            }
            if (result.src) {
              html = '<img src="' + result.src + '" />';
              $('.cover-user').attr('src', result.src);
              $('#cover-page').attr('src', result.src);
              $('#submit-cover-picture').removeClass('is-loading');
              $('#upload-crop-cover-modal').removeClass('is-active');
            }
          }

          $('#upload-cover-picture').on('change', function () {
            readCoverFile(this);
            $(this)
              .closest('.modal')
              .find('.cover-uploader-box, .upload-demo-wrap, .cover-reset')
              .toggleClass('is-hidden');
            $('#submit-cover-picture').removeClass('is-disabled');
          });

          $('#submit-cover-picture').on('click', function (ev) {
            var $this = $(this);
            $this.addClass('is-loading');
            $coverCrop
              .croppie('result', {
                type: 'canvas',
                size: 'original',
              })
              .then(function (resp) {
                popupCoverResult({
                  src: resp,
                });
              });
          });

          $('#cover-upload-reset').on('click', function () {
            $(this).addClass('is-hidden');
            $('.cover-uploader-box, .upload-demo-wrap').toggleClass(
              'is-hidden'
            );
            $('#submit-cover-picture').addClass('is-disabled');
            $('#upload-cover-picture').val('');
          });
        }
      });
    })(jQuery);
  }
}
