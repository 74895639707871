import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
	selector: "app-feed-post-actions",
	templateUrl: "./feed-post-actions.component.html",
	styleUrls: ["./feed-post-actions.component.scss"],
})
export class FeedPostActionsComponent implements OnInit {
	constructor() {}
	ngOnInit(): void {}
}
