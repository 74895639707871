<div id="milly-details" class="panel-body is-user-details" *ngIf="infoFriend">
	<div class="panel-body-inner">
		<div class="subheader">
			<div class="action-icon">
				<i class="mdi mdi-video"></i>
			</div>
			<div class="action-icon">
				<i class="mdi mdi-camera"></i>
			</div>
			<div class="action-icon">
				<i class="mdi mdi-microphone"></i>
			</div>
			<div
				class="
					dropdown
					details-dropdown
					is-spaced is-neutral is-right
					dropdown-trigger
					ml-auto
				"
			>
				<div>
					<div class="action-icon">
						<i class="mdi mdi-dots-vertical"></i>
					</div>
				</div>
				<div class="dropdown-menu" role="menu">
					<div class="dropdown-content" *ngIf="">
						<a href="#" class="dropdown-item">
							<div class="media">
								<i-feather name="user"></i-feather>
								<div class="media-content">
									<h3>View profile</h3>
									<small>View this user's profile.</small>
								</div>
							</div>
						</a>
						<a class="dropdown-item">
							<div class="media">
								<i-feather name="mail"></i-feather>
								<div class="media-content">
									<h3>Send message</h3>
									<small>Send a message to the user's inbox.</small>
								</div>
							</div>
						</a>
						<hr class="dropdown-divider" />
						<a class="dropdown-item">
							<div class="media">
								<i-feather name="share-2"></i-feather>
								<div class="media-content">
									<h3>Share profile</h3>
									<small>Share this user's profile.</small>
								</div>
							</div>
						</a>
						<a class="dropdown-item">
							<div class="media">
								<i-feather name="x"></i-feather>
								<div class="media-content">
									<h3>Block user</h3>
									<small>Block this user permanently.</small>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>

		<div class="details-avatar">
			<img
				[src]="infoFriend.avatar"
				data-demo-src="assets/img/avatars/milly.jpg"
				alt
			/>
			<div class="call-me">
				<i class="mdi mdi-phone"></i>
			</div>
		</div>

		<div class="user-meta has-text-centered">
			<h3>
				{{ infoFriend.firstname + " " + infoFriend.lastname }}
			</h3>
			<h4 *ngIf="infoFriend.occupation">{{ infoFriend.occupation }}</h4>
		</div>

		<div class="user-badges">
			<div class="hexagon is-red">
				<div>
					<i class="mdi mdi-heart"></i>
				</div>
			</div>
			<div class="hexagon is-green">
				<div>
					<i class="mdi mdi-dog"></i>
				</div>
			</div>
			<div class="hexagon is-accent">
				<div>
					<i class="mdi mdi-flash"></i>
				</div>
			</div>
			<div class="hexagon is-blue">
				<div>
					<i class="mdi mdi-briefcase"></i>
				</div>
			</div>
		</div>

		<div class="user-about">
			<label>About Me</label>
			<div class="about-block" *ngIf="infoFriend.occupation">
				<i class="mdi mdi-domain"></i>
				<div class="about-text">
					<span>Works at</span>
					<span>
						<a class="is-inverted" href="#">
							{{ infoFriend.occupation }}
						</a>
					</span>
				</div>
			</div>
			<div class="about-block" *ngIf="infoFriend.city">
				<i class="mdi mdi-map-marker"></i>
				<div class="about-text">
					<span>From</span>
					<span>
						<a class="is-inverted" href="#">
							{{ infoFriend.city }}
						</a>
					</span>
				</div>
			</div>
		</div>
	</div>
</div>
