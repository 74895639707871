import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Directive({
  selector: '[appPaste]'
})
export class PasteDirective {
  @Output() filesClipBoard: EventEmitter<any> = new EventEmitter();
  constructor(private sanitizer: DomSanitizer) { }
  @HostListener("paste", ["$event"]) public onPaste(event: ClipboardEvent) {
    
    
    let files = []
    let clipboardData = event.clipboardData.items;
    for (let i in clipboardData) {
      let item = clipboardData[i];
      if (item.kind == "file") {
        let blob = item.getAsFile();
        let url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
        files.push({ nameUrl: blob.name, urlImg: url, formData: blob });
        event.preventDefault();
        event.stopPropagation();
      }
    }
    this.filesClipBoard.emit(files);
  }
}
