<h1 mat-dialog-title>THÔNG BÁO</h1>
<hr />
<div mat-dialog-content class="description-dialog">
  <p>
    Bạn thực sự muốn nhường quyền quản lý cấp cao của nhóm
    <span>{{ data.groupName }}</span> cho thành viên
    <span>{{ data.userName }}</span>
  </p>
  <p>
    Sau khi nhường quyển quản lý cấp cao bạn sẽ trở thành quản lý bình thường
    của nhóm {{ data.groupName }}
  </p>
  <hr />
  <p>Thao tác này không thể hoàn tác, chắc chắn thực hiện ?</p>
</div>
<div mat-dialog-actions>
  <div class="button-wrap">
    <div class="btn-no" mat-button (click)="onNoClick()">Không</div>
    <div class="btn-yes" mat-button (click)="onYesClick()">Có</div>
  </div>
</div>
