<div
    id="user-photos-modal"
    class="modal user-photos-modal is-medium has-light-bg"
>
    <div class="modal-background"></div>
    <div class="modal-content">
        <!-- Card -->
        <div class="card">
            <div class="card-heading">
                <h3>Choose a picture</h3>
                <!-- Close X button -->
                <div class="close-wrap">
                    <span class="close-modal">
                        <i-feather name="x"></i-feather>
                    </span>
                </div>
            </div>
            <!-- Card body -->
            <div class="card-body">
                <!-- Tabs -->
                <div class="nav-tabs-wrapper">
                    <div class="tabs">
                        <ul class="is-faded">
                            <li
                                class="is-active"
                                data-tab="recent-photos"
                            >
                                <a>Recent</a>
                            </li>
                            <li data-tab="all-photos">
                                <a>Photos</a>
                            </li>
                            <li data-tab="photo-albums">
                                <a>Albums</a>
                            </li>
                        </ul>
                    </div>




                    <!-- Recent Photos -->
                    <div
                        id="recent-photos"
                        class="tab-content has-slimscroll-md is-active"
                    >
                        <!-- Grid -->
                        <div class="image-grid">
                            <div class="columns is-multiline">
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/3.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/4.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/9.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/2.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/13.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/11.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/17.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/22.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/8.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/18.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/20.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/21.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <!-- All photos -->
                    <div
                        id="all-photos"
                        class="tab-content has-slimscroll-md"
                    >
                        <!-- Grid -->
                        <div class="image-grid">
                            <div class="columns is-multiline">
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/19.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/25.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/23.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/28.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/34.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/27.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/18.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/30.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/26.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/29.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/20.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/17.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/11.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/24.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/32.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/31.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/33.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/35.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <!-- Load more images -->
                        <div class="load-more-wrap has-text-centered">
                            <a
                                href="#"
                                class="load-more-button"
                            >
                                Load More
                            </a>
                        </div>
                        <!-- /Load more images -->
                    </div>




                    <!-- Albums -->
                    <div
                        id="photo-albums"
                        class="tab-content has-slimscroll-md"
                    >
                        <!-- Grid -->
                        <div class="albums-grid">
                            <div class="columns is-multiline">
                                <!-- Album item -->
                                <div class="column is-6">
                                    <div
                                        class="album-wrapper"
                                        data-album="album-photos-1"
                                    >
                                        <div class="album-image">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/35.jpg"
                                                alt
                                            >
                                        </div>
                                        <div class="album-meta">
                                            <div class="album-title">
                                                <span>Design and Colors</span>
                                                <span>Added on sep 06 2018</span>
                                            </div>
                                            <div class="image-count">
                                                <i-feather name="image"></i-feather>
                                                <span>8</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Album item -->
                                <div class="column is-6">
                                    <div
                                        class="album-wrapper"
                                        data-album="album-photos-2"
                                    >
                                        <div class="album-image">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/19.jpg"
                                                alt
                                            >
                                        </div>
                                        <div class="album-meta">
                                            <div class="album-title">
                                                <span>Friends and Family</span>
                                                <span>Added on jun 10 2016</span>
                                            </div>
                                            <div class="image-count">
                                                <i-feather name="image"></i-feather>
                                                <span>29</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Album item -->
                                <div class="column is-6">
                                    <div
                                        class="album-wrapper"
                                        data-album="album-photos-3"
                                    >
                                        <div class="album-image">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/4.jpg"
                                                alt
                                            >
                                        </div>
                                        <div class="album-meta">
                                            <div class="album-title">
                                                <span>Trips and Travel</span>
                                                <span>Added on feb 14 2017</span>
                                            </div>
                                            <div class="image-count">
                                                <i-feather name="image"></i-feather>
                                                <span>12</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Album item -->
                                <div class="column is-6">
                                    <div
                                        class="album-wrapper"
                                        data-album="album-photos-4"
                                    >
                                        <div class="album-image">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/3.jpg"
                                                alt
                                            >
                                        </div>
                                        <div class="album-meta">
                                            <div class="album-title">
                                                <span>Summer 2017</span>
                                                <span>Added on aug 23 2017</span>
                                            </div>
                                            <div class="image-count">
                                                <i-feather name="image"></i-feather>
                                                <span>32</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Album item -->
                                <div class="column is-6">
                                    <div
                                        class="album-wrapper"
                                        data-album="album-photos-5"
                                    >
                                        <div class="album-image">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/20.jpg"
                                                alt
                                            >
                                        </div>
                                        <div class="album-meta">
                                            <div class="album-title">
                                                <span>Winter 2017</span>
                                                <span>Added on jan 07 2017</span>
                                            </div>
                                            <div class="image-count">
                                                <i-feather name="image"></i-feather>
                                                <span>7</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Album item -->
                                <div class="column is-6">
                                    <div
                                        class="album-wrapper"
                                        data-album="album-photos-6"
                                    >
                                        <div class="album-image">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/2.jpg"
                                                alt
                                            >
                                        </div>
                                        <div class="album-meta">
                                            <div class="album-title">
                                                <span>Thanksgiving 2017</span>
                                                <span>Added on nov 30 2017</span>
                                            </div>
                                            <div class="image-count">
                                                <i-feather name="image"></i-feather>
                                                <span>6</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <!-- Hidden Grid | Design and colors -->
                        <div
                            id="album-photos-1"
                            class="album-image-grid is-hidden"
                        >
                            <div class="album-info">
                                <h4>
                                    Design and Colors |
                                    <small>8 photo(s)</small>
                                </h4>
                                <a class="close-nested-photos">Go Back</a>
                            </div>
                            <div class="columns is-multiline">
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/35.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/17.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/30.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/28.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/32.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/27.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/18.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/26.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <!-- Load more images -->
                            <div class="load-more-wrap has-text-centered">
                                <a
                                    href="#"
                                    class="load-more-button"
                                >
                                    Load More
                                </a>
                            </div>
                            <!-- /Load more images -->
                        </div>




                        <!-- Hidden Grid | Friends and Family -->
                        <div
                            id="album-photos-2"
                            class="album-image-grid is-hidden"
                        >
                            <div class="album-info">
                                <h4>
                                    Friends and Family |
                                    <small>29 photo(s)</small>
                                </h4>
                                <a class="close-nested-photos">Go Back</a>
                            </div>
                            <div class="columns is-multiline">
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/23.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/22.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/19.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/20.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/2.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/21.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/38.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/36.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/37.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <!-- Load more images -->
                            <div class="load-more-wrap has-text-centered">
                                <a
                                    href="#"
                                    class="load-more-button"
                                >
                                    Load More
                                </a>
                            </div>
                            <!-- /Load more images -->
                        </div>




                        <!-- Hidden Grid | Trips and Travel -->
                        <div
                            id="album-photos-3"
                            class="album-image-grid is-hidden"
                        >
                            <div class="album-info">
                                <h4>
                                    Trips and Travel |
                                    <small>12 photo(s)</small>
                                </h4>
                                <a class="close-nested-photos">Go Back</a>
                            </div>
                            <div class="columns is-multiline">
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/4.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/6.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/5.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/38.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/37.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/18.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/19.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/3.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/32.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <!-- Hidden Grid | Summer 2017 -->
                        <div
                            id="album-photos-4"
                            class="album-image-grid is-hidden"
                        >
                            <div class="album-info">
                                <h4>
                                    Summer 2017 |
                                    <small>32 photo(s)</small>
                                </h4>
                                <a class="close-nested-photos">Go Back</a>
                            </div>
                            <div class="columns is-multiline">
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/4.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/6.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/5.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/38.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/37.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/18.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/19.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/3.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/32.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <!-- Load more images -->
                            <div class="load-more-wrap has-text-centered">
                                <a
                                    href="#"
                                    class="load-more-button"
                                >
                                    Load More
                                </a>
                            </div>
                            <!-- /Load more images -->
                        </div>




                        <!-- Hidden Grid | Winter 2017 -->
                        <div
                            id="album-photos-5"
                            class="album-image-grid is-hidden"
                        >
                            <div class="album-info">
                                <h4>
                                    Winter 2017 |
                                    <small>7 photo(s)</small>
                                </h4>
                                <a class="close-nested-photos">Go Back</a>
                            </div>
                            <div class="columns is-multiline">
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/22.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/24.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/36.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/25.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/2.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/8.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/12.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <!-- Hidden Grid | Thanksgiving 2017 -->
                        <div
                            id="album-photos-6"
                            class="album-image-grid is-hidden"
                        >
                            <div class="album-info">
                                <h4>
                                    Thanksgiving 2017 |
                                    <small>6 photo(s)</small>
                                </h4>
                                <a class="close-nested-photos">Go Back</a>
                            </div>
                            <div class="columns is-multiline">
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/23.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/22.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/19.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/20.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/2.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Grid item -->
                                <div class="column is-4">
                                    <div class="grid-image">
                                        <input
                                            type="radio"
                                            name="selected_photos"
                                        >
                                        <div class="inner">
                                            <img
                                                src="https://via.placeholder.com/1600x900"
                                                data-demo-src="assets/img/demo/unsplash/21.jpg"
                                                alt
                                            >
                                            <div class="inner-overlay"></div>
                                            <div class="indicator gelatine">
                                                <i-feather name="check"></i-feather>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button class="button is-solid accent-button replace-button is-disabled">Use Picture</button>
            </div>
        </div>
    </div>
</div>
