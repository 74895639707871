import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from 'src/app/services/group/group.service';
import { ProfileService } from 'src/app/services/profile.service';
import { environment } from '../../../environments/environment';
declare var $: any;
import * as loadjs from 'src/app/pages/feed-layout/pagelayout';

@Component({
  selector: 'app-group-main',
  templateUrl: './group-main.component.html',
  styleUrls: ['./group-main.component.scss'],
})
export class GroupMainComponent implements OnInit {
  groupId: String;
  isFeed: boolean;
  constructor(
    private groupService: GroupService,
    private route: ActivatedRoute,
    private profileService: ProfileService
  ) {
    this.groupId = this.route.snapshot.paramMap.get('id');
    if (this.groupId === 'feed') {
      this.isFeed = true;
    }

    this.environment = environment.apiUrl;
  }

  ngOnInit(): void {
    this.getInfoUserByToken();
    loadjs.loadjs();
    if (this.groupId != null && this.groupId != 'feed') {
      this.getGroupById(this.groupId);
    }
    this.groupService.isChangeCover.subscribe((result) => {
      if (result.status == 'success') {
        this.getGroupById(this.groupId);
      }
    });
    this.groupService.isJoin.subscribe((result) => {
      if (result.status == 'success') {
        this.getGroupById(this.groupId);
      }
    });
    // if (this.isFeed == true) {
    //   if ($('.pageloader').length) {
    //     $('.pageloader').toggleClass('is-active');
    //     setTimeout(() => {
    //       $('.pageloader').removeClass('is-active');
    //       $('.infraloader').removeClass('is-active');
    //     }, 700);
    //   }
    // }
  }
  environment;
  isScrollable: boolean = false;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    this.isScrollable = window.scrollY > 400 ? true : false;
  }
  //#region Get info group by groupId
  dataGroup;
  isAdminGroup: boolean;
  isHidden: boolean = false;
  getGroupById(groupId) {
    this.groupService.getGroupById(groupId.toString()).subscribe((result) => {
      if (result.status == 'success') {
        this.dataGroup = result.data;
        this.checkMemberGroup(groupId);
        this.getPhotoByGroupId(groupId);
      }
    });
  }
  //#endregion

  //#region Get info user login
  dataUser;
  getInfoUser(event) {
    this.dataUser = event.data;
    if (this.dataGroup && this.groupId != null && this.groupId != 'feed') {
      this.isAdminGroup =
        this.dataGroup.groupAdmin == this.dataUser._id ? true : false;
    }
  }

  getInfoUserByToken() {
    this.profileService.getProfile().subscribe((result) => {
      if (result.status == 'success') {
        this.dataUser = result['data'];
      }
    });
  }
  //#endregion

  //#region Check member group
  isMember: boolean;
  checkMemberGroup(groupId) {
    let reqGroup = {
      groupId: groupId,
    };
    this.groupService.checkMember(reqGroup).subscribe((result) => {
      if (result.status == 'success') {
        let member = result.data;
        if (member) {
          this.isMember = true;
          this.isHidden = false;
        } else {
          this.isHidden = this.dataGroup.groupDisplay == 1 ? true : false;
        }
      }
    });
  }
  //#endregion

  //#region Get photo by groupId
  arrPhoto = [];
  getPhotoByGroupId(groupId) {
    this.groupService.getPhotoByGroupId(groupId).subscribe((result) => {
      if (result.status == 'success') {
        this.arrPhoto = result.data;
      }
    });
  }
  //#endregion
}
