import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import iziToast from 'izitoast';
import { PagesService } from 'src/app/services/pages.service';
import * as pageLoadJs from '../../../../page-main/js/pagejs';
declare var jQuery: any;

declare function initModals(): any;
@Component({
  selector: 'app-pages-create',
  templateUrl: './pages-create.component.html',
  styleUrls: ['./pages-create.component.scss'],
})
export class PagesCreateComponent implements OnInit {
  @Input() isModalCreatePage;
  @Output() isModalctrPage = new EventEmitter<any>();
  selectCategory;
  arrCategory = [
    { value: 0, nameValue: 'Thể thao' },
    { value: 1, nameValue: 'Giáo dục' },
    { value: 2, nameValue: 'Y tế' },
    { value: 3, nameValue: 'Kinh doanh' },
    { value: 4, nameValue: 'Vui chơi' },
    { value: 5, nameValue: 'Ăn uống' },
  ];

  isActiveBtn;
  constructor(private pagesService: PagesService) { }
  avatar;
  cover;
  pageTitle;
  pageName;
  previewName;
  previewTitle;

  pageDescription;
  pageCategory;
  pageAddres;
  pagePhone;
  isImageCover;
  isImageAvatar;
  ngOnInit() {
    this.isActiveBtn = false;
    this.previewName = 'Pizza';
    this.previewTitle = 'Pizza & CocaCocal';
    this.isUpload = false;
    this.isImageCover = false;
    this.isImageAvatar = false;
    this.isDropCover = false;
    initModals();
  }

  loadJsUploadImage() {
    (function ($) {
      $(document).ready(function () {
        //Cover image cropper
        if ($('#upload-cover').length) {
          var coverSrc;

          function readCoverFile(input) {
            if (input.files && input.files[0]) {
              var reader = new FileReader();

              reader.onload = function (e) {
                $coverCrop
                  .croppie('bind', {
                    url: e.target.result,
                  })
                  .then(function () {
                    coverSrc = e.target.result;
                    console.log('jQuery bind complete');
                  });
              };

              reader.readAsDataURL(input.files[0]);
            }
          }
          if (!$('#upload-cover').data('croppie')) {
            var $coverCrop = $('#upload-cover').croppie({
              enableExif: true,
              url: 'assets/img/profile.png',
              viewport: {
                width: 640,
                height: 184,
                type: 'square',
              },
              boundary: {
                width: '100%',
                height: 300,
              },
            });
          }

          function popupCoverResult(result) {
            var html;
            if (result.html) {
              html = result.html;
            }
            if (result.src) {
              html = '<img src="' + result.src + '" />';
              $('.cover-image').attr('src', result.src);
              $('.input-cover').attr('value', result.src);

              $('#submit-cover-picture').removeClass('is-loading');
              $('#upload-crop-cover-modal').removeClass('is-active');
            }
          }

          $('#upload-cover-picture').on('change', function () {
            readCoverFile(this);
            $(this)
              .closest('.modal')
              .find('.cover-uploader-box, .upload-demo-wrap, .cover-reset')
              .toggleClass('is-hidden');
            $('#submit-cover-picture').removeClass('is-disabled');
          });

          $('#submit-cover-picture').on('click', function (ev) {
            var $this = $(this);
            $this.addClass('is-loading');
            $coverCrop
              .croppie('result', {
                type: 'canvas',
                size: 'original',
              })
              .then(function (resp) {
                popupCoverResult({
                  src: resp,
                });
              });
          });

          $('#cover-upload-reset').on('click', function () {
            $(this).addClass('is-hidden');
            $('.cover-uploader-box, .upload-demo-wrap').toggleClass(
              'is-hidden'
            );
            $('#submit-cover-picture').addClass('is-disabled');
            $('#upload-cover-picture').val('');
          });
        }

        //Pofile picture cropper
        if ($('#upload-profile').length) {
          let imgSrc;

          function readFile(input) {
            if (input.files && input.files[0]) {
              var reader = new FileReader();

              reader.onload = function (e) {
                $uploadCrop
                  .croppie('bind', {
                    url: e.target.result,
                  })
                  .then(function () {
                    imgSrc = e.target.result;
                    console.log('jQuery bind complete');
                  });
              };

              reader.readAsDataURL(input.files[0]);
            }
          }
          if (!$('#upload-profile').data('croppie')) {
            var $uploadCrop = $('#upload-profile').croppie({
              enableExif: true,
              url: 'assets/img/profile.png',
              viewport: {
                width: 130,
                height: 130,
                type: 'circle',
              },
              boundary: {
                width: '100%',
                height: 300,
              },
            });
          }

          function popupResult(result) {
            var html;
            if (result.html) {
              html = result.html;
            }
            if (result.src) {
              html = '<img src="' + result.src + '" />';
              $('.cover-bg .avatar .avatar-image, #user-avatar-minimal').attr(
                'src',
                result.src
              );
              $('#submit-profile-picture').removeClass('is-loading');
              $('#upload-crop-profile-modal').removeClass('is-active');
            }
          }

          $('#upload-profile-picture').on('change', function () {
            readFile(this);
            $(this)
              .closest('.modal')
              .find('.profile-uploader-box, .upload-demo-wrap, .profile-reset')
              .toggleClass('is-hidden');
            $('#submit-profile-picture').removeClass('is-disabled');
          });

          $('#submit-profile-picture').on('click', function (ev) {
            var $this = $(this);
            $this.addClass('is-loading');
            $uploadCrop
              .croppie('result', {
                type: 'canvas',
                size: 'viewport',
              })
              .then(function (resp) {
                popupResult({
                  src: resp,
                });
              });
          });

          $('#profile-upload-reset').on('click', function () {
            $(this).addClass('is-hidden');
            $('.profile-uploader-box, .upload-demo-wrap').toggleClass(
              'is-hidden'
            );
            $('#submit-profile-picture').addClass('is-disabled');
            $('#upload-profile-picture').val('');
          });
        }
      });
    })(jQuery);
  }

  isDropAvatar;
  openModelDropAvatar() {
    this.loadJsUploadImage();
    this.isDropAvatar = !this.isDropAvatar;
    this.isImageAvatar = false;
  }
  openModalAvatar() {
    this.isImageAvatar = !this.isImageAvatar;
  }
  closeModalDropAvatar() {
    this.isDropAvatar = !this.isDropAvatar;
  }
  closeModalAvatar() {
    this.isImageAvatar = !this.isImageAvatar;
  }

  isDropCover;
  openModelDropCover() {
    this.loadJsUploadImage();
    this.isDropCover = !this.isDropCover;
    this.isImageCover = false;
  }

  openModalCover() {
    this.isImageCover = !this.isImageCover;
  }
  closeModalDropCover() {
    this.isDropCover = !this.isDropCover;
  }
  closeModalCover() {
    this.isImageCover = !this.isImageCover;
  }
  clickActiveBtnPlus() {
    this.isActiveBtn = !this.isActiveBtn;
  }

  isUpload;
  closeModalUpload() {
    pageLoadJs.pageLoadJs();
    this.isUpload = !this.isUpload;
  }

  editCover() {
    pageLoadJs.pageLoadJs();
    this.isUpload = !this.isUpload;
  }

  getListPage(pageName) {
    this.pagesService.checkPageName(pageName).subscribe((result) => {
      if (result.status == 'User has already exist') {
        this.showMessageError('Tên trang đã tồn tại.!');
      }
    });
  }

  createPage(valueImgCover, valueImgAvatar) {
    if (valueImgAvatar.currentSrc) {
      this.avatar = valueImgAvatar.currentSrc;
    } else {
      this.avatar = '';
    }
    if (valueImgCover.currentSrc) {
      this.cover = valueImgCover.currentSrc;
    } else {
      this.cover = '';
    }

    if (this.pageTitle == null) {
      this.showMessageError('Tiêu đề Fanpage là bắt buộc.!');
      return;
    }
    if (this.pageName == null) {
      this.showMessageError('Tên Fanpage là bắt buộc.!');
      return;
    }
    if (this.pageCategory == 0) {
      this.showMessageError('Danh mục là bắt buộc.!');
      return;
    }
    if (this.pageTitle && this.pageName && this.pageCategory != 0) {
      let data = {
        avatar: this.avatar,
        cover: this.cover,
        pageTitle: this.pageTitle,
        pageName: this.pageName,
        pageMoreInfo: [],
        pageDescription: this.pageDescription,
        pageCategory: this.pageCategory,
        pageHistory: [],
        pageAddress: this.pageAddres,
        pagePhone: '',
        pageUrl: '',
        pageCompany: '',
      };

      this.pagesService.createPage(data).subscribe((result) => {
        if (result.status == 'success') {
          this.pagesService.changeSuccessCreatePage({
            isLoading: true,
            id: result._id,
          });
          this.createAdminPage(result.data);
          this.showMessageSuccess('Tạo Fanpage thành công');
          let close = document.getElementsByClassName('close-modal')[0];
          close.addEventListener('click', function () { }, false);
        }
      });
    }
  }

  createAdminPage(dataPage) {
    let data = {
      userId: dataPage.userId,
      pageId: dataPage._id,
      accessTo: 0,
    };
    this.pagesService.createAdminPage(data).subscribe((result) => { });
  }

  submitAvatar() {
    this.isActiveBtn = false;
  }

  closeModalCreatePage() {
    this.isModalctrPage.emit(false);
  }

  showMessageSuccess(message) {
    iziToast.show({
      class: 'toast',
      message: message,
      messageColor: '#ffffff',
      iconColor: '#ffffff',
      backgroundColor: '#00cc00',
      progressBarColor: '#fafafa',
      position: 'topRight',
      transitionIn: 'fadeInDown',
      close: false,
      zindex: 99999,
    });
  }

  showMessageError(message) {
    iziToast.warning({
      class: 'toast',
      message: message,
      messageColor: '#ffffff',
      iconColor: '#000000',
      backgroundColor: '#ff0000',
      progressBarColor: '#fafafa',
      position: 'topRight',
      transitionIn: 'fadeInDown',
      close: false,
      zindex: 99999,
    });
  }
}
