import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-videos-sidebar',
  templateUrl: './videos-sidebar.component.html',
  styleUrls: ['./videos-sidebar.component.scss'],
})
export class VideosSidebarComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    this.initSideBarVideo();

  }



  initSideBarVideo() {
    (function ($) {
      $(document).ready(function () {
        'use strict';

        if ($('.videos-sidebar').length) {
          $('.mobile-sidebar-trigger').on('click', function () {
            $('.videos-sidebar').addClass('is-active');
          });
          $('.close-videos-sidebar').on('click', function () {
            $(this).closest('.videos-sidebar').removeClass('is-active');
          });

          function handleMobileSidebar() {
            if (window.matchMedia('(max-width: 767px)').matches) {
              $('.videos-sidebar').removeClass('is-active');
            } else if (window.matchMedia('(max-width: 768px)').matches) {
              $('.videos-sidebar').removeClass('is-active');
            } else {
              $('.videos-sidebar').addClass('is-active');
            }
          }

          handleMobileSidebar();

          $(window).on('resize', function () {
            handleMobileSidebar();
          });
        }

        if ($('.related-side').length) {
          $('.related-trigger').on('click', function () {
            $('.related-side').addClass('is-opened');
          });
          $('.close-related-videos').on('click', function () {
            $('.related-side').removeClass('is-opened');
          });
        }
      });
    })(jQuery);
  }
}
