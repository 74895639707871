<div>
	<div class="button" (click)="showAction = !showAction">
		<i-feather name="more-vertical"></i-feather>
	</div>
</div>
<div
	class="dropdown-menu"
	role="menu"
	[ngStyle]="{display: showAction ? 'block' : 'none'}"
>
	<div class="dropdown-content">
		<a href="#" class="dropdown-item">
			<div class="media">
				<i-feather name="bookmark"></i-feather>
				<div class="media-content">
					<h3>Bookmark</h3>
					<small>Add this post to your bookmarks.</small>
				</div>
			</div>
		</a>
		<a class="dropdown-item">
			<div class="media">
				<i-feather name="bell"></i-feather>
				<div class="media-content">
					<h3>Notify me</h3>
					<small>Send me the updates.</small>
				</div>
			</div>
		</a>
		<hr class="dropdown-divider" />
		<a href="#" class="dropdown-item">
			<div class="media">
				<i-feather name="flag"></i-feather>
				<div class="media-content">
					<h3>Flag</h3>
					<small>In case of inappropriate content.</small>
				</div>
			</div>
		</a>
		<a (click)="editPost()" class="dropdown-item">
			<div class="media">
				<i-feather name="edit"></i-feather>
				<div class="media-content">
					<h3>Edit</h3>
					<small>Edit content of this post.</small>
				</div>
			</div>
		</a>
	</div>
</div>
