export let pageLoadJs = () => {
  let js = [
    // Core js
    '../../assets/js/global.js',
    '../../assets/js/tipuedrop_content.js',
    '../../assets/js/main.js',
  ];
  removeScript();
  for (let i of js) {
    loadScript(i);
  }
};

function removeScript() {
  let removeScript = document.querySelectorAll('script[data-name="myscript"]');
  if (removeScript.length > 0) {
    for (let i = 0; i < removeScript.length; i++) {
      if (removeScript[i]) {
        document.getElementsByTagName('body')[0].removeChild(removeScript[i]);
      }
    }
  }
}

function loadScript(url) {
  const stripeExists = document.getElementById('stripev3');
  if (!stripeExists) {
    const stripeScript = document.createElement('script');
    stripeScript.src = 'https://js.stripe.com/v3/';
    stripeScript.async = true;
    stripeScript.id = 'stripev3';
    document.head.appendChild(stripeScript);
  }

  let script;
  script = document.createElement('script');
  let body = <HTMLDivElement>document.body;
  script.setAttribute('data-name', 'myscript');
  script.innerHTML = '';
  script.src = url;
  script.async = true;
  script.defer = true;
  body.appendChild(script);
}
