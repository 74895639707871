<div
    id="no-stream-modal"
    class="modal no-stream-modal is-xsmall has-light-bg"
>
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="card">
            <div class="card-heading">
                <h3></h3>
                <!-- Close X button -->
                <div class="close-wrap">
                    <span class="close-modal">
                        <i-feather name="x"></i-feather>
                    </span>
                </div>
            </div>
            <div class="card-body has-text-centered">
                <div class="image-wrap">
                    <img
                        src="assets/img/illustrations/characters/no-stream.svg"
                        alt
                    >
                </div>
                <h3>Streaming Disabled</h3>
                <p>
                    Streaming is not allowed from mobile web. Please use our mobile apps for mobile streaming.
                </p>
                <div class="action">
                    <a
                        href="/#demos-section"
                        class="button is-solid accent-button raised is-fullwidth"
                    >
                        Got It
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
