<app-nav-bar></app-nav-bar>
<div id="main-feed" class="container">
	<div id="activity-feed" class="view-wrap true-dom">
		<div class="columns">
			<div class="column is-3 is-hidden-mobile pos-sticky-wrap"></div>
			<div class="column is-6 pos-sticky-wrap">
				<div class="nav-drop is-active">
					<div class="inner">
						<div
							class="nav-drop-body is-friend-requests"
							*ngIf="friendRequests && friendRequests.length > 0"
						>
							<ng-container *ngFor="let request of friendRequests">
								<div class="media" *ngIf="!request.friend">
									<figure class="media-left">
										<p class="image">
											<img
												[src]="request.senderId.avatar"
												data-demo-src="assets/img/avatars/dan.jpg"
												alt
											/>
										</p>
									</figure>
									<div class="media-content">
										<a href="#">
											{{ request.senderId.firstname }}
											{{ request.senderId.lastname }}
										</a>
										<span>sent your friend request</span>
									</div>
									<div class="media-right">
										<button
											class="button icon-button is-solid grey-button raised"
											(click)="createFriend(request)"
										>
											<i-feather name="user-plus"></i-feather>
										</button>
										<button
											(click)="deleteFriend(request)"
											class="button icon-button is-solid grey-button raised"
										>
											<i-feather name="user-minus"></i-feather>
										</button>
									</div>
								</div>
								<div class="media" *ngIf="request.friend">
									<figure class="media-left">
										<p class="image">
											<img
												[src]="request.senderId.avatar"
												data-demo-src="assets/img/avatars/elise.jpg"
												alt
											/>
										</p>
									</figure>
									<div class="media-content">
										<span>
											You are now friends with
											<a href="#">
												{{ request.senderId.firstname }}
												{{ request.senderId.lastname }}
											</a>
											. Check her
											<a href="#">profile</a>
											.
										</span>
									</div>
									<div class="media-right">
										<div class="added-icon">
											<i-feather name="tag"></i-feather>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
