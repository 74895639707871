import { Component, Input, OnInit } from '@angular/core';
import { GroupService } from 'src/app/services/group/group.service';
import { environment } from '../../../../../environments/environment';

declare function initDropdowns(): any;
declare function initModals(): any;

@Component({
  selector: 'app-group-photo',
  templateUrl: './group-photo.component.html',
  styleUrls: ['./group-photo.component.scss'],
})
export class GroupPhotoComponent implements OnInit {
  environment;
  filter = {
    skip: 0,
    limit: 9,
  };

  filterAlbum = {
    skip: 0,
    limit: 9,
  };

  arrShow = [
    { value: 0, content: 'Albums' },
    { value: 1, content: 'Photos' },
  ];

  layoutActiveId;
  constructor(private groupService: GroupService) {
    this.environment = environment.apiUrl;
  }

  dataGroups;
  @Input() set dataGroup(dataGroup: any) {
    this.dataGroups = dataGroup;
    this.filter.skip = 0;
    this.filter.limit = 9;
    if (this.dataGroups) {
      this.getPhotosByGroupId(this.dataGroups._id, this.filter);
      this.getAblumByGroupId(this.dataGroups._id, this.filterAlbum);
    }
  }
  ngOnInit(): void {
    //initDropdowns();
    initModals();
    this.layoutActiveId = 0;
  }

  //#region Track by
  trackHero(index, item) {
    return item ? item._id : undefined;
  }

  //#endregion

  //#region Get photo by groupId
  arrPhotos = [];
  getPhotosByGroupId(groupId, filter) {
    let reqPhoto = {
      groupId: groupId,
      skip: filter.skip,
      limit: filter.limit,
    };
    this.groupService.getMorePhotoByGroupId(reqPhoto).subscribe((result) => {
      if (result.status == 'success') {
        this.arrPhotos = result.data;
      }
    });
  }

  getMore() {
    this.filter.skip = this.filter.skip + Number(9);
    let reqPhoto = {
      groupId: this.dataGroups._id,
      skip: this.filter.skip,
      limit: this.filter.limit,
    };
    this.groupService.getMorePhotoByGroupId(reqPhoto).subscribe((result) => {
      if (result.status == 'success') {
        let arrPhotoNew = result.data;
        this.arrPhotos = this.arrPhotos.concat(arrPhotoNew);
      }
    });
  }
  //#endregion

  //#region Get album by groupId
  arrAlbums = [];
  getAblumByGroupId(groupId, filterAblum) {
    let reqAlbum = {
      groupId: groupId,
      skip: filterAblum.skip,
      limit: filterAblum.limit,
    };
    this.groupService.getMoreAlbum(reqAlbum).subscribe((result) => {
      if (result.status == 'success') {
        this.arrAlbums = result.data;
      }
    });
  }

  getMoreAlbum() {
    this.filterAlbum.skip = this.filterAlbum.skip + 9;
    this.getAblumByGroupId(this.dataGroups._id, this.filterAlbum);
  }
  //#endregion

  //#region Open dropdowns/ modal
  openDropDown() {
    //initDropdowns();
  }

  openModal() {
    initModals();
  }
  //#endregion

  //#region Click active layout image
  clickActiveLayout(id) {
    this.layoutActiveId = id;
  }
  //#endregion
}
