import { Component, Input, OnInit } from '@angular/core';
import { PagesService } from 'src/app/services/pages.service';
import * as enviroment from '../../../../environments/environment';
@Component({
  selector: 'app-pages-photos',
  templateUrl: './pages-photos.component.html',
  styleUrls: ['./pages-photos.component.scss'],
})
export class PagesPhotosComponent implements OnInit {
  dataPages;
  @Input() set dataPage(dataPage: any) {
    this.dataPages = dataPage;
  }
  @Input() set arrPost(arrPost: any) {
    this.arrPosts = arrPost;
  }
  @Input() set dataInfoUser(infoUser: any) {
    this.infoUsers = infoUser;
  }
  infoUsers;
  enviroment;
  arrPosts = [];
  constructor(private pageServer: PagesService) {
    this.enviroment = enviroment.environment.apiUrl;
  }

  ngOnInit(): void {
    this.getListImg();
    this.clickPageChildActive(1);
  }

  //#region Get array images in post
  arrImage = [];
  lengthArrImage = 0;
  listImage = [];
  getListImg() {
    this.arrImage = [];
    if (this.arrPosts.length > 0) {
      for (let post of this.arrPosts) {
        let lengthArrImage = post.postImg.length;
        if (lengthArrImage > 0) {
          let arrImg = post.postImg;
          for (let img of arrImg) {
            this.arrImage.push({
              pageId: this.dataPages._id,
              postId: post._id,
              image: img,
              createdAt: post.createdAt,
            });
          }
        }
      }
      if (this.arrImage.length > 0) {
        this.insertArrImg(this.arrImage);
      }
    }
  }

  insertArrImg(arrImage) {
    let arrImg = [];
    for (let [index, img] of arrImage.entries()) {
      if (arrImg.length < 4) {
        arrImg.push(img);
      }
      if (arrImg.length == 3) {
        this.listImage.push(arrImg);
        if (this.listImage.length > 0) {
          arrImg = [];
        }
      }
      if (arrImg.length < 3 && arrImage.length / 3 != 0) {
        if (index == arrImage.length - 1) {
          this.listImage.push(arrImg);
          arrImg = [];
        }
      }
    }
  }
  //#endregion

  //#region Click get data post
  dataPost;
  isActivePhoto;
  clickPost(postId) {
    this.pageServer.getPostById(postId).subscribe((result) => {
      if (result.status == 'success') {
        this.dataPost = result.data;
        this.isActivePhoto = true;
      }
    });
  }
  //#endregion

  //#region @Output from pagesPhotoLightBoxComponents
  closeModal(event) {
    this.isActivePhoto = event;
  }
  //#endregion

  //#region Active page child [Recent], [Albums]
  isPageActive;
  clickPageChildActive(isPageActice) {
    this.isPageActive = isPageActice;
  }
  //#endregion
}
