import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline-mobile-dropdown',
  templateUrl: './timeline-mobile-dropdown.component.html',
  styleUrls: ['./timeline-mobile-dropdown.component.scss']
})
export class TimelineMobileDropdownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
