import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FeedService } from 'src/app/services/feed.service';
import { GroupService } from 'src/app/services/group/group.service';
import { PagesService } from 'src/app/services/pages.service';
import * as environment from '../../../../environments/environment';

//import js share model
import { initShareModelCustome } from '../../page-main/js/share-modal';

declare var feather: any;
declare var jQuery: any;

declare function initDropdowns(): any;
@Component({
  selector: 'app-group-new-feed',
  templateUrl: './group-new-feed.component.html',
  styleUrls: ['./group-new-feed.component.scss'],
})
export class GroupNewFeedComponent implements OnInit {
  dataGroups;
  dataUsers;

  @Input() set dataUser(dataUser) {
    this.dataUsers = dataUser;
  }
  @Input() isAdmin;

  environment;
  constructor(
    private pageService: PagesService,
    private groupService: GroupService,
    private feedService: FeedService
  ) {
    this.environment = environment.environment.apiUrl;
  }

  skipNewFeed;
  limitNewFeed;
  limit;
  skip;
  skipReply;
  limitReply;

  // recevie attached from groupsidebarComponents
  isAttached: boolean = false;
  attachedSubscription;
  arrGroups = [];
  ngOnInit() {
    //filter get post in new feed
    this.skipNewFeed = 0;
    this.limitNewFeed = 5;
    //filter get more comment
    this.limit = 3;
    this.skip = 0;

    //filter get more replies
    this.skipReply = 0;
    this.limitReply = 1;

    // filter get more post
    this.skipPost = 0;
    this.limitPost = 5;
    initShareModelCustome();
    // this.getPosts(this.skipNewFeed, this.limitNewFeed);

    // when submit post
    this.groupService.isSuccessPost.subscribe((result) => {
      if (result.status == 'success') {
        this.listPost = [];
        this.getPosts(this.skipNewFeed, this.limitNewFeed);
      }
    });

    this.attachedSubscription = this.groupService.clickAttached.subscribe(
      (result) => {
        this.isAttached = result.status === true ? true : false;

        if (this.isAttached == false) {
          this.listPost = [];
          this.getPosts(this.skipNewFeed, this.limitNewFeed);
        }
        this.arrGroups = result.data;
      }
    );
  }

  ngOnChanges() {
    this.isAdmin = this.isAdmin;
  }

  ngOnDestroy() {
    this.attachedSubscription.unsubscribe();
  }

  clickGotoWeb(domain) {
    window.location.href = domain;
  }

  //#region  Get List All Post in group
  listPost = [];
  getPosts(skip, limit) {
    let reqFilter = {
      skip: skip,
      limit: limit,
    };
    this.groupService.getPostNewFeeds(reqFilter).subscribe((result) => {
      if (result.status == 'success') {
        let arrPostNew = result.data[0];
        for (let post of arrPostNew) {
          post.isOpenComment = post.isOpenComment || {
            isActive: false,
          };
          let filter = {
            limit: this.limit,
            skip: this.skip,
          };
          this.initPostContent(post);
          this.getCommentsByPostId(post, filter);
          this.getLikeByPostId(post);
        }
        this.listPost = this.listPost.concat(arrPostNew);
      }
    });
  }

  initPostContent(post) {
    if (post.postContent) {
      post.postContent = post.postContent.replace(/\n/g, '<br />');
      const lengthTxt = post.postContent.length;
      if (lengthTxt > 150) {
        post.postContentCut = post.postContent.substr(0, 150) + '...';
        post.isLength = true;
        return post;
      }
      post.isLength = false;
      return post;
    }
  }

  getCommentsByPostId(post, filters) {
    let requestComment = {
      postId: post._id,
      groupId: post.postGroupId._id,
      limit: filters.limit,
      skip: filters.skip,
    };
    this.groupService.getComments(requestComment).subscribe((resultComment) => {
      post.filterComment = post.filterComment || {
        skip: this.skip,
        limit: this.limit,
      };

      post.filterView = post.filterView || {
        isActive: false,
        isMore: false,
      };

      post.arrComment = post.arrComment || [];
      if (resultComment.status == 'success') {
        // insert count comment
        post.countComment = resultComment.count || 0;
        // arr comment get from service
        const arrcommentNew = resultComment.data;
        post.filterView.isMore =
          post.countComment > post.filterComment.limit ? true : false;
        post.filterView.isActive =
          post.countComment > post.filterComment.limit ? true : false;

        for (let commentNew of arrcommentNew) {
          this.insertReply(commentNew);
          this.getLikeByCommentId(commentNew);
          this.checkCommentForUser(commentNew);
        }
        post.arrComment = arrcommentNew.concat(post.arrComment);
      }
    });
  }
  checkCommentForUser(comment) {
    comment.isUser = comment.userId._id == this.dataUsers._id ? true : false;
  }
  getLikeByCommentId(commentCurrent) {
    this.groupService
      .getLikeByCommentId(commentCurrent._id)
      .subscribe((result) => {
        commentCurrent.arrLike = [];
        if (result.status == 'success') {
          let arrLike = result.data;
          commentCurrent.arrLike = arrLike;
          this.checkLikeUser(commentCurrent);
        }
      });
  }
  checkLikeUser(comment) {
    let arrLike = comment.arrLike;
    let checkLikeUser = arrLike.find(
      (like) => like.userId._id === this.dataUsers._id
    );
    comment.isLikeComment = checkLikeUser ? true : false;
  }
  //#endregion

  //#region Activities OPEN/CLOSE COMMENT in post
  openComment(post) {
    post.isOpenComment.isActive = true;
  }
  closeComment(post) {
    post.isOpenComment.isActive = false;
  }
  //#endregion

  //#region LIKE/UNLIKE post in FanPage
  createLikePost(post) {
    let dataLike = {
      postId: post._id,
      groupId: post.postGroupId._id,
      type: 0,
    };
    this.groupService.createLike(dataLike).subscribe((like) => {
      if (like.status == 'success') {
        this.getLikeByPostId(post);
      }
    });
  }
  unLikePost(post) {
    let unlike = {
      postId: post._id,
    };
    this.groupService.unlikePost(unlike).subscribe((result) => {
      if (result.status == 'success') {
        post.isLiked = false;
        this.getLikeByPostId(post);
      }
    });
  }
  //#endregion

  //#region Get Array Like Post in FanPage
  getLikeByPostId(post) {
    post.like = [];
    post.isLiked = false;
    this.groupService.getLikesByPostId(post._id).subscribe((result) => {
      if (result.status == 'success') {
        let arrLikeNew = result.data;
        let checkeUseLike = arrLikeNew.some(
          (like) => like.userId._id === this.dataUsers._id
        );
        post.isLiked = checkeUseLike == true ? true : false;
        post.like = arrLikeNew;
      }
    });
  }
  //#endregion

  //#region Create comment for post in group
  clearInput(postCurrent, index) {
    postCurrent.commentContent = undefined;
    postCurrent.commentImg = undefined;
    postCurrent.commentTag = undefined;
    postCurrent.commentVideo = undefined;
    document.getElementById(`${index}tag-friends`).innerHTML = '';
    document.getElementById(`${index}cmt-image`).style.display = 'none';
  }
  createComment(postCurrent, index) {
    postCurrent.commentContent = document.getElementById(
      `${index}tag-friends`
    ).innerHTML;

    let dataComment = {
      groupId: postCurrent.postGroupId._id,
      postId: postCurrent._id,
      commentContent: postCurrent.commentContent,
      commentImg: postCurrent.commentImg,
      commentTag: postCurrent.commentTag,
      commentVideo: postCurrent.commentVideo,
    };
    this.groupService.createComment(dataComment).subscribe((result) => {
      if (result.status == 'success') {
        this.closeImg(index, postCurrent);
        this.clearInput(postCurrent, index);
        let commentNew = result.data;
        this.getCommentById(postCurrent, commentNew._id);
      }
    });
  }
  getCommentById(postCurrent, id) {
    this.groupService.getCommentById(id).subscribe((result) => {
      if (result.status == 'success') {
        let commnetNews = result.data;
        if (commnetNews) {
          this.insertOneComment(postCurrent, commnetNews);
        }
      }
    });
  }
  insertOneComment(postCurrent, commentNew) {
    commentNew.isLikeComment = false;
    commentNew.arrLike = commentNew.arrLike || [];
    postCurrent.arrComment = postCurrent.arrComment || [];
    postCurrent.arrComment.push(commentNew);
    postCurrent.countComment = Number(postCurrent.countComment + 1);
  }
  deleteComment(post, comment) {
    this.groupService.deleteComment(comment._id).subscribe((deleteComment) => {
      if (deleteComment.status == 'success') {
        let indexCmt = post.arrComment.findIndex((cmt) => {
          cmt._id === comment._id;
        });
        if (indexCmt) {
          post.arrComment.splice(indexCmt, 1);
        }
      }
    });
  }
  //#endregion

  //#region Insert Reply of post when load first group
  insertReply(commentCurrent) {
    commentCurrent.isLikeComment = false;
    commentCurrent.arrLike = commentCurrent.arrLike || [];
    commentCurrent.filter = commentCurrent.filter || {
      skipReply: this.skipReply,
      limitReply: this.limitReply,
    };
    commentCurrent.filterView = commentCurrent.filterView || {
      isActive: false,
      isMore: false,
    };
    this.getReplyByCmtIds(commentCurrent, commentCurrent.filter);
  }
  getReplyByCmtIds(commentCurrent, filter) {
    commentCurrent.arrReply = commentCurrent.arrReply || [];
    commentCurrent.countReply = commentCurrent.countReply || 0;
    let requestReply = {
      id: commentCurrent._id,
      skip: filter.skipReply,
      limit: filter.limitReply,
    };
    this.groupService.getReplysByCmtId(requestReply).subscribe((dataReply) => {
      if (dataReply.status == 'success') {
        let arrRepliesNew = dataReply.data;
        if (arrRepliesNew.length > 0) {
          commentCurrent.countReply = dataReply.count || 0;
          commentCurrent.arrReplies = arrRepliesNew;

          commentCurrent.filterView.isActive =
            commentCurrent.countReply > 2 ? true : false;
          commentCurrent.filterView.isMore =
            commentCurrent.countReply > 2 ? true : false;

          commentCurrent.arrReplies = this.getUnique(
            commentCurrent.arrReplies,
            '_id'
          );

          let arrReplyNews = commentCurrent.arrReply;
          for (let replies of arrReplyNews) {
            replies.isUser =
              replies.userId._id == this.dataUsers._id ? true : false;
            replies.arrLikeReply = replies.arrLikeReply || [];
            replies.isLikeReply = false;
            this.getLikeByReplyId(replies);
          }
        }
      }
    });
  }
  // check array duplicate
  getUnique(arr, comp) {
    const unique = arr
      .map((e) => e[comp])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);
    return unique;
  }

  //#endregion

  //#region Search user for tags
  repeatTag(arr) {
    if (arr.length > 1) {
      let count = 0;
      for (let i = 0; i < arr.length; i++) {
        for (let j = 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            return (count = count + 1);
          }
        }
      }
    }
  }
  textComment;
  tagsUser(user, index, postCurrent) {
    let fullname = user.firstname + user.lastname;
    postCurrent.commentTag = postCurrent.commentTag || [];
    postCurrent.commentTag.push(user._id);
    let txtContent = document.getElementById(`${index}tag-friends`).innerHTML;
    let count = 0;
    if (this.repeatTag(postCurrent.arrName) > 0) {
      let lengthLast =
        postCurrent.arrName[postCurrent.arrName.length - 1].length;
      txtContent = txtContent.slice(0, txtContent.length - lengthLast);
    } else {
      if (postCurrent.arrName.length > 1) {
        count = postCurrent.arrName.length - 1;
      }
      let indexCount = txtContent.indexOf(postCurrent.arrName[count], 0);
      txtContent = txtContent.slice(0, indexCount);
    }
    document.getElementById(`${index}tag-friends`).innerHTML = txtContent;
    this.changeCommentContent(fullname, index);
    postCurrent.arrUser = [];
  }
  model;
  arrSpan = [];
  word;
  changeCommentContent(commentContent, index) {
    (function ($) {
      $(document).ready(function () {
        $(`#${index}tag-friends`).append(
          `<a contenteditable="false"  style="margin-left:3px;color:#000;padding:3px;background:#5DADE2;width:fit-content;border-radius:1px;font-size: 0.95rem;"  > ${commentContent}</a>`
        );
      });
    })(jQuery);
  }

  searchUser(username, postCurrent) {
    if (username != undefined || username != null) {
      (function ($) {
        $(document).ready(function () {
          $('.tag-fr').focusout(function () {
            var element = $(this);
            if (!element.text().replace(' ', '').length) {
              element.empty();
            }
          });
        });
      })(jQuery);
      var start = /@/gi; // @ Match
      var word = /@(\w+)/gi; //@abc Match
      if (username.indexOf('@') > -1) {
        var go = username.match(start); //Content Matching @
        postCurrent.arrName = username.match(word); //Content Matching @abc
        if (go.length > 0) {
          if (postCurrent.arrName) {
            let count = 0;
            if (postCurrent.arrName.length > 1) {
              count = postCurrent.arrName.length - 1;
            }
            let usernameFillter = postCurrent.arrName[count]
              .toString()
              .slice(1);
            this.pageService
              .searchUser({ search: usernameFillter })
              .subscribe((arrUser) => {
                if (arrUser.status == 'success') {
                  postCurrent.arrUser = arrUser.data;
                }
              });
          } else {
            postCurrent.arrUser = [];
          }
        }
      }
    }
  }
  //#endregion

  //#region Preview image
  detectFiles(event, index, postCurrent) {
    postCurrent.commentImg = undefined;
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        if (file.type.indexOf('image') > -1) {
          postCurrent.format = 'image';
        }
        if (file.type.indexOf('video') > -1) {
          postCurrent.format = 'video';
        }
        reader.onload = (e: any) => {
          document.getElementById(`${index}cmt-image`).style.display = 'block';
          if (file.type.indexOf('image') > -1) {
            postCurrent.commentImg = e.target.result;
          }
          if (file.type.indexOf('video') > -1) {
            postCurrent.commentVideo = e.target.result;
          }
        };
      }
    }
  }
  //#endregion

  //#region Delete image in comment in post
  closeImg(index, postCurrent) {
    postCurrent.commentImg = undefined;
    document.getElementById(`${index}cmt-image`).style.display = 'none';
    if (postCurrent.format === 'image') {
      postCurrent.commentImg = undefined;
      postCurrent.format = undefined;
      return;
    }
    if (postCurrent.format === 'video') {
      postCurrent.commentVideo = undefined;
      postCurrent.format = undefined;
      return;
    }
  }
  //#endregion

  //#region View all comment, reply in post
  viewAllComment(post) {
    post.filterComment.skip =
      post.filterComment.skip + post.filterComment.limit;
    this.getCommentsByPostId(post, post.filterComment);
  }

  viewAllreply(comment) {
    comment.filter.skipReply =
      comment.filter.skipReply + comment.filter.limitReply;
    this.insertManyReply(comment);
  }
  insertManyReply(comment) {
    let requestReply = {
      id: comment._id,
      limit: comment.filter.limitReply,
      skip: comment.filter.skipReply,
    };
    this.groupService
      .getReplysByCmtId(requestReply)
      .subscribe((resultReplies) => {
        if (resultReplies.status == 'success') {
          let arrRepliesNew = resultReplies.data;
          comment.arrReplies = comment.arrReplies || [];
          comment.countReply = resultReplies.count || 0;

          for (let replies of arrRepliesNew) {
            this.getLikeByReplyId(replies);
          }
          comment.filterView.isActive =
            comment.countReply > comment.filter.limiReply ? true : false;
          comment.filterView.isMore =
            comment.countReply > comment.filter.limiReply ? true : false;

          comment.arrReplies = arrRepliesNew.concat(comment.arrReplies);
        }
      });
  }
  //#endregion

  //#region Enter link preview
  private regex = new RegExp(
    '^(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})'
  );
  isEnter;
  enterLink(postShareLink) {
    this.isEnter = true;
    this.getDataFromUrl(postShareLink);
  }
  linkPreview;
  getDataFromUrl(url) {
    if (url.length > 0) {
      if (url.indexOf('https://') > -1) {
        url = url.slice(8, url.length);
      }
      this.pageService.getDataFromUrl({ url: url }).subscribe((result) => {
        if (result.status == 'success') {
          this.isEnter = false;
          this.linkPreview = result.data;
          (function ($) {
            $(document).ready(function () {
              $('#link-summary').css('display', 'block').fadeIn(2000, 'swing');
            });
          })(jQuery);
        }
      });
    }
  }
  //#endregion

  //#region CREATE, GET, DELETE, EDIT Reply comment in post FanPage
  createReplyCmt(postCurrent, index) {
    postCurrent.commentContent = document.getElementById(
      `${index}tag-friends`
    ).innerHTML;
    if (postCurrent.repliedCommentId) {
      let reply = {
        groupId: postCurrent.postGroupId._id,
        postId: postCurrent._id,
        commentId: postCurrent.repliedCommentId,
        replyContent: postCurrent.commentContent,
        replyImg: postCurrent.commentImg,
      };
      this.groupService.createReply(reply).subscribe((result) => {
        if (result.status == 'success') {
          if (result.data) {
            let replies = result.data;
            this.getReplyById(
              replies._id,
              index,
              postCurrent.indexCommenrCureent
            );
            this.clearInput(postCurrent, index);
            postCurrent.arrReplyTime = undefined;
          }
        }
      });
    }
  }
  getReplyById(replyId, index, indexComment) {
    this.groupService.getReplyById(replyId).subscribe((dataReply) => {
      if (dataReply.status == 'success') {
        let repliesNew = dataReply.data;
        this.insertOneReply(repliesNew, index, indexComment);
      }
    });
  }
  insertOneReply(replies, index, indexComment) {
    replies.isLikeReply = false;
    replies.arrLikeReply = replies.arrLikeReply || [];
    let arrPost = this.listPost;
    let postCurrent = arrPost[index];
    postCurrent.arrComment = postCurrent.arrComment || [];
    let commentCurrent = postCurrent.arrComment[indexComment];
    commentCurrent.arrReplies.push(replies);
    postCurrent.countComment = Number(postCurrent.countComment + 1);
  }
  clickReply(username, commentId, index, indexComment) {
    document.getElementById(`${index}tag-friends`).focus();
    let arrPost = this.listPost;
    let postCurrent = arrPost[index];
    postCurrent.repliedCommentId = commentId;
    postCurrent.indexCommenrCureent = indexComment;
    postCurrent.arrReplyTime = postCurrent.arrReplyTime || [];
    postCurrent.arrReplyTime.push(username);
    let fullname = username.firstname + username.lastname;
    if (this.dataUsers._id != username._id) {
      this.changeCommentContent(fullname, index);
    }
  }
  deleteReply(replyId, indexPost, indexComment, indexReply) {
    this.groupService.deleteReplies(replyId).subscribe((result) => {
      if (result.status == 'success') {
        let arrPost = this.listPost;
        let post = arrPost[indexPost];
        let arrcomment = post.arrComment;
        let comment = arrcomment[indexComment];
        let arrReply = comment.arrReplies;
        arrReply.splice(indexReply, 1);
        arrReply.countReply = Number(arrReply.countReply - 1);
      }
    });
  }
  //#endregion

  //#region Jquery load function
  openMenu() {
    initDropdowns();
  }
  //#endregion

  //#region CREATE LIKE, UNLIKE COMMENT
  createLikeComment(comment) {
    let reqLikeCmt = {
      commentId: comment._id,
      type: 1,
    };
    this.groupService.createLike(reqLikeCmt).subscribe((resultLike) => {
      if (resultLike.status == 'success') {
        this.getLikeByCommentId(comment);
      }
    });
  }

  unlikeComment(comment) {
    let unLike = {
      commentId: comment._id,
    };
    this.groupService.unlikeComment(unLike).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByCommentId(comment);
      }
    });
  }

  //#endregion

  //#region CREATE LIKE, UNLIKE REPLY COMMENT
  createLikeReply(comment, reply) {
    let likeReply = {
      commentId: comment._id,
      replyId: reply._id,
      type: 2,
    };
    this.groupService.createLike(likeReply).subscribe((result) => {
      if (result.status == 'success') {
        this.insertLikeReply(reply);
      }
    });
  }
  insertLikeReply(reply) {
    reply.isLikeReply = false;
    reply.arrLikeReply = reply.arrLikeReply || [];
    this.getLikeByReplyId(reply);
  }
  getLikeByReplyId(replies) {
    this.groupService.getLikeByReplyId(replies._id).subscribe((result) => {
      if (result.status == 'success') {
        let arrReplyNew = result.data;
        replies.arrLikeReply = arrReplyNew;
        this.checkLikeReply(replies);
      }
    });
  }
  checkLikeReply(replies) {
    let arrLikeReply = replies.arrLikeReply;
    let checkLikeReplies = arrLikeReply.find(
      (item) => item.userId._id === this.dataUsers._id
    );
    replies.isLikeReply = checkLikeReplies ? true : false;
  }

  unLikeReply(reply) {
    let data = {
      replyId: reply._id,
    };
    this.groupService.unlikeReply(data).subscribe((result) => {
      if (result.status == 'success') {
        reply.isLikeReply = false;
        reply.arrLikeReply = reply.arrLikeReply || [];
        this.getLikeByReplyId(reply);
      }
    });
  }
  //#endregion

  //#region GET list post with filter(skip, limit)
  skipPost;
  limitPost;
  skipPosts = 0;
  getMoresPost() {
    (function ($) {
      $(document).ready(function () {
        var t;
        $('.load-more-button').on('click', function (e) {
          e.preventDefault();
          clearTimeout(t);
          $(this).toggleClass('loading');
          t = setTimeout(function () {
            $('.load-more-button').removeClass('loading');
          }, 2500);
        });
      });
    })(jQuery);

    this.skipPost = this.skipPosts + 5;
    this.skipPosts = this.skipPost;
    let reqMore = {
      skip: this.skipPosts,
      limit: this.limitPost,
    };
    this.groupService.getPostNewFeeds(reqMore).subscribe((result) => {
      if (result.status == 'success') {
        let arrPostNew = result.data;
        for (let post of arrPostNew) {
          post.isOpenComment = post.isOpenComment || {
            isActive: false,
          };
          let filter = {
            limit: this.limit,
            skip: this.skip,
          };
          // this.getCommentsByPageId(post, filter);
          // this.getLikeByPostId(post);
        }
        this.listPost = this.listPost.concat(arrPostNew);
      }
    });
  }
  //#endregion

  //#region TrackBy ngFor
  trackByFn(index, item) {
    return index;
  }
  //#endregion

  //#region Click Attached group
  clickAttachedGroup(group) {
    if (group.isAttached == false) {
      group.isAttached = true;
      //attached groups
      let reqAttached = {
        id: group._id,
        isAttached: group.isAttached,
      };
      this.groupService.attachedGroup(reqAttached).subscribe((result) => {});
      return;
    }
    if (group.isAttached == true) {
      group.isAttached = false;
      //attached groups
      let reqAttached = {
        id: group._id,
        isAttached: group.isAttached,
      };
      this.groupService.attachedGroup(reqAttached).subscribe((result) => {});
      return;
    }
  }
  //#endregion

  //#region FUNCTION share post for user login
  sharePost(dataPost) {
    initShareModelCustome();
    dataPost.type = 2;
    this.feedService.clickSharePost({ status: 'success', data: dataPost });
  }
  //#endregion

  //#region JQUERY ZOOM IMAGE USING FANCYBOX
  dataPost;
  openPostFancyBox(post) {
    post.type = 2;
    this.dataPost = post;
    this.isActivePhoto = true;
  }

  //#endregion
  //#region @Output from pagesPhotoLightBoxComponents
  isActivePhoto: boolean = false;
  closeModal(event) {
    this.isActivePhoto = event;
  }
  //#endregion
}
