<div
    id="personal-settings"
    class="settings-section"
>
    <div class="settings-panel">

        <div class="title-wrap">
            <a class="mobile-sidebar-trigger">
                <i-feather name="menu"></i-feather>
            </a>
            <h2>Personal</h2>
        </div>

        <div class="settings-form-wrapper">
            <div class="settings-form">
                <div class="columns is-multiline flex-portrait">
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="user"></i-feather>
                                </div>
                                <h4>Personal Info</h4>
                                <p>Access your personal info</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="clock"></i-feather>
                                </div>
                                <h4>History</h4>
                                <p>Access private history</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="download-cloud"></i-feather>
                                </div>
                                <h4>Download</h4>
                                <p>Download your data</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="codepen"></i-feather>
                                </div>
                                <h4>Connected Apps</h4>
                                <p>Manage connected apps</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="credit-card"></i-feather>
                                </div>
                                <h4>Payment Info</h4>
                                <p>Manage payment info</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="hexagon"></i-feather>
                                </div>
                                <h4>Account</h4>
                                <p>Manage account info</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="github"></i-feather>
                                </div>
                                <h4>Integrations</h4>
                                <p>Manage integrations</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="shopping-cart"></i-feather>
                                </div>
                                <h4>Shop Settings</h4>
                                <p>Manage your store</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="database"></i-feather>
                                </div>
                                <h4>System Settings</h4>
                                <p>Manage preferences</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="illustration">
                <img
                    src="assets/img/illustrations/settings/3.svg"
                    alt
                >
                <p>
                    If you'd like to learn more about account settings, you can read about it in the
                    <a>user guide</a>
                    .
                </p>
            </div>
        </div>

    </div>
</div>
