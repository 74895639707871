import { Component, Input, OnInit } from '@angular/core';
declare var jQuery: any;
declare function initModals(): any;

@Component({
  selector: 'app-page-change-cover-modal',
  templateUrl: './page-change-cover-modal.component.html',
  styleUrls: ['./page-change-cover-modal.component.scss'],
})
export class PageChangeCoverModalComponent implements OnInit {
  @Input() id;
  idPage;
  constructor() {}

  ngOnInit(): void {
    this.loadModal();
  }
  loadModal() {
    initModals();
  }
}
