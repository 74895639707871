import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  AfterViewInit,
} from '@angular/core';
@Component({
  selector: 'app-page-photo-lightbox',
  templateUrl: './page-photo-lightbox.component.html',
  styleUrls: ['./page-photo-lightbox.component.scss'],
})
export class PagePhotoLightboxComponent implements OnInit {
  // data Posts from PagesPhotosComponent
  @Input() dataImg;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }
}
