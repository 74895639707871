import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  AfterViewInit,
} from '@angular/core';
import { FeedService } from 'src/app/services/feed.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PagesService } from 'src/app/services/pages.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Notification } from '../../../model/notificationModel';
import { Router, ActivatedRoute } from '@angular/router';

import * as enviroment from '../../../../../environments/environment';
import { GroupService } from 'src/app/services/group/group.service';
declare var jQuery: any;
declare var $: any;
declare var window: any;

declare function initDropdowns(): any;
declare function initModals(): any;

@Component({
  selector: 'app-page-photo-lightbox-container',
  templateUrl: './page-photo-lightbox-container.component.html',
  styleUrls: ['./page-photo-lightbox-container.component.scss'],
})
export class PagePhotoLightboxContainerComponent implements OnInit {
  @Input() dataPost;
  @Input() indexImg;

  // isActive when click in Images from PagesPhotosComponent
  @Input() isActivePhoto;

  // dataUserLogin from PagesPhotosComponent
  @Input() InfoUser;

  @Output() closeModal = new EventEmitter<boolean>();
  showChatMobile = false;
  constructor(
    private profileService: ProfileService,
    private pageService: PagesService,
    private feedService: FeedService,
    private notifiService: NotificationService,
    private groupService: GroupService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.enviroment = enviroment.environment.apiUrl;
  }
  ngAfterViewInit(): void {
    initDropdowns();
    this.initChangeTexts();
    this.initLoading();
    setTimeout(() => {
      console.log(this.indexImg);
      this.getCommentFile();
      this.slideShowImg(this.indexImg);
    }, 600);
  }

  enviroment;
  imgDefault: string = 'https://via.placeholder.com/1600x900';

  // filter get comments
  skip;
  limit;

  // filter get replys of comments
  skipReply;
  limitReply;

  user;
  token;
  environment;
  notifiModel;

  // filter get post

  // filter get comment
  skipCmt;
  limitCmt;

  isLoading = false;
  ngOnInit(): void {
    // model of post FanPage
    this.skip = 0;
    this.limit = 3;
    this.notifiModel = new Notification('', '', '');
    // model of post user
    this.skipReply = 0;
    this.limitReply = 3;
    this.skipCmt = 0;
    this.limitCmt = 3;
    this.getUser();
    this.pageService.selectSubject.subscribe((result) => {
      this.dataPost.dataCommentsFile = [];
      this.dataPost = result;
      this.initLoading();
      setTimeout(() => {
        this.getCommentFile();
        this.slideShowImg(this.indexImg);
        console.log(this.indexImg);

      }, 600);
    });
  }

  // ngOnChanges() {
  //   this.initLoading();
  //   // filter get comments
  //   this.skip = 0;
  //   this.limit = 5;
  //   // filter get replys of comments
  //   this.limitReply = 1;
  //   this.skipReply = 0;
  //   this.initGetPost(this.dataPost);
  // }

  initLoading() {
    this.isLoading = false;
    setTimeout(() => {
      this.isLoading = true;
    }, 600);
  }

  //#region FUNCTION search user of comment
  arrUser = [];
  checkKeySearch;
  arrName = [];
  searchUser(username) {
    if (username != undefined || username != null) {
      (function ($) {
        $(document).ready(function () {
          $('.tag-fr').focusout(function () {
            var element = $(this);
            if (!element.text().replace(' ', '').length) {
              element.empty();
            }
          });
        });
      })(jQuery);
      var start = /@/gi; // @ Match
      var word = /@(\w+)/gi; //@abc Match
      if (username.indexOf('@') > -1) {
        var go = username.match(start); //Content Matching @
        this.arrName = username.match(word); //Content Matching @abc
        if (go.length > 0) {
          if (this.arrName) {
            let count = 0;
            if (this.arrName.length > 1) {
              count = this.arrName.length - 1;
            }
            let usernameFillter = this.arrName[count].toString().slice(1);
            this.pageService
              .searchUser({ search: usernameFillter })
              .subscribe((arrUser) => {
                if (arrUser.status == 'success') {
                  this.arrUser = arrUser.data;
                }
              });
          } else {
            this.arrUser = [];
          }
        }
      }
    }
  }
  //#endregion

  //#region CREATE notification when user comment, reply, like post
  // type for user/post
  // 0: user
  // 1: page
  createNotification(
    notifiedUser,
    notifiedPage,
    notificationType,
    notificationContent,
    notificationPostId,
    notificationReaded
  ) {
    if (notifiedUser != this.user._id) {
      let reqNotification = {
        notifiedUser: notifiedUser,
        notifiedPage: notifiedPage,
        notificationType: notificationType,
        notificationContent: notificationContent,
        notificationPostId: notificationPostId,
        notificationReaded: notificationReaded,
      };

      this.notifiService
        .createNotification(reqNotification)
        .subscribe((resultNotification) => {
          if (resultNotification.status == 'success') {
          }
        });
    }
  }
  //#endregion

  //#region GET info user
  getUser() {
    this.token = localStorage.getItem('token');
    if (this.token) {
      this.profileService.getProfile().subscribe((user) => {
        if (user.status == 'success') {
          this.user = user['data'];
        }
      });
    }
  }
  //#endregion

  //#region ---------------------------- Post of User/Page--------------------------

  //#region FUNCTION GET arr posts of User/Page
  initGetPost(dataPost) {
    if (dataPost) {
      let filter = {
        limit: this.limitCmt,
        skip: this.skipCmt,
      };
      let filterView = {
        isActive: false,
        isViewMore: false,
      };
      if (dataPost.type == 1) {
        this.getLikeByPostIdPage(dataPost);
      }
      if (dataPost.type == 0) {
        this.getLikeByPostIdUser(dataPost);
      }
      if (dataPost.type == 2) {
        this.getLikeByPostIdGroup(dataPost);
      }
      dataPost.isLiked = false;
      dataPost.arrLike = [];
      dataPost.commentTagUser = dataPost.commentTagUser || [];
      // get comment
      dataPost.filterView = {
        isActive: filterView.isActive,
        isViewMore: filterView.isViewMore,
      };
      dataPost.filterComment = { skip: filter.skip, limit: filter.limit };
      this.getComments(dataPost, dataPost.filterComment);
      dataPost.postShare = dataPost.postShare || [];
      return dataPost;
    }
    return dataPost;
  }
  //#endregion

  //#region FUNCTION Get like post of User/Page
  getLikeByPostIdUser(post) {
    this.feedService
      .getLikeByFileId(post.postImg[this.indexImg]._id)
      .subscribe((result) => {
        if (result.status == 'success') {
          post.arrLike = [];
          post.arrLike = result.data;
          this.checkLikePostUserLogin(post);
        }
      });
  }
  getLikeByPostIdPage(post) {
    this.pageService.getLikeByPostId(post._id).subscribe((result) => {
      if (result.status == 'success') {
        post.arrLike = [];
        post.arrLike = result.data;
        this.checkLikePostUserLogin(post);
      }
    });
  }
  getLikeByPostIdGroup(post) {
    this.groupService.getLikesByPostId(post._id).subscribe((result) => {
      if (result.status == 'success') {
        post.arrLike = [];
        post.arrLike = result.data;
        this.checkLikePostUserLogin(post);
      }
    });
  }

  checkLikePostUserLogin(post) {
    let checkLikePost = post.arrLike.find(
      (item) => item.userId._id === this.user._id
    );
    post.isLiked = checkLikePost ? true : false;
  }
  //#endregion

  //#region FU3NCTION Get comment of User/Page
  getComments(post, filterComment) {
    let pageId;
    pageId = post.postPageId ? post.postPageId._id : null;
    let requestComment = {
      postId: post._id,
      skip: filterComment.skip,
      limit: filterComment.limit,
      fileId: this.dataPost.postImg[this.indexImg]._id,
      pageId: pageId,
    };
    post.arrComment = [];
    post.countComment = post.countComment || 0;
    post.commentTagUser = post.commentTagUser || [];
    let filterReplies = {
      limit: this.limitReply,
      skip: this.skipReply,
    };
    let filterView = {
      isActive: false,
      isViewMore: false,
    };
    if (post.type == 0) {
      this.getCommentsUser(requestComment, post, filterReplies, filterView);
      return;
    }
    if (post.type == 1) {
      this.getCommentsPage(requestComment, post, filterReplies, filterView);
      return;
    }
    if (post.type == 2) {
      this.getCommentsGroup(requestComment, post, filterReplies, filterView);
      return;
    }
  }
  getCommentsUser(requestComment, post, filterReplies, filterView) {
    this.profileService
      .getCommentByFile(requestComment)
      .subscribe((dataComment) => {
        if (dataComment.status == 'success') {
          this.initComment(dataComment, post, filterReplies, filterView);
        }
      });
  }
  getCommentsPage(requestComment, post, filterReplies, filterView) {
    this.pageService
      .getCommentPostInPage(requestComment)
      .subscribe((result) => {
        if (result.status == 'success') {
          this.initComment(result, post, filterReplies, filterView);
        }
      });
  }

  getCommentsGroup(requestComment, post, filterReplies, filterView) {
    this.groupService.getComments(requestComment).subscribe((result) => {
      if (result.status == 'success') {
        this.initComment(result, post, filterReplies, filterView);
      }
    });
  }

  initComment(result, post, filterReplies, filterView) {
    post.countComment = result.count;
    post.arrComment = result.data.concat(post.arrComment);
    if (post.arrComment.length > 0) {
      if (post.countComment > post.filterComment.skip) {
        post.filterView.isActive = true;
        post.filterView.isViewMore = true;
      }
      for (let comment of post.arrComment) {
        //filter get replies from comment Id
        comment.filterReplies = {
          skip: filterReplies.skip,
          limit: filterReplies.limit,
        };
        comment.filterView = {
          isActive: filterView.isActive,
          isViewMore: filterView.isViewMore,
        };
        comment.arrReplies = comment.arrReplies || [];
        comment.countReplies = comment.countReplies || 0;
        comment.isLiked = false;
        comment.arrLike = comment.arrLike || [];
        if (post.type == 0) {
          this.getReplyByCmtIdUser(comment, comment.filterReplies);
          this.getLikeByCommentIdUser(comment);
          this.checkCommentForUser(comment);
          return;
        }
        if (post.type == 1) {
          this.getReplyByCmtIdPage(comment, comment.filterReplies);
          this.getLikeByCommentIdPage(comment);
          this.checkCommentForUser(comment);
          return;
        }
        if (post.type == 2) {
          this.getReplyByCmtIGroup(comment, comment.filterReplies);
          this.getLikeByCommentIdPage(comment);
          this.checkCommentForUser(comment);
          return;
        }
      }
    }
  }
  checkCommentForUser(comment) {
    comment.isUser = comment.userId._id == this.InfoUser._id ? true : false;
  }
  //#region FUNCTION Get replies of comment for user
  getReplyByCmtIdUser(comment, filter) {
    let request = {
      commentId: comment._id,
      skip: filter.skip,
      limit: filter.limit,
    };
    this.feedService.getReplyUserByCmtId(request).subscribe((reply) => {
      if (reply.status == 'success') {
        comment.arrReplies = [];
        comment.countReply = reply.count;
        let arrReplyNew = reply.data;
        comment.arrReplies = arrReplyNew.concat(comment.arrReplies);
        this.getLikeRepliesUser(comment);
        if (comment.arrReplies.length > 0) {
          if (comment.countReply > comment.filterReplies.limit) {
            comment.filterView.isActive = true;
            comment.filterView.isViewMore = true;
          }
        }
      }
    });
  }
  getLikeRepliesUser(comment) {
    for (let replies of comment.arrReplies) {
      replies.isLikeReplies = false;
      replies.arrLikeReplies = replies.arrLikeReplies || [];
      this.getLikeByReplyIdUser(replies);
    }
  }
  //#endregion
  //#region FUNCTION GET replies of post in Fanpages
  getReplyByCmtIdPage(comment, filter) {
    let requestReply = {
      id: comment._id,
      skip: filter.skipReply,
      limit: filter.limitReply,
    };
    this.pageService
      .getReplyByCommentId(requestReply)
      .subscribe((dataReplies) => {
        if (dataReplies.status == 'success') {
          let arrReply = dataReplies.data;
          if (arrReply.length > 0) {
            comment.countReply = dataReplies.count;
            comment.arrReplies = arrReply.concat(comment.arrReplies);
            this.getLikeByRepliesIdPage(comment);
            comment.arrReplies = this.getUnique(comment.arrReplies, '_id');
            if (comment.countReply > comment.filterReplies.limit) {
              comment.filterView.isActive = true;
              comment.filterView.isViewMore = true;
            }
          }
        }
      });
  }
  getLikeByRepliesIdPage(comment) {
    for (let replies of comment.arrReplies) {
      replies.isLikeReplies = false;
      replies.arrLikeReplies = replies.arrLikeReplies || [];
      this.pageService.getLikeByReplyId(replies._id).subscribe((result) => {
        if (result.status == 'success') {
          replies.arrLikeReplies = result.data;
          this.checkLikeReply(replies);
        }
      });
    }
  }
  checkLikeReply(replies) {
    replies.isUser = replies.userId._id == this.user._id ? true : false;
    let checkLikeReplies = replies.arrLikeReplies.find(
      (item) => item.userId._id === this.user._id
    );
    replies.isLikeReplies = checkLikeReplies ? true : false;
  }
  // check array duplicate
  getUnique(arr, comp) {
    const unique = arr
      .map((e) => e[comp])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);
    return unique;
  }
  //#endregion
  //#region FUNCTION Get replies of comment in group
  getReplyByCmtIGroup(comment, filter) {
    let requestReply = {
      id: comment._id,
      skip: filter.skipReply,
      limit: filter.limitReply,
    };
    this.groupService.getReplysByCmtId(requestReply).subscribe((dataReply) => {
      if (dataReply.status == 'success') {
        let arrRepliesNew = dataReply.data;
        if (arrRepliesNew.length > 0) {
          comment.countReply = dataReply.count;
          comment.arrReplies = arrRepliesNew.concat(comment.arrReplies);
          comment.arrReply = this.getUnique(comment.arrReply, '_id');
          this.getLikeByRepliesIdGroup(comment);
          if (comment.countReply > comment.filterReplies.limit) {
            comment.filterView.isActive = true;
            comment.filterView.isViewMore = true;
          }
        }
      }
    });
  }
  getLikeByRepliesIdGroup(comment) {
    for (let replies of comment.arrReplies) {
      replies.isLikeReplies = false;
      replies.arrLikeReplies = replies.arrLikeReplies || [];
      this.groupService.getLikeByReplyId(replies._id).subscribe((result) => {
        if (result.status == 'success') {
          let arrReplyNew = result.data;
          if (arrReplyNew.length > 0) {
            replies.arrLikeReplies = arrReplyNew;
            this.checkLikeReply(replies);
          }
        }
      });
    }
  }
  //#endregion
  //#endregion

  //#region FUNCTION Get more comment of User/Page
  viewMoreComment(post) {
    post.filterComment.skip = Number(
      post.filterComment.skip + post.filterComment.limit
    );
    this.getComments(post, post.filterComment);
  }
  //#endregion

  //#region FUNCTION Create like post of User/Page
  createLikePost(post) {
    let pageId, groupId;
    if (post.postPageId) {
      pageId = post.postPageId._id;
    }
    if (post.postGroupId) {
      groupId = post.postGroupId._id;
    }
    let reqLikePost = {
      postId: post._id,
      pageId: pageId,
      groupId: groupId,
      fileId: this.dataPost.postImg[this.indexImg]._id,
      type: 0,
    };
    if (post.type == 0) {
      this.createLikePostUser(reqLikePost, post);
      return;
    }
    if (post.type == 1) {
      this.createLikePostPage(reqLikePost, post);
      return;
    }
    if (post.type == 2) {
      this.createLikePostGroup(reqLikePost, post);
      return;
    }
  }

  createLikePostUser(reqLikePost, post) {
    this.profileService.createLikeByFileId(reqLikePost).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByPostIdUser(post);
        this.notifiModel.notificationType = '0';
        this.notifiModel.notificationConent = 'liked on';
        this.createNotification(
          post.postUserId._id,
          undefined,
          0,
          this.notifiModel,
          post._id,
          false
        );
      }
    });
  }
  createLikePostPage(reqLikePost, post) {
    this.pageService.createLikePagePost(reqLikePost).subscribe((like) => {
      if (like.status == 'success') {
        this.getLikeByPostIdPage(post);
      }
    });
  }
  createLikePostGroup(reqLikePost, post) {
    this.groupService.createLike(reqLikePost).subscribe((like) => {
      if (like.status == 'success') {
        this.getLikeByPostIdGroup(post);
      }
    });
  }

  //#region FUNCTION unlike post of User/page
  unLikePost(post) {
    let requestPost = {
      fileId: post.postImg[this.indexImg]._id,
    };
    if (post.type == 0) {
      this.unlikePostUser(requestPost);
      return;
    }
    if (post.type == 1) {
      this.unlikePostPage(requestPost, post);
      return;
    }
    if (post.type == 2) {
      this.unLikePostGroup(requestPost, post);
      return;
    }
  }
  unlikePostUser(requestPost) {
    this.feedService
      .deleteLikePostByFileId(requestPost.fileId)
      .subscribe((result) => {
        if (result.status == 'success') {
          this.dataPost.isLiked = false;
          this.getLikeByPostIdUser(this.dataPost);
        }
      });
  }
  unlikePostPage(requestPost, post) {
    this.pageService.unLikePagePost(requestPost).subscribe((result) => {
      if (result.status == 'success') {
        post.isLiked = false;
        this.getLikeByPostIdPage(post);
      }
    });
  }
  unLikePostGroup(requestPost, post) {
    this.groupService.unlikePost(requestPost).subscribe((result) => {
      if (result.status == 'success') {
        post.isLiked = false;
        this.getLikeByPostIdGroup(post);
      }
    });
  }
  //#endregion

  //#endregion

  //#region Detect files imgage in comment of User/Page
  detectFile(event, post) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        if (file.type.indexOf('image') > -1) {
          post.format = 'image';
        } else if (file.type.indexOf('video') > -1) {
          post.format = 'video';
        }
        reader.onload = (e: any) => {
          document.getElementById(`cmt-image`).style.display = 'block';
          if (file.type.indexOf('image') > -1) {
            post.contentImg = e.target.result;
          } else if (file.type.indexOf('video') > -1) {
            post.contentVideo = e.target.result;
          }
        };
      }
    }
  }
  //#endregion

  //#region FUNCTION Create comment of User/ Page
  tagUser(user, post) {
    let fullname = user.firstname + user.lastname;
    post.commentTagUser.push(user._id);
    let txtContent = document.getElementById(`tag-friends`).innerHTML;
    let count = 0;
    if (this.repeatTag(this.arrName) > 0) {
      let lengthLast = this.arrName[this.arrName.length - 1].length;
      txtContent = txtContent.slice(0, txtContent.length - lengthLast);
    } else {
      if (this.arrName.length > 1) {
        count = this.arrName.length - 1;
      }
      let indexCount = txtContent.indexOf(this.arrName[count], 0);
      txtContent = txtContent.slice(0, indexCount);
    }
    document.getElementById(`tag-friends`).innerHTML = txtContent;
    this.changeCommentContent(fullname);
    this.arrUser = [];
  }
  repeatTag(arr) {
    if (arr.length > 1) {
      let count = 0;
      for (let i = 0; i < arr.length; i++) {
        for (let j = 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            return (count = count + 1);
          }
        }
      }
    }
  }
  model;
  arrSpan = [];
  word;
  changeCommentContent(commentContent) {
    (function ($) {
      $(document).ready(function () {
        $(`#tag-friends`).append(
          `<a contenteditable="false" style="margin-left: 3px; color: #000;padding: 5px;background: #6ba4e9;width: fit-content;border-radius: 3px;font-weight: 400 !important;font-size: 0.95rem;"> ${commentContent}</a>&nbsp`
        );
      });
    })(jQuery);
  }

  createComment(post) {
    let commentContentUser = document.getElementById(`tag-friends`).innerHTML;
    let pageId, groupId;
    if (post.postPageId) {
      pageId = post.postPageId._id;
    }
    if (post.postGroupId) {
      groupId = post.postGroupId._id;
    }
    let requestComment = {
      pageId: pageId,
      postId: post._id,
      fileId: this.dataPost.postImg[this.indexImg]._id,
      groupId: groupId,
      commentContent: commentContentUser,
      commentImg: post.contentImg,
      commentTag: post.commentTagUser,
      commentVideo: post.contentVideo,
    };
    if (post.type == 0) {
      this.createCmtPostUser(requestComment, post);
      return;
    }
    if (post.type == 1) {
      this.createCmtPostPage(requestComment, post);
      return;
    }
    if (post.type == 2) {
      this.createCmtPostGroup(requestComment, post);
      return;
    }
  }

  //#region FUNCTION create/get comment in post of User/ Page/ Group
  createCmtPostUser(requestComment, post) {
    this.profileService.createComment(requestComment).subscribe((comment) => {
      if (comment.status == 'success') {
        let commentNew = comment.data;
        this.getCommentByIdUser(commentNew._id, post);
        document.getElementById(`tag-friends`).innerHTML = '';

        // create notification
        this.notifiModel.notificationType = '2';
        this.notifiModel.notificationConent = 'commented on';
        post.contentImg = undefined;
        post.contentVideo = undefined;
        this.createNotification(
          post.postUserId._id,
          undefined,
          0,
          this.notifiModel,
          post._id,
          false
        );
        commentNew.userId = post.postUserId;
        post.dataCommentsFile.push(commentNew)
      }
    });
  }
  createCmtPostPage(requestComment, post) {
    this.pageService
      .createCommentPagePost(requestComment)
      .subscribe((result) => {
        let cmtNew;
        if (result.status == 'success') {
          cmtNew = result.data;
          this.getCommentByIdPage(cmtNew._id, post);
          document.getElementById(`tag-friends`).innerHTML = '';
          post.contentImg = undefined;
          post.contentVideo = undefined;
          cmtNew.userId = post.postUserId;
          post.dataCommentsFile.push(cmtNew)
        }
      });
  }
  createCmtPostGroup(requestComment, post) {
    this.groupService.createComment(requestComment).subscribe((result) => {
      if (result.status == 'success') {
        let commentNew = result.data;
        this.getCommentByIdGroup(commentNew._id, post);
        document.getElementById(`tag-friends`).innerHTML = '';
        post.contentImg = undefined;
        post.contentVideo = undefined;
        commentNew.userId = post.postUserId;
        post.dataCommentsFile.push(commentNew)
      }
    });
  }
  getCommentByIdUser(commentId, post) {
    this.feedService.getCommentById(commentId).subscribe((result) => {
      if (result.status == 'success') {
        let commentNew = result.data;
        if (commentNew.length > 0) {
          this.insertComment(commentNew, post);
        }
      }
    });
  }
  getCommentByIdPage(commentId, post) {
    this.pageService.getCommentById(commentId).subscribe((result) => {
      if (result.status == 'success') {
        let commnetNews = result.data;
        this.insertComment(commnetNews, post);
      }
    });
  }
  getCommentByIdGroup(commentId, post) {
    this.groupService.getCommentById(commentId).subscribe((result) => {
      if (result.status == 'success') {
        let commnetNews = result.data;
        this.insertComment(commnetNews, post);
      }
    });
  }
  //#endregion
  //#region FUNCTION insert comment in post of User/ Page/ Group
  insertComment(comment, post) {
    let filters = {
      limit: this.limitReply,
      skip: this.skipReply,
    };
    let filterView = {
      isActive: false,
      isViewMore: false,
    };
    comment.arrLike = comment.arrLike || [];
    comment.isLiked = false;
    comment.filterReplies = filters;
    comment.arrReplies = comment.arrReplies || [];
    comment.filterView = filterView;
    comment.countReply = comment.countReply || 0;
    post.arrComment = post.arrComment || [];
    post.arrComment.push(comment);
    post.countComment = Number(post.countComment + 1);
  }

  //#endregion
  //#endregion

  //#region FUNCTION delete comment in User/page
  deleteComment(post, comment, indexComment) {
    if (post.type == 0) {
      this.deleteCommentUser(post, comment, indexComment);
      return;
    }
    if (post.type == 1) {
      this.deleteCommentPage(post, comment, indexComment);
      return;
    }
    if (post.type == 2) {
      this.deleteCommentGroup(post, comment, indexComment);
      return;
    }
  }
  deleteCommentUser(post, comment, indexComment) {
    this.profileService
      .deleteComment(comment._id)
      .subscribe((deleteComment) => {
        if (deleteComment.status == 'success') {
          post.arrComment.splice(indexComment, 1);
        }
      });
  }
  deleteCommentPage(post, comment, indexComment) {
    this.pageService
      .deleteCommentPagePost(comment._id)
      .subscribe((deleteComment) => {
        if (deleteComment.status == 'success') {
          post.arrComment.splice(indexComment, 1);
        }
      });
  }
  deleteCommentGroup(post, comment, indexComment) {
    this.groupService.deleteComment(comment._id).subscribe((deleteComment) => {
      if (deleteComment.status == 'success') {
        post.arrComment.splice(indexComment, 1);
      }
    });
  }

  //#endregion

  //#region FUNCTION delete replies User/page
  deleteReplies(post, comment, reply, indexReplies) {
    if (post.type == 0) {
      this.deleteRepliesUser(comment, reply, indexReplies);
      return;
    }
    if (post.type == 1) {
      this.deleteRepliesPage(comment, reply, indexReplies);
      return;
    }
    if (post.type == 2) {
      this.deleteRepliesGroup(comment, reply, indexReplies);
      return;
    }
  }

  deleteRepliesUser(comment, reply, indexReplies) {
    this.profileService.deleteReplies(reply._id).subscribe((replies) => {
      if (replies.status == 'success') {
        comment.arrReplies.splice(indexReplies, 1);
      }
    });
  }
  deleteRepliesPage(comment, reply, indexReplies) {
    this.pageService.deleteReplyById(reply._id).subscribe((replies) => {
      if (replies.status == 'success') {
        comment.arrReplies.splice(indexReplies, 1);
      }
    });
  }
  deleteRepliesGroup(comment, reply, indexReplies) {
    this.groupService.deleteReplies(reply._id).subscribe((replies) => {
      if (replies.status == 'success') {
        comment.arrReplies.splice(indexReplies, 1);
      }
    });
  }

  //#endregion

  //#region FUNCTION Create like comment of User/Page
  createLikeComment(comment, post) {
    let reqLikeCmt = {
      commentId: comment._id,
      type: post.type,
    };
    if (post.type == 0) {
      this.createLikeCmtUser(post, reqLikeCmt, comment);
    } else if (post.type == 1) {
      this.createLikeCmtPage(reqLikeCmt, comment);
    } else if (post.type == 2) {
      this.createLikeCmtGroup(reqLikeCmt, comment);
    }
  }
  //#region FUNCTION create like comment of User
  createLikeCmtUser(post, reqLikeCmt, comment) {
    this.profileService.createLikeByFileId(reqLikeCmt).subscribe((like) => {
      if (like.status == 'success') {
        this.getLikeByCommentIdUser(comment);
        this.notifiModel.notificationType = '3';
        this.notifiModel.notificationConent = 'liked on';
        this.createNotification(
          post.postUserId._id,
          undefined,
          0,
          this.notifiModel,
          post._id,
          false
        );
      }
    });
  }
  getLikeByCommentIdUser(comment) {
    this.feedService.getLikeByCommentId(comment._id).subscribe((result) => {
      if (result.status == 'success') {
        comment.arrLike = [];
        comment.arrLike = result.data;
        this.checkLikeCmtUser(comment);
      }
    });
  }

  checkLikeCmtUser(comment) {
    let isLikeUser = comment.arrLike.find(
      (like) => like.userId._id === this.user._id
    );
    comment.isLiked = isLikeUser ? true : false;
  }

  //#endregion

  //#region FUNCTION create like cmt of Page
  createLikeCmtPage(reqLikeCmt, comment) {
    this.pageService.createLikeComment(reqLikeCmt).subscribe((likeCmt) => {
      if (likeCmt.status == 'success') {
        this.getLikeByCommentIdPage(comment);
        this.notifiModel.notificationType = '3';
        this.notifiModel.notificationConent = 'liked on';
      }
    });
  }

  getLikeByCommentIdPage(comment) {
    this.pageService.getLikeByCommentId(comment._id).subscribe((result) => {
      if (result.status == 'success') {
        comment.arrLike = [];
        comment.arrLike = result.data;
        this.checkLikeCmtUser(comment);
      }
    });
  }

  getLikeCommentByPage(comment) {
    this.getLikeByCommentIdPage(comment);
  }
  //#endregion

  //#region FUNCTION create like cmt of group
  createLikeCmtGroup(reqLikeCmt, comment) {
    this.groupService.createLike(reqLikeCmt).subscribe((resultLike) => {
      if (resultLike.status == 'success') {
        this.getLikeByCommentIdGroup(comment);
      }
    });
  }
  getLikeByCommentIdGroup(comment) {
    this.groupService.getLikeByCommentId(comment._id).subscribe((result) => {
      comment.arrLike = [];
      if (result.status == 'success') {
        comment.arrLike = result.data;
        this.checkLikeCmtUser(comment);
      }
    });
  }
  //#endregion

  //#endregion

  //#region FUNCTION Unlike comment of User/Page
  unlikeComment(comment, post) {
    let requsetUnlike = {
      commentId: comment._id,
    };
    if (post.type == 0) {
      this.feedService.deleteLikeComment(comment._id).subscribe((result) => {
        if (result.status == 'success') {
          this.getLikeByCommentIdUser(comment);
        }
      });
      return;
    }
    if (post.type == 1) {
      this.pageService.unLikeComment(requsetUnlike).subscribe((result) => {
        if (result.status == 'success') {
          this.getLikeCommentByPage(comment);
        }
      });
      return;
    }
    if (post.type == 2) {
      let unLike = {
        commentId: comment._id,
      };
      this.groupService.unlikeComment(unLike).subscribe((result) => {
        if (result.status == 'success') {
          this.getLikeByCommentIdGroup(comment);
        }
      });
    }
  }
  //#endregion

  //#region FUNCTION Click reply of comment for users
  clickReplyUser(username, comment, indexCommentUser) {
    document.getElementById(`tag-friends`).focus();
    this.dataPost.commentReplyByUser = comment;
    this.dataPost.indexCommentReplyByUser = indexCommentUser;
    this.dataPost.arrRepliesTemporary = [];
    this.dataPost.arrRepliesTemporary.push(username);

    let fullname = username.firstname + username.lastname;
    if (this.user._id != username._id) {
      this.changeCommentContent(fullname);
    }
  }
  clearReply(post) {
    post.commentReplyByUser = undefined;
    post.indexCommentReplyByUser = undefined;
    post.arrRepliesTemporary = [];
  }
  //#endregion

  //#region FUNCTION Create replies of User/Page
  createReplyUser(post) {
    let commentIdReplyByUser = post.commentReplyByUser._id;
    let replyContentUser = document.getElementById(`tag-friends`).innerHTML;
    let pageId, groupId;
    if (post.postPageId) {
      pageId = post.postPageId._id;
    }
    if (post.postGroupId) {
      groupId = post.postGroupId._id;
    }
    let requestReply = {
      pageId: pageId,
      postId: post._id,
      groupId: groupId,
      fileId: post.postImg[this.indexImg]._id,
      commentId: post.commentReplyByUser._id,
      replyContent: replyContentUser,
      replyImg: post.contentImg,
    };

    if (post.type == 0) {
      this.createRepliesUser(requestReply, post, commentIdReplyByUser);
      return;
    }

    if (post.type == 1) {
      this.createRepliesPage(requestReply, post);
      return;
    }
    if (post.type == 2) {
      this.createRepliesGroup(requestReply, post);
    }
  }

  createRepliesUser(requestReply, post, commentIdReplyByUser) {
    this.profileService.createReplyByFileId(requestReply).subscribe((reply) => {
      if (reply.status == 'success') {
        this.getRepliesByIdUser(reply.data, post.indexCommentReplyByUser);
        post.arrRepliesTemporary = [];
        // format div contentiditTable == ""
        document.getElementById(`tag-friends`).innerHTML = '';
        if (document.getElementById(`cmt-img`)) {
          document.getElementById(`cmt-img`).style.display = 'none';
        }
        post.indexCommentReplyByUser = undefined;
        post.contentImg = '';
        this.notifiModel.notificationType = '4';
        this.notifiModel.notificationConent = 'replied on';
        this.createNotification(
          commentIdReplyByUser,
          undefined,
          0,
          this.notifiModel,
          post._id,
          false
        );
      }
    });
  }
  createRepliesPage(requestReply, post) {
    this.pageService.createReplyComment(requestReply).subscribe((result) => {
      if (result.status == 'success') {
        post.arrRepliesTemporary = [];
        let replies = result.data;
        this.getRepliesByIdPage(replies, post);
        document.getElementById(`tag-friends`).innerHTML = '';
        post.contentImg = '';
        if (document.getElementById(`cmt-img`)) {
          document.getElementById(`cmt-img`).style.display = 'none';
        }
      }
    });
  }
  createRepliesGroup(requestReply, post) {
    this.groupService.createReply(requestReply).subscribe((result) => {
      if (result.status == 'success') {
        post.arrRepliesTemporary = [];
        let replies = result.data;
        this.getRepliesByIdGroup(replies, post);
        // format div contentiditTable == ""
        document.getElementById(`tag-friends`).innerHTML = '';
        post.contentImg = '';
        if (document.getElementById(`cmt-img`)) {
          document.getElementById(`cmt-img`).style.display = 'none';
        }
      }
    });
  }
  //#region FUNCTION replies for post in User/ Page/ Group
  getRepliesByIdUser(reply, indexCommentCurrent) {
    this.feedService.getReplyById(reply._id).subscribe((result) => {
      if (result.status == 'success') {
        let commentCurrent = this.dataPost.arrComment[indexCommentCurrent];
        commentCurrent.arrReplies = commentCurrent.arrReplies || [];
        commentCurrent.arrReplies.push(result.data);
        this.dataPost.indexCommentReplyByUser = undefined;
      }
    });
  }
  getRepliesByIdPage(replies, post) {
    this.pageService.getReplyById(replies._id).subscribe((dataReply) => {
      if (dataReply.status == 'success') {
        let commentCurrent = post.arrComment[post.indexCommentReplyByUser];
        commentCurrent.arrReplies = commentCurrent.arrReplies || [];
        commentCurrent.arrReplies.push(dataReply.data);
        post.countComment = Number(post.countComment + 1);
      }
    });
  }
  getRepliesByIdGroup(replies, post) {
    this.groupService.getReplyById(replies._id).subscribe((dataReply) => {
      if (dataReply.status == 'success') {
        let commentCurrent = post.arrComment[post.indexCommentReplyByUser];
        commentCurrent.arrReplies = commentCurrent.arrReplies || [];
        commentCurrent.arrReplies.push(dataReply.data);
        post.countComment = Number(post.countComment + 1);
      }
    });
  }
  //#endregion
  //#endregion

  //#region FUNCTION view more replies of comment User
  viewMoreRepliesBy(post, comment) {
    if (post.type == 0) {
      this.viewMoreRepliesUSer(comment);
      return;
    }
    if (post.type == 1) {
      this.viewMoresRepliesPage(comment);
      return;
    }
    if (post.type == 2) {
      this.viewMoresRepliesGroup(comment);
      return;
    }
  }
  viewMoreRepliesUSer(comment) {
    comment.filterReplies.skip =
      comment.filterReplies.skip + comment.filterReplies.limit;
    let request = {
      commentId: comment._id,
      skip: comment.filterReplies.skip,
      limit: comment.filterReplies.limit,
    };
    this.feedService.getReplyUserByCmtId(request).subscribe((reply) => {
      if (reply.status == 'success') {
        this.insertReplies(comment, reply);
      }
    });
  }
  viewMoresRepliesPage(comment) {
    comment.filterReplies.skip =
      comment.filterReplies.skip + comment.filterReplies.limit;
    let request = {
      id: comment._id,
      skip: comment.filterReplies.skip,
      limit: comment.filterReplies.limit,
    };
    this.pageService.getReplyByCommentId(request).subscribe((result) => {
      if (result.status == 'success') {
        this.insertReplies(comment, result);
      }
    });
  }
  viewMoresRepliesGroup(comment) {
    comment.filter.skipReply =
      comment.filter.skipReply + comment.filter.limitReply;
    let requestReply = {
      id: comment._id,
      limit: comment.filter.limitReply,
      skip: comment.filter.skipReply,
    };
    this.groupService.getReplysByCmtId(requestReply).subscribe((result) => {
      if (result.status == 'success') {
        this.insertReplies(comment, result);
      }
    });
  }

  insertReplies(comment, result) {
    comment.countReply = result.count;
    comment.arrReplies = result.data.concat(comment.arrReplies);
    comment.arrReplies.forEach((replies) => {
      this.checkRepliesUser(replies);
    });
  }
  checkRepliesUser(replies) {
    return (replies.isUser =
      replies.userId._id == this.user._id ? true : false);
  }

  //#endregion

  //#region FUNCTION Create like reply in User/ Page/ Group
  createLikeReplies(post, comment, replies) {
    let pageId, groupId;
    if (post.postPageId) {
      pageId = post.postPageId._id;
    }
    if (post.postGroupId) {
      groupId = post.postGroupId._id;
    }
    let reqLikeReplies = {
      replyId: replies._id,
      type: 2,
    };
    if (post.type == 0) {
      this.createLikeRepliesUser(reqLikeReplies, replies);
      return;
    }
    if (post.type == 1) {
      this.createLikeRepliesPage(reqLikeReplies, replies);
      return;
    }
    if (post.type == 2) {
      this.createLikeRepliesGroup(reqLikeReplies, replies);
      return;
    }
  }
  //#region FUNCTION create like replies in comment in User/ Page/ Group
  createLikeRepliesUser(reqLikeReplies, replies) {
    this.profileService.createLike(reqLikeReplies).subscribe((likeReply) => {
      if (likeReply.status == 'success') {
        this.getLikeByReplyIdUser(replies);
      }
    });
  }
  createLikeRepliesPage(reqLikeReplies, replies) {
    this.pageService.createLikeReply(reqLikeReplies).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByReplyIdPage(replies);
      }
    });
  }
  createLikeRepliesGroup(reqLikeReplies, replies) {
    this.groupService.createLike(reqLikeReplies).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByReplyIdGroup(replies);
      }
    });
  }
  getLikeByReplyIdUser(replies) {
    this.feedService.getLikeByReplyId(replies._id).subscribe((result) => {
      if (result.status == 'success') {
        replies.arrLikeReplies = result.data;
        this.checkLikeReplyUser(replies);
      }
    });
  }
  getLikeByReplyIdPage(replies) {
    this.pageService.getLikeByReplyId(replies._id).subscribe((result) => {
      if (result.status == 'success') {
        replies.arrLikeReplies = result.data;
        this.checkLikeReply(replies);
      }
    });
  }
  getLikeByReplyIdGroup(replies) {
    this.groupService.getLikeByReplyId(replies._id).subscribe((result) => {
      if (result.status == 'success') {
        replies.arrLikeReplies = result.data;
        this.checkLikeReply(replies);
      }
    });
  }
  checkLikeReplyUser(replies) {
    let checkLikeReplies = replies.arrLikeReplies.find(
      (item) => item.userId._id === this.user._id
    );
    replies.isLikeReplies = checkLikeReplies ? true : false;
    this.checkRepliesUser(replies);
  }

  //#region FUNCTION Unlike post of User/Page
  unLikeReplies(post, replies) {
    let reqUnlike = {
      replyId: replies._id,
    };
    if (post.type == 0) {
      this.unlikeRepliesUser(reqUnlike, replies);
    } else {
      this.unlikeRepliesPage(reqUnlike, replies);
    }
  }

  unlikeRepliesUser(reqUnlike, replies) {
    this.feedService.deleteLikeReply(replies._id).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByReplyIdUser(replies);
      }
    });
  }
  unlikeRepliesPage(reqUnlike, replies) {
    this.pageService.unLikeReply(reqUnlike).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByReplyIdUser(replies);
      }
    });
  }
  //#endregion

  //#endregion

  //#endregion

  //#region FUNCTION sort object in Array
  sortFunction(a, b) {
    var dateA = new Date(a.createdAt).getTime();
    var dateB = new Date(b.createdAt).getTime();
    return dateB > dateA ? 1 : -1;
  }
  //#endregion

  //#region FUNCTION share post for user login
  sharePost(dataPost) {
    initModals();
    this.feedService.clickSharePost({ status: 'success', data: dataPost });
  }
  //#endregion

  //#region FUNCTION change text in div attribute contenteditTable
  initChangeTexts() {
    (function ($) {
      $(document).on('paste', '[contenteditable]', function (e) {
        var content;
        e.preventDefault();

        if (window.clipboardData) {
          content = window.clipboardData.getData('Text');
          if (window.getSelection) {
            var selObj = window.getSelection();
            var selRange = selObj.getRangeAt(0);
            selRange.deleteContents();
            selRange.insertNode(document.createTextNode(content));
          }
        } else if (e.originalEvent.clipboardData) {
          content = (e.originalEvent || e).clipboardData.getData('text/plain');
          document.execCommand('insertText', false, content);
        }
      });
    })(jQuery);
  }
  //#endregion

  //#region Delete image of comment in post
  closeImg(post) {
    document.getElementById(`cmt-image`).style.display = 'none';
    if (post.format === 'image') {
      post.contentImg = undefined;
      post.format = undefined;
      return;
    }
    if (post.format === 'video') {
      post.contentImg = undefined;
      post.format = undefined;
      return;
    }
  }
  //#endregion

  //#endregion
  //#region Close popup post
  closePopup() {
    $('.one-time').slick('unslick');
    this.isActivePhoto = false;
    this.closeModal.emit(false);
  }

  //#endregion

  //#region SLICK SLIDE
  slideShowImg(indexImg) {
    $('.one-time').slick({
      centerPadding: '60px',
      speed: 500,
      fade: true,
      cssEase: 'linear',
      Accessibility: true,
      adaptiveHeight: false,
      centerMode: true,
      arrows: true,
      infinite: false,
      initialSlide: indexImg,
      prevArrow: $('.prev'),
      nextArrow: $('.next'),
    });
  }
  prev() {
    if (this.indexImg == 0) {
      return 0;
    } else {
      this.indexImg = this.indexImg - 1;
      this.getCommentFile();
    }
  }
  next() {
    if (this.indexImg == this.dataPost.postImg.length - 1) {
      return 0;
    } else {
      this.indexImg = this.indexImg + 1;
      this.getCommentFile();
    }
  }
  getCommentFile() {
    let data = {
      fileId: this.dataPost.postImg[this.indexImg]._id
        ? this.dataPost.postImg[this.indexImg]._id
        : '',
    };
    this.profileService.getCommentByFile(data).subscribe((result) => {
      this.dataPost.dataCommentsFile = [];
      this.dataPost.dataCommentsFile = result.data;
      //this.initGetPost(this.dataPost);
    });
  }
  gotoProfile(id) {
    this.router.navigate(['profile', id]);
  }
  gotoPage(pageId) {
    this.router.navigate(['pages', pageId]);
  }
}
