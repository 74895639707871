import { Component, OnInit, Input } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-photos-widget',
  templateUrl: './photos-widget.component.html',
  styleUrls: ['./photos-widget.component.scss'],
})
export class PhotosWidgetComponent implements OnInit {
  @Input() photos;
  constructor(private profileService: ProfileService) { }

  ngOnInit(): void { }

  clickSeeAll() {
    this.profileService.clickSeeAll({ status: 'success' });
  }
}
