import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-messages-dropdown",
	templateUrl: "./messages-dropdown.component.html",
	styleUrls: ["./messages-dropdown.component.scss"],
})
export class MessagesDropdownComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
