import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  public selectSubject: Subject<any> = new Subject();
  constructor(private http: HttpClient) { }
  selectSubjectNext(data) {
    this.selectSubject.next(data);
  }
  //#region CRUD fanpages
  checkPageName(pageName): Observable<any> {
    return this.http.post(`page/checkPageName`, pageName);
  }

  createPage(obj): Observable<any> {
    return this.http.post(`page/create`, obj);
  }
  // user admin edit FanPages
  editPage(obj): Observable<any> {
    return this.http.post(`page/update`, obj);
  }

  // get data FanPages for user create
  getPageByUserId(obj): Observable<any> {
    return this.http.post(`page/getPageByUserId`, obj);
  }

  getPageAdmin(): Observable<any> {
    return this.http.get(`page/getPAgeAdmin`);
  }

  // get list FanPages
  getAllPage(): Observable<any> {
    return this.http.get(`page/get`);
  }

  getRelatedPages(pageCategory): Observable<any> {
    return this.http.post(`page/getRelated`, pageCategory);
  }

  // search FanPages by user
  searchPageByName(pageName): Observable<any> {
    return this.http.post(`page/searchPageByName`, pageName);
  }

  // create admin page after create FanPages by user
  createAdminPage(obj): Observable<any> {
    return this.http.post(`page-admin/create`, obj);
  }

  // get list admin of FanPages
  getAdminByUserId(): Observable<any> {
    return this.http.get(`page-admin/getAdminByUserId`);
  }

  getAdminByPageId(groupId): Observable<any> {
    return this.http.get(`page-admin/getAdmin/${groupId}`);
  }

  // get data FanPages By id
  getPageById(pageId): Observable<any> {
    return this.http.get(`page/getPageById/${pageId}`);
  }

  // change cover page by user Admin
  updatePageCover(obj): Observable<any> {
    return this.http.post(`page/uploadPageCover`, obj);
  }
  // change avatar page by user Admin
  updatePageAvatar(obj): Observable<any> {
    return this.http.post(`page/uploaPagedAvatar`, obj);
  }
  //#endregion

  //#region CRUD like page
  createLikePage(obj): Observable<any> {
    return this.http.post(`page-like/create`, obj);
  }
  getLikeByPageId(pageId): Observable<any> {
    return this.http.get(`page-like/getLikeForPage/${pageId}`);
  }
  deleteLikeForPage(obj): Observable<any> {
    return this.http.post(`page-like/delete`, obj);
  }
  checkLikePageForUser(pageId): Observable<any> {
    return this.http.post(`page-like/checkLikePage`, pageId);
  }
  //#endregion

  //#region CRUD follow page
  createFollowPage(obj): Observable<any> {
    return this.http.post(`page-follow/create`, obj);
  }
  getFollowerByPageId(pageId): Observable<any> {
    return this.http.get(`page-follow/getFollowPage/${pageId}`);
  }
  checkFollower(obj): Observable<any> {
    return this.http.post(`page-follow/checkFollower`, obj);
  }
  //#endregion

  //#region CRUD post post in FanPages
  createPost(obj): Observable<any> {
    return this.http.post(`page-post/create`, obj);
  }
  getAllPostByPage(id): Observable<any> {
    return this.http.get(`page-post/getAllPost/${id}`);
  }

  getMorePost(obj): Observable<any> {
    return this.http.post(`page-post/getMore`, obj);
  }

  getPostById(postId): Observable<any> {
    return this.http.get(`page-post/getPostByPostId/${postId}`);
  }

  getPostPage(obj): Observable<any> {
    return this.http.post(`page-post/getPost`, obj);
  }
  //#endregion

  //#region Callback changes function() using service
  // load page when change components orthers
  isShowPage = new BehaviorSubject<any>([]);
  loadingPage = this.isShowPage.asObservable();
  changeSuccessCreatePage(loadingPage) {
    this.isShowPage.next(loadingPage);
  }
  // when submit change post post
  isSubmit = new BehaviorSubject<any>([]);
  loadPost = this.isSubmit.asObservable();
  changeListPost(loadPost) {
    this.isSubmit.next(loadPost);
  }
  // when submit change avatar
  isChangeAvatarPage = new BehaviorSubject<any>([]);
  loadParentPage = this.isChangeAvatarPage.asObservable();
  changeAvatar(loadParentPage) {
    this.isChangeAvatarPage.next(loadParentPage);
  }
  // when submit change cover, post post
  isChangeCoverPage = new BehaviorSubject<any>([]);
  loadTimeLinePage = this.isChangeCoverPage.asObservable();
  changeCoverPage(loadTimePage) {
    this.isChangeCoverPage.next(loadTimePage);
  }
  //#endregion

  //#region CRUD Like post in FanPages
  createLikePagePost(obj): Observable<any> {
    return this.http.post(`page-post-like/create`, obj);
  }
  unLikePagePost(obj): Observable<any> {
    return this.http.post(`page-post-like/unLikePost`, obj);
  }
  getLikePagePost(): Observable<any> {
    return this.http.get(`page-post-like/`);
  }
  getLikeByPostId(id): Observable<any> {
    return this.http.get(`page-post-like/getLikeByPostId/${id}`);
  }
  //#endregion

  //#region CRUD comment page post
  createCommentPagePost(obj): Observable<any> {
    return this.http.post(`page-post-comment/create`, obj);
  }
  deleteCommentPagePost(id): Observable<any> {
    return this.http.get(`page-post-comment/delete/${id}`);
  }
  getCommentPostInPage(obj): Observable<any> {
    return this.http.post(`page-post-comment/getCommentByPageId`, obj);
  }
  getCommentById(id): Observable<any> {
    return this.http.get(`page-post-comment/getCommentById/${id}`);
  }
  //#endregion

  //#region CRUD reply comment post in Fanpage
  createReplyComment(obj): Observable<any> {
    return this.http.post(`page-post-reply/create`, obj);
  }
  getReplyByCommentId(obj): Observable<any> {
    return this.http.post(`page-post-reply/getReplyByCommentId`, obj);
  }
  getReplyById(id): Observable<any> {
    return this.http.get(`page-post-reply/getReplyById/${id}`);
  }
  deleteReplyById(id): Observable<any> {
    return this.http.get(`page-post-reply/delete/${id}`);
  }
  //#endregion

  //#region Search user by firstname, firstname
  searchUser(obj): Observable<any> {
    return this.http.post(`user/searchUserLink`, obj);
  }
  //#endregion

  //#region CRUD LIKE COMMENT, REPLY IN POST
  createLikeComment(obj): Observable<any> {
    return this.http.post(`page-post-like/create`, obj);
  }
  unLikeComment(obj): Observable<any> {
    return this.http.post(`page-post-like/unLikeComment`, obj);
  }

  getLikeByCommentId(id): Observable<any> {
    return this.http.get(`page-post-like/getLikeByCommentId/${id}`);
  }

  createLikeReply(obj): Observable<any> {
    return this.http.post(`page-post-like/create`, obj);
  }
  unLikeReply(obj): Observable<any> {
    return this.http.post(`page-post-like/unLikeReply`, obj);
  }

  getLikeByReplyId(id): Observable<any> {
    return this.http.get(`page-post-like/getLikeByReplyId/${id}`);
  }
  //#endregion

  //#region Load data from urlWeb
  getDataFromUrl(obj): Observable<any> {
    return this.http.post(`page-post/getDataFromUrl`, obj);
  }
  //#endregion

  //#region CRUD album video, images
  createAlbum(obj): Observable<any> {
    return this.http.post(`page-album/create`, obj);
  }
  getAlbum(pageId): Observable<any> {
    return this.http.get(`page-album/${pageId}`);
  }
  deleteAlbum(albumId): Observable<any> {
    return this.http.get(`page-album/delete/${albumId}`);
  }
  updateAlbum(obj): Observable<any> {
    return this.http.post(`page-album/update`, obj);
  }
  getAlbumPageId(pageId): Observable<any> {
    return this.http.get(`page-album/getAlbumByPageId/${pageId}`);
  }
  //#endregion

  //#region CRUD photo of album
  createPhoto(obj): Observable<any> {
    return this.http.post(`page-photo/create`, obj);
  }
  getPhotoByAlbumId(obj): Observable<any> {
    return this.http.post(`page-photo/getByAlbumId`, obj);
  }
  deletePhoto(photoId): Observable<any> {
    return this.http.get(`page-photo/delete/${photoId}`);
  }
  //#endregion

  //#region Pin/unPin page
  pinPage(obj): Observable<any> {
    return this.http.post(`page/pinPage`, obj);
  }

  unPinPage(obj): Observable<any> {
    return this.http.post(`page/unPinPage`, obj);
  }
  //#endregion

  //#region Attached page
  attachedPage(obj): Observable<any> {
    return this.http.post(`page/attchedPage`, obj);
  }
  unAttachedPage(obj): Observable<any> {
    return this.http.post(`page/unAttchedPage`, obj);
  }
  //#endregion

  upload(formData): Observable<any> {
    return this.http.post(`page/upload`, formData);
  }
  getVideoByPage(parameters?): Observable<any> {
    let params = new HttpParams();
    if (!isUndefined(parameters)) {
      params = isUndefined(parameters.skip)
        ? params
        : params.append("skip", parameters.skip);
      params = isUndefined(parameters.limit)
        ? params
        : params.append("limit", parameters.limit);
    }
    return this.http.get('page-post/getPostVideo', { params })
  }
  getLikeByArrVideo(arrVideo): Observable<any> {
    return this.http.post('page-post-like/getLikeByArrVideo', arrVideo)
  }
  getCommentsByArrayVideo(arrVideo): Observable<any> {
    return this.http.post('page-post-comment/getCommentsByArrayVideo', arrVideo)
  }
  getRepliesByArrVideo(arrVideo): Observable<any> {
    return this.http.post('page-post-reply/getRepliesByArrVideo', arrVideo)
  }
}
function isUndefined(value) {
  if (value) {
    return false;
  } else {
    return true;
  }
}
