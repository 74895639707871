import { Component, OnInit } from '@angular/core';
import * as loadjs from '../../pages/feed-layout/pagelayout';
import { ProfileService } from 'src/app/services/profile.service';
import * as environment from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  constructor(private profileService: ProfileService) {
    loadjs.loadjs();
    this.education = new Education('', '', '', '', '', 0);
    this.job = new Job('', '', '', '', '', 0);
    this.getUser();
    this.getAllSchool();
    this.getAllCompany();
  }

  user;
  education;
  job;
  environment = environment.environment.apiUrl;
  ngOnInit(): void {
    this.getEducationByUserId();
    this.getJobByUserId();
    this.profileService.isCall.subscribe((result) => {
      if (result.isCallEdu == true) {
        this.getEducationByUserId();
        return;
      }

      if (result.isCallJob == true) {
        this.getJobByUserId();
        return;
      }
    });
  }

  getUser() {
    this.profileService.getProfile().subscribe((result) => {
      this.user = new User(
        result['data'].firstname,
        result['data'].lastname,
        result['data'].email,
        result['data'].backupEmail,
        result['data'].address,
        result['data'].city,
        result['data'].country,
        result['data'].avatar
      );
    });
  }

  arrEduUser;
  getEducationByUserId() {
    this.profileService.getEducationByUserId().subscribe((result) => {
      this.arrEduUser = result.data;
      for (let schl in this.arrSchools) {
        for (let edu in this.arrEduUser) {
          if (this.arrEduUser[edu].schoolId._id == this.arrSchools[schl]._id) {
            this.arrEduUser[edu].schoolName = this.arrSchools[schl].schoolName;
          }
        }
      }
    });
  }

  arrJobUser;
  getJobByUserId() {
    this.profileService.getJob().subscribe((result) => {
      this.arrJobUser = result.data;
      for (let schl in this.arrCompany) {
        for (let edu in this.arrJobUser) {
          if (this.arrJobUser[edu].companyId._id == this.arrCompany[schl]._id) {
            this.arrJobUser[edu].comName = this.arrCompany[schl].comName;
          }
        }
      }
    });
  }

  arrSchools;
  getAllSchool() {
    this.profileService.getAllSchool().subscribe((reuslt) => {
      this.arrSchools = reuslt.data;
    });
  }

  arrCompany;
  getAllCompany() {
    this.profileService.getAllCompany().subscribe((result) => {
      this.arrCompany = result.data;
    });
  }
}

export class User {
  constructor(
    public firstname,
    public lastname,
    public email,
    public backupEmail,
    public address,
    public city,
    public country,
    public avatar
  ) { }
}

export class Education {
  constructor(
    public userId,
    public schoolId,
    public role,
    public startDate,
    public endDate,
    public isShow
  ) { }
}

export class Job {
  constructor(
    public userId,
    public companyId,
    public role,
    public startDate,
    public endDate,
    public isShow
  ) { }
}
