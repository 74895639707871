<app-nav-bar></app-nav-bar>
<div class="settings-sidebar is-active" *ngIf="user">
	<div class="settings-sidebar-inner">
		<div class="user-block">
			<a class="close-settings-sidebar is-hidden">
				<i-feather name="x"></i-feather>
			</a>
			<div class="avatar-wrap">
				<img [src]="user.avatar" data-user-popover="0" alt />
				<div class="badge">
					<i-feather name="check"></i-feather>
				</div>
			</div>
			<h4>{{ user?.firstname }} {{ user?.lastname }}</h4>
			<p>{{ user?.city }}, {{ user?.country }}</p>
		</div>
		<div class="user-menu">
			<div class="user-menu-inner has-slimscroll">
				<div class="menu-block">
					<ul>
						<li data-section="general" class="is-active">
							<a>
								<i-feather name="settings"></i-feather>
								<span>General</span>
							</a>
						</li>
						<li data-section="security">
							<a>
								<i-feather name="shield"></i-feather>
								<span>Security</span>
							</a>
						</li>
						<li data-section="personal">
							<a>
								<i-feather name="alert-triangle"></i-feather>
								<span>Account</span>
							</a>
						</li>
					</ul>
				</div>
				<div class="separator"></div>

				<div class="menu-block">
					<ul>
						<li data-section="adminpage">
							<a>
								<i-feather name="flag"></i-feather>
								<span>Admin pages</span>
							</a>
						</li>
						<li data-section="preferences">
							<a>
								<i-feather name="sliders"></i-feather>
								<span>Admin group</span>
							</a>
						</li>
					</ul>
				</div>
				<div class="separator"></div>

				<div class="menu-block">
					<ul>
						<li data-section="privacy">
							<a>
								<i-feather name="lock"></i-feather>
								<span>Privacy</span>
							</a>
						</li>
						<li data-section="preferences">
							<a>
								<i-feather name="sliders"></i-feather>
								<span>Preferences</span>
							</a>
						</li>
					</ul>
				</div>
				<div class="separator"></div>
				<div class="menu-block">
					<ul>
						<li data-section="notifications">
							<a>
								<i-feather name="bell"></i-feather>
								<span>Notifications</span>
							</a>
						</li>
						<li data-section="support">
							<a>
								<i-feather name="life-buoy"></i-feather>
								<span>Help</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="settings-wrapper">
	<app-general-setting
		[user]="user"
		[education]="education"
		[arrEduUser]="arrEduUser"
		[arrSchools]="arrSchools"
		[job]="job"
		[arrCompany]="arrCompany"
		[arrJobUser]="arrJobUser"
	>
	</app-general-setting>
	<app-security-setting [user]="user"></app-security-setting>
	<app-personal-setting></app-personal-setting>
	<app-privacy-setting></app-privacy-setting>
	<app-preferences-setting></app-preferences-setting>
	<app-notifications-setting></app-notifications-setting>
	<app-support-setting></app-support-setting>
	<app-admin-page-setting></app-admin-page-setting>
</div>
