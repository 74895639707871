import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../../services/notification.service';
import * as environment from '../../../../../environments/environment';

@Component({
  selector: 'app-notification-dropdown',
  templateUrl: './notification-dropdown.component.html',
  styleUrls: ['./notification-dropdown.component.scss'],
})
export class NotificationDropdownComponent implements OnInit {
  //model
  arrNotification = [];
  environment;
  constructor(private notifiService: NotificationService) {
    this.environment = environment.environment.apiUrl;
  }
  ngOnInit(): void {
    setInterval(() => {
      this.getNotification();
    }, 5000);
  }

  trackByFn(index, item) {
    return index;
  }

  //#endregion GET notification of user login
  isNotification;
  getNotification() {
    this.notifiService.getNotification().subscribe((result) => {
      if (result.status == 'success') {
        this.arrNotification = result.data;
        let checkRead = this.arrNotification.find(
          (item) => item.notificationReaded === 'false'
        );
        if (checkRead) {
          this.isNotification = true;
        }
      }
    });
  }
  //#region
}
