import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-basic-infos-widget',
  templateUrl: './basic-infos-widget.component.html',
  styleUrls: ['./basic-infos-widget.component.scss'],
})
export class BasicInfosWidgetComponent implements OnInit {
  @Input() infouser;
  @Input() arrEducations;
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges() {}
}
