import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProfileService } from "src/app/services/profile.service";
import { environment } from "src/environments/environment";
import { MessageService } from "src/app/services/message.service";
import jwt_decode from "jwt-decode";
@Component({
	selector: "app-conversations-list",
	templateUrl: "./conversations-list.component.html",
	styleUrls: ["./conversations-list.component.scss"],
})
export class ConversationsListComponent implements OnInit {
	@Output() selectedFriendChanged = new EventEmitter<any>();
	@Input() listExpanded: boolean;
	@Input() friends;
	tokenInfo;
	constructor(private profileService: ProfileService, private messageService: MessageService) { }

	ngOnInit(): void {
		let token = localStorage.getItem("token");
		if (token) {
			this.getInfoUser(token);
		}
	}

	getDecodedAccessToken(token: string): any {
		try {
			return jwt_decode(token);
		} catch (Error) {
			return null;
		}
	}

	getInfoUser(token) {
		this.tokenInfo = this.getDecodedAccessToken(token);
	}

	getMessages(infoFriend) {
		this.selectedFriendChanged.emit(infoFriend);
		for (let friend of this.friends) {
			friend.active = false;
		}
		if (infoFriend.receiverId._id == this.tokenInfo._id) {
			infoFriend.active = true;
		} else {
			infoFriend.active = true;
		}
	}
}
