import { Injectable } from '@angular/core';
import io from "socket.io-client";
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import * as environment from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  socket: any;
  environment = environment.environment.apiUrl;

  constructor() {
    var token = localStorage.getItem('token')
    // const socket = io.connect('http://localhost:3000', {
    //   query: { token }
    // });
    this.socket = io(this.environment, { query: { token }, transports: ["websocket"] });
  }

  listen(eventName: string) {
    return new Observable((subcriber) => {
      this.socket.on(eventName, (data) => {
        subcriber.next(data);
      });
    });
  }

  emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  close(eventName: string) {
    this.socket.close(eventName);
  }
}
