import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  NgZone,
  Output,
} from '@angular/core';
declare var $: any;
@Directive({
  selector: '[appDatepicker]',
  exportAs: 'datepicker',
})
export class DatepickerDirective implements AfterViewInit {
  @Output() EventDate = new EventEmitter();

  constructor(private el: ElementRef, private ngZone: NgZone) {}
  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      $(this.el.nativeElement)
        .datepicker({
          format: 'mm-dd-yyyy',
          endDate: new Date(),
          container: 'body',
          autoHide: true,
          offset: 0,
        })
        .on('change', (date: any) => {
          this.ngZone.run(() => {
            this.setDate(date.timeStamp);
          });
        });
    });
  }

  setDate(date) {
    let dateCurrent = new Date(date);
    this.EventDate.emit(dateCurrent);
  }
}
