<ng-container *ngIf="infouser != undefined">
	<div class="box-heading">
		<h4>Basic Infos</h4>
		<div class="dropdown is-neutral is-spaced is-right dropdown-trigger">
			<div>
				<div class="button">
					<i-feather name="more-vertical"></i-feather>
				</div>
			</div>
			<div class="dropdown-menu" role="menu">
				<div class="dropdown-content">
					<a href="profile-about.html" class="dropdown-item">
						<div class="media">
							<i-feather name="eye"></i-feather>
							<div class="media-content">
								<h3>View</h3>
								<small>View user details.</small>
							</div>
						</div>
					</a>
					<a href="#" class="dropdown-item">
						<div class="media">
							<i-feather name="search"></i-feather>
							<div class="media-content">
								<h3>Related</h3>
								<small>Search for related users.</small>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
	<div class="basic-infos-wrapper">
		<div class="card is-profile-info">
			<div class="info-row" *ngFor="let edu of arrEducations">
				<div>
					<span>Studied at </span>
					<a class="is-inverted">{{ edu?.schoolId?.schoolName }}</a>
				</div>
				<i class="mdi mdi-school"></i>
			</div>
			<div class="info-row" *ngIf="infouser.relationship">
				<div>
					<span>{{ infouser?.relationship }}</span>
					<a class="is-inverted" *ngIf="infouser.relationshipperson">
						{{ infouser?.relationshipperson?.firstname }}
						{{ infouser?.relationshipperson?.lastname }}
					</a>
				</div>
				<i class="mdi mdi-heart"></i>
			</div>
			<div class="info-row">
				<div>
					<span>From</span>
					<a class="is-inverted">{{ infouser.country | uppercase }}</a>
				</div>
				<i class="mdi mdi-earth"></i>
			</div>
			<div class="info-row">
				<div>
					<span>Lives in</span>
					<a class="is-inverted">{{ infouser.address | uppercase }}</a>
				</div>
				<i class="mdi mdi-map-marker"></i>
			</div>
			<div class="info-row">
				<div>
					<span>Followers</span>
					<a class="is-muted">258 followers</a>
				</div>
				<i class="mdi mdi-bell-ring"></i>
			</div>
		</div>
	</div>
</ng-container>
