import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as loadjs from '../../pages/feed-layout/pagelayout';
import { AuthService } from '../../services/auth.service';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss'],
})
export class LoginLayoutComponent implements OnInit {
  checkToken: any;
  loginForm: FormGroup;
  Email;
  Password;
  typePassWord;
  isLoading: boolean = false;
  constructor(
    private router: Router,
    private toastr: ToastrManager,
    private fb: FormBuilder,
    private authService: AuthService
  ) {
    loadjs.loadjs();
    this.checkToken = localStorage.getItem('token');
    this.createForm();
    this.typePassWord = false;
    setTimeout(() => {
      this.isLoading = true;
    }, 1500);
  }

  private createForm() {
    this.loginForm = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        this.checkWhiteSpace,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]),
      password: new FormControl('', Validators.required),
    });
  }
  get primEmail() {
    return this.loginForm.get('email');
  }
  get primPassword() {
    return this.loginForm.get('password');
  }
  doLogin() {
    if (this.Email !== '' && this.Password !== '') {
      this.authService.login(this.loginForm.value).subscribe(
        (data) => {
          if (data === false) {
            this.toastr.errorToastr('User has not been validated');
          } else {
            this.toastr.successToastr('User logged.', null, {
              toastTimeout: 2000,
            });
            localStorage.setItem('token', data['token']);
            let token = localStorage.getItem('token');
            let decoded = jwt_decode(token);
            localStorage.setItem('UserName', decoded.username);

            // use for testing only
            var storedTokensStr = localStorage.getItem('storedTokens');
            if (storedTokensStr == null || storedTokensStr === 'null') storedTokensStr = '{}';
            var storedTokens = JSON.parse(storedTokensStr);
            storedTokens[decoded.username] = token;
            localStorage.setItem('storedTokens', JSON.stringify(storedTokens));
            /// end

            if (token != undefined) {
              window.location.replace('');
            }
          }
        },
        (error) => {
          this.toastr.errorToastr('Wrong password or email is not registered');
        }
      );
    } else if (this.Email === '') {
      this.toastr.errorToastr('Please enter Email');
    } else if (this.Password === '') {
      this.toastr.errorToastr('Please enter Password');
    }
  }
  private checkWhiteSpace(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    if (control.value === '') {
      return isValid ? null : { whitespace: false };
    }
    return isValid ? null : { whitespace: true };
  }

  showHidePassWord() {
    this.typePassWord = !this.typePassWord;
  }

  blockEnter(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  }
  enter(e) {
    if (e.keyCode == 13) {
      this.doLogin();
    }
  }

  goRegister() {
    window.location.replace(`/register`);

    //  this.router.navigateByUrl('/register');
    // this.router.navigate(['register']);
  }

  goMainPage() {
    this.router.navigate(['']);
  }

  ngOnInit(): void {}
}
