import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-videos-home',
  templateUrl: './videos-home.component.html',
  styleUrls: ['./videos-home.component.scss'],
})
export class VideosHomeComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    this.initVideoJS();
  }

  initVideoJS() {
    (function ($) {
      $(document).ready(function () {
        $(document).ready(function () {
          'use strict';

          //Home page
          if ($('.videos-wrapper.is-home').length) {
            //Init header carousel
            $('.video-header-wrap').slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              autoplay: true,
              autoplaySpeed: 8000,
              fade: true,
              dots: true,
              pauseOnFocus: true,
              //centerMode: true,
              //centerPadding: '0',
              arrows: false,
              prevArrow:
                "<div class='slick-custom is-prev'><i class='fa fa-chevron-left'></i></div>",
              nextArrow:
                "<div class='slick-custom is-next'><i class='fa fa-chevron-right'></i></div>",
            });
          }

          //Player page
          if ($('.videos-wrapper.has-player').length) {
            //Expand video description
            $('#description-show-more').on('click', function () {
              $('.additional-description').slideToggle('fast');
              if ($(this).text() == 'Show More') {
                $(this).html('Show Less');
              } else {
                $(this).html('Show More');
              }
            });

            //Expand comments
            $('.nested-replies .header').on('click', function () {
              $(this).toggleClass('is-active');
              $(this).siblings('.nested-comments').slideToggle('fast');
            });
          }
        });
      });
    })(jQuery);
  }
}
