<div class="like-wrapper">
    <a
        href="javascript:void(0);"
        class="like-button"
    >
        <i class="mdi mdi-heart not-liked bouncy"></i>
        <i class="mdi mdi-heart is-liked bouncy"></i>
        <span class="like-overlay"></span>
    </a>
</div>
<div class="fab-wrapper is-share">
    <a
        href="javascript:void(0);"
        class="small-fab share-fab modal-trigger"
        data-modal="share-modal"
    >
        <i-feather name="link-2"></i-feather>
    </a>
</div>
<div class="fab-wrapper is-comment">
    <a
        href="javascript:void(0);"
        class="small-fab"
    >
        <i-feather name="message-circle"></i-feather>
    </a>
</div>
