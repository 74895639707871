<a class="icon-link" [ngClass]="isNotification ? 'is-active' : ''">
	<i-feather name="bell"></i-feather>
	<span class="indicator"></span>
</a>
<div class="nav-drop">
	<div class="inner">
		<div class="nav-drop-header">
			<span>Notifications</span>
			<a href="#">
				<i-feather name="bell"></i-feather>
			</a>
		</div>
		<div class="nav-drop-body is-notifications">
			<!-- Notification -->
			<ng-container *ngFor="let notifi of arrNotification; trackBy: trackByFn">
				<div class="media">
					<figure class="media-left">
						<p class="image">
							<img
								[src]="notifi?.notificationer?.avatar"
								[attr.data-demo-src]="notifi?.notificationer?.avatar"
								alt
							/>
						</p>
					</figure>
					<div class="media-content">
						<span>
							<a [href]="'profile/' + notifi?.notificationer?._id"
								>{{ notifi?.notificationer?.firstname }}
								{{ notifi?.notificationer?.lastname }}</a
							>
							{{ notifi?.notificationContent?.notificationConent }}
							<a *ngIf="notifi?.notificationType == 1">your post</a>
							<a *ngIf="notifi?.notificationType == 2">your comment</a>
							.
						</span>
						<span class="time"> {{ notifi?.createdAt | dateAgo }}</span>
					</div>
					<div class="media-right">
						<div class="added-icon">
							<i-feather name="message-square"></i-feather>
						</div>
					</div>
				</div>
			</ng-container>
			<!-- Notification -->
			<!-- <div class="media">
          <figure class="media-left">
            <p class="image">
              <img
                src="https://via.placeholder.com/300x300"
                data-demo-src="assets/img/avatars/daniel.jpg"
                alt
              />
            </p>
          </figure>
          <div class="media-content">
            <span>
              <a href="#">Daniel Wellington</a>
              liked your
              <a href="#">profile.</a>
            </span>
            <span class="time">43 minutes ago</span>
          </div>
          <div class="media-right">
            <div class="added-icon">
              <i-feather name="heart"></i-feather>
            </div>
          </div>
        </div> -->
			<!-- Notification -->
			<!-- <div class="media">
          <figure class="media-left">
            <p class="image">
              <img
                src="https://via.placeholder.com/300x300"
                data-demo-src="assets/img/avatars/stella.jpg"
                alt
              />
            </p>
          </figure>
          <div class="media-content">
            <span>
              <a href="#">Stella Bergmann</a>
              shared a
              <a href="#">New video</a>
              on your wall.
            </span>
            <span class="time">Yesterday</span>
          </div>
          <div class="media-right">
            <div class="added-icon">
              <i-feather name="youtube"></i-feather>
            </div>
          </div>
        </div> -->
			<!-- Notification -->
			<!-- <div class="media">
          <figure class="media-left">
            <p class="image">
              <img
                src="https://via.placeholder.com/300x300"
                data-demo-src="assets/img/avatars/elise.jpg"
                alt
              />
            </p>
          </figure>
          <div class="media-content">
            <span>
              <a href="#">Elise Walker</a>
              shared an
              <a href="#">Image</a>
              with you an 2 other people.
            </span>
            <span class="time">2 days ago</span>
          </div>
          <div class="media-right">
            <div class="added-icon">
              <i-feather name="image"></i-feather>
            </div>
          </div>
        </div> -->
		</div>
		<div class="nav-drop-footer">
			<a href="#">View All</a>
		</div>
	</div>
</div>
