import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import * as environment from 'src/environments/environment';
import * as loadjs from 'src/app/pages/feed-layout/pagelayout';
import iziToast from 'iziToast';
@Component({
  selector: 'app-friend-request',
  templateUrl: './friend-request.component.html',
  styleUrls: ['./friend-request.component.scss']
})
export class FriendRequestComponent implements OnInit {
  environment = environment.environment.apiUrl; friendRequests
  constructor(private profileService: ProfileService) { }

  ngOnInit(): void {
    this.getFriendRequests();
    loadjs.loadjs();
  }
  getFriendRequests() {
    this.profileService.getFriendsRequest().subscribe((result) => {
      this.friendRequests = result['data'];
    });
  }

  createFriend(request) {
    let data = {
      senderId: request.senderId._id,
    };
    this.profileService.friendAccept(data).subscribe((result) => {
      iziToast.show({
        class: 'success-toast',
        icon: 'mdi mdi-send',
        title: '',
        message: `You are now friend with <b>${request.senderId.firstname} ${request.senderId.lastname}</b>`,
        titleColor: '#fff',
        messageColor: '#fff',
        iconColor: '#fff',
        backgroundColor: '#7F00FF',
        progressBarColor: '#b975ff',
        position: 'bottomRight',
        transitionIn: 'fadeInUp',
        close: false,
        timeout: 2500,
        zindex: 99999,
      });
      request.friend = true;
    });
  }
  deleteFriend(request) {
    this.profileService.deleteFriendRequest(request._id).subscribe((result) => {
      this.friendRequests.splice(this.friendRequests.findIndex((i) => {
        return i._id == request._id
      }), 1)
    })
  }
}
