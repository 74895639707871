import { Component, OnInit } from '@angular/core';
import { VideoService } from '../../../services/video.service';

@Component({
  selector: 'app-my-channel',
  templateUrl: './my-channel.component.html',
  styleUrls: ['./my-channel.component.scss']
})
export class MyChannelComponent implements OnInit {

  constructor(private videoService: VideoService) { }

  ngOnInit(): void {
    this.loadVideo();
  }

  arrVideos
  async loadVideo() {
    let videoPage = await this.videoService.getVideoByPage().toPromise();
    this.arrVideos = videoPage.data;
  }
}

function sortByDate(arr) {
  return arr.sort(function (a, b) {
    let c = new Date(a.createdAt).getTime();
    let d = new Date(b.createdAt).getTime();
    return (c - d);
  })
}