import { Component, Input, OnInit } from '@angular/core';
import { GroupService } from 'src/app/services/group/group.service';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-group-about',
  templateUrl: './group-about.component.html',
  styleUrls: ['./group-about.component.scss'],
})
export class GroupAboutComponent implements OnInit {
  groupDisplays;
  groupDisplayContents;
  groupDisplayIcons: string;

  groupHiddens;
  groupHiddenIcons: string;
  groupHiddenContents;

  dataGroups;
  @Input() set dataGroup(dataGroup: any) {
    this.dataGroups = dataGroup;
  }
  @Input() set groupDisplay(nameDisplay: any) {
    this.groupDisplays = nameDisplay;
  }
  @Input() set groupDisplayIcon(nameIcon: any) {
    this.groupDisplayIcons = nameIcon;
  }
  @Input() set groupDisplayContent(content: any) {
    this.groupDisplayContents = content;
  }

  @Input() set groupHidden(nameDisplay: any) {
    this.groupHiddens = nameDisplay;
  }
  @Input() set groupHiddenIcon(nameIcon: any) {
    this.groupHiddenIcons = nameIcon;
  }
  @Input() set groupHiddenContent(content: any) {
    this.groupHiddenContents = content;
  }
  constructor(private groupService: GroupService) {}
  environment;
  ngOnInit(): void {
    if (this.dataGroups) {
      this.getMemberByGroupId(this.dataGroups._id);
      this.getAdminByGroupId(this.dataGroups._id);
    }
    this.environment = environment.apiUrl;
  }

  dataMember = [];
  countMember;
  getMemberByGroupId(groupId) {
    let reqMember = {
      groupId: groupId,
      skip: 0,
      limit: 10,
    };
    this.groupService.getMembers(reqMember).subscribe((result) => {
      if (result.status == 'success') {
        this.countMember = this.nFormatter(result.count, 1);
        this.dataMember = result.data;
      }
    });
  }
  arrAdmin = [];
  countAdmin;
  getAdminByGroupId(groupId) {
    this.groupService.getAdminGroup(groupId).subscribe((result) => {
      if (result.status == 'success') {
        this.arrAdmin = result.data;
        this.countAdmin = this.nFormatter(result.count, 1);
      }
    });
  }

  //#region FUCNTION click see all member in group
  clickSeeAll() {
    this.groupService.clickSeeMember({ status: 'success' });
  }
  //#endregion

  //#region FUNCTION format dram https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
  nFormatter(num, digits) {
    var si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
  }
  //#endregion
}
