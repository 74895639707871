import { Component, Input, OnInit } from '@angular/core';
import { GroupService } from 'src/app/services/group/group.service';
import { environment } from '../../../../../../environments/environment';
@Component({
  selector: 'app-group-info',
  templateUrl: './group-info.component.html',
  styleUrls: ['./group-info.component.scss'],
})
export class GroupInfoComponent implements OnInit {
  groupDisplays: string;
  groupDisplayContents: string;
  groupDisplayIcons: string;

  groupHiddens: string;
  groupHiddenIcons: string;
  groupHiddenContents: string;
  environment;
  dataGroups;
  @Input() set dataGroup(dataGroup: any) {
    this.dataGroups = dataGroup;
  }
  @Input() set groupDisplay(nameDisplay: string) {
    this.groupDisplays = nameDisplay;
  }
  @Input() set groupDisplayIcon(nameIcon: string) {
    this.groupDisplayIcons = nameIcon;
  }
  @Input() set groupDisplayContent(content: string) {
    this.groupDisplayContents = content;
  }

  @Input() set groupHidden(nameDisplay: string) {
    this.groupHiddens = nameDisplay;
  }
  @Input() set groupHiddenIcon(nameIcon: string) {
    this.groupHiddenIcons = nameIcon;
  }
  @Input() set groupHiddenContent(content: string) {
    this.groupHiddenContents = content;
  }

  arrPhotos: [];
  @Input() set arrPhoto(arrPhoto: []) {
    this.arrPhotos = arrPhoto;
  }

  constructor(private groupService: GroupService) {
    this.environment = environment.apiUrl;
  }

  ngOnInit(): void {}

  seeAllPhoto() {
    this.groupService.clickSeeAllphoto({ status: 'success' });
  }
}
