import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
// Header nav
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { FeedMenuComponent } from './new-feed/feed-menu/feed-menu.component';

// Layout feed
import { NewFeedComponent } from './new-feed/new-feed.component';

import { AccountDropdownComponent } from './nav-bar/dropdowns/account-dropdown/account-dropdown.component';
import { MessagesDropdownComponent } from './nav-bar/dropdowns/messages-dropdown/messages-dropdown.component';
import { NotificationDropdownComponent } from './nav-bar/dropdowns/notification-dropdown/notification-dropdown.component';
import { FriendRequestsDropdownComponent } from './nav-bar/dropdowns/friend-requests-dropdown/friend-requests-dropdown.component';
import { ComposeCardComponent } from './new-feed/compose-card/compose-card.component';
import { PostComponent } from './new-feed/post/post.component';
import { AdminCardDropdownComponent } from './dropdowns/admin-card-dropdown/admin-card-dropdown.component';
import { CommentDropdownComponent } from './dropdowns/comment-dropdown/comment-dropdown.component';
import { FeedPostDropdownComponent } from './dropdowns/feed-post-dropdown/feed-post-dropdown.component';
import { SearchWidgetComponent } from './nav-bar/dropdowns/search-widget/search-widget.component';
import { FeedPostActionsComponent } from './new-feed/dropdowns/feed-post-actions/feed-post-actions.component';
import { StoriesWidgetComponent } from './widgets/stories-widget/stories-widget.component';
import { BirthdayWidgetComponent } from './widgets/birthday-widget/birthday-widget.component';
import { SuggestedFriendsWidgetComponent } from './widgets/suggested-friends-widget/suggested-friends-widget.component';
import { ProfileMainComponent } from './profile-main/profile-main.component';
import { BasicInfosWidgetComponent } from './profile-main/timeline/widgets/basic-infos-widget/basic-infos-widget.component';
import { PhotosWidgetComponent } from './profile-main/timeline/widgets/photos-widget/photos-widget.component';
import { StarFriendsWidgetComponent } from './profile-main/timeline/widgets/star-friends-widget/star-friends-widget.component';
import { TripsWidgetComponent } from './profile-main/timeline/widgets/trips-widget/trips-widget.component';
import { VideoWidgetComponent } from './profile-main/timeline/widgets/video-widget/video-widget.component';
import { TimelineHeaderComponent } from './profile-main/timeline/timeline-header/timeline-header.component';
import { TimelineMobileDropdownComponent } from './profile-main/timeline/dropdowns/timeline-mobile-dropdown/timeline-mobile-dropdown.component';
import { ProfileAboutComponent } from './profile-main/profile-about/profile-about.component';
import { ChangeCoverModalComponent } from './profile-main/timeline/modals/change-cover-modal/change-cover-modal.component';
import { ChangeProfilePicModalComponent } from './profile-main/timeline/modals/change-profile-pic-modal/change-profile-pic-modal.component';
import { CustomProfileLightboxComponent } from './profile-main/timeline/modals/custom-profile-lightbox/custom-profile-lightbox.component';
import { UploadCropCoverModalComponent } from './profile-main/timeline/modals/upload-crop-cover-modal/upload-crop-cover-modal.component';
import { UploadCropProfileModalComponent } from './profile-main/timeline/modals/upload-crop-profile-modal/upload-crop-profile-modal.component';
import { UserPhotosModalComponent } from './profile-main/timeline/modals/user-photos-modal/user-photos-modal.component';
import { ProfileComponent } from './profile-main/profile/profile.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AlbumsHelpModalComponent } from './new-feed/modals/albums-help-modal/albums-help-modal.component';
import { AlbumsModalComponent } from './new-feed/modals/albums-modal/albums-modal.component';
import { CreateGroupModalComponent } from './new-feed/modals/create-group-modal/create-group-modal.component';
import { VideoHelpModalComponent } from './new-feed/modals/video-help-modal/video-help-modal.component';
import { VideoModalComponent } from './new-feed/modals/video-modal/video-modal.component';
import { ShareModalComponent } from './new-feed/modals/share-modal/share-modal.component';
import { NoStreamModalComponent } from './new-feed/modals/no-stream-modal/no-stream-modal.component';
import { NewJobComponent } from './widgets/new-job/new-job.component';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { ScheduleWidgetComponent } from './widgets/schedule/schedule-widget/schedule-widget.component';
import { EventComponent } from './widgets/schedule/event-details/event/event.component';
import { PostTimelineComponent } from './profile-main/profile/post-timeline/post-timeline.component';
import { GeneralSettingComponent } from './settings/sections/general-setting/general-setting.component';
import { NotificationsSettingComponent } from './settings/sections/notifications-setting/notifications-setting.component';
import { PersonalSettingComponent } from './settings/sections/personal-setting/personal-setting.component';
import { PreferencesSettingComponent } from './settings/sections/preferences-setting/preferences-setting.component';
import { PrivacySettingComponent } from './settings/sections/privacy-setting/privacy-setting.component';
import { SecuritySettingComponent } from './settings/sections/security-setting/security-setting.component';
import { SupportSettingComponent } from './settings/sections/support-setting/support-setting.component';
import { SettingsComponent } from './settings/settings.component';
import { ProfileFriendsComponent } from './profile-main/profile-friends/profile-friends.component';
import { ProfilePhotosComponent } from './profile-main/profile-photos/profile-photos.component';
import { ChatComponent } from './chat/chat.component';
import { ChatNavComponent } from './chat/chat-nav/chat-nav.component';
import { ConversationsListComponent } from './chat/conversations-list/conversations-list.component';
import { PersonConversationComponent } from './chat/person-conversation/person-conversation.component';
import { AddConversationModalComponent } from './chat/add-conversation-modal/add-conversation-modal.component';
import { PersonDetailComponent } from './chat/person-detail/person-detail.component';
import { ComposeAddDropdownComponent } from './chat/compose-add-dropdown/compose-add-dropdown.component';
import { IconsModule } from './icons.module';
import { LoginLayoutComponent } from '../pages/login-layout/login-layout.component';
import { RegisterLayoutsComponent } from '../pages/register-layouts/register-layouts.component';
import { PageMainComponent } from './page-main/page-main.component';
import { PagesAboutComponent } from './page-main/pages-about/pages-about.component';
import { PagesPhotosComponent } from './page-main/pages-photos/pages-photos.component';
import { PagesCommunityComponent } from './page-main/pages-community/pages-community.component';
import { PagesCreateComponent } from './nav-bar/dropdowns/modal/pages-create/pages-create.component';
import { PageChangeCoverModalComponent } from './page-main/modal/page-change-cover-modal/page-change-cover-modal.component';
import { PageChangeProfilePicModalComponent } from './page-main/modal/page-change-profile-pic-modal/page-change-profile-pic-modal.component';
import { PageUploadCropProfileModalComponent } from './page-main/modal/page-upload-crop-profile-modal/page-upload-crop-profile-modal.component';
import { PageUploadCropCoverModalComponent } from './page-main/modal/page-upload-crop-cover-modal/page-upload-crop-cover-modal.component';
import { PageUserPhotosModalComponent } from './page-main/modal/page-user-photos-modal/page-user-photos-modal.component';
import { PageShareModalComponent } from './page-main/modal/page-share-modal/page-share-modal.component';
import { PageAddConversationModalComponent } from './page-main/modal/page-add-conversation-modal/page-add-conversation-modal.component';
import { TimelinePageComponent } from './page-main/timeline/page-timeline-header/timeline-page.component';
import { PagesProfileComponent } from './page-main/pages-profile/pages-profile.component';
import { InfoComponent } from './page-main/timeline/widgets/info/info.component';
import { PageComposeCardComponent } from './page-main/page-compose-card/page-compose-card.component';
import { NgxLinkPreviewModule } from 'ngx-link-preview';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PagePhotoLightboxComponent } from './page-main/modal/page-photo-lightbox/page-photo-lightbox.component';
import { PagePhotoLightboxContainerComponent } from './page-main/modal/page-photo-lightbox-container/page-photo-lightbox-container.component';

import { SanitizeHtmlPipePipe } from '../pipes/sanitize-html-pipe.pipe';
import { PageAlbumComponent } from './page-main/pages-photos/page-album/page-album.component';
import { StoriesMainComponent } from './stories-main/stories-main.component';
import { WatchComponent } from './watch/watch.component';
import { VideoComponent } from './watch/video/video.component';
import { MyChannelComponent } from './watch/my-channel/my-channel.component';
import { StoriesSidebarComponent } from './stories-main/stories-sidebar/stories-sidebar.component';
import { StoriesContentComponent } from './stories-main/stories-content/stories-content.component';
import { NewStoryModalComponent } from './stories-main/modal/new-story-modal/new-story-modal.component';
import { VideoStoryModalComponent } from './stories-main/modal/video-story-modal/video-story-modal.component';
import { ImageStoryModalComponent } from './stories-main/modal/image-story-modal/image-story-modal.component';
import { GroupMainComponent } from './group-main/group-main.component';
import { GroupCreateComponent } from './nav-bar/dropdowns/modal/group-create/group-create.component';
import { GroupSidebarComponent } from './group-main/group-sidebar/group-sidebar.component';
import { GroupContentComponent } from './group-main/group-content/group-content.component';
import { GroupComposeCardComponent } from './group-main/group-content/widgets/group-compose-card/group-compose-card.component';
import { GroupInfoComponent } from './group-main/group-content/widgets/group-info/group-info.component';
import { GroupPostComponent } from './group-main/group-content/group-post/group-post.component';
import { GroupPhotoComponent } from './group-main/group-content/group-photo/group-photo.component';
import { GroupMembersComponent } from './group-main/group-content/group-members/group-members.component';
import { GroupAboutComponent } from './group-main/group-content/group-about/group-about.component';
import { InviteMemberComponent } from './group-main/group-content/modal/invite-member/invite-member.component';
import { ModalConfirmComponent } from './group-main/group-content/modal/modal-confirm/modal-confirm.component';
import { GroupNewFeedComponent } from './group-main/group-new-feed/group-new-feed.component';
import { AdminPageSettingComponent } from './settings/sections/admin-page-setting/admin-page-setting.component';
import { AdminGroupSettingComponent } from './settings/sections/admin-group-setting/admin-group-setting.component';
import { QuestionsComponent } from './questions/questions.component';
import { QuestionHomeComponent } from './questions/question-home/question-home.component';
import { QuestionCategoriesComponent } from './questions/question-categories/question-categories.component';
import { QuestionsSingleComponent } from './questions/questions-single/questions-single.component';
import { QuestionsStatsComponent } from './questions/questions-stats/questions-stats.component';
import { QuestionsNavComponent } from './questions/questions-nav/questions-nav.component';
import { QuestionsSettingsComponent } from './questions/questions-settings/questions-settings.component';
import { VideosComponent } from './videos/videos.component';
import { VideosSidebarComponent } from './videos/videos-sidebar/videos-sidebar.component';
import { VideosHomeComponent } from './videos/videos-home/videos-home.component';
import { VideosPlayerComponent } from './videos/videos-player/videos-player.component';
import { GroupAlbumComponent } from './group-main/group-content/group-photo/group-album/group-album.component';
import { ProfileAlbumComponent } from './profile-main/profile-photos/profile-album/profile-album.component';
import { FriendRequestComponent } from './new-feed/friend-request/friend-request.component';
// derective datepicker
import { DatepickerDirective } from '../directives/datepicker.directive';
import { DragdropDirective } from '../directives/dragdrop.directive';
import { PasteDirective } from '../directives/paste.directive';
import { InputDirective } from '../directives/input.directive';

// import compression image
import { NgxImageCompressService } from 'ngx-image-compress';
import { GroupMemberRequestsDropdownComponent } from './group-main/group-content/group-member-requests-dropdown/group-member-requests-dropdown.component';
import { GroupPostRequestComponent } from './group-main/group-post-request/group-post-request.component';
import { PostRequestGroupComponent } from './group-main/group-post-request/post-request-group/post-request-group.component';
import { GroupConfirmLeaveDialogComponent } from './group-main/group-content/group-members/group-confirm-leave-dialog/group-confirm-leave-dialog.component';
import { GroupConfirmConversionAdminDialogComponent } from './group-main/group-content/group-members/group-confirm-conversion-admin-dialog/group-confirm-conversion-admin-dialog.component';
import { GroupConfirmCancelAdminDialogComponent } from './group-main/group-content/group-members/group-confirm-cancel-admin-dialog/group-confirm-cancel-admin-dialog.component';
import { ProfileFriendItemComponent } from './profile-main/profile-friends/profile-friend-item/profile-friend-item.component';
import { NotificationGroupDialogComponent } from './group-main/notification-group-dialog/notification-group-dialog.component';

@NgModule({
  declarations: [
    NavBarComponent,
    NewFeedComponent,
    AccountDropdownComponent,
    MessagesDropdownComponent,
    NotificationDropdownComponent,
    FriendRequestsDropdownComponent,
    ComposeCardComponent,
    PostComponent,
    AdminCardDropdownComponent,
    CommentDropdownComponent,
    FeedPostDropdownComponent,
    SearchWidgetComponent,
    FeedPostActionsComponent,
    StoriesWidgetComponent,
    BirthdayWidgetComponent,
    SuggestedFriendsWidgetComponent,
    ProfileMainComponent,
    ProfileComponent,
    BasicInfosWidgetComponent,
    PhotosWidgetComponent,
    StarFriendsWidgetComponent,
    TripsWidgetComponent,
    VideoWidgetComponent,
    TimelineHeaderComponent,
    TimelineMobileDropdownComponent,
    ProfileAboutComponent,
    ChangeCoverModalComponent,
    ChangeProfilePicModalComponent,
    CustomProfileLightboxComponent,
    UploadCropCoverModalComponent,
    UploadCropProfileModalComponent,
    UserPhotosModalComponent,
    AlbumsHelpModalComponent,
    AlbumsModalComponent,
    CreateGroupModalComponent,
    VideoHelpModalComponent,
    VideoModalComponent,
    ShareModalComponent,
    NoStreamModalComponent,
    NewJobComponent,
    DateAgoPipe,
    ScheduleWidgetComponent,
    EventComponent,
    PostTimelineComponent,
    GeneralSettingComponent,
    NotificationsSettingComponent,
    PersonalSettingComponent,
    PreferencesSettingComponent,
    PrivacySettingComponent,
    SecuritySettingComponent,
    SupportSettingComponent,
    SettingsComponent,
    ProfileFriendsComponent,
    ProfilePhotosComponent,
    ChatComponent,
    ChatNavComponent,
    ConversationsListComponent,
    PersonConversationComponent,
    AddConversationModalComponent,
    PersonDetailComponent,
    ComposeAddDropdownComponent,
    LoginLayoutComponent,
    RegisterLayoutsComponent,
    PageMainComponent,
    PagesAboutComponent,
    PagesPhotosComponent,
    PagesCommunityComponent,
    PagesCreateComponent,
    PageChangeCoverModalComponent,
    PageChangeProfilePicModalComponent,
    PageUploadCropProfileModalComponent,
    PageUploadCropCoverModalComponent,
    PageUserPhotosModalComponent,
    PageShareModalComponent,
    PageAddConversationModalComponent,
    TimelinePageComponent,
    PagesProfileComponent,
    InfoComponent,
    PageComposeCardComponent,
    PagePhotoLightboxComponent,
    SanitizeHtmlPipePipe,
    PageAlbumComponent,
    StoriesMainComponent,
    StoriesSidebarComponent,
    StoriesContentComponent,
    NewStoryModalComponent,
    VideoStoryModalComponent,
    ImageStoryModalComponent,
    GroupMainComponent,
    GroupCreateComponent,
    GroupSidebarComponent,
    GroupContentComponent,
    GroupComposeCardComponent,
    GroupInfoComponent,
    GroupPostComponent,
    GroupPhotoComponent,
    GroupMembersComponent,
    GroupAboutComponent,
    InviteMemberComponent,
    ModalConfirmComponent,
    GroupNewFeedComponent,
    AdminPageSettingComponent,
    AdminGroupSettingComponent,
    QuestionsComponent,
    QuestionHomeComponent,
    QuestionCategoriesComponent,
    QuestionsSingleComponent,
    QuestionsStatsComponent,
    QuestionsNavComponent,
    QuestionsSettingsComponent,
    VideosComponent,
    VideosSidebarComponent,
    VideosHomeComponent,
    VideosPlayerComponent,
    GroupAlbumComponent,
    ProfileAlbumComponent,
    DatepickerDirective,
    DragdropDirective,
    PasteDirective,
    InputDirective,
    WatchComponent,
    VideoComponent,
    MyChannelComponent,
    FeedMenuComponent,
    FriendRequestComponent,
    PagePhotoLightboxContainerComponent,
    GroupMemberRequestsDropdownComponent,
    GroupPostRequestComponent,
    PostRequestGroupComponent,
    GroupConfirmLeaveDialogComponent,
    GroupConfirmConversionAdminDialogComponent,
    GroupConfirmCancelAdminDialogComponent,
    ProfileFriendItemComponent,
    NotificationGroupDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    NgxLinkPreviewModule,
    Ng2SearchPipeModule,
    RouterModule,
    MaterialModule,
  ],
  providers: [NgxImageCompressService],
  exports: [DateAgoPipe],
})
export class ComponentsModule {}
