<a class="icon-link is-secondary is-friends">
	<i-feather name="heart"></i-feather>
	<span class="indicator"></span>
</a>
<div class="nav-drop">
	<div class="inner">
		<div class="nav-drop-header">
			<span>Friend requests</span>
			<a href="#">
				<i-feather name="search"></i-feather>
			</a>
		</div>
		<div
			class="nav-drop-body is-friend-requests"
			*ngIf="friendRequests && friendRequests.length > 0"
		>
			<ng-container *ngFor="let request of friendRequests">
				<div class="media" *ngIf="!request.friend">
					<figure class="media-left">
						<p class="image">
							<img
								[src]="request.senderId.avatar"
								data-demo-src="assets/img/avatars/dan.jpg"
								alt
							/>
						</p>
					</figure>
					<div class="media-content">
						<a href="#">
							{{ request.senderId.firstname }} {{ request.senderId.lastname }}
						</a>
						<span>You have 4 common friends</span>
					</div>
					<div class="media-right">
						<button
							class="button icon-button is-solid grey-button raised"
							(click)="createFriend(request)"
						>
							<i-feather name="user-plus"></i-feather>
						</button>
						<button
							(click)="deleteFriend(request)"
							class="button icon-button is-solid grey-button raised"
						>
							<i-feather name="user-minus"></i-feather>
						</button>
					</div>
				</div>
				<div class="media" *ngIf="request.friend">
					<figure class="media-left">
						<p class="image">
							<img
								[src]="request.senderId.avatar"
								data-demo-src="assets/img/avatars/elise.jpg"
								alt
							/>
						</p>
					</figure>
					<div class="media-content">
						<span>
							You are now friends with
							<a href="#">
								{{ request.senderId.firstname }}
								{{ request.senderId.lastname }}
							</a>
							. Check her
							<a href="#">profile</a>
							.
						</span>
					</div>
					<div class="media-right">
						<div class="added-icon">
							<i-feather name="tag"></i-feather>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
		<div class="nav-drop-footer">
			<a href="#">View All</a>
		</div>
	</div>
</div>
