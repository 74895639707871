<div class="chat-wrapper is-active">
	<div class="chat-inner is-active" [ngClass]="{'list-expanded': listExpanded}">
		<app-chat-nav [infoFriend]="infoFriend"></app-chat-nav>
		<div id="chat-sidebar" class="users-sidebar">
			<!-- Header -->
			<a class="header-item">
				<img
					src="assets/img/logo/AZ-char.png"
					alt
					routerLink="/"
					*ngIf="!listExpanded"
				/>
				<img
					src="assets/img/logo/AZ-blue.png"
					alt
					routerLink="/"
					*ngIf="listExpanded"
				/>
			</a>
			<div class="expanded">
				<app-conversations-list
					[listExpanded]="listExpanded"
					[friends]="friends"
					(selectedFriendChanged)="selectedFriendChanged($event)"
				></app-conversations-list>
				<div class="menu-expanded">
					<i
						(click)="changeToViewFullListDetail()"
						*ngIf="!listExpanded"
						class="mdi mdi-chevron-double-right"
					></i>
					<i
						(click)="changeToViewFullListDetail()"
						*ngIf="listExpanded"
						class="mdi mdi-chevron-double-left"
					></i>
				</div>
			</div>
			<!-- <div class="footer-item">
			<div class="add-button modal-trigger" data-modal="add-conversation-modal">
				<i-feather name="user"></i-feather>
			</div>
		</div> -->
		</div>
		<div
			id="chat-body"
			class="chat-body"
			[ngClass]="{'is-opened': showDetails == false}"
		>
			<app-person-conversation
				*ngIf="selectedMessages"
				[infoFriend]="infoFriend"
				[dataMessages]="selectedMessages"
			></app-person-conversation>
		</div>
		<div
			id="chat-panel"
			class="chat-panel"
			[ngClass]="{'is-opened': showDetails == false}"
		>
			<div class="panel-inner">
				<div class="panel-header">
					<h3>Details</h3>
				</div>
				<app-person-detail [infoFriend]="infoFriend"></app-person-detail>
			</div>
		</div>
		<div
			class="panel-close"
			[ngStyle]="{transform: showDetails ? 'rotate(45deg)' : ''}"
			(click)="showDetails = !showDetails"
		>
			<i-feather name="x"></i-feather>
		</div>
	</div>
	<app-add-conversation-modal></app-add-conversation-modal>
</div>
