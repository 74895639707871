<div class="videos-wrapper has-player">
	<div class="player-wrapper">
		<div class="video-side">
			<div class="image">
				<div class="plyr__video-embed" id="player">
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/LfgXPdqQBnA"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
						allow="autoplay"
					></iframe>
				</div>
			</div>
			<div class="meta-box video-meta">
				<div class="video-title">Dear Influencers</div>
				<div class="video-subtitle">
					<div class="views-count">457 223 views</div>
					<div class="quick-actions">
						<a class="quick-action">
							<i-feather name="thumbs-up"></i-feather>
						</a>
						<a class="quick-action">
							<i-feather name="thumbs-down"></i-feather>
						</a>
						<a class="quick-action">
							<i-feather name="share-2"></i-feather>
						</a>
						<a class="quick-action">
							<i-feather name="save"></i-feather>
						</a>
						<a class="quick-action">
							<i-feather name="cast"></i-feather>
						</a>
						<a class="quick-action is-mobile related-trigger">
							<i-feather name="more-vertical"></i-feather>
						</a>
					</div>
				</div>

				<div class="video-owner">
					<img
						src="https://via.placeholder.com/150x150"
						data-demo-src="https://yt3.ggpht.com/a/AATXAJwGpWL7g1YRC4p5kDEJRyaCDSGCk5u48UqmUqN_sw=s88-c-k-c0xffffffff-no-rj-mo"
						alt=""
					/>
					<div class="meta">
						<span>Marta Diaz</span>
						<span>Uploaded 10 days ago</span>
					</div>
					<a class="button">Follow 1.52M</a>
				</div>
			</div>
			<div class="meta-box video-description">
				<h4>Description</h4>
				<p>
					Quis non odit sordidos, vanos, leves, futtiles? Vide, quantum, inquam,
					fallare, Torquate. Duo Reges: constructio interrete. Vestri haec
					verecundius, illi fortasse constantius. Si longus, levis dictata sunt.
					Magna laus.
				</p>

				<div class="additional-description content">
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Murenam te
						accusante defenderem. Apud ceteros autem philosophos, qui quaesivit
						aliquid, tacet;
					</p>
					<p>
						<a class="standard-link" href="http://loripsum.net/" target="_blank"
							>Qui est in parvis malis.</a
						>
						Non potes, nisi retexueris illa.
						<a class="standard-link" href="http://loripsum.net/" target="_blank"
							>Sed fortuna fortis;</a
						>
						Duo Reges: constructio interrete.
						<a class="standard-link" href="http://loripsum.net/" target="_blank"
							>Primum divisit ineleganter;</a
						>
					</p>
					<ul>
						<li>
							Quid, si non sensus modo ei sit datus, verum etiam animus hominis?
						</li>
						<li>Re mihi non aeque satisfacit, et quidem locis pluribus.</li>
						<li>
							Ergo opifex plus sibi proponet ad formarum quam civis excellens ad
							factorum pulchritudinem?
						</li>
						<li>
							Animadverti, ínquam, te isto modo paulo ante ponere, et scio ab
							Antiocho nostro dici sic solere;
						</li>
					</ul>
					<p>
						Sed residamus, inquit, si placet. Dici enim nihil potest verius. Ita
						relinquet duas, de quibus etiam atque etiam consideret. Haec
						para/doca illi, nos admirabilia dicamus. Nescio quo modo
						praetervolavit oratio. Ut pulsi recurrant? A mene tu? Summus dolor
						plures dies manere non potest?
					</p>
				</div>

				<hr />
				<a
					id="description-show-more"
					class="has-text-centered video-description-more"
					>Show More</a
				>
			</div>
			<div class="comments-wrap">
				<div class="media comment-box">
					<div class="media-left">
						<div class="avatar-wrap">
							<img
								src="https://via.placeholder.com/150x150"
								data-demo-src="assets/img/avatars/jenna.png"
								data-user-popover="0"
								alt=""
							/>
							<div class="badge">
								<i-feather name="check"></i-feather>
							</div>
						</div>
					</div>
					<div class="media-content">
						<div class="control">
							<textarea
								class="textarea"
								placeholder="Add a comment..."
								rows="4"
							></textarea>
							<a class="button">Post Comment</a>
						</div>
					</div>
				</div>

				<div class="comments-count">
					<h3>Comments (9)</h3>
				</div>

				<div class="media is-comment">
					<div class="media-left">
						<div class="avatar-wrap is-smaller">
							<img
								src="https://via.placeholder.com/150x150"
								data-demo-src="assets/img/avatars/dan.jpg"
								
								alt=""
							/>
							<div class="badge">
								<i-feather name="check"></i-feather>
							</div>
						</div>
					</div>
					<div class="media-content">
						<div class="comment-meta">
							<h4><a>Dan Walker</a> <small> · 3 hours ago</small></h4>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
								porta eros lacus, nec ultricies elit blandit non. Suspendisse
								pellentesque mauris sit amet dolor blandit rutrum. Nunc in
								tempus turpis.
							</p>
						</div>
						<div class="comment-stats-wrap">
							<div class="comment-stats">
								<div class="stat is-likes">
									<i-feather name="thumbs-up"></i-feather>
									<span>23</span>
								</div>
								<div class="stat is-dislikes">
									<i-feather name="thumbs-down"></i-feather>
									<span>3</span>
								</div>
							</div>
							<div class="comment-actions">
								<a class="comment-action is-like">
									<i-feather name="thumbs-up"></i-feather>
								</a>
								<a class="comment-action is-dislike">
									<i-feather name="thumbs-down"></i-feather>
								</a>
								<a class="comment-action is-reply">
									<i-feather name="message-circle"></i-feather>
								</a>
							</div>
						</div>
						<div class="nested-replies">
							<a class="header">
								<span>5 Replies</span>
								<i-feather name="chevron-down"></i-feather>
							</a>
							<div class="nested-comments">
								<!--Nested comment -->
								<div class="media is-comment is-nested">
									<figure class="media-left">
										<div class="avatar-wrap is-smaller">
											<img
												src="https://via.placeholder.com/150x150"
												data-demo-src="assets/img/avatars/gaelle.jpeg"
												data-user-popover="11"
												alt=""
											/>
										</div>
									</figure>
									<div class="media-content">
										<div class="comment-meta">
											<h4>
												<a>Gaelle Morris</a> <small> · 30 minutes ago</small>
											</h4>
											<p>
												<span class="mention">@Dan Walker</span> Lorem ipsum
												dolor sit amet, consectetur adipiscing elit. Duis porta
												eros lacus, nec ultricies elit blandit non. Suspendisse
												pellentesque mauris sit amet dolor blandit rutrum. Nunc
												in tempus turpis.
											</p>
										</div>
										<div class="comment-stats-wrap">
											<div class="comment-stats">
												<div class="stat is-likes">
													<i-feather name="thumbs-up"></i-feather>
													<span>2</span>
												</div>
												<div class="stat is-dislikes">
													<i-feather name="thumbs-down"></i-feather>
													<span>0</span>
												</div>
											</div>
											<div class="comment-actions">
												<a class="comment-action is-like">
													<i-feather name="thumbs-up"></i-feather>
												</a>
												<a class="comment-action is-dislike">
													<i-feather name="thumbs-down"></i-feather>
												</a>
												<a class="comment-action is-reply">
													<i-feather name="message-circle"></i-feather>
												</a>
											</div>
										</div>
									</div>
								</div>
								<!--Nested comment -->
								<div class="media is-comment is-nested">
									<figure class="media-left">
										<div class="avatar-wrap is-smaller">
											<img
												src="https://via.placeholder.com/150x150"
												data-demo-src="assets/img/avatars/rolf.jpg"
												data-user-popover="13"
												alt=""
											/>
											<div class="badge">
												<i-feather name="check"></i-feather>
											</div>
										</div>
									</figure>
									<div class="media-content">
										<div class="comment-meta">
											<h4>
												<a>Rolf Krupp</a> <small> · 50 minutes ago</small>
											</h4>
											<p>
												<span class="mention">@Gaelle Morris</span> Lorem ipsum
												dolor sit amet, consectetur adipiscing elit. Duis porta
												eros lacus, nec ultricies elit blandit non.
											</p>
										</div>
										<div class="comment-stats-wrap">
											<div class="comment-stats">
												<div class="stat is-likes">
													<i-feather name="thumbs-up"></i-feather>
													<span>1</span>
												</div>
												<div class="stat is-dislikes">
													<i-feather name="thumbs-down"></i-feather>
													<span>0</span>
												</div>
											</div>
											<div class="comment-actions">
												<a class="comment-action is-like">
													<i-feather name="thumbs-up"></i-feather>
												</a>
												<a class="comment-action is-dislike">
													<i-feather name="thumbs-down"></i-feather>
												</a>
												<a class="comment-action is-reply">
													<i-feather name="message-circle"></i-feather>
												</a>
											</div>
										</div>
									</div>
								</div>
								<!--Nested comment -->
								<div class="media is-comment is-nested">
									<figure class="media-left">
										<div class="avatar-wrap is-smaller">
											<img
												src="https://via.placeholder.com/150x150"
												data-demo-src="assets/img/avatars/bobby.jpg"
												data-user-popover="8"
												alt=""
											/>
										</div>
									</figure>
									<div class="media-content">
										<div class="comment-meta">
											<h4><a>Bobby Brown</a> <small> · 1 hour ago</small></h4>
											<p>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit.
											</p>
										</div>
										<div class="comment-stats-wrap">
											<div class="comment-stats">
												<div class="stat is-likes">
													<i-feather name="thumbs-up"></i-feather>
													<span>5</span>
												</div>
												<div class="stat is-dislikes">
													<i-feather name="thumbs-down"></i-feather>
													<span>1</span>
												</div>
											</div>
											<div class="comment-actions">
												<a class="comment-action is-like">
													<i-feather name="thumbs-up"></i-feather>
												</a>
												<a class="comment-action is-dislike">
													<i-feather name="thumbs-down"></i-feather>
												</a>
												<a class="comment-action is-reply">
													<i-feather name="message-circle"></i-feather>
												</a>
											</div>
										</div>
									</div>
								</div>
								<!--Nested comment -->
								<div class="media is-comment is-nested">
									<figure class="media-left">
										<div class="avatar-wrap is-smaller">
											<img
												src="https://via.placeholder.com/150x150"
												data-demo-src="assets/img/avatars/stella.jpg"
												data-user-popover="2"
												alt=""
											/>
										</div>
									</figure>
									<div class="media-content">
										<div class="comment-meta">
											<h4>
												<a>Stella Bergmann</a> <small> · 2 hours ago</small>
											</h4>
											<p>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit.
												Duis porta eros lacus, nec ultricies elit blandit non.
											</p>
										</div>
										<div class="comment-stats-wrap">
											<div class="comment-stats">
												<div class="stat is-likes">
													<i-feather name="thumbs-up"></i-feather>
													<span>0</span>
												</div>
												<div class="stat is-dislikes">
													<i-feather name="thumbs-down"></i-feather>
													<span>0</span>
												</div>
											</div>
											<div class="comment-actions">
												<a class="comment-action is-like">
													<i-feather name="thumbs-up"></i-feather>
												</a>
												<a class="comment-action is-dislike">
													<i-feather name="thumbs-down"></i-feather>
												</a>
												<a class="comment-action is-reply">
													<i-feather name="message-circle"></i-feather>
												</a>
											</div>
										</div>
									</div>
								</div>
								<!--Nested comment -->
								<div class="media is-comment is-nested">
									<figure class="media-left">
										<div class="avatar-wrap is-smaller">
											<img
												src="https://via.placeholder.com/150x150"
												data-demo-src="assets/img/avatars/edward.jpeg"
												data-user-popover="5"
												alt=""
											/>
										</div>
									</figure>
									<div class="media-content">
										<div class="comment-meta">
											<h4>
												<a>Edward Mayers</a> <small> · 3 hours ago</small>
											</h4>
											<p>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit.
												Duis porta eros lacus, nec ultricies elit blandit non.
											</p>
										</div>
										<div class="comment-stats-wrap">
											<div class="comment-stats">
												<div class="stat is-likes">
													<i-feather name="thumbs-up"></i-feather>
													<span>6</span>
												</div>
												<div class="stat is-dislikes">
													<i-feather name="thumbs-down"></i-feather>
													<span>0</span>
												</div>
											</div>
											<div class="comment-actions">
												<a class="comment-action is-like">
													<i-feather name="thumbs-up"></i-feather>
												</a>
												<a class="comment-action is-dislike">
													<i-feather name="thumbs-down"></i-feather>
												</a>
												<a class="comment-action is-reply">
													<i-feather name="message-circle"></i-feather>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Comment -->
				<div class="media is-comment">
					<figure class="media-left">
						<div class="avatar-wrap is-smaller">
							<img
								src="https://via.placeholder.com/150x150"
								data-demo-src="assets/img/avatars/milly.jpg"
								data-user-popover="7"
								alt=""
							/>
						</div>
					</figure>
					<div class="media-content">
						<div class="comment-meta">
							<h4><a>Milly Augutine</a> <small> · 4 hours ago</small></h4>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
						</div>
						<div class="comment-stats-wrap">
							<div class="comment-stats">
								<div class="stat is-likes">
									<i-feather name="thumbs-up"></i-feather>
									<span>8</span>
								</div>
								<div class="stat is-dislikes">
									<i-feather name="thumbs-down"></i-feather>
									<span>0</span>
								</div>
							</div>
							<div class="comment-actions">
								<a class="comment-action is-like">
									<i-feather name="thumbs-up"></i-feather>
								</a>
								<a class="comment-action is-dislike">
									<i-feather name="thumbs-down"></i-feather>
								</a>
								<a class="comment-action is-reply">
									<i-feather name="message-circle"></i-feather>
								</a>
							</div>
						</div>
					</div>
				</div>

				<div class="media is-comment">
					<figure class="media-left">
						<div class="avatar-wrap is-smaller">
							<img
								src="https://via.placeholder.com/150x150"
								data-demo-src="assets/img/avatars/elise.jpg"
								data-user-popover="6"
								alt=""
							/>
						</div>
					</figure>
					<div class="media-content">
						<div class="comment-meta">
							<h4><a>Elise Walker</a> <small> · 5 hours ago</small></h4>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
								porta eros lacus, nec ultricies elit blandit non.
							</p>
						</div>
						<div class="comment-stats-wrap">
							<div class="comment-stats">
								<div class="stat is-likes">
									<i-feather name="thumbs-up"></i-feather>
									<span>3</span>
								</div>
								<div class="stat is-dislikes">
									<i-feather name="thumbs-down"></i-feather>
									<span>0</span>
								</div>
							</div>
							<div class="comment-actions">
								<a class="comment-action is-like">
									<i-feather name="thumbs-up"></i-feather>
								</a>
								<a class="comment-action is-dislike">
									<i-feather name="thumbs-down"></i-feather>
								</a>
								<a class="comment-action is-reply">
									<i-feather name="message-circle"></i-feather>
								</a>
							</div>
						</div>
					</div>
				</div>

				<div class="media is-comment">
					<figure class="media-left">
						<div class="avatar-wrap is-smaller">
							<img
								src="https://via.placeholder.com/150x150"
								data-demo-src="assets/img/avatars/placeholder-m.jpg"
								alt=""
							/>
						</div>
					</figure>
					<div class="media-content">
						<div class="comment-meta">
							<h4><a>John Doe</a> <small> · 5 hours ago</small></h4>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
								porta eros lacus, nec ultricies elit blandit non.
							</p>
						</div>
						<div class="comment-stats-wrap">
							<div class="comment-stats">
								<div class="stat is-likes">
									<i-feather name="thumbs-up"></i-feather>
									<span>12</span>
								</div>
								<div class="stat is-dislikes">
									<i-feather name="thumbs-down"></i-feather>
									<span>4</span>
								</div>
							</div>
							<div class="comment-actions">
								<a class="comment-action is-like">
									<i-feather name="thumbs-up"></i-feather>
								</a>
								<a class="comment-action is-dislike">
									<i-feather name="thumbs-down"></i-feather>
								</a>
								<a class="comment-action is-reply">
									<i-feather name="message-circle"></i-feather>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="related-side">
			<div class="meta-box related-list">
				<div class="autoplay">
					<h4 class="autoplay-title">Up next</h4>
					<div class="autoplay-toggle">
						<span>Autoplay</span>
						<label class="f-switch is-primary">
							<input type="checkbox" class="is-switch" checked />
							<i></i>
						</label>
						<div class="close-related-videos">
							<i-feather name="x"></i-feather>
						</div>
					</div>
				</div>
				<!-- Related Video -->
				<div class="media related-card">
					<div class="media-left">
						<a class="image">
							<img
								src="https://via.placeholder.com/120x90"
								data-demo-src="assets/img/demo/video/home/collections/c17.jpg"
								alt=""
							/>
						</a>
					</div>
					<div class="media-content">
						<div class="video-meta">
							<a class="related-title">The Old Caban</a>
							<div class="submeta">
								<img
									src="https://via.placeholder.com/150x150"
									data-demo-src="assets/img/icons/logos/metamovies.svg"
									data-page-popover="2"
									alt=""
								/>
								<div class="info">
									<span class="video-account">Meta Movies</span>
									<span class="video-views">139K views</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="related-label">
					<h4 class="related-title">Suggested</h4>
				</div>
				<!-- Related Video -->
				<div class="media related-card">
					<div class="media-left">
						<a class="image">
							<img
								src="https://via.placeholder.com/120x90"
								data-demo-src="assets/img/demo/video/home/collections/c18.jpg"
								alt=""
							/>
						</a>
					</div>
					<div class="media-content">
						<div class="video-meta">
							<a class="related-title">Eaten Alive</a>
							<div class="submeta">
								<img
									src="https://via.placeholder.com/150x150"
									data-demo-src="assets/img/icons/logos/metamovies.svg"
									data-page-popover="2"
									alt=""
								/>
								<div class="info">
									<span class="video-account">Meta Movies</span>
									<span class="video-views">98K views</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Related Video -->
				<div class="media related-card">
					<div class="media-left">
						<a class="image">
							<img
								src="https://via.placeholder.com/120x90"
								data-demo-src="assets/img/demo/video/home/collections/c19.jpg"
								alt=""
							/>
						</a>
					</div>
					<div class="media-content">
						<div class="video-meta">
							<a class="related-title">Forgotten</a>
							<div class="submeta">
								<img
									src="https://via.placeholder.com/150x150"
									data-demo-src="assets/img/icons/logos/metamovies.svg"
									data-page-popover="2"
									alt=""
								/>
								<div class="info">
									<span class="video-account">Meta Movies</span>
									<span class="video-views">479K views</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Related Video -->
				<div class="media related-card">
					<div class="media-left">
						<a class="image">
							<img
								src="https://via.placeholder.com/120x90"
								data-demo-src="assets/img/demo/video/home/collections/c20.jpg"
								alt=""
							/>
						</a>
					</div>
					<div class="media-content">
						<div class="video-meta">
							<a class="related-title">The Wastelands</a>
							<div class="submeta">
								<img
									src="https://via.placeholder.com/150x150"
									data-demo-src="assets/img/icons/logos/metamovies.svg"
									data-page-popover="2"
									alt=""
								/>
								<div class="info">
									<span class="video-account">Meta Movies</span>
									<span class="video-views">112K views</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Related Video -->
				<div class="media related-card">
					<div class="media-left">
						<a class="image">
							<img
								src="https://via.placeholder.com/120x90"
								data-demo-src="assets/img/demo/video/home/collections/c3.jpg"
								alt=""
							/>
						</a>
					</div>
					<div class="media-content">
						<div class="video-meta">
							<a class="related-title">I was at the VidCon 2019</a>
							<div class="submeta">
								<img
									src="https://via.placeholder.com/150x150"
									data-demo-src="assets/img/avatars/dan.jpg"
									
									alt=""
								/>
								<div class="info">
									<span class="video-account">Dan Walker</span>
									<span class="video-views">265 032 views</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Related Video -->
				<div class="media related-card">
					<div class="media-left">
						<a class="image">
							<img
								src="https://via.placeholder.com/120x90"
								data-demo-src="assets/img/demo/video/home/collections/c1.jpg"
								alt=""
							/>
						</a>
					</div>
					<div class="media-content">
						<div class="video-meta">
							<a class="related-title">The best keyboards for 2019!</a>
							<div class="submeta">
								<img
									src="https://via.placeholder.com/150x150"
									data-demo-src="assets/img/avatars/brian.jpg"
									data-user-popover="19"
									alt=""
								/>
								<div class="info">
									<span class="video-account">Brian Stevenson</span>
									<span class="video-views">8167 views</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Related Video -->
				<div class="media related-card">
					<div class="media-left">
						<a class="image">
							<img
								src="https://via.placeholder.com/120x90"
								data-demo-src="assets/img/demo/video/home/collections/c6.jpg"
								alt=""
							/>
						</a>
					</div>
					<div class="media-content">
						<div class="video-meta">
							<a class="related-title">Make your makeups glossy</a>
							<div class="submeta">
								<img
									src="https://via.placeholder.com/150x150"
									data-demo-src="assets/img/avatars/milly.jpg"
									data-user-popover="7"
									alt=""
								/>
								<div class="info">
									<span class="video-account">Milly Augustine</span>
									<span class="video-views">8167 views</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Related Video -->
				<div class="media related-card">
					<div class="media-left">
						<a class="image">
							<img
								src="https://via.placeholder.com/120x90"
								data-demo-src="assets/img/demo/video/home/collections/c14.jpg"
								alt=""
							/>
						</a>
					</div>
					<div class="media-content">
						<div class="video-meta">
							<a class="related-title">I tried the new Bolt 403</a>
							<div class="submeta">
								<img
									src="https://via.placeholder.com/150x150"
									data-demo-src="assets/img/avatars/edward.jpeg"
									data-user-popover="5"
									alt=""
								/>
								<div class="info">
									<span class="video-account">Edward Mayers</span>
									<span class="video-views">8167 views</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Related Video -->
				<div class="media related-card">
					<div class="media-left">
						<a class="image">
							<img
								src="https://via.placeholder.com/120x90"
								data-demo-src="assets/img/demo/video/home/collections/c10.jpg"
								alt=""
							/>
						</a>
					</div>
					<div class="media-content">
						<div class="video-meta">
							<a class="related-title">Raspberry cupcakes in 5 minutes</a>
							<div class="submeta">
								<img
									src="https://via.placeholder.com/150x150"
									data-demo-src="assets/img/avatars/mike.jpg"
									data-user-popover="12"
									alt=""
								/>
								<div class="info">
									<span class="video-account">Mike Lasalle</span>
									<span class="video-views">8167 views</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
