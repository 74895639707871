<div class="box-heading">
	<h4>Videos</h4>
	<div class="dropdown is-neutral is-spaced is-right dropdown-trigger">
		<div>
			<div class="button">
				<i-feather name="more-vertical"></i-feather>
			</div>
		</div>
		<div class="dropdown-menu" role="menu">
			<div class="dropdown-content">
				<a class="dropdown-item">
					<div class="media">
						<i-feather name="video"></i-feather>
						<div class="media-content">
							<h3>View Videos</h3>
							<small>View all your videos</small>
						</div>
					</div>
				</a>
				<a href="#" class="dropdown-item">
					<div class="media">
						<i-feather name="tag"></i-feather>
						<div class="media-content">
							<h3>Tagged</h3>
							<small>View videos you are tagged in.</small>
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</div>
<div class="is-videos-widget">
	<div class="video-container" *ngFor="let video of videos">
		<img
			src="https://via.placeholder.com/200x200"
			data-demo-src="assets/img/demo/widgets/videos/1.jpg"
			alt
		/>
		<div class="video-button">
			<img src="assets/img/icons/video/play.svg" alt />
		</div>
		<div class="video-overlay"></div>
	</div>
</div>
