import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { GroupService } from 'src/app/services/group/group.service';
import { ProfileService } from '../../../../services/profile.service';
import * as enviroment from '../../../../../environments/environment';
import { GroupConfirmLeaveDialogComponent } from './group-confirm-leave-dialog/group-confirm-leave-dialog.component';
import { GroupConfirmConversionAdminDialogComponent } from './group-confirm-conversion-admin-dialog/group-confirm-conversion-admin-dialog.component';
import { GroupConfirmCancelAdminDialogComponent } from './group-confirm-cancel-admin-dialog/group-confirm-cancel-admin-dialog.component';
import iziToast from 'izitoast';
@Component({
  selector: 'app-group-members',
  templateUrl: './group-members.component.html',
  styleUrls: ['./group-members.component.scss'],
})
export class GroupMembersComponent implements OnInit {
  @Input() set dataGroup(dataGroup: any) {
    this.dataGroups = dataGroup;
    this.getMembersByGroupId(this.dataGroups._id, 0, 5);
  }
  @Input() isHighAdmin;
  @Input() userId;

  dataGroups;
  keySearch: string;
  enviroment;
  isModalConfirm;
  constructor(
    private groupService: GroupService,
    public dialog: MatDialog,
    private profile: ProfileService
  ) {
    this.enviroment = enviroment.environment.apiUrl;
  }

  ngOnInit(): void {
    console.log(this.dataGroups);
    this.isModalConfirm = false;
  }

  arrMemberSearch = [];
  searchMember(nameMember) {
    if (nameMember) {
      let req = {
        memberName: nameMember,
        groupId: this.dataGroups._id,
      };
      this.groupService.searchMember(req).subscribe((result) => {
        if (result.status == 'success') {
          this.arrMemberSearch = result.data;
        }
      });
      return;
    }
    if (!nameMember) {
      this.arrMemberSearch = [];
      return;
    }
  }

  //#endregion FUNCTION see all member
  getMore(skip, limit) {
    this.getMembersByGroupId(this.dataGroups._id, skip, limit);
  }
  //#region

  //#region FUNCTION init get user in group
  initUser(arrMember, arrAdmin) {
    for (let user of arrMember) {
      user.isModalActive = false;
      user.isAdmin = false;
      user.memberPosition = '';
      for (let admin of arrAdmin) {
        if (
          user.memberId?._id == admin.groupAdmin?._id &&
          user.groupId == admin.groupId
        ) {
          user.memberPosition = 'Administrator';
          user.isAdmin = true;
        }
      }
    }
  }
  //#endregion

  //#region Fucntion get member of group
  arrMember = [];
  countMember;
  getMembersByGroupId(groupId, skip, limit) {
    this.arrMember = [];
    let reqMember = {
      groupId: groupId,
      skip: skip,
      limit: limit,
    };
    this.groupService.getMembers(reqMember).subscribe((result) => {
      if (result.status == 'success') {
        this.arrMember = result.data;
        this.arrMember.forEach((mem) => {
          let data = {
            friendId: mem.memberId._id,
          };
          mem.isFriend = false;

          this.profile.checkFriend(data).subscribe((result) => {
            if (result.status == 'success') {
              if (result.data != '') {
                mem.isFriend = true;
              }
            }
          });

          let data2 = {
            receiverId: mem.memberId._id,
          };
          mem.sendedFriendRequest = false;
          this.profile.checkFriendRequest(data2).subscribe((result) => {
            if (result.status == 'success') {
              if (result.data != '') {
                mem.sendedFriendRequest = true;
              }
            }
          });
        });
        this.countMember = this.nFormatter(result.count, 1);
        this.getAdminByGroupId(groupId, this.arrMember);
        console.log(this.arrMember);
      }
    });
  }
  //#endregion

  //#region OPEN modal managing
  closeModal(event) {
    if (event.isClose == true) {
      this.isModalConfirm = false;
      this.isBlock = false;
      this.isMakeAdmin = false;
      this.isRemoveMember = false;
    }
  }
  openModalManaging(user) {
    this.isModalConfirm = false;
    this.isBlock = false;
    this.isMakeAdmin = false;
    this.isRemoveMember = false;
    if (user.groupAdmin) {
      for (let i = 0; i < this.arrAdmin.length; i++) {
        if (user.groupAdmin._id === this.arrAdmin[i].groupAdmin._id) {
          this.arrAdmin[i].isModalActive = !this.arrAdmin[i].isModalActive;
        } else {
          this.arrAdmin[i].isModalActive = false;
        }
      }
      return;
    }
    if (user.memberId) {
      for (let i = 0; i < this.arrMember.length; i++) {
        if (user.memberId?._id === this.arrMember[i].memberId?._id) {
          this.arrMember[i].isModalActive = !this.arrMember[i].isModalActive;
        } else {
          this.arrMember[i].isModalActive = false;
        }
      }
      return;
    }
  }
  //#endregion

  //#region FUNCTION managing admin
  isBlock;
  isMakeAdmin;
  isRemoveMember;
  dataMember;
  openConfirm(functionId, dataMember) {
    console.log(dataMember);
    this.isModalConfirm = true;
    this.dataMember = dataMember;
    if (functionId == 1) {
      this.isBlock = true;
      this.isMakeAdmin = false;
      this.isRemoveMember = false;
      return;
    }
    if (functionId == 2) {
      this.isBlock = false;
      this.isMakeAdmin = true;
      this.isRemoveMember = false;
      return;
    }
    if (functionId == 3) {
      this.isBlock = false;
      this.isMakeAdmin = false;
      this.isRemoveMember = true;
      return;
    }
  }
  //#endregion

  //#region FUNCTION recevice data from modal confirm
  callFunction(event) {
    if (event.status === 'success') {
      this.getMembersByGroupId(this.dataGroups._id, 0, 5);
    }
  }
  //#endregion

  //#region FUNCTION get admin of group
  arrAdmin = [];
  countAdmin;
  isAdmin = false; // variable to check if user logged are admin but not first admin off group
  getAdminByGroupId(groupId, arrMember) {
    this.groupService.getAdminGroup(groupId).subscribe((result) => {
      if (result.status == 'success') {
        this.arrAdmin = result.data;
        this.countAdmin = this.nFormatter(result.count, 1);
        this.initUser(arrMember, this.arrAdmin);
        this.arrAdmin.forEach((admin) => {
          admin.isModalActive = false;
          admin.isAdmin = true;
          admin.memberPosition = 'Administrator';

          // use to check admin that not first admin of group
          if (!this.isHighAdmin) {
            if (this.userId == admin.groupAdmin._id) {
              this.isAdmin = true;
            }
          }
        });
      }
    });
  }

  //#endregion

  //#region member function

  confirmLeaveGroup(member) {
    const dialogRef = this.dialog.open(GroupConfirmLeaveDialogComponent, {
      width: '500px',
      data: {
        groupName: this.dataGroups.groupName,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.status == 'yes') {
        let data = {
          groupId: this.dataGroups._id,
          memberId: member.memberId._id,
        };
        this.groupService.leaveGroup(data).subscribe((res) => {
          this.arrMember.splice(member);
          if (member?.isAdmin) {
            let data = {
              groupAdmin: member.memberId._id,
              groupId: this.dataGroups._id,
            };
            this.groupService.deleteAdmin(data).subscribe((result) => {
              this.getMembersByGroupId(this.dataGroups._id, 0, 5);
            });
          }
          this.refresh();
        });
      }
    });
  }

  addFriendMember(member) {
    let data = {
      receiverId: member.memberId._id,
    };
    this.profile.friendRequest(data).subscribe((result) => {
      if ((result.status = 'success')) {
        member.sendedFriendRequest = true;
      }
    });
  }

  cancelFriendRequest(member) {
    let data = {
      receiverId: member.memberId._id,
    };

    this.profile.deleteFriendRequest(data).subscribe((result) => {
      if (result.status == 'success') {
        member.sendedFriendRequest = false;
      }
    });
  }

  //#endregion

  //#region admin function
  conversionHighAdmin(admin) {
    console.log(admin);
    const dialogRef = this.dialog.open(
      GroupConfirmConversionAdminDialogComponent,
      {
        width: '500px',
        data: {
          userName:
            admin.groupAdmin.firstname + ' ' + admin.groupAdmin.lastname,
          groupName: this.dataGroups.groupName,
        },
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result.status == 'yes') {
        if (this.isHighAdmin) {
          let data = {
            id: this.dataGroups._id,
            userId: admin.groupAdmin._id,
          };
          this.groupService.changeAdminGroup(data).subscribe((result) => {
            this.getMembersByGroupId(this.dataGroups._id, 0, 5);
            this.refresh();
          });
        }
      }
    });
  }

  cancelAdmin(admin) {
    const dialogRef = this.dialog.open(GroupConfirmCancelAdminDialogComponent, {
      width: '500px',
      data: {
        adminName: admin.groupAdmin.firstname + ' ' + admin.groupAdmin.lastname,
        groupName: this.dataGroups.groupName,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.status == 'yes') {
        if (this.isHighAdmin) {
          let data = {
            groupAdmin: admin.groupAdmin._id,
            groupId: this.dataGroups._id,
          };
          this.groupService.deleteAdmin(data).subscribe((result) => {
            this.getMembersByGroupId(this.dataGroups._id, 0, 5);
          });
        }
      }
    });
  }
  //#endregion

  refresh(): void {
    window.location.reload();
  }

  //#region FUNCTION format dram https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
  nFormatter(num, digits) {
    var si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
  }
  //#endregion
}
