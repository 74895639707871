<div class="feed-menu-v1 sticky-wrap-feed">
	<ul class="main-menu">
		<li [ngClass]="{'is-active': routeValue == '/'}">
			<a routerLink="/">
				<i-feather name="activity"></i-feather>
				<span>Feed</span>
				<span class="close-icon">
					<i-feather name="chevron-right"></i-feather>
				</span>
			</a>
			<!-- <ul class="submenu">
				<li class="is-subactive">
					<a
						>Following

						<span class="tag">4</span>
					</a>
				</li>
				<li>
					<a>Popular</a>
				</li>
				<li>
					<a>Trending</a>
				</li>
			</ul> -->
		</li>
		<li>
			<a routerLink="/groups/feed">
				<i-feather name="users"></i-feather>
				<span>Groups</span>
				<span class="close-icon">
					<i-feather name="chevron-right"></i-feather>
				</span>
			</a>
			<!-- <ul class="submenu">
				<li class="is-subactive">
					<a>Feed post group</a>
				</li>
			</ul> -->
		</li>
		<li>
			<a>
				<i-feather name="help-circle"></i-feather>
				<span>Questions</span>
				<span class="close-icon">
					<i-feather name="chevron-right"></i-feather>
				</span>
			</a>
			<ul class="submenu">
				<li>
					<a [routerLink]="['/questions/home']">Home</a>
				</li>
				<li>
					<a [routerLink]="['/questions/single']">My Questions</a>
				</li>
			</ul>
		</li>
		<li [ngClass]="{'is-active': routeValue == '/watch'}">
			<a routerLink="/watch">
				<i-feather name="youtube"></i-feather>
				<span>Videos</span>
				<span class="close-icon">
					<i-feather name="chevron-right"></i-feather>
				</span>
			</a>
			<!-- <ul class="submenu">
				<li class="is-subactive" routerLink="/watch">
					<a>
						Home
						<span class="tag">7</span>
					</a>
				</li>
				<li routerLink="/watch/my-channel">
					<a>My Channel</a>
				</li>
			</ul> -->
		</li>
		<!-- <li>
			<a>
				<i-feather name="shopping-cart"></i-feather>
				<span>Shopping</span>
				<span class="close-icon">
					<i-feather name="chevron-right"></i-feather>
				</span>
			</a>
			<ul class="submenu">
				<li>
					<a>
						Trending
						<span class="tag">2</span>
					</a>
				</li>
				<li>
					<a>
						Popular
						<span class="tag">5</span>
					</a>
				</li>
				<li>
					<a>My Products</a>
				</li>
			</ul>
		</li>
		<li>
			<a>
				<i-feather name="music"></i-feather>
				<span>Music</span>
				<span class="close-icon">
					<i-feather name="chevron-right"></i-feather>
				</span>
			</a>
			<ul class="submenu">
				<li>
					<a>My Products</a>
				</li>
				<li>
					<a>
						Trending
						<span class="tag">6</span>
					</a>
				</li>
				<li>
					<a>Popular</a>
				</li>
			</ul>
		</li> -->
	</ul>
</div>
