<app-nav-bar></app-nav-bar>
<div id="main-feed" class="container">
	<!-- <div class="pageloader"></div>
	<div class="infraloader is-active"></div>
	<div class="app-overlay"></div> -->
	<!-- <div id="shadow-dom" class="view-wrap">
		<div class="columns">
			<div class="column is-3">
				<div class="placeload is-bold questions-menu-placeload">
					<div class="inner-wrap">
						<div class="content-shape loads"></div>
						<div class="content-shape loads"></div>
						<div class="content-shape loads"></div>
						<div class="content-shape loads"></div>
					</div>
				</div>
			</div>

			<div class="column is-6">
				<div class="placeload compose-placeload">
					<div class="header">
						<div class="content-shape is-lg loads"></div>
						<div class="content-shape is-lg loads"></div>
						<div class="content-shape is-lg loads"></div>
					</div>
					<div class="body">
						<div class="img loads"></div>
						<div class="inner-wrap">
							<div class="content-shape loads"></div>
							<div class="content-shape loads"></div>
						</div>
					</div>
				</div>
				<div class="placeload post-placeload">
					<div class="header">
						<div class="img loads"></div>
						<div class="header-content">
							<div class="content-shape loads"></div>
							<div class="content-shape loads"></div>
						</div>
					</div>
					<div class="image-placeholder loads"></div>
					<div class="placeholder-footer">
						<div class="footer-block">
							<div class="img loads"></div>
							<div class="inner-wrap">
								<div class="content-shape loads"></div>
								<div class="content-shape loads"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="placeload post-placeload">
					<div class="header">
						<div class="img loads"></div>
						<div class="header-content">
							<div class="content-shape loads"></div>
							<div class="content-shape loads"></div>
						</div>
					</div>
					<div class="image-placeholder loads"></div>
					<div class="placeholder-footer">
						<div class="footer-block">
							<div class="img loads"></div>
							<div class="inner-wrap">
								<div class="content-shape loads"></div>
								<div class="content-shape loads"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="placeload post-placeload">
					<div class="header">
						<div class="img loads"></div>
						<div class="header-content">
							<div class="content-shape loads"></div>
							<div class="content-shape loads"></div>
						</div>
					</div>
					<div class="image-placeholder loads"></div>
					<div class="placeholder-footer">
						<div class="footer-block">
							<div class="img loads"></div>
							<div class="inner-wrap">
								<div class="content-shape loads"></div>
								<div class="content-shape loads"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="placeload post-placeload">
					<div class="header">
						<div class="img loads"></div>
						<div class="header-content">
							<div class="content-shape loads"></div>
							<div class="content-shape loads"></div>
						</div>
					</div>
					<div class="image-placeholder loads"></div>
					<div class="placeholder-footer">
						<div class="footer-block">
							<div class="img loads"></div>
							<div class="inner-wrap">
								<div class="content-shape loads"></div>
								<div class="content-shape loads"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="column is-3">
				<div class="placeload stories-placeload">
					<div class="header">
						<div class="content-shape loads"></div>
					</div>
					<div class="body">
						<div class="flex-block">
							<div class="img loads"></div>
							<div class="inner-wrap">
								<div class="content-shape loads"></div>
								<div class="content-shape loads"></div>
							</div>
						</div>
						<div class="flex-block">
							<div class="img loads"></div>
							<div class="inner-wrap">
								<div class="content-shape loads"></div>
								<div class="content-shape loads"></div>
							</div>
						</div>
						<div class="flex-block">
							<div class="img loads"></div>
							<div class="inner-wrap">
								<div class="content-shape loads"></div>
								<div class="content-shape loads"></div>
							</div>
						</div>
						<div class="flex-block">
							<div class="img loads"></div>
							<div class="inner-wrap">
								<div class="content-shape loads"></div>
								<div class="content-shape loads"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="placeload mini-widget-placeload">
					<div class="body">
						<div class="inner-wrap">
							<div class="img loads"></div>
							<div class="content-shape loads"></div>
							<div class="content-shape loads"></div>
							<div class="content-shape loads"></div>
							<div class="button-shape loads"></div>
						</div>
					</div>
				</div>
				<div class="placeload list-placeload">
					<div class="header">
						<div class="content-shape loads"></div>
					</div>
					<div class="body">
						<div class="flex-block">
							<div class="img loads"></div>
							<div class="inner-wrap">
								<div class="content-shape loads"></div>
								<div class="content-shape loads"></div>
							</div>
						</div>
						<div class="flex-block">
							<div class="img loads"></div>
							<div class="inner-wrap">
								<div class="content-shape loads"></div>
								<div class="content-shape loads"></div>
							</div>
						</div>
						<div class="flex-block">
							<div class="img loads"></div>
							<div class="inner-wrap">
								<div class="content-shape loads"></div>
								<div class="content-shape loads"></div>
							</div>
						</div>
						<div class="flex-block">
							<div class="img loads"></div>
							<div class="inner-wrap">
								<div class="content-shape loads"></div>
								<div class="content-shape loads"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> -->
	<div id="activity-feed" class="view-wrap true-dom">
		<div class="columns">
			<div class="column is-3 is-hidden-mobile pos-sticky-wrap">
				<app-feed-menu></app-feed-menu>
			</div>
			<div class="column is-6">
				<app-compose-card
					*ngIf="checkExistUser"
					(reload)="reloadPosts()"
				></app-compose-card>
				<app-post
					(emitPost)="getPost($event)"
					(dataUser)="getUser($event)"
				></app-post>
			</div>
			<div class="column is-3">
				<app-stories-widget class="widget-right"></app-stories-widget>
				<app-birthday-widget class="widget-right"></app-birthday-widget>
				<app-suggested-friends-widget
					class="widget-right"
				></app-suggested-friends-widget>
				<app-new-job class="widget-right"></app-new-job>
			</div>
		</div>
	</div>
	<app-albums-help-modal></app-albums-help-modal>
	<app-albums-modal></app-albums-modal>
	<app-video-help-modal></app-video-help-modal>
	<app-video-modal></app-video-modal>
	<app-share-modal [post]="post" [dataUser]="dataUser"></app-share-modal>
	<app-no-stream-modal></app-no-stream-modal>
</div>
