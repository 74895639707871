import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChange,
  AfterViewChecked
} from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import * as environment from 'src/environments/environment';
import * as dataMoods from '../../../../assets/data/api/activities/mood/mood-autocpl.json';
import * as dataActivities from '../../../../assets/data/api/activities/activity-autocpl.json';
import * as dataDrinking from '../../../../assets/data/api/activities/drinking/drinking-autocpl.json';
import * as dataImageGif from '../../../../assets/data/api/gif/imagegif.json';
import * as dataPlaces from '../../../../assets/data/api/places/places-autocpl.json';
import * as dataEatings from '../../../../assets/data/api/activities/eating/eating-autocpl.json';
import * as dataReadings from '../../../../assets/data/api/activities/reading/reading-autocpl.json';
import * as dataTravels from '../../../../assets/data/api/activities/travel/travel-autocpl.json';
import * as dataWatching from '../../../../assets/data/api/activities/watching/watching-autocpl.json';

import { PagesService } from 'src/app/services/pages.service';
import iziToast from 'izitoast';
import { GroupService } from 'src/app/services/group/group.service';

declare var jQuery: any;
declare var $: any;

import { initComposeCard } from '../js/composecard';
import { FeedService } from 'src/app/services/feed.service';
@Component({
  selector: 'app-page-compose-card',
  templateUrl: './page-compose-card.component.html',
  styleUrls: ['./page-compose-card.component.scss'],
})
export class PageComposeCardComponent implements OnInit, AfterViewChecked {
  environment;
  //#region model group
  dataGroups;
  isGroup: boolean = false;
  // from component GroupPostComponent
  @Input() set dataGroup(dataGroup) {
    this.dataGroups = dataGroup;
    this.isGroup = this.dataGroups != null ? true : false;
  }

  dataUsers;
  // from component GroupPostComponent
  @Input() set dataUser(dataUsers) {
    this.dataUsers = dataUsers;
  }
  // PageProfileComponent
  @Input() dataPage;
  @Input() idPage;
  @Input() dataAdminReceive;

  arrDisPlay = [
    {
      id: 0,
      icon: 'globe',
      mediaTitle: 'Public',
      mediaContent: 'Anyone can see this publication.',
    },
    {
      id: 1,
      icon: 'users',
      mediaTitle: 'Friends',
      mediaContent: 'only friends can see this publication.',
    },
    {
      id: 2,
      icon: 'user',
      mediaTitle: 'Specific friends',
      mediaContent: "Don't show it to some friends.",
    },
    {
      id: 3,
      icon: 'lock',
      mediaTitle: 'Only me',
      mediaContent: 'Only me can see this publication.',
    },
  ];

  //#endregion model array
  dataMoods = [];
  dataActivities = [];
  dataDrinking = [];
  dataImageGif;
  dataPlaces = [];
  dataEatings = [];
  dataReadings = [];
  dataTravels = [];
  dataWatching = [];


  //#endregion
  constructor(
    private profileService: ProfileService,
    private def: ChangeDetectorRef,
    private pagesService: PagesService,
    private groupService: GroupService,
    private feedService: FeedService
  ) {
    this.environment = environment.environment.apiUrl;
    loadPageJs();
    //#region Array import from Json
    this.dataWatching = dataWatching['default'];
    this.dataTravels = dataTravels['default'];
    this.dataReadings = dataReadings['default'];
    this.dataEatings = dataEatings['default'];
    this.dataImageGif = dataImageGif['default'];
    this.dataActivities = dataActivities['default'];
    this.dataMoods = dataMoods['default'];
    this.dataDrinking = dataDrinking['default'];
    this.dataPlaces = dataPlaces['default'];
    //#endregion
  }
  isLoadedJs = false;
  ngAfterViewChecked(): void {
    if (!this.isLoadedJs) {
      this.isLoadedJs = true;
      
      $('#publish').on('click', function () {
        $('.app-overlay').addClass('is-active');
        $('.is-new-content').addClass('is-highlighted');
      });
      $("#publish").on("input", function () {
        var valueLength = $(this).val().length;

        if (valueLength >= 1) {
          $("#publish-button").removeClass("is-disabled");
        } else {
          $("#publish-button").addClass("is-disabled");
        }
      });
    }
  }

  ngOnInit(): void {
    //#region setter model post
    this.postImgeGif = [];
    this.postImg = [];
    this.postLocation = '';
    this.postContent = '';
    this.postTags = [];
    this.postType = 0;

    this.postDisplay = {
      id: 0,
      icon: 'globe',
      mediaTitle: 'public',
      mediaContent: 'Anyone can see this publication.',
    };
    //#endregion

    //#region boolean
    this.isEnter = false;
    this.isSuccess = false;
    this.isActive = false;
    this.isHidden = false;
    //#endregion
    // initComposeCard();
    setTimeout(() => {
      this.checkAdminGroup();
    }, 0)
  }

  isAdminGroup: boolean;
  checkAdminGroup() {
    if (this.dataGroups && this.dataGroups._id != null && this.dataGroups.groupId != 'feed' && this.dataUsers) {
      this.isAdminGroup = this.dataGroups.groupAdmin == this.dataUsers._id;
    }
  }

  //#region Input image
  filesDropped(files): void {
    for (let file of files) {
      this.postImg.push(file);
    }
  }
  filesPasted(files): void {
    for (let file of files) {
      this.postImg.push(file);
    }
  }
  filesInput(files): void {
    for (let file of files) {
      this.postImg.push(file);
    }
  }

  async appendData(type) {
    this.isSuccess = true;
    let formData = new FormData();
    for (let file of this.postImg) {
      formData.append('file', file.formData);
    }
    this.profileService.uploadCloud(formData).subscribe((data) => {
      if (data) {
        let arrImg = data;
        const postImgs = this.initImg(arrImg);
        if (postImgs) {
          if (type == 0) {
            this.createPostPage(postImgs);
          }
          if (type == 1) {
            this.createPostGroup(postImgs);
          }
          if (type == 2) {
            this.requestPostGroup(postImgs);
          }
        }
      }
    });
  }

  initImg(arrImage) {
    let caption = [];
    for (let post of this.postImg) {
      caption.push(post.model);
    }
    this.postImg = [];
    for (const i in arrImage) {
      const imgN = arrImage[i];
      if (imgN.mimetype.indexOf('image/') > -1) {
        this.postImg.push({
          url: imgN.location,
          filename: imgN.originalname,
          type: imgN.mimetype,
          _id: imgN._id,
          caption: caption[i],
        });
      }
      if (imgN.mimetype.indexOf('video/') > -1) {
        this.postImg.push({
          url: imgN.location,
          filename: imgN.originalname,
          type: imgN.mimetype,
          _id: imgN._id,
          caption: '',
        });
      }
    }
    return this.postImg;
  }

  //#endregion

  //#region model recevie data from compnent pageMainComponents
  idPageReceive;
  dataPageReceive;
  //#endregion
  ngOnChanges(changes: SimpleChange) {
    this.dataPageReceive = this.dataPage;
    this.dataAdminReceive = this.dataAdminReceive;
    this.def.detectChanges();
  }

  //#region FUNCTION search user
  searchUser;
  arrUser = [];
  searchUsers() {
    this.arrUser = [];
    if (this.searchUser != '') {
      this.profileService
        .searchUsers({ search: this.searchUser })
        .subscribe((data) => {
          if (data.status == 'success') {
            this.arrUser = data['data'];
            if (this.postTags.length > 0) {
              for (let i = 0; i < this.postTags.length; i++) {
                for (let j = 0; j < this.arrUser.length; j++) {
                  if (this.postTags[i]._id == this.arrUser[j]._id) {
                    this.arrUser.splice(j, 1);
                  }
                }
              }
            }
            $('#eac-container-users-autocpl ul')
              .css('display', 'block')
              .fadeIn(300);
          }
        });
    }
  }

  //#endregion

  //#region FUNCTION tag user
  postTags = [];
  tagUser(user, index) {
    let check = false;
    if (this.postTags.length > 0) {
      for (let i of this.postTags) {
        if (i._id === user._id) {
          check = true;
        }
      }
    }
    if (check !== true) {
      this.postTags.push(user);
      this.arrUser.splice(index, 1);
      this.searchUser = '';
    }
  }
  deleteTag(user, index) {
    this.postTags.splice(index, 1);
    this.arrUser.push(user);
  }
  //#endregion
  //#region FUCNTION Clear input
  clearInputs() {
    this.postContent = undefined;
    this.postFeeling = undefined;
    this.postLocation = undefined;
    this.postImg = [];
  }
  //#endregion

  //#region FUCNTION close Mood
  closeMoods() {
    this.postFeeling = undefined;
    (function ($) {
      $(document).ready(function () {
        if (document.getElementsByClassName('control has-margin is-hidden')) {
          $('#activities-autocpl-wrapper').removeClass('is-hidden');
        }
      });
    })(jQuery);
    this.isMood = false;
    this.isEnter = false;
    this.isRead = false;
    this.isWatch = false;
    this.isTravel = false;
    this.isEat = false;
    this.isActive = !this.isActive;
    this.initAnimation();
  }
  initAnimation() {
    (function ($) {
      $(document).ready(function () {
        $('#eac-container-activities-autocpl > ul')
          .css('display', 'block')
          .fadeIn(500);
      });
    })(jQuery);
  }

  isActive: boolean = false;
  isHidden: boolean = false;
  openActive() {
    this.isHidden = !this.isHidden;
    if (this.isHidden == true) {
      this.isActive = !this.isActive;
      if (this.isActive == true) {
        this.initAnimation();
      }
      return;
    }
  }
  //#endregion

  //#region FUCNTION select acitvities using JQuery
  picActive;
  isMood: boolean = false;
  isDrink: boolean = false;
  isEat: boolean = false;
  isRead: boolean = false;
  isWatch: boolean = false;
  isTravel: boolean = false;
  selectActive(active) {
    this.isActive = !this.isActive;
    if (active.name == 'status') {
      this.picActive = active.pic;
      this.isMood = !this.isMood;
      if (this.isMood == true) {
        (function ($) {
          $(document).ready(function () {
            $('#eac-container-mood-autocpl > ul')
              .css('display', 'block')
              .fadeIn(300);
          });
        })(jQuery);
      }
    } else if (active.name == 'drinking') {
      this.isDrink = !this.isDrink;
      if (this.isDrink == true) {
        (function ($) {
          $(document).ready(function () {
            $('#eac-container-drinking-autocpl > ul')
              .css('display', 'block')
              .fadeIn(300);
          });
        })(jQuery);
      }
    } else if (active.name == 'eating') {
      this.isEat = !this.isEat;
      if (this.isEat == true) {
        (function ($) {
          $(document).ready(function () {
            $('#eac-container-eating-autocpl > ul')
              .css('display', 'block')
              .fadeIn(300);
          });
        })(jQuery);
      }
    } else if (active.name == 'reading') {
      this.isRead = !this.isRead;
      if (this.isRead == true) {
        (function ($) {
          $(document).ready(function () {
            $('#eac-container-reading-autocpl > ul')
              .css('display', 'block')
              .fadeIn(300);
          });
        })(jQuery);
      }
    } else if (active.name == 'watching') {
      this.isWatch = !this.isWatch;
      if (this.isWatch == true) {
        (function ($) {
          $(document).ready(function () {
            $('#eac-container-watching-autocpl > ul')
              .css('display', 'block')
              .fadeIn(300);
          });
        })(jQuery);
      }
    } else if (active.name == 'travelling') {
      this.isTravel = !this.isTravel;
      if (this.isWatch == true) {
        (function ($) {
          $(document).ready(function () {
            $('#eac-container-travelling-autocpl > ul')
              .css('display', 'block')
              .fadeIn(300);
          });
        })(jQuery);
      }
    }
  }

  selectValueActive(value) {
    this.postFeeling = {};
    let obj;
    obj = {
      img: value.pic,
      text: '',
      content: value.name,
    };
    if (this.isMood == true) {
      obj.id = 1;
      this.isMood = false;
    } else if (this.isDrink == true) {
      obj.id = 2;
      obj.text = 'is drinking';
      this.isDrink = false;
    } else if (this.isEat == true) {
      obj.id = 3;
      obj.text = 'is eating';
      this.isEat = false;
    } else if (this.isRead == true) {
      obj.id = 4;
      obj.text = 'is reading';
      this.isRead = false;
    } else if (this.isWatch == true) {
      obj.id = 5;
      obj.text = 'is watching';
      this.isWatch = false;
    } else if (this.isTravel == true) {
      obj.id = 6;
      obj.text = 'Travels to';
      this.isTravel = false;
    }
    this.postFeeling = Object.assign(obj);
  }

  openRepeatActive(value) {
    if (value.id == 1) {
      this.isMood = !this.isMood;
      return;
    }
    if (value.id == 2) {
      this.isDrink = !this.isDrink;
      return;
    }

    if (value.id == 3) {
      this.isEat = !this.isEat;
      return;
    }
    if (value.id == 4) {
      this.isRead = !this.isRead;
      return;
    }
    if (value.id == 5) {
      this.isWatch = !this.isWatch;
      return;
    }
    if (value.id == 6) {
      this.isTravel = !this.isTravel;
      return;
    }
  }

  closeActive() {
    this.isHidden = false;
    this.isActive = false;
  }

  //#endregion

  private regex = new RegExp(
    '^(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})'
  );
  isEnter;
  enterLink(postShareLink) {
    this.isEnter = true;
    this.getDataFromUrl(postShareLink);
  }

  postShareLink;
  postType;
  postDisplay;
  postContent;
  postFeeling;
  postLocation;
  postImg = [];
  isSuccess;
  postImgeGif;
  createPostPage(postImg) {
    let postVideo = false;
    if (postImg && postImg.length > 0) {
      if (postImg.length == 1) {
        if (postImg[0].type == 'video/mp4') {
          postVideo = true;
        }
      }
    }
    let data = {
      postUserId: this.dataAdminReceive.userId,
      postPageId: this.dataPageReceive._id,
      postContent: this.postContent,
      postImg: postImg,
      postImgeGif: this.postImgeGif,
      postFeeling: this.postFeeling,
      postTags: this.postTags,
      postShareLink: this.linkPreview,
      postLocation: this.postLocation,
      postType: 0,
      postDisplay: this.postDisplay.id,
      isActive: true,
      postVideo: postVideo,
    };
    this.pagesService.createPost(data).subscribe((result) => {
      if (result.status == 'success') {
        this.isSuccess = false;
        this.clearInput();
        this.pagesService.changeListPost({ status: 'success' });
        this.ngOnInit();
        this.closeCompose();
        iziToast.success({
          title: 'Đăng bài viết thành công.',
          titleColor: '#000',
        });
      }
    });
  }

  closeCompose() {
    $('.close-publish').trigger('click');
  }
  //#region FUCNTION create post of group
  createPostGroup(postImg) {
    let postVideo = false;
    if (postImg && postImg.length > 0) {
      if (postImg.length == 1) {
        if (postImg[0].type == 'video/mp4') {
          postVideo = true;
        }
      }
    }
    let data = {
      postUserId: this.dataUsers._id,
      postGroupId: this.dataGroups._id,
      postContent: this.postContent,
      postImg: postImg,
      postImgeGif: this.postImgeGif,
      postFeeling: this.postFeeling,
      postTags: this.postTags,
      postShareLink: this.linkPreview,
      postLocation: this.postLocation,
      postType: 0,
      postDisplay: this.postDisplay.id,
      postVideo: postVideo,
    };
    this.groupService.createPostGroup(data).subscribe((result) => {
      if (result.status == 'success') {
        let dataPost = result.data;
        if (this.postImg.length > 0) {
          for (let img of this.postImg) {
            this.createPhotoByPost(dataPost, img);
          }
        }
        this.isSuccess = false;
        this.clearInput();
        this.ngOnInit();
        this.closeCompose();
        iziToast.success({
          title: 'Đăng bài viết thành công.',
          titleColor: '#000',
        });
        this.groupService.isSuccessPost.next({ status: 'success' });
      }
    });
  }

  requestPostGroup(postImg) {
    let postVideo = false;
    if (postImg && postImg.length > 0) {
      if (postImg.length == 1) {
        if (postImg[0].type == 'video/mp4') {
          postVideo = true;
        }
      }
    }
    let data = {
      postUserId: this.dataUsers._id,
      postGroupId: this.dataGroups._id,
      postContent: this.postContent,
      postImg: postImg,
      postImgeGif: this.postImgeGif,
      postFeeling: this.postFeeling,
      postTags: this.postTags,
      postShareLink: this.linkPreview,
      postLocation: this.postLocation,
      postType: 0,
      postDisplay: this.postDisplay.id,
      postVideo: postVideo,
    };
    this.groupService.requestPostGroup(data).subscribe((result) => {
      if (result.status == 'success') {
        // let dataPost = result.data;
        // if (this.postImg.length > 0) {
        //   for (let img of this.postImg) {
        //     this.createPhotoByPost(dataPost, img);
        //   }
        // }
        this.isSuccess = false;
        this.clearInput();
        this.ngOnInit();
        this.closeCompose();
        iziToast.success({
          title: 'Yêu cầu đăng bài viết thành công. Chờ phê duyệt.',
          titleColor: '#000',
        });
        this.groupService.isSuccessPost.next({ status: 'success' });
      }
    });
  }

  createPhotoByPost(post, image) {
    let reqPhoto = {
      groupId: this.dataGroups._id,
      postId: post._id,
      photo: image.url,
      photoName: image.filename,
      groupName: post._id,
    };
    this.groupService.createPhoto(reqPhoto).subscribe((result) => { });
  }
  //#endregion

  clearInput() {
    this.postImg = [];
    this.postImgeGif = undefined;
    this.postDisplay = undefined;
    this.linkPreview = undefined;
    this.postShareLink = undefined;
    this.postFeeling = undefined;
  }
  imgFile = new FormData();

  deleteImg(index) {
    if (this.postImg.length > 0) {
      this.postImg.splice(index, 1);
    }
  }

  selectDisplayPost(value) {
    this.postDisplay = value;
    (function ($) {
      $(document).ready(function () {
        $('.dropdown-trigger').removeClass('is-active');
      });
    })(jQuery);
  }

  loadingUrl(url) {
    if (url == '') {
      this.linkPreview = {};
    }
  }

  linkPreview;
  getDataFromUrl(url) {
    if (url.length > 0) {
      if (url.indexOf('https://') > -1) {
        url = url.slice(8, url.length);
      }
      this.pagesService.getDataFromUrl({ url: url }).subscribe((result) => {
        if (result.status == 'success') {
          this.isEnter = false;
          this.linkPreview = result.data;
          (function ($) {
            $(document).ready(function () {
              $('#link-summary').css('display', 'block').fadeIn(1500, 'swing');
            });
          })(jQuery);
        }
      });
    }
  }
  searchPlace(place) {
    if (place) {
      (function ($) {
        $('#eac-container-location-autocpl')
          .removeClass('is-hidden')
          .fadeIn(300);
        $('#eac-container-location-autocpl ul')
          .css('display', 'block')
          .fadeIn(300);
      })(jQuery);
    } else if (!place) {
      (function ($) {
        $('#eac-container-location-autocpl').addClass('is-hidden').fadeIn(300);
      })(jQuery);
    }
  }

  selectPlace(place) {
    (function ($) {
      $('#eac-container-location-autocpl').addClass('is-hidden').fadeIn(300);
    })(jQuery);
  }

  changeUrlVideo(url) {
    let host = 'https://' + environment.environment.s3SpacePrefix + '.';
    let splt = url.replace(environment.environment.s3SpacePrefix + '/', '');
    let result = host + splt;
    return result;
  }
}
export let loadPageJs = () => {
  let js = [
    // Concatenated js plugins and jQuery
    '../../assets/js/global.js',
    '../../assets/js/app.js',
    '../../assets/js/main.js',
    '../../assets/js/stories.js',
    // Components js
    '../../assets/js/feed.js',
    '../../assets/js/autocompletes.js',
  ];
  removeScript();
  for (let i of js) {
    loadScript(i);
  }
};

function removeScript() {
  let removeScript = document.querySelectorAll('script[data-name="myscript"]');
  if (removeScript.length > 0) {
    for (let i = 0; i < removeScript.length; i++) {
      if (removeScript[i]) {
        document.getElementsByTagName('body')[0].removeChild(removeScript[i]);
      }
    }
  }
}

function loadScript(url) {
  let script;
  script = document.createElement('script');
  let body = <HTMLDivElement>document.body;
  script.setAttribute('data-name', 'myscript');
  script.innerHTML = '';
  script.src = url;
  script.async = true;
  script.defer = true;
  body.appendChild(script);
}
