import { Component, OnInit } from '@angular/core';
import { GroupService } from 'src/app/services/group/group.service';
import { PagesService } from 'src/app/services/pages.service';
import { ProfileService } from 'src/app/services/profile.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-search-widget',
  templateUrl: './search-widget.component.html',
  styleUrls: ['./search-widget.component.scss'],
})
export class SearchWidgetComponent implements OnInit {
  searchBox;
  arrUser = [];
  host;
  constructor(
    private profileService: ProfileService,
    private pagesServe: PagesService,
    private groupService: GroupService
  ) {
    this.host = environment.apiUrl;
  }

  ngOnInit(): void {}

  changeBox(searchBox) {
    this.arrUser = [];
    this.searchBox = searchBox;
    if (this.searchBox != '') {
      this.profileService
        .searchUsers({ search: this.searchBox })
        .subscribe((res) => {
          if (res.status == 'success') {
            this.arrUser = res.data;
          }
        });
      this.searchPageByName(this.searchBox);
      this.searchGroup(this.searchBox);
    }
  }

  arrPage;
  searchPageByName(pageName) {
    let data = {
      pageName: pageName,
      sort: -1,
      skip: 0,
      limit: 0,
    };
    this.pagesServe.searchPageByName(data).subscribe((result) => {
      if (result.status == 'success') {
        this.arrPage = result.data;
      }
    });
  }
  trackByFn(index, item) {
    return index;
  }

  goProfile(dataUser) {
    window.location.replace(`/profile/${dataUser._id}`);
  }

  goFanPage(dataPage) {
    localStorage.setItem('id', dataPage._id);
    window.location.replace(`/pages/${dataPage._id}`);
  }

  //#region search group by groupName
  arrGroup = [];
  searchGroup(groupName) {
    this.groupService
      .searchGroup({ groupName: groupName })
      .subscribe((result) => {
        if (result.status == 'success') {
          this.arrGroup = result.data;
        }
      });
  }

  goGroup(group) {
    window.location.replace(`groups/${group._id}`);
  }
  //#endregion
}
