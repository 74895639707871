<div class="chat-nav">
	<div class="nav-start">
		<div class="recipient-block" *ngIf="infoFriend">
			<div class="avatar-container">
				<img
					class="user-avatar"
					[src]="infoFriend.avatar"
					data-demo-src="assets/img/avatars/dan.jpg"
					alt
				/>
			</div>
			<div class="username">
				<span>
					{{ infoFriend.firstname + " " + infoFriend.lastname }}
				</span>
				<span>
					<i-feather name="star"></i-feather>
					<span>| Online</span>
				</span>
			</div>
		</div>
	</div>
	<div class="nav-end">
		<!-- Settings icon dropdown -->
		<div class="dropdown is-spaced is-neutral is-right dropdown-trigger">
			<div>
				<a class="chat-nav-item is-icon">
					<i-feather name="settings"></i-feather>
				</a>
			</div>
			<div class="dropdown-menu" role="menu">
				<div class="dropdown-content">
					<a href="#" class="dropdown-item">
						<div class="media">
							<i-feather name="message-square"></i-feather>
							<div class="media-content">
								<h3>Details</h3>
								<small>View this conversation's details.</small>
							</div>
						</div>
					</a>
					<a class="dropdown-item">
						<div class="media">
							<i-feather name="settings"></i-feather>
							<div class="media-content">
								<h3>Preferences</h3>
								<small>Define your preferences.</small>
							</div>
						</div>
					</a>
					<hr class="dropdown-divider" />
					<a class="dropdown-item">
						<div class="media">
							<i-feather name="bell"></i-feather>
							<div class="media-content">
								<h3>Notifications</h3>
								<small>Set notifications settings.</small>
							</div>
						</div>
					</a>
					<a class="dropdown-item">
						<div class="media">
							<i-feather name="bell-off"></i-feather>
							<div class="media-content">
								<h3>Silence</h3>
								<small>Disable notifications.</small>
							</div>
						</div>
					</a>
					<hr class="dropdown-divider" />
					<a class="dropdown-item">
						<div class="media">
							<i-feather name="box"></i-feather>
							<div class="media-content">
								<h3>Archive</h3>
								<small>Archive this conversation.</small>
							</div>
						</div>
					</a>
					<a class="dropdown-item">
						<div class="media">
							<i-feather name="trash-2"></i-feather>
							<div class="media-content">
								<h3>Delete</h3>
								<small>Delete this conversation.</small>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
		<div class="chat-search">
			<div class="control has-icon">
				<input type="text" class="input" placeholder="Search messages" />
				<div class="form-icon">
					<i-feather name="search"></i-feather>
				</div>
			</div>
		</div>
		<a class="chat-nav-item is-icon is-hidden-mobile">
			<i-feather name="at-sign"></i-feather>
		</a>
		<a class="chat-nav-item is-icon is-hidden-mobile">
			<i-feather name="star"></i-feather>
		</a>

		<!-- More dropdown -->
		<div class="dropdown is-spaced is-neutral is-right dropdown-trigger">
			<div>
				<a class="chat-nav-item is-icon no-margin">
					<i-feather name="more-vertical"></i-feather>
				</a>
			</div>
			<div class="dropdown-menu" role="menu">
				<div class="dropdown-content">
					<a href="#" class="dropdown-item">
						<div class="media">
							<i-feather name="file-text"></i-feather>
							<div class="media-content">
								<h3>Files</h3>
								<small>View all your files.</small>
							</div>
						</div>
					</a>
					<a class="dropdown-item">
						<div class="media">
							<i-feather name="users"></i-feather>
							<div class="media-content">
								<h3>Users</h3>
								<small>View all users you're talking to.</small>
							</div>
						</div>
					</a>
					<hr class="dropdown-divider" />
					<a class="dropdown-item">
						<div class="media">
							<i-feather name="gift"></i-feather>
							<div class="media-content">
								<h3>Daily bonus</h3>
								<small>Get your daily bonus.</small>
							</div>
						</div>
					</a>
					<a class="dropdown-item">
						<div class="media">
							<i-feather name="download-cloud"></i-feather>
							<div class="media-content">
								<h3>Downloads</h3>
								<small>See all your downloads.</small>
							</div>
						</div>
					</a>
					<hr class="dropdown-divider" />
					<a class="dropdown-item">
						<div class="media">
							<i-feather name="life-buoy"></i-feather>
							<div class="media-content">
								<h3>Support</h3>
								<small>Reach our support team.</small>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
		<a class="chat-nav-item is-icon close-chat" routerLink="/">
			<i-feather name="x"></i-feather>
		</a>
	</div>
</div>
