<div
    id="notifications-settings"
    class="settings-section"
>
    <div class="settings-panel">

        <div class="title-wrap">
            <a class="mobile-sidebar-trigger">
                <i-feather name="menu"></i-feather>
            </a>
            <h2>Notifications</h2>
        </div>

        <div class="settings-form-wrapper">
            <div class="settings-form">
                <div class="columns is-multiline">
                    <div class="column is-8">

                        <div class="sub-heading">
                            <h3>General notifications</h3>
                        </div>

                        <!-- Field -->
                        <div class="field spaced-field">
                            <div class="switch-block">
                                <label class="f-switch">
                                    <input
                                        type="checkbox"
                                        class="is-switch"
                                        checked
                                    >
                                </label>
                                <div class="meta">
                                    <h4>Notifications</h4>
                                    <p>Enable to activate notifications.</p>
                                </div>
                            </div>
                        </div>
                        <!-- Field -->
                        <div class="field spaced-field">
                            <div class="switch-block">
                                <label class="f-switch is-accent">
                                    <input
                                        type="checkbox"
                                        class="is-switch"
                                    >
                                </label>
                                <div class="meta">
                                    <h4>Sounds</h4>
                                    <p>
                                        Enable to play a sound on new notification.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="sub-heading">
                            <h3>Social notifications</h3>
                        </div>

                        <!-- Field -->
                        <div class="field spaced-field">
                            <div class="switch-block">
                                <label class="f-switch is-accent">
                                    <input
                                        type="checkbox"
                                        class="is-switch"
                                        checked
                                    >
                                </label>
                                <div class="meta">
                                    <h4>Friend Request</h4>
                                    <p>
                                        Enable to receive friend request notifications.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Field -->
                        <div class="field">
                            <div class="switch-block">
                                <label class="f-switch is-accent">
                                    <input
                                        type="checkbox"
                                        class="is-switch"
                                        checked
                                    >
                                </label>
                                <div class="meta">
                                    <h4>New Comment</h4>
                                    <p>
                                        Enable to receive new comment notifications.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="sub-heading">
                            <h3>Chat notifications</h3>
                        </div>

                        <!-- Field -->
                        <div class="field spaced-field">
                            <div class="switch-block">
                                <label class="f-switch is-accent">
                                    <input
                                        type="checkbox"
                                        class="is-switch"
                                        checked
                                    >
                                </label>
                                <div class="meta">
                                    <h4>New Message</h4>
                                    <p>
                                        Enable to receive new message notifications.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Field -->
                        <div class="field">
                            <div class="switch-block">
                                <label class="f-switch is-accent">
                                    <input
                                        type="checkbox"
                                        class="is-switch"
                                        checked
                                    >
                                </label>
                                <div class="meta">
                                    <h4>New Participant</h4>
                                    <p>
                                        Enable to receive new participant notifications.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="sub-heading">
                            <h3>Payment notifications</h3>
                        </div>

                        <!-- Field -->
                        <div class="field spaced-field">
                            <div class="switch-block">
                                <label class="f-switch is-accent">
                                    <input
                                        type="checkbox"
                                        class="is-switch"
                                        checked
                                    >
                                </label>
                                <div class="meta">
                                    <h4>Payment Status</h4>
                                    <p>
                                        Enable to receive a notification on payment status change.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="illustration">
                <img
                    src="assets/img/illustrations/settings/6.svg"
                    alt
                >
                <p>
                    If you'd like to learn more about notifications settings, you can read about it in the
                    <a>user guide</a>
                    .
                </p>
            </div>
        </div>

    </div>
</div>
