<div class="profile-post">
	<div class="time is-hidden-mobile">
		<div class="img-container">
			<img
				id="img-container"
				[src]="post?.postUserId?.avatar"
				[attr.data-demo-src]="post?.postUserId?.avatar"
				alt
			/>
		</div>
	</div>

	<!-- Post -->
	<div class="card is-post">
		<!-- Main wrap -->
		<div class="content-wrap">
			<!-- Header -->
			<div class="card-heading">
				<div class="user-block">
					<div class="image">
						<img
							id="img-container"
							[src]="post?.postUserId?.avatar"
							[attr.data-demo-src]="post?.postUserId?.avatar"
							[attr.data-user-popover]="post?.postUserId?._id"
							alt
						/>
					</div>
					<div class="user-info">
						<div class="user-info-wrapper" id="user-info">
							<strong>
								<span class="page-name">
									<a [href]="'profile/' + post?.postUserId?._id">
										<span
											>{{ post?.postUserId?.firstname }}
											{{ post?.postUserId?.lastname }}</span
										></a
									></span
								>
							</strong>
							<ng-container *ngIf="post?.postFeeling">
								<span>&nbsp;</span>-<span>&nbsp;</span>

								{{ post?.postFeeling?.text }}
								{{ post?.postFeeling?.content }}
								<strong>
									<span class="img-activities"
										><img [src]="post?.postFeeling?.img" alt="" /></span
								></strong>
							</ng-container>
							<span class="tag-friend" *ngIf="post?.postTags?.length > 0">
								<small>— with</small>
								<ng-container *ngIf="post?.postTags?.length > 2">
									<a
										*ngFor="let friend of post?.postTags | slice: 0:1"
										class="is-inverted"
										[href]="friend?._id"
										[ngStyle]="{margin: '0 3px'}"
									>
										<div>
											<span
												>{{ friend?.firstname }} {{ friend?.lastname }}</span
											>
										</div>
									</a>
								</ng-container>
								<ng-container *ngIf="post?.postTags?.length <= 2">
									<a
										*ngFor="let friend of post?.postTags | slice: 0:2"
										class="is-inverted"
										[href]="friend?._id"
										[ngStyle]="{margin: '0 3px'}"
									>
										<div>
											<span
												>{{ friend?.firstname }} {{ friend?.lastname }}</span
											>
										</div>
									</a>
								</ng-container>
								<ng-container *ngIf="post?.postTags?.length > 2">
									and {{ post?.postTags?.length }} people orthers</ng-container
								>
							</span>
						</div>

						<span class="time">{{ post?.createdAt | dateAgo }}</span>
					</div>
				</div>

				<div class="dropdown is-spaced is-right is-neutral dropdown-trigger">
					<div>
						<div class="button">
							<i-feather name="more-vertical"></i-feather>
						</div>
					</div>
					<div class="dropdown-menu" role="menu">
						<div class="dropdown-content">
							<a href="#" class="dropdown-item">
								<div class="media">
									<i-feather name="bookmark"></i-feather>
									<div class="media-content">
										<h3>Bookmark</h3>
										<small>Add this post to your bookmarks.</small>
									</div>
								</div>
							</a>
							<a class="dropdown-item">
								<div class="media">
									<i-feather name="bell"></i-feather>
									<div class="media-content">
										<h3>Notify me</h3>
										<small>Send me the updates.</small>
									</div>
								</div>
							</a>
							<hr class="dropdown-divider" />
							<a href="#" class="dropdown-item">
								<div class="media">
									<i-feather name="flag"></i-feather>
									<div class="media-content">
										<h3>Flag</h3>
										<small>In case of inappropriate content.</small>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
			<!-- /Header -->

			<!-- Post body -->
			<div class="card-body">
				<!-- Post body text -->
				<div class="post-text">
					<p>
						{{ post?.postContent }}
					</p>
					<p></p>
				</div>
				<div class="post-image" *ngIf="post?.postImg?.length > 0">
					<div
						id="masonry-grid"
						class="masonry-grid image-wrap"
						[ngClass]="
							post?.postImg?.length > 4 &&
							post?.postImg[0]?.width > post?.postImg[0]?.height
								? 'img-wrap-inset5-straight'
								: post?.postImg?.length > 4 &&
								  post?.postImg[0]?.width <= post?.postImg[0]?.height
								? 'img-wrap-inset5-horizontal'
								: post?.postImg?.length == 3
								? 'img-wrap-inset3'
								: post?.postImg?.length == 2
								? 'img-wrap-inset2'
								: post?.postImg?.length == 1
								? 'img-wrap-inset1'
								: ''
						"
					>
						<ng-container
							*ngFor="let img of post?.postImg | slice: 0:5; let index = index"
						>
							<div class="masonry-grid-wrapper postion-img">
								<a
									[attr.data-thumb]="img?.url"
									[attr.data-demo-href]="img?.url"
									*ngIf="img.type !== 'video/mp4'"
								>
									<img
										class="wrap-image"
										[src]="img?.url"
										[attr.data-demo-src]="img?.url"
										alt="thumb-image"
									/>
									<div
										*ngIf="index == 4 && post?.postImg?.length > 5"
										class="count-img-wrap title is-3"
									>
										<span>+{{ post?.postImg?.length - 5 }}</span>
									</div>
								</a>
								<a *ngIf="img.type == 'video/mp4'">
									<div class="wrap-video">
										<video
											[src]="img?.url"
											controls
											controlsList="nodownload"
										></video>
									</div>
									<div
										*ngIf="index == 4 && post?.postImg?.length > 5"
										class="count-img-wrap title is-3"
									>
										<span>+{{ post?.postImg?.length - 5 }}</span>
									</div>
								</a>
							</div>
						</ng-container>
					</div>
				</div>
				<div class="approve-reject">
					<div class="button-wrap">
						<button
							type="button"
							class="button"
							(click)="createGroupPost(post)"
						>
							Approve</button
						><button type="button" class="button" (click)="rejectRequest(post)">
							Reject
						</button>
					</div>
				</div>
			</div>
			<!-- /Post body -->
		</div>
		<!-- /Main wrap -->
	</div>
	<!-- /Post -->
	<!-- Load more photos -->
</div>
