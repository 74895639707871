import { Directive, HostListener, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Directive({
  selector: '[appDragdrop]'
})
export class DragdropDirective {
  @Output() files: EventEmitter<any> = new EventEmitter();
  constructor(private sanitizer: DomSanitizer) { }
  @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  @HostListener('drop', ['$event']) public onDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    let files = []
    for (let file of event.dataTransfer.files) {
      let fileType;
      if (file.type == "video/mp4") {
        fileType = "video"
      }
      let url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
      files.push({ nameUrl: file.name, urlImg: url, fileType: fileType, formData: file });
    }
    this.files.emit(files)
  }
}
