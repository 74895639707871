<app-group-member-requests-dropdown
  *ngIf="isAdminGroups"
  [groupId]="dataGroups._id"
  [isAdmin]="isAdminGroups"
  [memberRequests]="memberRequests"
></app-group-member-requests-dropdown>
<ng-container *ngIf="dataGroups">
  <div class="container">
    <div class="cover-bg">
      <img
        class="cover-image"
        [src]="dataGroups?.groupCover"
        data-demo-src="assets/img/demo/bg/4.jpg"
        alt
      />

      <div class="cover-overlay"></div>
      <div
        class="cover-edit modal-trigger"
        data-modal="change-cover-modal"
        *ngIf="isAdminGroups == true"
      >
        <i class="mdi mdi-camera"></i>
        <span>Edit cover image</span>
      </div>
      <!-- /html/partials/pages/pages/dropdowns/page-timeline-mobile-dropdown.html -->
      <div
        class="
          dropdown
          is-spaced is-right is-accent
          dropdown-trigger
          timeline-mobile-dropdown
          is-hidden-desktop
        "
      >
        <div>
          <div class="button">
            <i-feather name="more-vertical"></i-feather>
          </div>
        </div>
        <div class="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <a href="/pages-main.html" class="dropdown-item">
              <div class="media">
                <i-feather name="activity"></i-feather>
                <div class="media-content">
                  <h3>Timeline</h3>
                  <small>Open Timeline.</small>
                </div>
              </div>
            </a>
            <a href="/pages-about.html" class="dropdown-item">
              <div class="media">
                <i-feather name="align-right"></i-feather>
                <div class="media-content">
                  <h3>About</h3>
                  <small>See about info.</small>
                </div>
              </div>
            </a>
            <a href="/pages-community.html" class="dropdown-item">
              <div class="media">
                <i-feather name="globe"></i-feather>
                <div class="media-content">
                  <h3>Community</h3>
                  <small>See community.</small>
                </div>
              </div>
            </a>
            <a href="/pages-photos.html" class="dropdown-item">
              <div class="media">
                <i-feather name="image"></i-feather>
                <div class="media-content">
                  <h3>Photos</h3>
                  <small>See all photos.</small>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="profile-menu is-hidden-mobile">
      <div class="menu-start">
        <a
          class="button has-min-width btn-menu-wrap"
          [ngClass]="{ 'is-active': isPageActive == 1 }"
          (click)="clickActivePage(1)"
          >Discussion</a
        >
        <a
          class="button has-min-width btn-menu-wrap"
          [ngClass]="{ 'is-active': isPageActive == 3 }"
          (click)="clickActivePage(3)"
          >About</a
        >
      </div>
      <div class="menu-end">
        <a
          class="button has-min-width btn-menu-wrap"
          [ngClass]="{ 'is-active': isPageActive == 2 }"
          (click)="clickActivePage(2)"
          >Members</a
        >
        <a
          class="button has-min-width btn-menu-wrap"
          [ngClass]="{ 'is-active': isPageActive == 4 }"
          (click)="clickActivePage(4)"
          >Media</a
        >
      </div>
    </div>

    <div class="profile-subheader">
      <div class="subheader-start is-hidden-mobile">
        <span></span>
      </div>
      <div class="subheader-middle">
        <h2>{{ dataGroups?.groupName }}</h2>
        <span class="gr-displ"
          ><i-feather [name]="groupDisplayIcon"></i-feather>
          {{ groupDisplay }}</span
        >
      </div>
      <div class="subheader-end is-hidden-mobile" *ngIf="isMembers">
        <a
          (click)="openModal()"
          class="button has-icon is-bold modal-trigger"
          data-modal="create-member-modal"
        >
          <i-feather name="plus"></i-feather>
          Invite
        </a>
      </div>
      <div class="subheader-end is-hidden-mobile" *ngIf="!isMembers">
        <a
          class="button has-min-width btn-join"
          *ngIf="!sendedRequest"
          (click)="createJoinGroup()"
        >
          Tham gia nhóm
        </a>
        <a
          class="button has-min-width btn-join"
          *ngIf="sendedRequest"
          (click)="deleteRequest()"
        >
          Hủy yêu cầu
        </a>
      </div>
    </div>
    <div *ngIf="isBtnAdmin" class="btn-menu-admin" (click)="openSideBar()">
      <i-feather name="menu"></i-feather>
    </div>
  </div>
</ng-container>
<div class="container">
  <div class="view-wrap is-headless">
    <div class="columns has-portrait-padding" *ngIf="isPageActive == 1">
      <div class="column is-4 pos-sticky-wrap">
        <app-group-info
          [dataGroup]="dataGroups"
          [groupDisplay]="groupDisplay"
          [groupDisplayIcon]="groupDisplayIcon"
          [groupDisplayContent]="groupDisplayContent"
          [groupHidden]="groupHidden"
          [groupHiddenIcon]="groupHiddenIcon"
          [groupHiddenContent]="groupHiddenContent"
          [arrPhoto]="arrPhotos"
        ></app-group-info>
      </div>
      <div class="column is-8">
        <app-group-post
          [dataGroup]="dataGroups"
          [dataUser]="dataUsers"
          [isHidden]="isHiddens"
          [isMember]="isMembers"
          [isAdmin]="isAdminGroups"
          (closeSide)="closeSidebar($event)"
        ></app-group-post>
      </div>
    </div>
    <div class="columns has-portrait-padding" *ngIf="isPageActive == 3">
      <div class="column is-4"></div>
      <div class="column is-8">
        <app-group-about
          [dataGroup]="dataGroups"
          [groupDisplay]="groupDisplay"
          [groupDisplayIcon]="groupDisplayIcon"
          [groupDisplayContent]="groupDisplayContent"
          [groupHidden]="groupHidden"
          [groupHiddenIcon]="groupHiddenIcon"
          [groupHiddenContent]="groupHiddenContent"
        ></app-group-about>
      </div>
    </div>
    <div class="columns has-portrait-padding" *ngIf="isPageActive == 2">
      <div class="column is-4"></div>
      <div class="column is-8">
        <app-group-members
          [dataGroup]="dataGroups"
          [isHighAdmin]="isAdminGroups"
          [userId]="dataUsers._id"
        ></app-group-members>
      </div>
    </div>
    <div class="columns has-portrait-padding" *ngIf="isPageActive == 4">
      <!-- <div class="column is-4"></div> -->
      <div class="column">
        <app-group-photo [dataGroup]="dataGroups"></app-group-photo>
      </div>
    </div>
  </div>
</div>
<app-invite-member
  [dataGroup]="dataGroups"
  [isModal]="isModal"
  (isCloseModal)="closeModal($event)"
></app-invite-member>
<app-page-change-cover-modal
  *ngIf="isAdminGroups"
></app-page-change-cover-modal>
<app-page-upload-crop-cover-modal
  *ngIf="isAdminGroups"
  [dataGroup]="dataGroups"
></app-page-upload-crop-cover-modal>
