import {
  Component,
  OnChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import iziToast from 'izitoast';
import { FeedService } from 'src/app/services/feed.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PagesService } from 'src/app/services/pages.service';
import { ProfileService } from 'src/app/services/profile.service';
import * as environment from 'src/environments/environment';
import * as loadjs from '../../../pages/feed-layout/pagelayout';
// import model
import { Notification } from '../../model/notificationModel';
// import jQuery
declare var jQuery: any;
declare var $: any;

declare var window: any;
declare function initDropdowns(): any;
declare function initModals(): any;
//declare function getUserPopovers(): any;

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnChanges {
  constructor(
    private profileService: ProfileService,
    private pageService: PagesService,
    private feedService: FeedService,
    private notifiService: NotificationService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) { }
  dataPosts = [];
  dataComments = [];
  dataReplies = [];
  dataLikes = [];
  user;
  token;
  environment = environment.environment.apiUrl;
  notifiModel;
  @Output() dataUser = new EventEmitter<any>();
  @HostListener("window:scroll", [])
  onScroll(): void {
    if (this.bottomReached()) {
      console.log(true);

      this.getMorePosts()
    }
  }
  bottomReached(): boolean {
    return ((window.scrollY + document.body.offsetHeight) >= document.body.scrollHeight);
  }
  // filter get post
  skip;
  limit;
  // filter get comment
  skipCmt;
  limitCmt;
  // filter get replies
  skipReply;
  limitReply;

  isLoading: boolean = false;
  ngOnInit(): void {
    this.notifiModel = new Notification('', '', '');
    // model of post FanPage
    this.skip = 0;
    this.limit = 3;
    // model of post user
    this.skipReply = 0;
    this.limitReply = 3;
    this.skipCmt = 0;
    this.limitCmt = 3;

    this.getUser();
    this.getPosts();
    this.feedService.changeSuccess.subscribe((result) => {
      if (result.isSubmit == true) {
        this.getPosts();
        setTimeout(() => {
          this.isLoading = true;
        }, 500);
      }
    });
    setTimeout(() => {
      this.isLoading = true;
    }, 500);
    loadjs.loadjs();
    //initDropdowns();
    this.initChangeTexts();
  }
  ngOnChanges() {
    this.getPosts();
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  trackByFn(index, item) {
    return index;
  }

  //#region FUNCTION search user of comment
  arrUser = [];
  checkKeySearch;
  arrName = [];
  searchUser(username) {
    if (username != undefined || username != null) {
      (function ($) {
        $(document).ready(function () {
          $('.tag-fr').focusout(function () {
            var element = $(this);
            if (!element.text().replace(' ', '').length) {
              element.empty();
            }
          });
        });
      })(jQuery);
      var start = /@/gi; // @ Match
      var word = /@(\w+)/gi; //@abc Match
      if (username.indexOf('@') > -1) {
        var go = username.match(start); //Content Matching @
        this.arrName = username.match(word); //Content Matching @abc
        if (go.length > 0) {
          if (this.arrName) {
            let count = 0;
            if (this.arrName.length > 1) {
              count = this.arrName.length - 1;
            }
            let usernameFillter = this.arrName[count].toString().slice(1);
            this.pageService
              .searchUser({ search: usernameFillter })
              .subscribe((arrUser) => {
                if (arrUser.status == 'success') {
                  this.arrUser = arrUser.data;
                }
              });
          } else {
            this.arrUser = [];
          }
        }
      }
    }
  }
  //#endregion

  //#region CREATE notification when user comment, reply, like post
  // type for user/post
  // 0: user
  // 1: page
  createNotification(
    notifiedUser,
    notifiedPage,
    notificationType,
    notificationContent,
    notificationPostId,
    notificationReaded
  ) {
    if (notifiedUser != this.user._id) {
      let reqNotification = {
        notifiedUser: notifiedUser,
        notifiedPage: notifiedPage,
        notificationType: notificationType,
        notificationContent: notificationContent,
        notificationPostId: notificationPostId,
        notificationReaded: notificationReaded,
      };

      this.notifiService
        .createNotification(reqNotification)
        .subscribe((resultNotification) => {
          if (resultNotification.status == 'success') {
          }
        });
    }
  }
  //#endregion

  //#region GET info user
  getUser() {
    this.token = localStorage.getItem('token');
    if (this.token) {
      this.profileService.getProfile().subscribe((user) => {
        if (user.status == 'success') {
          this.user = user['data'];
          this.dataUser.emit({ data: user['data'] });
        }
      });
    }
  }
  //#endregion

  //#region FUNCTION Open comment
  openComment(postCurrnet) {
    postCurrnet.isShowComment = true;
    postCurrnet.arrComment = [];
    postCurrnet.filterComment = { skip: 0, limit: 3 };
    this.getComments(postCurrnet, postCurrnet.filterComment);
  }
  //#endregion

  //#region FUNCTION Close comment
  closeComment(post) {
    post.isShowComment = false;
  }
  //#endregion

  //#region ---------------------------- Post of User/Page--------------------------

  //#region FUNCTION GET arr posts of User/Page

  initPostContent(post) {
    if (post.postContent) {
      post.postContent = post.postContent.replace(/\n/g, "<br />");
      const lengthTxt = post.postContent.length;
      if (lengthTxt > 150) {
        post.postContentCut = post.postContent.substr(0, 150) + '...';
        post.isLength = true;
        return post;
      }
      post.isLength = false;
      return post;
    }
  }
  initGetPost(dataPosts) {
    if (dataPosts.length > 0) {
      let filter = {
        limit: this.limitCmt,
        skip: this.skipCmt,
      };

      let filterView = {
        isActive: false,
        isViewMore: false,
      };

      for (let post of dataPosts) {
        // get like post
        if (post.postPageId) {
          post.type = 1;
          this.getLikeByPostIdPage(post);
        } else {
          post.type = 0;
          this.getLikeByPostIdUser(post);
        }
        this.initPostContent(post);
        post.isLiked = false;
        post.arrLike = [];
        // get comment
        post.filterView = filterView;
        post.filterComment = filter;
        this.getComments(post, post.filterComment);
        post.postShare = post.postShare || [];
        post.countShare = post.countShare || 0;
        this.getShareUser(post);
      }
      return dataPosts;
    }
  }

  getPosts() {
    let filter = {
      skip: this.skip,
      limit: this.limit,
    };
    this.profileService.getPost().subscribe(async (resultpostUser) => {
      if (resultpostUser.status == 'success') {
        this.pageService
          .getPostPage(filter)
          .subscribe(async (resultpostpost) => {
            if (resultpostpost.status == 'success') {
              let arrPostUser =
                (await this.initGetPost(resultpostUser.data)) || [];
              let arrPostPage =
                (await this.initGetPost(resultpostpost.data)) || [];

              this.dataPosts = await arrPostUser.concat(arrPostPage);
              for (let post of this.dataPosts) {
                post.contenteditable = false;
              }
              this.processPostImages();
            }
          });
      }
    });
  }

  processPostImages() {
    for (var i in this.dataPosts) {
      var post = this.dataPosts[i];
      if (post.postImg) {
        for (var j in post.postImg) {
          var imageData = post.postImg[j];
          post.postImg[j].width = null;
          post.postImg[j].height = null;
          this.processImageData(imageData, i, j, (data) => {
            this.dataPosts[data.index].postImg[data.imgIndex].width =
              data.width;
            this.dataPosts[data.index].postImg[data.imgIndex].height =
              data.height;
          });
        }
      }
    }
  }

  processImageData(imageData, index, imgIndex, callback) {
    const img = new Image();
    img.onload = function () {
      callback({
        index: index,
        imgIndex: imgIndex,
        width: img.width,
        height: img.height,
      });
    };
    img.src = imageData.url;
  }
  //#endregion

  //#region FUNCTION Get like post of User/Page
  getLikeByPostIdUser(post): void {
    this.feedService.getLikeByPostId(post._id).subscribe((result) => {
      if (result.status == 'success') {
        post.arrLike = [];
        post.arrLike = result.data;
        this.checkLikePostUserLogin(post);
      }
    });
  }
  getLikeByPostIdPage(post) {
    this.pageService.getLikeByPostId(post._id).subscribe((result) => {
      if (result.status == 'success') {
        post.arrLike = [];
        post.arrLike = result.data;
        this.checkLikePostUserLogin(post);
      }
    });
  }
  checkLikePostUserLogin(post) {
    let checkLikePost = post.arrLike.find(
      (item) => item.userId._id === this.user._id
    );
    post.isLiked = checkLikePost ? true : false;
  }
  //#endregion

  //#region FUNCTION Get comment of User/Page
  getComments(post, filterComment) {
    let pageId;
    pageId = post.postPageId ? post.postPageId._id : null;
    let requestComment = {
      postId: post._id,
      skip: filterComment.skip,
      limit: filterComment.limit,
      pageId: pageId,
    };
    post.arrComment = post.arrComment || [];
    post.countComment = post.countComment || 0;
    post.commentTagUser = post.commentTagUser || [];
    let filterReplies = {
      limit: 3,
      skip: 0,
    };
    let filterView = {
      isActive: false,
      isViewMore: false,
    };
    if (post.type == 0) {
      this.getCommentsUser(requestComment, post, filterReplies, filterView);
    } else {
      this.getCommentsPage(requestComment, post, filterReplies, filterView);
    }
  }
  getCommentsUser(requestComment, post, filterReplies, filterView) {
    this.feedService
      .getCommentByPostId(requestComment)
      .subscribe((dataComment) => {
        if (dataComment.status == 'success') {
          this.initComment(dataComment, post, filterReplies, filterView);
        }
      });
  }
  getCommentsPage(requestComment, post, filterReplies, filterView) {
    this.pageService
      .getCommentPostInPage(requestComment)
      .subscribe((result) => {
        if (result.status == 'success') {
          this.initComment(result, post, filterReplies, filterView);
        }
      });
  }

  initComment(result, post, filterReplies, filterView) {
    post.countComment = result.count;
    post.arrComment = result.data.concat(post.arrComment);
    if (post.arrComment.length > 0) {
      if (post.countComment > post.filterComment.skip) {
        post.filterView.isActive = true;
        post.filterView.isViewMore = true;
      }
      for (let comment of post.arrComment) {
        comment.editComment = false;
        //filter get replies from comment Id
        comment.filterReplies = {
          skip: filterReplies.skip,
          limit: filterReplies.limit,
        };

        comment.filterView = {
          isActivce: filterView.isActive,
          isViewMore: filterView.isViewMore,
        };
        comment.filterView.isActive = filterView.isActive;
        comment.filterView.isViewMore = filterView.isViewMore;

        comment.arrReplies = comment.arrReplies || [];
        comment.countReplies = comment.countReplies || 0;
        comment.isLiked = false;
        comment.arrLike = comment.arrLike || [];
        if (post.type == 0) {
          this.getReplyByCmtIdUser(comment, comment.filterReplies);
          this.getLikeByCommentIdUser(comment);
          this.checkCommentForUser(comment);
        } else {
          this.getReplyByCmtIdPage(comment, comment.filterReplies);

          this.getLikeByCommentId(comment);
          this.checkCommentForUser(comment);
        }
      }
    }
  }
  checkCommentForUser(comment) {
    comment.isUser = comment.userId._id == this.user._id ? true : false;
  }
  //#region FUNCTION Get replies of comment for user
  getReplyByCmtIdUser(comment, filter) {
    let request = {
      commentId: comment._id,
      skip: filter.skip,
      limit: filter.limit,
    };
    this.feedService.getReplyUserByCmtId(request).subscribe((reply) => {
      if (reply.status == 'success') {
        comment.arrReplies = [];
        comment.countReply = reply.count;
        let arrReplyNew = reply.data;
        comment.arrReplies = arrReplyNew.concat(comment.arrReplies);
        this.getLikeRepliesUser(comment);
        if (
          comment.arrReplies.length > 0 &&
          comment.countReply > comment.filterReplies.skip
        ) {
          comment.filterView.isActive = true;
          comment.filterView.isViewMore = true;
        }
      }
    });
  }
  getLikeRepliesUser(comment) {
    for (let replies of comment.arrReplies) {
      replies.isLikeReplies = false;
      replies.arrLikeReplies = replies.arrLikeReplies || [];
      this.getLikeByReplyIdUser(replies);
    }
  }
  //#endregion
  //#region FUNCTION GET replies of post in Fanpages
  getReplyByCmtIdPage(comment, filter) {
    let requestReply = {
      id: comment._id,
      skip: filter.skipReply,
      limit: filter.limitReply,
    };
    this.pageService
      .getReplyByCommentId(requestReply)
      .subscribe((dataReplies) => {
        if (dataReplies.status == 'success') {
          let arrReply = dataReplies.data;
          comment.countReply = dataReplies.count;
          comment.arrReplies = arrReply.concat(comment.arrReplies);
          this.getLikeByRepliesIdPage(comment);
          comment.arrReplies = this.getUnique(comment.arrReplies, '_id');
          if (comment.countReply > comment.filterReplies.skip) {
            comment.filterView.isActive = true;
            comment.filterView.isViewMore = true;
          }
        }
      });
  }
  getLikeByRepliesIdPage(comment) {
    for (let replies of comment.arrReplies) {
      replies.isLikeReplies = false;
      replies.arrLikeReplies = replies.arrLikeReplies || [];
      this.pageService.getLikeByReplyId(replies._id).subscribe((result) => {
        if (result.status == 'success') {
          replies.arrLikeReplies = result.data;
          this.checkLikeReply(replies);
        }
      });
    }
  }
  checkLikeReply(replies) {
    replies.isUser = replies.userId._id == this.user._id ? true : false;
    let checkLikeReplies = replies.arrLikeReplies.find(
      (item) => item.userId._id === this.user._id
    );
    replies.isLikeReplies = checkLikeReplies ? true : false;
  }
  // check array duplicate
  getUnique(arr, comp) {
    const unique = arr
      .map((e) => e[comp])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);
    return unique;
  }
  //#endregion
  //#endregion

  //#region FUNCTION Get more comment of User/Page
  viewMoreComment(post) {
    post.filterComment.skip = Number(
      post.filterComment.skip + post.filterComment.limit
    );
    this.getComments(post, post.filterComment);
  }
  //#endregion

  //#region FUNCTION Get more post of User/Page
  getMorePosts() {
    this.skip += +5;
    let data = {
      skip: this.skip,
    };
    let filter = {
      skip: this.skip,
      limit: this.limit,
    };
    setTimeout(() => {
      this.getMoresPostUser(data);
      this.getMoresPostPage(filter);
    }, 1000)
  }
  getMoresPostUser(filter) {
    this.profileService.getMorePosts(filter).subscribe((result) => {
      if (result.status == 'success') {
        let arrPostMore = this.initGetPost(result.data) || [];
        this.dataPosts = this.dataPosts.concat(arrPostMore);
      }
    });
  }
  getMoresPostPage(filter) {
    this.pageService.getPostPage(filter).subscribe((result) => {
      if (result.status == 'success') {
        let arrMorePostPage = this.initGetPost(result.data) || [];
        this.dataPosts = this.dataPosts.concat(arrMorePostPage);
      }
    });
  }
  createLikePost(post) {
    let pageId;
    if (post.postPageId) {
      pageId = post.postPageId._id;
    }
    let reqLikePost = {
      postId: post._id,
      pageId: pageId,
      type: 0,
    };
    if (post.type == 0) {
      this.createLikePostUser(reqLikePost, post);
    } else {
      this.createLikePostPage(reqLikePost, post);
    }
  }

  createLikePostUser(reqLikePost, post) {
    this.profileService.createLike(reqLikePost).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByPostIdUser(post);
        this.notifiModel.notificationType = '0';
        this.notifiModel.notificationConent = 'liked on';
        this.createNotification(
          post.postUserId._id,
          undefined,
          0,
          this.notifiModel,
          post._id,
          false
        );
      }
    });
  }
  createLikePostPage(reqLikePost, post) {
    this.pageService.createLikePagePost(reqLikePost).subscribe((like) => {
      if (like.status == 'success') {
        this.getLikeByPostIdPage(post);
      }
    });
  }

  //#region FUNCTION unlike post of User/page
  unLikePost(post) {
    let requestPost = {
      postId: post._id,
    };
    if (post.type == 0) {
      this.unlikePostUser(requestPost, post);
    } else {
      this.unlikePostPage(requestPost, post);
    }
  }
  unlikePostUser(requestPost, post) {
    this.feedService.deleteLikePost(post._id).subscribe((result) => {
      if (result.status == 'success') {
        post.isLiked = false;
        this.getLikeByPostIdUser(post);
      }
    });
  }
  unlikePostPage(requestPost, post) {
    this.pageService.unLikePagePost(requestPost).subscribe((result) => {
      if (result.status == 'success') {
        post.isLiked = false;
        this.getLikeByPostIdPage(post);
      }
    });
  }
  //#endregion

  //#endregion

  //#region Detect files imgage in comment of User/Page
  detectFile(event, indexPostUser, post) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        if (file.type.indexOf('image') > -1) {
          post.format = 'image';
        } else if (file.type.indexOf('video') > -1) {
          post.format = 'video';
        }
        console.log(post.format)
        reader.onload = (e: any) => {
          document.getElementById(
            `${indexPostUser}cmt-image-user`
          ).style.display = 'block';
          if (file.type.indexOf('image') > -1) {
            post.contentImg = e.target.result;
          } else if (file.type.indexOf('video') > -1) {
            post.contentVideo = e.target.result;
          }
        };
      }
    }
  }
  //#endregion

  //#region FUNCTION Create comment of User/ Page
  tagUser(user, indexPostUser, post) {
    let fullname = user.firstname + user.lastname;
    post.commentTagUser.push(user._id);
    let txtContent = document.getElementById(`${indexPostUser}tag-friends`)
      .innerHTML;
    let count = 0;
    if (this.repeatTag(this.arrName) > 0) {
      let lengthLast = this.arrName[this.arrName.length - 1].length;
      txtContent = txtContent.slice(0, txtContent.length - lengthLast);
    } else {
      if (this.arrName.length > 1) {
        count = this.arrName.length - 1;
      }
      let indexCount = txtContent.indexOf(this.arrName[count], 0);
      txtContent = txtContent.slice(0, indexCount);
    }
    document.getElementById(
      `${indexPostUser}tag-friends`
    ).innerHTML = txtContent;
    this.changeCommentContent(fullname, indexPostUser);

    this.arrUser = [];
  }
  repeatTag(arr) {
    if (arr.length > 1) {
      let count = 0;
      for (let i = 0; i < arr.length; i++) {
        for (let j = 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            return (count = count + 1);
          }
        }
      }
    }
  }
  model;
  arrSpan = [];
  word;
  changeCommentContent(commentContent, index) {
    $(document).ready(function () {
      $(`#${index}tag-friends`).append(
        `<mark contenteditable="false">${commentContent}</mark>`
      );
    });
  }

  createComment(post, indexPostUser) {
    let commentContentUser = document.getElementById(
      `${indexPostUser}tag-friends`
    ).innerHTML;
    let pageId;
    if (post.postPageId) {
      pageId = post.postPageId._id;
    }
    let requestComment = {
      pageId: pageId,
      postId: post._id,
      commentContent: commentContentUser,
      commentImg: post.contentImg,
      commentTag: post.commentTagUser,
      commentVideo: post.contentVideo,
    };

    if (post.type == 0) {
      this.createCmtPostUser(requestComment, indexPostUser, post);
    } else {
      this.createCmtPostPage(requestComment, indexPostUser, post);
    }
  }

  createCmtPostUser(requestComment, indexPostUser, post) {
    this.profileService.createComment(requestComment).subscribe((comment) => {
      if (comment.status == 'success') {
        let commentNew = comment.data;
        this.getCommentByIdUser(commentNew._id, post);
        document.getElementById(`${indexPostUser}tag-friends`).innerHTML = '';

        // create notification
        this.notifiModel.notificationType = '2';
        this.notifiModel.notificationConent = 'commented on';
        post.contentImg = undefined;
        post.contentVideo = undefined;
        this.createNotification(
          post.postUserId._id,
          undefined,
          0,
          this.notifiModel,
          post._id,
          false
        );
      }
    });
  }
  //#region FUNCTION for post of User
  getCommentByIdUser(commentId, post) {
    this.feedService.getCommentById(commentId).subscribe((result) => {
      if (result.status == 'success') {
        let commentNew = result.data;
        this.insertComment(commentNew, post);
      }
    });
  }

  //#endregion
  createCmtPostPage(requestComment, indexPostUser, post) {
    this.pageService
      .createCommentPagePost(requestComment)
      .subscribe((result) => {
        let cmtNew;
        if (result.status == 'success') {
          cmtNew = result.data;
          this.getCommentByIdPage(cmtNew, post);
          document.getElementById(`${indexPostUser}tag-friends`).innerHTML = '';

          post.contentImg = undefined;
          post.contentVideo = undefined;
        }
      });
  }

  //#region FUNCTION for post of Page
  getCommentByIdPage(comment, post) {
    this.pageService.getCommentById(comment._id).subscribe((result) => {
      if (result.status == 'success') {
        let commnetNews = result.data;
        this.insertComment(commnetNews, post);
      }
    });
  }

  insertComment(comment, post) {
    let filters = {
      limit: this.limitReply,
      skip: this.skipReply,
    };
    let filterView = {
      isActive: false,
      isViewMore: false,
    };
    comment.arrLike = comment.arrLike || [];
    comment.isLiked = false;
    comment.filterReplies = filters;
    comment.arrReplies = comment.arrReplies || [];
    comment.filterView = filterView;
    comment.countReply = comment.countReply || 0;
    post.arrComment = post.arrComment || [];
    post.arrComment.push(comment);
    post.countComment = Number(post.countComment + 1);
  }

  //#endregion

  //#endregion

  //#region FUNCTION delete comment User/page
  deleteComment(post, comment, indexComment) {
    if (post.type == 0) {
      this.deleteCommentUser(post, comment, indexComment);
    } else {
      this.deleteCommentPage(post, comment, indexComment);
    }
  }
  deleteCommentUser(post, comment, indexComment) {
    this.profileService.deleteComment(comment._id).subscribe((result) => {
      if (result.status == 'success') {
        post.arrComment.splice(indexComment, 1);
      }
    });
  }
  deleteCommentPage(comment, post, indexComment) {
    this.pageService.deleteCommentPagePost(comment._id).subscribe((result) => {
      if (result.status == 'success') {
        post.arrComment.splice(indexComment, 1);
      }
    });
  }

  //#endregion

  //#region FUNCTION delete replies User/page
  deleteReplies(post, comment, reply, indexReplies) {
    if (post.type == 0) {
      this.deleteRepliesUser(comment, reply, indexReplies);
    }
  }

  deleteRepliesUser(comment, reply, indexReplies) {
    this.profileService.deleteReplies(reply._id).subscribe((replies) => {
      if (replies.status == 'success') {
        comment.arrReplies.splice(indexReplies, 1);
      }
    });
  }
  deleteRepliesPage(comment, reply, indexReplies) {
    this.pageService.deleteReplyById(reply._id).subscribe((replies) => {
      if (replies.status == 'success') {
        comment.arrReplies.splice(indexReplies, 1);
      }
    });
  }
  //#endregion

  //#region FUNCTION Create like comment of User/Page
  createLikeComment(post, comment) {
    let reqLikeCmt = {
      postId: post._id,
      commentId: comment._id,
      type: 1,
    };
    if (post.type == 0) {
      this.createLikeCmtUser(post, reqLikeCmt, comment);
    } else {
      this.createLikeCmtPage(reqLikeCmt, comment);
    }
  }
  //#region FUNCTION create like comment of User
  createLikeCmtUser(post, reqLikeCmt, comment) {
    this.profileService.createLike(reqLikeCmt).subscribe((like) => {
      if (like.status == 'success') {
        this.getLikeByCommentIdUser(comment);
        this.notifiModel.notificationType = '3';
        this.notifiModel.notificationConent = 'liked on';
        this.createNotification(
          post.postUserId._id,
          undefined,
          0,
          this.notifiModel,
          post._id,
          false
        );
      }
    });
  }
  getLikeByCommentIdUser(comment) {
    this.feedService.getLikeByCommentId(comment._id).subscribe((result) => {
      if (result.status == 'success') {
        comment.arrLike = result.data;
        this.checkLikeCmtUser(comment);
      }
    });
  }

  checkLikeCmtUser(comment) {
    let isLikeUser = comment.arrLike.find(
      (like) => like.userId._id === this.user._id
    );
    comment.isLiked = isLikeUser ? true : false;
  }

  //#endregion

  //#region FUNCTION create like cmt of Page
  createLikeCmtPage(reqLikeCmt, comment) {
    this.pageService.createLikeComment(reqLikeCmt).subscribe((likeCmt) => {
      if (likeCmt.status == 'success') {
        this.getLikeByCommentId(comment);
        this.notifiModel.notificationType = '3';
        this.notifiModel.notificationConent = 'liked on';
      }
    });
  }

  getLikeByCommentId(comment) {
    this.pageService.getLikeByCommentId(comment._id).subscribe((result) => {
      if (result.status == 'success') {
        comment.arrLike = [];
        comment.arrLike = result.data;
        this.checkLikeCmtUser(comment);
      }
    });
  }

  getLikeCommentByPage(comment) {
    this.getLikeByCommentId(comment);
  }
  //#endregion

  //#endregion

  //#region FUNCTION Unlike comment of User/Page
  unlikeComment(comment, post) {
    let requsetUnlike = {
      commentId: comment._id,
    };
    if (post.type == 0) {
      this.feedService.deleteLikeComment(comment._id).subscribe((result) => {
        if (result.status == 'success') {
          this.getLikeByCommentIdUser(comment);
        }
      });
      return;
    } else {
      this.pageService.unLikeComment(requsetUnlike).subscribe((result) => {
        if (result.status == 'success') {
          this.getLikeCommentByPage(comment);
        }
      });
    }
  }
  //#endregion

  //#region FUNCTION Click reply of comment for users
  clickReplyUser(username, comment, indexPostUser, indexCommentUser) {
    document.getElementById(`${indexPostUser}tag-friends`).focus();
    let post = this.dataPosts[indexPostUser];
    post.commentReplyByUser = comment;
    post.indexCommentReplyByUser = indexCommentUser;
    post.arrRepliesTemporary = [];
    post.arrRepliesTemporary.push(username);

    let fullname = username.firstname + username.lastname;
    if (this.user._id != username._id) {
      this.changeCommentContent(fullname, indexPostUser);
    }
  }

  clearReply(post) {
    post.commentReplyByUser = undefined;
    post.indexCommentReplyByUser = undefined;
    post.arrRepliesTemporary = [];
  }
  //#endregion

  //#region FUNCTION Create replies of User/Page
  createReplyUser(post, indexPostUser) {
    let commentIdReplyByUser = post.commentReplyByUser._id;
    let replyContentUser = document.getElementById(
      `${indexPostUser}tag-friends`
    ).innerHTML;
    let pageId;
    if (post.postPageId) {
      pageId = post.postPageId._id;
    }

    let requestReply = {
      pageId: pageId,
      postId: post._id,
      commentId: post.commentReplyByUser._id,
      replyContent: replyContentUser,
      replyImg: post.contentImg,
    };
    if (post.type == 0) {
      this.createRepliesUser(
        requestReply,
        indexPostUser,
        post,
        commentIdReplyByUser
      );
    } else {
      this.createRepliesPage(requestReply, indexPostUser, post);
    }
  }

  createRepliesUser(requestReply, indexPostUser, post, commentIdReplyByUser) {
    this.profileService.createReply(requestReply).subscribe((reply) => {
      if (reply.status == 'success') {
        this.getReplyByIdUser(
          reply.data,
          indexPostUser,
          post.indexCommentReplyByUser
        );
        post.arrRepliesTemporary = [];
        // format div contentiditTable == ""
        document.getElementById(`${indexPostUser}tag-friends`).innerHTML = '';
        if (document.getElementById(`${indexPostUser}cmt-img`)) {
          document.getElementById(`${indexPostUser}cmt-img`).style.display =
            'none';
        }
        post.indexCommentReplyByUser = undefined;
        post.contentImg = '';
        this.notifiModel.notificationType = '4';
        this.notifiModel.notificationConent = 'replied on';
        this.createNotification(
          commentIdReplyByUser,
          undefined,
          0,
          this.notifiModel,
          post._id,
          false
        );
      }
    });
  }
  createRepliesPage(requestReply, indexPostUser, post) {
    this.pageService.createReplyComment(requestReply).subscribe((result) => {
      if (result.status == 'success') {
        post.arrRepliesTemporary = [];
        let replies = result.data;
        this.getRepliesById(replies, post);
        document.getElementById(`${indexPostUser}tag-friends`).innerHTML = '';
        if (document.getElementById(`${indexPostUser}cmt-img`)) {
          document.getElementById(`${indexPostUser}cmt-img`).style.display =
            'none';
        }
        post.contentImg = '';
      }
    });
  }
  //#region FUNCTION replies for post of user
  getReplyByIdUser(reply, indexPostCurrent, indexCommentCurrent) {
    this.feedService.getReplyById(reply._id).subscribe((result) => {
      if (result.status == 'success') {
        let post = this.dataPosts[indexPostCurrent];
        let commentCurrent = post.arrComment[indexCommentCurrent];
        commentCurrent.arrReplies = commentCurrent.arrReplies || [];
        commentCurrent.arrReplies.push(result.data);
        post.indexCommentReplyByUser = undefined;
      }
    });
  }
  //#endregion

  //#region FUNCTION replies for page
  getRepliesById(replies, post) {
    this.pageService.getReplyById(replies._id).subscribe((dataReply) => {
      if (dataReply.status == 'success') {
        let commentCurrent = post.arrComment[post.indexCommentReplyByUser];
        commentCurrent.arrReplies = commentCurrent.arrReplies || [];
        commentCurrent.arrReplies.push(dataReply.data);
        post.countComment = Number(post.countComment + 1);
      }
    });
  }

  //#endregion

  //#endregion

  //#region FUNCTION view more replies of comment User
  viewMoreRepliesBy(post, indexPostCurrent, comment) {
    if (post.type == 0) {
      this.viewMoreRepliesUSer(indexPostCurrent, comment);
    } else {
      this.viewMoresRepliesPage(indexPostCurrent, comment);
    }
  }
  viewMoreRepliesUSer(indexPostCurrent, comment) {
    comment.filterReplies.skip += comment.filterReplies.limit;
    comment.indexPost = indexPostCurrent;
    let request = {
      commentId: comment._id,
      skip: comment.filterReplies.skip,
      limit: comment.filterReplies.limit,
    };
    this.feedService.getReplyUserByCmtId(request).subscribe((reply) => {
      if (reply.status == 'success') {
        this.insertReplies(comment, reply);
      }
    });
  }

  viewMoresRepliesPage(indexPostCurrent, comment) {
    comment.filterReplies.skip =
      comment.filterReplies.skip + comment.filterReplies.limit;
    comment.indexPost = indexPostCurrent;
    let request = {
      id: comment._id,
      skip: comment.filterReplies.skip,
      limit: comment.filterReplies.limit,
    };
    this.pageService.getReplyByCommentId(request).subscribe((result) => {
      if (result.status == 'success') {
        this.insertReplies(comment, result);
      }
    });
  }

  insertReplies(comment, result) {
    comment.countReply = result.count;
    comment.arrReplies = result.data.concat(comment.arrReplies);
    comment.arrReplies.forEach((replies) => {
      this.checkRepliesUser(replies);
    });
  }
  checkRepliesUser(replies) {
    return (replies.isUser =
      replies.userId._id == this.user._id ? true : false);
  }

  //#endregion

  //#region FUNCTION Create like reply of User/Page
  createLikeReplies(post, comment, replies) {
    let pageId;
    if (post.postPageId) {
      pageId = post.postPageId._id;
    }
    let reqLikeReplies = {
      postId: post._id,
      commentId: comment._id,
      replyId: replies._id,
      type: 2,
      pageId: pageId,
    };
    if (post.type == 0) {
      this.createLikeRepliesUser(reqLikeReplies, replies, post);
    } else {
      this.createLikeRepliesPage(reqLikeReplies, replies, post);
    }
  }

  //#region FUNCTION create like of User
  createLikeRepliesUser(reqLikeReplies, replies, post) {
    this.profileService.createLike(reqLikeReplies).subscribe((likeReply) => {
      if (likeReply.status == 'success') {
        this.getLikeByReplyIdUser(replies);
      }
    });
  }
  getLikeByReplyIdUser(replies) {
    this.feedService.getLikeByReplyId(replies._id).subscribe((result) => {
      if (result.status == 'success') {
        replies.arrLikeReplies = result.data;
        this.checkLikeReplyUser(replies);
      }
    });
  }
  checkLikeReplyUser(replies) {
    let checkLikeReplies = replies.arrLikeReplies.find(
      (item) => item.userId._id === this.user._id
    );
    replies.isLikeReplies = checkLikeReplies ? true : false;
    this.checkRepliesUser(replies);
  }

  //#region FUNCTION Unlike post of User/Page
  unLikeReplies(post, replies) {
    let reqUnlike = {
      replyId: replies._id,
    };
    if (post.type == 0) {
      this.unlikeRepliesUser(reqUnlike, replies);
    } else {
      this.unlikeRepliesPage(reqUnlike, replies);
    }
  }

  unlikeRepliesUser(reqUnlike, replies) {
    this.feedService.deleteLikeReply(replies._id).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByReplyIdUser(replies);
      }
    });
  }
  unlikeRepliesPage(reqUnlike, replies) {
    this.pageService.unLikeReply(reqUnlike).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByReplyIdUser(replies);
      }
    });
  }
  //#endregion

  //#endregion

  //#region FUNCTION Create like of page
  createLikeRepliesPage(reqLikeReplies, replies, post) {
    this.pageService.createLikeReply(reqLikeReplies).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByReplyIdPage(replies);
      }
    });
  }
  getLikeByReplyIdPage(replies) {
    this.pageService.getLikeByReplyId(replies._id).subscribe((result) => {
      if (result.status == 'success') {
        replies.arrLikeReplies = result.data;
        this.checkLikeReply(replies);
      }
    });
  }
  //#endregion

  //#endregion

  //#region FUNCTION sort object in Array
  sortFunction(a, b) {
    var dateA = new Date(a.createdAt).getTime();
    var dateB = new Date(b.createdAt).getTime();
    return dateB > dateA ? 1 : -1;
  }
  //#endregion

  //#region FUNCTION share post for user login
  sharePost(dataPost) {
    initModals();
    this.feedService.clickSharePost({ status: 'success', data: dataPost });
  }
  //#endregion

  //#region FUNCTION change text in div attribute contenteditTable
  initChangeTexts() {
    (function ($) {
      $(document).on('paste', '[contenteditable]', function (e) {
        var content;
        e.preventDefault();
        if (window.clipboardData) {
          content = window.clipboardData.getData('Text');
          if (window.getSelection) {
            var selObj = window.getSelection();
            var selRange = selObj.getRangeAt(0);
            selRange.deleteContents();
            selRange.insertNode(document.createTextNode(content));
          }
        } else if (e.originalEvent.clipboardData) {
          content = (e.originalEvent || e).clipboardData.getData('text/plain');
          document.execCommand('insertText', false, content);
        }
      });
    })(jQuery);
  }
  //#endregion

  //#region Open initDropdown
  openInitDropDowns() {
    //initDropdowns();
  }
  //#endregion

  //#region Delete image of comment in post
  closeImgUSer(indexPostUser, post) {
    document.getElementById(`${indexPostUser}cmt-image-user`).style.display =
      'none';
    if (post.format === 'image') {
      post.contentImg = undefined;
      post.format = undefined;
      return;
    }
    if (post.format === 'video') {
      post.contentImg = undefined;
      post.format = undefined;
      return;
    }
  }
  //#endregion

  //#endregion

  //#region JQUERY ZOOM IMAGE USING FANCYBOX
  dataPost;
  openPostFancyBox(post) {
    this.dataPost = post;
    this.isActivePhoto = true;
    this.pageService.selectSubjectNext(post);
  }
  //#endregion
  //#region @Output from pagesPhotoLightBoxComponents
  isActivePhoto: boolean = false;
  closeModal(event) {
    this.isActivePhoto = event;
  }
  //#endregion

  //#region Active page child [Recent], [Albums]
  isPageActive: boolean = false;
  clickPageChildActive(isPageActice) {
    this.isPageActive = isPageActice;
  }
  //#endregion

  //#region FUNCTION get share post
  getShareUser(post) {
    this.feedService.getShareUser(post._id).subscribe((result) => {
      if (result.status == 'success') {
        post.countShare = result.count;
        post.postShare = result.data;
      }
    });
  }
  gotoProfile(id) {
    this.router.navigate(['profile', id]);
  }
  gotoPage(pageId) {
    this.router.navigate(['pages', pageId]);
  }
  gotoGroup(groupId) {
    this.router.navigate(['groups', groupId]);
  }

  indexImg;
  getImage(index) {
    this.indexImg = index
    this.isActivePhoto = true;
  }

  editPost($event, post) {
    post.contenteditable = !post.contenteditable;
    if (post.contenteditable == true) {
      let div = document.getElementById(post._id);
      setTimeout(function () {
        div.focus();
      }, 0);
    }
  }
  updatePost(post) {
    let postContent: string = document.getElementById(post._id).innerHTML;
    post.contenteditable = false;
    this.profileService.updatePost(post._id, { postContent }).subscribe(result => {
      iziToast.success({
        title: 'Cập nhật bài viết thành công.',
        titleColor: '#000',
        timeout: 1000,
      })
    })
  }
  editComment(comment) {
    comment.editComment = !comment.editComment;
    if (comment.editComment == true) {
      let div = document.getElementById(comment._id);
      setTimeout(function () {
        div.focus();
      }, 0);
    }
  }
  updateComment(comment) {
    let postContent: string = document.getElementById(comment._id).innerHTML;
    comment.editComment = false;
    this.profileService.updateComment(comment._id, { content: postContent }).subscribe(result => {
      console.log(result);

    })
  }
  editReply(reply) {
    console.log(reply);
  }
}
