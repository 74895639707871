<div class="navbar-item">
	<div id="global-search" class="control">
		<input
			id="tipue_drop_input"
			class="input is-rounded"
			type="search"
			placeholder="Search"
			required
			[(ngModel)]="searchBox"
			(ngModelChange)="changeBox(searchBox)"
			autocomplete="off"
		/>
		<span class="search-icon">
			<i-feather name="search"></i-feather>
		</span>
		<span class="drop-icon">
			<i-feather name="more-vertical"></i-feather>
		</span>
		<!-- Search Dropdown -->
		<div class="search-options">
			<ul class="options-list">
				<!-- Search Option -->
				<li class="is-selected">
					<!-- label class="material-radio">
                        <input type="radio" name="search-type" checked>
                        <span class="radio-label"></span>
                    </label -->
					<span class="option-icon">
						<i-feather name="search"></i-feather>
					</span>
					<span class="option-description">
						<span>Search All</span>
						<span>Search everything</span>
					</span>
				</li>
				<!-- Search Option -->
				<li>
					<span class="option-icon">
						<i-feather name="users"></i-feather>
					</span>
					<span class="option-description">
						<span>Search People</span>
						<span>Search accross people and friends</span>
					</span>
				</li>
				<!-- Search Option -->
				<li>
					<span class="option-icon">
						<i-feather name="file-text"></i-feather>
					</span>
					<span class="option-description">
						<span>Search Pages</span>
						<span>Search all Posts and news feeds</span>
					</span>
				</li>
			</ul>
			<!-- Close -->
			<div class="is-close">
				<button
					id="close-search-options"
					type="button"
					class="button is-solid grey-button"
				>
					Close
				</button>
			</div>
		</div>
		<div
			id="tipue_drop_content"
			class="tipue-drop-content"
			*ngIf="
				searchBox != '' &&
				(arrUser?.length > 0 || arrPage?.length > 0 || arrGroup?.length > 0)
			"
		>
			<div class="tipue_drop_box">
				<div id="tipue_drop_wrapper" *ngIf="arrUser?.length > 0">
					<!-- <div class="tipue_drop_item drop-title">Trang cá nhân</div> -->
					<div
						class="tipue_drop_item"
						*ngFor="let user of arrUser | slice: 0:5; trackBy: trackByFn"
						(click)="goProfile(user)"
					>
						<div class="tipue_drop_left">
							<img [src]="user?.avatar" class="tipue_drop_image" />
						</div>
						<div class="tipue_drop_right">
							{{ user?.firstname }} {{ user?.lastname }}
							<div>{{ user?.occupation }}</div>
						</div>
					</div>
				</div>
				<div id="tipue_drop_wrapper" *ngIf="arrPage?.length > 0">
					<div class="tipue_drop_item drop-title">Page</div>

					<div
						class="tipue_drop_item"
						*ngFor="let page of arrPage; trackBy: trackByFn"
						(click)="goFanPage(page)"
					>
						<div class="tipue_drop_left">
							<img [src]="page?.pageAvatar" class="tipue_drop_image" />
						</div>
						<div class="tipue_drop_right">
							{{ page?.pageName }}
						</div>
					</div>
				</div>

				<div id="tipue_drop_wrapper" *ngIf="arrGroup?.length > 0">
					<div class="tipue_drop_item drop-title">Groups</div>

					<div
						class="tipue_drop_item"
						*ngFor="let group of arrGroup; trackBy: trackByFn"
						(click)="goGroup(group)"
					>
						<div class="tipue_drop_left">
							<img [src]="group?.groupCover" class="tipue_drop_image" />
						</div>
						<div class="tipue_drop_right">
							{{ group?.groupName }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
