<ng-container *ngIf="infoUser && dataMessages">
	<div
		class="chat-body-inner has-slimscroll"
		id="chatbox"
		(scroll)="onScroll($event)"
	>
		<!-- <div class="date-divider">
			<hr class="date-divider-line" />
			<span class="date-divider-text">Today</span>
		</div> -->
		<ng-container *ngFor="let message of dataMessages">
			<div
				class="chat-message is-sent"
				*ngIf="message.senderId == infoUser._id"
			>
				<img [src]="infoUser.avatar" alt />
				<div class="message-block">
					<span>{{ message.createdAt | date: "h:mm a" }}</span>
					<div class="message-text">
						{{ message.message }}
						<ng-container *ngIf="message.files && message.files.length > 0">
							<div class="uploaded-files">
								<div
									*ngFor="let message of message.files"
									class="uploaded-file"
								>
									<img
										*ngIf="message.type !== 'video/mp4'"
										[src]="message.url"
										alt=""
									/>
									<video
										*ngIf="message.type == 'video/mp4'"
										[src]="message.url"
										controls
									></video>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
			<div
				class="chat-message is-received"
				*ngIf="message.receiverId == infoUser._id"
			>
				<img [src]="infoFriend.avatar" alt />
				<div class="message-block">
					<span>{{ message.createdAt | date: "h:mm a" }}</span>
					<div class="message-text">
						{{ message.message }}
						<ng-container *ngIf="message.files && message.files.length > 0">
							<div class="uploaded-files">
								<div
									*ngFor="let message of message.files"
									class="uploaded-file"
								>
									<img
										*ngIf="message.type !== 'video/mp4'"
										[src]="message.url"
										alt=""
									/>
									<video
										*ngIf="message.type == 'video/mp4'"
										[src]="message.url"
										controls
									></video>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="chat-action" [ngStyle]="{width: 'calc(100% - 700px) !important'}">
		<div class="list-imgs">
			<div class="img-comment" *ngFor="let img of imgSrc">
				<img *ngIf="img.fileType !== 'video'" [src]="img?.urlImg" alt="" />
				<video [src]="img?.urlImg" *ngIf="img.fileType == 'video'"></video>
			</div>
		</div>
		<div class="chat-action-inner">
			<div class="control" appDragdrop (files)="filesDropped($event)">
				<input
					type="text"
					class="textarea comment-textarea"
					[(ngModel)]="chatMessage"
					appPaste
					(filesClipBoard)="filesPasted($event)"
					(keydown.enter)="create()"
				/>
				<div
					class="
						dropdown
						compose-dropdown
						is-spaced is-accent is-up
						dropdown-trigger
					"
				>
					<div (click)="showUpload = !showUpload">
						<div class="add-button">
							<div class="button-inner">
								<i-feather name="plus"></i-feather>
							</div>
						</div>
					</div>
					<div
						class="dropdown-menu"
						role="menu"
						[ngStyle]="{display: showUpload ? 'block' : 'none'}"
					>
						<div class="dropdown-content">
							<!-- <a href="#" class="dropdown-item">
								<div class="media">
									<i-feather name="code"></i-feather>
									<div class="media-content">
										<h3>Code snippet</h3>
										<small>Add and paste a code snippet.</small>
									</div>
								</div>
							</a>
							<a class="dropdown-item">
								<div class="media">
									<i-feather name="file-text"></i-feather>
									<div class="media-content">
										<h3>Note</h3>
										<small>Add and paste a note.</small>
									</div>
								</div>
							</a>
							<hr class="dropdown-divider" />
							<a class="dropdown-item">
								<div class="media">
									<i-feather name="server"></i-feather>
									<div class="media-content">
										<h3>Remote file</h3>
										<small>Add a file from a remote drive.</small>
									</div>
								</div>
							</a> -->
							<a class="dropdown-item">
								<div class="media">
									<i-feather name="monitor"></i-feather>
									<input
										type="file"
										name="file"
										id="file"
										[hidden]="true"
										appInput
										(filesInput)="filesInput($event)"
										multiple
									/>
									<label for="file">
										<div class="media-content">
											<h3>Local file</h3>
											<small>Add a file from your computer.</small>
										</div>
									</label>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
