import { NgModule } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import {
  Camera,
  Heart,
  Github,
  Link2,
  MessageCircle,
  Star,
  MoreVertical,
  LifeBuoy,
  Headphones,
  X,
  Flag,
  Bookmark,
  Bell,
  MessageSquare,
  Plus,
  Book,
  HelpCircle,
  Check,
  Settings,
  Power,
  Search,
  UserPlus,
  UserMinus,
  Tag,
  Mail,
  Youtube,
  Image,
  Users,
  FileText,
  Activity,
  ChevronRight,
  ShoppingCart,
  Music,
  Edit3,
  Video,
  MapPin,
  MoreHorizontal,
  Smile,
  ChevronDown,
  Globe,
  User,
  Lock,
  Eye,
  ThumbsUp,
  Triangle,
  AtSign,
  Calendar,
  Compass,
  ArrowRight,
  UploadCloud,
  Clock,
  Coffee,
  Copy,
  BellOff,
  Minus,
  Folder,
  Briefcase,
  Shield,
  Sliders,
  AlertTriangle,
  DownloadCloud,
  Codepen,
  CreditCard,
  Hexagon,
  Database,
  Menu,
  Monitor,
  Codesandbox,
  CloudSnow,
  Cpu,
  Gift,
  Command,
  Layout,
  BookOpen,
  Slash,
  Archive,
  Award,
  CheckCircle,
  ChevronLeft,
  ChevronsDown,
  Tv,
  Trash2,
  Edit,
  Box,
  PlusCircle,
  Share2,
  ArrowDown,
  Code,
  AlignRight,
  Server,
  Smartphone,
  FilePlus,
  Home,
  Edit2,
  EyeOff,
  ArrowLeftCircle,
  ArrowRightCircle,
  Play,
  CornerDownRight,
  CornerDownLeft,
  Moon,
  Sun,
  ChevronsRight,
  Send,
  Cast,
  ShoppingBag,
} from 'angular-feather/icons';

// Select some icons (use an object, not an array)
const icons = {
  Camera,
  Heart,
  Github,
  Link2,
  MessageCircle,
  Star,
  MoreVertical,
  LifeBuoy,
  Headphones,
  X,
  Flag,
  Bookmark,
  Bell,
  MessageSquare,
  Plus,
  Book,
  HelpCircle,
  Check,
  Settings,
  Power,
  Search,
  UserPlus,
  UserMinus,
  Tag,
  Mail,
  Youtube,
  Image,
  Users,
  FileText,
  Activity,
  ChevronRight,
  ShoppingCart,
  Music,
  Edit3,
  Video,
  MapPin,
  MoreHorizontal,
  Smile,
  ChevronDown,
  Globe,
  User,
  Lock,
  Eye,
  ThumbsUp,
  Triangle,
  AtSign,
  Calendar,
  Compass,
  ArrowRight,
  UploadCloud,
  Clock,
  Coffee,
  Copy,
  BellOff,
  Minus,
  Folder,
  Briefcase,
  Shield,
  Sliders,
  AlertTriangle,
  DownloadCloud,
  Codepen,
  CreditCard,
  Hexagon,
  Database,
  Menu,
  Monitor,
  Codesandbox,
  CloudSnow,
  Cpu,
  Gift,
  Command,
  Layout,
  BookOpen,
  Slash,
  Archive,
  Award,
  CheckCircle,
  ChevronLeft,
  ChevronsDown,
  Tv,
  Trash2,
  Edit,
  Box,
  PlusCircle,
  Share2,
  ArrowDown,
  AlignRight,
  Code,
  Server,
  Smartphone,
  FilePlus,
  Home,
  Edit2,
  EyeOff,
  ArrowLeftCircle,
  ArrowRightCircle,
  Play,
  CornerDownRight,
  CornerDownLeft,
  Moon,
  Sun,
  ChevronsRight,
  Send,
  Cast,
  ShoppingBag,
};

@NgModule({
  imports: [FeatherModule.pick(icons)],
  exports: [FeatherModule],
})
export class IconsModule {}
