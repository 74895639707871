<div class="signup-wrapper">
	<div class="fake-nav">
		<a href="/" class="logo">
			<img
				src="assets/img/logo/AZ-blue.png"
				 alt="logo-archzon"
			/>
		</a>
	</div>

	<div class="process-bar-wrap">
		<div class="process-bar">
			<div class="progress-wrap">
				<div class="track"></div>
				<div class="bar"></div>
				<div
					id="step-dot-1"
					class="dot is-first is-active is-current"
					data-step="0"
				>
					<i-feather name="smile"></i-feather>
				</div>
				<div id="step-dot-2" class="dot is-second" data-step="25">
					<i-feather name="user"></i-feather>
				</div>
				<div id="step-dot-3" class="dot is-third" data-step="50">
					<i-feather name="image"></i-feather>
				</div>
				<div id="step-dot-4" class="dot is-fourth" data-step="75">
					<i-feather name="lock"></i-feather>
				</div>
				<div id="step-dot-5" class="dot is-fifth" data-step="100">
					<i-feather name="flag"></i-feather>
				</div>
			</div>
		</div>
	</div>

	<div class="outer-panel">
		<div class="outer-panel-inner">
			<div class="process-title">
				<h2 id="step-title-1" class="step-title is-active">
					Welcome, select an account type.
				</h2>
				<h2 id="step-title-2" class="step-title">Tell us more about you.</h2>
				<h2 id="step-title-3" class="step-title">Upload a profile picture.</h2>
				<h2 id="step-title-4" class="step-title">Secure your account.</h2>
				<h2 id="step-title-5" class="step-title">You're all set. Ready?</h2>
			</div>

			<div id="signup-panel-1" class="process-panel-wrap is-active">
				<div class="columns">
					<div class="column is-4">
						<div class="account-type">
							<img src="assets/img/illustrations/signup/company.svg" alt />
							<h3>Company</h3>
							<p>
								Create a company account to be able to do some awesome things.
							</p>
							<a
								class="button is-fullwidth is-rounded process-button"
								data-step="step-dot-2"
							>
								Continue
							</a>
						</div>
					</div>
					<div class="column is-4">
						<div class="account-type">
							<img src="assets/img/illustrations/signup/public.svg" alt />
							<h3>Public Person</h3>
							<p>
								Create a company account to be able to do some awesome things.
							</p>
							<a
								class="button is-fullwidth is-rounded process-button"
								data-step="step-dot-2"
							>
								Continue
							</a>
						</div>
					</div>
					<div class="column is-4">
						<div class="account-type">
							<img src="assets/img/illustrations/signup/personal.svg" alt />
							<h3>Personal</h3>
							<p>
								Create a company account to be able to do some awesome things.
							</p>
							<a
								class="button is-fullwidth is-rounded process-button"
								data-step="step-dot-2"
							>
								Continue
							</a>
						</div>
					</div>
				</div>
			</div>
			<form [formGroup]="signUpForm">
				<div id="signup-panel-2" class="process-panel-wrap is-narrow">
					<fieldset formGroupName="info">
						<div class="form-panel">
							<div class="group-info">
								<div
									class="field"
									[ngClass]="{
										'is-invalid':
											primFirstname.invalid &&
											primFirstname.touched &&
											primFirstname.errors?.required
									}"
								>
									<label>First Name</label>
									<div class="control has-icons-right">
										<input
											type="text"
											[(ngModel)]="user.Firstname"
											formControlName="Firstname"
											class="input"
											placeholder="Enter your first name"
										/>
									</div>
								</div>

								<div
									class="field"
									[ngClass]="{
										'is-invalid':
											primLastname.invalid &&
											primLastname.touched &&
											primLastname.errors?.required
									}"
								>
									<label>Last Name</label>
									<div class="control">
										<input
											type="text"
											placement="left"
											[(ngModel)]="user.Lastname"
											formControlName="Lastname"
											class="input"
											placeholder="Enter your last name"
										/>
									</div>
								</div>
							</div>

							<div
								class="field"
								[ngClass]="{
									'is-invalid':
										(primEmail.invalid &&
											primEmail.touched &&
											primEmail.errors?.required) ||
										(primEmail.touched && primEmail.errors?.pattern) ||
										(primEmail.invalid && primEmail.touched && !isCheckEmail)
								}"
							>
								<label>Email</label>

								<div
									class="control has-validation"
									[ngClass]="
										(primEmail.invalid &&
											primEmail.touched &&
											primEmail.errors?.required &&
											!isCheckEmail) ||
										(primEmail.touched &&
											primEmail.errors?.pattern &&
											!isCheckEmail)
											? 'has-error'
											: (primEmail.invalid &&
													primEmail.touched &&
													!primEmail.errors?.required &&
													isCheckEmail) ||
											  (primEmail.touched &&
													!primEmail.errors?.pattern &&
													isCheckEmail)
											? 'has-success'
											: ''
									"
								>
									<input
										type="email"
										[(ngModel)]="user.Email"
										formControlName="Email"
										class="input"
										placeholder="Youremail@example.com"
									/>
									<div
										class="error-icon"
										*ngIf="
											(primEmail.invalid &&
												primEmail.touched &&
												primEmail.errors?.required &&
												!isCheckEmail) ||
											(primEmail.touched &&
												primEmail.errors?.pattern &&
												!isCheckEmail) ||
											!isCheckEmail
										"
										(click)="clearEmail()"
									>
										<i-feather name="x"></i-feather>
									</div>
									<div
										class="success-icon"
										*ngIf="
											(primEmail.invalid &&
												primEmail.touched &&
												!primEmail.errors?.required &&
												isCheckEmail) ||
											(primEmail.touched &&
												!primEmail.errors?.pattern &&
												isCheckEmail)
										"
									>
										<i-feather name="check"></i-feather>
									</div>
								</div>
							</div>

							<div class="field">
								<label>Birthday</label>
								<div class="group-validation">
									<input
										class="birthday"
										type="text"
										name="birthDay"
										formControlName="Birthday"
										[(ngModel)]="user.Birthday"
									/>
									<select
										class="birth-day text-bold input"
										[(ngModel)]="birthdayYear"
										name="Yearbirthday"
										(change)="changeYear()"
										formControlName="Yearbirthday"
										[class.is-invalid]="
											primYear.invalid &&
											primYear.touched &&
											primYear.errors?.yearInvalid
										"
									>
										<option disabled selected value>year</option>
										<option *ngFor="let year of arrYear" [value]="year">
											{{ year }}
										</option>
									</select>
									<select
										class="birth-day text-bold input"
										[(ngModel)]="birthdayMonth"
										name="birthdayMonth"
										(change)="changeMonth($event)"
										formControlName="Monthbirthday"
										[class.is-invalid]="primMonth.invalid && primMonth.touched"
									>
										<option disabled selected value>month</option>
										<option *ngFor="let m of arrMonth" [value]="m.number">
											{{ m.month }}
										</option>
									</select>
									<select
										class="birth-day text-bold input"
										[(ngModel)]="birthdayDay"
										formControlName="Daybirthday"
										name="dayBirthday"
										[class.is-invalid]="primDay.invalid && primDay.touched"
									>
										<option disabled selected value>
											<i class="fas fa-draw-polygon"></i>
										</option>
										<option *ngFor="let date of arrDate" [value]="date">
											{{ date }}
										</option>
									</select>
								</div>
							</div>
							<div class="field">
								<div class="group-gender">
									<div class="item-gender">
										<label class="text-bold label-gender" for="female">
											female
										</label>
										<input
											class="gender-default"
											[(ngModel)]="gender"
											name="female"
											type="text"
											name="Gender"
											formControlName="Gender"
										/>
										<input
											placement="top"
											class="gender-female input-gender"
											name="female"
											type="radio"
											(click)="clickGender(0)"
											[checked]="isFemale"
											name="GenderFemale"
											formControlName="GenderFemale"
										/>
									</div>
									<div class="item-gender item-gender-male gender-mobile">
										<label class="text-bold label-gender" for="male">
											male
										</label>
										<input
											class="gender-male input-gender"
											name="gender"
											type="radio"
											(click)="clickGender(1)"
											[checked]="isMale"
											name="GenderMale"
											formControlName="GenderMale"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="buttons">
							<a
								class="button is-rounded process-button"
								data-step="step-dot-1"
							>
								Back
							</a>
							<a
								class="button is-rounded process-button is-next"
								(click)="checkNextStep1()"
								[attr.data-step]="isDoNext ? 'step-dot-3' : null"
							>
								Next
							</a>
						</div>
					</fieldset>
				</div>

				<div id="signup-panel-3" class="process-panel-wrap is-narrow">
					<div class="form-panel">
						<div class="photo-upload">
							<div class="preview">
								<a class="upload-button">
									<label for="file-input">
										<i-feather name="plus"></i-feather>
									</label>
								</a>
								<!-- <div class="is-hidden" id="profile-pic-dz">
                                    <input class="input-custome" id="file-input" type="file" accept="image/*" />
                                </div> -->
								<form id="profile-pic-dz" class="is-hidden" action="/">
									<input
										class="input-custome"
										#getValueImage
										id="file-input"
										type="text"
									/>
								</form>
								<!-- <img id="upload-preview" src="https://via.placeholder.com/150x150"
                                    data-demo-src="assets/img/avatars/avatar-w.png" alt> -->
								<img
									id="upload-preview"
									[src]="imgURL ? imgURL : 'assets/img/avatar-default.png'"
									alt
								/>
								<!-- <form id="profile-pic-dz" class="dropzone is-hidden" action="/"></form> -->
							</div>
							<div class="limitation">
								<small>
									Only images with a size lower than 3MB are allowed.
								</small>
							</div>
						</div>
					</div>
					<div class="buttons">
						<a class="button is-rounded process-button" data-step="step-dot-2">
							Back
						</a>
						<a
							class="button is-rounded process-button is-next"
							(click)="checkNextStep2()"
							[attr.data-step]="isNextStep2 ? 'step-dot-4' : null"
						>
							Next
						</a>
					</div>
				</div>

				<div id="signup-panel-4" class="process-panel-wrap is-narrow">
					<div class="form-panel">
						<div class="field">
							<label>Password</label>
							<div class="control">
								<input
									type="password"
									class="input"
									placeholder="Choose a password"
									[(ngModel)]="user.Password"
									formControlName="Password"
									[class.is-invalid]="
										(primPassword.errors?.required && primPassword.touched) ||
										(primPassword.errors?.minlength &&
											primPassword.touched &&
											primPassword.dirty)
									"
								/>
							</div>
						</div>
						<div class="field">
							<label>Repeat Password</label>
							<div class="control">
								<input
									type="password"
									class="input"
									placeholder="Repeat your password"
									[(ngModel)]="passwordRepeat"
									formControlName="Confirmpassword"
									[class.is-invalid]="
										(primConfirmPassword.errors?.required &&
											primConfirmPassword.touched) ||
										(primConfirmPassword.errors?.minlength &&
											primConfirmPassword.touched &&
											primConfirmPassword.dirty)
									"
								/>
							</div>
						</div>
						<div class="field">
							<label>Phone Number</label>
							<div class="control">
								<input
									type="text"
									class="input"
									placeholder="Enter your phone number"
									[(ngModel)]="user.Phone"
									formControlName="Phone"
									[class.is-invalid]="
										(primPhoneNumber.errors?.required &&
											primPhoneNumber.touched) ||
										(primPhoneNumber.errors?.pattern &&
											primPhoneNumber.touched &&
											primPhoneNumber.dirty)
									"
								/>
							</div>
						</div>
					</div>

					<div class="buttons">
						<a class="button is-rounded process-button" data-step="step-dot-3">
							Back
						</a>
						<a
							[ngClass]="signUpForm.status === 'VALID' ? '' : 'disabled'"
							class="button is-rounded process-button is-next"
							[attr.data-step]="isNextStep3 ? 'step-dot-5' : ''"
							(click)="checkNextStep3()"
						>
							Next
						</a>
					</div>
				</div>

				<div id="signup-panel-5" class="process-panel-wrap is-narrow">
					<div class="form-panel">
						<img
							class="success-image"
							src="assets/img/illustrations/signup/mailbox.svg"
							alt
						/>

						<div class="success-text">
							<h3>Congratz, you successfully created your account.</h3>
							<p>
								We just sent you a confirmation email. PLease confirm your
								account within 24 hours.
							</p>
							<a id="signup-finish" class="button is-fullwidth is-rounded">
								Let Me In
							</a>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
	<!-- Edit Credit card Modal -->
	<div id="crop-modal" class="modal mdl-modal is-small crop-modal is-animated">
		<div class="modal-background"></div>
		<div class="modal-content">
			<div class="modal-card">
				<header class="modal-card-head">
					<div class="modal-card-title">
						<span>Crop your picture</span>
					</div>
					<button class="mdl-modal-close" aria-label="close">
						<i-feather name="x"></i-feather>
					</button>
				</header>
				<div class="modal-card-body">
					<div id="cropper-wrapper" class="cropper-wrapper"></div>
				</div>
			</div>
		</div>
	</div>
</div>
