import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import { StoriesService } from 'src/app/services/stories.service';
import * as environment from '../../../../environments/environment';

declare var jQuery: any;

declare function initModals(): any;

@Component({
  selector: 'app-stories-content',
  templateUrl: './stories-content.component.html',
  styleUrls: ['./stories-content.component.scss'],
})
export class StoriesContentComponent implements OnInit, AfterViewInit {
  infoUsers;
  @Input() set infoUser(infoUser: any) {
    this.infoUsers = infoUser;
  }
  arrStories = [];
  @Input() set arrStory(data: any) {
    this.arrStories = data;
    if (this.arrStories.length > 0) {
      this.arrStories[0].isActive = true;
    }
  }

  @Output() btnLeft = new EventEmitter<any>();
  @Output() btnRight = new EventEmitter<any>();

  environment;
  constructor(private storiesService: StoriesService) {
    this.environment = environment.environment.apiUrl;
  }

  ngOnInit(): void {
    initModals();
    this.storiesService.isClickSide.subscribe((result) => {
      if (result.isClick == true) {
        this.index = result.index;
        this.initLoadProcess();
      }
    });
  }
  ngAfterViewInit() {
    this.initLoadProcess();
  }

  index = 0;
  async openLeft(id) {
    const indexL = <number>await this.initLeft(this.index);
    this.index = indexL;
    this.initLoadProcess();
    this.btnLeft.emit({ id: id, index: this.index });
  }
  initLeft(index) {
    index = index - 1;
    if (index < 0) {
      index = 1;
    }
    return index;
  }
  async openRight(id) {
    const indexR = <number>await this.initright(this.index);
    this.index = indexR;
    this.initLoadProcess();
    this.btnRight.emit({ id: id, index: this.index });
  }
  initright(index) {
    index = index + 1;
    if (index > this.arrStories.length) {
      index = this.arrStories.length;
    }
    return index;
  }

  initLoadProcess() {
    setTimeout(() => {
      let process = document.getElementById('progress-bar-id');
      if (process) {
        for (let index = 0; index < 50; index++) {
          setTimeout(() => {
            process.style.width = index + '%';
          }, 5000);
        }
      }
    }, 500);
  }
}
