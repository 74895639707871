<ng-container *ngIf="dataPage">
	<div class="card page-about-card">
		<div class="card-title">
			<h4>About Fast Pizza</h4>
			<div class="btn-info">
				<button
					type="button"
					class="button b-btn b-save"
					*ngIf="isEdit"
					(click)="checkEdit()"
				>
					Save
				</button>
				<a
					href="https://www.fastpizza.com/index.intl.html"
					target="_blank"
					class="button is-solid grey-button raised"
				>
					Go to Website
				</a>
			</div>
		</div>
		<div class="about-body">
			<div class="columns">
				<div class="column is-6">
					<div class="about-block">
						<div class="block-header">
							<h4>Contact Info</h4>
						</div>
						<div class="block-content">
							<div class="flex-inner" *ngIf="!isEditPhone">
								<i-feather name="phone"></i-feather>
								<span>
									Call
									<a>+{{ dataPageReceive?.pagePhone }}</a>
								</span>
								<button
									type="button"
									*ngIf="isAdminReceive"
									class="btn-custome"
									(click)="InitEdit(0)"
								>
									<i-feather name="edit-2"></i-feather>
								</button>
							</div>
							<div class="flex-inner" *ngIf="isEditPhone">
								<i-feather name="phone"></i-feather>
								<div class="form-info">
									<input
										name="phone"
										id="phone"
										class="input"
										type="text"
										[(ngModel)]="pagePhone"
									/>
									<div class="btn b-close" (click)="initClose(0)">x</div>
								</div>
							</div>

							<div class="flex-inner">
								<i-feather name="message-circle"></i-feather>
								<span>
									Start a
									<a>Conversation</a>
								</span>
							</div>
							<div class="flex-inner" *ngIf="!isEditUrl">
								<i-feather name="globe"></i-feather>
								<span>
									<a>{{ dataPageReceive?.pageUrl }}</a>
								</span>
								<button
									type="button"
									*ngIf="isAdminReceive"
									class="btn-custome"
									(click)="InitEdit(1)"
								>
									<i-feather name="edit-2"></i-feather>
								</button>
							</div>
							<div class="flex-inner" *ngIf="isEditUrl">
								<i-feather name="globe"></i-feather>
								<div class="form-info">
									<input
										name="pageurl"
										id="pageurl"
										class="input"
										type="text"
										[(ngModel)]="pageUrl"
									/>
									<div class="btn b-close" (click)="initClose(1)">x</div>
								</div>
							</div>
						</div>
					</div>

					<div class="about-block">
						<div class="block-header">
							<h4>More Info</h4>
						</div>
						<div class="block-content">
							<div class="flex-inner has-meta" *ngIf="!isEditCompany">
								<i class="mdi mdi-office-building"></i>
								<div class="meta">
									<span>Company</span>
									<span> {{ dataPageReceive?.pageCompany }} </span>
								</div>
								<button
									type="button"
									class="btn-custome"
									*ngIf="isAdminReceive"
									(click)="InitEdit(2)"
								>
									<i-feather name="edit-2"></i-feather>
								</button>
							</div>
							<div class="flex-inner" *ngIf="isEditCompany">
								<i class="mdi mdi-office-building"></i>
								<div class="form-info">
									<input
										name="pageurl"
										id="pageurl"
										class="input"
										type="text"
										[(ngModel)]="pageCompany"
									/>
									<div class="btn b-close" (click)="initClose(2)">x</div>
								</div>
							</div>
							<div class="flex-inner has-meta">
								<i class="mdi mdi-earth"></i>
								<div class="meta">
									<span>Around the world</span>
									<span>
										Domino's Pizza has 28,489 stores around the world
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="column is-6">
					<div class="about-block">
						<div class="block-header">
							<h4>History</h4>
						</div>
						<div class="block-content">
							<div class="history-block">
								<div class="date">1960's</div>
								<div class="timeline">
									<ul>
										<li>
											Tom Willis and his brother James purchase "GoGo", a pizza
											restaurant in Saratoga, Michigan.
										</li>
										<li>
											The first Fast Pizza franchise restaurant opens in
											Saratoga, Michigan.
										</li>
									</ul>
								</div>
							</div>

							<div class="history-block">
								<div class="date">1970's</div>
								<div class="timeline">
									<ul>
										<li>
											Amstar Corp., maker of Fast Pace, institutes a trademark
											infringement lawsuit against Fast Pizza.
										</li>
									</ul>
								</div>
							</div>

							<div class="history-block">
								<div class="date">1980's</div>
								<div class="timeline">
									<ul>
										<li>
											Fast Pizza's first international restaurant opens in
											Winnipeg, Canada. The 1000th Fast Pizza's restaurant
											opens.
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
