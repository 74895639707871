<ng-container *ngIf="dataPageReceive && isAdmin">
	<div id="activity-feed" class="">
		<app-page-compose-card
			[dataAdminReceive]="dataAdminReceive"
			[dataPage]="dataPageReceive"
			[dataUser]="user"
		></app-page-compose-card>
	</div>
</ng-container>
<div class="box-heading">
	<h4>Posts</h4>
	<div class="button-wrap">
		<button type="button" class="button is-active">Recent</button>
		<button type="button" class="button">Popular</button>
	</div>
</div>

<div class="profile-timeline">
	<ng-container *ngFor="let post of dataPosts; let index = index">
		<div class="profile-post">
			<div class="time is-hidden-mobile">
				<div class="img-container">
					<img
						id="img-container"
						[src]="dataPageReceive?.pageAvatar"
						[attr.data-demo-src]="dataPageReceive?.pageAvatar"
						[attr.data-page-popover]="dataPageReceive?._id"
						alt="thumb-page"
					/>
				</div>
			</div>

			<!-- Post -->
			<div class="card is-post">
				<!-- Main wrap -->
				<div class="content-wrap">
					<!-- Header -->
					<div class="card-heading">
						<div class="user-block">
							<div class="image">
								<img
									id="img-container"
									[src]="dataPageReceive?.pageAvatar"
									[attr.data-demo-src]="dataPageReceive?.pageAvatar"
									[attr.data-page-popover]="dataPageReceive?._id"
									alt="thumb-page"
								/>
							</div>
							<div class="user-info">
								<div class="user-info-wrapper" id="user-info">
									<strong>
										<span class="page-name">
											<a href="#">
												<span>{{ dataPageReceive?.pageName }}</span></a
											></span
										>
									</strong>
									<ng-container *ngIf="post?.postFeeling">
										<span>&nbsp;</span>- <span>&nbsp;</span>

										{{ post?.postFeeling?.text }}
										{{ post?.postFeeling?.content }}
										<strong>
											<span>&nbsp;</span>
											<span class="img-activities"
												><img [src]="post?.postFeeling?.img" alt="" /></span
										></strong>
									</ng-container>
									<span class="tag-friend" *ngIf="post?.postTags?.length > 0">
										<small>— with</small>
										<ng-container *ngIf="post?.postTags?.length > 2">
											<a
												*ngFor="let friend of post?.postTags | slice: 0:1"
												class="is-inverted"
												href="#"
												[ngStyle]="{margin: '0 3px'}"
											>
												<div>
													<span
														>{{ friend?.firstname }}
														{{ friend?.lastname }}</span
													>
												</div>
											</a>
										</ng-container>
										<ng-container *ngIf="post?.postTags?.length <= 2">
											<a
												*ngFor="let friend of post?.postTags | slice: 0:2"
												class="is-inverted"
												[ngStyle]="{margin: '0 3px'}"
											>
												<div>
													<span
														>{{ friend?.firstname }}
														{{ friend?.lastname }}</span
													>
												</div>
											</a>
										</ng-container>
										<ng-container *ngIf="post?.postTags?.length > 2">
											and {{ post?.postTags?.length }} people
											orthers</ng-container
										>
									</span>
								</div>

								<span class="time">{{ post?.createdAt | dateAgo }}</span>
							</div>
						</div>

						<div
							class="dropdown is-spaced is-right is-neutral dropdown-trigger"
						>
							<div>
								<div class="button">
									<i-feather name="more-vertical"></i-feather>
								</div>
							</div>
							<div class="dropdown-menu" role="menu">
								<div class="dropdown-content">
									<a href="#" class="dropdown-item">
										<div class="media">
											<i-feather name="bookmark"></i-feather>
											<div class="media-content">
												<h3>Bookmark</h3>
												<small>Add this post to your bookmarks.</small>
											</div>
										</div>
									</a>
									<a class="dropdown-item">
										<div class="media">
											<i-feather name="bell"></i-feather>
											<div class="media-content">
												<h3>Notify me</h3>
												<small>Send me the updates.</small>
											</div>
										</div>
									</a>
									<hr class="dropdown-divider" />
									<a href="#" class="dropdown-item">
										<div class="media">
											<i-feather name="flag"></i-feather>
											<div class="media-content">
												<h3>Flag</h3>
												<small>In case of inappropriate content.</small>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
					<!-- /Header -->

					<!-- Post body -->
					<div class="card-body">
						<!-- Post body text -->
						<div class="image-wrap img-loading-wrap" *ngIf="!isLoading">
							<div class="image-loader loading-wrap is-active">
								<div class="loader loader-wrap is-loading"></div>
							</div>
						</div>
						<div class="post-text">
							<p
								*ngIf="post?.isLength; else textNot"
								[innerHTML]="post?.postContentCut | sanitizeHtmlPipe"
							></p>
							<ng-template #textNot>
								<p
									id="{{ post._id }}"
									[innerHTML]="post?.postContent | sanitizeHtmlPipe"
								></p
							></ng-template>
						</div>
						<div class="post-toggle" *ngIf="post?.postContent?.length > 150">
							<span class="toggle" (click)="post.isLength = !post.isLength">
								<span *ngIf="post?.isLength; else showmore">Show all</span>
								<ng-template #showmore><span>Show less</span></ng-template>
							</span>
						</div>
						<!-- Featured image -->
						<div class="post-image" *ngIf="post?.postImg?.length > 0">
							<!-- CSS masonry wrap -->
							<div
								id="masonry-grid"
								class="masonry-grid image-wrap"
								[ngClass]="
									post?.postImg?.length > 4 &&
									post?.postImg[0]?.width > post?.postImg[0]?.height
										? 'img-wrap-inset5-straight'
										: post?.postImg?.length > 4 &&
										  post?.postImg[0]?.width <= post?.postImg[0]?.height
										? 'img-wrap-inset5-horizontal'
										: post?.postImg?.length == 3
										? 'img-wrap-inset3'
										: post?.postImg?.length == 2
										? 'img-wrap-inset2'
										: post?.postImg?.length == 1
										? 'img-wrap-inset1'
										: ''
								"
							>
								<ng-container
									*ngFor="
										let img of post?.postImg | slice: 0:5;
										let index = index
									"
								>
									<div class="masonry-grid-wrapper postion-img">
										<a
											(click)="openPostFancyBox(post); getImage(index)"
											[attr.data-demo-src]="img?.url"
											[attr.data-thumb]="img?.url"
											[attr.data-demo-href]="img?.url"
											class="masonry-wrap"
											*ngIf="img.type !== 'video/mp4'"
										>
											<img
												class="wrap-image"
												[src]="img?.url"
												[attr.data-demo-src]="img?.url"
												alt="thumb-image"
											/>
											<div
												*ngIf="index == 4 && post?.postImg?.length > 5"
												class="count-img-wrap title is-3"
											>
												<span>+{{ post?.postImg?.length - 5 }}</span>
											</div>
										</a>
										<a *ngIf="img.type == 'video/mp4'">
											<div class="wrap-video">
												<video
													[src]="img?.url"
													controls
													controlsList="nodownload"
												></video>
											</div>
											<div
												*ngIf="index == 4 && post?.postImg?.length > 5"
												class="count-img-wrap title is-3"
											>
												<span>+{{ post?.postImg?.length - 5 }}</span>
											</div>
										</a>
									</div>
								</ng-container>
								<!-- Post actions -->
								<!-- /partials/pages/feed/buttons/feed-post-actions.html -->
							</div>
						</div>

						<div class="post-link" *ngIf="post?.postShareLink">
							<!-- link image -->
							<div class="link-images">
								<img
									[src]="post?.postShareLink?.img"
									[attr.data-demo-src]="post?.postShareLink?.img"
								/>
							</div>
							<!-- Link content -->
							<div class="link-content">
								<h4>
									<a
										[href]="post?.postShareLink?.domain"
										[ngStyle]="{'text-transform': 'capitalize'}"
										>{{ post?.postShareLink?.title }}</a
									>
								</h4>
								<p>
									{{ post?.postShareLink?.description }}
								</p>
								<small>{{ post?.postShareLink?.domain }}</small>
							</div>
							<!-- Post actions -->
							<!-- /partials/pages/feed/buttons/feed-post-actions.html -->
						</div>
						<div class="post-actions">
							<div class="fab-wrapper is-comment">
								<a class="small-fab">
									<i-feather name="message-circle"></i-feather>
								</a>
							</div>
							<div class="fab-wrapper is-share">
								<a
									class="small-fab share-fab modal-trigger"
									data-modal="share-modal"
									(click)="sharePost(post)"
								>
									<i-feather name="link-2"></i-feather>
								</a>
							</div>
							<div class="like-wrapper">
								<a
									(click)="
										post?.isLiked == false
											? createLikePost(post)
											: unLikePost(post)
									"
									class="like-button"
									[ngClass]="post?.isLiked == true ? 'is-active' : ''"
								>
									<i class="mdi mdi-heart not-liked bouncy"></i>
									<i class="mdi mdi-heart is-liked bouncy"></i>
									<span class="like-overlay"></span>
								</a>
							</div>
						</div>
					</div>
					<!-- /Post body -->

					<!-- Post footer -->
					<div class="card-footer">
						<!-- Followers -->

						<div class="icon-activities">
							<div class="icon-like">
								<img src="assets/img/page/icon-like.png" alt="" />
							</div>
						</div>
						<div class="likers-text likers-txt">
							<p>
								<ng-container *ngFor="let user of post?.arrLike | slice: 0:2">
									<a href="#"
										>{{ user?.userId?.firstname }}
										{{ user?.userId?.lastname }}</a
									>
								</ng-container>
								<ng-container *ngIf="post?.arrLike?.length > 2">
									<span class="hover-like">
										<span> and {{ post?.arrLike?.length - 2 }} others</span>
										<div class="hover-like-wrapper">
											<div
												class="header-tooltip"
												*ngFor="let user of post?.arrLike | slice: 2:7"
											>
												{{ user?.userId?.firstname }}
												{{ user?.userId?.lastname }}
											</div>
											<div
												class="body-tooltip"
												*ngIf="post?.arrLike?.length > 7"
											>
												<span> and {{ post?.arrLike?.length - 7 }} others</span>
											</div>
										</div>
									</span>
								</ng-container>
							</p>

							<ng-container
								*ngIf="post?.arrLike?.length < 6 && post?.arrLike?.length > 0"
							>
								<!-- <p>liked this</p> -->
							</ng-container>
						</div>
						<!-- Post statistics -->
						<div class="social-count">
							<div class="likes-count">
								<i-feather name="heart"></i-feather>
								<span *ngIf="post?.arrLike">{{ post?.arrLike?.length }}</span>
							</div>
							<div class="shares-count">
								<i-feather name="link-2"></i-feather>
								<span>{{ post?.countShare }}</span>
							</div>
							<div class="comments-count">
								<i-feather name="message-circle"></i-feather>
								<span *ngIf="post?.arrComment">{{ post?.countComment }}</span>
							</div>
						</div>
					</div>
					<!-- /Post footer -->
				</div>
				<!-- /Main wrap -->

				<!-- Comments -->
				<div class="comments-wrap is-hidden">
					<!-- Header -->
					<div class="comments-heading">
						<h4>
							Comments
							<small>({{ post?.countComment }})</small>
						</h4>
						<div class="close-comments" (click)="closeComment()">
							<i-feather name="x"></i-feather>
						</div>
					</div>
					<div
						class="comments-heading count-cmt"
						*ngIf="
							post?.arrComment?.length > 0 &&
							post?.filterView?.isActive == true &&
							post?.countComment >
								post?.filterComment.limit + post?.filterComment.skip
						"
					>
						<span
							*ngIf="post?.filterView?.isViewMore == true"
							(click)="viewMoreComment(post)"
							>View more Comments</span
						>
					</div>
					<!-- Header -->

					<!-- Comments body -->

					<div class="comments-body has-slimscroll comments-body-cus">
						<div
							*ngIf="post?.arrComment && post?.arrComment?.length == 0"
							class="comments-placeholder"
						>
							<img src="assets/img/icons/feed/bubble.svg" alt />
							<h3>Nothing in here yet</h3>
							<p>Be the first to post a comment.</p>
						</div>
						<!-- Comment -->
						<ng-container
							*ngFor="let comment of post?.arrComment; let indexComment = index"
						>
							<div class="media is-comment">
								<!-- User image -->
								<div class="media-left">
									<div class="image">
										<img
											[src]="comment?.userId?.avatar"
											[attr.data-demo-src]="comment?.userId?.avatar"
											[attr.data-user-popover]="comment?.userId?._id"
											alt
										/>
									</div>
								</div>
								<!-- Content -->
								<div class="media-content">
									<a [href]="'profile/' + comment?.UserId?._id">
										{{ comment?.userId?.firstname }}
										{{ comment?.userId?.lastname }}
									</a>
									<span class="time">{{ comment?.createdAt | dateAgo }}</span>
									<p
										class="cmt-tags"
										[innerHTML]="comment?.commentContent | sanitizeHtmlPipe"
									></p>

									<div class="media-img">
										<img
											*ngIf="comment?.commentImg"
											[src]="comment?.commentImg ? comment?.commentImg : ''"
											alt=""
										/>
									</div>
									<div class="media-video">
										<video
											*ngIf="comment?.commentVideo"
											height="200"
											controls
											controlsList="nodownload"
										>
											<source [src]="comment?.commentVideo" type="video/mp4" />
										</video>
									</div>

									<!-- Comment actions -->

									<div class="controls">
										<div
											[ngClass]="comment?.isLiked == true ? 'is-like' : ''"
											class="like-count"
											(click)="
												comment?.isLiked == false
													? createLikeComment(post, comment)
													: unlikeComment(comment, post)
											"
										>
											<i-feather name="thumbs-up"></i-feather>
											<span>{{ comment?.arrLike?.length }}</span>
										</div>
										<div
											class="reply"
											(click)="
												clickReply(
													comment?.userId,
													comment,
													index,
													indexComment
												)
											"
										>
											<a>Reply</a>
										</div>
									</div>
									<!----REPLAY COMMENT-->
									<div
										class="count-cmt"
										*ngIf="comment?.filterView?.isActive == true"
										(click)="viewMoreRepliesBy(post, index, comment)"
									>
										<span
											*ngIf="
												comment?.filterView?.isViewMore == true &&
												comment?.arrReplies?.length != 0 &&
												comment?.countReply != comment?.arrReplies?.length
											"
											><i-feather name="corner-down-right"></i-feather> View
											{{ comment?.countReply - comment?.arrReplies?.length }}
											Reply</span
										>
									</div>
									<ng-container
										*ngFor="
											let reply of comment?.arrReplies;
											let indexReply = index
										"
									>
										<div class="media is-comment">
											<!-- User image -->
											<div class="media-left">
												<div class="image">
													<img
														[src]="reply?.userId?.avatar"
														[attr.data-demo-src]="reply?.userId?.avatar"
														[attr.data-user-popover]="reply?.userId?._id"
														alt
													/>
												</div>
											</div>
											<!-- Content -->
											<div class="media-content">
												<a class="item-media" href="#"
													>{{ reply?.userId?.firstname }}
													{{ reply?.userId?.lastname }}</a
												>
												<span class="time">{{
													reply?.createdAt | dateAgo
												}}</span>
												<p
													class="cmt-tags"
													[innerHTML]="reply?.replyContent | sanitizeHtmlPipe"
												></p>

												<div class="media-img">
													<img
														*ngIf="reply?.replyImg"
														[src]="reply?.replyImg ? reply?.replyImg : ''"
														alt=""
													/>
												</div>

												<!-- Comment actions -->
												<div class="controls">
													<div
														[ngClass]="
															reply?.isLikeReplies == true ? 'is-like' : ''
														"
														class="like-count"
														(click)="
															reply?.isLikeReplies == true
																? unLikeReplies(post, reply)
																: createLikeReplies(post, comment, reply)
														"
													>
														<i-feather name="thumbs-up"></i-feather>
														<span>{{ reply?.arrLikeReplies?.length }}</span>
													</div>
													<div
														class="reply"
														(click)="
															clickReply(
																reply?.userId,
																comment?._id,
																index,
																indexComment
															)
														"
													>
														<a>Reply</a>
													</div>
												</div>
											</div>
											<!-- Right side dropdown -->
											<div class="media-right">
												<div
													class="
														dropdown
														is-spaced is-right is-neutral
														dropdown-trigger
													"
													(click)="openInitDropDowns()"
												>
													<div>
														<div class="button">
															<i-feather name="more-vertical"></i-feather>
														</div>
													</div>
													<div class="dropdown-menu" role="menu">
														<div class="dropdown-content">
															<a class="dropdown-item" *ngIf="reply?.isUser">
																<div
																	class="media"
																	(click)="
																		deleteReplies(
																			post,
																			comment,
																			reply,
																			indexReply
																		)
																	"
																>
																	<i-feather name="x"></i-feather>
																	<div class="media-content">
																		<h3>Hide</h3>
																		<small>Hide this comment.</small>
																	</div>
																</div>
															</a>
															<div
																class="dropdown-divider"
																*ngIf="reply?.isUser"
															></div>
															<a href="#" class="dropdown-item">
																<div class="media">
																	<i-feather name="flag"></i-feather>
																	<div class="media-content">
																		<h3>Report</h3>
																		<small>Report this comment.</small>
																	</div>
																</div>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</ng-container>
								</div>
								<!-- Right side dropdown -->
								<div class="media-right">
									<div
										class="
											dropdown
											is-spaced is-right is-neutral
											dropdown-trigger
										"
										(click)="openInitDropDowns()"
									>
										<div>
											<div class="button">
												<i-feather name="more-vertical"></i-feather>
											</div>
										</div>
										<div class="dropdown-menu" role="menu">
											<div class="dropdown-content">
												<a class="dropdown-item" *ngIf="comment?.isUser">
													<div
														class="media"
														(click)="deleteComment(post, comment, index)"
													>
														<i-feather name="x"></i-feather>
														<div class="media-content">
															<h3>Hide</h3>
															<small>Hide this comment.</small>
														</div>
													</div>
												</a>
												<div
													class="dropdown-divider"
													*ngIf="comment?.isUser"
												></div>
												<a href="#" class="dropdown-item">
													<div class="media">
														<i-feather name="flag"></i-feather>
														<div class="media-content">
															<h3>Report</h3>
															<small>Report this comment.</small>
														</div>
													</div>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- /Comment -->
						</ng-container>
					</div>
					<div
						class="replies-wrap"
						*ngIf="post?.arrRepliesTemporary?.length > 0"
					>
						<div class="content-replies-wrap">
							<span>replies</span
							><span *ngFor="let replies of post?.arrRepliesTemporary"
								>{{ replies?.firstname }}{{ replies?.lastname }}</span
							>
						</div>

						<div class="clear-replies-wrap" (click)="clearReply(post)">
							<i-feather name="x"></i-feather>
						</div>
					</div>
					<div class="card-footer">
						<div class="media post-comment has-emojis">
							<!-- Textarea -->
							<div class="media-content">
								<div class="field">
									<div
										placeholder="Write a comment..."
										[attr.id]="index + 'tag-friends'"
										class="tag-fr textarea"
										contenteditable="true"
										(input)="model = $event.target.textContent"
										(keyup)="searchUser(model)"
									></div>

									<div
										class="select-custome"
										*ngIf="arrUser?.length > 0 && model?.length > 0"
									>
										<div class="s-user" id="s-user-autocpl">
											<ul>
												<li
													class="is-selected"
													*ngFor="
														let user of arrUser | slice: 0:5;
														let indexs = index
													"
													(click)="tagUser(user, index, post)"
												>
													<div class="eac-item">
														<div class="template-wrapper">
															<div class="avatar-wrapper">
																<img
																	class="autocpl-avatar"
																	*ngIf="user?.avatar"
																	[src]="user?.avatar"
																/>
															</div>
															<div class="entry-text">
																{{ user?.firstname }} {{ user?.lastname }}
															</div>
														</div>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<!-- Additional actions -->
								<div class="actions">
									<div class="image is-32x32 img-cus">
										<img
											class="is-rounded"
											[src]="user?.avatar"
											[attr.data-demo-src]="user?.avatar"
											[attr.data-user-popover]="user?._id"
											alt
										/>
									</div>
									<div class="toolbar">
										<div class="action is-auto">
											<i-feather name="at-sign"></i-feather>
										</div>
										<div class="action is-emoji">
											<i-feather name="smile"></i-feather>
										</div>
										<div class="is-active emoji"></div>
										<div class="action is-upload">
											<i-feather name="camera"></i-feather>
											<input
												type="file"
												accept="video/mp4,image/png,image/jpeg"
												(change)="detectFile($event, index, post)"
											/>
										</div>
										<a
											class="button is-solid primary-button raised"
											(click)="
												post?.arrRepliesTemporary?.length > 0
													? createReply(post, index)
													: createComment(post, index)
											"
											>Post Comment</a
										>
									</div>
								</div>
								<div [attr.id]="index + 'cmt-image'" class="cmt-img">
									<div class="cmt-img-wrapper">
										<img
											*ngIf="post?.format === 'image' && post?.contentImg"
											id="cmt-img"
											[src]="post?.contentImg"
											alt=""
										/>
									</div>
									<div class="cmt-video">
										<video
											*ngIf="post?.format === 'video' && post?.contentVideo"
											[src]="post?.contentVideo"
											height="200"
											controls
											controlsList="nodownload"
										></video>
									</div>
									<div class="close-img" (click)="closeImg(index, post)">
										<i-feather name="x"></i-feather>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- /Comments footer -->
				</div>
				<!-- /Comments -->
			</div>
			<!-- /Post -->
			<!-- Load more photos -->
		</div>
	</ng-container>
</div>

<!-- <div class="load-more-wrap has-text-centered">
	<a class="load-more-button" (click)="getMorePosts()">Load More</a>
</div> -->
<app-page-photo-lightbox-container
	*ngIf="dataPost"
	[dataPost]="dataPost"
	[isActivePhoto]="isActivePhoto"
	[InfoUser]="user"
	[indexImg]="indexImg"
	(closeModal)="closeModal($event)"
></app-page-photo-lightbox-container>
