import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  //#region CRUD information user
  getProfile(): Observable<any> {
    return this.http.get(`user/getinfouser`);
  }

  getProfileById(data): Observable<any> {
    return this.http.post(`user/getInfoUserById`, data);
  }

  updateProfile(data: any): Observable<any> {
    return this.http.post(`user/updateUserLink`, data);
  }

  uploadAvatar(obj): Observable<any> {
    return this.http.post(`user/uploadAvatar`, obj);
  }

  uploadCover(obj): Observable<any> {
    return this.http.post(`user/uploadCover`, obj);
  }

  isChangeImg = new BehaviorSubject<any>([]);
  changeSuccess = this.isChangeImg.asObservable();
  changeImg(success) {
    this.isChangeImg.next(success);
  }

  isSeeAll = new BehaviorSubject<any>([]);
  seeAll = this.isSeeAll.asObservable();
  clickSeeAll(see) {
    this.isSeeAll.next(see);
  }

  //#endregion

  //#region CHANGE password
  changePassword(data: any): Observable<any> {
    return this.http.post(`user/changePassword`, data);
  }
  //#endregion

  //#region CRUD request friend
  friendRequest(data): Observable<any> {
    return this.http.post('friend-request/create', data);
  }
  friendAccept(data): Observable<any> {
    return this.http.post('friend/create', data);
  }
  getFriends(): Observable<any> {
    return this.http.get('friend');
  }
  deleteFriend(data): Observable<any> {
    return this.http.post('friend/delete', data);
  }
  deleteFriendById(id): Observable<any> {
    return this.http.delete(`friend/delete/${id}`);
  }
  getFriendsById(data): Observable<any> {
    return this.http.post('friend/getById', data);
  }
  getFriendsRequest(): Observable<any> {
    return this.http.get('friend-request');
  }
  deleteFriendRequest(data): Observable<any> {
    return this.http.post(`friend-request/deleteRequest`, data);
  }
  deleteRequestFriend(id): Observable<any> {
    return this.http.delete(`friend-request/delete/${id}`);
  }
  checkFriendRequest(data): Observable<any> {
    return this.http.post('friend-request/checkFriendRequest', data);
  }
  checkFriend(data): Observable<any> {
    return this.http.post('friend/checkfriend', data);
  }
  //#endregion

  //#region CRUD follow user
  getFollowers(): Observable<any> {
    return this.http.get('follow/follower');
  }
  getFollowing(): Observable<any> {
    return this.http.get('follow');
  }
  follow(data): Observable<any> {
    return this.http.post('follow/follow', data);
  }
  unfollow(data): Observable<any> {
    return this.http.post('follow/unfollow', data);
  }
  checkFollow(data): Observable<any> {
    return this.http.post('follow/checkfollow', data);
  }
  //#endregion

  //#region CRUD chat messages
  getChat(): Observable<any> {
    return this.http.get('chat');
  }
  chat(data): Observable<any> {
    return this.http.post('chat/create', data);
  }
  deleteChat(id): Observable<any> {
    return this.http.delete(`chat/delete/${id}`);
  }
  //#endregion

  //#region CRUD Album of user
  createAlbum(data): Observable<any> {
    return this.http.post('album/create', data);
  }
  updateAlbum(data): Observable<any> {
    return this.http.post('album/update', data);
  }
  uploadImage(img): Observable<any> {
    return this.http.post('post/upload', img);
  }

  getAlbumByUserId(userId): Observable<any> {
    return this.http.post(`album/`, userId);
  }

  createPhoto(data): Observable<any> {
    return this.http.post('photo/create', data);
  }

  getPhotos(data): Observable<any> {
    return this.http.post('photo/getPhotosByUserId', data);
  }

  getVideos(data): Observable<any> {
    return this.http.post('photo/getVideosByUserId', data);
  }

  getPhotoByAlbumId(albumId): Observable<any> {
    return this.http.get(`photo/getByAlbumId/${albumId}`);
  }

  deletePhoto(id): Observable<any> {
    return this.http.get(`photo/delete/${id}`);
  }
  //#endregion

  //#region API function search
  searchUsers(data): Observable<any> {
    return this.http.post('user/searchByText', data);
  }
  searchPlaces(data): Observable<any> {
    return this.http.post('/places/search', data);
  }
  //#endregion

  //#region CRUD post of user
  createPost(data): Observable<any> {
    return this.http.post('post/create', data);
  }
  createSharePost(data): Observable<any> {
    return this.http.post('post/create', data);
  }

  getPost(): Observable<any> {
    return this.http.get('post');
  }
  getPostByUserId(data): Observable<any> {
    return this.http.post('post/getPostByUserId', data);
  }
  getMorePosts(data): Observable<any> {
    return this.http.post('post/getMore', data);
  }
  getPostUser(obj): Observable<any> {
    return this.http.post(`post/getPostUser`, obj);
  }

  getPostShare(obj): Observable<any> {
    return this.http.post(`post/getPostShare`, obj);
  }
  getshareByPostId(postId): Observable<any> {
    return this.http.get(`post/link/getShareByPostId/${postId}`);
  }
  getPostShareByUserId(obj): Observable<any> {
    return this.http.post(`post/getPostShareUser`, obj);
  }
  updatePost(id, data): Observable<any> {
    return this.http.post(`post/update/${id}`, data);
  }
  //#endregion

  //#region CRUD comment of post user
  createComment(data): Observable<any> {
    return this.http.post('comment/create', data);
  }
  getComment(): Observable<any> {
    return this.http.get('comment');
  }
  updateComment(id, data): Observable<any> {
    return this.http.post(`comment/update/${id}`, data);
  }
  deleteComment(commentId): Observable<any> {
    return this.http.get(`comment/delete/${commentId}`);
  }
  //#endregion

  //#region CRUD reply comment of post user
  createReply(data): Observable<any> {
    return this.http.post('reply/create', data);
  }
  createReplyByFileId(data): Observable<any> {
    return this.http.post('reply/createReplyByFileId', data);
  }
  getReplies(): Observable<any> {
    return this.http.get('reply');
  }
  updateReply(id, data): Observable<any> {
    return this.http.post(`reply/update/${id}`, data);
  }
  deleteReplies(repliesId): Observable<any> {
    return this.http.get(`reply/deleteReply/${repliesId}`);
  }
  //#endregion

  //#region CRUD like (type: {0:post , 1: comment, 2 :reply} )
  createLike(data): Observable<any> {
    return this.http.post('like/create', data);
  }
  createLikeByFileId(data): Observable<any> {
    return this.http.post('like/createByFileId', data);
  }
  getLike(): Observable<any> {
    return this.http.get('like');
  }
  //#endregion

  //#region CRUD education user
  getAllSchool(): Observable<any> {
    return this.http.get(`School/getAllSchool`);
  }
  getEducationByUserId(): Observable<any> {
    return this.http.get(`Education/`);
  }

  getEduByUserId(userId): Observable<any> {
    return this.http.get(`Education/getEduByUserId/${userId}`);
  }
  createEducation(data): Observable<any> {
    return this.http.post('Education/create', data);
  }
  updateEducation(data): Observable<any> {
    return this.http.post(`Education/update/${data.id}`, data);
  }
  //#endregion

  //#region CRUD job user
  getAllCompany(): Observable<any> {
    return this.http.get(`Company/getAllCompany`);
  }
  createJobByUserId(data): Observable<any> {
    return this.http.post(`Job/create`, data);
  }
  getJob(): Observable<any> {
    return this.http.get(`Job/`);
  }

  getJobByUserId(userId): Observable<any> {
    return this.http.get(`Job/getJobByUserId/${userId}`);
  }
  updateJob(data): Observable<any> {
    return this.http.post(`Job/update/${data.id}`, data);
  }
  //#endregion

  //#region CRUD event of user invite
  createEvent(data): Observable<any> {
    return this.http.post(`Event/create`, data);
  }
  getEventByUserId(): Observable<any> {
    return this.http.get(`Event/`);
  }
  updateEventByUserId(data): Observable<any> {
    return this.http.get(`Event/update/${data.id}`, data);
  }
  createInviteEvent(data): Observable<any> {
    return this.http.post(`invite-event/create`, data);
  }
  getInviteEvent(id): Observable<any> {
    return this.http.post(`invite-event/getEventInviteById`, id);
  }
  //#endregion

  //#region  bindding data width service
  callGetList = new BehaviorSubject<any>([]);
  isCall = this.callGetList.asObservable();
  changeListByUser(isCall) {
    this.callGetList.next(isCall);
  }

  showEvent = new BehaviorSubject<any>([]);
  isShowEvent = this.showEvent.asObservable();
  changedataEvent(isShowEvent) {
    this.showEvent.next(isShowEvent);
  }
  //#endregion

  uploadCloud(formData) {
    return this.http.post(`post/upload-cloud`, formData, {
      reportProgress: true,
    });
  }

  getCommentByFile(data): Observable<any> {
    return this.http.post('comment/getCommentAggregate', data);
  }
}

export function checkToken() {
  let token = localStorage.getItem('token');
  if (token) {
    return true;
  } else {
    return false;
  }
}
