import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-birthday-widget',
  templateUrl: './birthday-widget.component.html',
  styleUrls: ['./birthday-widget.component.scss']
})
export class BirthdayWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
