import { Component, Input, OnInit } from '@angular/core';
import iziToast from 'izitoast';
import { PagesService } from 'src/app/services/pages.service';
import * as enviroment from '../../../../../../environments/environment';
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
  @Input() set dataPage(dataPage: any) {
    this.dataPages = dataPage;
    if (this.dataPages) {
      let pageCategory = this.dataPages.pageCategory;
      this.getRelatedPages(pageCategory);
    }
  }
  @Input() set countLikePage(countLikePage: number) {
    this.countLike =
      countLikePage >= 1000 ? countLikePage + 'K' : countLikePage;
  }
  @Input() set countFLPage(countFLPage: number) {
    this.countFollow = countFLPage >= 1000 ? countFLPage + 'K' : countFLPage;
  }
  dataPages;
  countFollow;
  countLike;
  imgDefault;
  enviroment;
  constructor(private pagesServe: PagesService) {
    this.enviroment = enviroment.environment.apiUrl;
  }

  ngOnInit(): void {
    this.imgDefault = '/assets/img/avatar-default.png';
  }
  //#region Get related pages with pageCategory
  arrRelatedPage = [];
  getRelatedPages(pageCategory) {
    let requestRelated = {
      pageCategory: pageCategory,
      limit: 5,
      skip: 0,
    };
    this.pagesServe.getRelatedPages(requestRelated).subscribe((result) => {
      if (result.status == 'success') {
        this.arrRelatedPage = result.data;
        if (this.arrRelatedPage.length > 0) {
          for (let page of this.arrRelatedPage) {
            page.isLiked = false;
          }
        }
      }
    });
  }
  //#endregion

  //#region Go to pages
  gotoPage(pageId) {
    window.location.replace(`pages/${pageId}`);
  }
  //#endregion

  //#region Create like pages
  createLikePage(dataPages, index) {
    if (dataPages) {
      let data = {
        pageId: dataPages._id,
      };
      this.pagesServe.createLikePage(data).subscribe((resultlike) => {
        if (resultlike.status == 'success') {
          this.arrRelatedPage[index].isLiked = true;
          iziToast.show({
            class: 'toast',
            message: `Thích thành công`,
            titleColor: '#fff',
            messageColor: '#fff',
            iconColor: '#fff',
            backgroundColor: '#0062ff',
            progressBarColor: '#fafafa',
            position: 'bottomRight',
            transitionIn: 'fadeInDown',
            close: false,
            timeout: 500,
            zindex: 99999,
          });
        }
      });
    }
  }

  unLikePage(dataPages, index) {
    if (dataPages) {
      let data = {
        pageId: dataPages._id,
      };
      this.pagesServe.deleteLikeForPage(data).subscribe((result) => {
        if (result.status == 'success') {
          this.arrRelatedPage[index].isLiked = false;
          iziToast.show({
            class: 'toast',
            message: `Bỏ thích`,
            titleColor: '#fff',
            messageColor: '#fff',
            iconColor: '#fff',
            backgroundColor: '#0062ff',
            progressBarColor: '#fafafa',
            position: 'bottomRight',
            transitionIn: 'fadeInDown',
            close: false,
            timeout: 500,
            zindex: 99999,
          });
        }
      });
    }
  }
  //#endregion
}
