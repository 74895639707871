import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpconfigInterceptor implements HttpInterceptor {
  constructor() {}
  token;
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.token = localStorage.getItem('token');
    if (this.token) {
      request = request.clone({
        url: this.prepareUrl(request.url),
        setHeaders: {
          enctype: 'multipart/form-data',
          // "Content-Type": "application/json",
          'x-token': this.token,
        },
      });
    } else {
      request = request.clone({
        url: this.prepareUrl(request.url),
      });
    }
    return next.handle(request);
  }
  private isAbsoluteUrl(url: string): boolean {
    const absolutePattern = /^https?:\/\//i;
    return absolutePattern.test(url);
  }
  private prepareUrl(url: string): string {
    url = this.isAbsoluteUrl(url) ? url : environment.apiUrl + url;
    return url;
  }
}
