// ================-===========
// //type for function user/post
// 0: like post
// 1: share post
// 2: comment in post
// 3: like comment
// 4 :reply comment
// 5: like reply
// 6: taged user in post
// 7: notification post of user need

export class Notification {
  constructor(
    public notificationConent,
    public notificationType,
    public notificationTags
  ) {}
}
