import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import iziToast from 'izitoast';
import { GroupService } from 'src/app/services/group/group.service';

@Component({
  selector: 'app-post-request-group',
  templateUrl: './post-request-group.component.html',
  styleUrls: ['./post-request-group.component.scss'],
})
export class PostRequestGroupComponent implements OnInit {
  @Input() post;
  @Input() groupId;
  @Output() spliceListPostRequest = new EventEmitter<string>(); // for splice list of request post
  @Output() updateListPost = new EventEmitter();

  constructor(private groupService: GroupService) {}

  ngOnInit(): void {}

  createPhotoByPost(post, image) {
    let reqPhoto = {
      groupId: this.groupId,
      postId: post._id,
      photo: image.url,
      photoName: image.filename,
      groupName: post._id,
    };
    this.groupService.createPhoto(reqPhoto).subscribe((result) => {});
  }

  createGroupPost(post) {
    this.groupService.createPostGroup(post).subscribe((result) => {
      if (result.status == 'success') {
        let dataPost = result.data;
        if (post.postImg.length > 0) {
          for (let img of post.postImg) {
            this.createPhotoByPost(dataPost, img);
          }
        }
        this.groupService.joinGroup({ status: 'success' });
        iziToast.success({
          class: 'toast',
          message: 'Đã chấp thuật bài viết',
          messageColor: '#ffffff',
          iconColor: '#ffffff',
          backgroundColor: '#00cc00',
          progressBarColor: '#fafafa',
          position: 'topRight',
          transitionIn: 'fadeInDown',
          close: false,
          zindex: 99999,
        });
        this.updateListPost.emit(post);
        this.deletePostRequest(post);
      }
    });
  }

  deletePostRequest(post) {
    this.groupService.deleteRequestPostGroup(post._id).subscribe((result) => {
      this.spliceListPostRequest.emit(post._id);
    });
  }

  rejectRequest(post) {
    this.groupService.deleteRequestPostGroup(post._id).subscribe((result) => {
      if (result.status == 'success') {
        this.groupService.joinGroup({ status: 'success' });
        iziToast.success({
          class: 'toast',
          message: 'Đã xóa yêu cầu bài viết của ' + post._id,
          messageColor: '#ffffff',
          iconColor: '#ffffff',
          backgroundColor: '#f71416',
          progressBarColor: '#fafafa',
          position: 'topRight',
          transitionIn: 'fadeInDown',
          close: false,
          zindex: 99999,
        });

        this.spliceListPostRequest.emit(post._id);
      }
    });
  }
}
