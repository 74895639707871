import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preferences-setting',
  templateUrl: './preferences-setting.component.html',
  styleUrls: ['./preferences-setting.component.scss']
})
export class PreferencesSettingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
