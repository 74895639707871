<a class="icon-link is-active">
	<i-feather name="mail"></i-feather>
	<span class="indicator"></span>
</a>
<div class="nav-drop">
	<div class="inner">
		<div class="nav-drop-header">
			<span>Messages</span>
			<a href="messages-inbox.html">Inbox</a>
		</div>
		<div class="nav-drop-body is-friend-requests">
			<!-- Message -->
			<div class="media">
				<figure class="media-left">
					<p class="image">
						<img
							src="https://via.placeholder.com/150x150"
							data-demo-src="assets/img/avatars/nelly.png"
							
						/>
					</p>
				</figure>
				<div class="media-content">
					<a href="#">Nelly Schwartz</a>
					<span>
						I think we should meet near the Starbucks so we can get...
					</span>
					<span class="time">Yesterday</span>
				</div>
				<div class="media-right is-centered">
					<div class="added-icon">
						<i-feather name="message-square"></i-feather>
					</div>
				</div>
			</div>
			<!-- Message -->
			<div class="media">
				<figure class="media-left">
					<p class="image">
						<img
							src="https://via.placeholder.com/150x150"
							data-demo-src="assets/img/avatars/edward.jpeg"
							
						/>
					</p>
				</figure>
				<div class="media-content">
					<a href="#">Edward Mayers</a>
					<span>
						That was a real pleasure seeing you last time we really should...
					</span>
					<span class="time">last week</span>
				</div>
				<div class="media-right is-centered">
					<div class="added-icon">
						<i-feather name="message-square"></i-feather>
					</div>
				</div>
			</div>
			<!-- Message -->
			<div class="media">
				<figure class="media-left">
					<p class="image">
						<img
							src="https://via.placeholder.com/150x150"
							data-demo-src="assets/img/avatars/dan.jpg"
						
						/>
					</p>
				</figure>
				<div class="media-content">
					<a href="#">Dan Walker</a>
					<span>
						Hey there, would it be possible to borrow your bicycle, i really
						need...
					</span>
					<span class="time">Jun 03 2018</span>
				</div>
				<div class="media-right is-centered">
					<div class="added-icon">
						<i-feather name="message-square"></i-feather>
					</div>
				</div>
			</div>
		</div>
		<div class="nav-drop-footer">
			<a href="#">Clear All</a>
		</div>
	</div>
</div>
