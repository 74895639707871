import { Component, Input, OnInit } from "@angular/core";
import * as environment from "src/environments/environment";

@Component({
	selector: "app-star-friends-widget",
	templateUrl: "./star-friends-widget.component.html",
	styleUrls: ["./star-friends-widget.component.scss"],
})
export class StarFriendsWidgetComponent implements OnInit {
	@Input() infouser;
	@Input() friends;
	environment = environment.environment.apiUrl;
	constructor() {}

	ngOnInit(): void {}
}
