<div
	id="compose-card"
	class="card is-new-content"
	*ngIf="inforUser && inforUser._id == userId"
>
	<!-- Top tabs -->
	<div class="tabs-wrapper">
		<div class="tabs is-boxed is-fullwidth">
			<ul>
				<li class="is-active">
					<a>
						<span class="icon is-small">
							<i-feather name="edit-3"></i-feather>
						</span>
						<span>Publish</span>
					</a>
				</li>
				<li>
					<a class="modal-trigger" data-modal="albums-help-modal">
						<span class="icon is-small">
							<i-feather name="image"></i-feather>
						</span>
						<span>Albums</span>
					</a>
				</li>
				<li>
					<a class="modal-trigger" data-modal="videos-help-modal">
						<span class="icon is-small">
							<i-feather name="video"></i-feather>
						</span>
						<span>Video</span>
					</a>
				</li>
				<!-- Close X button -->
				<li class="close-wrap">
					<span class="close-publish">
						<i-feather name="x"></i-feather>
					</span>
				</li>
			</ul>
		</div>
		<!-- Tab content -->
		<div class="tab-content">
			<!-- Compose form -->
			<div class="compose" appDragdrop (files)="filesDropped($event)">
				<div id="compose-img" class="compose-form">
					<img *ngIf="userAvatar" [src]="userAvatar" alt />
					<div class="control" appPaste (filesClipBoard)="filesPasted($event)">
						<textarea
							id="publish"
							class="textarea"
							rows="3"
							placeholder="Write something about you..."
							[(ngModel)]="postContent"
						></textarea>
					</div>
				</div>
				<div
					id="options-summary"
					class="options-summary"
					*ngIf="postFeeling || dataTags?.length > 0 || postShareLink"
				>
					<span
						class="mood-display"
						*ngIf="postFeeling"
						(click)="openRepeatActive(postFeeling)"
					>
						<img [src]="postFeeling?.img" alt="" />
						<span class="txt-active" *ngIf="postFeeling?.text != ''"
							>{{ postFeeling?.text }}
						</span>
						<span> {{ postFeeling?.content }}</span>
					</span>

					<span class="tagged-friend" *ngFor="let tag of dataTags">
						<small>— with</small>
						<a class="is-inverted" href="#">
							{{ tag?.firstname }} {{ tag?.lastname }}
						</a>
						,
					</span>
				</div>
				<div
					id="feed-upload"
					class="feed-upload image-wrap"
					[ngClass]="
						postImg?.length > 4
							? 'img-wrap-inset5-straight'
							: postImg?.length > 4
							? 'img-wrap-inset5-horizontal'
							: postImg?.length == 3
							? 'img-wrap-inset3'
							: postImg?.length == 2
							? 'img-wrap-inset2'
							: postImg?.length == 1
							? 'img-wrap-inset1'
							: ''
					"
					*ngIf="postImg?.length > 0"
				>
					<div
						class="upload-wrap postion-img"
						*ngFor="let url of postImg | slice: 0:5; let i = index"
						[attr.id]="'upload-wrap' + i"
					>
						<img *ngIf="!url.fileType" class="wrap-image" [src]="url?.urlImg" />
						<video
							*ngIf="url.fileType == 'video'"
							class="wrap-image"
							[src]="url?.urlImg"
							controls
							controlsList="nodownload"
						></video>
						<div
							*ngIf="i == 4 && postImg?.length > 5"
							class="count-img-wrap title is-3"
						>
							<span>+{{ postImg?.length - 5 }}</span>
						</div>
						<span class="remove-file" (click)="deleteImg(i)">
							<i-feather name="x"></i-feather>
						</span>
						<div class="caption">
							<input
								type="text"
								placeholder="Add Caption..."
								[(ngModel)]="url.model"
							/>
						</div>
					</div>
				</div>
				<div class="link-summary" id="link-summary" *ngIf="linkPreview">
					<div class="post-link">
						<ng-container *ngIf="linkPreview">
							<div class="link-images">
								<img
									*ngIf="linkPreview?.img"
									[src]="linkPreview ? linkPreview?.img : ''"
									[attr.data-demo-src]="linkPreview?.img"
								/>
							</div>
							<!-- Link content -->
							<div class="link-content">
								<h4>
									<a href="{{ linkPreview?.domain }}">{{
										linkPreview?.title
									}}</a>
								</h4>
								<p>
									{{ linkPreview?.description }}
								</p>
								<small>{{ linkPreview?.domain }}</small>
							</div>
						</ng-container>
						<!-- Post actions -->
					</div>
				</div>
				<div id="tag-suboption" class="is-autocomplete is-suboption is-hidden">
					<!-- Tag friends suboption -->
					<div id="tag-list" class="tag-list">
						<div class="control tag-control" *ngFor="let tag of dataTags">
							<div class="tags has-addons">
								<a class="tag is-link"
									>{{ tag?.firstname }} {{ tag?.lastname }}</a
								>
								<a
									class="tag is-delete is-inverted"
									(click)="deleteTag(tag)"
								></a>
							</div>
						</div>
					</div>
					<div class="control">
						<input
							id="users-autocpl"
							type="text"
							class="input"
							placeholder="Who are you with?"
							[(ngModel)]="searchUser"
							(ngModelChange)="searchUsers()"
							autocomplete="off"
						/>
						<div
							class="easy-autocomplete-container"
							id="eac-container-users-autocpl"
							*ngIf="dataUsers && dataUsers.length > 0 && searchUser"
						>
							<ul>
								<li
									class="is-selected"
									*ngFor="let user of dataUsers"
									(click)="tagUser(user)"
								>
									<div class="eac-item">
										<div class="template-wrapper">
											<div class="avatar-wrapper">
												<img
													class="autocpl-avatar"
													*ngIf="user.avatar"
													[src]="user.avatar"
												/>
												<img
													class="avatar-badge"
													src="assets/img/icons/flags/australia.svg"
												/>
											</div>
											<div class="entry-text">
												{{ user?.firstname }} {{ user?.lastname }}
												<br />
												<span>{{ user?.country }}</span>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
						<div class="icon">
							<i-feather name="search"></i-feather>
						</div>
						<div class="close-icon is-main">
							<i-feather name="x"></i-feather>
						</div>
					</div>
				</div>
				<!-- /Tag friends suboption -->
				<!-- Activities suboption -->
				<div
					id="activities-suboption"
					class="is-autocomplete is-suboption is-hidden"
					[ngClass]="isHidden ? '' : 'is-hidden'"
				>
					<div
						id="activities-autocpl-wrapper"
						class="control has-margin"
						[ngClass]="isActive ? ' ' : 'is-hidden'"
					>
						<input
							id="activities-autocpl"
							type="text"
							class="input"
							placeholder="What are you doing right now?"
						/>
						<div
							class="easy-autocomplete-container"
							id="eac-container-activities-autocpl"
						>
							<ul>
								<li
									class="focus-selected"
									*ngFor="let active of dataActivities"
									(click)="selectActive(active)"
								>
									<div class="eac-item">
										<div class="template-wrapper">
											<div class="avatar-wrapper">
												<img
													class="autocpl-avatar"
													*ngIf="active?.pic"
													[src]="active?.pic"
												/>
											</div>
											<div class="entry-text">
												{{ active?.name }}
												<br />
												<span> {{ active?.desc }} </span>
											</div>
											<div class="next-icon">
												<i class="mdi" mdi-chevron-right=""></i>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
						<div class="icon">
							<i-feather name="search"></i-feather>
						</div>
						<div class="close-icon is-main">
							<i-feather name="x"></i-feather>
						</div>
					</div>
					<!-- Mood suboption -->
					<div
						id="mood-autocpl-wrapper"
						class="is-autocomplete is-activity"
						[ngClass]="isMood ? '' : 'is-hidden'"
					>
						<div class="control has-margin">
							<input
								id="mood-autocpl"
								type="text"
								class="input is-subactivity"
								placeholder="How do you feel?"
							/>
							<div
								class="easy-autocomplete-container search-user"
								id="eac-container-mood-autocpl"
							>
								<ul>
									<li class="focus-selected" *ngFor="let mood of dataMoods">
										<div class="eac-item" (click)="selectValueActive(mood)">
											<div class="template-wrapper">
												<div class="avatar-wrapper">
													<img class="autocpl-avatar" [src]="mood?.pic" />
												</div>
												<div class="entry-text">{{ mood?.name }}</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div class="input-block">Feels</div>
							<div class="close-icon is-subactivity" (click)="closeMoods()">
								<i-feather name="x"></i-feather>
							</div>
						</div>
					</div>
					<!-- Drinking suboption child -->
					<div
						id="drinking-autocpl-wrapper"
						class="is-autocomplete is-activity"
						[ngClass]="isDrink ? '' : 'is-hidden'"
					>
						<div class="control has-margin">
							<input
								id="drinking-autocpl"
								type="text"
								class="input is-subactivity"
								placeholder="What are you drinking?"
							/>
							<div
								class="easy-autocomplete-container search-user"
								id="eac-container-drinking-autocpl"
							>
								<ul>
									<li
										class="selected focus-selected"
										*ngFor="let drink of dataDrinking"
									>
										<div class="eac-item" (click)="selectValueActive(drink)">
											<div class="template-wrapper">
												<div class="avatar-wrapper" is-smaller="">
													<img class="autocpl-avatar" [src]="drink?.pic" />
												</div>
												<div class="entry-text">{{ drink?.name }}</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div class="input-block">Drinks</div>
							<div class="close-icon is-subactivity" (click)="closeMoods()">
								<i-feather name="x"></i-feather>
							</div>
						</div>
					</div>
					<!-- Eating suboption child -->
					<div
						id="eating-autocpl-wrapper"
						class="is-autocomplete is-activity"
						[ngClass]="isEat ? '' : 'is-hidden'"
					>
						<div class="control has-margin">
							<input
								id="eating-autocpl"
								type="text"
								class="input is-subactivity"
								placeholder="What are you eating?"
							/>
							<div
								class="easy-autocomplete-container search-user"
								id="eac-container-eating-autocpl"
							>
								<ul>
									<li
										class="selected focus-selected"
										*ngFor="let eat of dataEatings"
									>
										<div class="eac-item" (click)="selectValueActive(eat)">
											<div class="template-wrapper">
												<div class="avatar-wrapper" is-smaller="">
													<img class="autocpl-avatar" [src]="eat?.pic" />
												</div>
												<div class="entry-text">{{ eat?.name }}</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div class="input-block">Eats</div>
							<div class="close-icon is-subactivity" (click)="closeMoods()">
								<i-feather name="x"></i-feather>
							</div>
						</div>
					</div>
					<!-- Reading suboption child -->
					<div
						id="reading-autocpl-wrapper"
						class="is-autocomplete is-activity"
						[ngClass]="isRead ? '' : 'is-hidden'"
					>
						<div class="control has-margin">
							<input
								id="reading-autocpl"
								type="text"
								class="input is-subactivity"
								placeholder="What are you reading?"
							/>
							<div
								class="easy-autocomplete-container search-user"
								id="eac-container-reading-autocpl"
							>
								<ul>
									<li
										class="selected focus-selected"
										*ngFor="let read of dataReadings"
									>
										<div class="eac-item" (click)="selectValueActive(read)">
											<div class="template-wrapper">
												<div class="avatar-wrapper" is-smaller="">
													<img class="autocpl-avatar" [src]="read?.pic" />
												</div>
												<div class="entry-text">{{ read?.name }}</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div class="input-block">Reads</div>
							<div class="close-icon is-subactivity" (click)="closeMoods()">
								<i-feather name="x"></i-feather>
							</div>
						</div>
					</div>
					<!-- Watching suboption child -->
					<div
						id="watching-autocpl-wrapper"
						class="is-autocomplete is-activity"
						[ngClass]="isWatch ? '' : 'is-hidden'"
					>
						<div class="control has-margin">
							<input
								id="watching-autocpl"
								type="text"
								class="input is-subactivity"
								placeholder="What are you watching?"
							/>
							<div
								class="easy-autocomplete-container search-user"
								id="eac-container-watching-autocpl"
							>
								<ul>
									<li
										class="selected focus-selected"
										*ngFor="let watch of dataWatching"
									>
										<div class="eac-item" (click)="selectValueActive(watch)">
											<div class="template-wrapper">
												<div class="avatar-wrapper" is-smaller="">
													<img class="autocpl-avatar" [src]="watch?.pic" />
												</div>
												<div class="entry-text">{{ watch?.name }}</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div class="input-block">Watches</div>
							<div class="close-icon is-subactivity" (click)="closeMoods()">
								<i-feather name="x"></i-feather>
							</div>
						</div>
					</div>
					<!-- Travel suboption child -->
					<div
						id="travel-autocpl-wrapper"
						class="is-autocomplete is-activity"
						[ngClass]="isTravel ? '' : 'is-hidden'"
					>
						<div class="control has-margin">
							<input
								id="travel-autocpl"
								type="text"
								class="input is-subactivity"
								placeholder="Where are you going?"
							/>
							<div
								class="easy-autocomplete-container search-user"
								id="eac-container-travelling-autocpl"
							>
								<ul>
									<li
										class="selected focus-selected"
										*ngFor="let travel of dataTravels"
									>
										<div class="eac-item" (click)="selectValueActive(travel)">
											<div class="template-wrapper">
												<div class="avatar-wrapper" is-smaller="">
													<img class="autocpl-avatar" [src]="travel?.pic" />
												</div>
												<div class="entry-text">{{ travel?.name }}</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div class="input-block">Travels</div>
							<div class="close-icon is-subactivity" (click)="closeMoods()">
								<i-feather name="x"></i-feather>
							</div>
						</div>
					</div>
				</div>
				<!-- /Activities suboption -->
				<!-- Location suboption -->
				<div
					id="location-suboption"
					class="is-autocomplete is-suboption is-hidden"
				>
					<div
						id="location-autocpl-wrapper"
						class="control is-location-wrapper has-margin"
					>
						<input
							id="location-autocpl"
							type="text"
							class="input"
							placeholder="Where are you now?"
							[(ngModel)]="postLocation"
						/>
						<div
							class="easy-autocomplete-container search-user is-hidden"
							id="eac-container-location-autocpl"
						>
							<ul>
								<li
									class="is-selected focus-selected"
									*ngFor="let place of dataPlaces | filter: postLocation"
									(click)="selectPlace(place)"
								>
									<div class="eac-item">
										<div class="template-wrapper">
											<div class="avatar-wrapper">
												<img
													class="autocpl-avatar"
													*ngIf="place.country"
													[src]="place?.country"
												/>
											</div>
											<div class="entry-text">
												{{ place?.name }}
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
						<div class="icon">
							<i-feather name="map-pin"></i-feather>
						</div>
						<div class="close-icon is-main">
							<i-feather name="x"></i-feather>
						</div>
					</div>
				</div>
				<!-- Link suboption -->
				<div id="link-suboption" class="is-autocomplete is-suboption is-hidden">
					<div
						id="link-autocpl-wrapper"
						class="control is-location-wrapper has-margin"
					>
						<input
							id="link-autocpl"
							type="text"
							class="input"
							[(ngModel)]="postShareLink"
							(keyup.enter)="enterLink(postShareLink)"
							placeholder="Enter the link URL (exp:https://example.com)"
						/>
						<div class="icon">
							<i-feather name="link-2"></i-feather>
						</div>
						<div class="close-icon is-main">
							<i-feather name="x"></i-feather>
						</div>
					</div>
				</div>
				<!-- GIF suboption -->
				<div id="gif-suboption" class="is-autocomplete is-suboption is-hidden">
					<div
						id="gif-autocpl-wrapper"
						class="control is-gif-wrapper has-margin"
					>
						<input
							id="gif-autocpl"
							type="text"
							class="input"
							placeholder="Search a GIF to add"
							autofocus
						/>
						<div class="icon">
							<i-feather name="search"></i-feather>
						</div>
						<div class="close-icon is-main">
							<i-feather name="x"></i-feather>
						</div>
						<div class="gif-dropdown">
							<div class="inner">
								<div class="gif-block">
									<ng-container *ngFor="let img of dataImageGif | slice: 0:3">
										<img [src]="img?.pic" />
									</ng-container>
								</div>
								<div class="gif-block">
									<ng-container *ngFor="let img of dataImageGif | slice: 4:7">
										<img [src]="img?.pic" />
									</ng-container>
								</div>
								<div class="gif-block">
									<ng-container *ngFor="let img of dataImageGif | slice: 8:12">
										<img [src]="img?.pic" />
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- /Compose form -->

			<!-- General extended options -->
			<div id="extended-options" class="compose-options is-hidden">
				<div class="columns is-multiline is-full">
					<!-- Upload action -->
					<div class="column is-6 is-narrower">
						<div class="compose-option is-centered">
							<i-feather name="camera"></i-feather>
							<span>Photo/Video</span>
							<input
								type="file"
								appInput
								(filesInput)="filesInput($event)"
								multiple
							/>
						</div>
					</div>
					<!-- Mood action -->
					<div class="column is-6 is-narrower">
						<div
							id="extended-show-activities"
							class="compose-option is-centered"
							(click)="openActive()"
						>
							<img src="assets/img/icons/emoji/emoji-1.svg" alt />
							<span>Mood/Activity</span>
						</div>
					</div>
					<!-- Tag friends action -->
					<div class="column is-6 is-narrower">
						<div id="open-tag-suboption" class="compose-option is-centered">
							<i-feather name="tag"></i-feather>
							<span>Tag friends</span>
						</div>
					</div>
					<!-- Post location action -->
					<div class="column is-6 is-narrower">
						<div
							id="open-location-suboption"
							class="compose-option is-centered"
						>
							<i-feather name="map-pin"></i-feather>
							<span>Post location</span>
						</div>
					</div>
					<!-- Share link action -->
					<div class="column is-6 is-narrower">
						<div id="open-link-suboption" class="compose-option is-centered">
							<i-feather name="link-2"></i-feather>
							<span>Share link</span>
						</div>
					</div>
					<!-- Post GIF action -->
					<div class="column is-6 is-narrower">
						<div id="open-gif-suboption" class="compose-option is-centered">
							<i-feather name="image"></i-feather>
							<span>Post GIF</span>
						</div>
					</div>
				</div>
			</div>
			<!-- /General extended options -->
			<!-- General basic options -->
			<div id="basic-options" class="compose-options">
				<!-- Upload action -->
				<div class="compose-option">
					<i-feather name="camera"></i-feather>
					<span>Media</span>
					<input
						type="file"
						appInput
						(filesInput)="filesInput($event)"
						multiple
					/>
				</div>
				<!-- Mood action -->
				<div id="show-activities" class="compose-option" (click)="openActive()">
					<img src="assets/img/icons/emoji/emoji-1.svg" alt />
					<span>Activity</span>
				</div>
				<!-- Expand action -->
				<div
					id="open-extended-options"
					class="compose-option"
					(click)="openExtendedOptions = !openExtendedOptions"
				>
					<i-feather name="more-horizontal"></i-feather>
				</div>
			</div>
			<!-- /General basic options -->
			<!-- Hidden Options -->
			<div
				class="hidden-options"
				[ngClass]="showComposeFriends ? 'is-opened' : ''"
			>
				<div class="target-channels">
					<!-- Publication Channel -->
					<div class="channel" (click)="changePost(true)">
						<div class="round-checkbox is-small">
							<div>
								<input
									type="checkbox"
									id="checkbox-1"
									[(ngModel)]="postFeed"
									(change)="changePost(postFeed)"
								/>
								<label for="checkbox-1"></label>
							</div>
						</div>
						<div class="channel-icon">
							<i-feather name="bell"></i-feather>
						</div>
						<div class="channel-name">Activity Feed</div>
						<!-- Dropdown menu -->
						<div
							class="
								dropdown
								is-spaced is-modern is-right is-neutral
								dropdown-trigger
							"
						>
							<div>
								<button class="button" aria-haspopup="true">
									<i-feather
										class="main-icon"
										[name]="postDisplayPost.icon"
									></i-feather>
									<span>{{ postDisplayPost.mediaTitle }}</span>
									<i-feather class="caret" name="chevron-down"></i-feather>
								</button>
							</div>
							<div class="dropdown-menu" role="menu">
								<div class="dropdown-content">
									<ng-container
										*ngFor="
											let display of arrDisplayPost;
											let indexDisplay = index
										"
									>
										<hr class="dropdown-divider" *ngIf="indexDisplay === 3" />
										<a
											class="dropdown-item"
											(click)="selectDisplayPost(display)"
										>
											<div class="media">
												<i-feather [name]="display.icon"></i-feather>
												<div class="media-content">
													<h3>{{ display.mediaTitle }}</h3>
													<small>{{ display.mediaContent }}</small>
												</div>
											</div>
										</a>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="friends-list"
					[ngClass]="showComposeFriends ? '' : 'is-hidden'"
				>
					<div class="list-header">
						<span>Send in a message</span>
						<div class="actions">
							<a
								id="open-compose-search"
								href="javascript:void(0);"
								class="search-trigger"
							>
								<i-feather name="search"></i-feather>
							</a>
							<div id="compose-search" class="control is-hidden">
								<input type="text" class="input" placeholder="Search People" />
								<span>
									<i-feather name="search"></i-feather>
								</span>
							</div>
							<a
								href="javascript:void(0);"
								class="is-inverted modal-trigger"
								data-modal="create-group-modal"
							>
								Create group
							</a>
						</div>
					</div>
					<!-- List body -->
					<div class="list-body">
						<!-- Friend -->
						<div class="friend-block">
							<div class="round-checkbox is-small">
								<div>
									<input type="checkbox" id="checkbox-7" />
									<label for="checkbox-7"></label>
								</div>
							</div>
							<img
								class="friend-avatar"
								src="https://via.placeholder.com/300x300"
								data-demo-src="assets/img/avatars/nelly.png"
								alt
							/>
							<div class="friend-name">Nelly Schwartz</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Footer buttons -->
			<div class="more-wrap">
				<!-- View more button -->
				<button
					id="show-compose-friends"
					type="button"
					class="button is-more"
					aria-haspopup="true"
					[ngClass]="showComposeFriends ? 'is-hidden' : ''"
					(click)="showComposeFriends = !showComposeFriends"
				>
					<i-feather name="more-vertical"></i-feather>
					<span>View More</span>
				</button>
				<!-- Publish button -->
				<button
					id="publish-button"
					type="button"
					class="button is-solid accent-button is-fullwidth"
					[ngClass]="
						postContent || postImg || postFeeling || postShareLink
							? ''
							: 'is-disabled'
					"
					(click)="appendData()"
				>
					<ng-container *ngIf="!isSuccess"> Đăng</ng-container>
					<div class="lds-ellipsis" *ngIf="isSuccess == true">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</button>
			</div>
		</div>
	</div>
</div>
