import { Component, Input, OnInit } from '@angular/core';
import * as environment from '../../../../environments/environment';
@Component({
  selector: 'app-pages-community',
  templateUrl: './pages-community.component.html',
  styleUrls: ['./pages-community.component.scss'],
})
export class PagesCommunityComponent implements OnInit {
  @Input() dataPage;
  @Input() set countLikePage(countLikePage: number) {
    this.countLike =
      countLikePage >= 1000 ? countLikePage + 'K' : countLikePage;
  }
  @Input() set countFLPage(countFLPage: number) {
    this.countFollow = countFLPage >= 1000 ? countFLPage + 'K' : countFLPage;
  }
  @Input() set arrLike(arrLike: any) {
    this.arrLikes = arrLike;
  }

  @Input() set dataFLPage(arrFollower: any) {
    this.arrFollower = arrFollower;
  }
  arrFollower;
  arrLikes;
  countLike;
  countFollow;
  enviroment;
  constructor() {
    this.enviroment = environment.environment.apiUrl;
  }

  ngOnInit(): void {}
}
