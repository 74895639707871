<div class="container is-custom">
  <div id="profile-friends" class="view-wrap is-headless">
    <div class="columns is-multiline no-margin">
      <div class="column is-paddingless"></div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="box-heading">
          <div
            class="
              dropdown
              friends-dropdown
              is-spaced is-neutral
              dropdown-trigger
            "
          >
            <div>
              <div class="button">
                <span>All Friends</span>
                <i-feather name="chevron-down"></i-feather>
              </div>
            </div>
            <div class="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a href="#" class="dropdown-item">
                  <div class="media">
                    <i-feather name="heart"></i-feather>
                    <div class="media-content">
                      <h3>Close Friends</h3>
                      <small>Your closest friends list.</small>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item">
                  <div class="media">
                    <i-feather name="bell"></i-feather>
                    <div class="media-content">
                      <h3>Followed</h3>
                      <small>Friends you are following.</small>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item">
                  <div class="media">
                    <i-feather name="coffee"></i-feather>
                    <div class="media-content">
                      <h3>Work relations</h3>
                      <small>Your work relations.</small>
                    </div>
                  </div>
                </a>
                <hr class="dropdown-divider" />
                <a
                  class="dropdown-item modal-trigger"
                  data-modal="albums-modal"
                >
                  <div class="media">
                    <i-feather name="mail"></i-feather>
                    <div class="media-content">
                      <h3>Friend Requests</h3>
                      <small>Your pending friend requests.</small>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="control heading-search">
            <input
              type="text"
              class="input is-rounded"
              placeholder="Search Friends..."
            />
            <div class="search-icon">
              <i-feather name="search"></i-feather>
            </div>
          </div>
        </div>
        <div class="friends-grid" *ngIf="friends?.length > 0">
          <div class="columns is-multiline">
            <!-- Friend item -->
            <div class="column is-3" *ngFor="let friend of friends">
              <app-profile-friend-item
                [friend]="friend"
                [loggedUserId]="loggedUserId"
                [infoUserId]="infouser._id"
                (friendListChange)="onfriendListChange($event)"
              ></app-profile-friend-item>
            </div>
          </div>
        </div>
        <div class="load-more-wrap has-text-centered">
          <a class="load-more-button">Load More</a>
        </div>
      </div>
    </div>
  </div>
</div>
