import {
  Directive,
  EventEmitter,
  Output,
  Input,
  HostListener,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Directive({
  selector: '[appInput]',
})
export class InputDirective {
  @Output() filesInput: EventEmitter<any> = new EventEmitter();
  constructor(private sanitizer: DomSanitizer) { }
  @HostListener('change', ['$event']) onChange(event) {
    event.preventDefault();
    event.stopPropagation();
    let files = [];
    for (let file of event.target.files) {
      let fileType;
      if (file.type == 'video/mp4') {
        fileType = 'video';
      }
      let url = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(file)
      );
      files.push({
        nameUrl: file.name,
        urlImg: url,
        fileType: fileType,
        formData: file,
      });
    }
    this.filesInput.emit(files);
  }
}
