<div class="questions-nav">
	<div class="inner is-scrollable">
		<div class="container">
			<div class="questions-nav-menu">
				<a
					href="questions/home"
					class="menu-item"
					[ngClass]="{'is-active': isActives == 0}"
				>
					<i-feather name="home"></i-feather>
					<span>Home</span>
				</a>
				<a
					href="questions/single"
					class="menu-item"
					[ngClass]="{'is-active': isActives == 4}"
				>
					<i-feather name="message-circle"></i-feather>
					<span>My Questions</span>
				</a>
				<a
					href="questions/stats"
					class="menu-item"
					[ngClass]="{'is-active': isActives == 2}"
				>
					<i-feather name="hexagon"></i-feather>
					<span>My Stats</span>
				</a>
				<a
					href="questions/categories"
					class="menu-item"
					[ngClass]="{'is-active': isActives == 1}"
				>
					<i-feather name="layers"></i-feather>
					<span>Categories</span>
				</a>
				<a
					href="questions/settings"
					class="menu-item"
					[ngClass]="{'is-active': isActives == 3}"
				>
					<i-feather name="settings"></i-feather>
					<span>Settings</span>
				</a>
			</div>
		</div>
	</div>
</div>
<div class="questions-nav" *ngIf="isQuestion">
	<div class="inner">
		<div class="container">
			<div class="question-summary">
				<img
					src="https://via.placeholder.com/150x150"
					data-demo-src="assets/img/avatars/dan.jpg"
					
					alt=""
				/>
				<div class="meta">
					<h4>How can I handle photos with javascript?</h4>
					<span>Dan Walker | 2 day ago</span>
				</div>
				<div class="dropdown is-spaced is-accent is-right dropdown-trigger">
					<div>
						<div class="button">
							<i-feather name="more-vertical"></i-feather>
						</div>
					</div>
					<div class="dropdown-menu" role="menu">
						<div class="dropdown-content">
							<a href="#" class="dropdown-item">
								<div class="media">
									<i-feather name="message-circle"></i-feather>
									<div class="media-content">
										<h3>My Questions</h3>
										<small>All questions you posted.</small>
									</div>
								</div>
							</a>
							<a class="dropdown-item">
								<div class="media">
									<i-feather name="hexagon"></i-feather>
									<div class="media-content">
										<h3>My Groups</h3>
										<small>View the groups you are part of.</small>
									</div>
								</div>
							</a>
							<a class="dropdown-item">
								<div class="media">
									<i-feather name="delete"></i-feather>
									<div class="media-content">
										<h3>Unsolved</h3>
										<small>View unsolved questions.</small>
									</div>
								</div>
							</a>
							<a class="dropdown-item">
								<div class="media">
									<i-feather name="disc"></i-feather>
									<div class="media-content">
										<h3>Solved</h3>
										<small>View solved questions.</small>
									</div>
								</div>
							</a>
							<hr class="dropdown-divider" />
							<a href="#" class="dropdown-item">
								<div class="media">
									<i-feather name="message-square"></i-feather>
									<div class="media-content">
										<h3>Ask</h3>
										<small>Post a new question.</small>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
