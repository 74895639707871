import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { group } from '@angular/animations';
@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(private http: HttpClient) {}

  //#region call function using service
  isChangeCover = new BehaviorSubject<any>([]);
  changeCover = this.isChangeCover.asObservable();
  changeCoverSuccess(value) {
    this.isChangeCover.next(value);
  }

  isBtnAdmin = new BehaviorSubject<any>([]);
  btnAdmin = this.isBtnAdmin.asObservable();
  clickBtnAdmin(click) {
    this.isBtnAdmin.next(click);
  }

  isSuccessPost = new BehaviorSubject<any>([]);
  successPost = this.isSuccessPost.asObservable();
  submitPost(isSuccess) {
    this.isSuccessPost.next(isSuccess);
  }

  isJoin = new BehaviorSubject<any>([]);
  joinSuccess = this.isJoin.asObservable();
  joinGroup(jsJoin) {
    this.isJoin.next(jsJoin);
  }

  isSeeAllPhoto = new BehaviorSubject<any>([]);
  seeAllPhoto = this.isSeeAllPhoto.asObservable();
  clickSeeAllphoto(isSee) {
    this.isSeeAllPhoto.next(isSee);
  }

  isSeeMember = new BehaviorSubject<any>([]);
  serrMember = this.isSeeMember.asObservable();
  clickSeeMember(isSee) {
    this.isSeeMember.next(isSee);
  }

  isSubmitAlbum = new BehaviorSubject<any>([]);
  successAlbum = this.isSubmitAlbum.asObservable();
  submitPhoto(success) {
    this.isSubmitAlbum.next(success);
  }

  isAttaced = new BehaviorSubject<any>([]);
  clickAttached = this.isAttaced.asObservable();
  openAttached(isAttaced: any) {
    this.isAttaced.next(isAttaced);
  }
  //#endregion

  //#region CRUD group
  checkGroupName(obj): Observable<any> {
    return this.http.post(`link/group/checkGroupName`, obj);
  }
  createGroup(obj): Observable<any> {
    return this.http.post(`link/group/create`, obj);
  }

  getGroupByUserId(): Observable<any> {
    return this.http.get(`link/group/getGroupAdminByUserId`);
  }

  getGroupsAdminByUserId(): Observable<any> {
    return this.http.get(`link/group/getGroupByUserId`);
  }

  getGroupById(groupId): Observable<any> {
    return this.http.get(`link/group/getGroupById/${groupId}`);
  }

  uploadCoverGroup(obj): Observable<any> {
    return this.http.post(`link/group/uploadCover`, obj);
  }

  searchGroup(groupName): Observable<any> {
    return this.http.post(`link/group/searchGroupByName`, groupName);
  }
  //#endregion

  //#region CRUD admin, censor group
  changeAdminGroup(obj): Observable<any> {
    return this.http.post(`link/group/changeGroupAdmin`, obj);
  }

  createAdmin(obj): Observable<any> {
    return this.http.post(`link/managing-group/create`, obj);
  }

  deleteAdmin(obj): Observable<any> {
    return this.http.post(`link/managing-group/deleteAdmin`, obj);
  }

  getAdminGroup(groupId): Observable<any> {
    return this.http.get(`link/managing-group/getAdminByGroupId/${groupId}`);
  }

  checkAdminGroup(groupId): Observable<any> {
    return this.http.get(`link/managing-group/checkAdmin/${groupId}`);
  }

  checkCensorGroup(groupId): Observable<any> {
    return this.http.get(`link/managing-group/checkCensor/${groupId}`);
  }

  //#endregion

  //#region CRUD request member
  getMemberRequest(obj): Observable<any> {
    return this.http.post(`link/group-member-request/get`, obj);
  }
  getMemberRequestById(obj): Observable<any> {
    return this.http.post(`link/group-member-request/getByUserId`, obj);
  }
  createMemberRequest(obj): Observable<any> {
    return this.http.post(`link/group-member-request/create`, obj);
  }
  deleteMemberRequest(id): Observable<any> {
    return this.http.delete(`link/group-member-request/delete/${id}`);
  }
  //#endregion

  //#region CRUD member of group
  createMember(obj): Observable<any> {
    return this.http.post(`link/group-member/create`, obj);
  }

  getMembers(obj): Observable<any> {
    return this.http.post(`link/group-member/getMembers`, obj);
  }

  leaveGroup(obj): Observable<any> {
    return this.http.post(`link/group-member/leaveGroup`, obj);
  }

  getGroupsByUserLogin(): Observable<any> {
    return this.http.get(`link/group-member/getGroupsByUser`);
  }

  deleteMember(id): Observable<any> {
    return this.http.get(`link/group-member/delete/${id}`);
  }

  blockMember(obj): Observable<any> {
    return this.http.post(`link/group-member/blocked`, obj);
  }

  searchMember(memberName): Observable<any> {
    return this.http.post(`link/group-member/searchMember`, memberName);
  }

  checkMember(obj): Observable<any> {
    return this.http.post(`link/group-member/checkMemberGroup`, obj);
  }

  attachedGroup(obj): Observable<any> {
    return this.http.post(`link/group-member/attached`, obj);
  }
  getGroupAttached(): Observable<any> {
    return this.http.get(`link/group-member/getGroupAttached`);
  }
  //#endregion

  //#region Search User from table user
  searchUser(userName): Observable<any> {
    return this.http.post(`user/link/searchUser`, userName);
  }
  getUsers(groupId): Observable<any> {
    return this.http.post(`user/link/getUsers`, groupId);
  }
  //#endregion

  //#region   CRUD post of group
  getPostNewFeeds(obj): Observable<any> {
    return this.http.post(`link/group-post/getPostNewFeed`, obj);
  }

  getPostNewFeed(obj): Observable<any> {
    return this.http.post(`link/group-post/`, obj);
  }

  requestPostGroup(obj): Observable<any> {
    return this.http.post(`link/group-post-request/create`, obj);
  }

  getPostRequestGroup(groupId): Observable<any> {
    return this.http.get(`link/group-post-request/getPostRequest/${groupId}`);
  }

  deleteRequestPostGroup(requestId): Observable<any> {
    return this.http.delete(`link/group-post-request/delete/${requestId}`);
  }

  deletePostGroup(id): Observable<any> {
    return this.http.delete(`link/group-post/delete/${id}`);
  }

  createPostGroup(obj): Observable<any> {
    return this.http.post(`link/group-post/create`, obj);
  }

  getPostGroup(groupId): Observable<any> {
    return this.http.get(`link/group-post/getPosts/${groupId}`);
  }

  getMorePosts(obj): Observable<any> {
    return this.http.post(`link/group-post/getMore`, obj);
  }
  //#endregion

  //#region CRUD like post, comment, reply of user
  createLike(obj): Observable<any> {
    return this.http.post(`link/group-post-like/create`, obj);
  }

  getLikesByPostId(postId): Observable<any> {
    return this.http.get(`link/group-post-like/getLikeByPostId/${postId}`);
  }

  getLikeByCommentId(cmtId): Observable<any> {
    return this.http.get(`link/group-post-like/getLikeByCommentId/${cmtId}`);
  }

  getLikeByReplyId(repliesId): Observable<any> {
    return this.http.get(`link/group-post-like/getLikeByReplyId/${repliesId}`);
  }

  unlikePost(obj): Observable<any> {
    return this.http.post(`link/group-post-like/unLikePost`, obj);
  }
  unlikeComment(obj): Observable<any> {
    return this.http.post(`link/group-post-like/unLikeComment`, obj);
  }
  unlikeReply(obj): Observable<any> {
    return this.http.post(`link/group-post-like/unlikeReply`, obj);
  }
  //#endregion

  //#region CRUD comment
  createComment(obj): Observable<any> {
    return this.http.post(`link/group-post-comment/create`, obj);
  }

  deleteComment(cmtId): Observable<any> {
    return this.http.get(`link/group-post-comment/delete/${cmtId}`);
  }

  getComments(obj): Observable<any> {
    return this.http.post(`link/group-post-comment/getCmtByPostId`, obj);
  }

  getCommentById(cmtId): Observable<any> {
    return this.http.get(`link/group-post-comment/getCmtById/${cmtId}`);
  }
  //#endregion

  //#region CRUD reply
  createReply(obj): Observable<any> {
    return this.http.post(`link/group-post-reply/create`, obj);
  }

  deleteReplies(repliesId): Observable<any> {
    return this.http.get(`link/group-post-reply/delete/${repliesId}`);
  }

  editReplies(obj): Observable<any> {
    return this.http.post(`link/group-post-reply/update`, obj);
  }

  getReplysByCmtId(obj): Observable<any> {
    return this.http.post(`link/group-post-reply/getReplyByCmtId`, obj);
  }
  getReplyById(repliesId): Observable<any> {
    return this.http.get(`link/group-post-reply/getReplyById/${repliesId}`);
  }

  //#endregion

  //#region CRUD album group
  createAlbumPhoto(obj): Observable<any> {
    return this.http.post(`link/group-album/create`, obj);
  }

  getAlbumGroup(groupId): Observable<any> {
    return this.http.get(`link/group-album/getAlbumByGroupId/${groupId}`);
  }

  getMoreAlbum(obj): Observable<any> {
    return this.http.post(`link/group-album/getMoreAlbum`, obj);
  }

  deleteAlbum(albumId): Observable<any> {
    return this.http.get(`link/group-album/delete/${albumId}`);
  }

  //#endregion

  //#region CRUD photo group
  createPhoto(photos): Observable<any> {
    return this.http.post(`link/group-photo/create`, photos);
  }

  getPhotoByGroupId(groupId): Observable<any> {
    return this.http.get(`link/group-photo/getPhotoByGroupId/${groupId}`);
  }

  getMorePhotoByGroupId(obj): Observable<any> {
    return this.http.post(`link/group-photo/getPhotosByGroupId`, obj);
  }

  getPhotoByAlubmId(obj): Observable<any> {
    return this.http.post(`link/group-photo/getByAlbumId`, obj);
  }
  deletePhotoGroup(id): Observable<any> {
    return this.http.delete(`link/group-photo/delete/${id}`);
  }
  deletePhotoByPostId(id): Observable<any> {
    return this.http.delete(`link/group-photo/deleteByPostId/${id}`);
  }
  //#endregion
  getVideoGroup(parameters?): Observable<any> {
    let params = new HttpParams();
    if (!isUndefined(parameters)) {
      params = isUndefined(parameters.skip)
        ? params
        : params.append('skip', parameters.skip);
      params = isUndefined(parameters.limit)
        ? params
        : params.append('limit', parameters.limit);
    }
    return this.http.get(`link/group-post/getVideoGroup`, { params });
  }
}
function isUndefined(value) {
  if (value) {
    return false;
  } else {
    return true;
  }
}
