<div
	id="account-dropdown"
	class="navbar-item is-account drop-trigger has-caret"
>
	<div class="user-image">
		<img
			*ngIf="profile"
			[src]="profile.avatar"
			data-demo-src="assets/img/avatars/jenna.png"
			alt
		/>
		<span class="indicator"></span>
	</div>
	<div class="nav-drop is-account-dropdown">
		<div class="inner">
			<div class="nav-drop-header">
				<span class="username" *ngIf="profile">
					{{ profile.firstname }} {{ profile.lastname }}
				</span>
				<label class="theme-toggle">
					<input
						id="toggle-theme"
						type="checkbox"
						(click)="changeTheme()"
						[checked]="theme == 'dark'"
					/>
					<span class="toggler">
						<span class="dark">
							<i-feather name="moon"></i-feather>
						</span>
						<span class="light">
							<i-feather name="sun"></i-feather>
						</span>
					</span>
				</label>
			</div>
			<div class="nav-drop-body account-items">
				<a id="profile-link" (click)="goProfile()" class="account-item">
					<div class="media">
						<div class="media-left">
							<div class="image">
								<img
									*ngIf="profile"
									src="{{ profile.avatar }}"
									data-demo-src="assets/img/avatars/jenna.png"
									alt
								/>
							</div>
						</div>
						<div class="media-content" (click)="goProfile()">
							<h3 *ngIf="profile">
								{{ profile.firstname }} {{ profile.lastname }}
							</h3>
							<small>Main account</small>
						</div>
						<div class="media-right">
							<i-feather class="check" name="check"></i-feather>
						</div>
					</div>
				</a>
				<hr class="account-divider" />
				<a class="account-item" (click)="logout()">
					<div class="media">
						<div class="icon-wrap">
							<i-feather name="power"></i-feather>
						</div>
						<div class="media-content">
							<h3>Log out</h3>
							<small>Log out from your account.</small>
						</div>
					</div>
				</a>

				<hr class="account-divider" />
				<div class="fab-wrapper">
					<a
						id="profile-link"
						class="account-item share-fab modal-trigger"
						(click)="openModalCreatePage()"
						data-modal="create-page"
					>
						<div class="media">
							<div class="icon-wrap">
								<i-feather name="file-plus"></i-feather>
							</div>
							<div class="media-content">
								<h3>Create Page</h3>
							</div>
						</div>
					</a>
				</div>
				<div class="fab-wrapper">
					<a
						id="profile-link"
						class="account-item share-fab modal-trigger"
						data-modal="create-group"
						(click)="openModelCreateGroup()"
					>
						<div class="media">
							<div class="icon-wrap">
								<i-feather name="users"></i-feather>
							</div>
							<div class="media-content">
								<h3>Create Group</h3>
							</div>
						</div>
					</a>
				</div>
				<ng-container *ngFor="let page of dataMyPage">
					<!-- <ng-container *ngIf="page?.isAttached"> -->
					<hr class="account-divider" />
					<a class="account-item" (click)="goMyPage(page)">
						<div class="media">
							<div class="media-left">
								<div class="image">
									<img
										[src]="page?.pageAvatar"
										data-demo-src="assets/img/avatars/jenna.png"
										alt
									/>
								</div>
							</div>
							<div class="media-content">
								<h3>{{ page?.pageName }}</h3>
								<small>{{ page?.pageTitle }}</small>
							</div>
							<div class="media-right">
								<i-feather class="check" name="check"></i-feather>
							</div>
						</div>
					</a>
					<!-- </ng-container> -->
				</ng-container>

				<ng-container *ngFor="let profile of loggedInUsers">
					<hr class="account-divider" />
					<a class="account-item" (click)="switchAccount(profile)">
						<div class="media">
							<div class="media-left">
								<div class="image"></div>
							</div>
							<div class="media-content">
								<h3>{{ profile?.username }}</h3>
							</div>
						</div>
					</a>
				</ng-container>
				<a class="account-item" (click)="goMyGroup()" *ngIf="dataGroup">
					<hr class="account-divider" />
					<div class="media">
						<div class="media-left">
							<div class="image">
								<img
									[src]="dataGroup?.groupCover"
									data-demo-src="assets/img/avatars/jenna.png"
									alt
								/>
							</div>
						</div>
						<div class="media-content">
							<h3>{{ dataGroup?.groupName }}</h3>
						</div>
						<div class="media-right">
							<i-feather class="check" name="check"></i-feather>
						</div>
					</div>
				</a>
				<hr class="account-divider" />
				<a (click)="goSettings()" class="account-item">
					<div class="media">
						<div class="icon-wrap">
							<i-feather name="settings"></i-feather>
						</div>
						<div class="media-content">
							<h3>Settings</h3>
							<small>Access widget settings.</small>
						</div>
					</div>
				</a>
				<a class="account-item">
					<div class="media">
						<div class="icon-wrap">
							<i-feather name="life-buoy"></i-feather>
						</div>
						<div class="media-content">
							<h3>Help</h3>
							<small>Contact our support.</small>
						</div>
					</div>
				</a>
				<!-- <a class="account-item" (click)="logout()">
					<div class="media">
						<div class="icon-wrap">
							<i-feather name="power"></i-feather>
						</div>
						<div class="media-content">
							<h3>Log out</h3>
							<small>Log out from your account.</small>
						</div>
					</div>
				</a> -->
			</div>
		</div>
	</div>
</div>
<app-pages-create
	(isModalctrPage)="changeModalcrtPageChild($event)"
></app-pages-create>
<app-group-create></app-group-create>
