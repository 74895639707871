import { Component, OnInit, Input } from "@angular/core";
import { ProfileService } from "src/app/services/profile.service";

@Component({
	selector: "app-security-setting",
	templateUrl: "./security-setting.component.html",
	styleUrls: ["./security-setting.component.scss"],
})
export class SecuritySettingComponent implements OnInit {
	@Input() user;
	currentPassword;
	newPassword;
	repeatPassword;
	phoneNumber;
	constructor(private profileService: ProfileService) {}

	ngOnInit(): void {}

	changePassword() {
		if (
			this.currentPassword.length >= 6 &&
			this.newPassword.length >= 6 &&
			this.repeatPassword.length >= 6
		) {
			let data = {
				password: this.currentPassword,
				newPassword: this.newPassword,
				repeatpassword: this.repeatPassword,
			};
			this.profileService.changePassword(data).subscribe((result) => {
				this.clearInputs();
			});
		}
	}

	clearInputs() {
		this.currentPassword = undefined;
		this.repeatPassword = undefined;
		this.newPassword = undefined;
	}
}
