import { Component, OnInit, AfterViewChecked, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';
import * as dataMoods from '../../../../assets/data/api/activities/mood/mood-autocpl.json';
import * as dataActivities from '../../../../assets/data/api/activities/activity-autocpl.json';
import * as dataDrinking from '../../../../assets/data/api/activities/drinking/drinking-autocpl.json';
import * as dataImageGif from '../../../../assets/data/api/gif/imagegif.json';
import * as dataPlaces from '../../../../assets/data/api/places/places-autocpl.json';
import * as dataEatings from '../../../../assets/data/api/activities/eating/eating-autocpl.json';
import * as dataReadings from '../../../../assets/data/api/activities/reading/reading-autocpl.json';
import * as dataTravels from '../../../../assets/data/api/activities/travel/travel-autocpl.json';
import * as dataWatching from '../../../../assets/data/api/activities/watching/watching-autocpl.json';
import * as environment from 'src/environments/environment';
import { PagesService } from 'src/app/services/pages.service';
import { FeedService } from 'src/app/services/feed.service';
import { NgxImageCompressService } from 'ngx-image-compress';
declare var jQuery: any;

declare var window: any;

declare var $: any;
import * as loadjs from 'src/app/pages/feed-layout/pagelayout';
@Component({
  selector: 'app-compose-card',
  templateUrl: './compose-card.component.html',
  styleUrls: ['./compose-card.component.scss'],
})
export class ComposeCardComponent implements OnInit, AfterViewChecked, OnChanges {
  arrDisplayPost = [
    {
      id: 0,
      icon: 'globe',
      mediaTitle: 'Public',
      mediaContent: 'Anyone can see this publication.',
    },
    {
      id: 1,
      icon: 'users',
      mediaTitle: 'Friends',
      mediaContent: 'only friends can see this publication.',
    },
    {
      id: 2,
      icon: 'user',
      mediaTitle: 'Specific friends',
      mediaContent: "Don't show it to some friends.",
    },
    {
      id: 3,
      icon: 'lock',
      mediaTitle: 'Only me',
      mediaContent: 'Only me can see this publication.',
    },
  ];
  arrDisplayStory = [
    {
      id: 0,
      icon: 'globe',
      mediaTitle: 'Public',
      mediaContent: 'Anyone can see this publication.',
    },
    {
      id: 1,
      icon: 'users',
      mediaTitle: 'Friends',
      mediaContent: 'only friends can see this publication.',
    },
    {
      id: 2,
      icon: 'user',
      mediaTitle: 'Friends and contacts',
      mediaContent: 'Your friends and contact.',
    },
  ];

  searchUser;
  dataUsers;
  dataTags = [];
  environment = environment.environment.apiUrl;
  postContent;
  postImg = [];
  selectedImg;
  postFeeling;
  postLocation;
  postShareLink;
  userAvatar;

  //#region  model activity
  dataMoods;
  dataActivities;
  dataDrinking;
  dataImageGif;
  dataPlaces;
  dataEatings;
  dataReadings;
  dataTravels;
  dataWatching;
  //#endregion

  activities;
  showComposeFriends;
  openExtendedOptions;
  inforUser;
  @Output() reload = new EventEmitter<string>();
  constructor(
    private profileService: ProfileService,
    private pagesService: PagesService,
    private feedService: FeedService,
    private imageCompress: NgxImageCompressService,
    private route: ActivatedRoute
  ) { }
  isLoadedJs = false;
  ngAfterViewChecked(): void {
    if (this.inforUser) {
      if (!this.isLoadedJs) {
        this.isLoadedJs = true;
        $('#publish').on('click', function () {
          $('.app-overlay').addClass('is-active');
          $('.is-new-content').addClass('is-highlighted');
        });
        $("#publish").on("input", function () {
          var valueLength = $(this).val().length;

          if (valueLength >= 1) {
            $("#publish-button").removeClass("is-disabled");
          } else {
            $("#publish-button").addClass("is-disabled");
          }
        });
      }
    }
  }
  userId;
  ngOnChanges(changes: SimpleChanges) {
  }
  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      this.getUser();
      this.profileService.changeSuccess.subscribe((result) => {
        if (result.status == 'success') {
          this.getUser();
        }
      });
    }
    this.initChangeTexts();
    this.dataWatching = dataWatching['default'];
    this.dataTravels = dataTravels['default'];
    this.dataReadings = dataReadings['default'];
    this.dataEatings = dataEatings['default'];
    this.dataImageGif = dataImageGif['default'];
    this.dataActivities = dataActivities['default'];
    this.dataMoods = dataMoods['default'];
    this.dataDrinking = dataDrinking['default'];
    this.dataPlaces = dataPlaces['default'];

    this.postDisplay = 0;
    this.postFeed = true;
    loadjs.loadjs();
  }

  getUser() {
    this.profileService.getProfile().subscribe((result) => {
      this.inforUser = result.data;
      this.route.params.subscribe((result) => {
        if (result && result.id) {
          this.userId = result.id
        } else {
          this.userId = this.inforUser._id;
        }
        console.log(this.userId, this.inforUser._id);

      });
      this.userAvatar = result['data'].avatar;
    });
  }

  searchUsers() {
    if (this.searchUser != '') {
      let data = { search: this.searchUser };
      this.profileService.searchUsers(data).subscribe((data) => {
        this.dataUsers = data['data'];
        (function ($) {
          $('#eac-container-users-autocpl').css('display', 'block').fadeIn(300);
        })(jQuery);
      });
    }
  }

  searchPlaces() {
    if (this.postLocation != '') {
      this.profileService
        .searchPlaces({ search: this.postLocation })
        .subscribe();
    }
  }

  tagUser(user) {
    let check = false;
    for (let i of this.dataTags) {
      if (i._id === user._id) {
        check = true;
      }
    }
    if (check !== true) {
      this.dataTags.push(user);
      this.searchUser = '';
    }
  }

  deleteTag(user) {
    for (let [index, value] of this.dataTags.entries()) {
      if (value._id == user._id) {
        this.dataTags.splice(index, 1);
      }
    }
  }

  clearInputs() {
    this.postContent = undefined;
    this.postFeeling = undefined;
    this.dataTags = undefined;
    this.postLocation = undefined;
    this.postImg = [];
    this.postShareLink = undefined;
    this.linkPreview = undefined;
    this.isSuccess = false;
    $('.close-publish').trigger('click');
  }

  isMood: boolean = false;
  picActive;
  isDrink: boolean = false;
  isEat: boolean = false;
  isRead: boolean = false;
  isWatch: boolean = false;
  isTravel: boolean = false;
  isActive: boolean = false;
  selectActive(active) {
    this.isActive = !this.isActive;
    if (active.name == 'status') {
      this.picActive = active.pic;
      this.isMood = !this.isMood;
      if (this.isMood == true) {
        (function ($) {
          $(document).ready(function () {
            $('#eac-container-mood-autocpl > ul')
              .css('display', 'block')
              .fadeIn(300);
          });
        })(jQuery);
      }
    } else if (active.name == 'drinking') {
      this.isDrink = !this.isDrink;
      if (this.isDrink == true) {
        (function ($) {
          $(document).ready(function () {
            $('#eac-container-drinking-autocpl > ul')
              .css('display', 'block')
              .fadeIn(300);
          });
        })(jQuery);
      }
    } else if (active.name == 'eating') {
      this.isEat = !this.isEat;
      if (this.isEat == true) {
        (function ($) {
          $(document).ready(function () {
            $('#eac-container-eating-autocpl > ul')
              .css('display', 'block')
              .fadeIn(300);
          });
        })(jQuery);
      }
    } else if (active.name == 'reading') {
      this.isRead = !this.isRead;
      if (this.isRead == true) {
        (function ($) {
          $(document).ready(function () {
            $('#eac-container-reading-autocpl > ul')
              .css('display', 'block')
              .fadeIn(300);
          });
        })(jQuery);
      }
    } else if (active.name == 'watching') {
      this.isWatch = !this.isWatch;
      if (this.isWatch == true) {
        (function ($) {
          $(document).ready(function () {
            $('#eac-container-watching-autocpl > ul')
              .css('display', 'block')
              .fadeIn(300);
          });
        })(jQuery);
      }
    } else if (active.name == 'travelling') {
      this.isTravel = !this.isTravel;
      if (this.isWatch == true) {
        (function ($) {
          $(document).ready(function () {
            $('#eac-container-travelling-autocpl > ul')
              .css('display', 'block')
              .fadeIn(300);
          });
        })(jQuery);
      }
    }
  }
  selectValueActive(value) {
    this.postFeeling = {};
    let obj;
    obj = {
      img: value.pic,
      text: '',
      content: value.name,
    };
    if (this.isMood == true) {
      obj.id = 1;
      this.isMood = false;
    } else if (this.isDrink == true) {
      obj.id = 2;
      obj.text = 'is drinking';
      this.isDrink = false;
    } else if (this.isEat == true) {
      obj.id = 3;
      obj.text = 'is eating';
      this.isEat = false;
    } else if (this.isRead == true) {
      obj.id = 4;
      obj.text = 'is reading';
      this.isRead = false;
    } else if (this.isWatch == true) {
      obj.id = 5;
      obj.text = 'is watching';
      this.isWatch = false;
    } else if (this.isTravel == true) {
      obj.id = 6;
      obj.text = 'Travels to';
      this.isTravel = false;
    }
    this.postFeeling = Object.assign(obj);
  }
  openRepeatActive(value) {
    if (value.id == 1) {
      this.isMood = !this.isMood;
    } else if (value.id == 2) {
      this.isDrink = !this.isDrink;
    } else if (value.id == 3) {
      this.isEat = !this.isEat;
    } else if (value.id == 4) {
      this.isRead = !this.isRead;
    } else if (value.id == 5) {
      this.isWatch = !this.isWatch;
    } else if (value.id == 6) {
      this.isTravel = !this.isTravel;
    }
  }
  isHidden;
  closeActive() {
    this.isHidden = false;
    this.isActive = false;
  }
  postFeed;
  postStory;
  changePost(valueType) {
    if (valueType == true) {
      this.postType.push({ type: 0, valueName: 'post', display: 0 });
    } else {
      let indexCheck = this.postType.findIndex((item) => item.type === 0);
      this.postType.splice(indexCheck, 1);
    }
  }
  changeStory(valueType) {
    if (valueType == true) {
      this.postType.push({ type: 1, valueName: 'story', display: 0 });
    } else {
      let indexCheck = this.postType.findIndex((item) => item.type === 1);
      this.postType.splice(indexCheck, 1);
    }
  }

  postType = [{ type: 0, valueName: 'post', display: 0 }];
  isSuccess = false;
  postDisplay;
  async createPost(postImg) {
    let postContent = (<HTMLInputElement>document.getElementById('publish'))
      .value;
    const regex = /\n/g;
    postContent = postContent.replace(regex, '<br/>');
    let postVideo = false;
    if (postImg && postImg.length > 0) {
      if (postImg.length == 1) {
        if (postImg[0].type == 'video/mp4') {
          postVideo = true;
        }
      }
    }
    let data = {
      postContent: postContent,
      postImg: postImg,
      postFeeling: this.postFeeling,
      postTags: this.dataTags,
      postLocation: this.postLocation,
      postDisplay: this.postDisplayPost.id,
      postType: 0,
      postShareLink: this.postShareLink,
      postVideo: postVideo
    };

    this.profileService.createPost(data).subscribe(async (result) => {
      if (result.status == 'success') {
        if (this.postImg) {
          for (let i of this.postImg) {
            let photo = {
              photoName: i.url,
              photoDescription: this.postContent,
              type: i.type
            };
            let result = await this.profileService.createPhoto(photo).toPromise();
          }
        }
        this.clearInputs();
        this.feedService.isSubmit.next({ isSubmit: true });
      }
    });
  }
  private regex = new RegExp(
    '^(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})'
  );
  isEnter;
  enterLink(postShareLink) {
    this.isEnter = true;
    this.getDataFromUrl(postShareLink);
  }
  linkPreview;
  getDataFromUrl(url) {
    if (url.length > 0) {
      if (url.indexOf('https://') > -1) {
        url = url.slice(8, url.length);
      }
      this.pagesService.getDataFromUrl({ url: url }).subscribe((result) => {
        if (result.status == 'success') {
          this.isEnter = false;
          this.linkPreview = result.data;
          this.postShareLink = result.data;
          (function ($) {
            $(document).ready(function () {
              $('#link-summary').css('display', 'block').fadeIn(1500, 'swing');
            });
          })(jQuery);
        }
      });
    }
  }
  selectPlace(place) {
    (function ($) {
      $('#eac-container-location-autocpl').addClass('is-hidden').fadeIn(300);
    })(jQuery);
  }
  openActive() {
    this.isHidden = !this.isHidden;
    if (this.isHidden == true) {
      this.isActive = !this.isActive;
      if (this.isActive == true) {
        this.initAnimation();
      }
    }
  }

  closeMoods() {
    this.postFeeling = undefined;
    (function ($) {
      $(document).ready(function () {
        if (document.getElementsByClassName('control has-margin is-hidden')) {
          $('#activities-autocpl-wrapper').removeClass('is-hidden');
        }
      });
    })(jQuery);
    this.isMood = false;
    this.isEnter = false;
    this.isRead = false;
    this.isWatch = false;
    this.isTravel = false;
    this.isEat = false;
    this.isActive = !this.isActive;
    this.initAnimation();
  }
  initAnimation() {
    (function ($) {
      $(document).ready(function () {
        $('#eac-container-activities-autocpl > ul')
          .css('display', 'block')
          .fadeIn(500);
      });
    })(jQuery);
  }
  //#endregion

  //#region delete Imgage
  deleteImg(index) {
    this.postImg.splice(index, 1);
  }

  //#endregion

  //#region select display of post/story
  postDisplayPost = {
    id: 0,
    icon: 'globe',
    mediaTitle: 'Public',
    mediaContent: 'Anyone can see this publication.',
  };
  selectDisplayPost(valueDisplay) {
    this.postDisplayPost = valueDisplay;
    for (let postType of this.postType) {
      if (postType.type == 0) {
        postType.display = valueDisplay.id;
      }
    }
    (function ($) {
      $(document).ready(function () {
        $('.dropdown-trigger').removeClass('is-active');
      });
    })(jQuery);
  }
  postDisplayStory = {
    id: 0,
    icon: 'globe',
    mediaTitle: 'Public',
    mediaContent: 'Anyone can see this publication.',
  };
  selectDisplayStory(valueDisplay) {
    this.postDisplayStory = valueDisplay;
    for (let postType of this.postType) {
      if (postType.type == 1) {
        postType.display = valueDisplay.id;
      }
    }
    (function ($) {
      $(document).ready(function () {
        $('.dropdown-trigger').removeClass('is-active');
      });
    })(jQuery);
  }
  //#endregion

  initChangeTexts() {
    (function ($) {
      $(document).on('paste', '[textarea]', function (e) {
        var content;
        e.preventDefault();

        if (window.clipboardData) {
          content = window.clipboardData.getData('Text');
          if (window.getSelection) {
            var selObj = window.getSelection();
            var selRange = selObj.getRangeAt(0);
            selRange.deleteContents();
            selRange.insertNode(document.createTextNode(content));
          }
        } else if (e.originalEvent.clipboardData) {
          content = (e.originalEvent || e).clipboardData.getData('text/plain');
          document.execCommand('insertText', false, content);
        }
      });
    })(jQuery);
  }

  filesDropped(files): void {
    for (let file of files) {
      file.model = ""
      this.postImg.push(file);
    }
  }
  filesPasted(files): void {
    for (let file of files) {
      file.model = ""
      this.postImg.push(file);
    }
  }
  filesInput(files): void {
    for (let file of files) {
      file.model = ""
      this.postImg.push(file);
    }
  }

  appendData() {
    this.isSuccess = true;
    let formData = new FormData();
    for (let file of this.postImg) {
      formData.append('file', file.formData);
    }
    this.profileService.uploadCloud(formData).subscribe((data) => {
      if (data) {
        let arrImg = data;
        const posts = this.initImg(arrImg);
        this.createPost(posts);
      }
    });
  }

  initImg(arrImage) {
    let caption = []
    for (let post of this.postImg) {
      caption.push(post.model)
    }
    this.postImg = [];
    for (const i in arrImage) {
      const imgN = arrImage[i];
      if (imgN.mimetype.indexOf('image/') > -1) {
        this.postImg.push({
          url: imgN.location,
          filename: imgN.originalname,
          type: imgN.mimetype,
          _id: imgN._id,
          caption: caption[i]
        });
      }
      if (imgN.mimetype.indexOf('video/') > -1) {
        this.postImg.push({
          url: "https://" + imgN.location,
          filename: imgN.originalname,
          type: imgN.mimetype,
          _id: imgN._id,
          caption: ""
        });
      }
    }
    return this.postImg;
  }

  changeUrlVideo(url) {
    let host = "https://" + environment.environment.s3SpacePrefix + "."
    let splt = url.replace(environment.environment.s3SpacePrefix + '/', '')
    let result = host + splt
    return result;
  }
}
