<div class="card">
	<div class="card-heading is-bordered">
		<h4>Stories</h4>
		<div class="dropdown is-spaced is-neutral is-right dropdown-trigger">
			<div>
				<div class="button">
					<i-feather name="more-vertical"></i-feather>
				</div>
			</div>
			<div class="dropdown-menu" role="menu">
				<div class="dropdown-content">
					<a href="#" class="dropdown-item">
						<div class="media">
							<i-feather name="tv"></i-feather>
							<div class="media-content">
								<h3>Browse stories</h3>
								<small>View all recent stories.</small>
							</div>
						</div>
					</a>
					<a class="dropdown-item">
						<div class="media">
							<i-feather name="settings"></i-feather>
							<div class="media-content">
								<h3>Settings</h3>
								<small>Access widget settings.</small>
							</div>
						</div>
					</a>
					<hr class="dropdown-divider" />
					<a href="#" class="dropdown-item">
						<div class="media">
							<i-feather name="trash-2"></i-feather>
							<div class="media-content">
								<h3>Remove</h3>
								<small>Removes this widget from your feed.</small>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
	<div class="card-body no-padding">
		<!-- Story block -->
		<div class="story-block">
			<a id="add-story-button" class="add-story" (click)="goToStories()">
				<i-feather name="plus"></i-feather>
			</a>
			<div class="story-meta">
				<span>Add a new Story</span>
				<span>Share an image, a video or some text</span>
			</div>
		</div>
		<ng-container *ngFor="let story of arrStories; let indStories = index">
			<!-- Story block -->
			<div class="story-block" (click)="goToStories()">
				<div class="img-wrapper">
					<img
						[src]="story?.postUserId?.avatar"
						data-demo-src="assets/img/avatars/dan.jpg"
						alt
					/>
				</div>
				<div class="story-meta">
					<span
						>{{ story?.postUserId?.firstname
						}}{{ story?.postUserId?.lastname }}</span
					>
					<span>{{ story?.createdAt | dateAgo }}</span>
				</div>
			</div>
		</ng-container>
	</div>
</div>
