<app-nav-bar></app-nav-bar>

<div id="main-feed" class="container">
	<div id="activity-feed" class="view-wrap true-dom">
		<div class="columns">
			<div class="column is-3 is-hidden-mobile">
				<app-feed-menu></app-feed-menu>
			</div>
			<div class="column is-9">
				<app-questions-nav [isActive]="idActive"></app-questions-nav>
				<app-question-home *ngIf="idActive == 0"></app-question-home>
				<app-question-categories
					*ngIf="idActive == 1"
				></app-question-categories>
				<app-questions-stats *ngIf="idActive == 2"></app-questions-stats>
				<app-questions-settings *ngIf="idActive == 3"></app-questions-settings>
				<app-questions-single *ngIf="idActive == 4"> </app-questions-single>
			</div>
		</div>
	</div>
</div>
