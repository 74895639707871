import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import iziToast from 'izitoast';
import { GroupService } from 'src/app/services/group/group.service';
import * as environment from '../../../../../../environments/environment';

@Component({
  selector: 'app-invite-member',
  templateUrl: './invite-member.component.html',
  styleUrls: ['./invite-member.component.scss'],
})
export class InviteMemberComponent implements OnInit {
  @Input() set dataGroup(dataGroup: any) {
    this.dataGroups = dataGroup;
    this.getListUser(this.dataGroups._id);
  }
  isModals;
  @Input() set isModal(isModal: boolean) {
    this.isModals = isModal;
    if (this.isModals == true) {
      this.getListUser(this.dataGroups._id);
    }
  }

  @Output() isCloseModal = new EventEmitter<any>();

  dataGroups;
  environment;
  constructor(private groupService: GroupService) {
    this.environment = environment.environment.apiUrl;
  }

  ngOnInit(): void {
    this.isSearch = false;
    this.isSuggested = true;
  }

  //#region Function get list user
  isSuggested;
  arrUserSuggested = [];
  getListUser(groupId) {
    this.arrUserSuggested = [];
    this.arrSelectedUser = [];
    this.groupService.getUsers({ groupId: groupId }).subscribe((result) => {
      if (result.status == 'success') {
        this.arrUserSuggested = result.data;
      }
    });
  }
  //#endregion

  //#region Function search user
  isSearch;
  arrUserResult = [];
  searchUser(username) {
    this.groupService.searchUser(username).subscribe((result) => {
      if (result.status == 'success') {
        this.arrUserResult = result.data;
      }
    });
  }
  //#endregion

  //#region Function send invite
  arrSelectedUser = [];
  selectUser(value) {
    if (this.arrSelectedUser.length > 0) {
      for (let [index, user] of this.arrSelectedUser.entries()) {
        if (value._id === user._id) {
          this.arrSelectedUser.splice(index, 1);
          return;
        }
      }
    }
    this.arrSelectedUser.push(value);
  }
  sendInvite() {
    if (this.arrSelectedUser.length > 0) {
      let lenthArr = this.arrSelectedUser.length;
      for (let i = 0; i < lenthArr; i++) {
        this.createMember(this.arrSelectedUser[i]._id);
        if (i == lenthArr - 1) {
          this.isCloseModal.emit({ isClose: false });
        }
      }
    }
  }
  createMember(userId) {
    let reqMember = {
      groupId: this.dataGroups._id,
      memberId: userId,
      isJoin: true,
    };
    this.groupService.createMember(reqMember).subscribe((result) => {
      iziToast.success({
        class: 'toast',
        message: 'Invite user join group success!',
        messageColor: '#ffffff',
        iconColor: '#ffffff',
        backgroundColor: '#00cc00',
        progressBarColor: '#fafafa',
        position: 'topRight',
        transitionIn: 'fadeInDown',
        close: false,
        zindex: 99999,
      });
    });
  }
  //#endregion
}
