<h1 mat-dialog-title>Thông báo</h1>
<hr />
<div mat-dialog-content class="description-dialog">
  <p>
    Bạn thực sự muốn rời group <span>{{ data.groupName }}</span>
  </p>
  <hr />
</div>
<div mat-dialog-actions>
  <div class="button-wrap">
    <div class="btn-no" mat-button (click)="onNoClick()">Không</div>
    <div class="btn-yes" mat-button (click)="onYesClick()">Có</div>
  </div>
</div>
