<ng-container *ngFor="let post of dataPosts; let indexPostUser = index">
	<div class="profile-post" *ngIf="post?.postType == 0 || post?.postType == 2">
		<div class="time is-hidden-mobile">
			<div class="img-container">
				<img
					[src]="post?.postUserId?.avatar"
					[attr.data-demo-src]="post?.postUserId?.avatar"
					alt
				/>
			</div>
		</div>
		<div class="card is-post">
			<div class="content-wrap">
				<div class="card-heading">
					<div class="user-block">
						<div class="image">
							<img
								[src]="post?.postUserId?.avatar"
								[attr.data-demo-src]="post?.postUserId?.avatar"
								[attr.data-user-popover]="post?.postUserId?._id"
								alt
							/>
						</div>
						<div class="user-info">
							<a [href]="'profile/' + post?.postUserId?._id">
								{{ post?.postUserId?.firstname }}
								{{ post?.postUserId?.lastname }}
							</a>
							<span class="time">
								{{ post?.createdAt | dateAgo }}
							</span>
						</div>
					</div>

					<app-feed-post-dropdown
						class="dropdown is-spaced is-right is-neutral dropdown-trigger"
						(edit)="editPost(post)"
					></app-feed-post-dropdown>
				</div>
				<div class="card-body">
					<div class="image-wrap img-loading-wrap" *ngIf="!isLoading">
						<div class="image-loader loading-wrap is-active">
							<div class="loader loader-wrap is-loading"></div>
						</div>
					</div>
					<div
						(keydown.enter)="updatePost(post)"
						id="{{ post._id }}"
						class="post-text"
						[attr.contenteditable]="post.contenteditable"
					>
						<p [innerHTML]="post?.postContent | sanitizeHtmlPipe"></p>
					</div>
					<div class="post-share" *ngIf="post?.postShareContent">
						<div class="content-wrap">
							<div class="card-heading">
								<div class="user-block">
									<div class="image">
										<img
											*ngIf="
												post?.postShareContent?.postUserId?.avatar &&
												!post?.postShareContent?.postPageId?.pageAvatar
											"
											[src]="post?.postShareContent?.postUserId?.avatar"
											[attr.data-demo-src]="
												post?.postShareContent?.postUserId?.avatar
											"
											[attr.data-user-popover]="
												post?.postShareContent?.postUserId?._id
											"
										/>
										<img
											*ngIf="post?.postShareContent?.postPageId?.pageAvatar"
											[src]="post?.postShareContent?.postPageId?.pageAvatar"
											[attr.data-demo-src]="
												post?.postShareContent?.postPageId?.pageAvatar
											"
											[attr.data-user-popover]="
												post?.postShareContent?.postPageId?._id
											"
										/>
									</div>
									<div class="user-info">
										<div class="user-info-wrapper" id="user-info">
											<strong>
												<span class="page-name">
													<a
														*ngIf="
															post?.postShareContent?.postUserId &&
															!post?.postShareContent?.postPageId
														"
														[href]="'profile/' + post?.postUserId?._id"
													>
														{{ post?.postShareContent?.postUserId?.firstname }}
														{{ post?.postShareContent?.postUserId?.lastname }}
													</a>
													<a
														*ngIf="post?.postShareContent?.postPageId"
														[href]="
															'pages/' + post?.postShareContent?.postPageId?._id
														"
													>
														{{ post?.postShareContent?.postPageId?.pageName }}
													</a>
												</span>
											</strong>
											<!-- <i-feather name="play"></i-feather> -->
											<img
												*ngIf="post?.postShareContent?.postGroupId"
												src="../../../../assets/img/icons/feed/icon-play.png"
												class="icon-play"
												alt=""
											/>
											<strong>
												<span class="gr-name">
													<a
														[href]="
															'groups/' +
															post?.postShareContent?.postGroupId?._id
														"
													>
														<span
															>{{
																post?.postShareContent?.postGroupId?.groupName
															}}
														</span></a
													></span
												>
											</strong>
										</div>
										<span class="time">
											{{ post?.postShareContent?.createdAt | dateAgo }}
										</span>
									</div>
								</div>
							</div>
							<div class="card-body">
								<div class="post-text">
									<p
										[innerHTML]="
											post?.postShareContent?.postContent | sanitizeHtmlPipe
										"
									></p>
								</div>
								<div class="post-activities">
									<div
										class="post-activities-wrapper post-feeling"
										*ngIf="post?.postFeeling"
									>
										{{ post?.postShareContent?.postFeeling?.text }}
										{{ post?.postShareContent?.postFeeling?.content }}
										<strong>
											<span>&nbsp;</span>
											<span class="img-activities"
												><img [src]="post?.postFeeling?.img" alt="" /></span
										></strong>
									</div>
									<div
										class="post-activities-wrapper post-tags"
										*ngIf="post?.postShareContent?.postTags?.length > 0"
									>
										<small>— with</small>
										<ng-container
											*ngIf="post?.postShareContent?.postTags?.length > 2"
										>
											<a
												*ngFor="
													let friend of post?.postShareContent?.postTags
														| slice: 0:1
												"
												class="is-inverted"
												[href]="'profile/' + friend?._id"
												[ngStyle]="{margin: '0 3px'}"
											>
												<div>
													<span
														>{{ friend?.firstname }}
														{{ friend?.lastname }}</span
													>
												</div>
											</a>
										</ng-container>
										<ng-container
											*ngIf="post?.postShareContent?.postTags?.length <= 2"
										>
											<a
												*ngFor="
													let friend of post?.postShareContent?.postTags
														| slice: 0:2
												"
												class="is-inverted"
												[href]="'profile/' + friend?._id"
												[ngStyle]="{margin: '0 3px'}"
											>
												<div>
													<span
														>{{ friend?.firstname }}
														{{ friend?.lastname }}</span
													>
												</div>
											</a>
										</ng-container>
										<ng-container
											*ngIf="post?.postShareContent?.postTags?.length > 2"
										>
											and {{ post?.postShareContent?.postTags?.length }} people
											orthers</ng-container
										>
									</div>
								</div>

								<div
									class="post-image"
									*ngIf="post?.postShareContent?.postImg?.length > 0"
								>
									<div
										id="masonry-grid"
										class="masonry-grid image-wrap"
										[ngClass]="
											post?.postShareContent?.postImg?.length > 4 &&
											post?.postShareContent?.postImg[0]?.width >
												post?.postShareContent?.postImg[0]?.height
												? 'img-wrap-inset5-straight'
												: post?.postShareContent?.postImg?.length > 4 &&
												  post?.postShareContent?.postImg[0]?.width <=
														post?.postShareContent?.postImg[0]?.height
												? 'img-wrap-inset5-horizontal'
												: post?.postShareContent?.postImg?.length == 3
												? 'img-wrap-inset3'
												: post?.postShareContent?.postImg?.length == 2
												? 'img-wrap-inset2'
												: post?.postShareContent?.postImg?.length == 1
												? 'img-wrap-inset1'
												: ''
										"
									>
										<!-- CSS masonry wrap -->
										<ng-container
											*ngFor="
												let imgShare of post?.postShareContent?.postImg
													| slice: 0:5;
												let index = index
											"
										>
											<div class="masonry-grid-wrapper postion-img">
												<a
													[attr.data-thumb]="imgShare.url"
													[attr.data-demo-src]="imgShare.url"
													(click)="openPostFancyBox(post); getImage(index)"
													*ngIf="imgShare.type !== 'video/mp4'"
												>
													<img
														class="wrap-image"
														[src]="imgShare.url"
														[attr.data-src]="imgShare.url"
														[attr.data-demo-src]="imgShare.url"
														alt
													/>
													<div
														*ngIf="
															index == 4 &&
															post?.postShareContent?.postImg?.length > 5
														"
														class="count-img-wrap title is-3"
													>
														<span
															>+{{
																post?.postShareContent?.postImg?.length - 5
															}}</span
														>
													</div>
												</a>
												<a *ngIf="imgShare.type == 'video/mp4'">
													<div class="wrap-video">
														<video
															[src]="imgShare?.url"
															controls
															controlsList="nodownload"
														></video>
													</div>
													<div
														*ngIf="index == 4 && post?.imgShare?.length > 5"
														class="count-img-wrap title is-3"
													>
														<span>+{{ post?.postImg?.length - 5 }}</span>
													</div>
												</a>
											</div>
										</ng-container>
									</div>
								</div>
								<div
									class="post-link"
									*ngIf="
										post?.postShareContent?.postShareLink != undefined &&
										post?.postShareContent?.postShareLink != ''
									"
								>
									<!-- link image -->
									<div class="link-images">
										<img
											[src]="post?.postShareContent?.postShareLink?.img"
											[attr.data-demo-src]="
												post?.postShareContent?.postShareLink?.img
											"
										/>
									</div>
									<!-- Link content -->
									<div class="link-content">
										<h4>
											<a [ngStyle]="{'text-transform': 'capitalize'}">{{
												post?.postShareContent?.postShareLink?.title
											}}</a>
										</h4>
										<p>
											{{ post?.postShareContent?.postShareLink?.description }}
										</p>
										<small>{{
											post?.postShareContent?.postShareLink?.domain
										}}</small>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="post-activities">
						<div
							class="post-activities-wrapper post-feeling"
							*ngIf="post?.postFeeling"
						>
							{{ post?.postFeeling?.text }}
							{{ post?.postFeeling?.content }}
							<strong>
								<span class="img-activities"
									><img [src]="post?.postFeeling?.img" alt="" /></span
							></strong>
						</div>
						<div
							class="post-activities-wrapper post-tags"
							*ngIf="post?.postTags?.length > 0"
						>
							<small>— with</small>
							<ng-container *ngIf="post?.postTags?.length > 2">
								<a
									*ngFor="let friend of post?.postTags | slice: 0:1"
									class="is-inverted"
									[href]="'profile/' + friend?._id"
									[ngStyle]="{margin: '0 3px'}"
								>
									<div>
										<span>{{ friend?.firstname }} {{ friend?.lastname }}</span>
									</div>
								</a>
							</ng-container>
							<ng-container *ngIf="post?.postTags?.length <= 2">
								<a
									*ngFor="let friend of post?.postTags | slice: 0:2"
									class="is-inverted"
									[href]="'profile/' + friend?._id"
									[ngStyle]="{margin: '0 3px'}"
								>
									<div>
										<span>{{ friend?.firstname }} {{ friend?.lastname }}</span>
									</div>
								</a>
							</ng-container>
							<ng-container *ngIf="post?.postTags?.length > 2">
								and {{ post?.postTags?.length }} people orthers</ng-container
							>
						</div>
					</div>
					<div class="post-image" *ngIf="post?.postImg?.length > 0">
						<div
							id="masonry-grid"
							class="masonry-grid image-wrap"
							[ngClass]="
								post?.postImg?.length > 4 &&
								post?.postImg[0]?.width > post?.postImg[0]?.height
									? 'img-wrap-inset5-straight'
									: post?.postImg?.length > 4 &&
									  post?.postImg[0]?.width <= post?.postImg[0]?.height
									? 'img-wrap-inset5-horizontal'
									: post?.postImg?.length == 3
									? 'img-wrap-inset3'
									: post?.postImg?.length == 2
									? 'img-wrap-inset2'
									: post?.postImg?.length == 1
									? 'img-wrap-inset1'
									: ''
							"
						>
							<!-- CSS masonry wrap -->
							<ng-container
								*ngFor="
									let img of post?.postImg | slice: 0:5;
									let index = index
								"
							>
								<div class="masonry-grid-wrapper postion-img">
									<a
										[attr.data-thumb]="img?.url"
										[attr.data-demo-href]="img?.url"
										(click)="openPostFancyBox(post); getImage(index)"
										*ngIf="img.type !== 'video/mp4'"
									>
										<img
											class="wrap-image"
											[src]="img?.url"
											[attr.data-demo-src]="img?.url"
											alt=""
										/>
										<div
											*ngIf="index == 4 && post?.postImg?.length > 5"
											class="count-img-wrap title is-3"
										>
											<span>+{{ post?.postImg?.length - 5 }}</span>
										</div>
									</a>
									<a *ngIf="img.type == 'video/mp4'">
										<div class="wrap-video">
											<video
												[src]="img?.url"
												controls
												controlsList="nodownload"
											></video>
										</div>
										<div
											*ngIf="index == 4 && post?.postImg?.length > 5"
											class="count-img-wrap title is-3"
										>
											<span>+{{ post?.postImg?.length - 5 }}</span>
										</div>
									</a>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="post-actions">
						<div class="fab-wrapper is-comment">
							<a class="small-fab" (click)="openComment(indexPostUser)">
								<i-feather name="message-circle"></i-feather>
							</a>
						</div>
						<div class="fab-wrapper is-share" (click)="sharePost(post)">
							<a
								class="small-fab share-fab modal-trigger"
								data-modal="share-modal"
							>
								<i-feather name="link-2"></i-feather>
							</a>
						</div>
						<div
							class="like-wrapper"
							(click)="
								post?.isLiked == false
									? deleteLikePostUser(post, indexPostUser)
									: createLikePost(post, indexPostUser)
							"
						>
							<a
								[ngClass]="post?.isLiked == true ? 'is-active' : ''"
								class="like-button"
								(click)="post.isLiked = !post.isLiked"
							>
								<i class="mdi mdi-heart not-liked bouncy"></i>
								<i class="mdi mdi-heart is-liked bouncy"></i>
								<span class="like-overlay"></span>
							</a>
						</div>
					</div>
				</div>

				<div class="card-footer">
					<div
						class="likers-group"
						*ngIf="post?.arrLike && post?.arrLike.length > 0"
					>
						<img
							*ngFor="let img of post?.arrLike"
							[src]="img?.userId?.avatar"
							[attr.data-demo-src]="img?.userId?.avatar"
							[attr.data-user-popover]="img?.userId?._id"
							alt
						/>
					</div>
					<div class="likers-text">
						<p *ngIf="post?.arrLike">
							{{ post?.arrLike?.length }} more liked this
						</p>
					</div>
					<div class="social-count">
						<div class="likes-count">
							<i-feather name="heart"></i-feather>
							<span>{{ post?.arrLike?.length }}</span>
						</div>
						<div class="shares-count">
							<i-feather name="link-2"></i-feather>
							<span> {{ post?.countShare }}</span>
						</div>
						<div class="comments-count">
							<i-feather name="message-circle"></i-feather>
							<span *ngIf="post?.arrComment">{{ post?.countComment }}</span>
						</div>
					</div>
				</div>
			</div>
			<div
				class="comments-wrap is-hidden"
				[attr.id]="indexPostUser + 'comments-wrap'"
			>
				<div class="comments-heading">
					<h4>
						Comments
						<small *ngIf="post?.arrComment">({{ post?.countComment }})</small>
					</h4>
					<div class="close-comment" (click)="closeComment(indexPostUser)">
						<i-feather name="x"></i-feather>
					</div>
				</div>
				<div
					class="comments-heading count-cmt"
					*ngIf="
						post?.arrComment?.length > 0 &&
						post?.filterView?.isActive == true &&
						post?.countComment >
							post?.filterComment.limit + post?.filterComment.skip
					"
				>
					<span
						*ngIf="post?.filterView?.isViewMore == true"
						(click)="viewMoreCommentUser(indexPostUser)"
						>View more Comments</span
					>
				</div>
				<div class="comments-body has-slimscroll">
					<div
						*ngIf="post?.arrComment && post?.arrComment?.length == 0"
						class="comments-placeholder"
					>
						<img src="assets/img/icons/feed/bubble.svg" alt />
						<h3>Nothing in here yet</h3>
						<p>Be the first to post a comment.</p>
					</div>
					<div
						class="media is-comment"
						*ngFor="
							let comment of post?.arrComment;
							let indexCommentUser = index
						"
					>
						<div class="media-left">
							<div class="image">
								<img
									[src]="comment?.userId?.avatar ? comment?.userId?.avatar : ''"
									[attr.data-demo-src]="comment?.userId?.avatar"
									[attr.data-user-popover]="comment?.userId?._id"
								/>
							</div>
						</div>
						<div class="media-content">
							<a href="#">
								{{ comment?.userId?.firstname }}
								{{ comment?.userId?.lastname }}
							</a>
							<span class="time">{{ comment?.createdAt | dateAgo }}</span>
							<div
								[innerHTML]="comment?.commentContent | sanitizeHtmlPipe"
								[attr.contenteditable]="comment?.contenteditable"
								(keydown.enter)="updateComment(comment)"
								id="{{ comment._id }}"
								class="content-text"
							></div>

							<div class="media-img">
								<img
									*ngIf="comment?.commentImg"
									[src]="comment?.commentImg ? comment?.commentImg : ''"
									alt=""
								/>
							</div>
							<div class="media-video">
								<video
									*ngIf="comment?.commentVideo"
									height="200"
									controls
									controlsList="nodownload"
								>
									<source [src]="comment?.commentVideo" type="video/mp4" />
								</video>
							</div>

							<div class="controls">
								<div
									[ngClass]="comment?.isLiked == true ? 'is-like' : ''"
									class="like-count"
									(click)="
										comment?.isLiked == true
											? deleteLikeCommentUser(
													comment,
													indexPostUser,
													indexCommentUser
											  )
											: createLikeCommentUser(
													post,
													comment,
													indexPostUser,
													indexCommentUser
											  )
									"
								>
									<i-feather name="thumbs-up"></i-feather>
									<span>{{ comment?.arrLike?.length }}</span>
								</div>
								<div
									class="reply"
									(click)="
										clickReplyUser(
											comment?.userId,
											comment,
											indexPostUser,
											indexCommentUser
										)
									"
								>
									<a>Reply</a>
								</div>
							</div>
							<div
								class="comments-heading count-cmt"
								*ngIf="
									comment?.filterView?.isActive == true &&
									comment?.filterView?.isViewMore == true
								"
								(click)="viewMoreReplies(comment)"
							>
								<span
									>View
									{{ comment?.countReply - comment?.arrReplies?.length }}
									Reply</span
								>
							</div>
							<ng-container
								*ngFor="
									let reply of comment?.arrReplies;
									let indexReplyUser = index
								"
							>
								<div class="media is-comment">
									<!-- User image -->
									<div class="media-left">
										<div class="image">
											<img
												[src]="reply?.userId?.avatar"
												[attr.data-demo-src]="reply?.userId?.avatar"
												[attr.data-user-popover]="reply?.userId?._id"
											/>
										</div>
									</div>
									<!-- Content -->
									<div class="media-content">
										<a
											class="item-media"
											[href]="'profile/' + reply?.userId?._id"
											>{{ reply?.userId?.firstname }}
											{{ reply?.userId?.lastname }}</a
										>
										<span class="time">{{ reply?.createdAt | dateAgo }}</span>
										<div
											id="{{ reply._id }}"
											class="cmt-tags content-text"
											[innerHTML]="reply?.replyContent | sanitizeHtmlPipe"
											[attr.contenteditable]="reply.contenteditable"
											(keydown.enter)="updateReply(reply)"
										></div>

										<div class="media-img">
											<img
												*ngIf="reply?.replyImg"
												[src]="reply?.replyImg ? reply?.replyImg : ''"
												alt=""
											/>
										</div>

										<!-- Comment actions -->
										<div class="controls">
											<div
												[ngClass]="reply?.isLikeReply == true ? 'is-like' : ''"
												class="like-count"
												(click)="
													reply?.isLikeReply == true
														? unLikeReply(reply)
														: createLikeReply(post, reply, comment)
												"
											>
												<i-feather name="thumbs-up"></i-feather>
												<span>{{ reply?.arrLikeReply?.length }}</span>
											</div>
											<div
												class="reply"
												(click)="
													clickReplyUser(
														reply?.userId,
														comment,
														indexPostUser,
														indexCommentUser
													)
												"
											>
												<a>Reply</a>
											</div>
										</div>
									</div>
									<!-- Right side dropdown -->
									<div class="media-right">
										<div
											class="
												dropdown
												is-spaced is-right is-neutral
												dropdown-trigger
											"
											[ngClass]="{'is-active': reply.showEditReply}"
										>
											<div>
												<div
													class="button"
													(click)="reply.showEditReply = !reply.showEditReply"
												>
													<i-feather name="more-vertical"></i-feather>
												</div>
											</div>
											<div class="dropdown-menu" role="menu">
												<div class="dropdown-content">
													<a class="dropdown-item" *ngIf="reply?.isUser">
														<div class="media">
															<i-feather name="x"></i-feather>
															<div class="media-content">
																<h3>Hide</h3>
																<small>Hide this reply.</small>
															</div>
														</div>
													</a>
													<div
														class="dropdown-divider"
														*ngIf="reply?.isUser"
													></div>
													<a class="dropdown-item">
														<div class="media">
															<i-feather name="flag"></i-feather>
															<div class="media-content">
																<h3>Report</h3>
																<small>Report this reply.</small>
															</div>
														</div>
													</a>
													<a class="dropdown-item" (click)="editReply(reply)">
														<div class="media">
															<i-feather name="edit"></i-feather>
															<div class="media-content">
																<h3>Edit</h3>
																<small>Edit this reply.</small>
															</div>
														</div>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
						<div class="media-right">
							<div
								class="dropdown is-spaced is-right is-neutral dropdown-trigger"
								[ngClass]="{'is-active': comment.showEditComment}"
							>
								<div>
									<div class="button">
										<i-feather
											name="more-vertical"
											(click)="
												comment.showEditComment = !comment.showEditComment
											"
										></i-feather>
									</div>
								</div>
								<div class="dropdown-menu" role="menu">
									<div class="dropdown-content">
										<a class="dropdown-item">
											<div class="media">
												<i-feather name="x"></i-feather>
												<div class="media-content">
													<h3>Hide</h3>
													<small>Hide this comment.</small>
												</div>
											</div>
										</a>
										<div class="dropdown-divider"></div>
										<a class="dropdown-item">
											<div class="media">
												<i-feather name="flag"></i-feather>
												<div class="media-content">
													<h3>Report</h3>
													<small>Report this comment.</small>
												</div>
											</div>
										</a>
										<a class="dropdown-item" (click)="editComment(comment)">
											<div class="media">
												<i-feather name="edit"></i-feather>
												<div class="media-content">
													<h3>Edit</h3>
													<small>Edit this comment.</small>
												</div>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="replies-wrap" *ngIf="post?.arrReplyTemporary?.length > 0">
					<div class="content-replies-wrap">
						<span>replies</span
						><span *ngFor="let replies of post?.arrReplyTemporary"
							>{{ replies?.firstname }}{{ replies?.lastname }}</span
						>
					</div>

					<div class="clear-replies-wrap" (click)="clearReply(post)">
						<i-feather name="x"></i-feather>
					</div>
				</div>
				<div class="card-footer">
					<div class="media post-comment has-emojis">
						<div class="media-content">
							<div class="field">
								<div
									placeholder="Write a comment..."
									[attr.id]="indexPostUser + 'tag-friends'"
									class="tag-fr textarea"
									contenteditable="true"
									(input)="model = $event.target.textContent"
									(keyup)="searchUser(model)"
								></div>
								<div
									class="select-custome"
									*ngIf="arrUser?.length > 0 && model?.length > 0"
								>
									<div class="s-user" id="s-user-autocpl">
										<ul>
											<li
												class="is-selected"
												*ngFor="
													let user of arrUser | slice: 0:5;
													let indexs = index
												"
												(click)="tagUser(user, indexPostUser)"
											>
												<div class="eac-item">
													<div class="template-wrapper">
														<div class="avatar-wrapper">
															<img
																class="autocpl-avatar"
																*ngIf="user.avatar"
																[src]="user?.avatar"
															/>
														</div>
														<div class="entry-text">
															{{ user?.firstname }} {{ user?.lastname }}
														</div>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div class="actions">
								<div class="image is-32x32">
									<img
										class="is-rounded"
										[src]="user?.avatar"
										[attr.data-demo-src]="user?.avatar"
										[attr.data-user-popover]="user?._id"
										alt
									/>
								</div>
								<div class="toolbar">
									<div class="action is-auto">
										<i-feather name="at-sign"></i-feather>
									</div>
									<div class="action is-emoji">
										<i-feather name="smile"></i-feather>
									</div>
									<div class="action is-upload">
										<i-feather name="camera"></i-feather>
										<input
											type="file"
											accept="video/mp4,image/png,image/jpeg"
											(change)="detectFileUser($event, indexPostUser)"
										/>
									</div>
									<a
										class="button is-solid primary-button raised"
										(click)="
											post?.arrReplyTemporary?.length > 0
												? createReplyUser(post, indexPostUser)
												: createCommentUser(post, indexPostUser)
										"
										>Post Comment</a
									>
								</div>
							</div>
							<div [attr.id]="indexPostUser + 'cmt-image-user'" class="cmt-img">
								<div class="cmt-img-wrapper">
									<img
										[src]="post?.contentImg"
										*ngIf="formatUser === 'image' && post?.contentImg"
										id="cmt-img"
										alt=""
									/>
								</div>
								<div class="cmt-video">
									<video
										[src]="post?.contentVideo"
										*ngIf="formatUser === 'video' && post?.contentVideo"
										height="200"
										controls
										controlsList="nodownload"
									></video>
								</div>
								<div class="close-img" (click)="closeImg(indexPostUser)">
									<i-feather name="x"></i-feather>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<app-share-modal [dataUser]="infousers"></app-share-modal>
<app-page-photo-lightbox-container
	*ngIf="dataPost"
	[dataPost]="dataPost"
	[isActivePhoto]="isActivePhoto"
	[indexImg]="indexImg"
	[InfoUser]="user"
	(closeModal)="closeModal($event)"
></app-page-photo-lightbox-container>
