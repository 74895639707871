<div class="dropdown is-spaced is-right is-accent dropdown-trigger timeline-mobile-dropdown is-hidden-desktop">
    <div>
        <div class="button">
            <i-feather name="more-vertical"></i-feather>
        </div>
    </div>
    <div
        class="dropdown-menu"
        role="menu"
    >
        <div class="dropdown-content">
            <a
                href="/profile-main.html"
                class="dropdown-item"
            >
                <div class="media">
                    <i-feather name="activity"></i-feather>
                    <div class="media-content">
                        <h3>Timeline</h3>
                        <small>Open Timeline.</small>
                    </div>
                </div>
            </a>
            <a
                href="/profile-about.html"
                class="dropdown-item"
            >
                <div class="media">
                    <i-feather name="align-right"></i-feather>
                    <div class="media-content">
                        <h3>About</h3>
                        <small>See about info.</small>
                    </div>
                </div>
            </a>
            <a
                href="/profile-friends.html"
                class="dropdown-item"
            >
                <div class="media">
                    <i-feather name="heart"></i-feather>
                    <div class="media-content">
                        <h3>Friends</h3>
                        <small>See friends.</small>
                    </div>
                </div>
            </a>
            <a
                href="/profile-photos.html"
                class="dropdown-item"
            >
                <div class="media">
                    <i-feather name="image"></i-feather>
                    <div class="media-content">
                        <h3>Photos</h3>
                        <small>See all photos.</small>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
