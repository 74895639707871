import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { MessageService } from "src/app/services/message.service";
import iziToast from 'izitoast'
import { WebSocketService } from "src/app/services/web-socket.service";
import { ProfileService } from "src/app/services/profile.service";
@Component({
	selector: "app-person-conversation",
	templateUrl: "./person-conversation.component.html",
	styleUrls: ["./person-conversation.component.scss"],
})
export class PersonConversationComponent implements OnInit, OnChanges {
	@Input() infoFriend;
	@Input() dataMessages;
	infoUser;
	chatMessage;
	skip = 20;
	limit = 10;
	showUpload = false;
	constructor(private messageService: MessageService, private socket: WebSocketService, private profileService: ProfileService) { }

	ngOnInit(): void {
		this.getInfoUser();

	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			setTimeout(() => {
				var chatBox = document.getElementById("chatbox");
				chatBox.scrollTop = chatBox.scrollHeight;
			}, 300)
		}
	}

	getInfoUser() {
		this.profileService.getProfile().subscribe((result) => {
			this.infoUser = result.data;

		})
	}
	async getChat() {
		let data = {
			receiverId: this.infoFriend._id,
			skip: this.skip,
			limit: this.limit,
		}
		this.messageService.getMessages(data).subscribe((messages) => {
			if (messages.data.length > 0) {
				for (var message of messages.data) {
					this.dataMessages.unshift(message);
				}
				var chatBox = document.getElementById("chatbox");
				chatBox.scrollTop = 50;
			}
		})
	}
	async createChat(dataFilesUploaded, message) {
		if (message && message != "") {
			let data = {
				receiverId: this.infoFriend._id,
				message: message,
				read: false,
				files: dataFilesUploaded,
				createdAt: new Date()
			}
			this.messageService.createMessage(data).subscribe(result => {
				this.socket.emit("message", result.data);
				this.dataMessages.push(result.data);
				setTimeout(() => {
					var chatBox = document.getElementById("chatbox");
					chatBox.scrollTop = chatBox.scrollHeight;
				}, 300)
			})
		}
	}
	imgSrc = []
	filesDropped(files): void {
		for (let file of files) {
			this.imgSrc.push(file);
		}
	}
	filesPasted(files): void {
		for (let file of files) {
			this.imgSrc.push(file);
		}
	}
	filesInput(files): void {
		for (let file of files) {
			this.imgSrc.push(file);
			this.showUpload = !this.showUpload
		}
	}
	getMoreMessages() {
		this.skip = this.skip + 10;
		this.getChat()
	}
	onScroll(event) {
		if (event.srcElement.scrollTop == 0) {
			this.getMoreMessages()
		}
	}
	initImg(arrImage) {
		let dataFilesUploaded = [];
		for (const i of arrImage) {
			if (i.mimetype.indexOf('image/') > -1) {
				dataFilesUploaded.push({
					url: i.location,
					filename: i.originalname,
					type: i.mimetype,
				});
			}
			if (i.mimetype.indexOf('video/') > -1) {
				dataFilesUploaded.push({
					url: 'https://' + i.location,
					filename: i.originalname,
					type: i.mimetype,
				});
			}
		}
		return dataFilesUploaded;
	}

	create() {
		let message = this.chatMessage;
		this.chatMessage = ""
		if (this.imgSrc && this.imgSrc.length > 0) {
			let formdata = new FormData;
			for (let i of this.imgSrc) {
				formdata.append('files', i.formData);
			}
			this.imgSrc = [];
			this.messageService.upload(formdata).subscribe(data => {
				if (data) {
					this.createChat(this.initImg(data), message);
				}
			})
		}
		else {
			this.createChat([], message);
		}
	}
}
