<ng-container>
	<!-- About sections -->
	<div class="left-menu">
		<div class="left-menu-inner">
			<div
				class="menu-item"
				(click)="isActive = 1"
				data-content="overview-content"
				[ngClass]="{'is-active': isActive == 1}"
			>
				<div class="menu-icon">
					<i class="mdi mdi-progress-check"></i>
					<span>Overview</span>
				</div>
			</div>
			<div
				class="menu-item"
				(click)="clickActiveTab(2)"
				data-content="personal-content"
				[ngClass]="{'is-active': isActive == 2}"
			>
				<div class="menu-icon">
					<i class="mdi mdi-apps"></i>
					<span>Personal Info</span>
				</div>
			</div>
			<div
				class="menu-item"
				(click)="clickActiveTab(3)"
				data-content="education-content"
				[ngClass]="{'is-active': isActive == 3}"
			>
				<div class="menu-icon">
					<i class="mdi mdi-school"></i>
					<span>Education</span>
				</div>
			</div>
			<div
				class="menu-item"
				(click)="clickActiveTab(4)"
				data-content="job-content"
				[ngClass]="{'is-active': isActive == 4}"
			>
				<div class="menu-icon">
					<i class="mdi mdi-briefcase-plus"></i>
					<span>Jobs</span>
				</div>
			</div>
		</div>
	</div>
	<div class="right-content">
		<div
			id="overview-content"
			class="content-section"
			[ngClass]="{'is-active': isActive == 1}"
		>
			<div class="columns">
				<div class="column">
					<!-- Work block -->
					<ng-container *ngFor="let job of arrJobs | slice: 0:1">
						<div class="flex-block">
							<img
								[src]="job?.companyId?.comCover"
								data-demo-src="assets/img/icons/logos/slicer.svg"
								alt
								data-page-popover="4"
							/>
							<div class="flex-block-meta">
								<span>
									Works at

									<a>{{ job?.companyId?.comName | titlecase }}</a>
								</span>
								<a class="action-link">Edit your professional info</a>
							</div>
							<div class="go-button">
								<i-feather name="arrow-right"></i-feather>
							</div>
						</div>
					</ng-container>
					<!-- Education block -->
					<ng-container *ngFor="let edu of arrEducations | slice: 0:1">
						<div class="flex-block">
							<img
								[src]="edu?.schoolId?.schoolAvt"
								data-demo-src="assets/img/icons/logos/brent.svg"
								alt
								data-page-popover="6"
							/>
							<div class="flex-block-meta">
								<span>
									Studied at

									<a>{{ edu?.schoolId?.schoolName | titlecase }}</a>
									>
								</span>
								<a class="action-link">Edit your education info</a>
							</div>
							<div class="go-button">
								<i-feather name="arrow-right"></i-feather>
							</div>
						</div>
					</ng-container>
					<!-- Location block -->
					<div class="flex-block">
						<img
							src="https://via.placeholder.com/400x400"
							data-demo-src="assets/img/icons/logos/la.svg"
							alt
							data-page-popover="8"
						/>
						<div class="flex-block-meta">
							<span>
								Lives in
								<a>{{ infouser.address | titlecase }}</a>
							</span>
							<a class="action-link">Edit your location</a>
						</div>
						<div class="go-button">
							<i-feather name="arrow-right"></i-feather>
						</div>
					</div>
					<!-- Family block -->
					<div class="flex-block">
						<img
							src="https://via.placeholder.com/300x300"
							data-demo-src="assets/img/avatars/dan.jpg"
						/>
						<div class="flex-block-meta">
							<span>
								{{ infouser.relationship }}
								<a *ngIf="infouser.relationshipperson">{{
									infouser.relationshipperson
								}}</a>
							</span>
							<a class="action-link">Edit your situation</a>
						</div>
						<div class="go-button">
							<i-feather name="arrow-right"></i-feather>
						</div>
					</div>
				</div>
				<div class="column">
					<div class="about-summary">
						<div class="content">
							<h3>About Me</h3>
							<p>{{ infouser.about }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Personal tab -->
		<div
			id="personal-content"
			class="content-section"
			[ngClass]="{'is-active': isActive == 2}"
		>
			<!-- Friends about card -->
			<div class="about-card">
				<!-- Header -->
				<div class="header">
					<div class="icon-title">
						<i class="mdi mdi-account-group"></i>
						<h3>Friends</h3>
					</div>
					<div class="actions">
						<div class="button-wrapper">
							<a class="button">Invitations</a>
							<div class="indicator">
								<span>8</span>
							</div>
						</div>
						<!-- Dropdown -->
						<div class="dropdown is-spaced is-accent is-right dropdown-trigger">
							<div>
								<div class="button">
									<i-feather name="more-vertical"></i-feather>
								</div>
							</div>
							<div class="dropdown-menu" role="menu">
								<div class="dropdown-content">
									<a href="#" class="dropdown-item">
										<div class="media">
											<i-feather name="smile"></i-feather>
											<div class="media-content">
												<h3>Manage</h3>
												<small>Manage your friend list.</small>
											</div>
										</div>
									</a>
									<a class="dropdown-item">
										<div class="media">
											<i-feather name="search"></i-feather>
											<div class="media-content">
												<h3>Find friends</h3>
												<small>Search for new friends.</small>
											</div>
										</div>
									</a>
									<hr class="dropdown-divider" />
									<a href="#" class="dropdown-item">
										<div class="media">
											<i-feather name="eye"></i-feather>
											<div class="media-content">
												<h3>View all</h3>
												<small>View all friends.</small>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="body">
					<div class="columns friends-columns is-multiline" *ngIf="friends">
						<div *ngFor="let friend of friends" class="column is-6">
							<div
								class="friend-small-card"
								*ngIf="infouser._id == friend?.receiverId?._id"
							>
								<img
									[src]="friend?.senderId?.avatar"
									data-demo-src="assets/img/avatars/nelly.png"
									[attr.data-user-popover]="friend?.senderId?._id"
									alt
								/>
								<div class="meta">
									<span>
										{{
											friend?.senderId.firstname +
												" " +
												friend?.senderId.lastname
										}}
									</span>
									<span>182 Friends</span>
								</div>
							</div>
							<div
								class="friend-small-card"
								*ngIf="infouser._id == friend?.senderId._id"
							>
								<img
									[src]="friend?.receiverId?.avatar"
									data-demo-src="assets/img/avatars/nelly.png"
									data-user-popover="9"
									alt
								/>
								<div class="meta">
									<span>
										{{
											friend?.receiverId?.firstname +
												" " +
												friend?.receiverId?.lastname
										}}
									</span>
									<span>182 Friends</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Photos about card -->
			<div class="about-card">
				<!-- Header -->
				<div class="header">
					<div class="icon-title">
						<i class="mdi mdi-camera"></i>
						<h3>Photos</h3>
					</div>
					<div class="actions">
						<div class="button-wrapper">
							<a class="button">Albums</a>
						</div>
						<!-- Dropdown -->
						<div class="dropdown is-spaced is-accent is-right dropdown-trigger">
							<div>
								<div class="button">
									<i-feather name="more-vertical"></i-feather>
								</div>
							</div>
							<div class="dropdown-menu" role="menu">
								<div class="dropdown-content">
									<a href="#" class="dropdown-item">
										<div class="media">
											<i-feather name="user"></i-feather>
											<div class="media-content">
												<h3>Of Me</h3>
												<small>View pictures of me.</small>
											</div>
										</div>
									</a>
									<a class="dropdown-item">
										<div class="media">
											<i-feather name="users"></i-feather>
											<div class="media-content">
												<h3>With Me</h3>
												<small>View pictures with me.</small>
											</div>
										</div>
									</a>
									<hr class="dropdown-divider" />
									<a class="dropdown-item">
										<div class="media">
											<i-feather name="upload-cloud"></i-feather>
											<div class="media-content">
												<h3>Upload</h3>
												<small>Upload pictures from computer.</small>
											</div>
										</div>
									</a>
									<a class="dropdown-item">
										<div class="media">
											<i-feather name="plus"></i-feather>
											<div class="media-content">
												<h3>Create Album</h3>
												<small>Create a new album.</small>
											</div>
										</div>
									</a>
									<hr class="dropdown-divider" />
									<a href="#" class="dropdown-item">
										<div class="media">
											<i-feather name="image"></i-feather>
											<div class="media-content">
												<h3>View all</h3>
												<small>View all photos.</small>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="body has-flex-list">
					<!-- Photos -->
					<div class="photo-list" *ngIf="photos">
						<!-- Photo item -->
						<div
							class="photo-wrapper"
							*ngFor="let photo of photos | slice: 0:10"
						>
							<div class="photo-overlay"></div>
							<div class="small-like" [ngClass]="photo.like ? 'is-active' : ''">
								<div class="inner" (click)="photo.like = !photo.like">
									<div class="like-overlay"></div>
									<i-feather name="heart"></i-feather>
								</div>
							</div>
							<img
								[src]="photo.photoName"
								data-demo-src="assets/img/demo/profile/about/photos/1.jpg"
								alt
							/>
						</div>
					</div>
				</div>
			</div>
			<!-- Videos about card -->
			<div class="about-card">
				<!-- Header -->
				<div class="header">
					<div class="icon-title">
						<i class="mdi mdi-video"></i>
						<h3>Videos</h3>
					</div>
					<div class="actions">
						<div class="button-wrapper">
							<a class="button">All Videos</a>
						</div>
						<!-- Dropdown -->
						<div class="dropdown is-spaced is-accent is-right dropdown-trigger">
							<div>
								<div class="button">
									<i-feather name="more-vertical"></i-feather>
								</div>
							</div>
							<div class="dropdown-menu" role="menu">
								<div class="dropdown-content">
									<a href="#" class="dropdown-item">
										<div class="media">
											<i-feather name="edit-3"></i-feather>
											<div class="media-content">
												<h3>Manage</h3>
												<small>Manage your videos.</small>
											</div>
										</div>
									</a>
									<a class="dropdown-item">
										<div class="media">
											<i-feather name="plus"></i-feather>
											<div class="media-content">
												<h3>Upload</h3>
												<small>Upload a new video.</small>
											</div>
										</div>
									</a>
									<hr class="dropdown-divider" />
									<a href="#" class="dropdown-item">
										<div class="media">
											<i-feather name="settings"></i-feather>
											<div class="media-content">
												<h3>Settings</h3>
												<small>Open video settings.</small>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="body has-flex-list">
					<!-- Videos -->
					<div class="video-list">
						<!-- Video item -->
						<div class="video-wrapper" *ngFor="let video of videos">
							<div class="video-overlay"></div>
							<div class="video-length">02:32</div>
							<div class="small-like">
								<div class="inner">
									<div class="like-overlay"></div>
									<i-feather name="heart"></i-feather>
								</div>
							</div>
							<img
								src="https://via.placeholder.com/800x600"
								data-demo-src="assets/img/demo/profile/about/videos/1.jpg"
								alt
							/>
							<div class="video-button" data-video-id="LTrzSSf0YlA">
								<img src="assets/img/icons/video/play.svg" alt />
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Places about card -->
			<div class="about-card">
				<!-- Header -->
				<div class="header">
					<div class="icon-title">
						<i class="mdi mdi-map-outline"></i>
						<h3>Places</h3>
					</div>
					<div class="actions">
						<div class="button-wrapper">
							<a class="button">View All</a>
						</div>
						<!-- Dropdown -->
						<div class="dropdown is-spaced is-accent is-right dropdown-trigger">
							<div>
								<div class="button">
									<i-feather name="more-vertical"></i-feather>
								</div>
							</div>
							<div class="dropdown-menu" role="menu">
								<div class="dropdown-content">
									<a href="#" class="dropdown-item">
										<div class="media">
											<i-feather name="clock"></i-feather>
											<div class="media-content">
												<h3>Recent</h3>
												<small>Show recent places.</small>
											</div>
										</div>
									</a>
									<a class="dropdown-item">
										<div class="media">
											<i-feather name="map-pin"></i-feather>
											<div class="media-content">
												<h3>Visited</h3>
												<small>Show visited places.</small>
											</div>
										</div>
									</a>
									<hr class="dropdown-divider" />
									<a href="#" class="dropdown-item">
										<div class="media">
											<i-feather name="plus"></i-feather>
											<div class="media-content">
												<h3>Add</h3>
												<small>Add a new place.</small>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="body has-flex-list">
					<!-- Places -->
					<div class="place-list">
						<!-- Place item -->
						<div class="place-wrapper">
							<img
								src="https://via.placeholder.com/800x600"
								data-demo-src="assets/img/demo/unsplash/places/16.jpg"
								alt
							/>
							<div class="foot">
								<a href="#" class="place-name"> Melbourne </a>
								<div class="rating">
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
								</div>
							</div>
						</div>
						<!-- Place item -->
						<div class="place-wrapper">
							<img
								src="https://via.placeholder.com/800x600"
								data-demo-src="assets/img/demo/unsplash/places/17.jpg"
								alt
							/>
							<div class="foot">
								<a href="#" class="place-name"> Dany's Burgers </a>
								<div class="rating">
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
								</div>
							</div>
						</div>
						<!-- Place item -->
						<div class="place-wrapper">
							<img
								src="https://via.placeholder.com/800x600"
								data-demo-src="assets/img/demo/unsplash/places/18.jpg"
								alt
							/>
							<div class="foot">
								<a href="#" class="place-name"> Vethnics Fashion </a>
								<div class="rating">
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
								</div>
							</div>
						</div>
						<!-- Place item -->
						<div class="place-wrapper">
							<img
								src="https://via.placeholder.com/800x600"
								data-demo-src="assets/img/demo/unsplash/places/19.jpg"
								alt
							/>
							<div class="foot">
								<a href="#" class="place-name"> The Smoothie Bar </a>
								<div class="rating">
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
								</div>
							</div>
						</div>
						<!-- Place item -->
						<div class="place-wrapper">
							<img
								src="https://via.placeholder.com/800x600"
								data-demo-src="assets/img/demo/unsplash/places/20.jpg"
								alt
							/>
							<div class="foot">
								<a href="#" class="place-name"> Eiffel Tower </a>
								<div class="rating">
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
								</div>
							</div>
						</div>
						<!-- Place item -->
						<div class="place-wrapper">
							<img
								src="https://via.placeholder.com/800x600"
								data-demo-src="assets/img/demo/unsplash/places/21.jpg"
								alt
							/>
							<div class="foot">
								<a href="#" class="place-name"> Lennie's Fair </a>
								<div class="rating">
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
									<i-feather name="star"></i-feather>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			id="education-content"
			class="content-section"
			[ngClass]="{'is-active': isActive == 3}"
		>
			<div id="education-glider" class="slider-timeline about-glider">
				<!-- Timeline Item -->
				<div class="timeline-item" *ngFor="let edu of arrEducations">
					<div class="image-container">
						<img
							[src]="edu?.schoolId?.schoolCover"
							alt
							[attr.data-demo-src]="edu?.schoolId?.schoolCover"
						/>
						<div class="logo-container">
							<img
								[src]="edu?.schoolId?.schoolAvt"
								alt
								[attr.data-demo-src]="edu?.schoolId?.schoolAvt"
								data-page-popover="6"
							/>
						</div>
					</div>
					<h3>{{ edu?.role | titlecase }}</h3>
					<p>{{ edu?.schoolId?.schoolName | titlecase }}</p>
					<div class="more">
						<ng-container *ngFor="let tag of edu?.schoolId?.schoolTag">
							<p>{{ edu?.schoolId?.schoolTag }}</p></ng-container
						>
					</div>
					<div class="date">
						{{ edu?.startDate | date: "MM, yyyy" }}
					</div>
				</div>
			</div>
			<div id="slider-dots-education-glider" class="dots"></div>
		</div>
		<div
			id="job-content"
			class="content-section"
			[ngClass]="{'is-active': isActive == 4}"
		>
			<!-- Timeline section -->
			<div id="jobs-glider" class="slider-timeline about-glider">
				<!-- Timeline Item -->
				<div class="timeline-item" *ngFor="let job of arrJobs">
					<div class="image-container">
						<img
							[src]="job?.companyId?.comCover"
							alt
							[attr.data-demo-src]="job?.companyId?.comCover"
						/>
						<div class="logo-container">
							<img
								[src]="job?.companyId?.comAvt"
								alt
								[attr.data-demo-src]="job?.companyId?.comAvt"
								data-page-popover="4"
							/>
						</div>
					</div>
					<h3>{{ job.role | titlecase }}</h3>
					<p>{{ job?.companyId?.comName | titlecase }}</p>
					<div class="more">
						<ng-container *ngFor="let tag of job?.companyId?.comTag">
							<p>
								{{ tag }}
							</p></ng-container
						>
					</div>
					<div class="date">{{ job?.startDate | date: "dd, MMMM" }}</div>
				</div>
			</div>
			<div id="slider-dots-jobs-glider" class="dots"></div>
		</div>
	</div>
</ng-container>
