<div
	id="add-conversation-modal"
	class="modal add-conversation-modal is-xsmall has-light-bg"
>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-heading">
				<h3>New Conversation</h3>
				<!-- Close X button -->
				<div class="close-wrap">
					<span class="close-modal">
						<i-feather name="x"></i-feather>
					</span>
				</div>
			</div>
			<div class="card-body">
				<img src="assets/img/icons/chat/bubbles.svg" alt />

				<div class="field is-autocomplete">
					<div class="control has-icon">
						<input
							type="text"
							class="input simple-users-autocpl"
							placeholder="Search a user"
						/>
						<div class="form-icon">
							<i-feather name="search"></i-feather>
						</div>
					</div>
				</div>

				<div class="help-text">
					Select a user to start a new conversation. You'll be able to add other
					users later.
				</div>

				<div class="action has-text-centered">
					<button type="button" class="button is-solid accent-button raised">
						Start conversation
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
