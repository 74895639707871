<app-basic-infos-widget
	[infouser]="infouser"
	[arrEducations]="arrEducations"
></app-basic-infos-widget>
<app-photos-widget [photos]="photos"></app-photos-widget>
<app-star-friends-widget
	[friends]="friends"
	[infouser]="infouser"
></app-star-friends-widget>
<app-video-widget [videos]="videos"></app-video-widget>
<app-trips-widget></app-trips-widget>
<div class="box-heading">
	<h4>Events</h4>
	<div class="dropdown is-neutral is-spaced is-right dropdown-trigger">
		<div>
			<div class="button">
				<i-feather name="more-vertical"></i-feather>
			</div>
		</div>
		<div class="dropdown-menu" role="menu">
			<div class="dropdown-content">
				<a class="dropdown-item">
					<div class="media">
						<i-feather name="calendar"></i-feather>
						<div class="media-content">
							<h3>All Events</h3>
							<small>View all your events</small>
						</div>
					</div>
				</a>
				<a href="#" class="dropdown-item">
					<div class="media">
						<i-feather name="search"></i-feather>
						<div class="media-content">
							<h3>Search</h3>
							<small>Search for events.</small>
						</div>
					</div>
				</a>
				<a href="#" class="dropdown-item">
					<div class="media">
						<i-feather name="compass"></i-feather>
						<div class="media-content">
							<h3>Suggestions</h3>
							<small>View trendy suggestions.</small>
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</div>
<!-- <app-schedule-widget [infouser]="infouser"></app-schedule-widget> -->
