import { Component, OnInit } from '@angular/core';
import { PagesService } from 'src/app/services/pages.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-admin-page-setting',
  templateUrl: './admin-page-setting.component.html',
  styleUrls: ['./admin-page-setting.component.scss'],
})
export class AdminPageSettingComponent implements OnInit {
  constructor(private pageService: PagesService) {
    this.environment = environment.apiUrl;
  }

  ngOnInit(): void {
    this.getPageUserAdmin();
  }

  dataPages = [];
  environment: any;
  getPageUserAdmin() {
    this.pageService.getPageAdmin().subscribe((result) => {
      if (result.status == 'success') {
        this.dataPages = result.data;
        let checkAttached = this.dataPages.find(
          (page) => page.isAttached === true
        );

        if (!checkAttached) {
          this.dataPages.forEach((elementPage) => {
            elementPage.isDisabled = false;
          });
          return;
        }
        if (checkAttached) {
          this.dataPages.forEach((page) => {
            page.isDisabled = page.isAttached == true ? false : true;
          });
          return;
        }
      }
    });
  }

  attachedPage(page, index) {
    if (this.dataPages[index].isAttached == true) {
      let reqPage = {
        id: page._id,
      };
      this.pageService.unAttachedPage(reqPage).subscribe((result) => {
        if (result.status == 'success') {
          this.dataPages.forEach((element) => {
            element.isDisabled = false;
          });
          this.getPageUserAdmin();
          this.pageService.changeSuccessCreatePage({ isLoading: true });
        }
      });
      return true;
    }

    if (this.dataPages[index].isAttached == false) {
      let reqPage = {
        id: page._id,
      };
      this.pageService.attachedPage(reqPage).subscribe((result) => {
        if (result.status == 'success') {
          this.dataPages.forEach((element) => {
            element.isDisabled = page._id == element._id ? false : true;
          });
          this.getPageUserAdmin();
          this.pageService.changeSuccessCreatePage({ isLoading: true });
        }
      });
      return true;
    }
  }
}
