export let loadjs = () => {
  let js = [
    // Concatenated js plugins and jQuery
    '../../assets/js/app.js',
    //'https://js.stripe.com/v3/',
    '../../assets/js/tipuedrop_content.js',
    '../../assets/js/main.js',
    // Page and UI related js
    '../../assets/js/global.js',
    '../../assets/js/feed.js',
    '../../assets/js/stories.js',
    '../../assets/js/chat.js',
    '../../assets/js/inbox.js',
    '../../assets/js/profile.js',
    '../../assets/js/friends.js',
    '../../assets/js/events.js',
    // '../../assets/js/explorer.js',
    '../../assets/js/news.js',
    '../../assets/js/questions.js',
    '../../assets/js/videos.js',
    '../../assets/js/shop.js',
    '../../assets/js/settings.js',
    // Components js
    '../../assets/js/widgets.js',
    '../../assets/js/autocompletes.js',
    '../../assets/js/modal-uploader.js',
    '../../assets/js/popovers-users.js',
    '../../assets/js/popovers-pages.js',
    '../../assets/js/go-live.js',
    '../../assets/js/touch.js',
    '../../assets/js/tour.js',
  ];
  removeScript();
  for (let i of js) {
    loadScript(i);
  }
};

function removeScript() {
  let removeScript = document.querySelectorAll('script[data-name="myscript"]');
  if (removeScript.length > 0) {
    for (let i = 0; i < removeScript.length; i++) {
      if (removeScript[i]) {
        document.getElementsByTagName('body')[0].removeChild(removeScript[i]);
      }
    }
  }
}

function loadScript(url) {
  const stripeExists = document.getElementById('stripev3');
  if (!stripeExists) {
    const stripeScript = document.createElement('script');
    stripeScript.src = 'https://js.stripe.com/v3/';
    stripeScript.async = true;
    stripeScript.id = 'stripev3';
    document.head.appendChild(stripeScript);
  }
  let script;
  script = document.createElement('script');
  let body = <HTMLDivElement>document.body;
  script.setAttribute('data-name', 'myscript');
  script.innerHTML = '';
  script.src = url;
  script.async = true;
  script.defer = true;
  body.appendChild(script);
}
