<div class="questions-wrap is-smaller">
	<div class="container">
		<div class="columns true-dom">
			<div class="column is-3">
				<!-- /html/partials/pages/questions/questions-menu -->
				<ul class="questions-menu questions-menu-fixed">
					<li>
						<a href="questions/home">
							<i-feather name="home"></i-feather>
							<span>Home</span>
						</a>
					</li>
					<li>
						<a href="questions/single">
							<i-feather name="message-circle"></i-feather>
							<span>My Questions</span>
						</a>
					</li>
					<li>
						<a href="questions/stats">
							<i-feather name="hexagon"></i-feather>
							<span>My Stats</span>
						</a>
					</li>
					<li>
						<a href="questions/categories">
							<i-feather name="layers"></i-feather>
							<span>Categories</span>
						</a>
					</li>
					<li class="is-active">
						<a href="questions/settings">
							<i-feather name="settings"></i-feather>
							<span>Settings</span>
						</a>
					</li>
				</ul>

				<ul class="questions-menu">
					<li>
						<a href="questions/home">
							<i-feather name="home"></i-feather>
							<span>Home</span>
						</a>
					</li>
					<li>
						<a href="questions/single">
							<i-feather name="message-circle"></i-feather>
							<span>My Questions</span>
						</a>
					</li>
					<li>
						<a href="questions/stats">
							<i-feather name="hexagon"></i-feather>
							<span>My Stats</span>
						</a>
					</li>
					<li>
						<a href="questions/categories">
							<i-feather name="layers"></i-feather>
							<span>Categories</span>
						</a>
					</li>
					<li class="is-active">
						<a href="questions/settings">
							<i-feather name="settings"></i-feather>
							<span>Settings</span>
						</a>
					</li>
				</ul>
			</div>
			<div class="column is-6">
				<div class="questions-settings">
					<div class="settings-header">
						<h2>Settings</h2>
						<div class="control">
							<input
								class="input is-rounded"
								type="text"
								placeholder="Search..."
							/>
							<div class="search-icon">
								<i-feather name="search"></i-feather>
							</div>
						</div>
					</div>

					<div class="settings-body">
						<div class="switch-block">
							<label class="f-switch is-accent">
								<input type="checkbox" class="is-switch" checked />
								<i></i>
							</label>
							<div class="meta">
								<span>New Question</span>
								<span
									>Receive a notification when someone you are following posts a
									new question.</span
								>
							</div>
						</div>
						<div class="switch-block">
							<label class="f-switch is-accent">
								<input type="checkbox" class="is-switch" />
								<i></i>
							</label>
							<div class="meta">
								<span>New Answer</span>
								<span
									>Receive a notification when someone posts an answer to a
									question you asked.</span
								>
							</div>
						</div>
						<div class="switch-block">
							<label class="f-switch is-accent">
								<input type="checkbox" class="is-switch" />
								<i></i>
							</label>
							<div class="meta">
								<span>Upvotes</span>
								<span
									>Receive notifications when your answers are being
									upvoted.</span
								>
							</div>
						</div>
						<div class="switch-block">
							<label class="f-switch is-primary">
								<input type="checkbox" class="is-switch" />
								<i></i>
							</label>
							<div class="meta">
								<span>New Follower</span>
								<span
									>Receive a notification when someone starts following
									you.</span
								>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="column is-3">
				<div class="questions-side-card">
					<img src="assets/img/icons/questions/help.svg" alt="" />
					<h4>Help center</h4>
					<p>
						Having trouble? Please search our
						<a class="standard-link">Help Center</a> for a quick answer to your
						problem.
					</p>
				</div>

				<div class="questions-side-card">
					<img src="assets/img/icons/questions/assistance.svg" alt="" />
					<h4>24/7 Support</h4>
					<p>
						Our Help Center didn't help? Please contact our 24/7
						<a class="standard-link">Customer Assistance</a> hotline.
					</p>
				</div>
			</div>
		</div>
	</div>
</div>
