<div class="box-heading">
	<div class="dropdown photos-dropdown is-spaced is-accent dropdown-trigger">
		<div>
			<div class="button">
				<span>All Pictures</span>
				<i-feather name="chevron-down"></i-feather>
			</div>
		</div>
		<div class="dropdown-menu" role="menu">
			<div class="dropdown-content">
				<a href="#" class="dropdown-item">
					<div class="media">
						<i-feather name="tag"></i-feather>
						<div class="media-content">
							<h3>Tagged Photos</h3>
							<small>Photos whith this user tagged.</small>
						</div>
					</div>
				</a>
				<a class="dropdown-item">
					<div class="media">
						<i-feather name="clock"></i-feather>
						<div class="media-content">
							<h3>Recent</h3>
							<small>View most recent photos.</small>
						</div>
					</div>
				</a>
				<a class="dropdown-item">
					<div class="media">
						<i-feather name="heart"></i-feather>
						<div class="media-content">
							<h3>Popular</h3>
							<small>View popular photos.</small>
						</div>
					</div>
				</a>
				<hr class="dropdown-divider" />
				<a class="dropdown-item">
					<div class="media">
						<i-feather name="book-open"></i-feather>
						<div class="media-content">
							<h3>Albums</h3>
							<small>See all albums.</small>
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>

	<div class="button-wrap">
		<button
			type="button"
			class="button"
			[ngClass]="isPageActive == 1 ? 'is-active' : ''"
			(click)="clickPageChildActive(1)"
		>
			Recent
		</button>
		<button
			type="button"
			class="button"
			[ngClass]="isPageActive == 2 ? 'is-active' : ''"
			(click)="clickPageChildActive(2)"
		>
			Albums
		</button>
	</div>
</div>

<div class="image-grid-wrap" *ngIf="isPageActive == 1">
	<div class="image-grid">
		<!-- Grid Row -->
		<ng-container *ngFor="let arrImg of listImage">
			<div class="image-row">
				<ng-container *ngFor="let itemImg of arrImg">
					<div
						class="flex-1 has-background-image"
						[ngStyle]="{
							background: 'url(' + itemImg?.image + ')'
						}"
						[attr.data-demo-background]="itemImg?.image"
						(click)="clickPost(itemImg?.postId)"
					>
						<div class="overlay"></div>
						<div class="image-owner">
							<img
								class="avatar"
								[src]="dataPages?.pageAvatar"
								[attr.data-demo-background]="dataPages?.pageCover"
								alt
							/>
							<div class="name">{{ dataPages?.pageName }}</div>
						</div>
						<div class="photo-time">
							{{ itemImg?.createdAt | date: "MMM dd,yyyy" }}
						</div>
						<a class="photo-like">
							<div class="inner">
								<div class="like-overlay"></div>
								<i-feather name="heart"></i-feather>
							</div>
						</a>
					</div>
				</ng-container>
				<!-- Photo -->
				<!-- Photo -->
			</div>
		</ng-container>
	</div>

	<!-- Load more photos -->
	<div class="load-more-wrap has-text-centered">
		<a href="#" class="load-more-button"> Load More </a>
	</div>
</div>
<app-page-album
	*ngIf="isPageActive == 2"
	[dataPage]="dataPages"
></app-page-album>
<app-page-photo-lightbox-container
	*ngIf="dataPost"
	[dataPost]="dataPost"
	[isActivePhoto]="isActivePhoto"
	[InfoUser]="infoUsers"
	(closeModal)="closeModal($event)"
></app-page-photo-lightbox-container>
