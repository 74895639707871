import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';
import { PagesService } from 'src/app/services/pages.service';
import * as environment from 'src/environments/environment';
import { GroupService } from 'src/app/services/group/group.service';
declare var jQuery: any;

declare function initNavDropdowns(): any;

@Component({
  selector: 'app-account-dropdown',
  templateUrl: './account-dropdown.component.html',
  styleUrls: ['./account-dropdown.component.scss'],
})
export class AccountDropdownComponent implements OnInit {
  constructor(
    private router: Router,
    private profileService: ProfileService,
    private pageService: PagesService,
    private groupService: GroupService
  ) { }
  profile;
  environment = environment.environment.apiUrl;

  // for testing..
  loggedInUsers = [];
  //
  ngOnInit(): void {
    this.isModalCreatePage = false;
    this.getProfile();
    this.pageService.loadingPage.subscribe((isLoading) => {
      if (isLoading.isLoading == true) {
        this.getProfile();
      }
    });
    this.profileService.changeSuccess.subscribe((image) => {
      if (image.status == 'success') {
        this.getProfile();
      }
    });
    initNavDropdowns();
    if (localStorage.getItem('theme') == 'dark') {
      this.theme = 'dark';
      document.body.classList.add('is-dark');
    }

    // for testing..
    var storedTokensStr = localStorage.getItem('storedTokens');
    var storedTokens = JSON.parse(storedTokensStr);
    for (var i in storedTokens) {
      this.loggedInUsers.push({
        username: i,
        token: storedTokens[i]
      })
    }
    //
  }

  switchAccount(profile) {
    localStorage.setItem('token', profile.token);
    let token = localStorage.getItem('token');
    localStorage.setItem('UserName', profile.username);


    if (token != undefined) {
      window.location.replace('');
    }
  }

  getProfile() {
    this.profileService.getProfile().subscribe((result) => {
      if (result.status == 'success') {
        this.profile = result['data'];
        this.getPageByUserId(this.profile._id);
        this.getGroupByUserId();
      }
    });
  }

  goProfile() {
    this.router.navigate(['/profile', this.profile._id]);
  }

  goSettings() {
    this.router.navigate(['/settings']);
  }

  logout() {
    // for testing...
    var storedTokensStr = localStorage.getItem('storedTokens');
    //
    localStorage.clear();

    // for testing... 
    localStorage.setItem('storedTokens', storedTokensStr);
    //
    window.location.reload();
  }

  goCreatePage() {
    this.router.navigate(['/create']);
  }

  dataMyPage: any;
  getPageByUserId(userId) {
    this.pageService.getPageByUserId(userId).subscribe((result) => {
      if (result.status == 'success') {
        this.dataMyPage = result.data;
      }
    });
  }

  goMyPage(dataPage) {
    localStorage.setItem('id', dataPage._id);
    // window.location.replace(`/pages/${dataPage._id}`);
    this.router.navigate(['pages', dataPage._id]);
  }

  isModalCreatePage: boolean = false;
  openModalCreatePage() {
    this.isModalCreatePage = !this.isModalCreatePage;
  }

  changeModalcrtPageChild(value) {
    this.isModalCreatePage = value;
  }

  isModelGroup: boolean = false;
  openModelCreateGroup() {
    this.isModelGroup = !this.isModelGroup;
  }

  dataGroup: any;
  getGroupByUserId() {
    this.groupService.getGroupByUserId().subscribe((result) => {
      if (result.status == 'success') {
        this.dataGroup = result.data;
      }
    });
  }
  goMyGroup() {
    // window.location.replace(`/groups/${this.dataGroup._id}`);
    this.router.navigate(['groups', this.dataGroup._id]);
  }

  theme = 'light';
  changeTheme() {
    if (this.theme == 'light') {
      this.theme = 'dark';
      localStorage.setItem('theme', 'dark');
      document.body.classList.add('is-dark');
    } else {
      localStorage.removeItem('theme');
      this.theme = 'light';
      document.body.classList.remove('is-dark');
    }
  }
}
