import { Component, Input, OnInit } from '@angular/core';
import { PagesService } from 'src/app/services/pages.service';
import { StoriesService } from 'src/app/services/stories.service';
import * as environment from '../../../../../environments/environment';
declare var jQuery: any;

@Component({
  selector: 'app-image-story-modal',
  templateUrl: './image-story-modal.component.html',
  styleUrls: ['./image-story-modal.component.scss'],
})
export class ImageStoryModalComponent implements OnInit {
  infoUsers;
  @Input() set infoUser(infoUser: any) {
    this.infoUsers = infoUser;
  }
  //model stories
  environment;
  postContent;
  postImg;
  constructor(
    private pagesService: PagesService,
    private storiesService: StoriesService
  ) {
    this.environment = environment.environment.apiUrl;
  }
  ngOnInit(): void { }
  //#region function Tag friend
  repeatTag(arr) {
    if (arr.length > 1) {
      let count = 0;
      for (let i = 0; i < arr.length; i++) {
        for (let j = 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            return (count = count + 1);
          }
        }
      }
    }
  }
  textComment;
  commentTag = [];
  postTags = [];
  model;
  tagsUser(user) {
    let fullname = user.firstname + user.lastname;
    this.postTags.push(user._id);
    let txtContent = document.getElementById('tag-friends').innerHTML;
    let count = 0;
    if (this.repeatTag(this.arrName) > 0) {
      let lengthLast = this.arrName[this.arrName.length - 1].length;
      txtContent = txtContent.slice(0, txtContent.length - lengthLast);
    } else {
      if (this.arrName.length > 1) {
        count = this.arrName.length - 1;
      }
      let indexCount = txtContent.indexOf(this.arrName[count], 0);
      txtContent = txtContent.slice(0, indexCount);
    }

    document.getElementById('tag-friends').innerHTML = txtContent;
    this.changeCommentContent(fullname);
    this.arrUser = [];
  }
  arrUser = [];
  checkKeySearch;
  arrName = [];
  searchUser(username) {
    if (username != undefined || username != null) {
      (function ($) {
        $(document).ready(function () {
          $('.tag-fr').focusout(function () {
            var element = $(this);
            if (!element.text().replace(' ', '').length) {
              element.empty();
            }
          });
        });
      })(jQuery);
      var start = /@/gi; // @ Match
      var word = /@(\w+)/gi; //@abc Match
      if (username.indexOf('@') > -1) {
        var go = username.match(start); //Content Matching @
        this.arrName = username.match(word); //Content Matching @abc
        if (go.length > 0) {
          if (this.arrName) {
            let count = 0;
            if (this.arrName.length > 1) {
              count = this.arrName.length - 1;
            }
            let usernameFillter = this.arrName[count].toString().slice(1);
            this.pagesService
              .searchUser({ search: usernameFillter })
              .subscribe((arrUser) => {
                if (arrUser.status == 'success') {
                  this.arrUser = arrUser.data;
                }
              });
          } else {
            this.arrUser = [];
          }
        }
      }
    }
  }
  changeCommentContent(commentContent) {
    (function ($) {
      $(document).ready(function () {
        $(`#tag-friends`).append(
          `<mark contenteditable="false">${commentContent}</mark>`
        );
      });
    })(jQuery);
  }
  //#endregion

  //#region function create stories
  async createStories() {
    let dataImg = await this.storiesService.uploadImage({ img: this.previewImg }).toPromise();
    let reqStories = {
      postImg: dataImg.data,
      postContent: this.postContent,
      postTags: this.postTags,
      postType: 1,
    };
    let result = await this.storiesService.createStoires(reqStories).toPromise();
    if (result.status == 'success') {
      (function ($) {
        $(document).ready(function () {
          $('.btn-cus.close-modal').trigger('click');
        });
      })(jQuery);
      this.storiesService.changeStories({ isStories: true });
      this.clearInput();
    }
  }

  clearInput() {
    this.postImg = [];
    this.postContent = undefined;
    this.postTags = [];
    this.previewImg = [];
    this.formData.delete('post-img');
    document.getElementById(`tag-friends`).innerHTML = '';
  }
  //#endregion

  //#region function upload images
  imgDefault =
    'https://cdn.dribbble.com/users/17001/screenshots/5899975/dribbble_11_1x.png';
  previewImg;

  formData: FormData = new FormData();
  detectFiles(event) {
    this.previewImg = null;
    const file = event.target.files[0];
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        // this.postImg = e.target.result;
        this.previewImg = e.target.result;
      };
    }
  }
  //#endregion
}
