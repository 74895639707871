import { Component, Input, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import * as environment from 'src/environments/environment';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
  @Input() arrEvent;
  arrEvents;
  arrDisplays = [
    { value: 0, name: 'This is a public event', viewValue: 'Public' },
    { value: 1, name: 'This is a private event', viewValue: 'Private' },
  ];
  environment;
  constructor(private profileServe: ProfileService) {
    this.environment = environment.environment.apiUrl;
  }

  ngOnInit(): void {
    this.profileServe.isShowEvent.subscribe((result) => {
      if (result.isShow == true) {
        for (let evt in this.arrEvent) {
          let dateEvent = new Date(this.arrEvent[evt].startDate).getDate();
          if (result.dayClick == dateEvent) {
            this.arrEvents = this.arrEvent[evt];
            this.getInviteEvent(this.arrEvents._id);
          }
        }
      }
    });
  }
  arrRole;
  arrDataInvite;
  getInviteEvent(id) {
    this.profileServe.getInviteEvent({ calEventId: id }).subscribe((result) => {
      this.arrDataInvite = result.data;
    });
  }
}
