<!-- /html/partials/pages/stories/stories-sidebar.html -->
<div class="stories-sidebar is-active str-wrap">
	<div class="stories-sidebar-inner">
		<div class="section-title main-section-title">
			<h2>Stories</h2>
		</div>
		<div class="section-title main-section-title">
			<h4>Your stories</h4>
		</div>
		<div class="user-block">
			<a class="story modal-trigger story-wrap" data-modal="new-story-modal">
				<div class="story-avatar">
					<img
						class="avatar"
						src="https://via.placeholder.com/150x150"
						data-demo-src="assets/img/avatars/jenna.png"
						data-user-popover="0"
						alt=""
					/>
					<div class="add-overlay">
						<i-feather name="plus"></i-feather>
					</div>
				</div>
				<div class="cr-str">
					<div class="title is-6">Create a storry</div>
					<div class="subtitle is-6">Share a photo or write something.</div>
				</div>
			</a>
		</div>
		<div class="user-menu">
			<div class="user-menu-inner has-slimscroll">
				<ng-container *ngFor="let story of arrStories; let index = index">
					<a class="story">
						<div
							class="story-wrap"
							[ngClass]="story?.isActive ? 'is-show' : ''"
							(click)="openStory(story?._id, index)"
						>
							<img
								class="story-wrap-avt"
								[src]="story?.postUserId?.avatar"
								data-demo-src="assets/img/avatars/dan.jpg"
								alt=""
							/>
							<div class="story-wrap-user">
								<div class="wrap-user-auth">
									{{ story?.postUserId?.firstname }}
									{{ story?.postUserId?.lastname }}
								</div>
								<div class="wrap-user-date subtitle is-6">
									{{ story?.createdAt | dateAgo }}
								</div>
							</div>
						</div>
					</a>
				</ng-container>
			</div>
		</div>
	</div>
</div>
<!-- Stories -->
