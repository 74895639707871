<div id="albums-modal" class="modal albums-modal is-xxl has-light-bg">
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-heading">
				<h3>New album</h3>
				<div class="button is-solid accent-button fileinputs-button file-btn">
					<label for="upload">
						<i-feather name="plus"></i-feather>
						Add pictures/videos
						<input
							id="upload"
							type="file"
							(change)="detectFiles($event)"
							multiple
							accept="image/png, image/jpeg"
							class="input-file"
						/>
					</label>
				</div>
				<!-- Close X button -->
				<div class="close-wrap">
					<span class="close-modal">
						<i-feather name="x"></i-feather>
					</span>
				</div>
			</div>
			<div class="card-body">
				<div class="left-section">
					<div class="album-form">
						<div class="control">
							<input
								type="text"
								class="input is-sm no-radius is-fade"
								placeholder="Album name"
								[(ngModel)]="dataAlbum.albumName"
							/>
							<div class="icon">
								<i-feather name="camera"></i-feather>
							</div>
						</div>
						<div class="control">
							<textarea
								class="textarea is-fade no-radius is-sm"
								rows="3"
								placeholder="describe your album ..."
								[(ngModel)]="dataAlbum.albumDescription"
							></textarea>
						</div>
						<div class="control">
							<input
								type="text"
								class="input is-sm no-radius is-fade"
								placeholder="Place"
								[(ngModel)]="dataAlbum.albumPlace"
							/>
							<div class="icon">
								<i-feather name="map-pin"></i-feather>
							</div>
						</div>
					</div>
					<div class="control is-autocomplete">
						<div
							id="materials-autocpl-wrapper"
							class="control is-wrapper has-margin"
						>
							<div
								*ngIf="itemCategories || subCategories"
								class="input"
								(click)="dropDown(0)"
							>
								<span *ngIf="itemCategories && !subCategories">{{
									itemCategories
								}}</span>
								<span *ngIf="subCategories">{{ subCategories }}</span>
							</div>
							<input
								*ngIf="!itemCategories && !subCategories"
								type="text"
								class="input is-sm no-radius is-fade"
								placeholder="Categories"
								[(ngModel)]="searchCate"
								(click)="dropDown(0)"
							/>
							<div
								class="material-wrap easy-autocomplete-container main-cate"
								id="eac-container-users-autocpl "
							>
								<ul
									[ngClass]="
										dropDownId == 0 || searchCate ? 'is-active' : 'is-hidden'
									"
								>
									<li
										class="categories-item"
										*ngFor="
											let categories of arrCategories | filter: searchCate;
											let i = index;
											trackBy: trackByFn
										"
										(click)="
											hoveredIndexItem != undefined
												? ''
												: selectCategories(categories)
										"
										(mouseenter)="hoveredIndex = i"
										(mouseleave)="hoveredIndex = null"
									>
										<div>
											<span>{{ categories?.title }}</span>
											<i-feather name="chevrons-right"></i-feather>
										</div>
										<ul
											class="sub-cate columns"
											[ngClass]="hoveredIndex === i ? 'is-active' : 'is-hidden'"
										>
											<div class="gr-sub-cate">
												<div class="sub-cate-left column">
													<li
														class="categories-item"
														*ngFor="
															let itemCate of categories?.arrSub
																| slice: 0:13
																| filter: searchCate;
															trackBy: trackByFn;
															let indexLeft = index
														"
														(click)="selectItemCategories(categories, itemCate)"
														(mouseenter)="hoveredIndexItem = indexLeft"
														(mouseleave)="hoveredIndexItem = null"
													>
														<div>
															<span>{{ itemCate?.title }}</span>
														</div>
													</li>
												</div>
												<div class="sub-cate-right column">
													<li
														class="categories-item"
														*ngFor="
															let itemCate of categories?.arrSub
																| slice: 13
																| filter: searchCate;
															let indexRight = index;
															trackBy: trackByFn
														"
														(click)="selectItemCategories(categories, itemCate)"
														(mouseenter)="hoveredIndexItem = indexRight"
														(mouseleave)="hoveredIndexItem = null"
													>
														<div>
															<span>{{ itemCate?.title }}</span>
														</div>
													</li>
												</div>
											</div>
										</ul>
									</li>
								</ul>
							</div>
							<div class="icon">
								<i-feather name="search"></i-feather>
							</div>
							<div class="close-icon is-main" (click)="clearCategories()">
								<i-feather name="x"></i-feather>
							</div>
						</div>
					</div>
					<div class="control is-autocomplete">
						<div
							id="materials-autocpl-wrapper"
							class="control is-wrapper has-margin"
						>
							<div *ngIf="countries" class="input" (click)="dropDown(1)">
								<span>
									{{ countries }}
								</span>
							</div>
							<input
								*ngIf="!countries"
								type="text"
								class="input is-sm no-radius is-fade"
								placeholder="Country/Region"
								[(ngModel)]="searchCountries"
								(click)="dropDown(1)"
							/>
							<div
								class="
									material-wrap
									easy-autocomplete-container
									scroll-customize
								"
								id="eac-container-users-autocpl"
							>
								<ul [ngClass]="dropDownId == 1 ? 'is-active' : 'is-hidden'">
									<li
										class="categories-item"
										*ngFor="let countries of arrCountries; trackBy: trackByFn"
									>
										<!-- <div><span
											*ngIf="countries?.isMostPopular; else noMost"
											class="is-6"
											>Most Popular</span</div>
										> -->
										<ng-template #noMost>
											<span class="is-6">Alphabetically</span></ng-template
										>
										<ng-container
											*ngFor="
												let popular of countries.arrPopular
													| filter: searchCountries;
												trackBy: trackByFn
											"
										>
											<div (click)="selectCountries(popular)">
												<span>{{ popular?.name }}</span>
											</div>
										</ng-container>
										<ng-container
											*ngFor="
												let subCountries of countries?.arrCountries
													| filter: searchCountries;
												trackBy: trackByFn
											"
										>
											<div (click)="selectCountries(subCountries)">
												<span>{{ subCountries?.name }}</span>
											</div></ng-container
										>
									</li>
								</ul>
							</div>
							<div class="icon">
								<i-feather name="search"></i-feather>
							</div>
							<div class="close-icon is-main" (click)="clearCountries()">
								<i-feather name="x"></i-feather>
							</div>
						</div>
					</div>
					<div class="control is-autocomplete">
						<div
							id="materials-autocpl-wrapper"
							class="control is-wrapper has-margin"
						>
							<div *ngIf="architect" class="input" (click)="dropDown(2)">
								<span>
									{{ architect }}
								</span>
							</div>
							<input
								*ngIf="!architect"
								type="text"
								class="input is-sm no-radius is-fade"
								placeholder="Architect"
								[(ngModel)]="searchArchitect"
								(click)="dropDown(2)"
							/>
							<div
								class="
									material-wrap
									easy-autocomplete-container
									scroll-customize
								"
								id="eac-container-users-autocpl"
							>
								<ul [ngClass]="dropDownId == 2 ? 'is-active' : 'is-hidden'">
									<li
										class="categories-item"
										*ngFor="
											let architect of arrAllArchitect
												| filter: searchArchitect;
											trackBy: trackByFn
										"
									>
										<!-- <span
											*ngIf="architect?.isMostPopular; else noMost"
											class="is-6"
											>Most Popular</span>
										<ng-template #noMost>
											<span class="is-6">Alphabetically</span>
                    </ng-template> -->
										<ng-container
											*ngFor="
												let popular of architect?.arrPopular;
												trackBy: trackByFn
											"
										>
											<div (click)="selectArchitect(popular)">
												<span>{{ popular?.title }}</span>
											</div>
										</ng-container>
										<ng-container
											*ngFor="
												let subArchitect of architect?.arrArchitects;
												trackBy: trackByFn
											"
										>
											<div (click)="selectArchitect(subArchitect)">
												<span>{{ subArchitect?.title }}</span>
											</div></ng-container
										>
									</li>
								</ul>
							</div>
							<div class="icon">
								<i-feather name="search"></i-feather>
							</div>
							<div class="close-icon is-main" (click)="clearArchitect()">
								<i-feather name="x"></i-feather>
							</div>
						</div>
					</div>
					<div class="control is-autocomplete">
						<div
							id="materials-autocpl-wrapper"
							class="control is-wrapper has-margin"
						>
							<div *ngIf="year" class="input" (click)="dropDown(3)">
								<span>
									{{ year }}
								</span>
							</div>
							<input
								*ngIf="!year"
								type="text"
								class="input is-sm no-radius is-fade"
								placeholder="Year"
								[(ngModel)]="searchYear"
								(click)="dropDown(3)"
							/>
							<div
								class="
									material-wrap
									easy-autocomplete-container
									scroll-customize
								"
								id="eac-container-users-autocpl"
							>
								<ul [ngClass]="dropDownId == 3 ? 'is-active' : 'is-hidden'">
									<li
										class="categories-item"
										*ngFor="
											let years of arrYears | filter: searchYear;
											trackBy: trackByFn
										"
										(click)="selectYear(years)"
									>
										<div>
											<span>{{ years }}</span>
										</div>
									</li>
								</ul>
							</div>
							<div class="icon">
								<i-feather name="search"></i-feather>
							</div>
							<div class="close-icon is-main" (click)="clearYear()">
								<i-feather name="x"></i-feather>
							</div>
						</div>
					</div>

					<div class="control is-autocomplete">
						<div
							id="materials-autocpl-wrapper"
							class="control is-wrapper has-margin"
						>
							<div *ngIf="material" class="input" (click)="dropDown(4)">
								<span>
									{{ material }}
								</span>
							</div>
							<input
								*ngIf="!material"
								type="text"
								class="input is-sm no-radius is-fade"
								placeholder="Material"
								[(ngModel)]="searchMaterial"
								(click)="dropDown(4)"
							/>
							<div
								class="
									material-wrap
									easy-autocomplete-container
									scroll-customize
								"
								id="eac-container-users-autocpl"
							>
								<ul [ngClass]="dropDownId == 4 ? 'is-active' : 'is-hidden'">
									<li
										class="categories-item"
										*ngFor="
											let material of arrMaterials | filter: searchMaterial;
											trackBy: trackByFn
										"
										(click)="selectMaterial(material)"
									>
										<div>
											<span>{{ material?.title }}</span>
										</div>
									</li>
								</ul>
							</div>
							<div class="icon">
								<i-feather name="search"></i-feather>
							</div>
							<div class="close-icon is-main" (click)="clearMaterial()">
								<i-feather name="x"></i-feather>
							</div>
						</div>
					</div>

					<div class="control is-autocomplete">
						<div
							id="materials-autocpl-wrapper"
							class="control is-wrapper has-margin"
						>
							<div class="input" (click)="dropDown(5)">
								<span *ngIf="!color; else nocolor">
									{{ placeHolderColor }}
								</span>
								<ng-template #nocolor>
									<span>
										{{ titleColor }}
									</span></ng-template
								>
							</div>
							<div
								class="
									material-wrap
									easy-autocomplete-container
									scroll-customize
								"
								id="eac-container-users-autocpl"
							>
								<div class="item-color-setting" *ngIf="dropDownId == 5">
									<div
										class="group-item"
										*ngFor="
											let color of arrColor;
											trackBy: trackByFn;
											let i = index
										"
									>
										<button
											mat-button
											class="btn-item-color"
											(click)="changeColor(color)"
											[ngStyle]="{'background-color': color.color}"
										>
											<img src="assets/img/check.png" *ngIf="color.isActive" />
										</button>
									</div>
								</div>
							</div>
							<!-- <div class="icon">
                <i-feather name="search"></i-feather>
              </div> -->
							<div class="close-icon is-main" (click)="clearColor()">
								<i-feather name="x"></i-feather>
							</div>
						</div>
					</div>

					<div class="control is-autocomplete">
						<div
							id="materials-autocpl-wrapper"
							class="control is-wrapper has-margin"
						>
							<div class="input" (click)="dropDown(7)">
								<span *ngIf="!isApply; else noisArea">
									{{ titleArea }}
								</span>
								<ng-template #noisArea>
									<span> {{ areaMin }} - {{ areaMax }} </span></ng-template
								>
							</div>

							<div
								class="
									material-wrap
									easy-autocomplete-container
									scroll-customize
								"
								id="eac-container-users-autocpl"
							>
								<div class="gr-area" *ngIf="dropDownId == 7">
									<div class="area-btn columns is-mobile is-desktop">
										<button
											class="button btn-wrap"
											[ngClass]="btnAreaId == 0 ? 'is-info' : ''"
											(click)="btnAreaId == 0 ? '' : changeArea(0)"
										>
											<span>ft <sup>2</sup></span>
										</button>
										<button
											class="button btn-wrap"
											[ngClass]="btnAreaId == 1 ? 'is-info' : ''"
											(click)="btnAreaId == 1 ? '' : changeArea(1)"
										>
											<span>m<sup>2</sup></span>
										</button>
									</div>
									<div class="area-wrap columns">
										<input
											class="input is-info area-btn"
											type="number"
											name=""
											id=""
											[(ngModel)]="areaMin"
										/>
										<span></span>
										<input
											class="input is-info area-btn"
											type="number"
											name=""
											id=""
											[(ngModel)]="areaMax"
										/>
									</div>
									<div class="btn-apply">
										<button
											class="button is-info is-active"
											(click)="applyArea()"
										>
											Apply
										</button>
									</div>
								</div>
							</div>
							<!-- <div class="icon">
                <i-feather name="search"></i-feather>
              </div> -->
							<div class="close-icon is-main" (click)="clearArea()">
								<i-feather name="x"></i-feather>
							</div>
						</div>
					</div>
				</div>
				<div class="right-section has-slimscroll">
					<div class="modal-uploader">
						<div id="actions" class="columns is-multiline no-mb">
							<div class="column is-12">
								<span
									class="
										button
										has-icon
										is-solid
										grey-button
										fileinput-button
										file-btn
									"
								>
									<label for="upload-1">
										<i-feather name="plus"></i-feather>
										<input
											id="upload-1"
											type="file"
											(change)="detectFiles($event)"
											multiple
											accept="image/png, image/jpeg"
											class="input-file"
										/>
									</label>
								</span>
								<button type="submit" class="button start is-hidden">
									<span>Upload</span>
								</button>
								<button
									type="reset"
									class="button is-solid grey-button cancel"
									(click)="deleteAll($event)"
								>
									<span>Clear all</span>
								</button>
								<span class="file-count">
									<span id="modal-uploader-file-count">{{
										arrImg?.length
									}}</span>
									file(s) selected
								</span>
							</div>
						</div>
						<div class="columns is-multiline" id="previews">
							<ng-container *ngFor="let img of arrImg; let index = index">
								<div id="template" class="column is-4 is-template">
									<div class="preview-box">
										<!-- This is used as the file preview template -->
										<div
											class="remove-button remove-btn"
											data-dz-remove
											(click)="deleteImg(index)"
										>
											<i class="mdi mdi-close"></i>
										</div>
										<div class="dz-preview-multiple group-photo">
											<span class="preview">
												<img [src]="img?.url ? img?.url : imgDefault" alt />
											</span>
											<span class="btn-default-photo">
												<a
													class="button is-rounded is-phantom accent-button"
													[ngClass]="img.isDefault == true ? 'is-default' : ''"
													(click)="clickSetDefaultPhoto(img, index)"
													>Default</a
												></span
											>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<!-- Dropdown menu -->
				<div
					class="
						dropdown
						is-up is-spaced is-modern is-neutral is-right
						dropdown-trigger
					"
				>
					<div>
						<button class="button" aria-haspopup="true">
							<i-feather class="main-icon" name="smile"></i-feather>
							<span>Friends</span>
							<i-feather class="caret" name="chevron-down"></i-feather>
						</button>
					</div>
					<div class="dropdown-menu" role="menu">
						<div class="dropdown-content">
							<a href="#" class="dropdown-item">
								<div class="media">
									<i-feather name="globe"></i-feather>
									<div class="media-content">
										<h3>Public</h3>
										<small>Anyone can see this publication.</small>
									</div>
								</div>
							</a>
							<a class="dropdown-item">
								<div class="media">
									<i-feather name="users"></i-feather>
									<div class="media-content">
										<h3>Friends</h3>
										<small>only friends can see this publication.</small>
									</div>
								</div>
							</a>
							<a class="dropdown-item">
								<div class="media">
									<i-feather name="user"></i-feather>
									<div class="media-content">
										<h3>Specific friends</h3>
										<small>Don't show it to some friends.</small>
									</div>
								</div>
							</a>
							<hr class="dropdown-divider" />
							<a class="dropdown-item">
								<div class="media">
									<i-feather name="lock"></i-feather>
									<div class="media-content">
										<h3>Only me</h3>
										<small>Only me can see this publication.</small>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
				<button
					*ngIf="isPage && !isGroup"
					type="button"
					class="button is-solid accent-button close-modal"
					(click)="createAlbumPage()"
				>
					Create album
				</button>
				<button
					*ngIf="!isPage && !isGroup"
					type="button"
					class="button is-solid accent-button close-modal"
					(click)="create()"
				>
					Create album
				</button>
				<button
					*ngIf="isGroup"
					type="button"
					class="button is-solid accent-button close-modal"
					(click)="createAlbumGroup()"
				>
					Create album
				</button>
			</div>
		</div>
	</div>
</div>
