import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FeedService } from 'src/app/services/feed.service';
import { PagesService } from 'src/app/services/pages.service';
import { ProfileService } from 'src/app/services/profile.service';
import * as environment from 'src/environments/environment';

declare var jQuery: any;
declare var window: any;

declare function initDropdowns(): any;
declare function initModals(): any;

import { initFancyBox } from '../js/fancybox';

@Component({
  selector: 'app-pages-profile',
  templateUrl: './pages-profile.component.html',
  styleUrls: ['./pages-profile.component.scss'],
})
export class PagesProfileComponent implements OnInit {
  @Input() dataPage;
  @Input() dataAdmin;
  @Input() isAdmin;

  dataPageReceive;
  dataAdminReceive;
  environment;
  constructor(
    private pageService: PagesService,
    private profileService: ProfileService,
    private cdr: ChangeDetectorRef,
    private feedService: FeedService
  ) {
    this.environment = environment.environment.apiUrl;
  }

  dataPosts = [];
  dataComments = [];
  dataReplies = [];
  dataLikes = [];
  user;
  token;
  notifiModel;

  // filter get post
  skip;
  limit;
  // filter get comment
  skipCmt;
  limitCmt;
  // filter get replies
  skipReply;
  limitReply;

  isLoading: boolean = false;
  ngOnInit(): void {
    // model of post FanPage
    this.skip = 0;
    this.limit = 3;

    // model of post user
    this.skipReply = 0;
    this.limitReply = 3;
    this.skipCmt = 0;
    this.limitCmt = 3;

    this.getUser();
    this.getPosts();

    // when submit post
    this.pageService.isSubmit.subscribe((result) => {
      if (result.status == 'success') {
        this.dataPosts = [];
        this.getPosts();
        setTimeout(() => {
          this.isLoading = true;
        }, 500);
      }
    });
    initDropdowns();
    this.initChangeTexts();
    setTimeout(() => {
      this.isLoading = true;
    }, 500);
  }
  ngOnChanges() {
    this.isAdmin = this.isAdmin;
    this.dataPageReceive = this.dataPage;
    this.dataAdminReceive = this.dataAdmin;
  }
  trackByFn(index, item) {
    return index;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  //#region FUNCTION search user of comment
  arrUser = [];
  checkKeySearch;
  arrName = [];
  searchUser(username) {
    if (username != undefined || username != null) {
      (function ($) {
        $(document).ready(function () {
          $('.tag-fr').focusout(function () {
            var element = $(this);
            if (!element.text().replace(' ', '').length) {
              element.empty();
            }
          });
        });
      })(jQuery);
      var start = /@/gi; // @ Match
      var word = /@(\w+)/gi; //@abc Match
      if (username.indexOf('@') > -1) {
        var go = username.match(start); //Content Matching @
        this.arrName = username.match(word); //Content Matching @abc
        if (go.length > 0) {
          if (this.arrName) {
            let count = 0;
            if (this.arrName.length > 1) {
              count = this.arrName.length - 1;
            }
            let usernameFillter = this.arrName[count].toString().slice(1);
            this.pageService
              .searchUser({ search: usernameFillter })
              .subscribe((arrUser) => {
                if (arrUser.status == 'success') {
                  this.arrUser = arrUser.data;
                }
              });
          } else {
            this.arrUser = [];
          }
        }
      }
    }
  }
  //#endregion

  //#region GET info user
  getUser() {
    this.token = localStorage.getItem('token');
    if (this.token) {
      this.profileService.getProfile().subscribe((user) => {
        if (user.status == 'success') {
          this.user = user['data'];
        }
      });
    }
  }
  //#endregion

  //#region FUNCTION Open comment
  openComment(postCurrnet) {
    postCurrnet.arrComment = [];
    postCurrnet.filterComment = { skip: 0, limit: 3 };
    this.getComments(postCurrnet, postCurrnet.filterComment);
  }
  //#endregion

  //#region FUNCTION Close comment
  closeComment() {
    (function ($) {
      $('.close-comments').on('click', function (e) {
        $(this)
          .addClass('is-active')
          .closest('.card')
          .find('.comments-wrap')
          .addClass('is-hidden');
        var jump = $(this).closest('.is-post');
        var new_position = $(jump).offset();
        $('html, body')
          .stop()
          .animate({ scrollTop: new_position.top - 70 }, 500);
        e.preventDefault();
        setTimeout(function () {
          $('.emojionearea-editor').val('');
        }, 400);
      });
    })(jQuery);
  }
  //#endregion

  //#region ---------------------------- Post ofPage--------------------------

  //#region FUNCTION GET arr posts of Page
  initGetPost(dataPosts) {
    if (dataPosts.length > 0) {
      let filter = {
        limit: this.limitCmt,
        skip: this.skipCmt,
      };

      let filterView = {
        isActive: false,
        isViewMore: false,
      };

      for (let post of dataPosts) {
        // get like post
        this.getLikeByPostIdPage(post);
        post.isLiked = false;
        post.arrLike = [];

        //content post
        this.initPostContent(post);

        // get comment
        post.filterView = filterView;
        post.filterComment = filter;
        if (post.postContent) {
          post.postContent = post.postContent.replace(/\n/g, '<br />');
        }

        post.postShare = post.postShare || [];
        this.getSharePage(post);
        this.getComments(post, post.filterComment)
      }
      return dataPosts;
    }
  }

  initPostContent(post) {
    if (post.postContent) {
      post.postContent = post.postContent.replace(/\n/g, '<br />');
      const lengthTxt = post.postContent.length;
      if (lengthTxt > 150) {
        post.postContentCut = post.postContent.substr(0, 150) + '...';
        post.isLength = true;
        return post;
      }
      post.isLength = false;
      return post;
    }
  }

  getPosts() {
    let requestPost = {
      postPageId: this.dataPageReceive._id,
      skip: this.skip,
      limit: this.limit,
    };
    this.pageService
      .getMorePost(requestPost)
      .subscribe(async (resultpostpost) => {
        if (resultpostpost.status == 'success') {
          this.dataPosts = this.initGetPost(resultpostpost.data);
        }
      });
  }
  //#endregion

  //#region FUNCTION Get like post of Page

  getLikeByPostIdPage(post) {
    this.pageService.getLikeByPostId(post._id).subscribe((result) => {
      if (result.status == 'success') {
        post.arrLike = [];
        post.arrLike = result.data;
        this.checkLikePostUserLogin(post);
      }
    });
  }
  checkLikePostUserLogin(post) {
    let checkLikePost = post.arrLike.find(
      (item) => item.userId._id === this.user._id
    );
    post.isLiked = checkLikePost ? true : false;
  }
  //#endregion

  //#region FUNCTION Get comment of Page
  getComments(post, filterComment) {
    let pageId;
    pageId = post.postPageId ? post.postPageId._id : null;
    let requestComment = {
      postId: post._id,
      skip: filterComment.skip,
      limit: filterComment.limit,
      pageId: pageId,
    };
    post.arrComment = post.arrComment || [];
    post.countComment = post.countComment || 0;
    post.commentTagUser = post.commentTagUser || [];
    let filterReplies = {
      limit: 3,
      skip: 0,
    };
    let filterView = {
      isActive: false,
      isViewMore: false,
    };
    this.getCommentsPage(requestComment, post, filterReplies, filterView);
  }

  getCommentsPage(requestComment, post, filterReplies, filterView) {
    this.pageService
      .getCommentPostInPage(requestComment)
      .subscribe((result) => {
        if (result.status == 'success') {
          this.initComment(result, post, filterReplies, filterView);
        }
      });
  }

  initComment(result, post, filterReplies, filterView) {
    post.countComment = result.count;
    post.arrComment = result.data.concat(post.arrComment);
    if (post.arrComment.length > 0) {
      if (post.countComment > post.filterComment.skip) {
        post.filterView.isActive = true;
        post.filterView.isViewMore = true;
      }
      for (let comment of post.arrComment) {
        //filter get replies from comment Id
        if (comment.filterReplies && comment.filterReplies.skip) {
          comment.filterReplies.skip = filterReplies.skip;
        }
        if (comment.filterReplies && comment.filterReplies.limit) {
          comment.filterReplies.limit = filterReplies.limit;
        }
        if (comment.filterView && comment.filterView.isActive) {
          comment.filterView.isActive = filterView.isActive;
        }
        if (comment.filterView && comment.filterView.isMore) {
          comment.filterView.isMore = filterView.isMore;
        }
        comment.arrReplies = comment.arrReplies || [];
        comment.countReplies = comment.countReplies || 0;
        comment.isLiked = false;
        comment.arrLike = comment.arrLike || [];
        this.getReplyByCmtIdPage(comment, comment.filterReplies);
        this.getLikeByCommentId(comment);
        this.checkCommentForUser(comment);
      }
    }
  }
  checkCommentForUser(comment) {
    comment.isUser = comment.userId._id == this.user._id ? true : false;
  }

  //#region FUNCTION GET replies of post in Fanpages
  getReplyByCmtIdPage(comment, filter) {
    let requestReply = {
      id: comment._id,
      skip: filter && filter.skipReply ? filter.skipReply : undefined,
      limit: filter && filter.limitReply ? filter.limitReply : undefined,
    };
    this.pageService
      .getReplyByCommentId(requestReply)
      .subscribe((dataReplies) => {
        if (dataReplies.status == 'success') {
          let arrReply = dataReplies.data;
          comment.countReply = dataReplies.count;
          comment.arrReplies = arrReply.concat(comment.arrReplies);
          this.getLikeByRepliesIdPage(comment);
          comment.arrReplies = this.getUnique(comment.arrReplies, '_id');
          if (comment.filterReplies && comment.countReply > comment.filterReplies.limit) {
            comment.filterView.isActive = true;
            comment.filterView.isViewMore = true;
          }
        }
      });
  }
  getLikeByRepliesIdPage(comment) {
    for (let replies of comment.arrReplies) {
      replies.isLikeReplies = false;
      replies.arrLikeReplies = replies.arrLikeReplies || [];
      this.pageService.getLikeByReplyId(replies._id).subscribe((result) => {
        if (result.status == 'success') {
          replies.arrLikeReplies = result.data;
          this.checkLikeReply(replies);
        }
      });
    }
  }
  checkLikeReply(replies) {
    replies.isUser = replies.userId._id == this.user._id ? true : false;
    let checkLikeReplies = replies.arrLikeReplies.find(
      (item) => item.userId._id === this.user._id
    );
    replies.isLikeReplies = checkLikeReplies ? true : false;
  }
  // check array duplicate
  getUnique(arr, comp) {
    const unique = arr
      .map((e) => e[comp])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);
    return unique;
  }
  //#endregion
  //#endregion

  //#region FUNCTION Get more comment of Page
  viewMoreComment(post) {
    post.filterComment.skip = Number(
      post.filterComment.skip + post.filterComment.limit
    );
    this.getComments(post, post.filterComment);
  }
  //#endregion

  //#region FUNCTION Get more post of Page
  getMorePosts() {
    this.skip = this.skip + this.limit;
    let filter = {
      skip: this.skip,
      limit: this.limit,
    };
    this.getMoresPostPage(filter);
  }

  getMoresPostPage(filter) {
    this.pageService.getPostPage(filter).subscribe((result) => {
      if (result.status == 'success') {
        let arrPostNew = result.data;
        if (Array.isArray(arrPostNew) == true) {
          if (arrPostNew.length > 0) {
            let arrMorePostPage = this.initGetPost(result.data);
            this.dataPosts = this.dataPosts.concat(arrMorePostPage);
          }
        }
      }
    });
  }
  //#endregion

  //#region FUNCTION Create like post of Page
  createLikePost(post) {
    let reqLikePost = {
      postId: post._id,
      pageId: post.postPageId._id,
      type: 0,
    };
    this.createLikePostPage(reqLikePost, post);
  }

  createLikePostPage(reqLikePost, post) {
    this.pageService.createLikePagePost(reqLikePost).subscribe((like) => {
      if (like.status == 'success') {
        this.getLikeByPostIdPage(post);
      }
    });
  }

  //#region FUNCTION unlike post of page
  unLikePost(post) {
    let requestPost = {
      postId: post._id,
    };
    this.unlikePostPage(requestPost, post);
  }

  unlikePostPage(requestPost, post) {
    this.pageService.unLikePagePost(requestPost).subscribe((result) => {
      if (result.status == 'success') {
        post.isLiked = false;
        this.getLikeByPostIdPage(post);
      }
    });
  }
  //#endregion

  //#endregion

  //#region Detect files imgage in comment of Page
  detectFile(event, indexPostUser, post) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        if (file.type.indexOf('image') > -1) {
          post.format = 'image';
        } else if (file.type.indexOf('video') > -1) {
          post.format = 'video';
        }
        reader.onload = (e: any) => {
          document.getElementById(
            `${indexPostUser}cmt-image-user`
          ).style.display = 'block';
          if (file.type.indexOf('image') > -1) {
            post.contentImg = e.target.result;
          } else if (file.type.indexOf('video') > -1) {
            post.contentVideo = e.target.result;
          }
        };
      }
    }
  }
  //#endregion

  //#region FUNCTION Create comment of  Page
  tagUser(user, indexPostUser, post) {
    let fullname = user.firstname + user.lastname;
    post.commentTagUser.push(user._id);
    let txtContent = document.getElementById(
      `${indexPostUser}tag-friends`
    ).innerHTML;
    let count = 0;
    if (this.repeatTag(this.arrName) > 0) {
      let lengthLast = this.arrName[this.arrName.length - 1].length;
      txtContent = txtContent.slice(0, txtContent.length - lengthLast);
    } else {
      if (this.arrName.length > 1) {
        count = this.arrName.length - 1;
      }
      let indexCount = txtContent.indexOf(this.arrName[count], 0);
      txtContent = txtContent.slice(0, indexCount);
    }
    document.getElementById(`${indexPostUser}tag-friends`).innerHTML =
      txtContent;
    this.changeCommentContent(fullname, indexPostUser);
    this.arrUser = [];
  }
  repeatTag(arr) {
    if (arr.length > 1) {
      let count = 0;
      for (let i = 0; i < arr.length; i++) {
        for (let j = 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            return (count = count + 1);
          }
        }
      }
    }
  }
  model;
  arrSpan = [];
  word;
  changeCommentContent(commentContent, index) {
    (function ($) {
      $(document).ready(function () {
        $(`#${index}tag-friends`).append(
          `<mark contenteditable="false">${commentContent}</mark>`
        );
      });
    })(jQuery);
  }

  createComment(post, indexPostUser) {
    let commentContentUser = document.getElementById(
      `${indexPostUser}tag-friends`
    ).innerHTML;
    let pageId;
    if (post.postPageId) {
      pageId = post.postPageId._id;
    }
    let requestComment = {
      pageId: pageId,
      postId: post._id,
      commentContent: commentContentUser,
      commentImg: post.contentImg,
      commentTag: post.commentTagUser,
      commentVideo: post.contentVideo,
    };

    this.createCmtPostPage(requestComment, indexPostUser, post);
  }

  createCmtPostPage(requestComment, indexPostUser, post) {
    this.pageService
      .createCommentPagePost(requestComment)
      .subscribe((result) => {
        let cmtNew;
        if (result.status == 'success') {
          cmtNew = result.data;
          this.getCommentByIdPage(cmtNew, post);
          document.getElementById(`${indexPostUser}tag-friends`).innerHTML = '';

          post.contentImg = undefined;
          post.contentVideo = undefined;
        }
      });
  }

  //#region FUNCTION for post of Page
  getCommentByIdPage(comment, post) {
    this.pageService.getCommentById(comment._id).subscribe((result) => {
      if (result.status == 'success') {
        let commnetNews = result.data;
        this.insertComment(commnetNews, post);
      }
    });
  }

  insertComment(comment, post) {
    let filters = {
      limit: this.limitReply,
      skip: this.skipReply,
    };
    let filterView = {
      isActive: false,
      isViewMore: false,
    };
    comment.arrLike = comment.arrLike || [];
    comment.isLiked = false;
    comment.filterReplies = filters;
    comment.arrReplies = comment.arrReplies || [];
    comment.filterView = filterView;
    comment.countReply = comment.countReply || 0;
    post.arrComment = post.arrComment || [];
    post.arrComment.push(comment);
    post.countComment = Number(post.countComment + 1);
  }

  //#endregion

  //#endregion

  //#region FUNCTION delete comment page
  deleteComment(post, comment, indexComment) {
    this.deleteCommentPage(post, comment, indexComment);
  }
  deleteCommentPage(comment, post, indexComment) {
    this.pageService.deleteCommentPagePost(comment._id).subscribe((result) => {
      if (result.status == 'success') {
        post.arrComment.splice(indexComment, 1);
      }
    });
  }

  //#endregion

  //#region FUNCTION delete replies page
  deleteReplies(post, comment, reply, indexReplies) {
    this.deleteRepliesPage(comment, reply, indexReplies);
  }

  deleteRepliesPage(comment, reply, indexReplies) {
    this.pageService.deleteReplyById(reply._id).subscribe((replies) => {
      if (replies.status == 'success') {
        comment.arrReplies.splice(indexReplies, 1);
      }
    });
  }
  //#endregion

  //#region FUNCTION Create like comment of Page
  createLikeComment(post, comment) {
    let reqLikeCmt = {
      postId: post._id,
      commentId: comment._id,
      type: 1,
    };
    this.createLikeCmtPage(reqLikeCmt, comment);
  }
  //#region FUNCTION create like comment of User

  checkLikeCmtUser(comment) {
    let isLikeUser = comment.arrLike.find(
      (like) => like.userId._id === this.user._id
    );
    comment.isLiked = isLikeUser ? true : false;
  }

  //#endregion

  //#region FUNCTION create like cmt of Page
  createLikeCmtPage(reqLikeCmt, comment) {
    this.pageService.createLikeComment(reqLikeCmt).subscribe((likeCmt) => {
      if (likeCmt.status == 'success') {
        this.getLikeByCommentId(comment);
      }
    });
  }

  getLikeByCommentId(comment) {
    this.pageService.getLikeByCommentId(comment._id).subscribe((result) => {
      if (result.status == 'success') {
        comment.arrLike = [];
        comment.arrLike = result.data;
        this.checkLikeCmtUser(comment);
      }
    });
  }

  getLikeCommentByPage(comment) {
    this.getLikeByCommentId(comment);
  }
  //#endregion

  //#endregion

  //#region FUNCTION Unlike comment of Page
  unlikeComment(comment, post) {
    let requsetUnlike = {
      commentId: comment._id,
    };
    this.pageService.unLikeComment(requsetUnlike).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeCommentByPage(comment);
      }
    });
  }
  //#endregion

  //#region FUNCTION Click reply of comment for users
  clickReply(username, comment, indexPostUser, indexCommentUser) {
    console.log(username);

    document.getElementById(`${indexPostUser}tag-friends`).focus();
    let post = this.dataPosts[indexPostUser];
    post.commentReplyByUser = comment;
    post.indexCommentReplyByUser = indexCommentUser;
    post.arrRepliesTemporary = post.arrRepliesTemporary || [];
    post.arrRepliesTemporary.push(username);

    let fullname = username.firstname + username.lastname;
    if (this.user._id != username._id) {
      this.changeCommentContent(fullname, indexPostUser);
    }
  }
  clearReply(post) {
    post.commentReplyByUser = undefined;
    post.indexCommentReplyByUser = undefined;
    post.arrRepliesTemporary = [];
  }
  createReply(post, indexPostUser) {
    let replyContentUser = document.getElementById(
      `${indexPostUser}tag-friends`
    ).innerHTML;
    let requestReply = {
      pageId: post.postPageId._id,
      postId: post._id,
      commentId: post.commentReplyByUser._id,
      replyContent: replyContentUser,
      replyImg: post.contentImg,
    };
    this.createRepliesPage(requestReply, indexPostUser, post);
  }

  createRepliesPage(requestReply, indexPostUser, post) {
    this.pageService.createReplyComment(requestReply).subscribe((result) => {
      if (result.status == 'success') {
        post.arrRepliesTemporary = [];
        let replies = result.data;
        this.getRepliesById(replies, post);
        document.getElementById(`${indexPostUser}tag-friends`).innerHTML = '';
        if (document.getElementById(`${indexPostUser}cmt-img`)) {
          document.getElementById(`${indexPostUser}cmt-img`).style.display =
            'none';
        }
        post.contentImg = '';
      }
    });
  }

  //#region FUNCTION replies for page
  getRepliesById(replies, post) {
    this.pageService.getReplyById(replies._id).subscribe((dataReply) => {
      if (dataReply.status == 'success') {
        let commentCurrent = post.arrComment[post.indexCommentReplyByUser];
        commentCurrent.arrReplies = commentCurrent.arrReplies || [];
        commentCurrent.arrReplies.push(dataReply.data);
        post.countComment = Number(post.countComment + 1);
      }
    });
  }

  //#endregion

  //#endregion

  //#region FUNCTION view more replies of comment User
  viewMoreRepliesBy(post, indexPostCurrent, comment) {
    this.viewMoresRepliesPage(indexPostCurrent, comment);
  }

  viewMoresRepliesPage(indexPostCurrent, comment) {
    comment.filterReplies.skip =
      comment.filterReplies.skip + comment.filterReplies.limit;
    comment.indexPost = indexPostCurrent;
    let request = {
      id: comment._id,
      skip: comment.filterReplies.skip,
      limit: comment.filterReplies.limit,
    };
    this.pageService.getReplyByCommentId(request).subscribe((result) => {
      if (result.status == 'success') {
        this.insertReplies(comment, result);
      }
    });
  }

  insertReplies(comment, result) {
    comment.countReply = result.count;
    comment.arrReplies = result.data.concat(comment.arrReplies);
    comment.arrReplies.forEach((replies) => {
      this.checkRepliesUser(replies);
    });
  }
  checkRepliesUser(replies) {
    return (replies.isUser =
      replies.userId._id == this.user._id ? true : false);
  }

  //#endregion

  //#region FUNCTION Unlike post of User/Page
  unLikeReplies(post, replies) {
    let reqUnlike = {
      replyId: replies._id,
    };
    this.unlikeRepliesPage(reqUnlike, replies);
  }

  unlikeRepliesPage(reqUnlike, replies) {
    this.pageService.unLikeReply(reqUnlike).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByReplyIdPage(replies);
      }
    });
  }
  //#endregion

  //#region FUNCTION Create like reply of Page
  createLikeReplies(post, comment, replies) {
    let reqLikeReplies = {
      postId: post._id,
      commentId: comment._id,
      replyId: replies._id,
      type: 2,
      pageId: post.postPageId._id,
    };
    this.createLikeRepliesPage(reqLikeReplies, replies, post);
  }

  //#endregion

  //#region FUNCTION Create like of page
  createLikeRepliesPage(reqLikeReplies, replies, post) {
    this.pageService.createLikeReply(reqLikeReplies).subscribe((result) => {
      if (result.status == 'success') {
        this.getLikeByReplyIdPage(replies);
      }
    });
  }
  getLikeByReplyIdPage(replies) {
    this.pageService.getLikeByReplyId(replies._id).subscribe((result) => {
      if (result.status == 'success') {
        replies.arrLikeReplies = result.data;
        this.checkLikeReply(replies);
      }
    });
  }
  //#endregion

  //#endregion

  //#region FUNCTION sort object in Array
  sortFunction(a, b) {
    var dateA = new Date(a.createdAt).getTime();
    var dateB = new Date(b.createdAt).getTime();
    return dateB > dateA ? 1 : -1;
  }
  //#endregion

  //#region FUNCTION change text in div attribute contenteditTable
  initChangeTexts() {
    (function ($) {
      $(document).on('paste', '[contenteditable]', function (e) {
        var content;
        e.preventDefault();

        if (window.clipboardData) {
          content = window.clipboardData.getData('Text');
          if (window.getSelection) {
            var selObj = window.getSelection();
            var selRange = selObj.getRangeAt(0);
            selRange.deleteContents();
            selRange.insertNode(document.createTextNode(content));
          }
        } else if (e.originalEvent.clipboardData) {
          content = (e.originalEvent || e).clipboardData.getData('text/plain');
          document.execCommand('insertText', false, content);
        }
      });
    })(jQuery);
  }
  //#endregion

  //#region Open initDropdown
  openInitDropDowns() {
    //initDropdowns();
  }
  //#endregion

  //#region Delete image of comment in post
  closeImg(indexPostUser, post) {
    document.getElementById(`${indexPostUser}cmt-image`).style.display = 'none';
    if (post.format === 'image') {
      post.contentImg = undefined;
      post.format = undefined;
      return;
    }
    if (post.format === 'video') {
      post.contentImg = undefined;
      post.format = undefined;
      return;
    }
  }
  //#endregion

  //#endregion

  //#region JQUERY ZOOM IMAGE USING FANCYBOX
  dataPost;
  openPostFancyBox(post) {
    initFancyBox();
    post.type = 1;
    this.dataPost = post;
    this.isActivePhoto = true;
  }
  //#endregion
  //#region @Output from pagesPhotoLightBoxComponents
  isActivePhoto: boolean = false;
  closeModal(event) {
    this.isActivePhoto = event;
  }
  //#endregion

  //#region Active page child [Recent], [Albums]
  isPageActive: boolean = false;
  clickPageChildActive(isPageActice) {
    this.isPageActive = isPageActice;
  }
  //#endregion

  //#region FUNCTION share post for user login
  sharePost(dataPost) {
    initModals();
    dataPost.type = 1;
    this.feedService.clickSharePost({ status: 'success', data: dataPost });
  }
  //#endregion

  //#region FUNCTION get share post
  getSharePage(post) {
    this.feedService.getSharePage(post._id).subscribe((result) => {
      if (result.status == 'success') {
        post.countShare = result.count;
        post.postShare = result.data;
      }
    });
  }
  //#endregion
  joinUrlVideo(url) {
    if (url && url.length > 0) {
      let host = 'https://' + environment.environment.s3SpacePrefix + '.';
      let link = url.replace(environment.environment.s3SpacePrefix + '/', '');
      let result = host.concat(link);
      return result;
    }
  }
  indexImg;
  getImage(index) {
    this.indexImg = index;
    this.isActivePhoto = true;
  }
}
