import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compose-add-dropdown',
  templateUrl: './compose-add-dropdown.component.html',
  styleUrls: ['./compose-add-dropdown.component.scss']
})
export class ComposeAddDropdownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
