import { Component, Input, OnInit } from '@angular/core';
import { PagesService } from 'src/app/services/pages.service';
import * as enviroment from '../../../../../environments/environment';

declare var jQuery: any;

declare function initDropdowns(): any;
declare function initLightboxEmojis(): any;

@Component({
  selector: 'app-page-album',
  templateUrl: './page-album.component.html',
  styleUrls: ['./page-album.component.scss'],
})
export class PageAlbumComponent implements OnInit {
  @Input() set dataPage(dataPage: any) {
    this.dataPages = dataPage;
    this.getAlbumByPageId(dataPage._id);
  }

  dataPages;
  enviroment;
  constructor(private pageServer: PagesService) {
    this.enviroment = enviroment.environment.apiUrl;
  }

  ngOnInit(): void {
    this.skip = 0;
    this.limit = 9;
    this.isActiveAlbum = false;
    this.isDelete = false;
  }

  //#region Load icon feather
  loadIconFeather() {

  }
  //#endregion

  //#region Get album by pageId;
  arrAlbumm = [];
  listShowAblum = [];
  getAlbumByPageId(pageId) {
    this.arrAlbumm = [];
    this.arrPhoto = [];
    this.listShowAblum = [];
    this.pageServer.getAlbumPageId(pageId).subscribe((result) => {
      if (result.status == 'success') {
        this.arrAlbumm = result.data;
        let lengthAlbum = this.arrAlbumm.length;
        if (lengthAlbum > 0) {
          this.insertArrImg(this.arrAlbumm, this.listShowAblum);
        }
      }
    });
  }
  insertArrImg(arrAlbumm, listShow) {
    let arrImg = [];
    for (let [index, img] of arrAlbumm.entries()) {
      img.isActive = false;
      if (arrImg.length < 4) {
        arrImg.push(img);
      }
      if (arrImg.length == 3) {
        listShow.push(arrImg);
        if (listShow.length > 0) {
          arrImg = [];
        }
      }
      if (arrImg.length < 3 && arrAlbumm.length / 3 != 0) {
        if (index == arrAlbumm.length - 1) {
          listShow.push(arrImg);
          arrImg = [];
        }
      }
    }
    this.loadIconFeather();
  }
  //#endregion

  //#region Get photo of album in fanpages
  skip;
  limit;
  arrPhoto = [];
  listShowPhoto = [];
  albumIdCurrent;
  getPhotoByAlbumId(albumId, skip, limit) {
    this.arrPhoto = [];
    this.listShowPhoto = [];
    this.albumIdCurrent = albumId;
    let requestData = {
      albumId: albumId,
      skip: skip,
      limit: limit,
    };
    this.pageServer.getPhotoByAlbumId(requestData).subscribe((result) => {
      if (result.status == 'success') {
        this.arrPhoto = result.data;
        if (this.arrPhoto.length > 0) {
          this.insertArrImg(this.arrPhoto, this.listShowPhoto);
        }
      }
      this.loadFancybox();
    });
  }
  //#endregion

  //#region Open/Close album
  isActiveAlbum;
  titleAlbum;
  openAlbum(album) {
    this.titleAlbum = album.albumName;
    this.getPhotoByAlbumId(album._id, this.skip, this.limit);
    this.isActiveAlbum = !this.isActiveAlbum;
  }
  closeAlbum() {
    this.isActiveAlbum = !this.isActiveAlbum;
    this.getAlbumByPageId(this.dataPages._id);
    this.loadIconFeather();
    this.skip = 0;
    this.limit = 5;
  }
  //#endregion

  //#region deleteImg of photo in album
  isDelete;
  photoId;
  openModalDelete(photoId) {
    this.loadIconFeather();
    this.photoId = photoId;
    this.isDelete = !this.isDelete;
  }
  closeModalDelete() {
    this.isDelete = !this.isDelete;
  }
  deleteImg() {
    this.isDelete = false;
    if (this.photoId) {
      this.pageServer.deletePhoto(this.photoId).subscribe((result) => {
        if (result.status == 'success') {
          let indexDetele = this.listShowPhoto.find(
            (index) => index._id === this.photoId
          );
          if (indexDetele) {
            this.listShowPhoto.splice(indexDetele, 1);
          }
        }
      });
    }
  }
  //#endregion

  //#region Load more photo of album
  skipCurrent = 0;
  getMorePhoto() {
    this.skip = this.skipCurrent + 5;
    this.skipCurrent = this.skip;
    this.getPhotoByAlbumId(this.albumIdCurrent, this.skip, this.limit);
    this.loadIconFeather();
  }
  //#endregion

  //#region JQUERY ZOOM IMAGE USING FANCYBOX
  loadFancybox() {
    (function ($) {
      $(document).ready(function () {
        if ($('[data-fancybox]').length) {
          $('[data-fancybox]').each(function () {
            if ($(this).attr('data-lightbox-type') == 'photo') {
              $(this).fancybox({
                baseClass: 'fancybox-custom-layout',
                keyboard: false,
                infobar: false,
                touch: {
                  vertical: false,
                },
                buttons: ['close', 'thumbs', 'share'],
                animationEffect: 'fade',
                transitionEffect: 'fade',
                preventCaptionOverlap: false,
                idleTime: false,
                gutter: 0,
                caption: false,
                afterShow: function (instance, current) {
                  initDropdowns();
                  initLightboxEmojis();
                },
              });
            }
          });
        }
      });
    })(jQuery);
  }
  //#endregion
}
