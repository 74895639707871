import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GroupService } from 'src/app/services/group/group.service';

@Component({
  selector: 'app-group-post-request',
  templateUrl: './group-post-request.component.html',
  styleUrls: ['./group-post-request.component.scss'],
})
export class GroupPostRequestComponent implements OnInit {
  dataUsers;
  isLoading: boolean = false;

  constructor(private groupService: GroupService) {}

  ngOnInit(): void {
    this.getPosts(this.groupId);
  }

  listPost = [];
  @Input() groupId;
  getPosts(groupId) {
    this.groupService.getPostRequestGroup(groupId).subscribe((result) => {
      if (result.status == 'success') {
        let arrPostNew = result.data;
        for (let post of arrPostNew) {
          post.isOpenComment = post.isOpenComment || {
            isActive: false,
          };
        }
        this.listPost = this.listPost.concat(arrPostNew);
      }
    });
  }

  //Use when a request post is approve or reject to show in UI
  spliceListPostRequest(id) {
    this.listPost.splice(
      this.listPost.findIndex((i) => {
        return i._id == id;
      }),
      1
    );
  }

  @Output() updateList = new EventEmitter(); // Output from post-request-group to update list post of group when add new post
  updateListPost(post) {
    this.updateList.emit(post);
  }
}
