import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-group-dialog',
  templateUrl: './notification-group-dialog.component.html',
  styleUrls: ['./notification-group-dialog.component.scss'],
})
export class NotificationGroupDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NotificationGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onYesClick() {
    let data = {
      status: 'yes',
    };
    this.dialogRef.close(data);
  }

  onNoClick() {
    let data = {
      status: 'no',
    };
    this.dialogRef.close(data);
  }
}
