import { Component, OnInit, Input } from '@angular/core';
import * as environment from 'src/environments/environment';
declare function initAboutGlider(): any;
@Component({
  selector: 'app-profile-about',
  templateUrl: './profile-about.component.html',
  styleUrls: ['./profile-about.component.scss'],
})
export class ProfileAboutComponent implements OnInit {
  @Input() infouser;
  @Input() friends;
  @Input() photos;
  @Input() videos;
  @Input() arrEducations;
  @Input() arrJobs;
  isActive;
  environment = environment.environment.apiUrl;
  constructor() { }

  ngOnInit(): void {
    this.isActive = 1;
    initAboutGlider();
  }

  clickActiveTab(id) {
    this.isActive = id;
    setTimeout(() => {
      initAboutGlider();
    }, 100);
  }
}
