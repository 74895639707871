import { Component, Input, OnInit } from '@angular/core';
import iziToast from 'izitoast';
import { GroupService } from 'src/app/services/group/group.service';
import * as environment from '../../../../environments/environment';
declare function initModals(): any;
@Component({
  selector: 'app-group-content',
  templateUrl: './group-content.component.html',
  styleUrls: ['./group-content.component.scss'],
})
export class GroupContentComponent implements OnInit {
  @Input() set dataGroup(dataGroup: any) {
    this.dataGroups = dataGroup;
    if (this.dataGroups.groupHidden) {
      let indexHidden = this.arrHidden[Number(this.dataGroups.groupHidden)];
      this.groupHidden = indexHidden.nameValue;
      this.groupHiddenIcon = indexHidden.icon;
      this.groupHiddenContent = indexHidden.content;
    }
    let index = this.arrDisplay[Number(this.dataGroups.groupDisplay)];
    this.groupDisplay = index.nameValue;
    this.groupDisplayIcon = index.icon;
    this.groupDisplayContent = index.content;
  }
  isAdminGroups;
  @Input() set isAdminGroup(isAdminn) {
    this.isAdminGroups = isAdminn;
  }

  dataUsers;
  @Input() set dataUser(dataUser) {
    this.dataUsers = dataUser;
  }

  isMembers: boolean;
  @Input() set isMember(isMember: boolean) {
    this.isMembers = isMember;
  }
  isHiddens: boolean;
  @Input() set isHidden(isHidden) {
    this.isHiddens = isHidden;
    if (this.isHiddens == true) {
      this.clickActivePage(3);
    }
  }

  arrPhotos: [];
  @Input() set arrPhoto(arrPhoto: []) {
    this.arrPhotos = arrPhoto;
  }

  //model group
  groupDisplay;
  groupDisplayIcon: string;
  groupDisplayContent;
  groupHidden;
  groupHiddenIcon: string;
  groupHiddenContent;
  dataGroups;
  environment;

  isPageActive = 1;

  // decalre array
  arrDisplay = [
    {
      value: 0,
      nameValue: 'Public',
      content: 'Anyone can see their team members and what they post.',
      icon: 'globe',
    },
    {
      value: 1,
      nameValue: 'Private',
      content: 'Only members can see group members and what they post.',
      icon: 'lock',
    },
  ];

  arrHidden = [
    {
      value: 0,
      nameValue: 'Visible',
      content: 'Anyone can find this group.',
      icon: 'eye',
    },
    {
      value: 1,
      nameValue: 'Hidden',
      content: 'Only members can find this group',
      icon: 'eye-off',
    },
  ];

  constructor(private groupService: GroupService) {
    this.environment = environment.environment.apiUrl;
  }

  isBtnAdmin: boolean; // click side bar of admin page
  ngOnInit(): void {
    this.checkSendedRequest();
    this.getMemberRequest();
    this.isBtnAdmin = false;
    initModals();
    this.groupService.btnAdmin.subscribe((event) => {
      if (event.event == 'click') {
        this.isBtnAdmin = true;
      }
    });
    this.groupService.isSeeAllPhoto.subscribe((result) => {
      if (result.status == 'success') {
        this.clickActivePage(4);
      }
    });

    this.groupService.isSeeMember.subscribe((result) => {
      if (result.status == 'success') {
        this.clickActivePage(2);
      }
    });
  }
  //#region CLICK activi page
  clickActivePage(id) {
    this.isPageActive = id;
  }

  isModal: boolean = false;
  openModal() {
    initModals();
    this.isModal = true;
  }
  closeModal(event) {
    this.isModal = event.isClose;
  }
  //#endregion

  //#region open menu side bar of admin group
  closeSidebar(event) {
    if (event.click == true) {
      let divId = document.getElementById('group-sidebar');
      divId.classList.remove('is-active');
      this.isBtnAdmin = false;
    }
  }
  openSideBar() {
    let divId = document.getElementById('group-sidebar');
    divId.classList.toggle('is-active');
    // this.groupService.clickBtnAdmin({ event: 'click' });
    this.isBtnAdmin = false;
  }
  //#endregion

  //#region Join group

  memberRequests = [];
  getMemberRequest() {
    let data = {
      groupId: this.dataGroups._id,
    };
    this.groupService.getMemberRequest(data).subscribe((result) => {
      this.memberRequests = result.data;
    });
  }

  createJoinGroup() {
    let data = {
      groupId: this.dataGroups._id,
      // receiverId: this.dataUser._id,
    };
    if (this.sendedRequest == false) {
      this.sendedRequest = true;
      this.groupService.createMemberRequest(data).subscribe((result) => {
        if (result.status == 'success') {
          let request = result.data;
          this.memberRequests.push(request);
        }
      });
    }
  }

  sendedRequest: boolean;
  checkSendedRequest() {
    let data = {
      groupId: this.dataGroups._id,
    };
    this.groupService.getMemberRequestById(data).subscribe((result) => {
      if (result.status == 'success') {
        if (result.data != '') {
          this.sendedRequest = true;
        } else this.sendedRequest = false;
      }
      console.log(this.sendedRequest);
    });
  }

  deleteRequest() {
    let data = {
      groupId: this.dataGroups._id,
    };
    this.groupService.getMemberRequestById(data).subscribe((result) => {
      if (result.status == 'success') {
        this.groupService
          .deleteMemberRequest(result.data[0]._id)
          .subscribe((res) => {
            if (res.status == 'success') {
              this.sendedRequest = false;
              this.memberRequests.splice(
                this.memberRequests.findIndex((i) => {
                  return i._id == result.data[0]._id;
                }),
                1
              );
            }
          });
      }
    });
  }
  //#endregion
}
