<div
    id="preferences-settings"
    class="settings-section"
>
    <div class="settings-panel">

        <div class="title-wrap">
            <a class="mobile-sidebar-trigger">
                <i-feather name="menu"></i-feather>
            </a>
            <h2>Preferences</h2>
        </div>

        <div class="settings-form-wrapper">
            <div class="settings-form">
                <div class="columns is-multiline flex-portrait">
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="monitor"></i-feather>
                                </div>
                                <h4>Devices</h4>
                                <p>Manage connected devices</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="codesandbox"></i-feather>
                                </div>
                                <h4>Authentication</h4>
                                <p>Authentication settings</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="box"></i-feather>
                                </div>
                                <h4>API</h4>
                                <p>API settings</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="search"></i-feather>
                                </div>
                                <h4>Search</h4>
                                <p>Search settings</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="cloud-snow"></i-feather>
                                </div>
                                <h4>Cloud Settings</h4>
                                <p>Manage storage</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="cpu"></i-feather>
                                </div>
                                <h4>Cache</h4>
                                <p>Cache settings</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="gift"></i-feather>
                                </div>
                                <h4>Reedeem</h4>
                                <p>Reedeem your points</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="command"></i-feather>
                                </div>
                                <h4>Shortcuts</h4>
                                <p>manage shortcuts</p>
                            </div>
                        </a>
                    </div>
                    <!-- link -->
                    <div class="column is-4">
                        <a class="setting-sublink">
                            <div class="link-content">
                                <div class="link-icon">
                                    <i-feather name="layout"></i-feather>
                                </div>
                                <h4>Layout</h4>
                                <p>Layout settings</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="illustration">
                <img
                    src="assets/img/illustrations/settings/5.svg"
                    alt
                >
                <p>
                    If you'd like to learn more about preferences settings, you can read about it in the
                    <a>user guide</a>
                    .
                </p>
            </div>
        </div>

    </div>
</div>
