import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

// in bytes, compress images larger than 1MB
const fileSizeMax = 1 * 1024 * 1024;
// in pixels, compress images have the width or height larger than 1024px
const widthHeightMax = 1024;
const defaultWidthHeightRatio = 1;
const defaultQualityRatio = 0.7;
@Injectable({
  providedIn: 'root',
})
export class FeedService {
  constructor(private http: HttpClient) { }

  //#region call back changes with service
  // submit create post in new feed
  isSubmit = new BehaviorSubject<any>([]);
  changeSuccess = this.isSubmit.asObservable();
  changeSuccessCreatePage(loadingPage) {
    this.isSubmit.next(loadingPage);
  }

  //submit create post in new feed (stories)
  isSubmitStories = new BehaviorSubject<any>([]);
  changeSubmit = this.isSubmitStories.asObservable();
  changeSubmitStories(stories) {
    this.isSubmitStories.next(stories);
  }

  //#region Share post
  isShare = new BehaviorSubject<any>([]);
  recevieSharePost = this.isShare.asObservable();
  clickSharePost(dataPost) {
    this.isShare.next(dataPost);
  }

  //#endregion

  //#region FUNCTION share post after submit
  isSubmitSharePost = new BehaviorSubject<any>([]);
  changeSubmitSharePost = this.isSubmitSharePost.asObservable();
  clickSubmitSharePostSuccess(isChanges) {
    this.isSubmitSharePost.next(isChanges);
  }
  //#endregion
  //#endregion

  //#region GET comment by postId for user
  getCommentByPostId(obj): Observable<any> {
    return this.http.post(`comment/getCommentByPostId`, obj);
  }
  getCommentById(commentId): Observable<any> {
    return this.http.get(`comment/getCommentById/${commentId}`);
  }

  updateCommentByUserId(obj): Observable<any> {
    return this.http.post(`comment/update`, obj);
  }
  //#endregion

  //#region  GET, DELETE like post, comment, reply of user
  getLikeByPostId(postId): Observable<any> {
    return this.http.get(`like/getLikeByPostId/${postId}`);
  }
  getLikeByFileId(fileId): Observable<any> {
    return this.http.get(`like/getLikeByFileId/${fileId}`);
  }
  getLikeByCommentId(commentId): Observable<any> {
    return this.http.get(`like/getLikeByCommentId/${commentId}`);
  }
  getLikeByReplyId(replyId): Observable<any> {
    return this.http.get(`like/getLikeByReplyId/${replyId}`);
  }

  deleteLikePost(postId): Observable<any> {
    return this.http.get(`like/deleteLikePost/${postId}`);
  }
  deleteLikePostByFileId(id): Observable<any> {
    return this.http.delete(`like/deleteLikePostByFileId/${id}`)
  }
  deleteLikeComment(commentId): Observable<any> {
    return this.http.get(`like/deleteLikeComment/${commentId}`);
  }
  deleteLikeReply(repliesId): Observable<any> {
    return this.http.get(`like/deleteLikeReply/${repliesId}`);
  }
  //#endregion

  //#region GET replies of commentId
  getReplyUserByCmtId(obj): Observable<any> {
    return this.http.post(`reply/getReplyUserByCmtId`, obj);
  }
  getReplyById(replyId): Observable<any> {
    return this.http.get(`reply/getReplyById/${replyId}`);
  }
  //#endregion

  //#region GET story
  getStory(obj): Observable<any> {
    return this.http.post(`post/getStory`, obj);
  }
  getStoryUser(): Observable<any> {
    return this.http.get(`post/getStoryUser`);
  }
  //#endregion

  //#region FUNCTION search user
  searchUserShare(search): Observable<any> {
    return this.http.post(`user/searchUserLink`, search);
  }
  //#endregion

  //#region CRUD share post
  createShare(obj): Observable<any> {
    return this.http.post(`share/create`, obj);
  }

  getShareUser(postId): Observable<any> {
    return this.http.get(`share/getShareUser/${postId}`);
  }

  getShareGroup(postId): Observable<any> {
    return this.http.get(`share/getShareGroup/${postId}`);
  }

  getSharePage(postId): Observable<any> {
    return this.http.get(`share/getSharePage/${postId}`);
  }
  //#endregion

  //#region UpLoad file image
  uploadFile(obj): Observable<any> {
    return this.http.post(`post/upload-cloud`, obj);
  }
  //#endregion
  getVideoPost(parameters): Observable<any> {
    let params = new HttpParams();
    if (!isUndefined(parameters)) {
      params = isUndefined(parameters.skip)
        ? params
        : params.append("skip", parameters.skip);
      params = isUndefined(parameters.limit)
        ? params
        : params.append("limit", parameters.limit);
    }
    return this.http.get(`post/getVideoPost`, { params });
  }
}
function isUndefined(value) {
  if (value) {
    return false;
  } else {
    return true;
  }
}
