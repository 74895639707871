<div class="videos-sidebar is-active">
	<div class="videos-sidebar-inner">
		<div class="user-block">
			<a class="close-videos-sidebar">
				<i-feather name="x"></i-feather>
			</a>
			<div class="avatar-wrap">
				<img
					src="https://via.placeholder.com/150x150"
					data-demo-src="assets/img/avatars/jenna.png"
					data-user-popover="0"
					alt=""
				/>
				<div class="badge">
					<i-feather name="check"></i-feather>
				</div>
			</div>
			<h4>Jenna Davis</h4>
			<p>Melbourne, AU</p>
			<div class="user-stats">
				<div class="stat-block">
					<span>Videos</span>
					<span>49</span>
				</div>
				<div class="stat-block">
					<span>Followers</span>
					<span>2.3K</span>
				</div>
			</div>
		</div>
		<div class="user-menu">
			<div class="user-menu-inner has-slimscroll">
				<div class="menu-block">
					<ul>
						<li class="is-active">
							<a>
								<i-feather name="smile"></i-feather>
								<span>Featured</span>
							</a>
						</li>
						<li>
							<a>
								<i-feather name="youtube"></i-feather>
								<span>Videos</span>
							</a>
						</li>
						<li>
							<a>
								<i-feather name="cast"></i-feather>
								<span>TV Shows</span>
							</a>
						</li>
						<li>
							<a>
								<i-feather name="film"></i-feather>
								<span>Movies</span>
							</a>
						</li>
					</ul>
				</div>
				<div class="separator"></div>
				<div class="menu-block">
					<ul>
						<li>
							<a>
								<i-feather name="check-square"></i-feather>
								<span>Subscriptions</span>
							</a>
						</li>
						<li>
							<a>
								<i-feather name="copy"></i-feather>
								<span>Library</span>
							</a>
						</li>
						<li>
							<a>
								<i-feather name="thumbs-up"></i-feather>
								<span>Liked Videos</span>
							</a>
						</li>
						<li>
							<a>
								<i-feather name="watch"></i-feather>
								<span>Watch Later</span>
							</a>
						</li>
					</ul>
				</div>
				<div class="separator"></div>
				<div class="menu-block">
					<ul>
						<li>
							<a>
								<i-feather name="sliders"></i-feather>
								<span>Settings</span>
							</a>
						</li>
						<li>
							<a>
								<i-feather name="life-buoy"></i-feather>
								<span>Help & Support</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
