import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  //#region CRUD Notification of user login
  getNotification(): Observable<any> {
    return this.http.get(`notification/link/`);
  }
  createNotification(obj): Observable<any> {
    return this.http.post(`notification/link/create`, obj);
  }
  updateNotification(obj): Observable<any> {
    return this.http.post(`notification/link/update`, obj);
  }
  deleteNotification(id): Observable<any> {
    return this.http.get(`notification/link/delete/${id}`);
  }

  //#endregion
}
