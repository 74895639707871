import { Component, Input, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import * as enviroment from '../../../../../environments/environment';

declare var jQuery: any;

declare function initDropdowns(): any;
declare function initLightboxEmojis(): any;

@Component({
  selector: 'app-profile-album',
  templateUrl: './profile-album.component.html',
  styleUrls: ['./profile-album.component.scss'],
})
export class ProfileAlbumComponent implements OnInit {
  infousers;
  @Input() set infouser(infoUser) {
    this.infousers = infoUser;
    this.getAlbumUser(this.infousers._id);
  }

  enviroment;
  constructor(private profileServer: ProfileService) {
    this.enviroment = enviroment.environment.apiUrl;
  }

  ngOnInit(): void {
    this.skip = 0;
    this.limit = 9;
    this.isActiveAlbum = false;
    this.isDelete = false;
  }

  //#region Get album by pageId;
  arrAlbumm = [];
  listShowAblum = [];
  getAlbumUser(userId) {
    this.arrAlbumm = [];
    this.arrPhoto = [];
    this.profileServer
      .getAlbumByUserId({ userId: userId })
      .subscribe((result) => {
        if (result.status == 'success') {
          this.arrAlbumm = result.data;
        }
      });
  }

  //#endregion

  //#region Get photo of album in fanpages
  skip;
  limit;
  arrPhoto = [];
  listShowPhoto = [];
  albumIdCurrent;
  getPhotoByAlbumId(albumId, skip, limit) {
    this.arrPhoto = [];
    this.albumIdCurrent = albumId;
    this.profileServer.getPhotoByAlbumId(albumId).subscribe((result) => {
      if (result.status == 'success') {
        this.arrPhoto = result.data;
      }
      this.loadFancybox();
    });
  }
  //#endregion

  //#region Open/Close album
  isActiveAlbum;
  titleAlbum;
  openAlbum(album) {
    this.titleAlbum = album.albumName;
    this.getPhotoByAlbumId(album._id, this.skip, this.limit);
    this.isActiveAlbum = !this.isActiveAlbum;
  }
  closeAlbum() {
    this.isActiveAlbum = !this.isActiveAlbum;
    this.getAlbumUser(this.infousers._id);
    this.skip = 0;
    this.limit = 5;
  }
  //#endregion

  //#region deleteImg of photo in album
  isDelete;
  photoId;
  openModalDelete(photoId) {
    this.photoId = photoId;
    this.isDelete = !this.isDelete;
  }
  closeModalDelete() {
    this.isDelete = !this.isDelete;
  }
  deleteImg() {
    this.isDelete = false;
    if (this.photoId) {
      this.profileServer.deletePhoto(this.photoId).subscribe((result) => {
        if (result.status == 'success') {
          let indexDetele = this.listShowPhoto.findIndex(
            (index) => index._id === this.photoId
          );
          if (indexDetele) {
            this.listShowPhoto.splice(indexDetele, 1);
          }
        }
      });
    }
  }
  //#endregion

  //#region Load more photo of album
  skipCurrent = 0;
  getMorePhoto() {
    this.skip = this.skipCurrent + 5;
    this.skipCurrent = this.skip;
    this.getPhotoByAlbumId(this.albumIdCurrent, this.skip, this.limit);
  }
  //#endregion

  //#region JQUERY ZOOM IMAGE USING FANCYBOX
  loadFancybox() {
    (function ($) {
      $(document).ready(function () {
        if ($('[data-fancybox]').length) {
          $('[data-fancybox]').each(function () {
            if ($(this).attr('data-lightbox-type') == 'photo') {
              $(this).fancybox({
                baseClass: 'fancybox-custom-layout',
                keyboard: false,
                infobar: false,
                touch: {
                  vertical: false,
                },
                buttons: ['close', 'thumbs', 'share'],
                animationEffect: 'fade',
                transitionEffect: 'fade',
                preventCaptionOverlap: false,
                idleTime: false,
                gutter: 0,
                caption: false,
                afterShow: function (instance, current) {
                  //initDropdowns();
                  initLightboxEmojis();
                },
              });
            }
          });
        }
      });
    })(jQuery);
  }
  //#endregion
}
