import { Component, OnInit } from '@angular/core';

declare var jQuery: any;
@Component({
  selector: 'app-questions-single',
  templateUrl: './questions-single.component.html',
  styleUrls: ['./questions-single.component.scss'],
})
export class QuestionsSingleComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }

}
