import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "app-chat-nav",
	templateUrl: "./chat-nav.component.html",
	styleUrls: ["./chat-nav.component.scss"],
})
export class ChatNavComponent implements OnInit {
	@Input() infoFriend;
	@Output() closeBtnChat = new EventEmitter<any>();
	constructor() { }

	ngOnInit(): void { }
}
